import React from 'react';
import {observer} from 'mobx-react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {IconColors, IconSizes, BorderRoundedIcon} from '@techsee/techsee-ui-common/lib/icons/large-rounded-icon';
import {LabelWithIcon} from '@techsee/techsee-ui-common/lib/forms/labels/with-icon';
import {BorderRoundedIconButton} from '@techsee/techsee-ui-common/lib/forms/buttons/border-rounded-icon';
import {ITranslate} from '../../../services/LocalizationService';
import {TSImageResource} from './SessionSummary.contracts';

export interface IThumbnailBadgedImageProps {
    image: TSImageResource;
    height?: number;
    width?: number;
    maxHeight?: number;
    maxWidth?: number;
    displayTagBadge?: boolean;
    displaySharedBadge?: boolean;
    displayFavoriteBadge?: boolean;
    className?: string;
    isError?: boolean;
    isMainImage?: boolean;

    onImageClicked?(): void;

    onFavoriteClicked?(index: number): void;

    index?: number;
    translate: ITranslate;
}

interface IThumbnailBadgedImageDefaults {
    displayTagBadge?: boolean;
    displaySharedBadge?: boolean;
    displayFavoriteBadge?: boolean;
    className?: string;
    isError?: boolean;
}

@observer
export class SessionSummaryImage extends TechseeBaseComponent<IThumbnailBadgedImageProps> {
    private readonly imageStyle: {
        height?: number;
        width?: number;
        maxHeight?: number;
        maxWidth?: number;
        backgroundColor?: string;
    };

    private readonly imageContainerStyle: {height: number; width: number; backgroundColor?: string};

    constructor(props: IThumbnailBadgedImageProps) {
        super(props);

        this.imageStyle = {
            height: 100,
            width: 100,
            backgroundColor: '#EBECEA'
        };

        this.imageContainerStyle = {
            height: 100,
            width: 100
        };

        if (props.height && props.width) {
            this.imageStyle = {
                height: props.height,
                width: props.width
            };
        } else if (props.maxHeight && props.maxWidth) {
            this.imageStyle = {
                maxHeight: props.maxHeight,
                maxWidth: props.maxWidth
            };

            this.imageContainerStyle = {
                height: props.maxHeight,
                width: props.maxWidth,
                backgroundColor: '#EBECEA'
            };
        }
    }

    static defaultProps: Readonly<IThumbnailBadgedImageDefaults> = {
        displayTagBadge: false,
        displaySharedBadge: false,
        displayFavoriteBadge: false,
        className: '',
        isError: false
    };

    renderInternal(): JSX.Element {
        const {
            image,
            isMainImage,
            className,
            index,
            onFavoriteClicked,
            isError,
            translate,
            onImageClicked,
            displaySharedBadge,
            displayTagBadge,
            displayFavoriteBadge
        } = this.props;
        let markedBadge = <div></div>;
        let imageComponent = isMainImage ? (
            <img style={this.imageStyle} src={this.props.image && this.props.image.url}></img>
        ) : (
            <div
                className={`image-container ${image && image.isSelected && !isMainImage ? 'imageSelected' : ''}`}
                style={this.imageStyle}>
                <img src={image && image.url}></img>
            </div>
        );

        if (className !== 'img-container') {
            const indexToClick = index !== undefined ? index : -1;
            const colorName = displayFavoriteBadge ? IconColors.Yellow : IconColors.Grey;

            markedBadge = (
                <BorderRoundedIconButton
                    className={'icon-image icon-star-position'}
                    iconName={'star'}
                    onClick={() => onFavoriteClicked && onFavoriteClicked(indexToClick)}
                    reverseColor={true}
                    colorName={colorName}
                    sizeName={IconSizes.Large}
                />
            );
        }

        if (isError) {
            if (className === 'img-container') {
                imageComponent = (
                    <div className={'main-error-image-container'}>
                        <img style={this.imageStyle} src={image && image.url}></img>
                        <div className={'text-error-container'}>
                            <span className={'error-text main'}>
                                {translate('REACT.DASHBOARD.VIEW.FAILED_SUMMARY_MAIN_MESSAGE')}
                            </span>
                            <span className={'error-text text'}>
                                {translate('REACT.DASHBOARD.VIEW.FAILED_SUMMARY_MESSAGE')}
                            </span>
                        </div>
                    </div>
                );
            } else {
                imageComponent = <></>;
            }
        }

        return (
            <div
                className={'session-summary-image ' + className}
                style={this.imageContainerStyle}
                onClick={() => onImageClicked && onImageClicked()}>
                {imageComponent}
                {markedBadge}
                {displayTagBadge && (
                    <BorderRoundedIcon
                        className={'icon-image icon-tag-position'}
                        iconName={'tag'}
                        reverseColor={false}
                        colorName={IconColors.Grey}
                        sizeName={IconSizes.Medium}></BorderRoundedIcon>
                )}
                {displaySharedBadge && (
                    <LabelWithIcon className={'icon-share-design'} icon={'share'}>
                        {translate('REACT.DASHBOARD.VIEW.SHARED')}
                    </LabelWithIcon>
                )}
            </div>
        );
    }
}
