import React from 'react';
import {observer} from 'mobx-react';
import {IOfflineSessionsController} from './controller';
import {Table} from '@techsee/techsee-ui-common/lib/table';
import {ButtonTypeAttributes} from '@techsee/techsee-ui-common/lib/forms/buttons/generic';
import {PrimaryButton} from '@techsee/techsee-ui-common/lib/forms/buttons/primary';
import {Icon} from '@techsee/techsee-ui-common/lib/icons/simple-icon';
import {SESSION_SOURCE_MAP} from '@techsee/techsee-common/lib/constants/room.constants';

import './styles.scss';
import moment from 'moment';

export interface IOfflineSessions {
    ctrl: IOfflineSessionsController;
    onJoin: (sessionId: string) => Promise<void>;
}

@observer
export class OfflineSessions extends React.Component<IOfflineSessions> {
    render() {
        const {translate, offlineSessions, selectSession, selectedSession, isJoinAvailable, onJoin} = this.props.ctrl;

        const TRANSLATE_PREFIX = 'REACT.INVITE.JOIN_OFFLINE_TABLE';

        return (
            <div className='offline-sessions'>
                <div className='offline-sessions__title'>{translate(`${TRANSLATE_PREFIX}.TITLE`)}</div>
                <Table striped bordered condensed hover responsive>
                    <thead>
                        <tr>
                            <th className='offline-sessions__firstcol'></th>
                            <th>{translate(`${TRANSLATE_PREFIX}.SESSION_SOURCE`)}</th>
                            <th>{translate(`${TRANSLATE_PREFIX}.CUSTOMER_ID`)}</th>
                            <th>{translate(`${TRANSLATE_PREFIX}.CUSTOMER_NUMBER`)}</th>
                            <th>{translate(`${TRANSLATE_PREFIX}.SESSION_ID`)}</th>
                            <th>{translate(`${TRANSLATE_PREFIX}.LINK_CREATION`)}</th>
                            <th>{translate(`${TRANSLATE_PREFIX}.FIRST_ENTRY`)}</th>
                            <th>{translate(`${TRANSLATE_PREFIX}.LAST_UPDATE`)}</th>
                            <th>{translate(`${TRANSLATE_PREFIX}.SESSION_CREATED`)}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {offlineSessions.map((session) => {
                            const roomConnectedTimestamp = moment(session.room.connectedTimestamp).unix()
                                ? session.room.connectedTimestamp
                                : '';

                            return (
                                <tr
                                    key={session.room._id}
                                    className={session.occupied ? 'occupied' : ''}
                                    onClick={() => selectSession(session)}>
                                    <td>
                                        <input
                                            value={session.room._id}
                                            type='radio'
                                            checked={selectedSession.room._id === session.room._id}
                                        />
                                    </td>
                                    <td>{(SESSION_SOURCE_MAP as any)[session.room.sessionSource]}</td>
                                    <td>{session.room.customerId}</td>
                                    <td>{session.room.customerNumber}</td>
                                    <td>{session.room.sessionCode}</td>
                                    <td>{session.room.creationTimestamp}</td>
                                    <td>{roomConnectedTimestamp}</td>
                                    <td>{session.room.lastUpdate}</td>
                                    <td>{session.createdBy}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>

                <div className='offline-sessions__join'>
                    <PrimaryButton
                        disabled={!isJoinAvailable}
                        onClick={() => onJoin(this.props.onJoin)}
                        type={ButtonTypeAttributes.Submit}
                        className={'submit'}>
                        {translate('REACT.INVITE.VIEW.JOIN_OFFLINE_SESSION_SUBMIT')}
                        <Icon iconName='arrow-next' />
                    </PrimaryButton>
                </div>
            </div>
        );
    }
}
