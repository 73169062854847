'use strict';
import {getRootStore} from '../../_react_/app.bootstrap';

import get from 'lodash/get';
import camelCase from 'lodash/camelCase';

function _extractBrowsers(browsers) {
    const enabledBrowsersArr = [];

    if (browsers.enabledChrome) {
        enabledBrowsersArr.push('Chrome');
    }

    if (browsers.enabledFF) {
        enabledBrowsersArr.push('Firefox');
    }

    if (browsers.enabledIE) {
        enabledBrowsersArr.push('IE');
    }

    if (browsers.enabledSafari) {
        enabledBrowsersArr.push('Safari');
    }

    if (browsers.enabledMobileSafari) {
        enabledBrowsersArr.push('Mobile Safari');
    }

    if (browsers.enabledEdge) {
        enabledBrowsersArr.push('Edge');
    }

    if (browsers.enabledChromeWebview) {
        enabledBrowsersArr.push('Chrome Webview');
    }

    if (browsers.enabledChromeForAndroid) {
        enabledBrowsersArr.push('Chrome for Android');
    }

    if (browsers.enabledChromeForIos) {
        enabledBrowsersArr.push('Chrome for iOS');
    }

    return enabledBrowsersArr;
}

export class BrowserNotSupportedController {
    constructor(tsLocaleHelper, $stateParams) {
        'ngInject';

        this.dir = tsLocaleHelper.isRTL() ? 'rtl' : 'ltr';
        this.browserInfo = $stateParams.browserInfo;
        this.userName = $stateParams.userName;
        this.environmentDetect = getRootStore().environmentService;
        this.displayTabletAsDesktop = getRootStore().displayTabletAsDesktop;

        if (this.userName) {
            this._initBrowsers();
        }
    }

    get browserMajor() {
        return this.environmentDetect.browser().major;
    }

    _initBrowsers() {
        const browsers =
            this.browserInfo.browsers[this.environmentDetect.deviceCategory(this.displayTabletAsDesktop)] || {};

        this.enabledBrowsersArr = _extractBrowsers(browsers);

        this.enabledBrowsers = this.enabledBrowsersArr.join(', ');

        const browserName = camelCase(this.browserInfo.browserName);

        this.minVersion = get(browsers, `${browserName}MinVersion`);

        const excludedVersions = get(browsers, `${browserName}ExcludedVersions`);

        this.excludedVersions = excludedVersions ? excludedVersions.join(', ') : undefined;

        this.browserIcons = this.browserInfo.isSupportedBrowser
            ? [this.browserInfo.browserName]
            : this.enabledBrowsersArr;
    }
}
