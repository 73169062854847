import React from 'react';
import {observer} from 'mobx-react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {SecondaryButton} from '@techsee/techsee-ui-common/lib/forms/buttons/secondary';
import {DefaultModalHeader} from '@techsee/techsee-ui-common/lib/modals/generic/default-modal-header';
import {ButtonsToolbar} from '@techsee/techsee-ui-common/lib/forms/buttons/toolbar';
import {PrimaryButton} from '@techsee/techsee-ui-common/lib/forms/buttons/primary';
import {GenericModal} from '@techsee/techsee-ui-common/lib/modals/generic';
import {ITranslate} from '../../../../../services/LocalizationService';
import {IChangeNumberController} from './controller';
import {AddressTypesEnum} from '@techsee/techsee-common/lib/constants/room.constants';
import {ChangeNumberOrigins} from '../../../_contracts/InviteContracts';
import InviteMethodFormView from '../invite-method-form/view';

export interface IChangeNumber {
    ctrl: IChangeNumberController;
    translate: ITranslate;
}

@observer
export class ChangeNumber extends TechseeBaseComponent<IChangeNumber> {
    componentDidUpdate() {
        this.props.ctrl.inviteMethodController.inviteForm.snapshotFormValues();
    }

    renderInternal() {
        const {ctrl, translate} = this.props;
        const isEmail = ctrl.inviteMethodController.addressType === AddressTypesEnum.EMAIL;

        let modalTitle = isEmail
            ? 'REACT.INVITE.SPEAKER_WIFI.CHANGE_ADDRESS'
            : 'REACT.INVITE.SPEAKER_WIFI.CHANGE_NUMBER';

        modalTitle =
            ctrl.modalMode === ChangeNumberOrigins.UNSUPPORTED ? 'REACT.INVITE.SPEAKER_WIFI.CHANGE_DEVICE' : modalTitle;

        const Header = () => <DefaultModalHeader>{translate(modalTitle)}</DefaultModalHeader>;

        const Content = () => (
            <div className='wrapper'>
                {ctrl.modalMode === ChangeNumberOrigins.UNSUPPORTED && this.getUnsupportedDeviceContent()}
                <InviteMethodFormView
                    addressType={ctrl.inviteMethodController.addressType}
                    ctrl={ctrl.inviteMethodController}
                    translate={translate}
                    isChangeNumber={true}
                />
            </div>
        );

        const ToolBar = () => (
            <ButtonsToolbar>
                <SecondaryButton onClick={() => ctrl.onCancelClick()}>
                    {ctrl.modalMode === ChangeNumberOrigins.UNSUPPORTED
                        ? translate('REACT.INVITE.SPEAKER_WIFI.CLOSE_SESSION')
                        : translate('REACT.COMMON.BUTTONS.CANCEL')}
                </SecondaryButton>
                <PrimaryButton onClick={() => ctrl.onChangeInviteInfoClick()}>
                    {ctrl.modalMode === ChangeNumberOrigins.UNSUPPORTED
                        ? translate(
                              isEmail
                                  ? 'REACT.INVITE.SPEAKER_WIFI.RESEND_ADDRESS'
                                  : 'REACT.INVITE.SPEAKER_WIFI.RESEND_SMS'
                          )
                        : translate('REACT.INVITE.VIEW.CHANGE')}
                </PrimaryButton>
            </ButtonsToolbar>
        );

        return (
            <GenericModal
                className={'change-number'}
                header={Header}
                content={Content}
                toolbar={ToolBar}
                onHideHandler={() => ctrl.onCancelClick()}
                show={ctrl.isDisplayConfirmationModal}></GenericModal>
        );
    }

    private getUnsupportedDeviceContent() {
        const device = this.props.ctrl.clientMobileOsName + this.props.ctrl.clientMobileOsVersion;

        return (
            <div className='content'>
                <p className='image' />
                <p className='info'>
                    {this.props.translate('REACT.INVITE.SPEAKER_WIFI.UNSUPPORTED_DEVICE', {device: `${device}`})}
                </p>
                <p className='text'>{this.props.translate('REACT.INVITE.SPEAKER_WIFI.ASK_CUSTOMER_CHANGE_DEVICE')}</p>
            </div>
        );
    }
}
