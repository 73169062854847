import {inject, IReactComponent} from 'mobx-react';
import * as component from './component';

const mapStoresToProps = (stores: any) => ({
    translate: stores.localizationService.translate.bind(stores.localizationService)
});

const BottomBar = inject(mapStoresToProps)(component.BottomBar) as IReactComponent;

export {BottomBar};
export default BottomBar;
