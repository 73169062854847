import React from 'react';
import {configure} from 'mobx';
import {Provider} from 'mobx-react';
import InviteView from './invite.view.html';
import {InviteStores} from './invite.stores';
import AppLayoutComponent from '../../layouts/main-layout/index';
import {ScreenLoader} from '../../components/screen-loader/screen-loader';

import InviteTopBar from './invite-top-bar';
import InviteMainContent from './main-content';

import '@techsee/techsee-ui-common/lib/_shared/icons.css';

//Configures MobX to allow changing state from actions only.
configure({
    enforceActions: 'observed'
});

class InviteRootComponent extends React.Component {
    render() {
        const {stores} = this.props;

        if (typeof stores !== 'object') {
            return <ScreenLoader />;
        }

        const providerStores = {};

        Object.getOwnPropertyNames(stores).forEach((storeName) => {
            providerStores[storeName] = stores[storeName];
        });

        return (
            <Provider {...providerStores}>
                <AppLayoutComponent topBarComponent={InviteTopBar} mainContentComponent={InviteMainContent} />
            </Provider>
        );
    }
}

const reactDirectiveWrapper = ['reactDirective', (reactDirective) => reactDirective(InviteRootComponent)];

const inviteView = {
    template: InviteView,
    controller: InviteStores,
    controllerAs: 'invite'
};

export default angular
    .module('states.invite.new', [])
    .directive('inviteRootComponent', reactDirectiveWrapper)
    .config(['$stateProvider', config]);

function config($stateProvider) {
    $stateProvider.state('invite.new', {
        params: {
            message: null,
            error: null
        },
        views: {
            'invite-desktop-view': inviteView,
            'invite-mobile-view': inviteView
        }
    });
}
