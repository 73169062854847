import React from 'react';
import GuidanceImage, {IGuidanceImage} from './GuidanceImage';

interface IGuidanceImagesProps {
    images: IGuidanceImage[];
}

const GuidanceImages: React.FunctionComponent<IGuidanceImagesProps> = (props: IGuidanceImagesProps) => (
    <div className='images-wrapper'>
        {props.images.map((img, index) => (
            <GuidanceImage key={`guidanceImage${index}`} {...img} />
        ))}
    </div>
);

export default GuidanceImages;
