'use strict';

import tsHelpBrowserView from './ts-help-browser.view.html';
import './ts-help-browser.style.scss';

export class TsHelpBrowserService {
    constructor($compile, $document, $rootScope) {
        'ngInject';

        this.scope = $rootScope.$new(true);
        this.scope.readyCallback = () => this.readyCallback();
        this.scope.visible = false;

        $compile(tsHelpBrowserView)(this.scope);
    }

    openHelp() {
        this.scope.visible = true;
    }

    closeHelp() {
        this.scope.visible = false;
    }
}
