/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import classNames from 'classnames';
import findIndex from 'lodash/findIndex';
import {observer} from 'mobx-react';

import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {SimpleLabel} from '@techsee/techsee-ui-common/lib/forms/labels/simple';
import {SimpleTabs} from '@techsee/techsee-ui-common/lib/tabs/simple';

import {InviteInfoStepController} from './controller';
import {IInviteMethodController} from '../../components/invite-method-form/controller';
import {IJoinOfflineController} from '../../components/join-offline-form/controller';
import {IOfflineSessionsController} from '../../components/offline-sessions/controller';
import {IPendingRoomSessionsController} from '../../components/PendingRoomSessions/controller';
import {JoinOfflineForm} from '../../components/join-offline-form/component';
import {OfflineSessions} from '../../components/offline-sessions/component';
import {PendingRoomSessions} from '../../components/PendingRoomSessions/component';
import {AgentHelper} from '../../components/agent-helper';
import InviteMethodFormView from '../../components/invite-method-form/view';
import StartWithModes from '../../../../../components/start-with-modes';
import {InviteInfoToolbar} from './toolbar';
import {IFilter, IObservableController} from '../../components/observable-form/controller';
import ObservableForm from '../../components/observable-form/component';
import {IObservableSessionsController} from '../../components/observable-sessions/controller';
import {ObservableSessions} from '../../components/observable-sessions/component';
import {DeviceCategory} from '@techsee/techsee-common/lib/constants/utils.constant';
import {AddressTypesEnum} from '@techsee/techsee-common/lib/constants/room.constants';

export interface IInviteInfoContent {
    step: InviteInfoStepController;
    inviteInfo: IInviteMethodController;
    joinOffline: IJoinOfflineController;
    offlineSessions: IOfflineSessionsController;
    WarmTransferSessions: IPendingRoomSessionsController;
    observableForm: IObservableController;
    observableSessions: IObservableSessionsController;
}

interface ITabList {
    title: string;
    content: (addressType?: AddressTypesEnum) => JSX.Element;
    disabled: boolean;
    available: boolean;
    addressType: AddressTypesEnum;
    badgeText?: string;
}

function _updateTabActive(step: InviteInfoStepController, tabsList: ITabList[]) {
    const currentTabIndex = findIndex(tabsList, (tab) => tab.addressType === step.currentAddressType);

    if (tabsList[currentTabIndex].disabled) {
        const newTabIndex = findIndex(tabsList, (tab) => !tab.disabled);

        step.setActiveTab(newTabIndex);
        step.setInviteAddressType(tabsList[newTabIndex].addressType);
    }
}

interface INoSessionContent {
    text: string;
}

export class NoSessionContent extends TechseeBaseComponent<INoSessionContent> {
    renderInternal(): JSX.Element {
        return (
            <div className={'no-sessions'}>
                <img src='img/invite-no-sessions.png'></img>
                <div className='no-sessions-text'>{this.props.text}</div>
            </div>
        );
    }
}

@observer
export class InviteInfoContent extends TechseeBaseComponent<IInviteInfoContent> {
    componentDidMount() {
        this.props.step.setPagePlacement('invite-page');
    }

    componentWillMount() {
        if (this.tabsList && this.tabsList[0] && this.tabsList[0].addressType) {
            this.props.step.setInviteAddressType(this.tabsList[0].addressType);
        }

        _updateTabActive(this.props.step, this.tabsList);
    }

    getInviteTabContent(addressType: AddressTypesEnum): JSX.Element {
        const {step, inviteInfo} = this.props;

        return (
            <div className='invite-info-tabs__tab'>
                {step.isAgentHelperAvailable && (
                    <AgentHelper>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: step.translate('REACT.INVITE.VIEW.START_VISUAL_SUPPORT')
                            }}></div>
                        <br />
                        <br />
                        <div dangerouslySetInnerHTML={{__html: step.translate('REACT.INVITE.VIEW.MAKE_OFFER')}}></div>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: step.translate('REACT.INVITE.VIEW.SEE_WHAT_YOU_SEE')
                            }}></div>
                        <br />
                        <br />
                        <div
                            dangerouslySetInnerHTML={{
                                __html: step.translate('REACT.INVITE.VIEW.NO_SMARTPHONE')
                            }}></div>
                    </AgentHelper>
                )}
                <div className='invite-info-tabs__form'>
                    <InviteMethodFormView
                        addressType={addressType}
                        onSubmit={step.onNextClick}
                        ctrl={inviteInfo}
                        translate={step.translate}
                    />
                    {step.notificationMessageText && (
                        <SimpleLabel className={classNames('offline-session-label', step.notificationMessageStatus)}>
                            {step.notificationMessageText}
                        </SimpleLabel>
                    )}
                    <InviteInfoToolbar step={step} />
                </div>
            </div>
        );
    }

    get rawTabsList(): ITabList[] {
        const {step} = this.props;
        const {appState} = step;
        const {offlineRoomCount, pendingRoomCount} = appState;

        return [
            {
                title: step.translate('REACT.INVITE.VIEW.SMS_TAB_HEADER'),
                content: () => this.getInviteTabContent(AddressTypesEnum.SMS),
                disabled: step.isDesktopSharingMode,
                available: step.isInviteBySmsAvailable,
                addressType: AddressTypesEnum.SMS
            },
            {
                title: step.translate('REACT.INVITE.VIEW.WHATSAPP_TAB_HEADER'),
                content: () => this.getInviteTabContent(AddressTypesEnum.WHATSAPP),
                disabled: step.isDesktopSharingMode,
                available: step.isInviteByWhatsAppAvailable,
                addressType: AddressTypesEnum.WHATSAPP
            },
            {
                title: step.translate('REACT.INVITE.VIEW.EMAIL_TAB_HEADER'),
                content: () => this.getInviteTabContent(AddressTypesEnum.EMAIL),
                disabled: step.isDesktopSharingMode,
                available: step.isInviteByEmailAvailable,
                addressType: AddressTypesEnum.EMAIL
            },
            {
                title: step.translate('REACT.INVITE.VIEW.INVITE_BY_CODE_TAB_HEADER'),
                content: () => this.getInviteTabContent(AddressTypesEnum.INVITE_BY_CODE),
                disabled: !step.isDesktopSharingMode,
                available: step.isDesktopSharingAvailable,
                addressType: AddressTypesEnum.INVITE_BY_CODE
            },
            {
                title: step.translate('REACT.INVITE.VIEW.SESSION_OBSERVATION'),
                content: () => this.getObservableTabContent(),
                disabled: false,
                available: step.isObserveTabVisible,
                addressType: AddressTypesEnum.OBSERVE
            },
            {
                title: step.translate(
                    step.isOfflineMode
                        ? 'REACT.INVITE.VIEW.OFFLINE_TAB_HEADER_OFFLINE_MODE'
                        : 'REACT.INVITE.VIEW.OFFLINE_TAB_HEADER'
                ),
                badgeText: offlineRoomCount === '0' ? '' : offlineRoomCount,
                content: () => this.getOfflineTabContent(),
                disabled: step.isDesktopSharingMode,
                available: step.isOfflineSessionAvailable,
                addressType: AddressTypesEnum.OFFLINE
            },
            {
                title: step.translate('REACT.INVITE.VIEW.PENDING_ROOM_TAB_HEADER'),
                badgeText: pendingRoomCount === '0' ? '' : pendingRoomCount,
                content: () => this.getWtTabContent(),
                disabled: step.isDesktopSharingMode,
                available: step.isWarmTransferSessionAvailable,
                addressType: AddressTypesEnum.OFFLINE
            }
        ];
    }

    get tabsList(): ITabList[] {
        return this.rawTabsList.filter((tab) => tab.available);
    }

    componentWillUpdate() {
        _updateTabActive(this.props.step, this.tabsList);
    }

    getObservableTabContent(): JSX.Element {
        const {observableForm, observableSessions, step} = this.props;
        const renderObservableContent = (
            <NoSessionContent text={step.translate('REACT.INVITE.VIEW.SESSION_OBSERVATION.NO_SESSIONS')} />
        );

        return (
            <div className='join-table'>
                <ObservableForm
                    observableCtrl={observableSessions}
                    onSearchObservableRooms={(filter?: IFilter) => step.retrieveObservableRooms(filter)}
                    ctrl={observableForm}
                />

                <div className={'divider'}></div>

                <ObservableSessions
                    noSessionsComponent={renderObservableContent}
                    onSearchObservableRooms={step.retrieveObservableRooms}
                    ctrl={observableSessions}
                />
            </div>
        );
    }

    getOfflineTabContent(): JSX.Element {
        const {joinOffline, offlineSessions, step} = this.props;

        if (step.appState.offlineRoomCount === '0') {
            return (
                <NoSessionContent
                    text={
                        step.isOfflineMode
                            ? step.translate('REACT.INVITE.VIEW.NO_OFFLINE_SESSIONS_OFFLINE_MODE')
                            : step.translate('REACT.INVITE.VIEW.NO_OFFLINE_SESSIONS')
                    }
                />
            );
        }

        return (
            <div className='join-table'>
                {step.allowSearchOfflineSessionsById && (
                    <React.Fragment>
                        <JoinOfflineForm
                            onJoin={step.onJoinOfflineClick}
                            ctrl={joinOffline}
                            translate={step.translate}
                        />

                        <div className='join-offline__divider'>
                            <span className='join-offline__divider-text'>{step.translate('REACT.INVITE.VIEW.OR')}</span>
                        </div>
                    </React.Fragment>
                )}

                <OfflineSessions onJoin={step.onJoinOfflineClick} ctrl={offlineSessions} />
            </div>
        );
    }

    getWtTabContent(): JSX.Element {
        const {joinOffline, WarmTransferSessions, step} = this.props;

        if (step.appState.pendingRoomCount === '0') {
            return <NoSessionContent text={step.translate('REACT.INVITE.VIEW.NO_PENDING_ROOM_SESSIONS')} />;
        }

        return (
            <div className='join-table'>
                <React.Fragment>
                    <JoinOfflineForm
                        onJoin={step.onJoinWtClick}
                        ctrl={joinOffline}
                        translate={step.translate}
                        warmTransfer={true}
                        getLink={this.props.WarmTransferSessions.findLinkById}
                    />

                    <div className='join-offline__divider'>
                        <span className='join-offline__divider-text'>{step.translate('REACT.INVITE.VIEW.OR')}</span>
                    </div>
                </React.Fragment>
                <PendingRoomSessions onJoin={step.onJoinWtClick} ctrl={WarmTransferSessions} />
            </div>
        );
    }

    handleTabChange(index: number) {
        const {step} = this.props;

        step.setInviteAddressType(this.tabsList[index].addressType);
    }

    renderInternal(): JSX.Element {
        const {step, inviteInfo} = this.props;
        const {appState, notificationMessageText, notificationMessageStatus} = step;
        const {offlineRoomCount} = appState;
        const initialTabIndex = findIndex(this.tabsList, (tab) => tab.addressType === step.currentAddressType);
        const isLeftSideVisible = !(
            (step.currentAddressType === AddressTypesEnum.OBSERVE ||
                step.currentAddressType === AddressTypesEnum.OFFLINE) &&
            inviteInfo.deviceCategory === DeviceCategory.tablet
        );

        return (
            <React.Fragment>
                <div
                    className={classNames('invite-info', {
                        'desktop-sharing': step.isDesktopSharingMode,
                        'agent-helper-enabled': step.isAgentHelperAvailable
                    })}>
                    {isLeftSideVisible && (
                        <div className='invite-info__block invite-info_small'>
                            <div className='invite-info__header'>
                                <h1 className='page-header'>{step.translate('REACT.INVITE.CUSTOMER_INFO.Header')}</h1>
                                <h2 className='sub-header'>{step.translate('REACT.INVITE.CUSTOMER_INFO.SubHeader')}</h2>
                            </div>
                            {inviteInfo.isStartWithModesEnabled && <StartWithModes />}
                        </div>
                    )}

                    <div
                        className={classNames('invite-info-tabs', 'invite-info__block', {
                            'invite-info-tabs_notabs': !step.showInviteTabs
                        })}>
                        {step.showInviteTabs ? (
                            <SimpleTabs
                                uid='invite-info-tabs'
                                tabsList={this.tabsList}
                                initialTabIndex={initialTabIndex}
                                setHandlers={({setActiveTab}) => (step.setActiveTab = setActiveTab)}
                                onChange={(index: number) => this.handleTabChange(index)}
                            />
                        ) : (
                            this.getInviteTabContent(AddressTypesEnum.SMS)
                        )}
                    </div>

                    {!step.showInviteTabs && <div className='invite-info__block invite-info_small' />}
                </div>
            </React.Fragment>
        );
    }
}
