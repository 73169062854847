import {GeneralSettings, VJHistoryFilterSettings} from '../../models/AccountSettings';
import {IInviteFlowManager} from '../../states/invite-new/_contracts/InviteContracts';
import {IRedirectionService} from '../../services/RedirectionService';
import {VJFormController} from '../vj-form/controller';
import {BasicFormModel} from '../common-forms/form-model';
import {getRootStore} from '../../app.bootstrap';
import {SESSION_SOURCE} from '@techsee/techsee-common/lib/constants/room.constants';
import {IGenericFormController} from '../common-forms/controller';

export interface IVJHistoryFilterController {
    onVJSessionsSearch(event: any): void;
    searchHistory(): Promise<boolean | void>;

    formController: IGenericFormController;
}

export class VJHistoryFilterController implements IVJHistoryFilterController {
    readonly formController: IGenericFormController;

    private formModel: BasicFormModel;

    private accountId: string;

    private _redirectionService: IRedirectionService;

    constructor(
        flowManager: IInviteFlowManager,
        accSettings: VJHistoryFilterSettings,
        accountId: string,
        redirectionService: IRedirectionService,
        accGeneralSettings: GeneralSettings
    ) {
        this.formModel = new BasicFormModel(undefined, true, flowManager.translate);
        this.formController = new VJFormController(
            flowManager.translate,
            accSettings,
            this.formModel,
            this.onVJSessionsSearch.bind(this),
            {
                enablePhoneNumberField: false,
                enableCustomerRefField: true,
                enableEmailField: false,
                enableEndDate: true,
                enableStartDate: true
            },
            accGeneralSettings.reportsDateFormat
        );

        this._redirectionService = redirectionService;
        this.accountId = accountId;
    }

    onVJSessionsSearch(event: any) {
        event && event.preventDefault();

        if (!this.formController || this.formController.isLoading) {
            return;
        }

        const {formStartDateValue, formCustomerId} = this.formController;

        this.formController.setIsLoading(true);

        if (formStartDateValue || formCustomerId) {
            this.searchHistory()
                .then((dataFound) => {
                    this.formController.setFormError(!dataFound);
                })
                .catch(() => {
                    this.formController.setIsLoading(false);
                })
                .finally(() => {
                    this.formController.setIsLoading(false);
                });
        } else {
            this.formController.setIsLoading(false);
        }
    }

    async searchHistory(): Promise<boolean | void> {
        const startDateValue = this.formController.formStartDateValue
            ? new Date(this.formController.formStartDateValue)
            : undefined;
        const endDateValue = this.formController.formEndDateValue
            ? new Date(this.formController.formEndDateValue)
            : new Date();
        const customerId = this.formController.formCustomerId || undefined;

        startDateValue?.setHours(0, 0, 0, 0);
        endDateValue.setHours(23, 59, 59, 999);

        const response = await getRootStore().interactionSummaryService.listInteractionSummaries(
            this.accountId,
            customerId,
            startDateValue,
            endDateValue,
            undefined,
            undefined,
            SESSION_SOURCE.VJ
        );

        const {browserUtilsService} = getRootStore();
        const interactionSummaryVjPreviews = response.previews;

        if (interactionSummaryVjPreviews.length === 0) {
            this.formController.setFormError(true);

            return false;
        }

        browserUtilsService.saveToSessionStorage('interactionSummaryVJ', {
            interactionSummaryVjPreviews,
            startDateValue,
            endDateValue,
            customerId
        });

        this._redirectionService.goToVJHistory();

        this.formController.setIsLoading(false);

        return true;
    }
}
