'use strict';

import {reverse} from './reverse/reverse.filter.js';
import {tsBaseName} from './ts-basename/ts-basename.filter.js';
import {tsLinkify} from './ts-linkify/ts-linkify.filter.js';
import {trustResource} from './trust-resource/trust-resource.filter.js';
import {localeDate} from './locale-date/locale-date.filter.js';
import {removeExtension} from './remove-extension/remove-extension.filter.js';

export default angular
    .module('app.filters', [])
    .filter('reverse', reverse)
    .filter('ts_basename', tsBaseName)
    .filter('ts_linkify', tsLinkify)
    .filter('trust_resource', trustResource)
    .filter('locale_date', ['tsLocaleHelper', localeDate])
    .filter('remove_extension', removeExtension);
