'use strict';

export class SmsStatusNewUiController {
    constructor($modalInstance, title, message, dir, tsChatApi) {
        'ngInject';

        this.title = title;
        this.$modalInstance = $modalInstance;
        this.message = message;
        this.dir = dir;

        // Close dialog if mobile connected
        tsChatApi.service.on('clientConnected', () => {
            this.answer(true);
        });
    }

    answer(result) {
        this.$modalInstance.close({answer: result});
    }
}
