import React from 'react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {AgentGuidance} from '../agent-guidance/AgentGuidance';
import {ProgressList} from '../progress-list/ProgressList';

import './styles.scss';
import {IconSizes} from '@techsee/techsee-ui-common/lib/icons/large-rounded-icon';
import {InviteDeviceType} from '../../../_contracts/InviteContracts';

export interface GuidenceProgressItem {
    icon: string;
    iconColor: string;
    iconSize?: IconSizes;
    iconNoBorder?: boolean;
    isActive: boolean;
    isIconAnimated: boolean;
    label: string;
}

export interface GuidanceProgressProps {
    progressList: GuidenceProgressItem[];
    deviceType: InviteDeviceType;
    guidanceType: string;
    guidanceText: string;
}

export class GuidanceProgress extends TechseeBaseComponent<GuidanceProgressProps> {
    renderInternal() {
        const {progressList, deviceType, guidanceType, guidanceText} = this.props;

        return (
            <div className={'guidance-progress row'}>
                <div className={'col-left col-sm-4'}>
                    <ProgressList progressList={progressList} />
                </div>
                <div className={'col-left col-sm-4 mid-col'}>
                    <AgentGuidance
                        stepType={guidanceType}
                        stepNum={1}
                        deviceType={deviceType}
                        stepText={guidanceText}
                    />
                </div>
                <div className={'col-left col-sm-4'}>&nbsp;</div>
            </div>
        );
    }
}
