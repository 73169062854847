'use strict';

import urlParse from 'url-parse';
import forEach from 'lodash/forEach';
import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';
import defaults from 'lodash/defaults';
import isString from 'lodash/isString';
import map from 'lodash/map';
import last from 'lodash/last';

export class TsUrlUtilsService {
    constructor($rootScope, $location) {
        'ngInject';

        this.$rootScope = $rootScope;
        this.$location = $location;

        this.urlChangeListeners = [];
        this.queryChangeListeners = {};

        this.urlChangeFlag = false;
        this.evtPrefix = 'ts-url-utils-evt.';
    }

    /**
     * Setup a listener on URL changes
     */
    _urlChangeHandler() {
        this.urlChangeFlag = true;

        // On URL changes, execute registered listeners
        this.$rootScope.$on('$locationChangeSuccess', (e, newUrl, oldUrl, newState, oldState) => {
            forEach(this.urlChangeListeners, (listener) => {
                listener(e, newUrl, oldUrl, newState, oldState);
            });
        });
    }

    /**
     * Register a callback function to be called on URL changes
     *
     * @param  {Function} cb callback
     */
    onUrlChange(cb) {
        // Validate
        if (!isFunction(cb)) {
            throw new TypeError('`onUrlChange`: `cb` must be a function');
        }

        this.urlChangeListeners.push(cb);

        if (!this.urlChangeFlag) {
            this._urlChangeHandler();
        }
    }

    /**
     * Register a callback function to be called on a specific query param value changes
     * The listeners themselves will be destroyed once the given scope is destroyed by default
     *
     * @param  {Object}   scope The Angular scope that initiated the listener
     * @param  {string}   param The query param key to listen for changes on
     * @param  {Function} cb    Callback to execute with the new param value
     */
    onQueryChange(scope, param, cb) {
        // Validate
        if (!isObject(scope) || !isString(param) || !isFunction(cb)) {
            throw new TypeError('`onQueryChange`: Wrong arguments types');
        }

        // Subscribe to given param change events
        scope.$on(this.evtPrefix + param, (e, paramValue) => {
            cb(paramValue);
        });

        let listener = this.queryChangeListeners[param];

        // If the listener for this query param already exists, stop here
        if (listener) {
            return;
        }

        // Register a new listener for this query param
        this.queryChangeListeners[param] = listener = {};
        // Set initial value, to check later if it changed
        listener.currValue = this.getParamValue(param);

        // Listen to URL changes to check if the query param changed
        this.onUrlChange(() => {
            const paramValue = this.getParamValue(param);

            // Stop if the query param value didn't change
            if (listener.currValue === paramValue) {
                return;
            }

            listener.currValue = paramValue;

            // Notify all subscribers of the param change
            this.$rootScope.$broadcast(this.evtPrefix + param, paramValue);
        });
    }

    /**
     * Get the value of a specific query param
     *
     * @param  {string} param The query param to look for
     * @return {string}       The param value
     */
    getParamValue(param) {
        const queryParams = this.$location.search();

        return queryParams[param];
    }

    /**
     * Set the value of a specific query param
     *
     * @param  {string} param The query param to set/update
     */
    setParamValue(param, value) {
        this.$location.search(param, value);
    }

    /**
     * Set the value of multiple params simultaneously, to avoid multiple reloads
     *
     * @param  {Object} params An object with key/value pairs for the parameters to set/update
     * @param  {Boolean} replace If true, existing params are simply replaced, otherwise the
     *                           new ones are set, but existing params not supplied in the
     *                           object are preserved
     *
     */
    setParamValues(params, replace) {
        const oldParams = this.$location.search();
        const newParams = replace ? params : defaults(params, oldParams);
        const paramsArray = map(newParams, (value, key) => `${key}=${value}`);

        this.$location.search(paramsArray.join('&'));
    }

    tryDecodeUri(uri) {
        try {
            return decodeURIComponent(uri).trim();
        } catch (e) {
            return uri;
        }
    }

    extractFileName(url) {
        const parsedUrl = url && urlParse(url, true);

        return parsedUrl && last(parsedUrl.pathname.split('/'));
    }
}
