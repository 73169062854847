'use strict';
import tsDashboardTopBarView from './ts-dashboard-top-bar.view.html';
import './ts-dashboard-top-bar.style.scss';

export function tsDashboardTopBar() {
    return {
        template: tsDashboardTopBarView,
        scope: true
    };
}
