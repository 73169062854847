import {action, computed, observable} from 'mobx';

export interface IGalleryController {
    readonly images: Image[];
}
export type Image =
    | {
          image?: undefined;
          id: string;
          isLoading: true;
          isFailed?: undefined;
      }
    | {image: string; id: string; isLoading?: undefined; isFailed?: undefined}
    | {image?: undefined; id: string; isLoading?: undefined; isFailed: true};

export class GalleryController implements IGalleryController {
    @observable
    private _images: Image[] = [];

    @computed
    public get images(): Image[] {
        return this._images;
    }

    @action
    public addImage = (id: string, image: string) => {
        this._images = [...this._images.filter((item) => item.id !== id), {image, id}];
    };

    @action
    public addImageLoadingPlaceholder = (id: string) => {
        this._images = [...this._images.filter((item) => item.id !== id), {id, isLoading: true}];
    };

    @action
    public addImageFailedPlaceholder = (id: string) => {
        this._images = [...this._images.filter((item) => item.id !== id), {id, isFailed: true}];
    };
}
