import React from 'react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import ScrollableContent from '@techsee/techsee-ui-common/lib/scrollable-content';
import {getRootStore} from '../../../../../app.bootstrap';

import './styles.scss';

export interface IAgentHelper {
    children: React.ReactNode | string;
}

export default class AgentHelper extends TechseeBaseComponent<IAgentHelper> {
    renderInternal() {
        const {children} = this.props;

        const {localizationService} = getRootStore();

        return (
            <ScrollableContent className={'agent-helper-view'} height='calc(100vh - 240px)' width='25vw'>
                <div className='agent-helper' dir={localizationService.getTextDirection()}>
                    <div className='agent-helper__inner'>{children}</div>
                </div>
            </ScrollableContent>
        );
    }
}
