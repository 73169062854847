import React from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {InfoBox} from '@techsee/techsee-ui-common/lib/info-box';
import {ITranslate} from '../../../../services/LocalizationService';
import {observer} from 'mobx-react';
import {SimpleLabel} from '@techsee/techsee-ui-common/lib/forms/labels/simple';
import {PLACEMENT_TYPE} from '@techsee/techsee-ui-common/lib/popover-panel';
import {locales} from '@techsee/techsee-common/lib/helpers/i18n.settings';
import find from 'lodash/find';
import {getRootStore} from '../../../../app.bootstrap';

export interface IInfoBox {
    translate: ITranslate;
    upperTitle: string;
    upperData: string;
    bottomTitle: string;
    bottomData: string;
    iconName: string;
    displayHeaders: boolean;
    clientLanguage?: string;
}

@observer
export class InfoBoxComponent extends TechseeBaseComponent<IInfoBox> {
    renderInternal() {
        const {translate} = this.props,
            {clientLanguage} = this.props,
            isBottomData = this.props.bottomData && this.props.bottomData !== 'none',
            isUpperData = this.props.upperData && this.props.upperData !== 'none';
        let UpperLine = () => <div></div>;
        let BottomLine = () => <div></div>;
        let BottomElement = () => <div></div>;
        const {localizationService} = getRootStore();

        if (this.props.displayHeaders && this.props.bottomTitle && isBottomData) {
            BottomLine = () => (
                <div className={'same-line'}>
                    <SimpleLabel>{translate(this.props.bottomTitle)}: </SimpleLabel>
                    <SimpleLabel className={'bold-label padding-left-5'}> {this.props.bottomData}</SimpleLabel>
                </div>
            );
        } else if (isBottomData) {
            BottomLine = () => (
                <SimpleLabel className={'bold-label same-line'}>{translate(this.props.bottomData)}</SimpleLabel>
            );
        }

        if (this.props.displayHeaders && this.props.upperTitle && isUpperData) {
            UpperLine = () => (
                <div className={'same-line'} dir={localizationService.getTextDirection()}>
                    <SimpleLabel> {translate(this.props.upperTitle)}: </SimpleLabel>
                    <SimpleLabel className={'bold-label padding-left-5 same-line'}> {this.props.upperData}</SimpleLabel>
                </div>
            );
        } else if (isUpperData) {
            UpperLine = () => (
                <SimpleLabel className={'bold-label same-line'}>{translate(this.props.upperData)}</SimpleLabel>
            );
        }

        if (clientLanguage) {
            const lang = find(locales, {value: clientLanguage});

            BottomElement = () => (
                <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement={PLACEMENT_TYPE.BOTTOM}
                    overlay={this.getLangTooltip(lang ? lang.name : '')}>
                    <div className={'language'}>{lang ? lang.label : ''}</div>
                </OverlayTrigger>
            );
        }

        return (
            <div>
                <InfoBox
                    className={'info-box'}
                    upperLine={UpperLine}
                    bottomLine={BottomLine}
                    icon={this.props.iconName}
                    bottomElement={BottomElement}></InfoBox>
            </div>
        );
    }

    getLangTooltip(languageName: string) {
        return (
            <Tooltip className={'language-tooltip'} id={'language-tooltip'}>
                {this.props.translate('REACT.INVITE.VIEW.TOPBAR.LANGUAGE', {language: languageName})}
            </Tooltip>
        );
    }
}
