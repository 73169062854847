import {IDomUtilsService} from '@techsee/techsee-client-infra/lib/services/DomUtilsService';
import {InvitationEndedController, IInvitationEndedController, InvitationEndedSessionTypes} from './controller';
import {getRootStore} from '../../app.bootstrap';
import {getDashboardLanguage} from '../helper/general.helper';
// @ts-ignore
import InvitationEndedStateView from './invitation-ended.view.html';
import {getAppTracer} from '../../../app.tracer';
const trace = getAppTracer('Invitation-Ended.Module');

class InvitationEndedStateController {
    private readonly _domUtilsService: IDomUtilsService;

    private invitationEndedController: IInvitationEndedController | undefined;

    static $inject = ['tsUrlUtils', 'tsStateHelper', 'accountData'];

    constructor(tsUrlUtils: any, tsStateHelper: any, accountData: any) {
        this._domUtilsService = getRootStore().domUtilsService;

        const localizationService = getRootStore().localizationService;
        const isInitialized = localizationService.isInitialized();

        const localServiceInitPromise = [Promise.resolve()];

        if (!isInitialized) {
            const accountLanguage = getDashboardLanguage(accountData);

            localizationService.setAccountData(accountData?._id, accountLanguage);
            localServiceInitPromise.push(localizationService.init());
        }

        Promise.all(localServiceInitPromise)
            .catch((err) => {
                trace.error('Translation of the invitation ended page failed: ' + err?.message, err);
            })
            .finally(() => {
                this.initController(tsUrlUtils, tsStateHelper, localizationService);
            });
    }

    initController(tsUrlUtils: any, tsStateHelper: any, localizationService: any) {
        const translate = localizationService.translate;
        const expired = tsUrlUtils.getParamValue('err');
        const sessionType = tsUrlUtils.getParamValue('sessionType');
        const sessionState = {
            ended: true,
            isExpired: expired,
            sessionType: sessionType ? sessionType : InvitationEndedSessionTypes.regular
        };
        const goToInvitePage =
            sessionType === InvitationEndedSessionTypes.warmTransfer
                ? () => {
                      tsStateHelper.safeGo('invite');
                  }
                : () => {};

        this.invitationEndedController = new InvitationEndedController(
            translate,
            this._domUtilsService,
            sessionState,
            goToInvitePage
        );
    }
}

declare const angular: any;

declare const ROUTE_BASE_PATH: string;

const invitationExpiredView = {
    template: InvitationEndedStateView,
    controller: 'InvitationEndedStateController',
    controllerAs: 'ended'
};

export default angular
    .module('states.invitationEnded', [])
    .config(['$stateProvider', config])
    .controller('InvitationEndedStateController', InvitationEndedStateController);

function config($stateProvider: any) {
    $stateProvider.state('invitationEnded', {
        url: ROUTE_BASE_PATH + 'invitationEnded?err&sessionType',
        views: {
            'desktop-view': invitationExpiredView,
            'mobile-view': invitationExpiredView
        },
        resolve: {
            // Expose the current user data
            currentUser: () =>
                getRootStore()
                    .dbUserService.find('current')
                    .catch((err) => {
                        trace.error('Error while getting the current user: ' + err?.message, err);
                    }),
            // Expose the associated user account data, if he is associated
            accountData: [
                'currentUser',
                (currentUser: any) => {
                    if (!currentUser || !currentUser.accountId) {
                        return {};
                    }

                    return getRootStore()
                        .dbAccountService.find(currentUser.accountId)
                        .catch((err) => {
                            trace.error('Error while getting the current account: ' + err?.message, err);
                        });
                }
            ]
        }
    });
}
