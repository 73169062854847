'use strict';

export class TsModalImagePopupController {
    constructor($modalInstance, imageData) {
        'ngInject';

        this.$modalInstance = $modalInstance;
        this.data = imageData;
    }

    close() {
        this.$modalInstance.close();
    }
}
