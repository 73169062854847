import {Nullable} from '@techsee/techsee-ui-common/lib/_shared/reusable-types';

export class OperationResult<T = object> {
    get isSuccess(): boolean {
        return this.code === 0;
    }

    get isFailure(): boolean {
        return this.code !== 0;
    }

    code: number = 0;

    message: string = '';

    data?: Nullable<T> = null;

    static getSuccessResult<T = object>(data?: Nullable<T>, message?: string): OperationResult<T> {
        const result = new OperationResult<T>();

        result.code = 0;
        result.message = message || '';
        result.data = data;

        return result;
    }

    static getFailResult<T = object>(message?: string, data?: Nullable<T>, code?: number): OperationResult<T> {
        const result = new OperationResult<T>();

        result.code = code || 1;
        result.message = message || '';
        result.data = data;

        return result;
    }
}
