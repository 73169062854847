import {inject, IReactComponent} from 'mobx-react';
import {ChangeNumber} from './component';

import './styles.scss';

const mapStoresToProps = (stores: any) => ({
    ctrl: stores.changeNumberController,
    translate: stores.localizationService.translate.bind(stores.localizationService)
});

const ChangeNumberComponent = inject(mapStoresToProps)(ChangeNumber) as IReactComponent;

export default ChangeNumberComponent;
