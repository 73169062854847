'use strict';

import TsUtils from '@techsee/techsee-common/lib/utils';

/**
 * Add auth token headers to each request
 * Expects the token to be saved in LocalStorage
 */

export function TokenInterceptor($localStorage, $injector, tsUrlConfig, $window) {
    'ngInject';

    const adminBackendUrl = TsUtils.getAdminBackendUrl(API_URL, $window.location.hostname);
    const serviceBackendUrl = TsUtils.getServiceBackendUrl(API_URL, $window.location.hostname);

    function isExternalRequest(url) {
        return (
            url.indexOf('http') === 0 &&
            url.indexOf(tsUrlConfig.get('API_URL')) < 0 &&
            url.indexOf(adminBackendUrl) < 0 &&
            url.indexOf(serviceBackendUrl) < 0
        );
    }

    function request(config) {
        if (isExternalRequest(config.url)) {
            // Prevent headers modifications for request to 3rd party services
            return config;
        }

        const auth = $injector.get('auth');

        const token = auth.getAuthToken();

        if (token) {
            config.headers = config.headers || {};
            config.headers.Authorization = 'Bearer ' + token;
        }

        return config;
    }

    function responseError(resp) {
        if (resp.config && resp.config.url && isExternalRequest(resp.config.url)) {
            return resp;
        }

        if (resp.status === 401) {
            const $state = $injector.get('$state');
            const authExpiry = $injector.get('authExpiry');

            if (
                (authExpiry.service.isNotificationEnabled && resp.data.expired) ||
                authExpiry.service.isloginModalVisible
            ) {
                authExpiry.service.setSessionAsExpired();
            } else if (!$state.current.name.match(/login\./)) {
                $state.go('login');
            }
        }

        return resp;
    }

    return {
        request: request,
        responseError: responseError
    };
}
