import {OperationResult} from '../../models/OperationResult';
import {ClientState} from '../../states/invite-new/_contracts/ClientState';
import {MeetingMode, UserType, AddressTypesEnum} from '@techsee/techsee-common/lib/constants/room.constants';
import {NetworkInfo} from '../../models/LiveSessionState';
import {VideoFilterType} from '@techsee/techsee-common/lib/constants/account.constants';

export type SubscriptionDisposer = () => void;
export type DataListener<T> = (data: T) => void;
export type NotificationListener = () => void;

export enum ConnectionStatesEnum {
    HIGH = 'HIGH',
    LOW = 'LOW',
    OFFLINE = 'OFFLINE'
}

export type ConnectionState = ConnectionStatesEnum.HIGH | ConnectionStatesEnum.LOW | ConnectionStatesEnum.OFFLINE;

export enum ConnectionTypesEnum {
    NETWORK = 'NETWORK',
    MOBILE_DATA = 'MOBILE',
    WIFI = 'WIFI',
    CELLULAR = 'CELLULAR'
}

export type ConnectionType =
    | ConnectionTypesEnum.MOBILE_DATA
    | ConnectionTypesEnum.WIFI
    | ConnectionTypesEnum.NETWORK
    | ConnectionTypesEnum.CELLULAR;

export interface DeviceDetails {
    osName: string;
    osVersion: string;
    clientType: string;
    clientDevice: string;
    clientBrowser: string;
    usingApplication: boolean;
}

export interface SessionAccSettings {
    allowOneClickPhotoMode: boolean;
}

export interface StartSessionParams {
    customerId?: string;
    clientLanguage: string;
    customerNumber?: string;
    customerEmail?: string;
    userType: UserType;
    mobileClientURL: string;
    offline: boolean;
    startWithAgentType?: MeetingMode;
    isDesktopSharing: boolean;
    audio?: boolean;
    video?: boolean;
    measure?: boolean;
    referralRegion?: string;
    hdEnabled: boolean;
    videoFilterType?: VideoFilterType;
    startUrlCobrowsing?: string;
}

export interface JoinSessionParams {
    roomId: string;
    sessionId: string;
    sessionUrl: string;
    userType: UserType;
    byShortId: boolean;
}

export interface SessionConnectResult {
    sessionRoomId: string;
    sessionShortId: string;
    sessionLinkUrl: string;
    prefixLength: number;
}

export interface SocketVersionConfig {
    namespace: string;
    path: string;
}

export interface ISessionService {
    clientState: ClientState;

    startSession(params: StartSessionParams): Promise<OperationResult<SessionConnectResult>>;

    joinSession(params: JoinSessionParams): Promise<OperationResult<SessionConnectResult>>;

    joinBySessionId(sessionId: string): Promise<OperationResult<SessionConnectResult>>;

    endSession(keepRoom?: boolean): Promise<void>;

    repromptClientForTos(): void;

    updateCustomerInfo(address: string, addressType: AddressTypesEnum): void;

    onClientConnect(listener: NotificationListener): SubscriptionDisposer;

    onClientDeviceDetails(listener: DataListener<DeviceDetails>): SubscriptionDisposer;

    onDesktopSharingUnsupported(listener: NotificationListener): SubscriptionDisposer;

    onSocketVersionChanged(listener: DataListener<SocketVersionConfig>): SubscriptionDisposer;

    onClientNetworkInfo(listener: DataListener<NetworkInfo>): SubscriptionDisposer;

    onClientDisconnect(listener: NotificationListener): SubscriptionDisposer;

    onClientEndedMeeting(listener: NotificationListener): SubscriptionDisposer;

    onClientUnsupportedDevice(listener: NotificationListener): SubscriptionDisposer;

    onClientTosView(listener: NotificationListener): SubscriptionDisposer;

    onClientTosResult(listener: DataListener<boolean>): SubscriptionDisposer;

    onClientPreCameraView(listener: NotificationListener): SubscriptionDisposer;

    onClientPreCameraResult(listener: DataListener<boolean>): SubscriptionDisposer;

    onClientCameraView(listener: NotificationListener): SubscriptionDisposer;

    onScreenShareCapturingPermission(listener: NotificationListener): SubscriptionDisposer;

    onChannelDisconnect(listener: NotificationListener): SubscriptionDisposer;

    onHandshakeSuccess(listener: DataListener<string>): SubscriptionDisposer;

    onVideoApplicationCameraAudioDialog(listener: NotificationListener): SubscriptionDisposer;

    onForceTimeout(listener: NotificationListener): SubscriptionDisposer;
}
