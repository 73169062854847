/* eslint-disable indent */
/* eslint-disable max-params */
'use strict';

/* eslint-disable consistent-return */
import get from 'lodash/get';
import includes from 'lodash/includes';
import filter from 'lodash/filter';
import forEach from 'lodash/forEach';
import each from 'lodash/each';
import some from 'lodash/some';
import isEmpty from 'lodash/isEmpty';
import findLast from 'lodash/findLast';
import find from 'lodash/find';
import assign from 'lodash/assign';
import cloneDeep from 'lodash/cloneDeep';
import map from 'lodash/map';

import {MESSAGE_TIMEOUT, SESSION_TYPES} from './invite.settings.js';

import changeSettingsView from './dialogs/change-settings.view.html';
import changeAccountView from './dialogs/change-account.view.html';
import smsStatusView from './dialogs/sms-status.view.html';
import {SMSStatusController} from './dialogs/sms-status.controller.js';
import {ConnectivityCheckModalController} from '../../../_react_/states/connectivity/connectivity-check-modal/connectivityCheckModal.controller';
import acceptTermsView from './dialogs/accept-terms.view.html';
import {AcceptTermsController} from './dialogs/accept-terms.controller.js';
import {MediaTypes} from '@techsee/techsee-common/lib/constants/account.constants';
import {InviteStartedFrom, PlatformType} from '@techsee/techsee-common/lib/constants/utils.constant';
import {
    MeetingMode,
    AddressTypesEnum,
    ReasonForClosing,
    UserType,
    FlowType
} from '@techsee/techsee-common/lib/constants/room.constants';
import {getRootStore} from '../../../_react_/app.bootstrap';
import {StartWithModesController} from '../../../_react_/components/start-with-modes/controller';
import {AgentPerformanceController} from '../../../_react_/components/agent-performance/controller';
import {LookupNumberController} from '../../../_react_/states/invite-new/invite-wizard/components/lookup-number/controller';
import {ServiceEvents} from '../../../_react_/services/AccountSocket/AccountSocketEvents';
import {sendSentryLog} from '../../../error-reporting';
import {SelectVideoPublisherController} from '../../../_react_/components/select-video-publisher/controller';
import {SimpleModalController} from '../../../_react_/components/simple-modal/controller';

export class InviteController {
    // eslint-disable-next-line complexity
    constructor(
        $window,
        $state,
        tsStateHelper,
        db,
        currentUser,
        accountData,
        patternsConstant,
        focus,
        $anchorScroll,
        $location,
        auth,
        tsUrlUtils,
        tsVideoUtils,
        $translate,
        tsLocaleHelper,
        eventLog,
        $modal,
        $timeout,
        $scope,
        tsHelpBrowser,
        ROLES,
        $q,
        LogEvents,
        tsUrlConfig,
        tsTranslationHelper,
        tsInterfaceHelper,
        $localStorage,
        $rootScope,
        SmsStatusNewUi,
        tsSmsService,
        tsQuickLauncher,
        isUserPermittedToGetOfflineAlerts
    ) {
        'ngInject';

        this.$window = $window;
        this.$state = $state;
        this.message = this.$state.params.message;
        this.stateHelper = tsStateHelper;
        this.db = db;
        this.$q = $q;
        this.$anchorScroll = $anchorScroll;
        this.$location = $location;
        this.currentUser = currentUser;
        this.isMultiAccountsUser = this.currentUser.parentSubUserId;
        this.accountData = accountData;
        this.mobilePhonePattern = patternsConstant.mobilePhone;
        this.phonePattern = patternsConstant.phone;
        this.auth = auth;
        this.tsUrlUtils = tsUrlUtils;
        this.videoUtils = tsVideoUtils;
        this.eventLog = eventLog;
        this.LogEvents = LogEvents;
        this.urlConfig = tsUrlConfig;
        this.eventLog.userId = this.currentUser._id;
        this.$translate = $translate;
        this.translate = getRootStore().localizationService.translate;
        this.$modal = $modal;
        this.$timeout = $timeout;
        this.helpBrowser = tsHelpBrowser;
        this.roles = ROLES;
        this.sessionTypes = SESSION_TYPES;
        this.inviteMethods = AddressTypesEnum;
        this.tsInterfaceHelper = tsInterfaceHelper;
        this.$rootScope = $rootScope;
        this.$localStorage = $localStorage;
        this.requireImage = $scope.requireImage;

        const {
            browserUtilsService,
            accountSocketService,
            authExpiryService,
            localizationService,
            dbReportsService,
            eventsLogService,
            displayTabletAsDesktop,
            environmentService
        } = getRootStore();

        this.browserUtilsService = browserUtilsService;
        this.accountSocketService = accountSocketService;
        this.environmentDetect = environmentService;
        this.displayTabletAsDesktop = displayTabletAsDesktop;
        this.isMobileDevice = this.environmentDetect.isMobile(this.displayTabletAsDesktop);
        this.isPhone = this.environmentDetect.isPhone();
        this.SmsStatusNewUi = SmsStatusNewUi;
        this.tsQuickLauncher = tsQuickLauncher;
        this.canCurrentUserBeRedirectToAdmin = !includes(
            [ROLES.TECHNICIAN, ROLES.TECHNICIAN_SUPERVISOR],
            this.currentUser.role
        );
        this.meetingModes = MeetingMode;
        this.isUserPermittedToGetOfflineAlerts = isUserPermittedToGetOfflineAlerts;
        this.isOverlayOpen = false;
        this.isLive3 = get(accountData, 'protectedSettings.v3.enabled');

        const translate = localizationService.translate;

        if (
            get(accountData, 'protectedSettings.enableAgentPerformance') &&
            [ROLES.USER, ROLES.MANAGER].includes(this.currentUser.role) &&
            !this.browserUtilsService.getFromSessionStorage('shouldNotDisplayAgentPerformance')
        ) {
            this.agentPerformanceController = new AgentPerformanceController(
                translate,
                dbReportsService,
                this.currentUser.role === ROLES.USER,
                this.browserUtilsService,
                authExpiryService
            );

            this.agentPerformanceController.setPosition({x: 250, y: 380});
            this.agentPerformanceController.setElementWrapper('ts-main');
            this.agentPerformanceController.init();
        }

        if (get(accountData, 'protectedSettings.smsSettings.smsUseNumberLookup')) {
            this.numberLookupModalCtrl = new LookupNumberController(
                translate,
                eventsLogService,
                get(accountData, 'protectedSettings.keepCustomerMobile')
            );
        }

        this.smsService = tsSmsService;
        this.smsService.init(
            db,
            get(accountData, 'protectedSettings.smsSettings'),
            accountData._id,
            currentUser._id,
            get(accountData, 'protectedSettings.keepCustomerMobile')
        );

        this.lastDeclineTOSCustomerNumber = $localStorage.lastDeclineTOSCustomerNumber;
        $localStorage.lastDeclineTOSCustomerNumber = undefined;

        this.lastUsedTOSNumberWithoutCountryCode = $localStorage.lastUsedTOSNumberWithoutCountryCode;
        $localStorage.lastUsedTOSNumberWithoutCountryCode = undefined;

        this.lastDeclineTOSCustomerId = $localStorage.lastDeclineTOSCustomerId;
        $localStorage.lastDeclineTOSCustomerId = undefined;

        this.lastUsedTOSCountryCode = $localStorage.lastUsedTOSCountryCode;
        $localStorage.lastUsedTOSCountryCode = undefined;

        this.ssoAuthId = this.$state.params.authId;

        this.isStartedFromLauncher = this.$state.params.sf === InviteStartedFrom.DESKTOP_LAUNCHER;
        const allowExternalCustomerDetails = get(this.accountData, 'protectedSettings.allowExternalCustomerDetails');
        const enableQuickLaunch = get(this.accountData, 'protectedSettings.enableQuickLaunch');
        const allowAutoFillSessionFields =
            allowExternalCustomerDetails || (enableQuickLaunch && this.isStartedFromLauncher);

        if (allowAutoFillSessionFields) {
            if (this.$state.params.r) {
                this.preloadCustomerReference = this.tsUrlUtils.tryDecodeUri(this.$state.params.r);
            }

            if (this.$state.params.p) {
                this.preloadCustomerNumber = this.tsUrlUtils.tryDecodeUri(this.$state.params.p);
            }

            if (this.$state.params.c) {
                this.preloadCountryCode = this.tsUrlUtils.tryDecodeUri(this.$state.params.c);
            }

            if (this.isStartedFromLauncher) {
                $timeout(() => {
                    this.submit(this.inviteForm);
                });
            }
        }

        this.updateStartWithMode = this.updateStartWithMode.bind(this);

        this.selectVideoPublisherController = new SelectVideoPublisherController(translate);

        this.unauthorizedModalCtrl = new SimpleModalController(
            'unauthorized-modal',
            '',
            translate('INVITE.VIEW.UNAUTHORIZED_ERROR_CONTENT'),
            '',
            translate('INVITE.VIEW.UNAUTHORIZED_ERROR_BUTTON')
        );

        this.startWithModesEnabled =
            this.tsInterfaceHelper.useNewInterface &&
            get(this.accountData, 'protectedSettings.startWithModes.enabled') &&
            !this.isMobileDevice;

        if (this.startWithModesEnabled) {
            this.startWithModesController = new StartWithModesController(translate, this.updateStartWithMode);

            const modesOptions = get(this.accountData, 'protectedSettings.startWithModes.modesOptions');
            const defaultMode = get(this.accountData, 'protectedSettings.startWithModes.defaultMode');
            const defaultStartWithMode = find(modesOptions, (startWithMode) => startWithMode.mode === defaultMode);
            const startWithModes = filter(modesOptions, (startWithMode) => startWithMode.mode !== defaultMode);

            startWithModes.unshift(defaultStartWithMode);

            this.startWithModesController.setConfig({
                offlineEnabled: get(this.accountData, 'settings.accountOfflineSessions')
            });

            this.startWithModesController.setAvailableMeetingModes(startWithModes);
            this.startWithModesController.setSelectedMeetingMode(defaultMode);
        }

        this.allowSendByEmail = this.accountData.settings ? this.accountData.settings.allowSendByEmail : true;
        this.enableFieldServices = this.accountData.protectedSettings
            ? this.accountData.protectedSettings.enableFieldServices
            : true;
        this.supportInviteByCode = this.accountData.protectedSettings
            ? this.accountData.protectedSettings.supportInviteByCode
            : false;
        this.fsInviteByTechsee = get(this.accountData, 'settings.fsSettings.fsInviteByTechsee');
        this.fsInviteBySMS = get(this.accountData, 'settings.fsSettings.fsInviteBySMS');
        this.fsInviteByWhatsApp = get(this.accountData, 'settings.fsSettings.fsInviteByWhatsApp');
        this.fsInviteByEmail = get(this.accountData, 'settings.fsSettings.fsInviteByEmail');
        this.clientStartURL = TsUtils.replaceUrlDomain(CLIENT_START_URL, this.$window.location.hostname);
        this.enableLinkToAdmin =
            get(this.accountData, 'protectedSettings.enableLinkToAdmin') && this.canCurrentUserBeRedirectToAdmin;
        this.enableLinkToConnectivityCheck =
            get(this.accountData, 'protectedSettings.connectivityCheck.enable') &&
            includes(get(this.accountData, 'protectedSettings.connectivityCheck.platform'), PlatformType.dashboard);
        this.chromeDetection = this.accountData.protectedSettings
            ? this.accountData.protectedSettings.chromeDetection
            : false;
        this.addCountryCodeToSms =
            get(this.accountData, 'protectedSettings.smsSettings.addCountryCodeToSms') && !this.isStartedFromLauncher;
        this.accountCountryCode = get(this.accountData, 'settings.countryCode');

        if (this.addCountryCodeToSms) {
            this.selectedCountries = get(this.accountData, 'protectedSettings.selectedCountries');

            this.$translate('INVITE.VIEW.COUNTRY_CODES_PLACEHOLDER').then((m) => (this.countryCodesPlaceHolder = m));

            this.$translate('INVITE.VIEW.COUNTRY_CODES_NO_MATCH').then((m) => (this.countryCodesNoMatch = m));
        }

        this.showSmsPreview = this.accountData.settings ? this.accountData.settings.showSmsPreview : true;
        this.cameraModeOnly = this.accountData.settings ? this.accountData.settings.cameraModeOnly : false;
        this.availableStartingModes = this.getAvailableStartingModes();
        this.enableHistorySearch = this.isEligibleForHistorySearch();
        this.terms = this.accountData.protectedSettings ? this.accountData.protectedSettings.terms : {};
        this.allowInviteBySms = this.accountData.settings ? this.accountData.settings.allowInviteBySms : true;
        this.allowSessionWithoutSms = this.accountData.settings
            ? this.accountData.settings.allowSessionWithoutSms
            : true;
        this.accountOfflineSessions = this.accountData.settings
            ? this.accountData.settings.accountOfflineSessions
            : false;
        this.allowSearchOfflineSessionsById = get(this.accountData, 'protectedSettings.allowSearchOfflineSessionsById');
        this.allowSelfServiceOfflineSessions = get(
            this.accountData,
            'protectedSettings.allowSelfServiceOfflineSessions'
        );
        this.acceptedTerms = this.currentUser.acceptedTerms;
        this.csiEanbled = get(this.accountData, 'protectedSettings.enableClientSessionInitiation');
        this.keepCustomerMobile = get(this.accountData, 'protectedSettings.keepCustomerMobile');
        this.allowScreenShare =
            get(this.accountData, 'protectedSettings.liveSdk.enabled') &&
            includes(this.accountData.protectedSettings.liveSdk.sharingType, 'screen');
        this.allowApplicationShare =
            get(this.accountData, 'protectedSettings.liveSdk.enabled') &&
            includes(this.accountData.protectedSettings.liveSdk.sharingType, 'appSharing');

        this.allowCoBrowsing = get(this.accountData, 'protectedSettings.coBrowsing.enabled');
        this.coBrowsingStartUrl = this.allowCoBrowsing && get(accountData, 'protectedSettings.coBrowsing.defaultURL');
        this.enabledSessionTypes =
            this.accountOfflineSessions ||
            this.allowScreenShare ||
            this.allowApplicationShare ||
            this.allowCoBrowsing ||
            !this.checkIfIsAvailableMode(this.sessionTypes.LIVE);

        this.sessionParams = this._getDefaultSessionParams();
        this._storeInviteMethod(null);
        this.$window.onbeforeunload = () => undefined; // eslint-digit sable-line

        // Show invite method radio buttons when offline is enabled and at least two
        // invite methods are available. If no invite methods are available, the
        // start button will be displayed.
        this.showInviteMethodSelection =
            this.enabledSessionTypes &&
            (this.allowInviteBySms
                ? this.allowSendByEmail || this.allowSessionWithoutSms
                : this.allowSendByEmail && this.allowSessionWithoutSms);

        this.phoneRequired = true;
        const dashboardConnectivityCheckOnFirstLogin = get(
            this.accountData,
            'protectedSettings.connectivityCheck.dashboardConnectivityCheckOnFirstLogin'
        );

        if (this.enableLinkToConnectivityCheck && dashboardConnectivityCheckOnFirstLogin) {
            const translate = localizationService.translate;

            if (!get(this.currentUser, 'ranConnectivityTests')) {
                this.db.User.updateRanConnectivityTests().finally(() => {
                    this.connectivityCheckModalController = new ConnectivityCheckModalController(translate);

                    this.connectivityCheckModalController.show();
                });
            }
        }

        const accountLanguage = accountData.settings ? accountData.settings.language : null;

        tsTranslationHelper
            .storeAccountCustomStrings(this.accountData._id)
            .then(() => tsLocaleHelper.loadLocale(accountLanguage, accountData.companyName));

        this.dir = tsLocaleHelper.isRTL() ? 'rtl' : 'ltr';

        this.isCurrentUserATechnicianSupervisor = this.isTechnicianSupervisor();

        if (this.isCurrentUserATechnicianSupervisor) {
            this.accountData.settings.logoUrl = $scope.requireImage('tech-sup-logo.png');
        } else if (this.accountData.settings && !this.accountData.settings.logoUrl) {
            this.accountData.settings.logoUrl = $scope.requireImage('new-logo.png');
        }

        this.topbarTitle = this.isCurrentUserATechnicianSupervisor
            ? 'INVITE.VIEW.VISUAL_SUPPORT_TS'
            : 'INVITE.VIEW.VISUAL_SUPPORT';

        if (this.isSysOrSuperAdmin()) {
            this.db.System.version().then((response) => {
                this.version = response.data.release;
            });
        }

        this.observableUsers = [];
        this.activeTechnicians = [];

        if (this.currentUser.canObserve) {
            this.retrieveObservableRooms();
        }

        if (this.isCurrentUserATechnicianSupervisor) {
            this.retrieveActiveTechnicians();
        }

        this.showWizardSetting =
            this.accountData &&
            this.accountData.settings &&
            this.accountData.settings.showWizard &&
            !this.isMobileDevice;
        this.showWizard = this.showWizardSetting && this.currentUser.showWizard;

        this.tooltipPlacement = this.dir === 'rtl' ? 'left' : 'right';
        this.tooltipPlacement = this.isMobileDevice ? 'top' : this.tooltipPlacement;
        this.audioConfig = this._getAudioConfig();

        this.displayStartWithAudio = this._shouldAllowAudio() && this.audioConfig && this.audioConfig.enableAudio;
        this.enableAudio = this.displayStartWithAudio && this.audioConfig.startSessionWithAudio;
        this.toggleAudio = this.toggleAudio.bind(this);
        this.multiSelectSessionTypes = this.getSessionTypesForMultiSelect();
        this.inviteViaMethods = this._getInviteViaMethods();

        if (this.isMobileDevice && this.fsInviteByTechsee) {
            this.toggleTechseeInvite();
        }

        this.sms = false;
        this.room = null;

        this.timeouts = [];
        this.historySearchResults = {
            byId: {images: 0, videos: 0, messages: 0, files: 0},
            byNumber: {images: 0, videos: 0, messages: 0, files: 0}
        };

        $scope.$on('$viewContentLoaded', () => {
            // make the code execute on the next cycle
            this.$timeout(() => {
                this.eventLog.dashboardInviteViewLoaded({authId: this.ssoAuthId});

                if (this.ssoAuthId) {
                    this.db.SSO.dashboardInviteViewLoaded(this.ssoAuthId);
                }
            }, 0);
        });

        $scope.$on('$destroy', () => {
            this.smsService.cleanup();

            forEach(this.timeouts, (timeout) => this.$timeout.cancel(timeout));

            this.timeouts = [];
        });

        // Hide message after 5 seconds
        this.timeoutMessage();

        if (this.terms.display && !this.acceptedTerms) {
            this.acceptTerms().then(
                () => {
                    focus('invite-phone');

                    this.acceptedTerms = true;
                    this.db.User.acceptTerms();
                },
                () => this.logout()
            );
        } else {
            focus('invite-phone');
        }

        if (
            this.currentUser.role === this.roles.SYSADMIN ||
            this.currentUser.role === this.roles.SUPERADMIN ||
            this.currentUser.role === this.roles.TECHNICIAN ||
            this.currentUser.role === this.roles.LITE_ADMIN
        ) {
            this.isForbiddenRole = true;
        }

        const errorUrlParam = this.browserUtilsService.getParamValue('error');
        const unauthorizedError = errorUrlParam && errorUrlParam.indexOf('422') > -1;

        if (unauthorizedError && this.unauthorizedModalCtrl) {
            this.unauthorizedModalCtrl.show();
        } else if ($state.params.error) {
            const errorCode = parseInt($state.params.error, 10);

            // reset the param since, otherwise the error will keep appearing
            this.tsUrlUtils.setParamValue('error', '');
            this._showErrorMessage({status: errorCode});
        }

        if (
            !this.isForbiddenRole &&
            get(this.accountData, 'settings.multiLanguage') &&
            get(this.accountData, 'settings.clientLanguages')
        ) {
            this.initLanguage();
        }

        $scope.$watch(
            () => this.inviteForm,
            () => {
                if (!this.inviteForm) {
                    return;
                }

                if (this.lastUsedTOSNumberWithoutCountryCode && this.lastUsedTOSNumberWithoutCountryCode !== 'none') {
                    this.inviteForm.phone = this.lastUsedTOSNumberWithoutCountryCode;

                    if (this.addCountryCodeToSms && this.lastUsedTOSCountryCode) {
                        this.countryCodeText = this.preloadCountryCode || this.lastUsedTOSCountryCode;
                    }
                } else if (this.lastDeclineTOSCustomerNumber && this.lastDeclineTOSCustomerNumber !== 'none') {
                    this.inviteForm.phone = this.lastDeclineTOSCustomerNumber;

                    if (this.addCountryCodeToSms && this.lastUsedTOSCountryCode) {
                        this.countryCodeText = this.preloadCountryCode || this.lastUsedTOSCountryCode;
                    }
                } else if (this.preloadCustomerNumber) {
                    this.inviteForm.phone = this.preloadCustomerNumber;
                }

                if (this.preloadCountryCode) {
                    this.countryCodeText = this.preloadCountryCode;
                }

                if (this.lastDeclineTOSCustomerId && this.lastDeclineTOSCustomerId !== 'none') {
                    this.inviteForm.customerId = this.lastDeclineTOSCustomerId;
                } else if (this.preloadCustomerReference) {
                    this.inviteForm.customerId = this.preloadCustomerReference;
                }
            }
        );

        this.connectivityRunTest = this.connectivityRunTest.bind(this);

        if (
            this.browserUtilsService.getFromLocalStorage('checkForcePasswordChange') &&
            currentUser.forcePasswordChange &&
            !this.ssoAuthId
        ) {
            this.changeUserSettings(true);
        }

        this.multiSelectObservableUsers = this.getObservableUsersForMultiSelect();

        this.browserUtilsService.saveToLocalStorage('checkForcePasswordChange', false);
        this.accountSocketService.on(ServiceEvents.FS_DATA, (data) => this.updateFSData(data));
        this.accountSocketService.on(ServiceEvents.OFFLINE_DATA, (data) => this.updateOfflineData(data));
        this.accountSocketService.on(ServiceEvents.PENDING_ROOM_DATA, (data) => this.updateWarmTransferData(data));
        this.accountSocketService.joinAccountChannel().catch((err) => sendSentryLog(err));
    }

    _getInviteViaMethods() {
        const inviteViaMethods = [];

        if (this.allowInviteBySms) {
            inviteViaMethods.push({value: this.inviteMethods.SMS, name: this.translate('INVITE.VIEW.SMS')});
        }

        if (this.allowSendByEmail) {
            inviteViaMethods.push({value: this.inviteMethods.EMAIL, name: this.translate('INVITE.VIEW.EMAIL')});
        }

        if (this.displayInviteByLink()) {
            inviteViaMethods.push({value: this.inviteMethods.LINK, name: this.translate('INVITE.VIEW.LINK')});
        }

        return inviteViaMethods;
    }

    getSessionTypesForMultiSelect() {
        const sessionTypesValues = this.accountOfflineSessions
            ? [...this.availableStartingModes.availableModes, SESSION_TYPES.OFFLINE]
            : [...this.availableStartingModes.availableModes];

        const iterator = (sessionType) => ({
            value: sessionType,
            name:
                sessionTypesValues.indexOf(SESSION_TYPES.LIVE) > -1 || sessionType === SESSION_TYPES.OFFLINE
                    ? this.$translate.instant('INVITE.VIEW.' + sessionType)
                    : this.$translate.instant('REACT.DASHBOARD.MODE_SWITCH_CONFIRMATION.HEADER.' + sessionType)
        });

        return map(sessionTypesValues, iterator);
    }

    _getAudioConfig() {
        const env = this.environmentDetect.deviceCategory(this.displayTabletAsDesktop);
        const audioConfig = get(this.accountData, 'protectedSettings.audioStartMode');

        return env && audioConfig && audioConfig[env];
    }

    _shouldAllowAudio() {
        return (
            get(this.accountData, 'protectedSettings.newHeaderFooterLeftbar') &&
            get(this.accountData, 'protectedSettings.enableSessionToolbar') &&
            get(this.accountData, 'protectedSettings.mediaType') === MediaTypes.videoAndAudio
        );
    }

    /*
       audio is support only in video mode, we have 2 option:
       1. startWithModes enabled and the currentMode is video
       2. startWithModes disabled and the sessionType is Live and cameraModeOnly = false (meaning start with video)
     */
    isAudioSupportedMode() {
        const currentMode = get(this.startWithModesController, 'currentMode.mode');

        if (currentMode) {
            return currentMode === MeetingMode.video;
        }

        return (
            (this.sessionParams.sessionType === this.sessionTypes.LIVE ||
                this.sessionParams.sessionType === this.meetingModes.video) &&
            !get(this.accountData, 'settings.cameraModeOnly')
        );
    }

    shouldStartWithAudio() {
        return this.enableAudio && this.isAudioSupportedMode();
    }

    getAvailableStartingModes() {
        let result = {availableModes: [SESSION_TYPES.LIVE], defaultMode: SESSION_TYPES.LIVE};

        if (
            !this.cameraModeOnly &&
            get(this.accountData, 'settings.allowOneClickPhotoMode') &&
            get(this.accountData, 'settings.defaultMeetingMode') === MeetingMode.oneClick
        ) {
            result = {availableModes: [MeetingMode.video, MeetingMode.oneClick], defaultMode: MeetingMode.oneClick};
        }

        return result;
    }

    checkIfIsAvailableMode(mode) {
        return some(this.availableStartingModes.availableModes, (availableMode) => availableMode === mode);
    }

    connectivityRunTest(meta, goTo) {
        this.eventLog.connectivityCheck(meta);

        if (goTo) {
            this.stateHelper.safeGo('invite.old.connectivity');
        }
    }

    isInConnectivityState() {
        return this.$state.current.name === 'invite.old.connectivity';
    }

    initLanguage() {
        const selectedLanguage = find(this.accountData.settings.clientLanguages, (lang) => {
            if (lang.value !== this.accountData.settings.language) {
                return false;
            }

            lang.selected = true;

            return true;
        });

        this.clientLanguage = selectedLanguage
            ? selectedLanguage.value
            : this.accountData.settings.clientLanguages[0].value;
    }

    _getDefaultSessionParams() {
        const sessionParams = {
            sessionType: this.availableStartingModes.defaultMode,
            inviteMethod: AddressTypesEnum.SMS
        };

        if (this.allowInviteBySms) {
            sessionParams.inviteMethod = AddressTypesEnum.SMS;
        } else if (this.allowSendByEmail) {
            sessionParams.inviteMethod = AddressTypesEnum.EMAIL;
        } else if (this.allowSessionWithoutSms) {
            sessionParams.inviteMethod = AddressTypesEnum.LINK;
        } else {
            sessionParams.inviteMethod = AddressTypesEnum.NONE;
        }

        return sessionParams;
    }

    timeoutMessage() {
        this.$timeout(() => {
            this.message = null;
        }, MESSAGE_TIMEOUT);
    }

    getObservableUsersForMultiSelect() {
        const iterator = (observableUser) => ({
            value: observableUser.roomId,
            name: observableUser.userName
        });

        const observableUsers = [];

        if (!this.observableUsers.length) {
            observableUsers.push({
                value: null,
                name: this.$translate.instant('REACT.INVITE.VIEW.SESSION_OBSERVATION.NO_SESSIONS_TO_OBSERVE')
            });
        } else {
            return map(this.observableUsers, iterator);
        }

        return observableUsers;
    }

    isSupervisor() {
        return this.currentUser.canObserve || this.isCurrentUserATechnicianSupervisor;
    }

    isTechnicianSupervisor() {
        return this.currentUser.role === this.roles.TECHNICIAN_SUPERVISOR;
    }

    isFieldServiceEngineer() {
        return this.currentUser.role === this.roles.FIELD_SERVICES_ENGINEER;
    }

    allowedToJoinByCodeOrGuid() {
        if (this.csiEanbled) {
            return true;
        }

        if (this.enableFieldServices && this.isTechnicianSupervisor()) {
            return true;
        }

        return this.accountOfflineSessions && this.allowSearchOfflineSessionsById;
    }

    isUser() {
        return this.currentUser.role === this.roles.USER;
    }

    isCurrentUserEligibleForHistorySearch() {
        return !(
            this.isUser() ||
            this.isKnowledgeExpert() ||
            this.currentUser.role === this.roles.FIELD_SERVICES_ENGINEER
        );
    }

    isKnowledgeExpert() {
        return this.currentUser.role === this.roles.KNOWLEDGE_EXPERT;
    }

    isEligibleForHistorySearch() {
        return (
            get(this.accountData, 'protectedSettings.enableHistory') &&
            get(this.accountData, 'protectedSettings.enableHistorySearch') &&
            (get(this.accountData, 'protectedSettings.enableUserHistorySearch') ||
                this.isCurrentUserEligibleForHistorySearch())
        );
    }

    isSysOrSuperAdmin() {
        return this.currentUser.role === this.roles.SYSADMIN || this.currentUser.role === this.roles.SUPERADMIN;
    }

    canRetrieveObservableRooms() {
        return !includes(
            [this.roles.MANAGER, this.roles.USER, this.roles.FIELD_SERVICES_ENGINEER],
            this.currentUser.role
        );
    }

    toggleAudio() {
        this.enableAudio = !this.enableAudio;
        this.$rootScope.safeApply();
    }

    retrieveObservableRooms() {
        if (this.currentUser.accountId && this.canRetrieveObservableRooms()) {
            return this.db.User.observableByAccount({
                params: {
                    id: this.currentUser.accountId
                }
            }).then((users) => {
                this.observableUsers = users.data;
                this.multiSelectObservableUsers = this.getObservableUsersForMultiSelect();
            });
        }

        return this.db.Rooms.active().then((rooms) => {
            const roomsWithoutCurrentUser = filter(rooms && rooms.data, (room) => room.userId !== this.currentUser._id);

            if (isEmpty(roomsWithoutCurrentUser)) {
                return;
            }

            // After we retrieve all the open rooms, we need a list of users
            // for the current account.
            if (this.currentUser.accountId) {
                // For user and group managers, allow observing only on group peers
                return this.db.User.groupPeers({
                    params: {
                        id: this.currentUser._id
                    }
                }).then((users) => {
                    this.observableUsers = this._matchUsersToRooms(users.data, roomsWithoutCurrentUser);
                    this.multiSelectObservableUsers = this.getObservableUsersForMultiSelect();
                });
            }

            // If the user is a super-admin, the accountId will be unset,
            // which means that we will ask for a list of all users
            return this.db.User.findAll().then((users) => {
                this.observableUsers = this._matchUsersToRooms(users, roomsWithoutCurrentUser.data);
                this.multiSelectObservableUsers = this.getObservableUsersForMultiSelect();
            });
        });
    }

    retrieveOfflineRooms(data) {
        let offlineAwaitingAgent = 0;
        let offlineAwaitingClient = 0;

        each(data, (entry) => {
            if (!entry.occupied && entry.lastActionBy === UserType.client) {
                offlineAwaitingAgent++;
            } else if (!entry.occupied && entry.lastActionBy === UserType.dashboard) {
                offlineAwaitingClient++;
            }
        });

        this.offlineAwaitingAgent = offlineAwaitingAgent;
        this.offlineAwaitingClient = offlineAwaitingClient;
        this.$rootScope.safeApply();
    }

    inInvitePage() {
        return this.$state.current.name === 'invite.old';
    }

    showOfflineSessions() {
        this.stateHelper.safeGo('invite.old.offlinePool');
    }

    _matchUsersToRooms(users, rooms) {
        // Since there might be leftover rooms, we only pick the latest
        // one that has is registered to a user in our list. If none is
        // found, that user is skipped.
        const userList = [],
            now = new Date().getTime();

        forEach(users, (user) => {
            const room = findLast(rooms, {userId: user._id});

            // Rooms must be less than an hour old, or they are presumed dead
            if (room && now - moment(room.creationTimestamp).valueOf() < 3600000) {
                userList.push({
                    userName: `${user.firstName} ${user.lastName}`,
                    roomId: room._id
                });
            }
        });

        return userList;
    }

    retrieveActiveTechnicians() {
        this.db.Rooms.active().then((rooms) => {
            this.db.User.groupPeers({
                params: {
                    id: this.currentUser._id
                }
            }).then((users) => {
                const technicians = filter(
                    users.data,
                    (user) => user.role === this.roles.TECHNICIAN_SUPERVISOR || user.role === this.roles.TECHNICIAN
                );

                this.activeTechnicians = this._matchUsersToRooms(technicians, rooms.data);
            });
        });
    }

    acceptTerms() {
        return this.$modal.open({
            animation: true,
            template: acceptTermsView,
            controller: AcceptTermsController,
            controllerAs: 'vm',
            windowClass: 'accept-terms-modal',
            resolve: {
                termsPath: () => this.terms.path,
                dir: () => this.dir,
                readOnly: false
            }
        }).result;
    }

    /**
     * Goes to the dashboard page
     *
     * @param observe - if a truth value is supplied, the user connects as an observer
     */
    gotoDashboard(observe) {
        if (!this.roomShortId && !this.accountData.protectedSettings.showRoomIdInUrl) {
            this._getRoomShortId().then((reply) => {
                this.roomShortId = reply.data.shortId;
                this._redirection(observe);
            });
        } else {
            this._redirection(observe);
        }
    }

    _observationLogs(observe) {
        if (!observe) {
            return Promise.resolve();
        }

        return this.db.Observations.add({
            data: {
                roomId: this.room._id,
                userName: this.currentUser.userName,
                firstName: this.currentUser.firstName,
                lastName: this.currentUser.lastName,
                addNewSegment: true
            }
        }).catch(() => null);
    }

    _redirection(observe) {
        this._observationLogs(observe).then(() => {
            this.enableAudio = this.shouldStartWithAudio();
            const normalizedUrl = TsUtils.replaceUrlDomain(DASHBOARD_URL, this.$window.location.hostname);
            let url = normalizedUrl
                .replace(
                    '{room}',
                    this.accountData.protectedSettings.showRoomIdInUrl ? this.room._id : this.roomShortId
                )
                .replace('{observe}', observe ? 'true' : '')
                .replace('{audio}', this.enableAudio ? 'yes' : '')
                .replace('{roomCode}', this.roomCode ? this.roomCode : '');

            if (this.tsQuickLauncher.startedFromLauncher) {
                url = `${url}&sf=dl`;
            }

            if (!ENV.desktop) {
                // There is a known bug in Opentok that we can workaround by reloading entire page only
                this.$window.location = url;

                if (ENV.dev) {
                    this.$window.location.reload();
                }
            } else {
                this.tsUrlUtils.setParamValue(
                    'room',
                    this.accountData.protectedSettings.showRoomIdInUrl ? this.room._id : this.roomShortId
                );
                this.tsUrlUtils.setParamValue('observe', '');
                this.tsUrlUtils.setParamValue('roomCode', this.roomCode ? this.roomCode : '');
                this.tsUrlUtils.setParamValue('audio', this.enableAudio ? 'yes' : '');

                this.stateHelper.safeGo('dashboard.entry', {
                    room: this.room._id,
                    observe: observe ? 'true' : '',
                    roomCode: this.roomCode ? this.roomCode : '',
                    audio: this.enableAudio ? 'yes' : ''
                });
            }
        });
    }

    logout() {
        const accountId = this.accountData._id,
            userId = this.currentUser._id,
            returnURL = !accountId
                ? ''
                : this.$rootScope.windowOrigin + BASE_PATH + get(this.accountData, 'protectedSettings.alias');

        return this.auth.logout(accountId, userId, returnURL).then((res) => {
            if (!res.data) {
                this.$window.location.href = '/';

                return;
            }

            this.$window.location.href = res.data;
        });
    }

    searchHistory(idOrNumber) {
        const isValidPhoneNumber = new RegExp(this.phonePattern, 'g').test(idOrNumber);

        this.db.History.byCustomerId(
            {
                params: {
                    customerId: idOrNumber,
                    accountId: this.accountData._id
                }
            },
            {bypassCache: true}
        )
            .then((result) => this.videoUtils.createThumbnailsForVideos(result.data))
            .then((result) => {
                this.historySearchResults.byId = result;

                //TODO: When implementing search history by email, if we still use byCustomerNumber
                //for searching by email, we need to add this in the 'if' below: !this.keepCustomerEmailAddress
                if (!this.keepCustomerMobile || !isValidPhoneNumber) {
                    return;
                }

                return this.db.History.byCustomerNumber(
                    {
                        params: {
                            customerNumber: idOrNumber,
                            accountId: this.accountData._id
                        }
                    },
                    {bypassCache: true}
                );
            })
            .then((result) => {
                if (!get(result, 'data')) {
                    return {records: [], images: 0, videos: 0, messages: 0, files: 0};
                }

                return this.videoUtils.createThumbnailsForVideos(result.data);
            })
            .then((result) => {
                this.historySearchResults.byNumber = result;

                const hasIdHistory = this._historyNotEmpty(this.historySearchResults.byId),
                    hasNumberHistory = this._historyNotEmpty(this.historySearchResults.byNumber);

                if (!hasNumberHistory && !hasIdHistory) {
                    this._noHistoryFound();
                } else if (hasNumberHistory && !hasIdHistory) {
                    this.showHistoryForNumber(idOrNumber);
                } else if (!hasNumberHistory && hasIdHistory) {
                    this.showHistoryForId(idOrNumber);
                } else {
                    this.historyDropdownOpen = true;
                }
            });
    }

    displayInviteByLink() {
        return (
            this.allowSessionWithoutSms ||
            this.sessionParams.sessionType === this.sessionTypes.OFFLINE ||
            this.sessionParams.sessionType === this.sessionTypes.SCREEN_SHARING ||
            this.sessionParams.sessionType === this.sessionTypes.APPLICATION_SHARING ||
            this.sessionParams.sessionType === this.sessionTypes.CO_BROWSING
        );
    }

    showHistoryForId(id) {
        this.stateHelper.safeGo('invite.old.history', {customerId: id});
    }

    showHistoryForNumber(number) {
        this.stateHelper.safeGo('invite.old.history', {customerNumber: number});
    }

    _historyNotEmpty(history) {
        return history.images > 0 || history.videos > 0 || history.messages > 0 || history.files > 0;
    }

    _noHistoryFound() {
        this.updateStatusMessage('INVITE.CONTROLLER.NO_HISTORY_FOUND');

        const timeout = this.$timeout(() => {
            this.statusMessage = null;
        }, 3000);

        this.timeouts.push(timeout);
    }

    updateStartWithMode() {
        this.$rootScope.safeApply();
    }

    isCobrowsingSelected() {
        return (
            this.sessionParams.sessionType === SESSION_TYPES.CO_BROWSING ||
            (this.startWithModesEnabled &&
                get(this.startWithModesController, 'currentMode.mode') === MeetingMode.coBrowsing)
        );
    }

    _getSessionStartMode() {
        if (this.startWithModesEnabled) {
            if (get(this.startWithModesController, 'isOfflineMode')) {
                return null;
            }

            return get(this.startWithModesController, 'currentMode.mode');
        }

        return this.sessionParams.sessionType !== SESSION_TYPES.LIVE &&
            this.sessionParams.sessionType !== SESSION_TYPES.OFFLINE
            ? this.sessionParams.sessionType
            : '';
    }

    _sanitizeRoomCreationParams(data, forShortUrl) {
        let roomData = cloneDeep(data);
        const startAsCoBrowsing = this.isCobrowsingSelected();
        const sessionStartMode = this._getSessionStartMode();

        if (startAsCoBrowsing && !forShortUrl) {
            roomData = assign(data, {startUrlCobrowsing: this.coBrowsingStartUrl});
        }

        if (this.startWithModesEnabled && get(this.startWithModesController, 'isOfflineMode')) {
            roomData = assign(roomData, {offline: true});
        }

        return !isEmpty(sessionStartMode) ? assign(roomData, {startWithAgentType: sessionStartMode}) : roomData;
    }

    _sendClientAsDashboardSMS(phone, roomId, shortId) {
        const domain = this.$window.location.origin;
        const room = get(this.accountData, 'protectedSettings.showRoomIdInUrl') ? `roomId=${roomId}` : `g=${shortId}`;

        const url = `${domain}/?${room}&audio=${this.enableAudio ? 'yes' : ''}`;
        const countryCode = this.countrySelector.getCurrentCountryCode();
        const customerNumber = phone && this._validateCountryCodeAndPhone(countryCode, phone);

        if (!customerNumber) {
            throw new Error('Invalid customer number');
        }

        return this._createRoomLinks(roomId)
            .then(() =>
                this.smsService.sendClientAsDashboard({
                    roomId: roomId,
                    phone: customerNumber,
                    url
                })
            )
            .then(() => {
                this.$window.location.href = `${this.clientStartURL}/?g=${this.roomShortId}`;
            });
    }

    _sendSMS(phone, roomId, offline, useNumberLookup) {
        const smsUseNumberLookup =
            useNumberLookup === undefined
                ? get(this.accountData, 'protectedSettings.smsSettings.smsUseNumberLookup')
                : useNumberLookup;

        const smsData = {
            phone: phone,
            roomId: roomId,
            offline: offline,
            chromeDetection: this.chromeDetection,
            cameraModeOnly: this.cameraModeOnly,
            showSmsPreview: this.showSmsPreview,
            mobileClientURL: this.clientStartURL,
            countryCode: this.countrySelector.getCurrentCountryCode(),
            startWithAgentType: this._getSessionStartMode(),
            smsUseNumberLookup: smsUseNumberLookup,
            audio: this.shouldStartWithAudio()
        };

        this.browserUtilsService.saveToLocalStorage('phoneNumber', smsData.phone);
        this.browserUtilsService.saveToLocalStorage('countryCode', smsData.countryCode);

        this.$localStorage.meetingMode = smsData.startWithAgentType;

        const smsCb = (message) => {
            if (message.data && message.data.customError) {
                this.sms = false;
                this.updateCustomError(message.data.customError);
            }

            if (message.data && message.data.smsError) {
                this.sms = false;
                this.updateCustomError(message.data.smsError.Message, message.data.smsError.Level);
            }

            if (message.data && message.data.statusMessage) {
                this.updateStatusMessage(message.data.statusMessage);
            }
        };

        const promptCb = () => this._promptForDelivery();

        // Sending SMS...
        return this.smsService
            .sendSMS(smsData, (message) => smsCb(message), true, promptCb)
            .then((result) => {
                if (result && result.success) {
                    if (offline) {
                        return this._offlineSessionCreated();
                    }

                    return this.gotoDashboard();
                }

                this.sms = false;
            })
            .catch((err) => {
                const lookupPhoneNumber = get(err, 'data.data.lookupPhoneNumber');

                if (this.numberLookupModalCtrl && smsUseNumberLookup && lookupPhoneNumber) {
                    this.updateStatusMessage(null);

                    this.numberLookupModalCtrl
                        .showLookupModal({
                            enteredPhone: `+${smsData.countryCode}${smsData.phone}`,
                            validatedPhone: `+${lookupPhoneNumber}`,
                            logData: {
                                accountId: this.currentUser.accountId,
                                roomId: roomId,
                                userId: this.currentUser._id
                            }
                        })
                        .then(() => {
                            const newPhoneNumber = lookupPhoneNumber.replace(smsData.countryCode, '');

                            const roomInstance = this.db.Rooms.createInstance({_id: roomId});

                            roomInstance.setCustomer({
                                customerNumber: `${smsData.countryCode}${newPhoneNumber}`,
                                customerCountryCode: smsData.countryCode,
                                customerId: this.inviteForm.customerId
                            });

                            this._sendSMS(newPhoneNumber, roomId, offline, false);
                        })
                        .catch(() => {
                            this._sendSMS(smsData.phone, roomId, offline, false);
                        });
                }

                this.sms = false;
            });
    }

    _promptForDelivery() {
        let modalInstance = null;

        if (this.tsInterfaceHelper.useNewInterface) {
            modalInstance = this.SmsStatusNewUi.show('SMS_STATUS.VIEW.TITLE', 'SMS_STATUS.VIEW.QUESTION', this.dir);
        } else {
            modalInstance = this.$modal.open({
                animation: true,
                template: smsStatusView,
                controller: SMSStatusController,
                controllerAs: 'vm',
                resolve: {
                    message: () => null,
                    question: () => true,
                    dir: () => this.dir
                }
            });
        }

        return {
            modalAbort: (completionFunc) => modalInstance.close({answer: 'abort', completionFunc}),
            modalCompletion: () => modalInstance.result
        };
    }

    _validateCountryCodeAndPhone(countryCode, phone) {
        if (!this.addCountryCodeToSms) {
            return phone;
        }

        if (!this.countrySelector.validateCountryCode(countryCode)) {
            this.updateCustomError('INVITE.CONTROLLER.INVALID_COUNTRY_CODE');

            return null;
        }

        if (includes(phone, '+')) {
            this.updateCustomError('INVITE.CONTROLLER.PLUS_SIGN_NOT_SUPPORTED');

            return null;
        }

        return countryCode + phone.replace(/^0*/, '');
    }

    submit(form) {
        this.offlineSessionUrl = undefined;
        this.changedSettingsMessage = undefined;
        this.phoneRequired = true;
        const countryCode = this.countrySelector.getCurrentCountryCode();

        this._storeInviteMethod(this.sessionParams.inviteMethod);

        if (this.sms) {
            return;
        }

        const phone = form.phone;
        const customerId = form.customerId;

        const customerNumber = phone && this._validateCountryCodeAndPhone(countryCode, phone);

        if (!customerNumber) {
            return;
        }

        this.sms = true;

        this.updateStatusMessage('INVITE.CONTROLLER.STARTING_SESSION');
        this.$timeout(() => {
            if (this.room) {
                this._cancelRoom();
            }

            const data = {
                customerNumber: customerNumber,
                customerCountryCode: countryCode || 'none',
                customerId: customerId,
                clientLanguage: this.clientLanguage,
                initiateWithAudio: this.shouldStartWithAudio()
            };

            const isStartedWithOfflineMode =
                this.startWithModesEnabled && get(this.startWithModesController, 'isOfflineMode');

            if (this.isTechnicianSupervisor()) {
                data.flowType = FlowType.fs;
            }

            this.db.Rooms.create(this._sanitizeRoomCreationParams(data)).then(
                (room) => {
                    this.room = room;
                    this.$localStorage.lastUsedTOSCountryCode = this.countryCodeText;
                    this.eventLog.room = room._id;
                    this.smsService.setRoomId(room._id);

                    // eslint-disable-next-line new-cap
                    this.eventLog.SMSInvited(this.isStartedFromLauncher ? 'TechSee Quick Launcher SMS' : 'TechSee SMS');

                    return this.selectVideoPublisherController.viewVideoPublisher
                        ? this._sendSMS(phone, room._id, isStartedWithOfflineMode)
                        : this._sendClientAsDashboardSMS(phone, room._id, room.guids[0]);
                },
                (error) => {
                    let errorMessage = 'INVITE.CONTROLLER.FAILED_START_SESSION';

                    if (error.status === 402) {
                        errorMessage = 'INVITE.CONTROLLER.ERROR_101';
                    }

                    this.sms = false;
                    this.updateCustomError(errorMessage);
                }
            );
        }, 500);
    }

    submitObserve(form) {
        this.changedSettingsMessage = undefined;
        const roomId = form.observedRoom;

        this.db.Rooms.find(roomId).then((room) => {
            this.room = room;
            this.gotoDashboard(true);
        });
    }

    validateObserve(form) {
        if (!form.$valid) {
            this.updateCustomError('INVITE.CONTROLLER.SELECT_MEETING_TO_SUPERVISE');

            return false;
        }

        this.customError = null;

        return true;
    }

    validateInviteCode(form) {
        if (!form.$valid) {
            if (form['meeting-code'].$error && form['meeting-code'].$error.pattern) {
                this.updateCustomError('INVITE.CONTROLLER.INCORRECT_MEETING_ID');
            } else {
                this.updateCustomError('INVITE.CONTROLLER.TYPE_MEETING_ID');
            }

            return false;
        }

        this.customError = null;

        return true;
    }

    toggleOverlay() {
        this.isOverlayOpen = !this.isOverlayOpen;
    }

    redirectToAdmin() {
        this.db.System.adminUrl({
            params: {
                origin: this.$rootScope.windowOrigin
            }
        }).then((response) => {
            window.location = response.data.adminUrl;
        });
    }

    get showSelectVideoPublisher() {
        const env = this.environmentDetect.deviceCategory(this.displayTabletAsDesktop);
        const isLive = includes(
            [this.sessionTypes.LIVE, this.meetingModes.video, this.meetingModes.oneClick],
            this.sessionParams.sessionType
        );
        const selectVideoPublisher = get(this.accountData, 'protectedSettings.selectVideoPublisher');

        return (
            this.isFieldServiceEngineer() &&
            this.selectVideoPublisherController &&
            this.showMobileTechseeInvite &&
            this.enableFieldServices &&
            selectVideoPublisher &&
            selectVideoPublisher[env] &&
            isLive
        );
    }

    switchAccount() {
        this.changedSettingsMessage = '';

        const modalInstance = this.$modal.open({
            template: changeAccountView,
            controller: 'ChangeAccountController',
            controllerAs: 'vm',
            resolve: {
                currentUser: () => this.currentUser,
                accountData: () => this.accountData,
                dir: () => this.dir
            }
        });

        modalInstance.result
            .then((res) => {
                if (res.reload) {
                    this.$window.location.reload();
                }
            })
            .catch(() => false);
    }

    /**
     * Opens user settings editing modal
     */
    changeUserSettings(passwordOnly = false) {
        this.changedSettingsMessage = '';

        const modalInstance = this.$modal.open({
            template: changeSettingsView,
            controller: 'ChangeSettingsController',
            controllerAs: 'vm',
            backdrop: passwordOnly ? 'static' : true,
            keyboard: !passwordOnly,
            resolve: {
                currentUser: () => this.currentUser,
                accountData: () => this.accountData,
                dir: () => this.dir,
                passwordOnly: () => passwordOnly
            }
        });

        modalInstance.result
            .then((updatedUser) => {
                assign(this.currentUser, updatedUser);
                // User settings changed
                this.updateChangesSettingsMessage('CHANGE_SETTINGS.VIEW.CHANGED');
            })
            .catch(() => false);
    }

    toggleTechseeInvite() {
        this.showMobileTechseeInvite = !this.showMobileTechseeInvite;
    }

    _showErrorMessage(error) {
        let errorMessage = 'INVITE.CONTROLLER.FAILED_START_SESSION';

        if (error.status === 402) {
            errorMessage = 'INVITE.CONTROLLER.ERROR_101';
        }

        this.sms = false;
        this.updateCustomError(errorMessage);
    }

    createRoomManualy(preventRedirection) {
        return this.$q((resolve) => {
            if (!this._checkInviteId()) {
                return resolve(false);
            }

            if (this.room) {
                this._cancelRoom();
            }

            // Creating a new room...
            this.updateStatusMessage('INVITE.CONTROLLER.STARTING_SESSION');
            const data = {
                offline: this.sessionParams.sessionType === SESSION_TYPES.OFFLINE,
                customerNumber: this.inviteForm.phone,
                customerId: this.inviteForm.customerId,
                clientLanguage: this.clientLanguage,
                initiateWithAudio: this.shouldStartWithAudio()
            };

            this.db.Rooms.create(this._sanitizeRoomCreationParams(data))
                .then((room) => {
                    this.room = room;

                    this.eventLog.room = room._id;
                    this.smsService.setRoomId(room._id);

                    const mobileUrlConfig = this.urlConfig.get('MOBILE_CLIENT_URL');
                    const url = TsUtils.replaceUrlDomain(mobileUrlConfig, this.$window.location.hostname);

                    this.link = url.replace('{room}', room._id) + (this.chromeDetection ? '' : '&intent=no');

                    resolve(true);

                    if (!preventRedirection) {
                        this.gotoDashboard();
                    }
                })
                .catch((error) => {
                    this._showErrorMessage(error);
                });
        });
    }

    _cancelRoom() {
        this.room.end(this.LogEvents.closedByTypes.dashboard, ReasonForClosing.cancelled);

        this.eventLog.roomCancelled();
    }

    _checkInviteId() {
        if (this.inviteForm['invite-id'] && !this.inviteForm['invite-id'].$valid) {
            // Manually trigger the required error message
            this.inviteForm['invite-id'].$dirty = true;
            this.inviteForm.$setSubmitted();

            this.scrollToMainTop();

            return false;
        }

        return true;
    }

    _checkValidStartUrl() {
        const startAsCoBrowsing = this.sessionParams.sessionType === SESSION_TYPES.CO_BROWSING;

        if (!startAsCoBrowsing) {
            return true;
        }

        const startUrl = this.coBrowsingStartUrl;

        if (isEmpty(startUrl)) {
            this.updateCustomError('INVITE.CONTROLLER.EMPTY_COBROWSING_URL');

            return false;
        }

        if (TsUtils.checkValidCoBrowsingUrl(startUrl)) {
            return true;
        }

        this.updateCustomError('INVITE.CONTROLLER.INVALID_COBROWSING_URL');

        return false;
    }

    _updateInviteMethodParam(type) {
        switch (type) {
            case 'sms':
                this.sessionParams.inviteMethod = AddressTypesEnum.SMS;
                break;
            case 'whatsapp':
                this.sessionParams.inviteMethod = AddressTypesEnum.WHATSAPP;
                break;
            case 'email':
                this.sessionParams.inviteMethod = AddressTypesEnum.EMAIL;
                break;
            default:
                break;
        }
    }

    launchInviteMobile(type, collapse) {
        this.showMobileTechseeInvite = !collapse;
        this._updateInviteMethodParam(type);

        if (!this._checkInviteId()) {
            return;
        }

        this.createRoomManualy(true).then((result) => {
            if (!result) {
                return;
            }

            this._createRoomLinks(this.room._id).then(() => {
                switch (type) {
                    case 'sms':
                        this.$window.location = this.smsTrigger;
                        this.eventLog.SMSInvited('native SMS');
                        break;
                    case 'whatsapp':
                        this.$window.location = this.whatsappTrigger;
                        break;
                    case 'email':
                        this.$window.location = this.emailTrigger;
                        this.eventLog.emailInvited();
                        break;
                    default:
                        break;
                }

                this.$timeout(() => this.gotoDashboard(), 1000);
            });
        });
    }

    handleButtonImage(type, isSelected) {
        const selected = '-selected';

        return this.isMobileDevice
            ? this.requireImage(`icon-new-${type}${isSelected ? selected : ''}.png`)
            : this.requireImage(`icon-orange-${type}.png`);
    }

    emailInvite() {
        this.phoneRequired = false;
        this.offlineSessionUrl = undefined;

        if (this.isMobileDevice) {
            this.launchInviteMobile('email');
        } else {
            this.createRoomManualy(true).then((result) => {
                if (!result) {
                    return;
                }

                this._createRoomLinks(this.room._id).then(() => {
                    this.eventLog.emailInvited();
                    this.$window.open(this.emailTrigger);

                    this.gotoDashboard();
                });
            });
        }
    }

    _createRoomLinks(roomId) {
        const data = {
            roomId: roomId,
            chromeDetection: this.chromeDetection,
            cameraModeOnly: this.cameraModeOnly,
            mobileClientURL: this.clientStartURL,
            audio: this.shouldStartWithAudio()
        };

        if (this.isTechnicianSupervisor()) {
            data.flowType = FlowType.fs;
        }

        return this.db.ShortUrl.create(data).then((res) => {
            this.roomShortId = res.shortId;

            const text = this.$translate.instant('INVITE.VIEW.GO_TO_MEETING');

            const linkText = encodeURIComponent(`${text}: ${res.url}`);

            if (this.environmentDetect.osName() === 'iOS') {
                this.smsTrigger = 'sms:&body=' + linkText;
            } else {
                this.smsTrigger = 'sms:?body=' + linkText;
            }

            this.emailTrigger = 'mailto:?body=' + linkText;
            this.whatsappTrigger = 'whatsapp://send?text=' + linkText;
        });
    }

    joinByCode(code) {
        let roomCode = code.toLowerCase();
        const guidPrefix = get(this.accountData, 'guidPrefix');

        if (roomCode.substr(0, guidPrefix.length) !== guidPrefix) {
            roomCode = `${guidPrefix}${roomCode}`;
        }

        this.db.Rooms.code({params: {roomCode: roomCode}}).then((exists) => {
            if (!exists.data) {
                this.updateCustomError('INVITE.CONTROLLER.INVALID_SESSION_ID');

                return;
            }

            this.tsUrlUtils.setParamValue('audio', this.enableAudio ? 'yes' : '');

            if (exists.data.roomId) {
                this.tsUrlUtils.setParamValue('shortId', roomCode);
                this.tsUrlUtils.setParamValue('roomId', exists.data.roomId);
                this.stateHelper.safeGo('join', {
                    shortId: roomCode,
                    roomId: exists.data.roomId,
                    audio: this.enableAudio ? 'yes' : ''
                });
            } else {
                this.tsUrlUtils.setParamValue('roomCode', exists.data.unprefixedRoomCode || roomCode);
                this.stateHelper.safeGo('join', {
                    roomCode: exists.data.unprefixedRoomCode || roomCode,
                    audio: this.enableAudio ? 'yes' : ''
                });
            }
        });
    }

    saveWizardSetting() {
        const showWizard = this.currentUser.showWizard;

        return this.db.User.update(this.currentUser._id, {showWizard}).then(() => {
            this.showWizard = showWizard && this.showWizardSetting;
        });
    }

    getFullName() {
        return `${this.currentUser.firstName} ${this.currentUser.lastName}`;
    }

    startSessionWithoutInvitation() {
        // If the user is sysadmin or superAdmin, don't go and try to create a session
        if (this.isForbiddenRole) {
            this.updateStatusMessage('INVITE.CONTROLLER.FAILED_START_SESSION');
        } else {
            this.$timeout(() => {
                this.offlineSessionUrl = undefined;

                if (this.room) {
                    this._cancelRoom();
                }

                this.phoneRequired = false;
                const data = {
                    customerId: this.inviteForm.customerId,
                    clientLanguage: this.clientLanguage,
                    initiateWithAudio: this.shouldStartWithAudio()
                };

                this.db.Rooms.create(this._sanitizeRoomCreationParams(data)).then(
                    (room) => {
                        this.room = room;
                        this.eventLog.room = room._id;
                        this.smsService.setRoomId(room._id);
                        this._getShortUrl(room, true, false);
                    },
                    (error) => {
                        this._showErrorMessage(error);
                    }
                );
            }, 500);
        }
    }

    _offlineWithoutSms() {
        return this._getShortUrl(this.offlineRoom, false, true)
            .then((url) => {
                this.offlineSessionUrl = url;

                this._offlineSessionCreated();
            })
            .catch((err) => {
                console.error(err);
            });
    }

    _sendOfflineSms(phoneNumber) {
        return this._sendSMS(phoneNumber, this.offlineRoom._id, true)
            .then(() => true)
            .catch((err) => {
                console.error(err);
            });
    }

    _offlineSessionCreated() {
        this.updateStatusMessage('INVITE.CONTROLLER.OFFLINE_SESSION_CREATED');

        return Promise.resolve();
    }

    blurOnSubmit() {
        document.body.tabIndex = -1;
        document.body.focus();
    }

    submitInviteForm() {
        this.scrollToMainTop();
        this.blurOnSubmit();

        if (this.inviteForm.$valid) {
            return this.submit(this.inviteForm);
        }
    }

    submitHistoryForm() {
        this.scrollToMainTop();
        this.blurOnSubmit();

        if (this.searchHistoryForm.$valid) {
            return this.searchHistory(this.searchHistoryForm.idOrNumber);
        }
    }

    submitCodeForm() {
        this.scrollToMainTop();
        this.blurOnSubmit();

        if (this.inviteCodeForm.$valid) {
            return this.joinByCode(this.inviteCodeForm.meetingCode);
        }
    }

    _storeInviteMethod(inviteMethod) {
        this.browserUtilsService.saveToSessionStorage('InviteMethod', inviteMethod);
    }

    startSession() {
        const offline =
            this.sessionParams.sessionType === SESSION_TYPES.OFFLINE ||
            (this.startWithModesEnabled && get(this.startWithModesController, 'isOfflineMode'));

        const sendSms = this.sessionParams.inviteMethod === AddressTypesEnum.SMS;

        if (this.isForbiddenRole) {
            this.updateStatusMessage('INVITE.CONTROLLER.FAILED_START_SESSION');

            return;
        }

        this.$timeout(() => {
            this.offlineSessionUrl = undefined;

            if (!sendSms) {
                this.phoneRequired = false;

                this.inviteForm['invite-phone'].$setPristine();
                this.inviteForm['invite-phone'].$setUntouched();
                this.inviteForm.$submitted = false;
            } else if (this.sessionParams.inviteMethod !== AddressTypesEnum.EMAIL) {
                this.phoneRequired = true;
            }

            if (!this._checkInviteId()) {
                return;
            }

            if (!this._checkValidStartUrl()) {
                return;
            }

            if (sendSms && !this.inviteForm['invite-phone'].$valid) {
                this.inviteForm['invite-phone'].$setDirty();
                this.inviteForm['invite-phone'].$setTouched();
                this.inviteForm.$setSubmitted();

                this.scrollToMainTop();

                return;
            }

            if (this.sessionParams.inviteMethod === AddressTypesEnum.EMAIL) {
                return this.emailInvite();
            } else if (this.sessionParams.inviteMethod === AddressTypesEnum.NONE) {
                return this.startSessionWithoutInvitation();
            }

            if (!offline) {
                if (this.sessionParams.inviteMethod === AddressTypesEnum.SMS) {
                    return this.submit(this.inviteForm);
                } else if (this.sessionParams.inviteMethod === AddressTypesEnum.LINK) {
                    return this.startSessionWithoutInvitation();
                }
            }

            const phone = this.inviteForm.phone;

            const countryCode = this.countrySelector.getCurrentCountryCode();
            const customerNumber = phone && this._validateCountryCodeAndPhone(countryCode, phone);

            if (!customerNumber && !(offline && this.sessionParams.inviteMethod === AddressTypesEnum.LINK)) {
                return;
            }

            const roomData = {
                offline,
                customerId: this.inviteForm.customerId,
                clientLanguage: this.clientLanguage,
                initiateWithAudio: this.shouldStartWithAudio()
            };

            if (sendSms) {
                assign(roomData, {customerNumber: customerNumber, customerCountryCode: countryCode || 'none'});
            }

            this.db.Rooms.create(this._sanitizeRoomCreationParams(roomData))
                .then((room) => {
                    this.offlineRoom = room;
                    this.smsService.setRoomId(room._id);

                    if (sendSms) {
                        return this._sendOfflineSms(phone);
                    }

                    return this._offlineWithoutSms();
                })
                .catch((err) => {
                    console.error(err);
                });
        }, 500);
    }

    _getShortUrl(room, redirectToDashboard, offline) {
        this.updateStatusMessage('INVITE.CONTROLLER.STARTING_SESSION');

        const data = {
            audio: this.shouldStartWithAudio(),
            roomId: room._id,
            chromeDetection: this.chromeDetection,
            cameraModeOnly: this.cameraModeOnly,
            mobileClientURL: this.clientStartURL,
            offline
        };

        if (this.isTechnicianSupervisor()) {
            data.flowType = FlowType.fs;
        }

        return this.db.ShortUrl.create(this._sanitizeRoomCreationParams(data, true))
            .then((data) => {
                this.roomShortId = data.shortId;

                if (redirectToDashboard) {
                    return this.gotoDashboard();
                }

                return data.url;
            })
            .catch(() => this.updateStatusMessage('INVITE.CONTROLLER.FAILED_START_SESSION'));
    }

    _getRoomShortId() {
        return this.db.Rooms.shortId(this.room._id);
    }

    phoneChanged() {
        this.customError = null;
    }

    scrollToMainTop() {
        this.$location.hash('main-top');
        this.$anchorScroll();
    }

    updateStatusMessage(status) {
        this.customError = null;
        this.statusMessage = status ? this.$translate.instant(status) : status;
        this.changedSettingsMessage = null;

        this.scrollToMainTop();
    }

    updateCustomError(message, errorLevel) {
        this.customError = {
            message: message ? this.$translate.instant(message) : message,
            level: errorLevel
        };
        this.statusMessage = null;
        this.changedSettingsMessage = null;

        this.scrollToMainTop();
    }

    updateChangesSettingsMessage(message) {
        this.customError = null;
        this.statusMessage = null;
        this.changedSettingsMessage = this.$translate.instant(message);

        this.scrollToMainTop();
    }

    updateFSData(data) {
        if (this.enableFieldServices && this.isTechnicianSupervisor() && this.isUserPermittedToGetOfflineAlerts) {
            this.roomCodes = data;
            this.roomCodesCount = data.length;
        }

        this.$rootScope.safeApply();
    }

    updateOfflineData(data) {
        if (this.accountOfflineSessions && this.isUserPermittedToGetOfflineAlerts) {
            this.retrieveOfflineRooms(data);
        }

        this.$rootScope.safeApply();
    }
    updateWarmTransferData(data) {
        this.$rootScope.safeApply();
    }

    showSessionRequests() {
        this.stateHelper.safeGo('invite.old.sessionRequests');
    }
}

/* eslint-enable consistent-return */
