'use strict';
import tsMuteButtonView from './ts-mute-button.view.html';
import './ts-mute-button.style.scss';

export function tsMuteButton() {
    return {
        template: tsMuteButtonView,
        scope: true
    };
}
