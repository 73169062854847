'use strict';

export class ErrorDialogController {
    constructor($modalInstance, message, dir, showRetryButton) {
        'ngInject';

        this.$modalInstance = $modalInstance;
        this.message = message;
        this.dir = dir;
        this.showRetryButton = showRetryButton;
    }

    closeMessage() {
        this.$modalInstance.close({});
    }

    retryDownload() {
        this.$modalInstance.close({retryDownload: true});
    }
}
