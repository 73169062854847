/* eslint-disable max-classes-per-file */
import {Nullable} from '@techsee/techsee-common';
import {TechseeMediaServiceBase} from '@techsee/techsee-media-service/lib/MediaServiceBase';
import {ClientWebRtcInfo} from '@techsee/techsee-common/lib/data-contracts/ClientInfo';
import {
    IMediaEnvironment,
    MediaPublisherParams,
    MediaRequestSuccessResult,
    MediaSessionParams,
    RemoteTrackStats,
    StreamCreatedEventArgs,
    StreamDestroyedEventArgs
} from '@techsee/techsee-media-service/lib/MediaContracts';
import {
    DEFAULT_VIDEO_WAIT_TIMEOUT_SECONDS,
    KnownMediaStream,
    MediaServiceType,
    MediaSessionDisconnectReason,
    MediaStreamUnregisterReason,
    SessionClientRole
} from '@techsee/techsee-media-service/lib/MediaConstants';
import {TechseeMediaPublisher} from '@techsee/techsee-media-service/lib/MediaPublisher';
import {TechseeMediaStream} from '@techsee/techsee-media-service/lib/TechseeMediaStream';
import {getMediaTracer} from '@techsee/techsee-media-service/lib/MediaUtils/MediaTracer';
import includes from 'lodash/includes';
import assign from 'lodash/assign';
import head from 'lodash/head';
import cloneDeep from 'lodash/cloneDeep';
import forEach from 'lodash/forEach';
import {RECORDING_STATUS} from '@techsee/techsee-common/lib/constants/room.constants';

const tracer = getMediaTracer('DashboardAppMediaService');

export interface UserVideoStateEventArgs {
    isReady: boolean;
}

export interface RecordingStatusEventArgs {
    status: RECORDING_STATUS;
    recordingId: string;
}

enum privateEvents {
    USER_VIDEO_STATE = 'USER_VIDEO_STATE',
    VIDEO_CONNECTION_ERROR = 'VIDEO_CONNECTION_ERROR',
    AUDIO_STREAM_FAILED = 'AUDIO_STREAM_FAILED',
    RECORD_STATUS = 'RECORD_STATUS',
    RECONNECTING = 'RECONNECTING'
}

interface StreamExpectations {
    //Number of seconds to wait before emit video timeout
    videoNotReceivedTimeout: number;

    //Callback that should be called when
    onTimeout: () => void;
}

class HealthMonitor {
    private waitingPtr: any = null;

    // eslint-disable-next-line no-useless-constructor
    constructor(private streamExpectations: StreamExpectations) {}

    beginWait() {
        if (!this.waitingPtr) {
            this.waitingPtr = setTimeout(
                this.streamExpectations.onTimeout,
                this.streamExpectations.videoNotReceivedTimeout * 1000
            );
        }
    }

    stopWait() {
        clearTimeout(this.waitingPtr);
        this.waitingPtr = null;
    }
}

export class DashboardAppMediaService extends TechseeMediaServiceBase {
    private healthMonitor: Nullable<HealthMonitor> = null;

    private sessionParams: any = null;

    constructor(environment: IMediaEnvironment, webRtcSupportInfo: ClientWebRtcInfo) {
        super(environment, webRtcSupportInfo);

        this.videoStreamWaitingTimeoutHandler = this.videoStreamWaitingTimeoutHandler.bind(this);
        this.initMediaServerHandlers = this.initMediaServerHandlers.bind(this);
        this.removeMediaServerHandlers = this.removeMediaServerHandlers.bind(this);

        this.onStreamCreated((eventArgs: StreamCreatedEventArgs) => {
            if (eventArgs.streamType === KnownMediaStream.USER_VIDEO_STREAM) {
                this.emitEvent(privateEvents.USER_VIDEO_STATE, {isReady: true});
                this.healthMonitor && this.healthMonitor.stopWait();
            }

            if (
                this._serviceOptions &&
                this._serviceOptions.mediaServiceType !== MediaServiceType.OPENTOK &&
                ((this.sessionParams.clientRole === SessionClientRole.AGENT &&
                    eventArgs.streamType === KnownMediaStream.AGENT_AUDIO_STREAM) ||
                    (this.sessionParams.clientRole === SessionClientRole.OBSERVER &&
                        eventArgs.streamType === KnownMediaStream.OBSERVER_AUDIO_STREAM))
            ) {
                this.pauseAudioStream(true);
            }
        });

        this.onStreamDestroyed((eventArgs: StreamDestroyedEventArgs) => {
            if (eventArgs.streamType === KnownMediaStream.USER_VIDEO_STREAM) {
                this.emitEvent(privateEvents.USER_VIDEO_STATE, {isReady: false});

                if (eventArgs.reason === MediaStreamUnregisterReason.ClosedRemotely && this.isSessionActive) {
                    tracer.info('Start waiting for stream');
                    this.healthMonitor && this.healthMonitor.beginWait();
                }
            }
        });
    }

    pauseAudioStream(isPaused: boolean) {
        this.changeEnableForKnownStream(
            this.sessionParams.clientRole === SessionClientRole.AGENT
                ? KnownMediaStream.AGENT_AUDIO_STREAM
                : KnownMediaStream.OBSERVER_AUDIO_STREAM,
            isPaused
        );
    }

    getUserVideoStats(parseReports: boolean = false): Promise<any> {
        return this.getStatsForRemoteTrack(KnownMediaStream.USER_VIDEO_STREAM)
            .catch((err) => {
                tracer.error('Failed to get user video stats from track', err);

                return undefined;
            })
            .then((userVideoStats?: RemoteTrackStats) => {
                const reports = userVideoStats && userVideoStats.trackStats;

                if (!parseReports || !reports) {
                    return userVideoStats || {};
                }

                const matches: any = [];

                if (this._serviceOptions?.mediaServiceType === MediaServiceType.OPENTOK) {
                    forEach(reports, (report: any) => {
                        if (report.video && report.video.bytesReceived) {
                            matches.push({
                                bytesReceived: report.video.bytesReceived,
                                timestamp: report.timestamp
                            });
                        }
                    });
                } else {
                    reports.forEach((report: any) => {
                        if (includes(['inbound-rtp', 'ssrc'], report.type)) {
                            matches.push(report);
                        }
                    });
                }

                return assign(userVideoStats, {parsedTrackStats: {video: head(matches)}});
            });
    }

    onRecordStatusChange(callback: (eventArgs: RecordingStatusEventArgs) => void) {
        this.registerEventCallback(privateEvents.RECORD_STATUS, callback);
    }

    onReconnectingMediaServer(callback: () => void) {
        this.registerEventCallback(privateEvents.RECONNECTING, callback);
    }

    onUserVideoStateChange(callback: (eventArgs: UserVideoStateEventArgs) => void) {
        this.registerEventCallback(privateEvents.USER_VIDEO_STATE, callback);
    }

    onUserVideoStreamTimeout(callback: () => void) {
        this.registerEventCallback(privateEvents.VIDEO_CONNECTION_ERROR, callback);
    }

    onAudioStreamFailed(callback: () => void) {
        this.registerEventCallback(privateEvents.AUDIO_STREAM_FAILED, callback);
    }

    get mediaServiceType() {
        return this._serviceOptions && this._serviceOptions.mediaServiceType;
    }

    connectToSession(sessionParams: MediaSessionParams, isMediaSessionMode?: boolean): Promise<void> {
        const timeout = this._serviceOptions!.videoNotReceivedTimeoutSeconds || DEFAULT_VIDEO_WAIT_TIMEOUT_SECONDS;
        const sessionParamToSend = cloneDeep(sessionParams);

        this.sessionParams = sessionParams;

        if (!this.healthMonitor && isMediaSessionMode) {
            const expectations: StreamExpectations = {
                videoNotReceivedTimeout: this._environment.isIE11() ? timeout * 10 : timeout,
                onTimeout: this.videoStreamWaitingTimeoutHandler
            };

            this.healthMonitor = new HealthMonitor(expectations);
        } else {
            this.healthMonitor!.stopWait();
        }

        if (this._serviceOptions && this._serviceOptions.mediaServiceType === MediaServiceType.MEDIASERVER) {
            assign(sessionParamToSend, {
                initHandlers: this.initMediaServerHandlers,
                removeHandlers: this.removeMediaServerHandlers
            });
        }

        return super
            .connectToSession(sessionParamToSend)
            .then(() => {
                if (isMediaSessionMode) {
                    this.healthMonitor!.beginWait();
                }

                this.recordingEventsListener();
            })
            .catch((err) => {
                this.healthMonitor!.stopWait();
                throw err;
            });
    }

    connectToMediaServer() {
        const timeout = this._serviceOptions!.videoNotReceivedTimeoutSeconds || DEFAULT_VIDEO_WAIT_TIMEOUT_SECONDS;

        if (!this.healthMonitor) {
            const expectations: StreamExpectations = {
                videoNotReceivedTimeout: this._environment.isIE11() ? timeout * 10 : timeout,
                onTimeout: this.videoStreamWaitingTimeoutHandler
            };

            this.healthMonitor = new HealthMonitor(expectations);
        }

        this.healthMonitor!.beginWait();
    }

    stopWaitForMediaServer() {
        this.healthMonitor!.stopWait();
    }

    protected disconnectFromSessionInternal(reason: MediaSessionDisconnectReason): Promise<void> {
        tracer.info('disconnect from session internal', reason);

        if (reason === MediaSessionDisconnectReason.ForcedByConsumer) {
            this.healthMonitor && this.healthMonitor.stopWait();
        }

        return super.disconnectFromSessionInternal(reason);
    }

    protected createMediaPublisher(destinationRole: SessionClientRole): Promise<Nullable<TechseeMediaPublisher>> {
        const resultStream: Nullable<TechseeMediaPublisher> = null;
        const audioStream = this.getRegisteredStreamByType(
            destinationRole === SessionClientRole.OBSERVER
                ? KnownMediaStream.OBSERVER_AUDIO_STREAM
                : KnownMediaStream.AGENT_AUDIO_STREAM
        );

        if (this.isVoipEnabled && audioStream) {
            const publisherParams: MediaPublisherParams = {
                destinationRole: destinationRole,
                streamTypes: [KnownMediaStream.USER_AUDIO_STREAM]
            };

            return Promise.resolve(new TechseeMediaPublisher(publisherParams, [audioStream.mediaTrack]));
        }

        return Promise.resolve(resultStream);
    }

    protected getLocalMediaImplementation(): Promise<void> {
        if (this.isVoipEnabled) {
            return this._localStreamsManager
                .getUserMediaStream({audio: true, video: false})
                .then((streamResult: MediaRequestSuccessResult) => {
                    tracer.info('getLocalMediaImplementation stream result', streamResult);
                    const regPromises: any[] = [];

                    if (streamResult.isNew) {
                        streamResult.mediaStream.getTracks().forEach((mediaTrack) => {
                            if (mediaTrack.kind === 'audio') {
                                const newDedicatedStream = new TechseeMediaStream(
                                    mediaTrack,
                                    this.sessionParams.clientRole === SessionClientRole.AGENT
                                        ? KnownMediaStream.AGENT_AUDIO_STREAM
                                        : KnownMediaStream.OBSERVER_AUDIO_STREAM,
                                    false
                                );

                                regPromises.push(this.registerStream(newDedicatedStream));
                            }
                        });
                    }

                    return Promise.all(regPromises).then(() => undefined);
                })
                .catch((err: any) => {
                    tracer.error('Failed to establish audio stream', err);
                    this.emitEvent(privateEvents.AUDIO_STREAM_FAILED, {err});

                    throw err;
                });
        }

        return Promise.resolve();
    }

    private videoStreamWaitingTimeoutHandler() {
        this.disconnectFromSession().catch(() => undefined);
        tracer.info('Emitting VIDEO_CONNECTION_ERROR');
        this.emitEvent(privateEvents.VIDEO_CONNECTION_ERROR);
    }

    private recordingEventsListener() {
        this.onRecordStarted((eventArgs: any) => {
            this.emitEvent(privateEvents.RECORD_STATUS, {
                status: RECORDING_STATUS.started,
                recordingId: eventArgs.id
            });
        });

        this.onRecordStopped((eventArgs: any) => {
            this.emitEvent(privateEvents.RECORD_STATUS, {status: RECORDING_STATUS.ready, recordingId: eventArgs.id});
        });

        this.onReconnecting(() => {
            this.emitEvent(privateEvents.RECONNECTING);
        });
    }

    private initMediaServerHandlers() {
        // unload event is deprecated in IOS, using pagehide instead:
        // https://developer.apple.com/library/archive/documentation/AppleApplications/Reference/SafariWebContent/HandlingEvents/HandlingEvents.html#//apple_ref/doc/uid/TP40006511-SW5
        window.addEventListener('pagehide', this.disconnectFromMediaSession);
    }

    private removeMediaServerHandlers() {
        window.removeEventListener('pagehide', this.disconnectFromMediaSession);
    }
}
