'use strict';

import {EventEmitter} from 'events';

export class TsBusyService extends EventEmitter {
    constructor() {
        'ngInject';

        super();
    }

    busy() {
        this.emit('busy');
    }

    free() {
        this.emit('free');
    }
}
