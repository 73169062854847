import React from 'react';
import {observer} from 'mobx-react';
import {PrimaryButton} from '@techsee/techsee-ui-common/lib/forms/buttons/primary';
import {SecondaryButton} from '@techsee/techsee-ui-common/lib/forms/buttons/secondary';
import {ButtonTypeAttributes} from '@techsee/techsee-ui-common/lib/forms/buttons/generic';
import {Spinner} from '@techsee/techsee-ui-common/lib/spinner';
import {SimpleLabel} from '@techsee/techsee-ui-common/lib/forms/labels/simple';
import {GenericForm} from '../common-forms/form';
import {Icon} from '@techsee/techsee-ui-common/lib/icons/simple-icon';
import {Datepicker} from '@techsee/techsee-ui-common/lib/datepicker';
import {FormInput} from '@techsee/techsee-ui-common/lib/forms/input';

@observer
export class VJForm extends GenericForm {
    renderInternal() {
        const {ctrl, translate, buttonText, labelText} = this.props;

        const {
            formModel,
            formError,
            changeEndDate,
            changeStartDate,
            onTodayClick,
            onLast7DaysClick,
            onYesterdayClick,
            dateFormat,
            formCustomerId,
            formStartDateValue,
            maxDate,
            minDate
        } = ctrl;

        const startDateField = (
            <div className='start-date-picker'>
                <SimpleLabel className='simple-label'>
                    {translate('REACT.INVITE.VIEW.START_DATE_INPUT_LABEL')}
                </SimpleLabel>
                <Datepicker
                    dateFormat={dateFormat}
                    onDateChange={(date: Date) => changeStartDate && changeStartDate(date)}
                    placeholderText={dateFormat?.toUpperCase()}
                    maxDate={maxDate}
                    minDate={minDate}
                />
            </div>
        );

        const endDateField = (
            <div className='end-date-picker'>
                <SimpleLabel className='simple-label'>
                    {translate('REACT.INVITE.VIEW.END_DATE_INPUT_LABEL')}
                </SimpleLabel>
                <Datepicker
                    dateFormat={dateFormat}
                    onDateChange={(date: Date) => changeEndDate && changeEndDate(date)}
                    placeholderText={dateFormat?.toUpperCase()}
                    maxDate={maxDate}
                    minDate={minDate}
                />
            </div>
        );

        const customerRefField = (
            <FormInput
                uid='customerRef'
                className='input-field'
                model={formModel.fields[formModel.fieldNames.customerRef]}
            />
        );

        const quickSelectAction = (
            <div className='quick-action-section'>
                <SimpleLabel className='simple-label'>{translate('REACT.INVITE.VIEW.QUICK_SELECT_LABEL')}</SimpleLabel>
                <div className='quick-action-buttons'>
                    <SecondaryButton
                        onClick={onTodayClick}
                        className='quick-select-button'
                        type={ButtonTypeAttributes.Submit}
                        disabled={ctrl.isLoading}>
                        {translate('REACT.INVITE.VIEW.TODAY_BUTTON')}
                    </SecondaryButton>
                    <SecondaryButton
                        onClick={onYesterdayClick}
                        className='quick-select-button'
                        type={ButtonTypeAttributes.Submit}
                        disabled={ctrl.isLoading}>
                        {translate('REACT.INVITE.VIEW.YESTERDAY_BUTTON')}
                    </SecondaryButton>
                    <SecondaryButton
                        onClick={onLast7DaysClick}
                        className='quick-select-button'
                        type={ButtonTypeAttributes.Submit}
                        disabled={ctrl.isLoading}>
                        {translate('REACT.INVITE.VIEW.LAST_7_DAYS_BUTTON')}
                    </SecondaryButton>
                </div>
            </div>
        );

        return (
            <div className={'generic-form-container'}>
                <SimpleLabel>{translate(labelText)}</SimpleLabel>
                <form className='use-common-styles generic-form' onSubmit={(event) => ctrl.onSubmit(event)}>
                    <div className='input-field'>{customerRefField}</div>

                    <div className='pick-date'>{startDateField}</div>

                    <div className='pick-date'>{endDateField}</div>

                    {formError && <div className='error'>{translate('REACT.INVITE.VJ.FORM.ERROR')}</div>}

                    <PrimaryButton
                        className={ctrl.isLoading ? 'loading-button' : 'search-button'}
                        type={ButtonTypeAttributes.Submit}
                        disabled={ctrl.isLoading || (!formCustomerId && !formStartDateValue)}>
                        {ctrl.isLoading ? (
                            <Spinner />
                        ) : (
                            <div>
                                {translate(buttonText)}
                                <Icon iconName={'arrow-next'} />
                            </div>
                        )}
                    </PrimaryButton>

                    <div className='quick-selection'>{quickSelectAction}</div>
                </form>
            </div>
        );
    }
}
