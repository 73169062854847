import {inject, IReactComponent} from 'mobx-react';
import * as component from './component';

const mapStoresToProps = (stores: any) => ({
    translate: stores.localizationService.translate.bind(stores.localizationService)
});

const LeftSide = inject(mapStoresToProps)(component.LeftSide) as IReactComponent;

export {LeftSide};
export default LeftSide;
