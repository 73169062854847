'use strict';

import {getRootStore} from '../../_react_/app.bootstrap';
import {IAuthExpiryService} from './ts-auth-expiry.service';

export class AuthExpiryNgService {
    private _service: IAuthExpiryService;

    constructor() {
        this._service = getRootStore().authExpiryService;
    }

    get service() {
        return this._service;
    }
}
