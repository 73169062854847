'use strict';

export const mediaFrameUIParams = {
    WIDTH_PADDING: 400,
    HEIGHT_PADDING: 210,
    WIDTH_PADDING_NEW: 530,
    HEIGHT_PADDING_NEW: 140,
    MOBILE_DASHBOARD_WIDTH_PADDING_PORTRAIT: 60,
    MOBILE_DASHBOARD_HEIGHT_PADDING_PORTRAIT: 172,
    MOBILE_DASHBOARD_WIDTH_PADDING_LANDSCAPE: 172,
    MOBILE_DASHBOARD_HEIGHT_PADDING_LANDSCAPE: 60,
    OLD_FOOTER_PADDING: 50,
    NEW_FOOTER_PADDING: 0,
    TOOLBAR_SIZE: 60,
    WIDTH_PADDING_IMAGES: 400,
    WIDTH_PADDING_IMAGES_OLD_UI: 640,
    ALLOWED_RATIOS: [16 / 9, 4 / 3, 9 / 16, 3 / 4],
    MINIMIZED_VIDEO_MAX_WIDTH: 200,
    MINIMIZED_VIDEO_MAX_HEIGHT: 200,
    MOBILE_VIDEO_CONTAINER_PADDING: 36,
    MOBILE_CANVAS_CONTAINER_PADDING: 150,
    MOBILE_EDIT_CANVAS_PADDING: 96,
    MOBILE_DASHBOARD_MINIMIZED_VIDEO_WIDTH: 85,
    MOBILE_DASHBOARD_MINIMIZED_VIDEO_HEIGHT: 115,
    MINIMIZED_VIDEO_LEFT_OFFSET: 100,
    MINIMIZED_VIDEO_TOP_OFFSET: 200,
    MINIMIZED_VIDEO_LEFT_OFFSET_NEW: 30,
    MINIMIZED_VIDEO_TOP_OFFSET_NEW: 360,
    MINIMIZED_VIDEO_RIGHT_OFFSET_NEW_BOTTOM_RIGHT: 260,
    MINIMIZED_VIDEO_TOP_OFFSET_NEW_BOTTOM_RIGHT: 50,
    CO_BROWSING_OFFSET_WIDTH: 390,
    CO_BROWSING_OFFSET_HEIGHT: 255
};
