'use strict';

import filter from 'lodash/filter';

import tsVideoPlayerView from './ts-video-player.view.html';
import {MAX_TAG_LENGTH} from './ts-video-player.settings.js';
import './ts-video-player.style.scss';

/**
 * A control that display a video and allows for playback on top of it.
 *
 * Attributes:
 *
 *  video-model: Model for the video URL
 */
class TsVideoPlayerController {
    constructor($scope, $rootScope, tsVideoControl, $q, mediaFrameUIParams) {
        'ngInject';

        this.$scope = $scope;
        this.videoControlService = tsVideoControl;
        this.BASE_PATH = BASE_PATH;
        this.maxTagLength = MAX_TAG_LENGTH;
        this.$q = $q;
        this.mediaFrameUIParams = mediaFrameUIParams;
        this.tagWhiteSrc = $rootScope.requireImage('tag-white.png');
        this.videoNotShownImage = $rootScope.requireImage('video-preloader.gif');
        this.tags = this.videoControlService.getTags();
    }

    /* Connect the video control
     *
     * @param video {DOMElement} video element to control
     * @param options {Object} extra options for video control initialization
     */
    setVideoElement(videoEl, options) {
        this.videoControlService.setVideoElement(videoEl, options);
    }

    /* Start controlling a new video
     *
     * @param video {Object { url: String }} new video to use
     */
    setVideo(video) {
        this.videoControlService.setVideo(video);

        if (this.enableTagging) {
            this.tags = video.tags ? video.tags.slice() : [];

            this.videoControlService.setTags(this.tags);
            this.tagsOpen = false;
        }
    }

    addPresetTag(tag) {
        if (!this.tags || this.tags.length === 0) {
            this.tags = [tag];
        } else if (this.tags.indexOf(tag) < 0) {
            this.tags = this.tags.concat(tag);
        }
    }

    closeTags() {
        this.tagsOpen = false;
    }

    openTagsPanel(e) {
        this.tagsOpen = true;
        e.stopPropagation();
    }

    getPresetTags($query) {
        const regex = new RegExp(`${$query}`, 'i'),
            results = filter(this.presetTags, (tag) => tag.text.match(regex));

        return this.$q.when(results);
    }
}

function linkFn(scope, element, attrs, ctrl) {
    const videoEl = element.find('video')[0],
        tags = element.find('.video-tags-view-container'),
        // The size of video container will define
        // the maximum size for the video
        maxWidth = element.width(),
        maxHeight = element.height();

    // As soon as we get the element, we pass it over to fabric, along with
    // some default options that we want for it
    ctrl.setVideoElement(videoEl, {
        width: maxWidth,
        height: maxHeight,
        maxWidth: maxWidth,
        maxHeight: maxHeight,
        allowedRatios: ctrl.mediaFrameUIParams.ALLOWED_RATIOS
    });

    // eslint-disable-next-line no-redeclare
    /* globals Hamster */
    const scrollBlock = new Hamster(tags[0]);

    tags.data('hamster', scrollBlock);

    scrollBlock.wheel((e) => {
        // prevent scrolling in the tags area from changing image zoom
        e.stopPropagation();
    });

    // Every time a new video is selected, we set the video background
    scope.$watch(
        () => ctrl.model,
        (resource) => {
            if (resource && resource.isVideo && resource.url) {
                ctrl.setVideo(resource);
            }
        }
    );
}

export function tsVideoPlayerDirective() {
    return {
        template: tsVideoPlayerView,
        replace: true,
        restrict: 'E',
        scope: {},
        bindToController: {
            model: '=videoModel',
            enableTagging: '=',
            presetTags: '=',
            dashboardTags: '='
        },
        controller: TsVideoPlayerController,
        controllerAs: 'vm',
        link: linkFn
    };
}
