'use strict';

export class TsTimedOperationService {
    private _locked: boolean = false;

    private _timer: number | null = null;

    private _clear() {
        if (this._timer) {
            clearTimeout(this._timer);
            this._timer = null;
        }
    }

    lock(timeout: number) {
        this._locked = true;

        this._clear();

        if (timeout) {
            this._timer = setTimeout(() => this.release(), timeout) as unknown as number;
        }
    }

    lockAttempt(timeout: number) {
        if (this.locked) {
            return false;
        }

        this.lock(timeout);

        return true;
    }

    release() {
        this._clear();

        this._locked = false;
    }

    get locked() {
        return this._locked;
    }
}
