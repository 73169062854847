import React, {Component} from 'react';
import {Popover} from 'react-bootstrap';
import {PrimaryButton} from '@techsee/techsee-ui-common/lib/forms/buttons/primary';
import {Icon} from '@techsee/techsee-ui-common/lib/icons/simple-icon';

import './styles.scss';

interface IMiniDashboardPopup {
    onPrimaryButton?: () => void;
    onExitButton?: () => void;
    title: string;
    info: string;
    primaryButtonText?: string;
    isErrorStatus?: boolean;
}

export class MiniDashboardPopup extends Component<IMiniDashboardPopup> {
    render() {
        const {onPrimaryButton, onExitButton, title, info, primaryButtonText, isErrorStatus} = this.props;

        return (
            <Popover className={'miniDashboard-popup-container'} arrowOffsetTop={20} id={'miniDashboard-popover'}>
                {onExitButton && (
                    <div onClick={onExitButton} className={'miniDashboard-popup-exit'}>
                        <Icon iconName={'exit'}></Icon>
                    </div>
                )}
                {title && (
                    <div className={'miniDashboard-popup-title-container'}>
                        <h1 className={'miniDashboard-popup-title'}>{title}</h1>
                    </div>
                )}
                {info && (
                    <div className={'miniDashboard-popup-info-container'}>
                        <p className={'miniDashboard-popup-info'}>{info}</p>
                    </div>
                )}

                <div className={'miniDashboard-popup-buttons-container'}>
                    {onPrimaryButton && primaryButtonText && (
                        <PrimaryButton
                            onClick={onPrimaryButton}
                            className={`miniDashboard-popup-button-primary ${isErrorStatus ? 'error-status' : ''}`}>
                            {primaryButtonText}
                        </PrimaryButton>
                    )}
                </div>
            </Popover>
        );
    }
}
