'use strict';

import tsToastView from './ts-toast.view.html';

const DEFAULT_TIMEOUT = 2000;

/*
 * Displays a toast notification that fades out after a few seconds
 *
 * options:
 *  `toast-duration`: milliseconds before the toast fades out (default 2000)
 *  `toast-message`: An object containing at least a 'text' attribute or a string.
 *  `toast-transclude`: Use transclusion instead of the default template contents.
 */
class TsToastController {
    constructor($element, $attrs, $scope, $timeout, $window) {
        'ngInject';

        this.$timeout = $timeout;
        this.$element = $element;
        this.duration = $attrs.toastDuration || DEFAULT_TIMEOUT;
        this.transclude = $attrs.toastTransclude;
        this.$window = $window;
    }

    show() {
        if (!this.message) {
            return;
        }

        if (this._currentTimer) {
            this.$timeout.cancel(this._currentTimer);
        }

        this.visible = true;

        this._currentTimer = this.$timeout(() => {
            this.visible = false;
            this._currentTimer = null;
        }, this.duration);
    }

    hide() {
        if (this._currentTimer) {
            this.$timeout.cancel(this._currentTimer);
            this._currentTimer = null;
        }

        this.visible = false;
    }
}

function linkFn(scope, element, attrs, ctrl) {
    element.on('click', () => ctrl.hide());

    scope.$watch(
        () => ctrl.message,
        () => {
            ctrl.show();
        }
    );
}

export function tsToastDirective() {
    return {
        template: tsToastView,
        replace: true,
        transclude: true,
        restrict: 'E',
        scope: {},
        bindToController: {
            message: '=toastMessage'
        },
        controller: TsToastController,
        controllerAs: 'vm',
        link: linkFn
    };
}
