import React from 'react';
import {
    Spinner,
    SpinnerTypeOptions,
    SpinnerSizes,
    SpinnerBackgroundColors,
    SpinnerColors
} from '@techsee/techsee-ui-common/lib/spinner';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';

import './screen-loader.scss';

export class ScreenLoader extends TechseeBaseComponent {
    renderInternal() {
        return (
            <div className={'use-common-styles state-loading-screen'}>
                <Spinner
                    spinnerType={SpinnerTypeOptions.CIRCULAR}
                    sizeName={SpinnerSizes.Large}
                    backgroundColorName={SpinnerBackgroundColors.Transparent}
                    colorName={SpinnerColors.LightBlue}
                />
            </div>
        );
    }
}
