'use strict';

import './error-dialog.style.scss';
import errorDialogView from './error-dialog.view.html';
import {ErrorDialogController} from './error-dialog.controller.js';
import {getRootStore} from '../../../../_react_/app.bootstrap';

export class ErrorDialogModal {
    constructor($modal) {
        'ngInject';

        this.$modal = $modal;
        this.instance = null;
        this.environmentDetect = getRootStore().environmentService;
        this.browserUtilsService = getRootStore().browserUtilsService;
        this.isMobile = this.environmentDetect.isMobile(getRootStore().displayTabletAsDesktop);
    }

    getModalClass() {
        if (this.isMobile) {
            return ' mobile-modal';
        } else if (this.browserUtilsService.getFromSessionStorage('miniDashboard')) {
            return ' mini-modal-dialog';
        }

        return '';
    }

    show(message, dir, showRetryButton) {
        this.instance = this.$modal.open({
            animation: true,
            template: errorDialogView,
            controller: ErrorDialogController,
            controllerAs: 'vm',
            backdropClass: 'backdrop-hidden',
            windowClass: 'on-video-alert' + this.getModalClass(),
            resolve: {
                message: () => message,
                dir: () => dir,
                showRetryButton: () => Boolean(showRetryButton)
            }
        });

        return this.instance;
    }

    hide() {
        if (this.instance) {
            this.instance.close();
        }
    }
}
