'use strict';

import tsImageShareModesView from './ts-image-share-modes.view.html';
import './ts-image-share-modes.style.scss';

export const ImageShareLayouts = {
    DEFAULT: 'default',
    SELF_SERVICE: 'self-service'
};

class TsImageShareModesController {
    getLayoutType() {
        const layout = this.layoutType;

        return angular.isDefined(layout) && layout !== ImageShareLayouts.DEFAULT ? layout : '';
    }

    delegateImageLoaded(event) {
        if (this.onImageLoaded) {
            this.onImageLoaded({event});
        }
    }
}

export function tsImageShareModesDirective() {
    return {
        restrict: 'EA',
        replace: true,
        scope: true,
        controller: TsImageShareModesController,
        controllerAs: 'vm',
        bindToController: {
            isActive: '=',
            displayBadge: '=',
            displayBorder: '=',
            badgeValue: '@',
            width: '@',
            height: '@',
            url: '@',
            data: '=',
            onImageLoaded: '&',
            layoutType: '@'
        },
        template: tsImageShareModesView
    };
}
