'use strict';
import tsCaptureImageView from './ts-capture-image.view.html';
import './ts-capture-image.style.scss';

export function tsCaptureImage() {
    return {
        template: tsCaptureImageView,
        scope: true
    };
}
