import React from 'react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {
    IWizardHeaderComponent,
    IWizardContentComponent,
    IWizardToolbarComponent
} from '@techsee/techsee-ui-common/lib/wizard/contracts';
import {WizardDefaultContent} from '@techsee/techsee-ui-common/lib/wizard/components/content/DefaultContent';
import {WizardDefaultToolbar} from '@techsee/techsee-ui-common/lib/wizard/components/toolbar/DefaultToolbar';
import {TechseeWizard} from '@techsee/techsee-ui-common/lib/wizard/components/TechseeWizard';
import {IInviteWizardController} from '../_contracts/InviteContracts';
import {InviteWizardHeader} from './InviteWizard.Header';
import AgentPerformance from '../../../components/agent-performance';
import {SimpleModal} from '../../../components/simple-modal/component';

import './styles.scss';

interface IInvitationWizardProps {
    wizardController: IInviteWizardController;
}

export class InviteWizardComponent extends TechseeBaseComponent<IInvitationWizardProps> {
    renderInternal() {
        const WizardHeader = (props: IWizardHeaderComponent<IInviteWizardController>) => (
            <div className={'invite-wizard-header'}>
                <InviteWizardHeader
                    wizardController={props.wizardController}
                    wizClassName={props.wizClassName}
                    className={'col-sm-4'}
                />
            </div>
        );

        const WizardToolbar = (props: IWizardToolbarComponent<IInviteWizardController>) => (
            <WizardDefaultToolbar
                wizardController={props.wizardController}
                wizClassName={props.wizClassName}
                className={'invite-wizard-toolbar col-sm-12'}
            />
        );

        const WizardContent = (props: IWizardContentComponent<IInviteWizardController>) => (
            <React.Fragment>
                <SimpleModal ctrl={props.wizardController.unauthorizedModal} />
                {props.wizardController.displayAgentPerformance ? <AgentPerformance /> : <React.Fragment />}
                <WizardDefaultContent wizardController={props.wizardController} wizClassName={props.wizClassName} />
            </React.Fragment>
        );

        const wizardAttrs = {
            className: 'invite-wizard',
            wizardController: this.props.wizardController,
            wizardHeader: WizardHeader,
            wizardContent: WizardContent,
            wizardToolbar: WizardToolbar
        };

        return <TechseeWizard<IInviteWizardController> {...wizardAttrs} />;
    }
}
