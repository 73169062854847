import {ITranslate} from '../../../services/LocalizationService';

export interface IDashboardFooterController {
    sessionId: string;
    translate: ITranslate;
}

export class DashboardFooterController implements IDashboardFooterController {
    private readonly _translate: ITranslate;

    private readonly _sessionId: string;

    constructor(sessionId: string, translate: ITranslate) {
        this._translate = translate;
        this._sessionId = sessionId;
    }

    get translate() {
        return this._translate;
    }

    get sessionId() {
        return this._sessionId;
    }
}
