'use strict';

import entryView from './entry.view.html';
import entryWizardView from './entry-wizard.view.html';
import {EntryController} from './entry.controller.js';

function config($stateProvider) {
    $stateProvider.state('dashboard.entry', {
        url: '/entry',
        views: {
            'desktop-dashboard-view': {
                template: entryView + entryWizardView,
                controller: 'entryController',
                controllerAs: 'entry'
            },
            'mobile-dashboard-view': {
                template: entryView + entryWizardView,
                controller: 'entryController',
                controllerAs: 'entry'
            }
        },
        resolve: {
            offlineRoom: (roomData, tsStateHelper, $stateParams) => {
                if (roomData.offline) {
                    tsStateHelper.safeGo('dashboard.main', $stateParams);
                }
            }
        }
    });
}

export default angular
    .module('states.dashboard.entry', [])
    .config(config)
    .controller('entryController', EntryController);
