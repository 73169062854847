'use strict';

import forEach from 'lodash/forEach';

/**
 * Service that abstracts the browser-specific differences in the
 * visibilitychange event API and allows for listening to it
 */

export interface IVisibilityChange {
    visible(callback: (...args: any[]) => void): void;
    hidden(callback: (...args: any[]) => void): void;
}

export class TsVisibilityChange implements IVisibilityChange {
    private window: any;

    private _callbacks: {visible: any[]; hidden: any[]};

    private visibilityEventsDisabled: boolean = false;

    private hiddenAttr: string = '';

    private visibilitychange: string = '';

    private focusEvent: string = '';

    private blurEvent: string = '';

    private visibilityChangeHandler: () => void = () => '';

    private onBlur: () => void = () => '';

    private onFocus: () => void = () => '';

    constructor(window: any) {
        'ngInject';

        this.window = window;
        // visibilitychange is one event, but we want to expose an API
        // that handles it as two: visible and hidden, so we maintain
        // separate callback registries for the off method to avoid
        // de-registering a function that listens to 'visible' when it is
        // de-registered from 'hidden' and vice-versa
        this._callbacks = {
            visible: [],
            hidden: []
        };

        this._init();
        this._addHandlers();
    }

    _init() {
        const doc: any = this.window.document;

        if (typeof doc.hidden !== 'undefined') {
            this.hiddenAttr = 'hidden';
            this.visibilitychange = 'visibilitychange';
        } else if (typeof doc.mozhidden !== 'undefined') {
            this.hiddenAttr = 'mozhidden';
            this.visibilitychange = 'mozvisibilitychange';
        } else if (typeof doc.mshidden !== 'undefined') {
            this.hiddenAttr = 'mshidden';
            this.visibilitychange = 'msvisibilitychange';
        } else if (typeof doc.webkithidden !== 'undefined') {
            this.hiddenAttr = 'webkithidden';
            this.visibilitychange = 'webkitvisibilitychange';
        }

        this.focusEvent = 'focus';
        this.blurEvent = 'blur';
    }

    _addHandlers() {
        if (!this.isSupported() || this.visibilityEventsDisabled) {
            return;
        }

        const doc = this.window.document;

        this.visibilityChangeHandler = () => {
            // @ts-ignore
            if (doc[this.hiddenAttr]) {
                this._callCallbacks(this._callbacks.hidden);
            } else {
                this._callCallbacks(this._callbacks.visible);
            }
        };

        doc.addEventListener(this.visibilitychange, this.visibilityChangeHandler);

        this.onBlur = () => {
            this._callCallbacks(this._callbacks.hidden);
        };
        this.onFocus = () => {
            this._callCallbacks(this._callbacks.visible);
        };

        this.window.addEventListener(this.focusEvent, this.onFocus);
        this.window.addEventListener(this.blurEvent, this.onBlur);
    }

    _callCallbacks(callbacks: any) {
        forEach(callbacks, (cb) => cb());
    }

    isSupported() {
        return !!(this.hiddenAttr && this.visibilitychange);
    }

    disableVisibilityEvents() {
        if (this.visibilityEventsDisabled) {
            return;
        }

        this.visibilityEventsDisabled = true;
        this.window.document.removeEventListener(this.visibilitychange, this.visibilityChangeHandler);
        this.window.removeEventListener(this.focusEvent, this.onFocus);
        this.window.removeEventListener(this.blurEvent, this.onBlur);
    }

    visible(callback: (...args: any[]) => void) {
        this.on('visible', callback);
    }

    hidden(callback: (...args: any[]) => void) {
        this.on('hidden', callback);
    }

    /*
     * Attach a handler to a visibility change event
     *
     * @param eventName - {String} 'visible' or 'hidden'
     * @param callback - {Function} callback
     */
    on(eventName: string, callback: (...args: any[]) => void) {
        if (!this.isSupported() || ['hidden', 'visible'].indexOf(eventName) === -1) {
            return;
        }

        // @ts-ignore
        this._callbacks[eventName].push(callback);
    }

    /*
     * Detach a handler from a visibility change event
     *
     * @param eventName - {String} 'visible' or 'hidden'
     * @param callback - {Function} callback
     */
    off(eventName: string, callback: (...args: any[]) => void) {
        if (!this.isSupported() || ['hidden', 'visible'].indexOf(eventName) === -1) {
            return;
        }

        // @ts-ignore
        const index = this._callbacks[eventName].indexOf(callback);

        if (index !== -1) {
            // @ts-ignore
            this._callbacks[eventName].splice(index, 1);
        }
    }
}
