import {ITranslate} from '../../services/LocalizationService';
import {action, computed, observable} from 'mobx';

export interface IShareScreenPopupController {
    readonly translate: ITranslate;
    readonly shareScreen: () => void;
    readonly stopShareScreen: () => void | undefined;
    isSharingScreen: boolean;
    isSharingScreenDisabled: boolean;
    setIsSharingScreen: (val: boolean) => void;
}

export class ShareScreenPopupController implements IShareScreenPopupController {
    private readonly _translate: ITranslate;
    private _onShareScreen: undefined | (() => Promise<void>) = undefined;
    private _onStopShareScreen: undefined | (() => void | undefined) = undefined;
    @observable private _isScreenSharing: boolean = false;
    @observable private _isScreenSharingDisabled: boolean = false;

    constructor(translate: ITranslate) {
        this._translate = translate;
        this.shareScreen = this.shareScreen.bind(this);
        this.setIsSharingScreen = this.setIsSharingScreen.bind(this);
        this.setShareScreenCallback = this.setShareScreenCallback.bind(this);
    }

    get translate() {
        return this._translate;
    }

    @action
    public setIsSharingScreenDisabled(value: boolean) {
        this._isScreenSharingDisabled = value;
    }

    @computed
    public get isSharingScreenDisabled() {
        return this._isScreenSharingDisabled;
    }

    @action
    public setIsSharingScreen(value: boolean) {
        this._isScreenSharing = value;
    }

    @computed
    public get isSharingScreen() {
        return this._isScreenSharing;
    }

    @action
    public setShareScreenCallback(onShareScreen: () => Promise<void>, onStopShareScreen: () => void | undefined) {
        this._onShareScreen = onShareScreen;
        this._onStopShareScreen = onStopShareScreen;

        this.stopShareScreen = this.stopShareScreen.bind(this);
        this.shareScreen = this.shareScreen.bind(this);
    }

    @action
    public shareScreen(): undefined | Promise<void> {
        let promise = Promise.resolve();

        this.setIsSharingScreen(true);

        if (this._onShareScreen) {
            promise = this._onShareScreen().catch(() => this.setIsSharingScreen(false));
        }

        return promise;
    }

    @action
    public stopShareScreen() {
        this.setIsSharingScreen(false);

        if (this._onStopShareScreen) {
            this._onStopShareScreen();
        }
    }
}
