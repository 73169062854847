import React from 'react';
import {observer} from 'mobx-react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {IconColors} from '@techsee/techsee-ui-common/lib/icons/large-rounded-icon';
import {GuidenceProgressItem} from '../components/guidance-progress/GuidanceProgress';
import {ProgressList} from '../components/progress-list/ProgressList';
import {InviteStepsLayout} from './_Shared';
import {AgentGuidance} from '../components/agent-guidance/AgentGuidance';
import {InviteStepProps} from './_InviteGenericStep';
import {SimpleTabs, ITab} from '@techsee/techsee-ui-common/lib/tabs/simple';
import {InviteDeviceType} from '../../_contracts/InviteContracts';

@observer
export class VideoApplicationGuidanceTosStep extends TechseeBaseComponent<InviteStepProps> {
    componentDidMount() {
        this.props.ctrl.setPagePlacement('invite-terms-video-application');
    }

    get progressItems(): GuidenceProgressItem[] {
        const {translate} = this.props.ctrl;

        return [
            {
                iconColor: IconColors.Blue,
                label: translate('REACT.INVITE.PERMISSIONS.VIDEO_APPLICATION.USER_PERMISSION'),
                isActive: true,
                icon: 'tos',
                isIconAnimated: false,
                iconNoBorder: true
            },
            {
                iconColor: IconColors.DarkGray,
                label: translate('REACT.INVITE.PERMISSIONS.VIDEO_APPLICATION.CAMERA_MICROPHONE_PERMISSION'),
                isActive: false,
                icon: 'camera',
                isIconAnimated: false,
                iconNoBorder: true
            }
        ];
    }

    getTab(device: InviteDeviceType) {
        const {progressItems} = this;
        const {translate} = this.props.ctrl;

        if (device === InviteDeviceType.ios) {
            return () => (
                <div className={'guidance-progress row'}>
                    <div className={'col-left col-sm-4'}>
                        <ProgressList progressList={progressItems} />
                    </div>
                    <div className={'col-left col-sm-6 mid-col'}>
                        <AgentGuidance
                            stepType={'video-application'}
                            stepNum={1}
                            deviceType={device}
                            stepText={translate('REACT.INVITE.PERMISSIONS.VIDEO_APPLICATION.TAP_ALLOW_BUTTON')}
                        />
                    </div>
                </div>
            );
        }

        if (device === InviteDeviceType.android) {
            return () => (
                <div className={'guidance-progress row'}>
                    <div className={'col-left col-sm-4'}>
                        <ProgressList progressList={progressItems} />
                    </div>
                    <div className={'col-left col-sm-6 mid-col'}>
                        <AgentGuidance
                            stepType={'video-application'}
                            stepNum={1}
                            deviceType={device}
                            stepText={translate('REACT.INVITE.PERMISSIONS.VIDEO_APPLICATION.TAP_ALLOW_BUTTON')}
                        />
                    </div>
                    <div className={'col-left col-sm-4'}>&nbsp;</div>
                </div>
            );
        }

        return () => <React.Fragment />;
    }

    renderInternal() {
        const {translate} = this.props.ctrl;
        const _clientDeviceType = this.props.ctrl.clientDeviceType;
        const tabsList: ITab[] = [
            {
                title: _clientDeviceType === InviteDeviceType.ios ? 'iOS' : 'Android',
                content: this.getTab(_clientDeviceType)
            }
        ];

        if (_clientDeviceType === InviteDeviceType.common) {
            return <React.Fragment />;
        }

        return (
            <InviteStepsLayout
                className={'screen-share-step'}
                h1Text={translate('REACT.INVITE.PERMISSIONS.VIDEO_APPLICATION.TOS_TITLE')}
                h2Text={translate('REACT.INVITE.PERMISSIONS.VIDEO_APPLICATION.TOS_SUB_TITLE')}>
                <SimpleTabs tabsList={tabsList} uid={'screen-share-tabs'} />
            </InviteStepsLayout>
        );
    }
}
