import {IDbEventLog} from './AngularServices/AngularServices';
//@ts-ignore
import {EVENT_SOURCES, EVENT_LEVELS} from '@techsee/techsee-common/lib/constants/event-logs.constants';
import isEmpty from 'lodash/isEmpty';
import {EventLogConstant} from '../../constants/events/event-log.constant';
import {UrlUtilsService} from '@techsee/techsee-client-services/lib/services/UrlUtilsService';

export type EventSource = EVENT_SOURCES.backend | EVENT_SOURCES.client | EVENT_SOURCES.dashboard;

export interface EventLogParams {
    logType: string;
    accountId?: string;
    userId?: string;
    roomId?: string;
    meta?: any;
}

export interface IEventLogsService {
    room: string;

    debug(logParams: EventLogParams): Promise<void>;

    info(logParams: EventLogParams): Promise<void>;

    warn(logParams: EventLogParams): Promise<void>;

    error(logParams: EventLogParams): Promise<void>;
}

export class EventLogsService implements IEventLogsService {
    private readonly _logger: IDbEventLog;

    private readonly _eventSource: EventSource;

    private readonly _isObserve: string | undefined | null;

    private _room: string;

    constructor(logger: IDbEventLog, eventsSource: EventSource) {
        this._logger = logger;
        this._eventSource = eventsSource;
        this._isObserve = this._isObserver();
        this._room = EventLogConstant.NO_ROOM;
    }

    get room(): string {
        return this._room;
    }

    set room(room: string) {
        this._room = room || EventLogConstant.NO_ROOM;
    }

    _isObserver(): string | undefined | null {
        return UrlUtilsService.extractQueryParamValueFromUrl(window.location.href, 'observe');
    }

    debug(logParams: EventLogParams) {
        return this.createLogRecord(logParams, EVENT_LEVELS.debug);
    }

    info(logParams: EventLogParams) {
        return this.createLogRecord(logParams, EVENT_LEVELS.info);
    }

    warn(logParams: EventLogParams) {
        return this.createLogRecord(logParams, EVENT_LEVELS.warn);
    }

    error(logParams: EventLogParams) {
        return this.createLogRecord(logParams, EVENT_LEVELS.error);
    }

    private createLogRecord(logParams: EventLogParams, level: string): Promise<void> {
        let meta = logParams.meta;

        if (this._isObserve) {
            meta = isEmpty(meta) ? {isObserve: true} : {...meta, isObserve: true};
        }

        const dbLogParams: any = {
            type: logParams.logType,
            level: level as string,
            sentBy: this._eventSource as string,
            accountId: logParams.accountId,
            userId: logParams.userId,
            room: logParams.roomId || this.room,
            meta
        };

        return this._logger.create(dbLogParams);
    }
}
