import React from 'react';
import {observer} from 'mobx-react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {ButtonTypeAttributes} from '@techsee/techsee-ui-common/lib/forms/buttons/generic';
import {PrimaryButton} from '@techsee/techsee-ui-common/lib/forms/buttons/primary';
import {Icon} from '@techsee/techsee-ui-common/lib/icons/simple-icon';

import {ISelectVideoPublisherController} from './controller';
import './styles.scss';

export interface ISelectVideoPublisher {
    ctrl: ISelectVideoPublisherController;
}

@observer
export class SelectVideoPublisher extends TechseeBaseComponent<ISelectVideoPublisher> {
    renderInternal() {
        const {ctrl} = this.props;
        const {translate, viewVideoPublisher, setVideoPublisherSide} = ctrl;
        const startViewVideoSession = true;

        return (
            <div className={'use-common-styles publisher-selector-container'}>
                <PrimaryButton
                    type={ButtonTypeAttributes.Button}
                    className={`${viewVideoPublisher ? 'active-option' : ''} video-publisher-selector view-video`}
                    onClick={() => setVideoPublisherSide(startViewVideoSession)}>
                    <Icon iconName={'view-video'} />
                    <p>{translate('REACT.INVITE.VIEW.VIEW_VIDEO')}</p>
                </PrimaryButton>

                <PrimaryButton
                    type={ButtonTypeAttributes.Button}
                    className={`${!viewVideoPublisher ? 'active-option' : ''} video-publisher-selector show-video`}
                    onClick={() => setVideoPublisherSide(!startViewVideoSession)}>
                    <Icon iconName={'show-video'} />
                    <p>{translate('REACT.INVITE.VIEW.SHOW_VIDEO')}</p>
                </PrimaryButton>
            </div>
        );
    }
}
