'use strict';

export const SCANNERS = {
    TEXT: {
        name: 'SCAN.TYPE.TEXT',
        select: true,
        endpoint: 'text',
        reportName: 'scannedText',
        type: 'text'
    }
};
