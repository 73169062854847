'use strict';

import browserNotSupportedView from './browser-not-supported.view.html';
import {BrowserNotSupportedController} from './browser-not-supported.controller.js';
import './browser-not-supported.style.scss';

function config($stateProvider) {
    $stateProvider.state('browserNotSupported', {
        url: '/browserNotSupported',
        params: {
            browserInfo: null,
            userName: ''
        },
        views: {
            'desktop-view': {
                template: browserNotSupportedView,
                controller: 'BrowserNotSupportedController',
                controllerAs: 'browserNotSupported'
            },
            'mobile-view': {
                template: browserNotSupportedView,
                controller: 'BrowserNotSupportedController',
                controllerAs: 'browserNotSupported'
            }
        }
    });
}

export default angular
    .module('states.browserNotSupported', [])
    .config(config)
    .controller('BrowserNotSupportedController', BrowserNotSupportedController);
