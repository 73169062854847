'use strict';

import {PasswordErrorType} from '@techsee/techsee-common/lib/constants/utils.constant';

export class ChangePasswordController {
    constructor(tsStateHelper, $translate, db, tsLocaleHelper, currentUser, accountData) {
        'ngInject';

        this.stateHelper = tsStateHelper;
        this.$translate = $translate;
        this.db = db;
        this.dir = tsLocaleHelper.isRTL() ? 'rtl' : 'ltr';
        this.waiting = false;
        this.userId = currentUser._id;
        this.passwordSettings = accountData.settings.passwordSettings;
        this.allowCustom = accountData.protectedSettings.allowCustomPasswordPolicy;
    }

    submit(form) {
        this.waiting = true;

        if (!form.oldPassword) {
            this.waiting = false;

            return this.$translate('CHANGE_PASSWORD.CONTROLLER.ENTER_OLD_PASSWORD').then(
                (message) => (this.message = message)
            );
        }

        if (!form.newPassword) {
            this.waiting = false;

            return this.$translate('CHANGE_PASSWORD.CONTROLLER.ENTER_NEW_PASSWORD').then(
                (message) => (this.message = message)
            );
        }

        if (!form.confirmPassword) {
            this.waiting = false;

            return this.$translate('CHANGE_PASSWORD.CONTROLLER.CONFIRM_PASSWORD').then(
                (message) => (this.message = message)
            );
        }

        if (form.newPassword !== form.confirmPassword) {
            this.waiting = false;

            return this.$translate('CHANGE_PASSWORD.CONTROLLER.PASSWORD_MISMATCH').then(
                (message) => (this.message = message)
            );
        }

        if (form.$error.passwordLengthValidator) {
            this.waiting = false;

            return this.$translate('CHANGE_PASSWORD.CONTROLLER.MINIMUM_LENGTH').then(
                (message) => (this.message = message + this.passwordSettings.minLength)
            );
        }

        if (form.$error.passwordUppercaseValidator) {
            this.waiting = false;

            return this.$translate('CHANGE_PASSWORD.CONTROLLER.CONTAIN_UPPERCASE').then(
                (message) => (this.message = message)
            );
        }

        if (form.$error.passwordLowercaseValidator) {
            this.waiting = false;

            return this.$translate('CHANGE_PASSWORD.CONTROLLER.CONTAIN_LOWERCASE').then(
                (message) => (this.message = message)
            );
        }

        if (form.$error.passwordDigitValidator) {
            this.waiting = false;

            return this.$translate('CHANGE_PASSWORD.CONTROLLER.CONTAIN_DIGIT').then(
                (message) => (this.message = message)
            );
        }

        if (form.$error.passwordSpecialValidator) {
            this.waiting = false;

            return this.$translate('CHANGE_PASSWORD.CONTROLLER.CONTAIN_SPECIAL').then(
                (message) => (this.message = message)
            );
        }

        if (form.$error.passwordDefaultValidator) {
            this.waiting = false;

            return this.$translate('CHANGE_PASSWORD.CONTROLLER.DEFAULT_PATTERN').then(
                (message) => (this.message = message)
            );
        }

        this.db.User.update(this.userId, {
            oldPassword: form.oldPassword,
            newPassword: form.newPassword
        })
            .then(() => {
                this.stateHelper.safeGo('invite');
            })
            .catch((err) => {
                this.waiting = false;

                if (err.data && err.data.message === PasswordErrorType.usedPassword) {
                    this.$translate('CHANGE_PASSWORD.CONTROLLER.USED_PASSWORD').then(
                        (message) => (this.message = message)
                    );

                    return;
                }

                this.$translate('CHANGE_PASSWORD.CONTROLLER.ERROR').then((message) => (this.message = message));
            });
    }
}
