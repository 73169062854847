import React from 'react';
import {observer} from 'mobx-react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {InfoBoxCollapsible} from '@techsee/techsee-ui-common/lib/info-box-collapsible';
import {IconButton} from '@techsee/techsee-ui-common/lib/forms/buttons/icon';
import {ILocationInfoController} from './controller';
import {getRootStore} from '../../../../../app.bootstrap';

import './styles.scss';

export interface ILocationInfoComponent {
    ctrl: ILocationInfoController;
}

@observer
export class LocationInfoComponent extends TechseeBaseComponent<ILocationInfoComponent> {
    renderPopverContent() {
        const {ctrl} = this.props;

        return (
            <div className='location-info-popover-container'>
                <div className='location-info-popover-address'>
                    <span>{ctrl.locationInfoData || ctrl.locationInfoTitle}</span>
                    <IconButton iconName={'copy'} onClick={() => ctrl.onCopyClick(ctrl.locationInfoData)} />
                </div>
                <div className='location-info-popover-coords'>
                    <div className='location-info-popover-coords-line'>
                        <span className='location-info-popover-coords-type'>
                            {ctrl.translate('REACT.DASHBOARD.VIEW.CLIENT_LOCATION_COORDINATES')}
                        </span>
                        <span>{ctrl.locationInfoCoordsShort}</span>
                    </div>
                    <div className='location-info-popover-coords-line'>
                        <span className='location-info-popover-coords-type'>
                            {ctrl.translate('REACT.DASHBOARD.VIEW.CLIENT_LOCATION_ALTITUDE')}
                        </span>
                        <span>
                            {ctrl.clientLocationInfo.altitude ? `${ctrl.clientLocationInfo.altitude.toFixed(1)}m` : '–'}
                        </span>
                    </div>
                    <div className='location-info-popover-coords-line'>
                        <span className='location-info-popover-coords-type'>
                            {ctrl.translate('REACT.DASHBOARD.VIEW.CLIENT_LOCATION_ACCURACY')}
                        </span>
                        <span>
                            {ctrl.clientLocationInfo.accuracy ? `${ctrl.clientLocationInfo.accuracy.toFixed(0)}m` : '–'}
                        </span>
                    </div>
                </div>
                <div className='location-info-popover-map-link'>
                    {ctrl.clientLocationInfo.googleMapsLink && (
                        <a href={ctrl.clientLocationInfo.googleMapsLink} target='_blank' rel='noopener noreferrer'>
                            {ctrl.translate('REACT.DASHBOARD.VIEW.CLIENT_LOCATION_GOOGLE_LINK')}
                        </a>
                    )}
                </div>
            </div>
        );
    }

    private bottomLineComponent() {
        const {ctrl} = this.props;

        return observer(() => <span className='location-info-data'>{ctrl.locationInfoData}</span>);
    }

    private upperLineComponent() {
        const {ctrl} = this.props;

        return observer(() => <span>{ctrl.locationInfoTitle}</span>);
    }

    renderInternal() {
        const {localizationService} = getRootStore();

        return (
            <div className='section location-info' dir={localizationService.getTextDirection()}>
                <InfoBoxCollapsible
                    content={() => this.renderPopverContent()}
                    icon={'location'}
                    upperLine={this.upperLineComponent()}
                    bottomLine={this.bottomLineComponent()}
                    id={'collapsible-popover'}></InfoBoxCollapsible>
            </div>
        );
    }
}
