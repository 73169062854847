'use strict';

import {getRootStore} from '../../_react_/app.bootstrap';
import {getAppTracer} from '../../app.tracer';
import {PromiseUtilsService} from '@techsee/techsee-client-services/lib/services/PromiseUtilsService';

const trace = getAppTracer('LoginCallbackModule');

function _handleError({tsStateHelper, authId, err} = {}) {
    const traceMessage = `Failed to redirect from login callback (Auth=${authId}): ${err}`;
    const tracePromise = () => trace.error(traceMessage, err);
    const tracePromiseTimeout = 3000;

    PromiseUtilsService.startPromiseWithTimeout(tracePromise, tracePromiseTimeout)
        .catch((err) => console.error(traceMessage + ' >> trace failed: ' + err))
        .then(() => {
            tsStateHelper.safeGo('login');
        });
}

function config($stateProvider) {
    $stateProvider.state('loginCallback', {
        url:
            ROUTE_BASE_PATH +
            'login-callback?authId&token&r&p&c&is&roomId&g&roomCode&audio&observe&emb&mini&prg&orgId&startDate&endDate',
        onEnter: (tsStateHelper, $localStorage, tsUrlUtils) => {
            const authId = tsUrlUtils.getParamValue('authId'),
                token = tsUrlUtils.getParamValue('token'),
                r = tsUrlUtils.getParamValue('r'),
                p = tsUrlUtils.getParamValue('p'),
                c = tsUrlUtils.getParamValue('c'),
                initialState = tsUrlUtils.getParamValue('is'),
                roomId = tsUrlUtils.getParamValue('roomId'),
                g = tsUrlUtils.getParamValue('g'),
                roomCode = tsUrlUtils.getParamValue('roomCode'),
                observe = tsUrlUtils.getParamValue('observe'),
                emb = tsUrlUtils.getParamValue('emb'),
                mini = tsUrlUtils.getParamValue('mini'),
                sw = tsUrlUtils.getParamValue('sw'),
                audio = tsUrlUtils.getParamValue('audio'),
                orgId = tsUrlUtils.getParamValue('orgId'),
                prg = tsUrlUtils.getParamValue('prg'),
                startDate = tsUrlUtils.getParamValue('startDate'),
                endDate = tsUrlUtils.getParamValue('endDate');

            try {
                const roomIdentifier = roomId || roomCode || g;
                const traceMessage = `loginCallback has loaded room: ${roomIdentifier}, (Auth=${authId})`;

                getRootStore().authService.setAuthToken({token: token, authId});
                $localStorage.loginCallbackResult = !!authId;

                if (initialState) {
                    const lowerInitialState = initialState.toLowerCase();

                    if (lowerInitialState.includes('reactivateAccount'.toLowerCase())) {
                        tsStateHelper.safeGo('reactivateAccount', {
                            authId,
                            r,
                            p,
                            c,
                            roomId,
                            g,
                            roomCode,
                            audio,
                            emb,
                            mini,
                            sw,
                            prg,
                            orgId,
                            observe: observe === 'guest' ? 'true' : observe
                        });

                        return;
                    }

                    if (lowerInitialState.includes('revokeAccount'.toLowerCase())) {
                        tsStateHelper.safeGo('revokeAccount', {
                            authId,
                            orgId
                        });

                        return;
                    }

                    if (lowerInitialState.includes('vjhistory')) {
                        tsStateHelper.safeGo('vjhistory', {authId, r, startDate, endDate, is: initialState, roomId});

                        return;
                    }

                    if (lowerInitialState.includes('history') && (r || p)) {
                        tsStateHelper.safeGo('invite.old.history', {authId, customerId: r, customerNumber: p});

                        return;
                    }

                    if (lowerInitialState.includes('historySearchByTags'.toLowerCase())) {
                        tsStateHelper.safeGo('invite.old.history', {authId, isSearchByTags: true});

                        return;
                    }

                    if (lowerInitialState.includes('admin')) {
                        return getRootStore()
                            .redirectionService.goToAdmin()
                            .catch((err) => {
                                _handleError({
                                    tsStateHelper,
                                    authId,
                                    err: 'Failed redirection to admin' + err?.message
                                });
                            });
                    }
                }

                return PromiseUtilsService.startPromiseWithTimeout(() => trace.info(traceMessage), 3000).then(() =>
                    tsStateHelper.safeGo('invite', {
                        authId,
                        r,
                        p,
                        c,
                        roomId,
                        g,
                        roomCode,
                        audio,
                        emb,
                        mini,
                        sw,
                        prg,
                        observe: observe === 'guest' ? 'true' : observe,
                        startDate,
                        endDate
                    })
                );
            } catch (err) {
                _handleError({tsStateHelper, authId, err});

                throw err;
            }
        }
    });
}

export default angular.module('states.loginCallback', []).config(config);
