import {action, computed, observable, runInAction} from 'mobx';
import {ConnectionStatesEnum, ConnectionTypesEnum} from '../../../../../../services/Session/SessionContracts';
import {DashboardState} from '../../../../../dashboard/_contracts/DashboardState';
import {ShareScreenPopupController} from '../../../../../../components/twoWay-share-screen/controller';
import {ITranslate} from '../../../../../../services/LocalizationService';

type EndMeetingCallback = () => void;
type OnSnapshotRequestedCb = () => void;
type UserConnectionInfo = {
    icon: string;
};
type TranslatedShareScreenText = {
    startShareScreenTitle: string;
    startShareScreenInfo: string;
    startShareScreenButton: string;
    stopShareScreenTitle: string;
    stopShareScreenInfo: string;
    stopShareScreenButton: string;
    startRemoteControlTitle: string;
    startRemoteControlInfo: string;
    startRemoteControlButton: string;
    stopRemoteControlTitle: string;
    stopRemoteControlInfo: string;
    stopRemoteControlButton: string;
    stopRemoteControlCancel: string;
    toastAgentStartSharingScreen: string;
    toastAgentStopSharingScreen: string;
    toastClientStartSharingScreen: string;
    toastClientStopSharingScreen: string;
    toastErrorStartSharingScreen: string;
    toastRequestRemoteControl: string;
    toastApprovedRemoteControl: string;
    toastLostRemoteControl: string;
    popoverShareScreenAvailable: string;
    popoverShareScreenDisabled: string;
};

export interface ILeftBarController {
    readonly endMeeting: EndMeetingCallback;
    onSnapshotRequested?: OnSnapshotRequestedCb;
    onSnapshotEnabled: boolean;
}

export class LeftBarController {
    @observable public onSnapshotRequested?: OnSnapshotRequestedCb = undefined;
    @observable public isSnapshotEnabled: boolean = true;
    @observable public isScreenShareDisabled: boolean = false;
    @observable public isLivePointerRunning: boolean = false;
    @observable public isEnableScreenShareButtons: boolean = false;
    public translatedShareScreenText: TranslatedShareScreenText;

    constructor(
        private readonly dashboardState: DashboardState,
        public readonly endMeeting: EndMeetingCallback,
        private readonly shareScreenPopupController: ShareScreenPopupController,
        private readonly enabledDesktopSharingInTwoWay: boolean,
        private readonly translate: ITranslate,
        public setLastEvent: (text: string, timestamp?: number) => void,
        public readonly isEnableRemoteControl: boolean,
        public readonly isDesktopView: boolean
    ) {
        this.shareScreen = this.shareScreen.bind(this);
        this.stopShareScreen = this.stopShareScreen.bind(this);

        this.translatedShareScreenText = {
            startShareScreenTitle: translate('DASHBOARD_MINI.TWO_WAY.SHARE_SCREEN.START.TITLE'),
            startShareScreenInfo: translate('DASHBOARD_MINI.TWO_WAY.SHARE_SCREEN.START.INFO'),
            startShareScreenButton: translate('DASHBOARD_MINI.TWO_WAY.SHARE_SCREEN.START.BUTTON'),
            stopShareScreenTitle: translate('DASHBOARD_MINI.TWO_WAY.SHARE_SCREEN.STOP.TITLE'),
            stopShareScreenInfo: translate('DASHBOARD_MINI.TWO_WAY.SHARE_SCREEN.STOP.INFO'),
            stopShareScreenButton: translate('DASHBOARD_MINI.TWO_WAY.SHARE_SCREEN.STOP.BUTTON'),
            startRemoteControlTitle: translate('DASHBOARD_MINI.TWO_WAY.SHARE_SCREEN.START.REMOTE_CONTROL.TITLE'),
            startRemoteControlInfo: translate('DASHBOARD_MINI.TWO_WAY.SHARE_SCREEN.START.REMOTE_CONTROL.INFO'),
            startRemoteControlButton: translate('DASHBOARD_MINI.TWO_WAY.SHARE_SCREEN.START.REMOTE_CONTROL.BUTTON'),
            stopRemoteControlTitle: translate('DASHBOARD_MINI.TWO_WAY.SHARE_SCREEN.STOP.REMOTE_CONTROL.TITLE'),
            stopRemoteControlInfo: translate('DASHBOARD_MINI.TWO_WAY.SHARE_SCREEN.STOP.REMOTE_CONTROL.INFO'),
            stopRemoteControlButton: translate('DASHBOARD_MINI.TWO_WAY.SHARE_SCREEN.STOP.BUTTON'),
            stopRemoteControlCancel: translate('DASHBOARD_MINI.TWO_WAY.SHARE_SCREEN.STOP.REMOTE_CONTROL.CANCEL'),
            toastAgentStartSharingScreen: translate('DASHBOARD_MINI.TWO_WAY.SHARE_SCREEN.AGENT.START.TOAST'),
            toastAgentStopSharingScreen: translate('DASHBOARD_MINI.TWO_WAY.SHARE_SCREEN.AGENT.STOP.TOAST'),
            toastClientStartSharingScreen: translate('DASHBOARD_MINI.TWO_WAY.SHARE_SCREEN.CLIENT.START.TOAST'),
            toastClientStopSharingScreen: translate('DASHBOARD_MINI.TWO_WAY.SHARE_SCREEN.CLIENT.STOP.TOAST'),
            toastErrorStartSharingScreen: translate('DASHBOARD_MINI.TWO_WAY.SHARE_SCREEN.ERROR.START.TOAST'),
            toastRequestRemoteControl: translate('DASHBOARD_MINI.TWO_WAY.SHARE_SCREEN.REMOTE_CONTROL.REQUEST.TOAST'),
            toastApprovedRemoteControl: translate('DASHBOARD_MINI.TWO_WAY.SHARE_SCREEN.REMOTE_CONTROL.APPROVED.TOAST'),
            toastLostRemoteControl: translate('DASHBOARD_MINI.TWO_WAY.SHARE_SCREEN.REMOTE_CONTROL.LOST.TOAST'),
            popoverShareScreenAvailable: translate('DASHBOARD_MINI.TWO_WAY.SHARE_SCREEN.AVAILABLE.POPOVER'),
            popoverShareScreenDisabled: translate('DASHBOARD_MINI.TWO_WAY.SHARE_SCREEN.DISABLED.POPOVER')
        };
    }

    @computed
    get isEnabledDesktopSharingInTwoWay(): boolean {
        return this.enabledDesktopSharingInTwoWay;
    }

    @computed
    get isSharingScreenOn(): boolean {
        return this.shareScreenPopupController?.isSharingScreen as boolean;
    }

    @computed
    get userConnectionInfo(): UserConnectionInfo {
        const result = {icon: ''};

        if (!this.dashboardState.sessionInfo.isSessionActive || !this.dashboardState.sessionInfo.clientJoinedSession) {
            result.icon = 'network-offline';

            return result;
        }

        if (this.dashboardState.connectionState === ConnectionStatesEnum.OFFLINE) {
            result.icon = 'offline';
        } else {
            switch (this.dashboardState.clientNetworkInfo.connectionType) {
                case ConnectionTypesEnum.CELLULAR:
                case ConnectionTypesEnum.MOBILE_DATA:
                    result.icon = 'mobile-' + this.dashboardState.connectionState.toLocaleLowerCase();
                    break;
                case ConnectionTypesEnum.WIFI:
                    result.icon = 'wifi-' + this.dashboardState.connectionState.toLocaleLowerCase();
                    break;
                default:
                    result.icon = 'network-connected';
            }
        }

        return result;
    }

    @action
    setScreenShareDisabled(value: boolean) {
        this.isScreenShareDisabled = value;
    }

    @action
    setIsLivePointerRunning(value: boolean) {
        this.isLivePointerRunning = value;
    }

    @action
    setIsEnableScreenShareButtons(value: boolean) {
        if (value && !this.isEnableScreenShareButtons && this.enabledDesktopSharingInTwoWay) {
            setTimeout(() => {
                this.setLastEvent(this.translate('DASHBOARD_MINI.TWO_WAY.SHARE_SCREEN.AVAILABLE.TOAST'), 5000);
            }, 1000);
        }
        this.isEnableScreenShareButtons = value;
    }

    async shareScreen(): Promise<void> {
        try {
            await this.shareScreenPopupController?.shareScreen();

            if (this.isSharingScreenOn) {
                this.setLastEvent(this.translatedShareScreenText.toastAgentStartSharingScreen);
            }
        } catch (err) {
            this.setLastEvent(this.translatedShareScreenText.toastErrorStartSharingScreen);
        }
    }

    stopShareScreen(): void {
        this.shareScreenPopupController?.stopShareScreen();
        this.setLastEvent(this.translatedShareScreenText.toastAgentStopSharingScreen);
    }
}
