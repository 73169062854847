import {inject, IReactComponent} from 'mobx-react';
import * as component from '../../../layouts/main-layout/left-bar-base/LeftBarComponent';

const mapStoresToProps = (stores: any) => ({
    controller: stores.leftBarController
});

const LeftBar = inject(mapStoresToProps)(component.LeftBarComponent) as IReactComponent;

export {LeftBar};
export default LeftBar;
