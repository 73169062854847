import {inject, IReactComponent} from 'mobx-react';
import {AgentSettings} from './component';

import './styles.scss';

const mapStoresToProps = (stores: any) => ({
    ctrl: stores.agentSettingsController,
    translate: stores.localizationService.translate.bind(stores.localizationService)
});

const AgentSettingsComponent = inject(mapStoresToProps)(AgentSettings) as IReactComponent;

export default AgentSettingsComponent;
