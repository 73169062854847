import React from 'react';
import {IFormFancyDropDownOptionComponent} from '@techsee/techsee-ui-common/lib/forms/form-fancy-drop-down';

export class AccountOption extends React.Component<IFormFancyDropDownOptionComponent, {}> {
    render() {
        const {currentSelIndex, index, data} = this.props;

        return (
            <div className={`item ${currentSelIndex === index ? 'focused' : ''}`} key={data._id}>
                <span className='name'>{data.companyName}</span>
            </div>
        );
    }
}
