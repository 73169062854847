'use strict';

import {MediaTypes, RolesConstant} from '@techsee/techsee-common/lib/constants/account.constants';
import {getRootStore} from '../../_react_/app.bootstrap';
import get from 'lodash/get';
import {getAppTracer} from '../../app.tracer';
import {goToDashboard} from '../../_react_/states/helper/general.helper';

const trace = getAppTracer('join.module');

function config($stateProvider) {
    $stateProvider.state('join', {
        url: ROUTE_BASE_PATH + 'join?roomCode&roomId&shortId&g&audio&observe&emb&mini&sw',
        onEnter: ($modal, tsStateHelper, db, tsUrlUtils, tsChatApi, eventLog) => {
            const roomCode = tsUrlUtils.getParamValue('roomCode'),
                roomId = tsUrlUtils.getParamValue('roomId'),
                observe = tsUrlUtils.getParamValue('observe'),
                shortId = tsUrlUtils.getParamValue('shortId') || tsUrlUtils.getParamValue('g'),
                mini = tsUrlUtils.getParamValue('mini'),
                emb = tsUrlUtils.getParamValue('emb'),
                sw = tsUrlUtils.getParamValue('sw'),
                environmentDetect = getRootStore().environmentService,
                browserUtilsService = getRootStore().browserUtilsService;
            let currentUser;

            const _redirectToRoom = (accountData, roomId) => {
                const showRoomId = accountData.protectedSettings ? accountData.protectedSettings.showRoomIdInUrl : true;
                const shouldRedirectToExternalDashboard = get(accountData, 'protectedSettings.v3.enabled');
                const dashboardV3Params = {
                    roomId: showRoomId ? roomId : undefined,
                    shortId: showRoomId ? undefined : shortId
                };

                if (shouldRedirectToExternalDashboard) {
                    return getRootStore().redirectionService.goToExternalDashboard(dashboardV3Params);
                }

                tsUrlUtils.setParamValue('room', roomId);

                if (mini && browserUtilsService) {
                    browserUtilsService.saveToSessionStorage('miniDashboard', true);
                }

                if (emb && browserUtilsService) {
                    browserUtilsService.saveToSessionStorage('embeddedDashboard', true);
                }

                const enableFieldServices = accountData.protectedSettings
                    ? accountData.protectedSettings.enableFieldServices
                    : true;

                const voiceOverIp =
                    accountData.settings && accountData.settings.fsSettings
                        ? accountData.settings.fsSettings.voiceOverIP
                        : false;

                const audioEnableInAccount =
                    get(accountData, 'protectedSettings.mediaType') === MediaTypes.videoAndAudio;
                const audio = tsUrlUtils.getParamValue('audio');

                if (observe) {
                    const filter = {sessionId: '', roomId: ''};

                    const isGuest = currentUser.role === RolesConstant.GUEST_OBSERVER;

                    const userData = {
                        userName: currentUser.userName,
                        firstName: currentUser.firstName,
                        lastName: currentUser.lastName,
                        addNewSegment: true
                    };

                    if (showRoomId) {
                        userData.roomId = roomId;
                        filter.roomId = roomId;
                    } else {
                        userData.shortId = roomId;
                        filter.sessionId = roomId;
                    }

                    return db.User.joinSessionValidation({params: {id: accountData._id, filter}})
                        .then((response) => {
                            if (!response) {
                                throw new Error('joinSessionValidation failed');
                            }

                            return (isGuest ? Promise.resolve() : db.Observations.add({data: userData})).then(() =>
                                tsStateHelper.safeGo(goToDashboard(emb, sw), {
                                    room: roomId,
                                    roomCode: roomCode,
                                    audio: environmentDetect.isIE11() ? false : audio,
                                    observe: observe,
                                    sw
                                })
                            );
                        })
                        .catch((err) => {
                            trace.error(
                                'Join loading failed (redirection phase): ' +
                                    JSON.stringify({isMinidashboard: mini, isEmbeddedDashboard: emb}),
                                (err && err.status) || err
                            );
                            if (emb) {
                                return tsStateHelper.safeGo('embeddedDashboardGeneralError');
                            }

                            return tsStateHelper.safeGo('invite', {
                                error: (err && err.status) || err
                            });
                        });
                }

                // Audio is disabled for IE11 and when FS is disabled or mediaType not include audio in account settings.
                if (environmentDetect.isIE11() || !((enableFieldServices && voiceOverIp) || audioEnableInAccount)) {
                    return tsStateHelper.safeGo(goToDashboard(emb, sw), {
                        room: roomId,
                        roomCode: roomCode,
                        sw
                    });
                }

                return tsStateHelper.safeGo(goToDashboard(emb, sw), {
                    room: roomId,
                    roomCode: roomCode,
                    audio: audio,
                    sw,
                    mini,
                    emb
                });
            };

            db.User.find('current')
                .then((user) => {
                    currentUser = {
                        userName: user.userName,
                        firstName: user.firstName,
                        lastName: user.lastName,
                        role: user.role
                    };

                    return db.Account.find(user.accountId, {bypassCache: true}).then((accountData) => {
                        tsChatApi.service.ensureSocketIsConnected();
                        if (roomId || (shortId && !roomCode)) {
                            return Promise.resolve()
                                .then(() => {
                                    eventLog.room = roomId;

                                    const showRoomId = accountData.protectedSettings
                                        ? accountData.protectedSettings.showRoomIdInUrl
                                        : true;

                                    if ((showRoomId && roomId) || (!showRoomId && shortId)) {
                                        return _redirectToRoom(accountData, showRoomId ? roomId : shortId);
                                    }

                                    if (!showRoomId && !shortId) {
                                        return db.Rooms.shortId(roomId).then(({data}) =>
                                            _redirectToRoom(accountData, data.shortId)
                                        );
                                    }

                                    // showRoomId && !roomId
                                    return db.ShortUrl.find(`${shortId}/id`, {bypassCache: true}).then((data) =>
                                        _redirectToRoom(accountData, data.roomId)
                                    );
                                })
                                .catch((err) => {
                                    trace.error(
                                        'Join loading failed (handling phase): ' +
                                            JSON.stringify({isMinidashboard: mini, isEmbeddedDashboard: emb}),
                                        (err && err.status) || err
                                    );
                                    if (emb) {
                                        return tsStateHelper.safeGo('embeddedDashboardGeneralError');
                                    }

                                    return tsStateHelper.safeGo('invite', {
                                        error: (err && err.status) || err
                                    });
                                });
                        }

                        return tsChatApi.service
                            .requestRoomId(roomCode, true)
                            .then((res) => {
                                if (res.roomId) {
                                    return res.roomId;
                                }

                                return db.Rooms.create().then((room) => room._id);
                            })
                            .then((roomId) => {
                                eventLog.room = roomId;

                                const showRoomId = accountData.protectedSettings
                                    ? accountData.protectedSettings.showRoomIdInUrl
                                    : true;

                                if (showRoomId) {
                                    return _redirectToRoom(accountData, roomId);
                                }

                                const clientStartURL = TsUtils.replaceUrlDomain(
                                    CLIENT_START_URL,
                                    window.location.hostname
                                );

                                return db.ShortUrl.create({
                                    roomId: roomId,
                                    mobileClientURL: clientStartURL
                                }).then((data) => _redirectToRoom(accountData, data.shortId));
                            })
                            .catch((err) => {
                                trace.error(
                                    'Join loading failed (requestRoomId phase): ' +
                                        JSON.stringify({isMinidashboard: mini, isEmbeddedDashboard: emb}),
                                    (err && err.status) || err
                                );
                                if (emb) {
                                    return tsStateHelper.safeGo('embeddedDashboardGeneralError');
                                }

                                return tsStateHelper.safeGo('invite', {
                                    error: (err && err.status) || err
                                });
                            });
                    });
                })
                .catch((err) => {
                    trace.error(
                        'Join loading failed: ' + JSON.stringify({isMinidashboard: mini, isEmbeddedDashboard: emb}),
                        err
                    );
                    if (emb) {
                        return tsStateHelper.safeGo('embeddedDashboardGeneralError');
                    }

                    return tsStateHelper.safeGo('login', {roomCode});
                });
        }
    });
}

export default angular.module('states.join', []).config(config);
