import React, {Component, Fragment} from 'react';
import {observer} from 'mobx-react';
import classnames from 'classnames';
import {DefaultModalHeader} from '@techsee/techsee-ui-common/lib/modals/generic/default-modal-header';
import {FormInput} from '@techsee/techsee-ui-common/lib/forms/input';
import {ButtonsToolbar} from '@techsee/techsee-ui-common/lib/forms/buttons/toolbar';
import {PrimaryButton} from '@techsee/techsee-ui-common/lib/forms/buttons/primary';
import {ButtonTypeAttributes} from '@techsee/techsee-ui-common/lib/forms/buttons/generic';
import {GenericModal} from '@techsee/techsee-ui-common/lib/modals/generic';
import {ITranslate} from '../../../services/LocalizationService';
import {IAgentSettingsController} from './controller';
import {SecondaryButton} from '@techsee/techsee-ui-common/lib/forms/buttons/secondary';

import './styles.scss';
import {FormFancyDropDown} from '@techsee/techsee-ui-common/lib/forms/form-fancy-drop-down';
import {ClientLanguageItem} from '../../../models/AccountSettings';

export interface IAgentSettings {
    ctrl: IAgentSettingsController;
    translate: ITranslate;
    passwordOnly: boolean;
}

@observer
export class AgentSettings extends Component<IAgentSettings> {
    renderHeader() {
        const {translate, ctrl} = this.props;

        return (
            <DefaultModalHeader>
                {ctrl.passwordOnly
                    ? translate('REACT.APP.AGENT_SETTINGS.MODAL_CHANGE_PASSWORD_TITLE')
                    : translate('REACT.APP.AGENT_SETTINGS.MODAL_TITLE')}
            </DefaultModalHeader>
        );
    }

    renderContent() {
        const {
            agentSettingsForm,
            handleOldPasswordChange,
            toggleShowOldPassword,
            toggleShowNewPassword,
            toggleShowConfirmPassword,
            onFormSubmit,
            closeAgentSettings,
            error,
            translate,
            passwordOnly,
            showOldPassword,
            showNewPassword,
            showConfirmPassword,
            enableMultiLanguage,
            supportedLanguages
        } = this.props.ctrl;

        return (
            <form
                className={classnames('agent-settings-form', {'password-only': passwordOnly})}
                onSubmit={onFormSubmit}>
                {!passwordOnly && (
                    <Fragment>
                        <FormInput
                            uid={agentSettingsForm.fieldNames.firstName}
                            model={agentSettingsForm.fields[agentSettingsForm.fieldNames.firstName]}
                        />

                        <FormInput
                            uid={agentSettingsForm.fieldNames.lastName}
                            model={agentSettingsForm.fields[agentSettingsForm.fieldNames.lastName]}
                        />
                    </Fragment>
                )}

                {enableMultiLanguage && (
                    <FormFancyDropDown
                        className='user-settings-language'
                        uid={agentSettingsForm.fieldNames.clientLanguage}
                        options={supportedLanguages}
                        optionComponent={({data}: {data: ClientLanguageItem}) => (
                            <div className='item'>{data.name}</div>
                        )}
                        model={agentSettingsForm.fields[agentSettingsForm.fieldNames.clientLanguage]}
                        optionValueKey='name'
                    />
                )}

                <div className='password-field'>
                    <FormInput
                        onChange={handleOldPasswordChange}
                        inputType={this.props.ctrl.showOldPassword ? 'text' : 'password'}
                        uid={agentSettingsForm.fieldNames.oldPassword}
                        model={agentSettingsForm.fields[agentSettingsForm.fieldNames.oldPassword]}
                    />

                    <div
                        onClick={toggleShowOldPassword}
                        className={classnames(
                            'show-password',
                            'glyphicon',
                            `glyphicon-eye-${showOldPassword ? 'close' : 'open'}`
                        )}></div>
                </div>

                <div className='password-field'>
                    <FormInput
                        inputType={showNewPassword ? 'text' : 'password'}
                        uid={agentSettingsForm.fieldNames.newPassword}
                        model={agentSettingsForm.fields[agentSettingsForm.fieldNames.newPassword]}
                    />

                    <div
                        onClick={toggleShowNewPassword}
                        className={classnames(
                            'show-password',
                            'glyphicon',
                            `glyphicon-eye-${showNewPassword ? 'close' : 'open'}`
                        )}></div>
                </div>

                <div className='password-field'>
                    <FormInput
                        inputType={showConfirmPassword ? 'text' : 'password'}
                        uid={agentSettingsForm.fieldNames.confirmPassword}
                        model={agentSettingsForm.fields[agentSettingsForm.fieldNames.confirmPassword]}
                    />

                    <div
                        onClick={toggleShowConfirmPassword}
                        className={classnames(
                            'show-password',
                            'glyphicon',
                            `glyphicon-eye-${showConfirmPassword ? 'close' : 'open'}`
                        )}></div>
                </div>

                {!!error && <div className='agent-settings-error'>{error}</div>}

                <ButtonsToolbar>
                    {!passwordOnly && (
                        <SecondaryButton onClick={closeAgentSettings}>
                            {translate('REACT.COMMON.BUTTONS.CANCEL')}
                        </SecondaryButton>
                    )}
                    <PrimaryButton type={ButtonTypeAttributes.Submit}>
                        {translate('REACT.COMMON.BUTTONS.SAVE')}
                    </PrimaryButton>
                </ButtonsToolbar>
            </form>
        );
    }

    renderToolbar() {
        return <React.Fragment />;
    }

    render() {
        const {closeAgentSettings, isDisplayedAgentSettings, passwordOnly} = this.props.ctrl;

        return (
            <GenericModal
                className={'agent-settings'}
                header={() => this.renderHeader()}
                content={() => this.renderContent()}
                toolbar={() => this.renderToolbar()}
                onHideHandler={closeAgentSettings}
                isCloseVisible={!passwordOnly}
                closeOnEscape={!passwordOnly}
                show={isDisplayedAgentSettings}></GenericModal>
        );
    }
}
