import React, {FC} from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {PLACEMENT_TYPE} from '@techsee/techsee-ui-common/lib/popover-panel';
import {getRootStore} from '../../app.bootstrap';
import HDIcon from '../../../../img/HDicon.png';
import SDIcon from '../../../../img/SDicon.png';
import './video-resolution-indication.scss';
import get from 'lodash/get';
import {VideoResolutionFormat} from '@techsee/techsee-media-service/lib/MediaConstants';

const getImgResolutionFormat = (resolution: string) => {
    const resolutionFormatObj = {HD: HDIcon, SD: SDIcon};

    return get(resolutionFormatObj, resolution, '');
};

//display resolution dimensions component on screen
export const VideoResolutionIndication: FC<any> = ({dimensionType}) => {
    const translate = getRootStore().localizationService.translate;
    const isVisible = dimensionType === VideoResolutionFormat.NONE ? null : dimensionType;
    const tooltipText = dimensionType === VideoResolutionFormat.SD ? translate('REACT.VIDEO_DIMENSION_TOOLTIP_SD') : '';
    const imgResolutionFormat = getImgResolutionFormat(dimensionType);

    const getTooltip = () =>
        tooltipText ? (
            <Tooltip id={dimensionType} className={'right-bar-tooltip'}>
                {tooltipText}
            </Tooltip>
        ) : (
            <></>
        );

    return (
        isVisible && (
            <div className={'video-resolution-container'}>
                <OverlayTrigger
                    key={PLACEMENT_TYPE.TOP}
                    placement={PLACEMENT_TYPE.TOP}
                    delayHide={2000}
                    overlay={getTooltip()}>
                    <img src={imgResolutionFormat} className={'video-resolution-button'} />
                </OverlayTrigger>
            </div>
        )
    );
};
