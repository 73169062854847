import {ITranslate} from '../../../_react_/services/LocalizationService';
import {IDomUtilsService} from '@techsee/techsee-client-infra/lib/services/DomUtilsService';

export interface ISpeedtestController {
    readonly translate: ITranslate;

    loadImage(img: string): string;
}

export class SpeedtestController implements ISpeedtestController {
    private readonly _translate: ITranslate;

    private readonly _domUtilsService: IDomUtilsService;

    constructor(translate: ITranslate, domUtilsService: IDomUtilsService) {
        this._domUtilsService = domUtilsService;
        this._translate = translate;
    }

    loadImage(img: string) {
        return this._domUtilsService.loadImage(img);
    }

    get translate() {
        return this._translate;
    }
}
