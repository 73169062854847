import {JoinOfflineForm} from './form-model';
import {IFieldFactory} from '../../../../../app.contracts';
import {OperationResult} from '../../../../../models/OperationResult';
import {IInviteFlowManager} from '../../../_contracts/InviteContracts';
import {action, computed, observable} from 'mobx';
import {FormControl} from 'react-bootstrap';

import './styles.scss';

export interface IJoinOfflineController {
    readonly form: JoinOfflineForm;
    readonly sessionIdError: boolean;

    validateForm(): PromiseLike<OperationResult>;
    resetForm(): void;
    onChange(e?: React.ChangeEvent<FormControl>): any;
    onSubmit(event: React.FormEvent<HTMLFormElement>, callback: (sessionId: string) => Promise<void>): void;
    onSubmitWt(
        event: React.FormEvent<HTMLFormElement>,
        callback: (agentLink: string) => Promise<void>,
        getAgentLink?: (sessionId: string) => string
    ): void;
}

export class JoinOfflineController implements IJoinOfflineController {
    @observable private _sessionIdError: boolean = false;

    readonly form: JoinOfflineForm;

    constructor(
        private _flowManager: IInviteFlowManager,
        private _fieldFactory: IFieldFactory
    ) {
        this.form = new JoinOfflineForm(this._flowManager.translate, _fieldFactory);

        this.createFormFields(this.form);

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onSubmitWt = this.onSubmitWt.bind(this);
    }

    validateForm(): PromiseLike<OperationResult> {
        return new Promise<OperationResult>((resolve) => {
            this.form.validateForm().then((isFormValid) => {
                if (!isFormValid) {
                    this.form.displayFieldsErrors();
                    resolve(OperationResult.getFailResult());

                    return;
                }

                resolve(OperationResult.getSuccessResult());
            });
        });
    }

    @action
    onChange() {
        this._sessionIdError = false;
    }

    @action
    onSubmit(event: React.FormEvent<HTMLFormElement>, callback: (sessionId: string) => Promise<void>) {
        event.preventDefault();

        this.setSessionIdError(false);

        this.validateForm().then((result) => {
            if (result.isSuccess) {
                return callback(this.form.getFormValues().sessionId).catch(() => {
                    this.setSessionIdError(true);
                });
            }

            return null;
        });
    }

    @action
    onSubmitWt(
        event: React.FormEvent<HTMLFormElement>,
        callback: (agentLink: string) => Promise<void>,
        getAgentLink?: (sessionId: string) => string
    ) {
        event.preventDefault();

        this.setSessionIdError(false);

        this.validateForm().then((result) => {
            if (result.isSuccess) {
                let clientLink = '';

                if (getAgentLink) {
                    clientLink = getAgentLink(this.form.getFormValues().sessionId);
                }

                return callback(clientLink).catch(() => {
                    this.setSessionIdError(true);
                });
            }

            return null;
        });
    }
    @computed
    get sessionIdError() {
        return this._sessionIdError;
    }

    resetForm(): void {
        this.form.resetFormFields();
    }

    @action
    private setSessionIdError(state: boolean) {
        this._sessionIdError = state;
    }

    private createFormFields(form: JoinOfflineForm): void {
        form.createSessionIdField();
    }
}
