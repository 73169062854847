import {
    IMainTopBarController,
    MainTopBarControllerBase,
    TempAccountSettings
} from '../../../layouts/main-layout/main-top-bar/controller.base';
import {InviteState} from '../_contracts/InviteState';
import {ITranslate} from '../../../services/LocalizationService';
import {DeepReadonly} from '@techsee/techsee-common/lib/core/data-structures';

export interface IInviteTopBarController extends IMainTopBarController<InviteState> {}

export class InviteTopBarController extends MainTopBarControllerBase<InviteState> implements IInviteTopBarController {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(
        accSettings: DeepReadonly<TempAccountSettings>,
        stateObject: DeepReadonly<InviteState>,
        translate: ITranslate
    ) {
        super(accSettings, stateObject, translate);
    }
}
