/* eslint-disable max-classes-per-file */
import {TechseeFormBase} from '@techsee/techsee-ui-common/lib/forms/_shared/TechseeFormBase';
import {ITranslate} from '../../../../../services/LocalizationService';
import {IFieldFactory} from '../../../../../app.contracts';
import {serializable} from 'serializr';
import {observable} from 'mobx';
import {observableFieldsTranslate} from './controller';

export class ObservableInfo {
    @serializable @observable searchBy: string = '';

    @serializable @observable searchByOption: string = '';
}

export interface ObservableFieldNames {
    searchBy: string;
    searchByOption: string;
}

export class ObservableForm extends TechseeFormBase {
    private _translate: ITranslate;

    private _fieldFactory: IFieldFactory;

    constructor(translate: ITranslate, fieldFactory: IFieldFactory) {
        super();

        this._translate = translate;
        this._fieldFactory = fieldFactory;
    }

    get fieldNames(): ObservableFieldNames {
        return {
            searchBy: 'searchBy',
            searchByOption: 'searchByOption'
        };
    }

    getFormValues(): ObservableInfo {
        const info = new ObservableInfo();
        const infoProps = Object.getOwnPropertyNames(info);

        Object.getOwnPropertyNames(this.fieldNames).forEach((field) => {
            if (!infoProps.indexOf(field)) {
                console.error(`${field} not exists on ObservableInfo type`);
            } else if (this.fields[field]) {
                (info as any)[field] = this.fields[field].value as string;
            }
        });

        return info;
    }

    onClear() {
        this.fields.searchBy.setValue('');
    }

    createSearchByField(): void {
        const searchByOption = this._fieldFactory();
        const searchBy = this._fieldFactory();

        searchByOption.setReadonly(true);
        this.addField(this.fieldNames.searchByOption, searchByOption);
        this.addField(this.fieldNames.searchBy, searchBy);
        this.onClear();
        this.fields.searchByOption.setValue(this._translate(observableFieldsTranslate.userName));
    }
}
