import {inject, IReactComponent} from 'mobx-react';
import {StartWithModes} from './component';

import './styles.scss';

const mapStoresToProps = (stores: any) => ({
    startWithModesController: stores.startWithModesController,
    translate: stores.localizationService.translate.bind(stores.localizationService)
});

const StartWithModesComponent = inject(mapStoresToProps)(StartWithModes) as IReactComponent;

export default StartWithModesComponent;
