import {inject, IReactComponent} from 'mobx-react';
import {Stores} from '../../MultipartyDashboardStores';
import {VideoComponent} from './VideoComponent';

function mapStoresToProps(stores: Stores) {
    return {
        controller: stores.videoController
    };
}

export const Video = inject(mapStoresToProps)(VideoComponent) as IReactComponent;
