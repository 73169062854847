import {inject, IReactComponent} from 'mobx-react';
import * as component from './component';

const mapStoresToProps = (stores: any) => ({
    ctrl: stores.loginConfirmationController,
    onConfirmLogin: stores.loginConfirmationController.onConfirmLogin
});

const LoginConfirmation = inject(mapStoresToProps)(component.LoginConfirmation) as IReactComponent;

export default LoginConfirmation;
