import {DeepReadonly} from '@techsee/techsee-ui-common/lib/_shared/reusable-types';
import {InviteState} from './InviteState';
import {IInviteFlowLogger} from '../invite.flow.logger';
import {ITranslate} from '../../../services/LocalizationService';
import {IWizardController} from '@techsee/techsee-ui-common/lib/wizard/contracts';
import {IStartWithModesController} from '../../../components/start-with-modes/controller';
import {InviteMethodInfo} from '../../../models/LiveSessionState';
import {AppState} from '../../../app.state';
import {ILookupNumberController} from '../invite-wizard/components/lookup-number/controller';
import {IChangeNumberController} from '../invite-wizard/components/change-number/controller';
import {ISelectVideoPublisherController} from '../../../components/select-video-publisher/controller';
import {Nullable} from '@techsee/techsee-common';
import {ISimpleModalController} from '../../../components/simple-modal/controller';
import {AddressTypesEnum} from '@techsee/techsee-common/lib/constants/room.constants';

export enum ChangeNumberOrigins {
    MANUAL = 'Manual',
    SMSFAILED = 'SMSFailed',
    UNSUPPORTED = 'Unsupported'
}

export type ChangeNumberOrigin =
    | ChangeNumberOrigins.MANUAL
    | ChangeNumberOrigins.SMSFAILED
    | ChangeNumberOrigins.UNSUPPORTED;

export enum InviteStepsEnum {
    InviteMethod = 'InviteMethod',
    SpeakerGuidance = 'SpeakerGuidance',
    WifiGuidance = 'WifiGuidance',
    SendSmsFlow = 'SendSmsFlow',
    TermsApproval = 'TermsApproval',
    PreCameraApproval = 'PreCameraApproval',
    CameraApproval = 'CameraApproval',
    Dashboard = 'Dashboard',
    AppShareGuidance = 'AppShareGuidance',
    ScreenShareGuidance = 'ScreenShareGuidance',
    ScreenShareCaptureGuidance = 'ScreenShareCaptureGuidance',
    VideoApplicationGuidance = 'VideoApplicationGuidance',
    VideoApplicationCameraAudioDialog = 'VideoApplicationCameraAudioDialog',
    InviteByCode = 'InviteByCode',
    Email = 'Email'
}

export interface ICopilotSettings {
    enabled: boolean;
}

export interface IInviteFlowManager {
    readonly onReady: Promise<void>;

    readonly inviteState: DeepReadonly<InviteState>;

    readonly inviteLogger: IInviteFlowLogger;

    readonly translate: ITranslate;

    readonly isSmsFlowInProgress: boolean;

    readonly _selectVideoPublisherController: Nullable<ISelectVideoPublisherController>;

    readonly startWithModesController: IStartWithModesController;

    readonly lookupNumberController: ILookupNumberController;

    readonly changeNumberController: IChangeNumberController;

    readonly appState: AppState;

    readonly desktopShowURL: {url: string; displayUrl: string};

    startSession(): Promise<void>;

    sendEmail(): Promise<void>;

    joinOfflineSession(sessionId: string): Promise<void>;

    joinWtSession(agentLink: string): void;

    endSession(keepRoom?: boolean): Promise<void>;

    sendInviteSms(): void;

    sendVisualJourneySMS(): Promise<void>;

    sendVisualJourneyEmail(): Promise<void>;

    sendClientAsDashboardEmail(): Promise<void>;

    repromptClientForTos(): void;

    setDisplayedStep(step: InviteStepsEnum): void;

    changeInviteMethodInfo(): PromiseLike<void>;

    updateInviteMethod(inviteMethodInfo: InviteMethodInfo): void;

    onStartModeChanged(callback: () => void): void;

    resetAddressField(): void;

    sendWarmTransferClientLinkSMS(sessionId: string): Promise<void>;

    copilotSettings: ICopilotSettings;

    initCopilot(copilotElement: HTMLDivElement): Promise<void>;
}

export interface InviteWizardHeaderStep {
    title: string;
    steps: InviteStepsEnum[];
}

export enum InviteDeviceType {
    ios = 'ios',
    android = 'android',
    common = 'common'
}

export enum InviteGuidanceBrowser {
    chrome = 'chrome',
    firefox = 'firefox',
    safari = 'safari',
    edge = 'edge'
}

export interface IInviteWizardController extends IWizardController {
    readonly enableNewInviteSpeakersStep: boolean;
    readonly enableNewInviteWifiStep: boolean;
    readonly unauthorizedModal: ISimpleModalController;
    readonly wizardHeaderGroups: InviteWizardHeaderStep[];

    displayAgentPerformance: boolean;
    addressType: AddressTypesEnum;

    onChangeNumberClick(): void;

    isOnWifiStep(): boolean;

    isOnSpekersStep(): boolean;

    isOnSmsStep(): boolean;

    onNextClick(): void;

    onBackClick(): void;
}

export interface IKeepCustomerDetailsConfig {
    keepCustomerMobile?: boolean;
    keepCustomerEmailAddress?: boolean;
}
