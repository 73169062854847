import React, {Component} from 'react';
import {CollapsibleWidgetButton} from '@techsee/techsee-ui-common/lib/collapsible-widget/collapsible-widget-button';
import {
    ICollapsibleWidgetStructure,
    IInteractionSummaryGetDataParams
} from '@techsee/techsee-ui-common/lib/collapsible-widget/collapsible-widget';
import {getRootStore} from '../../app.bootstrap';

import './styles.scss';

interface IInteractionSummaryButton {
    interactionSummaryData: ICollapsibleWidgetStructure;
    toggleInteractionSummaryWidget: (getDataParams: IInteractionSummaryGetDataParams) => void;
    stepsTranslated: string;
    dateFormat: string;
    isFormatted?: boolean;
}

export class InteractionSummaryButton extends Component<IInteractionSummaryButton> {
    private readonly sessionId;
    private readonly dateFormat;

    constructor(props: IInteractionSummaryButton) {
        super(props);

        this.sessionId = props.interactionSummaryData?.sessionId;
        this.dateFormat = props.dateFormat;
    }

    getDateFormattedByAcc(date: string) {
        const reportsDateFormat = this.dateFormat || 'dd/mm/yyyy';

        return getRootStore().getDateFormattedByAcc(date, reportsDateFormat);
    }

    onClick = (): void => {
        this.props.toggleInteractionSummaryWidget({sessionId: this.sessionId || ''});
    };

    render() {
        const {interactionSummaryData, isFormatted} = this.props;
        const {sessionSource, totalResults, creationTimestamp, itemsText} = interactionSummaryData;

        return (
            <div className={'interaction-summary-btn-container'}>
                <CollapsibleWidgetButton
                    date={this.getDateFormattedByAcc(creationTimestamp)}
                    text={sessionSource}
                    // note 'steps' should have more generic name e.g. itemsText as it can be "Steps" or "Images"
                    steps={itemsText ?? ''}
                    // note numberOfSteps should have more generic name e.g. numberOfItems
                    numberOfSteps={totalResults}
                    onClick={this.onClick}
                    isFormatted={isFormatted}
                />
            </div>
        );
    }
}
