'use strict';

// Scrolling needs to be manually animated
const FRAME_RATE = 30;

/**
 * A simple directive that wathces a collection and scrolls an element
 * to its bottom, whenever the number of items changes.
 * Used on the container of the collection.
 *
 * Attributes:
 *
 *  ts-scroll-down: Model to watch for new values
 */
class TsScrollDownController {
    constructor($element, $attrs, $scope, $interval) {
        'ngInject';

        this.$scope = $scope;
        this.$interval = $interval;
        // we maintain a reference to any started interval,
        // so that we can cancel it and avoid conflicts in
        // animation.
        this.currentInterval = null;
    }

    /*
     * Animates a value, from start to end, over a given duration and
     * feeds the current value to a callback. Used for setting scrollTop
     * to the element of the directive.
     *
     * @param startValue {Number} initial value
     * @param endValue {Number} final value
     * @param duration {Number} duration of the animation, in msec
     * @param callback {Function} function to call with `stepValue` parameter,
     *                            in every iteration.
     */
    iterativelyIncrease(startValue, endValue, duration, callback) {
        const distance = endValue - startValue,
            repeats = (duration * FRAME_RATE) / 1000,
            step = distance / repeats;
        let stepValue = startValue;

        // We need to stop any running scroll, before adding the new one
        this.cancel();

        this.$interval(
            () => {
                stepValue += step;
                callback(stepValue);
            },
            FRAME_RATE,
            repeats
        );
    }

    cancel() {
        if (this.currentInterval) {
            this.$interval.cancel(this.currentInterval);
        }
    }
}

function linkFn(scope, element, attrs, ctrl) {
    const container = element[0];

    const performScrolling = () => {
        const startValue = container.scrollTop,
            endValue = container.scrollHeight;

        ctrl.iterativelyIncrease(startValue, endValue, 300, (stepValue) => {
            container.scrollTop = stepValue;
        });
    };

    scope.$watchCollection(() => ctrl.model, performScrolling);
    scope.$watch(() => ctrl.expand, performScrolling);

    scope.$on('$destroy', () => {
        ctrl.cancel();
    });
}

export function tsScrollDownDirective() {
    return {
        restrict: 'A',
        scope: {},
        bindToController: {
            model: '=tsScrollDown',
            expand: '=tsScrollExpand'
        },
        controller: TsScrollDownController,
        controllerAs: 'vm',
        link: linkFn
    };
}
