'use strict';

import cloneDeep from 'lodash/cloneDeep';
import includes from 'lodash/includes';
import {EventEmitter} from 'events';
import {
    ACCENT_CLASS,
    RTL_LANGUAGES,
    LOG_REPETITION_TYPE,
    LOG_MESSAGES,
    CONNECTION_STATUS,
    RECONNECTION_SETTINGS
} from './ts-locale-helper.settings.js';
import {BrowserUtilsService} from '@techsee/techsee-client-infra/lib/services/BrowserUtilsService';
import {DASHBOARD_LANGUAGE_KEY} from '../../_react_/states/helper/general.helper';

/**
 * 1. Some common methods for $language service
 * 2. Event Logs Dispatcher
 */
export class TsLocaleHelperService extends EventEmitter {
    constructor($translate, $rootScope, $timeout, eventLog, STATUS_MESSAGES) {
        'ngInject';

        super();

        this.$translate = $translate;
        this.$rootScope = $rootScope;
        this.$timeout = $timeout;

        this.browserUtilsService = new BrowserUtilsService(window);
        this.eventLog = eventLog;

        this.ACCENT_CLASS = ACCENT_CLASS;
        this.STATUS_MESSAGES = STATUS_MESSAGES;

        this.clear();
    }

    // Override funtion for EventEmitter.emit. Ensures that changes in the
    // callabck will be reflected on the scope (i.e. triggers digest loop)
    emit(...args) {
        const phase = this.$rootScope.$$phase;

        if (phase === '$apply' || phase === '$digest') {
            super.emit(...args);
        } else {
            this.$rootScope.$apply(() => super.emit(...args));
        }
    }

    /**
     * Handles Log Message updating this.logs array to show on event.logs screen
     */
    handleMessage(message, sender, isNew) {
        const name = message.name,
            description = LOG_MESSAGES[name];

        if (!description) {
            if (message.isError) {
                console.error(sender, name);
            } else {
                console.log(sender, name);
            }

            return;
        }

        if (name === this.STATUS_MESSAGES.TOS_REJECTED) {
            this._stopDisconnectionTimer();

            this._tosRejected = true;
        }

        if (description.endsMeeting) {
            this._stopDisconnectionTimer();

            this._meetingIsEndedByClient = true;
        }

        if (description.repetition === LOG_REPETITION_TYPE.SINGLE && this._registry[name] !== undefined) {
            // This message already presented
            return;
        }

        if (this._registry[name] === undefined) {
            this._registry[name] = {};
        }

        if (description.repetition === LOG_REPETITION_TYPE.UNIQUE && this._registry[name][message.id] !== undefined) {
            // Message with the same id already presented
            return;
        }

        this._registry[name][message.id] = true;

        this._prepareMessage({
            ...description,
            time: message.time,
            text: description.message,
            isNew
        });

        this.updateLogs();
    }

    _stopDisconnectionTimer() {
        if (this._disconnectionTimer !== null) {
            this.$timeout.cancel(this._disconnectionTimer);
            this._disconnectionTimer = null;
        }
    }

    _prepareMessage({group, text, accent, isNew, time}) {
        const msgTime = moment(time).isValid() ? moment(time) : moment();

        const preparedMessage = {
            order: ++this._messageCounter,
            group: group,
            text: text,
            accent: accent,
            time: msgTime.format('HH:mm:ss')
        };

        if (isNew) {
            this.emit('newEvent', preparedMessage);
        }

        this._messages.push(preparedMessage);
    }

    updateLogs() {
        const ordered =
            cloneDeep(this._messages) ||
            []
                .map((message) => {
                    message.index = ++this._updateCounter;

                    return message;
                })
                .sort((a, b) => {
                    if (a.time > b.time) {
                        return 1;
                    }

                    if (a.time < b.time) {
                        return -1;
                    }

                    if (a.group < 0 || b.group < 0) {
                        return a.order - b.order;
                    }

                    if (a.group < b.group) {
                        return -1;
                    }

                    if (a.group > b.group) {
                        return 1;
                    }

                    return a.order - b.order;
                });

        this.emit('update', ordered);
    }

    mobileClientConnected() {
        this.connected = true;
        this._meetingIsEndedByClient = false;

        this._stopDisconnectionTimer();

        this.emit('logEvent', this.STATUS_MESSAGES.CLIENT_CONNECTED);

        this.emit('clientConnectionStatus', CONNECTION_STATUS.CONNECTED);
    }

    mobileClientDisconnected() {
        this._stopDisconnectionTimer();

        if (this._tosRejected) {
            return;
        }

        // Mobile client clicks end-meeting button.
        // So there is no reason to wait for reconnection
        if (this._meetingIsEndedByClient) {
            this.emit('clientConnectionStatus', CONNECTION_STATUS.DISCONNECTED);

            this.updateLogs();

            return;
        }

        if (RECONNECTION_SETTINGS.SHOW_RECONNECTION_STATUS) {
            this.emit('logEvent', this.STATUS_MESSAGES.CLIENT_RECONNECTING);
            this.emit('clientConnectionStatus', CONNECTION_STATUS.RECONNECTING);
        }

        if (RECONNECTION_SETTINGS.TIMEOUT <= 0) {
            this.connected = false;

            this.emit('logEvent', this.STATUS_MESSAGES.CLIENT_DISCONNECTED);
            this.eventLog.mobileDisconnected();

            this.emit('clientConnectionStatus', CONNECTION_STATUS.DISCONNECTED);
        } else {
            this._disconnectionTimer = this.$timeout(() => {
                this._disconnectionTimer = null;

                this.connected = false;

                this.emit('logEvent', this.STATUS_MESSAGES.CLIENT_DISCONNECTED);
                this.eventLog.mobileDisconnected();

                this.emit('clientConnectionStatus', CONNECTION_STATUS.DISCONNECTED);

                this.updateLogs();
            }, RECONNECTION_SETTINGS.TIMEOUT * 1000);
        }

        this.updateLogs();
    }

    eventIsError(event) {
        return event.accent === this.ACCENT_CLASS.DANGER;
    }

    eventIsWarning(event) {
        return event.accent === this.ACCENT_CLASS.WARNING;
    }

    loadLocale(locale, companyName) {
        let nextLocale = locale;

        if (!nextLocale) {
            nextLocale = this.getLocale();
        }

        this.browserUtilsService.saveToLocalStorage(DASHBOARD_LANGUAGE_KEY, nextLocale);
        this.$translate.fallbackLanguage(nextLocale);

        this.$translate.use(companyName ? companyName + nextLocale : nextLocale);
    }

    getLocale() {
        return this.browserUtilsService.getFromLocalStorage(DASHBOARD_LANGUAGE_KEY) || 'en_US';
    }

    isRTL() {
        return includes(RTL_LANGUAGES, this.getLocale());
    }

    mobileClientHidden() {
        if (this._tosRejected) {
            return;
        }

        this.emit('logEvent', this.STATUS_MESSAGES.CLIENT_HIDDEN);
    }

    mobileClientVisible() {
        this.emit('logEvent', this.STATUS_MESSAGES.CLIENT_VISIBLE);
    }

    mobileNetworkCell() {
        this.emit('logEvent', this.STATUS_MESSAGES.CLIENT_NETWORK_CELLULAR);
    }

    mobileNetworkWifi() {
        this.emit('logEvent', this.STATUS_MESSAGES.CLIENT_NETWORK_WIFI);
    }

    dashboardConnectedOffline() {
        this.emit('logEvent', this.STATUS_MESSAGES.ENTERED_OFFLINE_ROOM);
    }

    objectAnalyzingSuccess() {
        this.emit('logEvent', this.STATUS_MESSAGES.OBJECT_ANALYZING_SUCCESS);
    }

    objectAnalyzingError() {
        this.emit('logEvent', this.STATUS_MESSAGES.OBJECT_ANALYZING_ERROR);
    }

    objectAnalyzingStartTracking() {
        this.emit('logEvent', this.STATUS_MESSAGES.OBJECT_ANALYZING_START_TRACKING);
    }

    deviceAnalyzingError() {
        this.emit('logEvent', this.STATUS_MESSAGES.DEVICE_ANALYZING_ERROR);
    }

    clear() {
        // unordered messages to show
        this._messages = [];

        this._messageCounter = 0;
        this._updateCounter = 0;

        // log-type => related ids
        this._registry = {};

        this._disconnectionTimer = null;
        this._meetingIsEndedByClient = false;
        this._tosRejected = false;
    }
}
