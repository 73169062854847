/* eslint-disable init-declarations */
// @ts-ignore
import ConnectivityStandaloneCheckView from './connectivity-standalone.view.html';
// @ts-ignore
import ConnectivityStandaloneCheckMobileView from './connectivity-standalone-mobile.view.html';
import {getRootStore} from '../../app.bootstrap';

import './connectivity-standalone.style.scss';

class ConnectivityStandaloneController {
    private tsEnvironmentDetect: any;

    constructor() {
        this.tsEnvironmentDetect = getRootStore().environmentService;
    }

    isSupported() {
        const browserVersion = this.tsEnvironmentDetect.browser().major;

        return (
            (this.tsEnvironmentDetect.isChrome() && browserVersion >= 52) ||
            (this.tsEnvironmentDetect.isFF() && browserVersion >= 52) ||
            this.tsEnvironmentDetect.isIE11()
        );
    }

    getIframeSrc() {
        return '/connectivity.html#standalone=yes';
    }
}

declare const angular: any;

declare const ROUTE_BASE_PATH: string;

export default angular
    .module('states.connectivityCheckS', [])
    .config(['$stateProvider', config])
    .controller('ConnectivityStandaloneController', ConnectivityStandaloneController);

const viewDesktop = {
    template: ConnectivityStandaloneCheckView,
    controller: 'ConnectivityStandaloneController',
    controllerAs: 'connectivityStandalone'
};

const viewMobile = {
    template: ConnectivityStandaloneCheckMobileView,
    controller: 'ConnectivityStandaloneController',
    controllerAs: 'connectivityStandalone'
};

function config($stateProvider: any) {
    $stateProvider.state('connectivityStandalone', {
        url: '^' + ROUTE_BASE_PATH + 'connectivity-self-check',
        views: {
            'desktop-view': viewDesktop,
            'mobile-view': viewMobile
        }
    });
}
