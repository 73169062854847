'use strict';

import includes from 'lodash/includes';

import tsSharingModeView from './ts-sharing-mode.view.html';
import './ts-sharing-mode.scss';

const supportedDisplayModes = {
    'top-to-bottom-on-right': 'top-to-bottom-on-right',
    'left-to-right-on-left': 'left-to-right-on-left'
};

const supportedOptions = {
    copyToClipboard: 'copyToClipboard',
    download: 'download',
    sendByEmail: 'sendByEmail'
};

class TsSharingModeController {
    constructor($rootScope) {
        'ngInject';

        this.shareSrc = $rootScope.requireImage('new-interface-assets/share.png');
        this.download = $rootScope.requireImage('new-interface-assets/download.png');
        this.copyToClipboard = $rootScope.requireImage('new-interface-assets/copy-to-clipboard.png');
        this.copyToClipboardDisabled = $rootScope.requireImage('new-interface-assets/copy-to-clipboard-dis.png');
        this.emailImage = $rootScope.requireImage('new-interface-assets/send-email.png');
        this.emailImageDisabled = $rootScope.requireImage('new-interface-assets/send-email-dis.png');

        this.optionsVisible = false;
        this.signedData = null;
        this.supportedOptions = supportedOptions;
    }

    isOptionEnabled(option) {
        return includes(this.enabledOptions, option);
    }

    getDisplayMode() {
        if (!this.displayMode || !includes(supportedDisplayModes, this.displayMode)) {
            return supportedDisplayModes['top-to-bottom-on-right'];
        }

        return this.displayMode;
    }

    emailClick(event) {
        this._executeCallback(this.onSendByEmailClicked, this.signedData !== null, event);
    }

    copyClick(event) {
        this._executeCallback(this.onCopyToClipboardClicked, this.signedData !== null, event);
    }

    saveClick(event) {
        this._executeCallback(this.onDownloadClicked, true, event);
    }

    onToggleClick(event) {
        if (this.optionsVisible) {
            this._closeOptions();
        } else {
            this._openOptions();
        }

        event.stopPropagation();
    }

    _openOptions() {
        this.optionsVisible = true;
        if (this._shouldLoadSignedData()) {
            this.sharingService
                .getSignedDataForImageSharing(this.data)
                .then((signedData) => (this.signedData = signedData));
        }
    }

    _closeOptions() {
        this.optionsVisible = false;
        this.signedData = null;
    }

    _executeCallback(callback, isEnabled, event) {
        event.stopPropagation();

        if (isEnabled) {
            const signedData = this.signedData;

            this._closeOptions();

            return callback({data: {...this.data, signedData}});
        }

        return null;
    }

    _shouldLoadSignedData() {
        return (
            this.signedData === null &&
            (this.isOptionEnabled(supportedOptions.copyToClipboard) ||
                this.isOptionEnabled(supportedOptions.sendByEmail))
        );
    }
}

export function tsSharingModeDirective() {
    return {
        template: tsSharingModeView,
        replace: true,
        restrict: 'E',
        scope: {},
        bindToController: {
            displayMode: '@',
            onDownloadClicked: '&',
            onCopyToClipboardClicked: '&',
            onSendByEmailClicked: '&',
            enabledOptions: '=',
            enableCopyToClipboard: '=',
            enableImageSaving: '=',
            enableSendByEmail: '=',
            sharingService: '=',
            data: '='
        },
        controller: TsSharingModeController,
        controllerAs: 'vm'
    };
}
