import {InfoBoxComponent} from './component';
import {inject, IReactComponent} from 'mobx-react';

import './styles.scss';

const mapStoresToProps = (stores: any) => ({
    translate: stores.localizationService.translate.bind(stores.localizationService)
});

const BoxInfoComponent = inject(mapStoresToProps)(InfoBoxComponent) as IReactComponent;

export default BoxInfoComponent;
