import React from 'react';
import {observer} from 'mobx-react';
import {GenericModal} from '@techsee/techsee-ui-common/lib/modals/generic';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {IDashboardGuidanceController} from './controller';
import {AgentGuidance as GuidanceImage} from '../../invite-new/invite-wizard/components/agent-guidance/AgentGuidance';
import {ProgressList} from '../../invite-new/invite-wizard/components/progress-list/ProgressList';
import {GoToApplicationNote, ScreenShareIaTwo} from './contract';
import {ButtonsToolbar} from '@techsee/techsee-ui-common/lib/forms/buttons/toolbar';
import {PrimaryButton} from '@techsee/techsee-ui-common/lib/forms/buttons/primary';
import {Icon} from '@techsee/techsee-ui-common/lib/icons/simple-icon';

import './styles.scss';

export interface IDashboardGuidance {
    controller: IDashboardGuidanceController;
}

@observer
export class DashboardGuidance extends TechseeBaseComponent<IDashboardGuidance> {
    renderInternal() {
        const {controller} = this.props;
        const settings = controller.settings;

        const progressItems = controller.settings.progress;

        progressItems.forEach((item) => (item.label = controller.translate(item.label)));

        const Header = () => (
            <div className='guidance-header'>
                <div className='guidance-end-session' onClick={controller.endMeeting}>
                    <span>{controller.translate('REACT.DASHBOARD_GUIDANCE.END_MEETING')}</span>
                    <Icon iconName='logout'></Icon>
                </div>
            </div>
        );

        let Toolbar = () => <React.Fragment />;

        if (controller.isButtonVisible) {
            Toolbar = () => (
                <ButtonsToolbar className={'toolbar'}>
                    <PrimaryButton onClick={controller.onButtonClick}>
                        {controller.settings.buttonText}
                        <Icon iconName={'arrow-next'} />
                    </PrimaryButton>
                </ButtonsToolbar>
            );
        }

        const Images = () => (
            <React.Fragment>
                {controller.isScreenSharingIOS && (
                    <div className='col-sm-8 guidance-col ios-screen-share'>
                        <GuidanceImage
                            stepType={settings.stepType}
                            stepNum={settings.stepNum}
                            deviceType={controller.deviceType}
                            stepText={settings.stepText}
                        />

                        <GuidanceImage
                            stepType={ScreenShareIaTwo.stepType}
                            stepNum={ScreenShareIaTwo.stepNum}
                            deviceType={controller.deviceType}
                            stepText={controller.translate(ScreenShareIaTwo.stepText)}
                        />
                    </div>
                )}
                {controller.isGoToApplicationIOS && (
                    <div className='col-sm-8 guidance-col go-to-application'>
                        <GuidanceImage
                            stepType={settings.stepType}
                            stepNum={settings.stepNum}
                            deviceType={controller.deviceType}
                            stepText={settings.stepText}
                        />

                        <GuidanceImage
                            stepType={GoToApplicationNote.stepType}
                            stepNum={GoToApplicationNote.stepNum}
                            deviceType={controller.deviceType}
                            stepText={controller.translate(GoToApplicationNote.stepText)}
                        />
                    </div>
                )}
                {!controller.isScreenSharingIOS && !controller.isGoToApplicationIOS && (
                    <div className='guidance-col'>
                        <GuidanceImage
                            stepType={settings.stepType}
                            stepNum={settings.stepNum}
                            deviceType={controller.deviceType}
                            stepText={settings.stepText}
                        />
                    </div>
                )}
            </React.Fragment>
        );

        const Content = () => (
            <React.Fragment>
                <div className={'header'}>
                    <p>{settings.headerTitle}</p>
                    <p>{settings.headerSubTitle}</p>
                </div>

                <div className={'guidance row'}>
                    {progressItems.length > 0 && (
                        <div className='col-sm-4 guidance-col'>
                            <ProgressList progressList={progressItems} />
                        </div>
                    )}

                    <Images />
                </div>
            </React.Fragment>
        );

        return (
            <GenericModal
                className={`${controller.isButtonVisible ? 'active-footer' : 'inactive-footer'} dashboard-guidance`}
                header={() => Header()}
                content={() => Content()}
                toolbar={() => Toolbar()}
                onHideHandler={controller.hide}
                isCloseVisible={false}
                closeOnEscape={false}
                show={controller.isVisible}></GenericModal>
        );
    }
}
