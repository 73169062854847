import {inject, IReactComponent} from 'mobx-react';
import {InviteWizardComponent} from './InviteWizard.View';

const mapStoresToProps = (stores: any) => ({
    wizardController: stores.inviteWizardController
});

export const InviteWizard = inject(mapStoresToProps)(InviteWizardComponent) as IReactComponent;

export default InviteWizard;
