'use strict';

import {EventEmitter} from 'events';
import startsWith from 'lodash/startsWith';

export class TsVideoControlService extends EventEmitter {
    constructor($document, $window, $rootScope, $timeout, eventLog) {
        'ngInject';

        super();
        this.$document = $document;
        this.$window = $window;
        this.$rootScope = $rootScope;
        this.$timeout = $timeout;
        this.eventLog = eventLog;

        this.isActive = false;

        this._addGlobalEventHandlers();
    }

    // Override function for EventEmitter.emit. Ensures that changes in the
    // callback will be reflected on the scope (i.e. triggers digest loop)
    emit(...args) {
        const phase = this.$rootScope.$$phase;

        if (phase === '$apply' || phase === '$digest') {
            super.emit(...args);
        } else {
            this.$rootScope.$apply(() => super.emit(...args));
        }
    }

    setDimensions(width, height) {
        this.video.width = width;
        this.video.height = height;
    }

    setVideoElement(video, options) {
        // Cleanup old event handlers
        if (this.video) {
            this.video.pause();
            this.video.src = '';
            this.video.load();
        }

        this.options = options;
        this.video = video;
        this.video.crossOrigin = 'Anonymous';

        this.videoReady = false;

        $(this.video)
            .last()
            .on('error', () => {
                if (!this.video || !this.video.src) {
                    return;
                }

                this.eventLog.videoDownloadFailed({
                    url: this.video.src,
                    error: 'Failed to load video from URL'
                });
                this.emit('video:failedtoloadurl');
            });

        // Start with a clean slate;
        $(this.video)
            .last()
            .on('loadedmetadata', () => {
                this.setDimensions(this.video.videoWidth, this.video.videoHeight);
                this.videoReady = true;

                setTimeout(() => this._handleSizing(), 0);
            });

        // If provided with default dimensions, we use these
        if (this.options.width && this.options.height) {
            this.setDimensions(this.options.width, this.options.height);
        }
    }

    /*
     * Sets the video of the current element and
     * also resize control according to video aspect ratio
     *
     * @param video {objectURL} new video
     */
    setVideo(video) {
        this.videoReady = false;

        if (this.video && video.url) {
            this.video.poster = video.url;
        }

        if (this.videoChangeLock) {
            this.nextVideo = video;
        } else {
            this.videoChangeLock = true;
            this._syncSetVideo(video.video);
        }
    }

    /**
     * Sets the background video, and checks at the end if a new background
     * was specified while the current one was set. In this case it recurse
     * and sets the last specified one (if multiple backgrounds were specified
     * while locked, only the last of them is set).
     *
     * @param url {objectURL} new background video
     */
    _syncSetVideo(url) {
        if (this.video && url) {
            // Append timestamp parameter to URL to prevent browser form using a cached video, see:
            // https://stackoverflow.com/questions/14228839/all-of-my-browsers-are-not-sending-the-origin-header.
            // Signed S3 urls can't have extra params, so they are excluded from this
            if ((startsWith(url, 'http://') || startsWith(url, 'https://')) && !/X-Amz-Signature/.test(url)) {
                this.video.src = `${url}?timestamp=${moment().valueOf()}`;
            } else {
                this.video.src = url;
            }

            if (this.nextVideo) {
                const nextVideo = this.nextVideo;

                this.nextVideo = null;
                this._syncSetVideo(nextVideo);
            } else {
                this.videoChangeLock = false;
            }
        }
    }

    setContainerScale(scale) {
        const container = $(this.video).parent(),
            transform = scale || scale === 1 ? `scale(${scale})` : 'none',
            maxHeight = `${100 / scale}%`;

        container.css({transform, maxHeight});
    }

    _handleSizing() {
        const container = $(this.video).parent();

        const outerContainer = container && container.parent();

        if (!outerContainer) {
            return;
        }

        this.options.maxWidth = outerContainer.width();
        this.options.maxHeight = outerContainer.height();

        const sx = this.options.maxWidth / this.video.width,
            sy = this.options.maxHeight / this.video.height,
            sc = Math.min(sx, sy);

        this.setContainerScale(sc);
    }

    _addGlobalEventHandlers() {
        const resizeRotateHandler = () => {
            if (!this.video) {
                return;
            }

            this.$timeout.cancel(this.resetTimer);
            this.resetTimer = this.$timeout(() => this._handleSizing(), 150);
        };

        this.$window.addEventListener('resize', resizeRotateHandler);
        this.$window.addEventListener('orientationchange', resizeRotateHandler);
    }

    getTags() {
        return this.customTags || [];
    }

    setTags(tags) {
        this.customTags = tags;
    }

    get isActive() {
        return this._isActive;
    }

    set isActive(isActive) {
        if (!isActive) {
            this.videoReady = false;
        }

        this._isActive = isActive || false;
    }

    get videoUrl() {
        return this.video.src;
    }

    get videoElement() {
        return this.video;
    }
}
