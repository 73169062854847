import {IDomUtilsService} from '@techsee/techsee-client-infra/lib/services/DomUtilsService';
import {RolesConstant} from '@techsee/techsee-common/lib/constants/account.constants';
import {GuestController, IGuestController} from '../../components/guest/controller';
// @ts-ignore
import GuestStateView from './guest-state.view.html';
import {getRootStore} from '../../app.bootstrap';
import {IEventLogsService} from '../../services/EventsLogService';
import {LOG_EVENTS} from '@techsee/techsee-common/lib/constants/event-logs.constants';
import {IBrowserUtilsService} from '@techsee/techsee-client-infra/lib/services/BrowserUtilsService';
import {ssoCurrentUser} from '../helper/general.helper';
import {getAppTracer} from '../../../app.tracer';

const trace = getAppTracer('GuestState');

interface IObserverLogData {
    roomId?: string;
    shortId: string;
    userName: string;
    lastName: string;
    firstName: string;
    addNewSegment: boolean;
}

class GuestStateController {
    private guestController: IGuestController | undefined;

    private readonly _domUtilsService: IDomUtilsService;

    private readonly _eventsLogService: IEventLogsService;

    private readonly _tsUrlUtils: any;

    private _stateHelper: any;

    private _db: any;

    private _auth: any;

    private _token: string = '';

    private _browserUtilsService: IBrowserUtilsService;

    private _deepLink: string = '';

    private isSf: boolean = false;

    private isMini: boolean = false;

    private isReady: boolean = false;

    private isEmb: boolean = false;

    static $inject = ['$localStorage', 'tsUrlUtils', 'tsStateHelper', 'db', 'auth'];

    constructor($localStorage: any, tsUrlUtils: any, tsStateHelper: any, db: any, auth: any) {
        this._domUtilsService = getRootStore().domUtilsService;
        this._eventsLogService = getRootStore().eventsLogService;
        this._tsUrlUtils = tsUrlUtils;
        this._stateHelper = tsStateHelper;
        this._db = db;
        this._auth = auth;
        this._browserUtilsService = getRootStore().browserUtilsService;

        this.goToLogin = this.goToLogin.bind(this);
        this.joinSession = this.joinSession.bind(this);

        this.init();
    }

    init() {
        const ott = this._tsUrlUtils.getParamValue('ott') || '';
        const room = this._tsUrlUtils.getParamValue('g') || this._tsUrlUtils.getParamValue('roomId');

        return this._db.OTT.redeem({data: {ott}})
            .then((token: any) => {
                this._token = token.data;
                this._eventsLogService.info({
                    logType: LOG_EVENTS.guestObserverRedeemOttSuccessful,
                    meta: {ott: ott, roomIdOrGuid: room}
                });

                return getRootStore()
                    .localizationService.init()
                    .then(() => {
                        const translate = getRootStore().localizationService.translate;
                        const sessionState = {ended: false, isExpired: false};

                        this.guestController = new GuestController(
                            translate,
                            this._domUtilsService,
                            this.goToLogin,
                            this.joinSession,
                            sessionState
                        );

                        return this._db.Observations.guestObserverDetails({
                            params: {hint: ott},
                            headers: {'x-ts-token': this._token}
                        })
                            .then((res: any) => {
                                if (res.data.deepLink) {
                                    this._deepLink = res.data.deepLink;
                                    this.isSf = true;
                                    this.isMini = true;
                                    this.isEmb = true;
                                }

                                this.isReady = true;
                            })
                            .catch((err: any) => {
                                this.isReady = true;
                                this._eventsLogService.error({
                                    logType: LOG_EVENTS.guestObserverDetailsFailed.type,
                                    meta: {
                                        roomIdOrGuid: room,
                                        error: `failed GET guestObserverDetails using hint: ${ott}, with error: ${err}`
                                    }
                                });

                                return this._stateHelper.safeGo('invitationEnded', {err: true});
                            });
                    });
            })
            .catch((e: any) => {
                this._eventsLogService.error({
                    logType: LOG_EVENTS.guestObserverRedeemOttFailed.type,
                    meta: {
                        roomIdOrGuid: room,
                        error: `Guest failed to redeemOtt using ott: ${ott}, with error: ${e}`
                    }
                });

                return this._stateHelper.safeGo('invitationEnded', {err: true});
            });
    }

    goToLogin() {
        const g = this._tsUrlUtils.getParamValue('g') || this._tsUrlUtils.getParamValue('shortId'),
            roomId = this._tsUrlUtils.getParamValue('roomId'),
            roomCode = this._tsUrlUtils.getParamValue('roomCode'),
            audio = this._tsUrlUtils.getParamValue('audio');

        if (this._deepLink) {
            this._eventsLogService.info({
                logType: LOG_EVENTS.observerGoToDeepLinkFromWelcomePage,
                meta: {roomId: roomId, guid: g}
            });

            window.location.href = decodeURIComponent(this._deepLink);

            return;
        }

        this._eventsLogService.info({
            logType: LOG_EVENTS.guestObserverGoToLoginPage,
            meta: {roomId: roomId, guid: g}
        });

        if (!this._browserUtilsService.getFromSessionStorage('ssoObserver')) {
            return this._stateHelper.safeGo('login', {
                roomId,
                g,
                roomCode,
                observe: true,
                ...(this.isMini && {mini: this.isMini}),
                ...(this.isEmb && {emb: this.isEmb})
            });
        }

        return ssoCurrentUser(this._tsUrlUtils, this._db.User.find, null, null, '').then(
            (response: any) => {
                if (response && response.embeddedDashboardFailure) {
                    trace.error(
                        'GuestState failed - ssoCurrentUser: ' +
                            JSON.stringify({
                                isEmbeddedDashboard: true
                            }),
                        response.embeddedDashboardFailureError
                    );

                    return this._stateHelper.safeGo('embeddedDashboardGeneralError');
                }

                if (response.redirect) {
                    window.location.href = response.redirect;
                } else {
                    this._stateHelper.safeGo('invite', {
                        roomId,
                        g,
                        roomCode,
                        audio,
                        observe: true,
                        ...(this.isMini && {mini: this.isMini}),
                        ...(this.isEmb && {emb: this.isEmb})
                    });
                }
            },
            () => {
                this._stateHelper.safeGo('login', {
                    roomId,
                    g,
                    roomCode,
                    observe: true,
                    ...(this.isMini && {mini: this.isMini}),
                    ...(this.isEmb && {emb: this.isEmb})
                });
            }
        );
    }

    joinSession(firstName: string, lastName: string) {
        const g = this._tsUrlUtils.getParamValue('g') || this._tsUrlUtils.getParamValue('shortId'),
            roomId = this._tsUrlUtils.getParamValue('roomId'),
            room = roomId || g,
            audio = this._tsUrlUtils.getParamValue('audio');

        this._tsUrlUtils.setParamValue('room', room);

        const data: IObserverLogData = {
            shortId: g,
            userName: RolesConstant.GUEST_OBSERVER,
            lastName: lastName,
            firstName: firstName,
            addNewSegment: true
        };

        if (roomId) {
            data.roomId = roomId;
        }

        return this._db.Observations.guestObserverLogin({data: {token: this._token}})
            .then((token: any) => {
                this._auth.setAuthToken(token.data);

                return this._db.Observations.add({data}).then(() =>
                    this._stateHelper.safeGo('invite', {
                        room,
                        roomId,
                        g,
                        audio,
                        observe: true,
                        ...(this.isMini && {mini: this.isMini}),
                        ...(this.isEmb && {emb: this.isEmb})
                    })
                );
            })
            .catch((err: any) => {
                this._eventsLogService.error({
                    logType: LOG_EVENTS.guestObserverDetailsFailed.type,
                    meta: {
                        roomIdOrGuid: room,
                        error: `Failed login guestObserver ${firstName} ${lastName}, with error: ${err}`
                    }
                });

                return this._stateHelper.safeGo('invitationEnded', {err: true});
            });
    }
}

declare const angular: any;

declare const ROUTE_BASE_PATH: string;

export default angular
    .module('states.guest', [])
    .config(['$stateProvider', config])
    .controller('GuestStateController', GuestStateController);

const view = {
    template: GuestStateView,
    controller: 'GuestStateController',
    controllerAs: 'guest'
};

function config($stateProvider: any) {
    $stateProvider.state('guest', {
        url:
            ROUTE_BASE_PATH +
            'guest?authId&r&p&c&is&roomId&g&roomCode&nl&ott&audio&observe&emb&mini&sw&startDate&endDate',
        views: {
            'desktop-view': view,
            'mobile-view': view
        }
    });
}
