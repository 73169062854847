'use strict';

import embeddedDashboardGeneralErrorView from './embedded-dashboard-general-error.html';
import {EmbeddedDashboardGeneralErrorController} from './embedded-dashboard-general-error.controller.js';
import './embedded-dashboard-general-error.style.scss';

function config($stateProvider) {
    $stateProvider.state('embeddedDashboardGeneralError', {
        url: '/emb-dashboard-error',
        views: {
            'desktop-view': {
                template: embeddedDashboardGeneralErrorView,
                controller: 'EmbeddedDashboardGeneralErrorController',
                controllerAs: 'embeddedDashboardGeneralError'
            },
            'mobile-view': {
                template: embeddedDashboardGeneralErrorView,
                controller: 'EmbeddedDashboardGeneralErrorController',
                controllerAs: 'embeddedDashboardGeneralError'
            }
        }
    });
}

export default angular
    .module('states.embeddedDashboardGeneralError', [])
    .config(config)
    .controller('EmbeddedDashboardGeneralErrorController', EmbeddedDashboardGeneralErrorController);
