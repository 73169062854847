import {IDbObservation, IObserverData} from './AngularServices';

export class DbObservationAdapter implements IDbObservation {
    constructor(db: any) {
        Object.defineProperty(this, '_dbService', {
            value: db,
            enumerable: false
        });
    }

    addNew(params: IObserverData): Promise<void> {
        return (this as any)._dbService.Observations.add(params);
    }

    find(roomId: string): Promise<any> {
        return (this as any)._dbService.Observations.find(roomId);
    }
}
