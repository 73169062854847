'use strict';

import get from 'lodash/get';
import includes from 'lodash/includes';
import {PlatformType} from '@techsee/techsee-common/lib/constants/utils.constant';
import {TraceLevel, TraceOutputData} from '@techsee/techsee-common/lib/core/Tracer';
import {LOG_EVENTS} from '@techsee/techsee-common/lib/constants/event-logs.constants';

export class TsExtraLoggingService {
    private _eventLog: any;

    private _enabled: boolean = true;

    private _logOnlySpecifiedCategories: boolean = false;

    private _logCategories?: [string] | null;

    constructor() {
        this.traceToEventLogOutputFunction = this.traceToEventLogOutputFunction.bind(this);
    }

    init(account: object, eventLog: any) {
        if (account) {
            this._enabled = !!get(account, 'protectedSettings.verboseLogging.enabled');
        }

        this._eventLog = eventLog;
        this._logOnlySpecifiedCategories = !!get(
            account,
            'protectedSettings.verboseLogging.logOnlySpecifiedCategories'
        );
        // @ts-ignore
        this._logCategories = (get(account, 'protectedSettings.verboseLogging.categories') as string[]) || [];
    }

    traceToEventLogOutputFunction(traceData: TraceOutputData): Promise<void> {
        if (
            !this._enabled ||
            traceData.traceLevel === TraceLevel.Debug ||
            (this._logOnlySpecifiedCategories &&
                this._logCategories !== null &&
                !includes(this._logCategories, traceData.tracerNamePostfix))
        ) {
            return Promise.resolve();
        }

        const metaData = {
            side: PlatformType.dashboard,
            logType: traceData.traceLevel === TraceLevel.Error ? LOG_EVENTS.traceErr.type : LOG_EVENTS.trace,
            verboseLogging: this._enabled,
            verboseLogType: traceData.tracerName + ':' + traceData.tracerNamePostfix,
            message: traceData.message,
            details: traceData.optionalData,
            traceLevel: traceData.traceLevel,
            sourceUrl: window.location.href,
            clientVersion: CLIENT_VERSION
        };

        return this._eventLog
            .trace(metaData)
            .catch((err: any) =>
                console.error(
                    ' >> send trace as eventlog failed: >> msg:' +
                        traceData.message +
                        ' >> err: ' +
                        err +
                        ' >> metaData' +
                        JSON.stringify(metaData)
                )
            );
    }
}
