'use strict';

/*
 * Fixes alignment of table bodies to headers. The body is assumed to have a
 * 100% width, relative to its container (the table), so when a scrollbar
 * appears, this directive will make sure the actual body width is still that
 * of the full container, thus remaining aligned to any headers that exist on
 * top of it (which would become un-aligned, due to not having a scrollbar of
 * their own). Used in states/invite/offline-pool
 */
class TsTableScrollFixController {
    constructor() {
        'ngInject';

        this.offset = 0;
    }

    checkOffset(tableBody) {
        return tableBody.parentNode.clientWidth - tableBody.clientWidth + this.offset;
    }

    fixOffset(tableBody, offset) {
        this.offset = offset;

        tableBody.style.width = offset ? `calc(100% + ${offset}px)` : '100%';
    }
}

function linkFn(scope, element, attrs, ctrl) {
    const tableBody = element[0];

    scope.$watch(
        () => ctrl.checkOffset(tableBody),
        (offset) => {
            ctrl.fixOffset(tableBody, offset);
        }
    );
}

export function tsTableScrollFixDirective() {
    return {
        restrict: 'A',
        scope: false,
        controller: TsTableScrollFixController,
        controllerAs: 'vm',
        link: linkFn
    };
}
