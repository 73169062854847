import React from 'react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {ConfirmationModal} from '@techsee/techsee-ui-common/lib/modals/confirmation-modal';
import {AbortButton} from '@techsee/techsee-ui-common/lib/forms/buttons/abort';
import {ITranslate} from '../../../../services/LocalizationService';
import {ISessionAbortController} from './controller';
import {observer} from 'mobx-react';

export interface ISessionAbort {
    ctrl: ISessionAbortController;
    translate: ITranslate;
}

@observer
export class SessionAbort extends TechseeBaseComponent<ISessionAbort> {
    renderInternal() {
        const {ctrl} = this.props;

        return (
            <div className={'session-abort'}>
                <AbortButton onClick={() => ctrl.onAbortClicked()} disabled={!ctrl.isSessionActive} />
                <ConfirmationModal
                    header={this.props.translate('REACT.INVITE.VIEW.ABORT_TITLE')}
                    content={this.props.translate('REACT.INVITE.VIEW.ABORT_CONTENT')}
                    show={ctrl.isDisplayConfirmationModal}
                    positiveText={this.props.translate('REACT.COMMON.BUTTONS.CONFIRM')}
                    negativeText={this.props.translate('REACT.COMMON.BUTTONS.DECLINE')}
                    onPositiveClick={() => ctrl.continueSession()}
                    onNegativeClick={() => ctrl.abortSession()}></ConfirmationModal>
            </div>
        );
    }
}
