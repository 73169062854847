import React from 'react';
import {observer} from 'mobx-react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {ITranslate} from '../../../../services/LocalizationService';

import './styles.scss';

export interface IRightSide {
    translate: ITranslate;
}

@observer
export class RightSide extends TechseeBaseComponent<IRightSide> {
    renderInternal() {
        const {translate} = this.props;
        const currentYear = new Date().getFullYear();

        return (
            <div className='login-copyright'>
                {translate('REACT.LOGIN.VIEW.COPYRIGHT', {currentYear})}&nbsp;
                <a href='https://techsee.me/terms-and-conditions/' target='_blank' rel='noopener noreferrer'>
                    {translate('REACT.LOGIN.VIEW.TERMS')}
                </a>
                &nbsp;
                <a href='https://techsee.me/privacy-policy/' target='_blank' rel='noopener noreferrer'>
                    {translate('REACT.LOGIN.VIEW.PRIVACY')}
                </a>
            </div>
        );
    }
}

export default RightSide;
