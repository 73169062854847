// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../../../img/react-assets/customer-left.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customer-ended-the-meeting{width:100%;height:100vh;display:flex;justify-content:center;margin-top:123px;text-align:center}.customer-ended-the-meeting .content{width:536px}.customer-ended-the-meeting .customer-left-icon{width:77px;height:115px;display:inline-block;background:url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center left}.customer-ended-the-meeting header{margin:24px 0 21px;font-size:24px}.customer-ended-the-meeting p{font-size:16px}
`, "",{"version":3,"sources":["webpack://./app/_react_/states/multipartyDashboard/components/customerEndedTheMeeting/CustomerEndedTheMeeting.scss"],"names":[],"mappings":"AAAA,4BACI,UAAW,CACX,YAAa,CAEb,YAAa,CACb,sBAAuB,CACvB,gBAAiB,CAEjB,iBAAkB,CARtB,qCAWQ,WAAY,CAXpB,gDAeQ,UAAW,CACX,YAAa,CAEb,oBAAqB,CAErB,wEAA6F,CApBrG,mCAwBQ,kBAAmB,CAEnB,cAAe,CA1BvB,8BA8BQ,cAAe","sourcesContent":[".customer-ended-the-meeting {\n    width: 100%;\n    height: 100vh;\n\n    display: flex;\n    justify-content: center;\n    margin-top: 123px;\n\n    text-align: center;\n\n    .content {\n        width: 536px;\n    }\n\n    .customer-left-icon {\n        width: 77px;\n        height: 115px;\n\n        display: inline-block;\n\n        background: url('../../../../../../img/react-assets/customer-left.png') no-repeat center left;\n    }\n\n    header {\n        margin: 24px 0 21px;\n\n        font-size: 24px;\n    }\n\n    p {\n        font-size: 16px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
