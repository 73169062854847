import React from 'react';
import {observer} from 'mobx-react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {CollapsiblePanel} from '@techsee/techsee-ui-common/lib/collapsible-panel';
import {ImageTooltip, PreviewActions} from '@techsee/techsee-ui-common/lib/image-tooltip';
import {IPort, ISelfServiceController, IStep} from './controller';
import isEmpty from 'lodash/isEmpty';
import {ITranslate} from '../../../services/LocalizationService';
import {
    BorderRoundedIcon,
    IconColor,
    IconColors,
    IconSize,
    IconSizes
} from '@techsee/techsee-ui-common/lib/icons/large-rounded-icon';

import './styles.scss';

enum StatusEnum {
    CONNECTED = 'CONNECTED',
    DISCONNECTED = 'DISCONNECTED',
    OFF = 'OFF'
}

const titleHeight = 41;
const stepHeight = 61;

export interface ISelfServiceComponent {
    ctrl: ISelfServiceController;
}

@observer
export class SelfServiceComponent extends TechseeBaseComponent<ISelfServiceComponent> {
    renderLeds(leds: any, translate: ITranslate) {
        return leds.map((led: any, index: number) => {
            let status = StatusEnum.CONNECTED;
            let icon = 'check';
            let color = IconColors.Green;

            if (led.status === StatusEnum.DISCONNECTED) {
                status = StatusEnum.DISCONNECTED;
                icon = 'unsuccess';
                color = IconColors.Error;
            }

            if (led.status === StatusEnum.OFF) {
                status = StatusEnum.OFF;
                icon = 'unsuccess';
                color = IconColors.DarkGray;
            }

            return (
                <div key={index} className={'row-style'}>
                    <BorderRoundedIcon
                        iconName={icon}
                        noBorder={false}
                        sizeName={IconSizes.Medium as IconSize}
                        colorName={color as IconColor}
                        className={'icon-mapping'}
                    />
                    <span className={icon}>{translate(`MAIN.VIEW.ANALYZE_SUMMARY.${led.translate}${status}`)}</span>
                </div>
            );
        });
    }

    renderLedsData(ledsMapping: any, translate: ITranslate) {
        if (!ledsMapping || isEmpty(ledsMapping)) {
            return <React.Fragment />;
        }

        return (
            <div>
                <div className={'warm-transfer-styles__sub-title'}>Led mapping</div>
                {this.renderLeds(ledsMapping, translate)}
            </div>
        );
    }

    renderPortsData(portsMapping: any, translate: ITranslate) {
        if (!portsMapping || isEmpty(portsMapping)) {
            return <React.Fragment />;
        }

        return (
            <React.Fragment>
                <div className={'warm-transfer-styles__sub-title'}>Port mapping</div>
                {this.renderPorts(portsMapping, translate)}
            </React.Fragment>
        );
    }

    renderPorts(ports: IPort[], translate: ITranslate) {
        return ports.map((port: IPort, index: number) => {
            let status = StatusEnum.CONNECTED;
            let icon = 'check';

            if (!port.connected) {
                status = StatusEnum.DISCONNECTED;
                icon = 'unsuccess';
            }

            return (
                <div key={index} className={'row-style'}>
                    <BorderRoundedIcon
                        iconName={icon}
                        noBorder={false}
                        sizeName={IconSizes.Medium as IconSize}
                        colorName={(icon === 'check' ? IconColors.Green : IconColors.Error) as IconColor}
                        className={'icon-mapping'}
                    />
                    <span className={icon === 'unsuccess' ? 'error' : ''}>
                        {translate(
                            port.message ? port.message : `MAIN.VIEW.ANALYZE_SUMMARY.${port.translate}${status}`
                        )}
                    </span>
                </div>
            );
        });
    }

    renderSteps(steps: IStep[], translate: ITranslate, length: number, isStepsOpen: boolean) {
        if (!steps) {
            return <React.Fragment />;
        }

        return steps
            .slice(0)
            .reverse()
            .map((step: IStep, index: number) => (
                <div
                    onClick={() => this.props.ctrl.clickStepHandler(step)}
                    key={`step${index}`}
                    className={step.image ? 'warm-transfer-styles__step' : 'warm-transfer-styles__step not-clickable'}>
                    <div className={'tooltip-step'}>
                        {isStepsOpen && step.image && (
                            <ImageTooltip
                                src={step.image.url}
                                thumbnailSize={{w: 50, h: 50}}
                                previewSize={{w: 215, h: 215}}
                                previewAction={PreviewActions.HOVER}
                            />
                        )}
                    </div>
                    <div className={'step-title'}>
                        <span className={'number'}>{length - index}.</span>
                        {translate(`MAIN.VIEW.ANALYZE_SUMMARY.${step.name}`)}
                    </div>
                </div>
            ));
    }

    renderInternal() {
        const {
            show,
            deviceType,
            transferInnitiator,
            translate,
            steps,
            portsMapping,
            ledsMapping,
            isStepsOpen,
            toggleSteps
        } = this.props.ctrl;

        if (!show || !deviceType) {
            return <React.Fragment />;
        }

        return (
            <div className='warm-transfer-styles'>
                <div className={'warm-transfer-styles__body use-common-styles'}>
                    <div className='warm-transfer-styles__title'>
                        {translate('REACT.SELF_SERVICE.ANALYZE_SUMMARY.SELF_SERVICE_SUMMARY')}
                    </div>
                    <div className={'warm-transfer-styles__sub-title'}>
                        {translate('REACT.SELF_SERVICE.ANALYZE_SUMMARY.DEVICE_TYPE')}
                    </div>
                    <div className={'warm-transfer-styles__value spacing'}>{deviceType}</div>
                    <div className={'warm-transfer-styles__sub-title'}>
                        {translate('REACT.SELF_SERVICE.ANALYZE_SUMMARY.TRANSFER_INNITIATOR')}
                    </div>
                    <div className={'warm-transfer-styles__value spacing'}>
                        {translate(`MAIN.VIEW.ANALYZE_SUMMARY.REQUESTED_BY_${transferInnitiator}`)}
                    </div>
                    <CollapsiblePanel
                        width='inherit'
                        height={`${titleHeight + stepHeight}px`}
                        title={translate('REACT.SELF_SERVICE.COLLAPSE_TITLE_STEPS', {
                            numberSteps: steps && steps.length
                        })}
                        isOpen={isStepsOpen}
                        onToggleClick={toggleSteps}>
                        <div className={'warm-transfer-styles__value'}>
                            {this.renderSteps(steps, translate, steps && steps.length, isStepsOpen)}
                        </div>
                    </CollapsiblePanel>
                    {this.renderPortsData(portsMapping, translate)}
                    {this.renderLedsData(ledsMapping, translate)}
                </div>
            </div>
        );
    }
}
