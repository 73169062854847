import React from 'react';
import {SimpleLabel} from '@techsee/techsee-ui-common/lib/forms/labels/simple';
import {IGuidancePopoversController} from './GuidancePopovers.controller';
import {observer} from 'mobx-react';

import './GuidancePopovers.styles.scss';

interface IMeasurementGuidanceProps {
    ctrl: IGuidancePopoversController;
    onUpdate(): void;
}

@observer
export class MeasurementGuidance extends React.Component<IMeasurementGuidanceProps> {
    componentDidMount() {
        this.props.ctrl.onUpdatePopoverContent = this.props.onUpdate;
    }

    render() {
        const {ctrl} = this.props;

        return (
            <div className='guidance-content'>
                {ctrl.measurementStep === 1 ? (
                    <SimpleLabel>{ctrl.translate('REACT.FLOATING_TOOLBAR.MEASUREMENT_GUIDE_MARKERS')}</SimpleLabel>
                ) : (
                    <SimpleLabel>
                        {ctrl.translate('REACT.FLOATING_TOOLBAR.MEASUREMENT_GUIDE_SELECT_OBJECT', {
                            object: ctrl.measurementObject
                        })}
                    </SimpleLabel>
                )}
            </div>
        );
    }
}
