/* eslint-disable consistent-return */

import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import {CoBrowserControlsEnum, coBrowsingControlTypes} from '@techsee/techsee-common/lib/constants/account.constants';
import {TIMEOUT} from './ts-cobrowsing-service.settings';
import {COBROWSING_EVENTS} from '@techsee/techsee-common/lib/constants/room.constants';
import {getAppTracer} from '../../app.tracer';
import {CoBrowsingServiceBase} from './CoBrowsingServiceBase';

const trace = getAppTracer('CoBrowsing');

export class TsCobrowsingService extends CoBrowsingServiceBase {
    constructor(tsChatApi, db, $rootScope, eventLog, $timeout, mediaFrameUIParams) {
        'ngInject';

        super(true, tsChatApi.service, db);

        this.chatApi = tsChatApi.service;
        this.db = db;
        this.$rootScope = $rootScope;
        this.$timeout = $timeout;
        this._eventLog = eventLog;
        this.mediaFrameUIParams = mediaFrameUIParams;

        this.chatApi.on('cobrowsingPauseAction', (pause) => {
            this.isPaused = pause;
            this._updateCobrowsingControls(pause, CoBrowserControlsEnum.pause);
        });

        this.chatApi.on('showLoaderAction', (value) => {
            this.otherSideLoading = value;
            if (!value && !this.showLoader) {
                this.$rootScope.safeApply();
            }

            value ? this._loaderTimeout() : this.$timeout.cancel(this._currentTimer);
        });

        this.on(COBROWSING_EVENTS.CHANGE_COBROWSING_URL, (url) => {
            this._updateCobrowsingControls(url, CoBrowserControlsEnum.url);
            this._eventLog.coBrowsingChangeUrl({url: url});
        });

        this.on(COBROWSING_EVENTS.SESSION_STARTED, () => {
            trace.info('Co Browsing session started');
        });

        this.on(COBROWSING_EVENTS.SESSION_ENDED, () => {
            trace.info('Co Browsing session ended');
        });

        this.on(COBROWSING_EVENTS.CONTROL_SWITCH, (agentInControl) => {
            const sentBy = agentInControl ? coBrowsingControlTypes.USER : coBrowsingControlTypes.AGENT;

            this._updateCobrowsingControls(agentInControl, CoBrowserControlsEnum.control);
            this._eventLog.coBrowsingSwitchControl({agentInControl, sentBy});
            trace.info('Co Browsing - switch control');
        });

        this.on(COBROWSING_EVENTS.ERROR, (err) => {
            trace.error('Error in co-browsing by Surfly: ', err);
        });
    }

    _updateCobrowsingControls(value, controlState) {
        const settings = get(this.chatApi, 'dashboard.coBrowsingSettings');

        if (settings) {
            const updatedSettings = cloneDeep(settings);

            switch (controlState) {
                case CoBrowserControlsEnum.pause:
                    updatedSettings.isPaused = value;
                    break;
                case CoBrowserControlsEnum.control:
                    updatedSettings.agentInControl = value;
                    break;
                case CoBrowserControlsEnum.url:
                    updatedSettings.url = value;
                    break;
                default:
                    break;
            }

            this.chatApi.setStatus('coBrowsingSettings', updatedSettings);
        }
    }

    start(accountId, roomId, widgetKey) {
        if (this.inProgress) {
            return;
        }

        this.inProgress = true;
        this.roomId = roomId;
        this.accountId = accountId;
        this.isInControl = get(this.chatApi, 'dashboard.coBrowsingSettings.agentInControl');
        this.isPaused = get(this.chatApi, 'dashboard.coBrowsingSettings.isPaused');

        this.getSurflyObject(window, widgetKey)
            .then(() => {
                this.updateClientDimensions();

                trace.info('Co Browsing getSurflyObject success');

                return this._initCoBrowsing();
            })
            .catch((err) => {
                this.emit(COBROWSING_EVENTS.INIT_ERROR, err);
                this._eventLog.coBrowsingSurflySessionFailed({
                    failure: err
                });

                trace.info('Co Browsing getSurflyObject failed', err);

                this.inProgress = false;
            });
    }

    _showHideLoader(value) {
        this.showLoader = value;
        this.$rootScope.safeApply();
        this.chatApi.requestAction('showLoader', value);

        value ? this._loaderTimeout() : this.$timeout.cancel(this._currentTimer);
    }

    _loaderTimeout() {
        if (this._currentTimer) {
            this.$timeout.cancel(this._currentTimer);
        }

        this._currentTimer = this.$timeout(() => {
            if (this.showLoader) {
                const iframeId = '#coBrowsingFollowerFrame';

                $(iframeId)[0].src += '';
                this._currentTimer = null;
            }
        }, TIMEOUT.LOADER_TIMEOUT);
    }

    _isInitialUrlLoad(eventData) {
        // FIXME: 'event.data.value.length < 11' hack that needs to be removed - ask Guy
        return (
            eventData.message === CoBrowserControlsEnum.url &&
            eventData.value.length < 11 &&
            !get(this.chatApi, 'dashboard.coBrowsingSettings.leaderLink')
        );
    }

    _initCoBrowsing() {
        const startUrl = get(this.chatApi, 'dashboard.coBrowsingSettings.url');

        super
            .initCoBrowsing(this.accountId, this.roomId, startUrl)
            .then((response) => {
                this.chatApi.setStatus('coBrowsingSettings', {
                    sessionId: response.data.sessionId,
                    followerLink: response.data.followerLink,
                    leaderLink: response.data.leaderLink,
                    url: response.data.url,
                    agentInControl: this.isInControl,
                    isPaused: this.isPaused
                });
                this._eventLog.coBrowsingSurflySessionSuccess();

                trace.info('Co Browsing initSession success');

                return this._initSession();
            })
            .catch((err) => {
                this._eventLog.coBrowsingSurflySessionFailed({
                    err,
                    failure: 'start session failed'
                });

                trace.info('Co Browsing initSession failed', err);

                throw err;
            });
    }

    updateClientDimensions() {
        const width = window.screen.width - this.mediaFrameUIParams.CO_BROWSING_OFFSET_WIDTH;
        const height = window.screen.availHeight - this.mediaFrameUIParams.CO_BROWSING_OFFSET_HEIGHT;

        this.clientDimensions = {
            'max-height': 'calc(100vh - 80px)',
            height: height ? height + 'px' : '80%',
            width: width ? width + 'px' : '80%'
        };
    }

    _initSession() {
        const url = get(this.chatApi.dashboard, 'coBrowsingSettings.followerLink');

        if (!url) {
            return;
        }
        super.initSession(url);
    }

    get isInSession() {
        return !!this._sessionObject;
    }

    get EVENTS() {
        return COBROWSING_EVENTS;
    }
}
