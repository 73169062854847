import {ITranslate} from '../../services/LocalizationService';
import {action, computed, observable} from 'mobx';

export interface ISelectVideoPublisherController {
    readonly translate: ITranslate;
    viewVideoPublisher: boolean;

    setVideoPublisherSide(isViewVideoSelected: boolean): void;
}

export class SelectVideoPublisherController implements ISelectVideoPublisherController {
    private readonly _translate: ITranslate;

    @observable private _viewVideoPublisher: boolean;

    constructor(translate: ITranslate) {
        this._translate = translate;
        this._viewVideoPublisher = true;

        this.setVideoPublisherSide = this.setVideoPublisherSide.bind(this);
    }

    @action
    setVideoPublisherSide(isViewVideoSelected: boolean) {
        this._viewVideoPublisher = isViewVideoSelected;
    }

    @computed
    get viewVideoPublisher() {
        return this._viewVideoPublisher;
    }

    get translate() {
        return this._translate;
    }
}
