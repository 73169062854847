import {EventEmitter} from 'events';

export interface ISpeedtestResults {
    serverName: string;
    serverLocation: string;
    timestamp: Date;
    upload?: number;
    download?: number;
    latency?: number;
    clientIP: string;
}

export interface ISpeedtestService {
    resultsHandler(results: any): void;
    onResultsProcessed(handler: Function): void;
}

export class SpeedtestService extends EventEmitter implements ISpeedtestService {
    resultsHandler(results: ISpeedtestResults): void {
        this.emit('resultsProcessed', results);
    }

    onResultsProcessed(handler: Function): void {
        this.on('resultsProcessed', (data: ISpeedtestResults) => handler(data));
    }
}
