import React from 'react';
import {observer} from 'mobx-react';
import {InviteByCodeStepController} from './controller';
import {AgentGuidance} from '../../components/agent-guidance/AgentGuidance';
import {InviteDeviceType} from '../../../_contracts/InviteContracts';

export interface IInviteByCodeStepContentProps {
    step: InviteByCodeStepController;
}

@observer
export class InviteByCodeStepContent extends React.Component<IInviteByCodeStepContentProps> {
    componentDidMount() {
        this.props.step.setPagePlacement('invite-by-code-step');
    }

    render() {
        const {inviteCode, desktopShowURL, translate} = this.props.step;

        return (
            <div className='invite-by-code'>
                <h1 className='page-header'>{translate('REACT.INVITE.VIEW.ENTER_CODE_TITLE')}</h1>
                <h2
                    className='sub-header'
                    dangerouslySetInnerHTML={{
                        __html: translate('REACT.INVITE.VIEW.ENTER_CODE_SUB_TITLE', {url: desktopShowURL.displayUrl})
                    }}></h2>

                <div className='invite-by-code__content'>
                    <div className='invite-by-code__code'>
                        <span>{translate('REACT.INVITE.VIEW.SESSION_CODE')}</span>
                        <h3>{inviteCode}</h3>
                    </div>
                    <div className='invite-by-code__guidance'>
                        <div className='invite-by-code__image'>
                            <AgentGuidance
                                stepText={translate('REACT.INVITE.GUIDANCE.ENTER_CODE')}
                                stepNum={1}
                                stepType={'desktop-sharing'}
                                deviceType={InviteDeviceType.common}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
