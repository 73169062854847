/*
 * This controller is responsible to load initial data, decide which of "flows" should be used (new or old) and load it.
 */

import {getRootStore} from '../../_react_/app.bootstrap';
import get from 'lodash/get';
// @ts-ignore
import {getSubDomainFromUrl} from '@techsee/techsee-common/lib/utils';

export default class LoginRootController {
    constructor($state, $scope, tsStateHelper, db) {
        'ngInject';

        this._tsStateHelper = tsStateHelper;
        this._db = db;
        this._stateParams = $state.params;
        this.environmentDetect = getRootStore().environmentService;
        this.browserUtilsService = getRootStore().browserUtilsService;
        this.displayTabletAsDesktop = getRootStore().displayTabletAsDesktop;
        this.isPhone = this.environmentDetect.isPhone();

        if (
            (this._stateParams.tablet || this.environmentDetect.isTablet(this.displayTabletAsDesktop)) &&
            !this.displayTabletAsDesktop
        ) {
            this.browserUtilsService.saveToLocalStorage('displayTabletAsDesktop', true);
            location.reload(true);
        }

        $scope.$watch(
            () => $state.$current.name,
            (newState, oldState) => {
                if (oldState.match(/login\./i) && newState.match(/login/i)) {
                    this._stateParams.observe === 'guest' ? this.redirectToGuestState() : this.redirectToLoginState();
                }
            }
        );

        if ($state.current.name.match(/login/)) {
            this._stateParams.observe === 'guest' ? this.redirectToGuestState() : this.redirectToLoginState();
        }
    }

    redirectToGuestState() {
        const {r, p, c, is, roomId, g, roomCode, audio, observe, emb, mini, ott, sw} = this._stateParams;

        this._tsStateHelper.safeGo('guest', {r, p, c, is, roomId, g, roomCode, audio, observe, emb, mini, ott, sw});
    }

    isEnv(platform) {
        return this.environmentDetect.isEnvironment(platform, this.displayTabletAsDesktop);
    }

    redirectToLoginState() {
        const {r, p, c, is, roomId, g, roomCode, audio, observe, emb, mini, sw, startDate, endDate} = this._stateParams;

        const miniDashboard = getRootStore().browserUtilsService.getFromSessionStorage('miniDashboard');

        if (miniDashboard) {
            return this._tsStateHelper.safeGo('embeddedDashboardGeneralError');
        }

        return this._assertManualLoginAbility().then((blockManualLogin) => {
            if (blockManualLogin) {
                return;
            }

            if (this.isPhone) {
                return this._tsStateHelper.safeGo('login.old', {
                    r,
                    p,
                    c,
                    is,
                    roomId,
                    g,
                    roomCode,
                    audio,
                    observe,
                    emb,
                    mini,
                    sw,
                    startDate,
                    endDate
                });
            }

            return this._tsStateHelper.safeGo('login.new', {
                r,
                p,
                c,
                is,
                roomId,
                g,
                roomCode,
                audio,
                observe,
                emb,
                mini,
                sw,
                startDate,
                endDate
            });
        });
    }

    _assertManualLoginAbility() {
        const subDomain = getSubDomainFromUrl(window.location.href);

        return this._db.Account.manualLoginAbilityAssertion({params: {subDomain: subDomain}})
            .then((res) => {
                const loginEnabled = get(res, 'data.enabled');
                const redirectUrl = get(res, 'data.redirectUrl');
                const enabledLive3 = get(res, 'data.enabledLive3');

                if (enabledLive3) {
                    getRootStore().redirectionService.goToPortal();

                    return true;
                }

                if (!loginEnabled && redirectUrl) {
                    window.location.href = redirectUrl;

                    return true;
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }
}
