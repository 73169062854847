import React from 'react';
import {observer} from 'mobx-react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {IFallbackModeController} from './controller';
import {SimpleLabel} from '@techsee/techsee-ui-common/lib/forms/labels/simple';
import UserMessageModal from '@techsee/techsee-ui-common/lib/modals/user-message-modal';

import './styles.scss';

export interface IFallbackModeModal {
    controller: IFallbackModeController;
}

@observer
export class FallbackModeModal extends TechseeBaseComponent<IFallbackModeModal> {
    renderInternal() {
        const {controller} = this.props;

        const Content = () => (
            <div className={'modal-switch-mode-container'}>
                <img src={controller.loadImage('switch-mode-photo.png')} />

                <SimpleLabel className={'message main-message'}>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: controller.translate('REACT.PHOTO_STREAM_REASON.VIEW.SWITCHING_MODE', {
                                mode: controller.displayMode
                            })
                        }}></div>
                </SimpleLabel>
                <SimpleLabel className={'message'}>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: controller.translate('REACT.PHOTO_STREAM_REASON.VIEW.' + controller.reason)
                        }}></div>
                </SimpleLabel>
            </div>
        );

        return (
            <UserMessageModal
                className={'switch-mode use-common-styles'}
                content={Content}
                onOkButtonClick={controller.hide}
                show={controller.isVisible}
                okButtonText={controller.translate('REACT.PHOTO_STREAM_REASON.VIEW.GO')}></UserMessageModal>
        );
    }
}
