'use strict';

/**
 * This directive compiles the passed HTML and embeds it into the element.
 * Note: The compilation and embedding are done only once when this directive is loaded.
 * @param scope
 * @param element
 * @param attrs
 */
function linkFn(scope, element, attrs) {
    const html = scope.$eval(attrs.compile);

    element.html(html);
    this.$compile(element.contents())(scope);
}

export function compileDirective($compile) {
    'ngInject';

    return {
        restrict: 'A',
        scope: true,
        link: angular.bind({$compile}, linkFn)
    };
}
