import React from 'react';
import {observer} from 'mobx-react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {getRootStore} from '../../app.bootstrap';

import './styles.scss';

@observer
export class RecordingIndicator extends TechseeBaseComponent {
    translate = getRootStore().localizationService.translate;

    renderInternal(): JSX.Element {
        return (
            <div className='recording-indicator-container'>
                <span className='dot'></span>
                <span className='rec-title'>{this.translate('REACT.DASHBOARD.VIEW.RECORDING_INDICATOR.TITLE')}</span>
            </div>
        );
    }
}
