'use strict';

class TsMouseWheelController {
    constructor($element, $attrs, $scope, $parse) {
        'ngInject';

        this.$parse = $parse;
        this.$scope = $scope;
    }
}

function linkFn(scope, element, attrs, ctrl) {
    const expression = ctrl.$parse(attrs.tsMouseWheel);
    let hamster = element.data('hamster');

    const fn = (event, delta, deltaX, deltaY) => {
        scope.$apply(() => {
            expression(ctrl.$scope, {
                $event: event,
                $delta: delta,
                $deltaX: deltaX,
                $deltaY: deltaY
            });
        });
    };

    // Don't create multiple Hamster instances per element
    if (!hamster) {
        // eslint-disable-next-line no-undef
        hamster = new Hamster(element[0]);
        element.data('hamster', hamster);
    }

    // Bind Hamster wheel event
    hamster.wheel(fn);

    // Unbind Hamster wheel event
    scope.$on('$destroy', () => {
        hamster.unwheel(fn);
    });
}

export function tsMouseWheelDirective() {
    return {
        restrict: 'A',
        controller: TsMouseWheelController,
        controllerAs: 'vm',
        link: linkFn
    };
}
