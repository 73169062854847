import React from 'react';
import {computed} from 'mobx';
import {IReactComponent} from 'mobx-react';
import {BasicWizardStep} from '@techsee/techsee-ui-common/lib/wizard/controllers/BasicWizardStep';
import {IMainLayoutController} from '../../../../layouts/main-layout/layout.controller';
import {IInviteFlowManager, InviteStepsEnum, InviteDeviceType} from '../../_contracts/InviteContracts';
import {ITranslate} from '../../../../services/LocalizationService';
import {IDomUtilsService} from '@techsee/techsee-client-infra/lib/services/DomUtilsService';

export interface InviteStepParams {
    stepType: InviteStepsEnum;
    content: IReactComponent;
    toolbar?: IReactComponent;
    className?: string;
    isLeftBarVisible?: boolean;
}

export interface IInviteStepController {
    readonly isDesktopSharing: boolean;
    readonly desktopShowURL: {url: string; displayUrl: string};
    readonly clientBrowser: string;

    setPagePlacement(placeName: string): void;

    clientDeviceType: InviteDeviceType;
    translate: ITranslate;
}

export interface InviteStepProps<ctrlType = IInviteStepController> {
    ctrl: ctrlType;
}

export class InviteGenericStep extends BasicWizardStep implements IInviteStepController {
    private readonly _stepType: InviteStepsEnum;

    protected readonly flowManager: IInviteFlowManager;

    protected readonly layoutController: IMainLayoutController;

    protected readonly domUtilsService: IDomUtilsService;

    private readonly _translate: ITranslate;

    public isLeftBarVisible: boolean;

    constructor(
        flowManager: IInviteFlowManager,
        mainLayoutController: IMainLayoutController,
        domUtilsService: IDomUtilsService,
        stepParams: InviteStepParams
    ) {
        const ContentComponent = () => React.createElement(stepParams.content, {ctrl: this});

        super('', ContentComponent, stepParams.toolbar, stepParams.className);

        this.flowManager = flowManager;
        this.layoutController = mainLayoutController;
        this.domUtilsService = domUtilsService;
        this._stepType = stepParams.stepType;

        this.isLeftBarVisible = stepParams.isLeftBarVisible || false;

        this._translate = flowManager.translate.bind(flowManager);
    }

    setPagePlacement(placeName: string) {
        this.domUtilsService.setPagePlacement(placeName);
    }

    @computed
    get desktopShowURL() {
        return this.flowManager.desktopShowURL;
    }

    @computed
    get clientDeviceType(): InviteDeviceType {
        if (!this.flowManager.inviteState.clientMobileType) {
            return InviteDeviceType.common;
        }

        return /iPad|iPhone|iPod/.test(this.flowManager.inviteState.clientMobileType)
            ? InviteDeviceType.ios
            : InviteDeviceType.android;
    }

    @computed
    get isDesktopSharing(): boolean {
        return this.flowManager.inviteState.sessionInfo.isDesktopSharing;
    }

    @computed
    get clientBrowser(): string {
        return this.flowManager.inviteState.clientMobileBrowser;
    }

    get stepType(): InviteStepsEnum {
        return this._stepType;
    }

    get translate() {
        return this._translate;
    }

    didMount() {
        this.isLeftBarVisible ? this.layoutController.showLeftBar() : this.layoutController.hideLeftBar();
        this.flowManager.setDisplayedStep(this._stepType);
    }
}
