import {inject, IReactComponent} from 'mobx-react';
import * as component from './component';

const mapStoresToProps = (stores: any) => ({
    translate: stores.localizationService.translate.bind(stores.localizationService)
});

const RightSide = inject(mapStoresToProps)(component.RightSide) as IReactComponent;

export {RightSide};
export default RightSide;
