'use strict';

import isEqual from 'lodash/isEqual';

function linkFn(scope, element, attrs, ctrl) {
    const [model] = ctrl;

    model.$validators.pattern = (modelValue) => isEqual(modelValue, scope.compareTo);

    scope.$watch('compareTo', model.$validate.bind(model));
}

export function compareToDirective() {
    return {
        restrict: 'A',
        scope: {
            ngModel: '=',
            compareTo: '='
        },
        require: ['ngModel'],
        link: linkFn
    };
}
