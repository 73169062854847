'use strict';

import offlinePoolView from './offline-pool.view.html';
import {OfflinePoolController} from './offline-pool.controller.js';
import './offline-pool.style.scss';

function config($stateProvider) {
    $stateProvider.state('invite.old.offlinePool', {
        url: '^' + ROUTE_BASE_PATH + 'offline-pool',
        template: offlinePoolView,
        controller: 'OfflinePoolController',
        controllerAs: 'offlinePool'
    });
}

export default angular
    .module('states.invite.offlinePool', [])
    .config(config)
    .controller('OfflinePoolController', OfflinePoolController);
