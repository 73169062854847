import {ITranslate} from '../../services/LocalizationService';
import {action, computed, observable} from 'mobx';
import {Nullable} from '@techsee/techsee-common';

export interface IRemoteControlPopupController {
    readonly translate: ITranslate;
    readonly toggleRequestControl: () => void;
    isRemoteControlButtonDisabled: boolean;
    isInControl: boolean;
    isStopControlModalVisible: boolean;
    setInControl: (val: boolean) => void;
    setStopControlModalVisible: (value: boolean) => void;
    onStopRemoteControl: () => void;
}

export class RemoteControlPopupController implements IRemoteControlPopupController {
    private readonly _translate: ITranslate;
    private _toggleRequestControl: Nullable<() => void> = null;
    private _isStopControlModalVisible: boolean = false;
    @observable private _isInControl: boolean = false;
    @observable private _isRemoteControlButtonDisabled: boolean = false;

    constructor(translate: ITranslate) {
        this._translate = translate;
        this.setInControl = this.setInControl.bind(this);
        this.setToggleRequestRemoteControlCallback = this.setToggleRequestRemoteControlCallback.bind(this);
    }

    @computed
    get isStopControlModalVisible() {
        return this._isStopControlModalVisible;
    }

    @action
    setStopControlModalVisible(value: boolean) {
        this._isStopControlModalVisible = value;
    }

    @action
    onStopRemoteControl() {
        this.setStopControlModalVisible(false);
        this.toggleRequestControl();
    }

    get translate() {
        return this._translate;
    }

    @action
    public setInControl(value: boolean) {
        this._isInControl = value;
    }

    @action
    public setRemoteControlButtonDisabled(value: boolean) {
        this._isRemoteControlButtonDisabled = value;
    }

    @computed
    public get isRemoteControlButtonDisabled() {
        return this._isRemoteControlButtonDisabled;
    }

    @action
    public toggleRequestControl = () => {
        if (this._toggleRequestControl) {
            return this._toggleRequestControl();
        }

        throw new Error('request control CB was not provided');
    };

    @computed
    public get isInControl() {
        return this._isInControl;
    }

    @action
    public setToggleRequestRemoteControlCallback(toggleRequestRemoteControl: () => void) {
        this._toggleRequestControl = toggleRequestRemoteControl;
    }
}
