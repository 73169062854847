import {IDbSso} from './AngularServices';

export class DbSso implements IDbSso {
    constructor(db: any) {
        Object.defineProperty(this, '_dbService', {
            value: db,
            enumerable: false
        });
    }

    dashboardInviteViewLoaded(ssoAuthId: string): Promise<any> {
        return (this as any)._dbService.SSO.dashboardInviteViewLoaded(ssoAuthId);
    }
}
