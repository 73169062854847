import {ITranslate} from '../../../../_react_/services/LocalizationService';
import {IDomUtilsService} from '@techsee/techsee-client-infra/lib/services/DomUtilsService';
import {IMeasureService} from '../../../../services/ts-measure/ts-measure.service';
import {observable, action, computed} from 'mobx';
import {ISpeedtestService, ISpeedtestResults} from '../../../../_react_/services/SpeedtestService';

export interface IGuidancePopoversController {
    readonly translate: ITranslate;
    readonly measurementStep: number;
    readonly measurementObject: string;
    readonly speedtestResults: ISpeedtestResults;
    readonly showSpeedtestResults: boolean;

    loadImage(img: string): string;
    updatePopoverTitle(title: string): void;
    onUpdatePopoverContent(): void;
    speedtestPopoverActivate(): void;
}

export class GuidancePopoversController implements IGuidancePopoversController {
    @observable measurementStep: number;

    @observable measurementObject: string;

    @observable
    private _speedtestResults?: ISpeedtestResults;

    constructor(
        public translate: ITranslate,
        private domUtilsService: IDomUtilsService,
        private measureService: IMeasureService,
        private speedtestService: ISpeedtestService,
        private _mobileSpeedtestOoklaPremium: boolean,
        private _mobileSpeedtestDefaultProvider: boolean
    ) {
        this.measurementStep = measureService.processStep;
        this.measurementObject = measureService.measureObject;

        this.measureService.onMeasureObjectChanged((obj) => {
            this.setMeasurementObject(obj.name);
        });

        this.measureService.onStepChanged((step) => {
            this.setMeasurementStep(step);

            this.updatePopoverTitle(
                translate('REACT.FLOATING_TOOLBAR.STEP', {
                    current: step + 1,
                    all: 2
                })
            );

            this.onUpdatePopoverContent();
        });

        if (this.showSpeedtestResults) {
            this.speedtestService.onResultsProcessed((results: ISpeedtestResults) => {
                this.setSpeedtestResults(results);
                this.speedtestPopoverActivate();
            });
        }
    }

    loadImage(img: string) {
        return this.domUtilsService.loadImage(img);
    }

    @action
    setMeasurementStep(step: number) {
        this.measurementStep = step;
    }

    @action
    setMeasurementObject(name: string) {
        this.measurementObject = name;
    }

    @computed
    get speedtestResults(): any {
        return this._speedtestResults;
    }

    @computed
    get showSpeedtestResults(): any {
        return this._mobileSpeedtestOoklaPremium || this._mobileSpeedtestDefaultProvider;
    }

    @action
    setSpeedtestResults(speedtestResults: ISpeedtestResults) {
        this._speedtestResults = speedtestResults;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updatePopoverTitle(title: string): void {}

    onUpdatePopoverContent(): void {}

    speedtestPopoverActivate(): void {}
}
