import {ITranslate} from '../../services/LocalizationService';
import {action, computed, observable} from 'mobx';
import {MeetingMode, PhotoStreamReasonKeys} from '@techsee/techsee-common/lib/constants/room.constants';
import {IDomUtilsService} from '@techsee/techsee-client-infra/lib/services/DomUtilsService';

export interface IFallbackModeController {
    readonly translate: ITranslate;
    readonly isVisible: boolean;
    readonly reason: PhotoStreamReasonKeys;
    readonly displayMode: string;

    loadImage(img: string): string;
    show(mode: MeetingMode, reason: PhotoStreamReasonKeys): void;
    hide(): void;
}

export class FallbackModeController implements IFallbackModeController {
    private readonly _translate: ITranslate;

    private _domUtilsService: IDomUtilsService;

    @observable private _isVisible: boolean;

    @observable private _displayMode: string = '';

    @observable private _reason: PhotoStreamReasonKeys | undefined = undefined;

    constructor(translate: ITranslate, domUtilsService: IDomUtilsService) {
        this._translate = translate;
        this._isVisible = false;
        this._domUtilsService = domUtilsService;

        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
    }

    @action
    show(mode: MeetingMode, reason: PhotoStreamReasonKeys) {
        this._reason = reason;
        this._isVisible = true;
        this._displayMode = this._translate('REACT.PHOTO_STREAM_REASON.VIEW.' + mode);
    }

    @action
    hide() {
        this._isVisible = false;
    }

    @computed
    get isVisible() {
        return this._isVisible;
    }

    @computed
    get displayMode() {
        return this._displayMode;
    }

    @computed
    get reason() {
        return this._reason!;
    }

    get translate() {
        return this._translate;
    }

    loadImage(img: string) {
        return this._domUtilsService.loadImage(img);
    }
}
