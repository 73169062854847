import {SessionStateBase} from '../../../models/LiveSessionState';
import {observable} from 'mobx';

export class DashboardState extends SessionStateBase {
    @observable isOfflineRoom: boolean = false;

    @observable clientOs: string = '';

    @observable isObserve: boolean = false;

    @observable isOfflineMode: boolean = false;
}
