'use strict';

import isUndefined from 'lodash/isUndefined';
import includes from 'lodash/includes';
import filter from 'lodash/filter';

import tsImageEditorView from './ts-image-editor.view.html';
import {MAX_TAG_LENGTH} from './ts-image-editor.settings.js';
import './ts-image-editor.style.scss';
import ResizeSensor from 'css-element-queries/src/ResizeSensor';
import {MeetingMode} from '@techsee/techsee-common/lib/constants/room.constants';
import {getRootStore} from '../../_react_/app.bootstrap';

/**
 * A canvas that watches an image URL, to use as its background,
 * and allows for some editing on top of it.
 *
 * Attributes:
 *
 *  image-model: Model for the image URL
 */
class TsImageEditorController {
    // eslint-disable-next-line max-params
    constructor(
        $scope,
        $rootScope,
        tsCanvasAnnotate,
        $q,
        $window,
        mediaFrameUIParams,
        tsInterfaceHelper,
        tsScanArea,
        tsChatApi,
        $timeout
    ) {
        'ngInject';

        this.$scope = $scope;
        this.chatApi = tsChatApi.service;
        this.annotateService = tsCanvasAnnotate;
        this.BASE_PATH = BASE_PATH;
        this.maxTagLength = MAX_TAG_LENGTH;
        this.$q = $q;
        this.$window = $window;
        this.tagWhiteSrc = $rootScope.requireImage('tag-white.png');
        this.mediaFrameUIParams = mediaFrameUIParams;
        this.environmentDetect = getRootStore().environmentService;
        this.browserUtilsService = getRootStore().browserUtilsService;
        this.displayTabletAsDesktop = getRootStore().displayTabletAsDesktop;
        this.tsInterfaceHelper = tsInterfaceHelper;
        this.tsScanArea = tsScanArea;

        if (isUndefined(this.showAnnotationBar)) {
            this.showAnnotationBar = true;
        }

        this.tags = this.annotateService.getTags();

        this._resizeHandler = () => this.annotateService.resizeAnnotationCanvas();
    }

    get showScanningTitle() {
        return (
            this.imageMode === this.imageModes.SCANNING &&
            this.isScanCaptureDesign &&
            !(this.isRoundedFloatingVideo && this.isStretchedVideoMode) &&
            includes([MeetingMode.oneClick, MeetingMode.images], this.mainMode)
        );
    }

    /* Start editing a new image
     *
     * @param image {Object { url: String }} new image to use as a background
     */
    setImage(image) {
        this.image = image;
        this.annotateService.setBackgroundImage(image.url);

        if (this.enableTagging) {
            this.tags = image.tags ? image.tags.slice() : [];

            this.annotateService.setTags(this.tags);
            this.tagsOpen = false;
        }
    }

    /* Connect the current canvas to the fabric.js engine
     *
     * @param canvas {DOMElement} canvas to edit
     * @param options {Object} extra options for canvas initialization
     */
    setCanvas(canvas, options) {
        this.annotateService.setCanvas(canvas, options);
    }

    outOfBounds(state) {
        this.annotateService.setOutOfBounds(state);
    }

    /* Allows to turn off annotation tools. This featue disabled by default.
     *
     */
    readonly(flag) {
        this.annotateService.readonly = flag;
    }

    addPresetTag(tag) {
        if (!this.tags || this.tags.length === 0) {
            this.tags = [tag];
        } else if (this.tags.indexOf(tag) < 0) {
            this.tags = this.tags.concat(tag);
        }
    }

    closeTags() {
        this.tagsOpen = false;
    }

    openTagsPanel(e) {
        this.tagsOpen = true;
        this.tags = this.annotateService.getTags();
        e.stopPropagation();
    }

    getPresetTags($query) {
        const regex = new RegExp(`${$query}`, 'i');
        const results = filter(this.presetTags, (tag) => tag.text.match(regex));

        return this.$q.when(results);
    }

    init(element) {
        this._initMainContainerSizeSensor();

        if (this.disablePadding) {
            this.annotateService.removePadding();
        }

        this.element = element;
    }

    destroy() {
        this._disposeMainContainerSizeSensor();
        this.destroyScanMode();
    }

    initScanMode() {
        this.annotateService.setTool(null);
        this.tsScanArea.init(this.annotateService.canvas.upperCanvasEl);
    }

    destroyScanMode() {
        this.tsScanArea.destroy();
    }

    _initMainContainerSizeSensor() {
        if (!this._mainContainerSizeSensor) {
            const mainContainerElement = $('.dashboard-main-image-container');

            this._mainContainerSizeSensor = new ResizeSensor(mainContainerElement, () => {
                this._resizeHandler();
            });
        }
    }

    _disposeMainContainerSizeSensor() {
        if (this._mainContainerSizeSensor && this._mainContainerSizeSensor.reset) {
            ResizeSensor.detach($('.dashboard-main-image-container'));
            this._mainContainerSizeSensor = null;
        }
    }
}

function linkFn(scope, element, attrs, ctrl) {
    const canvas = element.find('canvas')[0];
    const tags = element.find('.tags-view-container');

    ctrl.init(element);

    // The size of canvas container will define
    // the maximum size for the canvas

    // As soon as we get the element, we pass it over to fabric, along with
    // some default options that we want for it.
    if (ctrl.environmentDetect.isMobile(this.displayTabletAsDesktop) || ctrl.disablePadding) {
        const maxWidth = element.width();
        const maxHeight = element.height();

        ctrl.setCanvas(canvas, {
            width: maxWidth,
            height: maxHeight,
            maxWidth: maxWidth,
            maxHeight: maxHeight,
            allowedRatios: ctrl.mediaFrameUIParams.ALLOWED_RATIOS
        });
    } else {
        const widthImagePadding = ctrl.newUi
            ? ctrl.mediaFrameUIParams.WIDTH_PADDING_IMAGES
            : ctrl.mediaFrameUIParams.WIDTH_PADDING_IMAGES_OLD_UI;

        ctrl.setCanvas(canvas, {
            width: ctrl.$window.innerWidth - widthImagePadding,
            height: ctrl.$window.innerHeight - ctrl.tsInterfaceHelper.mediaFrameHeightPadding,
            maxWidth: ctrl.$window.innerWidth - widthImagePadding,
            maxHeight: ctrl.$window.innerHeight - ctrl.tsInterfaceHelper.mediaFrameHeightPadding,
            allowedRatios: ctrl.mediaFrameUIParams.ALLOWED_RATIOS
        });
    }

    // eslint-disable-next-line no-redeclare
    /* globals Hamster */
    const scrollBlock = new Hamster(tags[0]);

    tags.data('hamster', scrollBlock);

    scrollBlock.wheel((e) => {
        // prevent scrolling in the tags area from changing image zoom
        e.stopPropagation();
    });

    // Every time a new image is selected, we set the
    // image background.
    scope.$watch(
        () => ctrl.model,
        (resource) => {
            if (resource && !resource.isVideo && resource.url) {
                ctrl.setImage(resource);
            }
        }
    );

    scope.$watch(
        () => ctrl.enableAnnotation,
        (enabled) => ctrl.readonly(!enabled)
    );

    scope.$watch(
        () => ctrl.imageMode,
        () => {
            const {SCANNING} = ctrl.imageModes;

            ctrl.isSelectionMode = ctrl.imageMode === SCANNING;

            if (ctrl.imageMode === SCANNING && ctrl.isScanCaptureDesign) {
                ctrl.initScanMode(canvas);
            }

            if (ctrl.imageMode !== SCANNING && ctrl.isScanCaptureDesign && ctrl.isCanvas) {
                ctrl.destroyScanMode();
            }
        }
    );

    scope.$on('$destroy', () => ctrl.destroy());
}

export function tsImageEditorDirective() {
    return {
        template: tsImageEditorView,
        replace: true,
        restrict: 'E',
        transclude: {
            'share-modes': '?tsSharingMode'
        },
        scope: {},
        bindToController: {
            model: '=imageModel',
            enableAnnotation: '=',
            showAnnotationBar: '=',
            enableTagging: '=',
            presetTags: '=',
            dashboardTags: '=',
            imageMode: '=',
            imageModes: '=',
            labelMode: '=',
            cancelScanning: '&',
            isScanCaptureDesign: '=',
            isRoundedFloatingVideo: '=',
            isStretchedVideoMode: '=',
            allowPauseByAgent: '=',
            mainMode: '=',
            recognizeLoader: '=',
            spinnerOptions: '=',
            newUi: '=',
            isCanvas: '=',
            disablePadding: '='
        },
        controller: TsImageEditorController,
        controllerAs: 'vm',
        link: linkFn
    };
}
