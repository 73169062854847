import React from 'react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {SimpleLabel} from '@techsee/techsee-ui-common/lib/forms/labels/simple';
import {GuidenceProgressItem} from '../guidance-progress/GuidanceProgress';

import './styles.scss';
import {
    BorderRoundedIcon,
    IconAnimation,
    IconAnimations,
    IconColor,
    IconSize,
    IconSizes
} from '@techsee/techsee-ui-common/lib/icons/large-rounded-icon';

export interface ProgressListProps {
    progressList: GuidenceProgressItem[];
}

export class ProgressList extends TechseeBaseComponent<ProgressListProps> {
    renderInternal() {
        const {progressList} = this.props;

        return (
            <div className={'progress-list'}>
                {progressList.map((item: GuidenceProgressItem, index: number) => {
                    const itemClasses = 'progress-list-item' + (item.isActive ? ' active' : '');

                    return (
                        <div className={itemClasses} key={index}>
                            <BorderRoundedIcon
                                iconName={item.icon}
                                colorName={item.iconColor as IconColor}
                                sizeName={item.iconSize || (IconSizes.Medium as IconSize)}
                                noBorder={item.iconNoBorder}
                                animation={
                                    (item.isIconAnimated ? IconAnimations.Rotate : IconAnimations.None) as IconAnimation
                                }
                            />
                            <SimpleLabel className={item.icon === 'screen' ? 'change-padding' : ''}>
                                {item.label}
                            </SimpleLabel>
                        </div>
                    );
                })}
            </div>
        );
    }
}
