import {action, runInAction} from 'mobx';
import last from 'lodash/last';
import get from 'lodash/get';
import {ISmsService} from '../../services/SmsService';
import {EVENT_TYPES, ISmsData} from '../../../services/ts-sms/ts-sms.settings';
import {SendSmsStatus} from './_contracts/SendSmsStatus';
import {IInviteStateManager} from './invite.persister';
import {AccountSettings} from '../../models/AccountSettings';
import {getRootStore} from '../../app.bootstrap';
import {IJsApiService, MessagesStatus, MessageType} from '../../services/JsApiService';
import {ITranslate} from '../../services/LocalizationService';

function extractSmsSendingError(lastMessage: SendSmsStatus | undefined, translate: ITranslate): string {
    const errorMessage =
        get(lastMessage, 'messageDetails.customError') || get(lastMessage, 'messageDetails.smsError.Message') || '';

    return translate(errorMessage);
}

export class InviteSmsFlow {
    private _stateManager: IInviteStateManager;

    private _jsApiService: IJsApiService;

    private _smsService: ISmsService;

    private _accountSettings: AccountSettings;

    private _translate: ITranslate;

    private isCanceled: boolean = false;

    constructor(
        stateManager: IInviteStateManager,
        smsService: ISmsService,
        settings: AccountSettings,
        translate: ITranslate
    ) {
        if (!stateManager.isReady) {
            throw new Error('StateManager should be ready before passing it to this class.');
        }

        this._stateManager = stateManager;
        this._smsService = smsService;
        this._accountSettings = settings;
        this._translate = translate;
        this._jsApiService = getRootStore().jsApiService;
    }

    async sendSms(): Promise<void> {
        const smsData = this.getSendSmsParams();
        const msgParams = {
            type: MessageType.smsSent,
            techseeSessionId: this._stateManager.inviteState.sessionInfo.sessionId,
            customerNumber: this._stateManager.inviteState.inviteMethodInfo.address,
            customerId: this._stateManager.inviteState.inviteMethodInfo.customerRef,
            sessionType: this._stateManager.inviteState.sessionInfo.meetingMode
        };

        return new Promise((resolve, reject) => {
            this._smsService
                .sendSms(smsData, this.handleSmsProgressEvent.bind(this))
                .then((sendSmsResult) => {
                    if (this.isCanceled) {
                        return;
                    }

                    if (sendSmsResult.success) {
                        const msg = {...msgParams, status: MessagesStatus.Success};

                        this._jsApiService.SMSSent(msg);

                        resolve();
                    } else {
                        const lastMessage = last(this.sendSmsProgress);
                        const msg = {
                            ...msgParams,
                            error: extractSmsSendingError(lastMessage, this._translate),
                            status: MessagesStatus.Failed
                        };

                        this._jsApiService.SMSSent(msg);

                        reject();
                    }
                })
                .catch(() => {
                    if (!this.isCanceled) {
                        const lastMessage = last(this.sendSmsProgress);
                        const msg = {
                            ...msgParams,
                            error: extractSmsSendingError(lastMessage, this._translate),
                            status: MessagesStatus.Failed
                        };

                        this._jsApiService.SMSSent(msg);

                        reject();
                    }
                });
        });
    }

    cancel() {
        this.isCanceled = true;
        this._smsService.abortSendSms();
    }

    @action
    private handleSmsProgressEvent(message: any): void {
        if (this.isCanceled || !message || !message.type) {
            return;
        }

        if (
            message.type === EVENT_TYPES.ERROR &&
            this.sendSmsProgress.length > 0 &&
            this.sendSmsProgress[this.sendSmsProgress.length - 1].name === EVENT_TYPES.ERROR
        ) {
            this.sendSmsProgress.splice(
                this.sendSmsProgress.length - 1,
                1,
                new SendSmsStatus(message.type, true, message.data)
            );

            return;
        }

        if (
            message.type === EVENT_TYPES.CLIENT_RECEIVED &&
            this.sendSmsProgress.length > 0 &&
            this.sendSmsProgress[this.sendSmsProgress.length - 1].name === EVENT_TYPES.WAITING_FOR_CLIENT
        ) {
            return;
        }

        if (message.type !== EVENT_TYPES.UNKNOWN) {
            runInAction(() => {
                if (this.sendSmsProgress.length > 0) {
                    this.sendSmsProgress[this.sendSmsProgress.length - 1].isActive = false;
                }

                this.sendSmsProgress.push(new SendSmsStatus(message.type, true, message.data));

                if (message.type === EVENT_TYPES.SMS_DELIVERED) {
                    this.sendSmsProgress[this.sendSmsProgress.length - 1].isActive = false;
                    this.sendSmsProgress.push(new SendSmsStatus(EVENT_TYPES.WAITING_FOR_CLIENT, true));
                }
            });
        }
    }

    private getSendSmsParams(): ISmsData {
        const countryCode = this._stateManager.inviteState.inviteMethodInfo.countryCode
            ? this._stateManager.inviteState.inviteMethodInfo.countryCode.replace(/[^0-9]/g, '')
            : '';

        const smsData: ISmsData = {
            phone: this._stateManager.inviteState.inviteMethodInfo.phoneNumber || '',
            roomId: this._stateManager.inviteState.sessionInfo.sessionRoomId,
            link: this._stateManager.inviteState.sessionInfo.sessionLink,
            chromeDetection: this._accountSettings.sessionSettings.chromeDetection,
            cameraModeOnly: this._accountSettings.sessionSettings.cameraModeOnly,
            offline: this._stateManager.inviteState.sessionInfo.isOffline,
            showSmsPreview: true,
            countryCode: countryCode,
            startWithAgentType: this._stateManager.inviteState.sessionInfo.meetingMode,
            smsUseNumberLookup: false,
            audio: this._stateManager.inviteState.inviteMethodInfo.enableAudio
        };

        return smsData;
    }

    private get sendSmsProgress(): SendSmsStatus[] {
        return this._stateManager.inviteState.smsFlowProgress;
    }
}
