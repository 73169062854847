'use strict';

import {getRootStore} from '../../_react_/app.bootstrap';
import {getAppTracer} from '../../app.tracer';

import {postMessageMethod} from '@techsee/techsee-common/lib/constants/account.constants';

const trace = getAppTracer('EmbeddedDashboardGeneralErrorController');

export class EmbeddedDashboardGeneralErrorController {
    constructor() {
        trace.error('Got to embedded dashboard general error page');

        const jsApiService = getRootStore().jsApiService;

        jsApiService.init(null, {
            enabled: true,
            method: postMessageMethod.IFrame,
            SSOStatusCheck: true,
            dashboardErrorPageLoadedCheck: true
        });

        jsApiService.dashboardErrorPageLoaded();
    }
}
