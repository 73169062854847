export function base64ToBlob(data: string): any {
    const byteString = atob(data);

    return new Blob(
        [
            byteString.split('').reduce(
                (buffer, char, index) => {
                    buffer[index] = char.charCodeAt(0);

                    return buffer;
                },
                new Uint8Array(new ArrayBuffer(byteString.length))
            )
        ],
        {type: 'image/png'}
    );
}
