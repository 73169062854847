import {IDbResource} from './AngularServices';

export class DbResourceAdapter implements IDbResource {
    constructor(db: any) {
        Object.defineProperty(this, '_dbService', {
            value: db,
            enumerable: false
        });
    }

    default(accountId: string): Promise<any> {
        return (this as any)._dbService.Resource.default(accountId);
    }
}
