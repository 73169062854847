import React from 'react';
import {IReactComponent, observer} from 'mobx-react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';

import './style.scss';

interface IMainTopBarProps {
    accountLogo: string;
    topBarComponent: IReactComponent;
}

@observer
export class MainTopBar extends TechseeBaseComponent<IMainTopBarProps> {
    renderInternal() {
        const TopBarComponent = this.props.topBarComponent;

        return (
            <div className='app-top-bar use-common-styles'>
                <div className='section logo' style={{backgroundImage: `url(${this.props.accountLogo})`}} />
                <TopBarComponent />
            </div>
        );
    }
}

export default MainTopBar;
