import React from 'react';
import {ConfirmationModal} from '@techsee/techsee-ui-common/lib/modals/confirmation-modal';
import {ILookupNumberController} from './controller';
import {observer} from 'mobx-react';

export interface ILookupNumberProps {
    ctrl: ILookupNumberController;
}

@observer
export class LookupNumber extends React.Component<ILookupNumberProps> {
    constructor(props: ILookupNumberProps) {
        super(props);

        this.rednerContent = this.rednerContent.bind(this);
    }

    rednerContent() {
        const {translate, enteredPhone, validatedPhone} = this.props.ctrl;

        return (
            <div
                className='lookup-number-modal__content'
                dangerouslySetInnerHTML={{
                    __html: translate('REACT.INVITE.LOOKUP_VALIDATION.TEXT', {enteredPhone, validatedPhone})
                }}
            />
        );
    }

    render() {
        const {translate, showModal, accept, cancel} = this.props.ctrl;

        return (
            <ConfirmationModal
                className='lookup-number-modal'
                header={translate('REACT.INVITE.LOOKUP_VALIDATION.TITLE')}
                iconName='mobile'
                content={this.rednerContent}
                show={showModal}
                positiveText={translate('REACT.INVITE.LOOKUP_VALIDATION.ACCEPT')}
                negativeText={translate('REACT.INVITE.LOOKUP_VALIDATION.CANCEL')}
                onPositiveClick={accept}
                onNegativeClick={cancel}
            />
        );
    }
}
