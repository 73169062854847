import {IDbRooms} from '../../../../../services/AngularServices/AngularServices';
import {AppState} from '../../../../../app.state';
// @ts-ignore
import {findGuidInUrl} from '@techsee/techsee-common/lib/utils';
import {SESSION_SOURCE, UserType} from '@techsee/techsee-common/lib/constants/room.constants';
import {observable, action, computed} from 'mobx';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import includes from 'lodash/includes';
import filter from 'lodash/filter';
import some from 'lodash/some';
import moment from 'moment';
import {ITranslate} from '../../../../../services/LocalizationService';
import {IInviteFlowManager} from '../../../_contracts/InviteContracts';
import {PlatformType} from '@techsee/techsee-common/lib/constants/utils.constant';
import {GeneralSettings, InviteMethodSettings} from '../../../../../models/AccountSettings';
import {IAccountSocketService} from '../../../../../services/AccountSocket/AccountSocketService';
import {IGroup} from '../../../../../services/AccountSocket/AccountSocketServiceContracts';
import {ServiceEvents} from '../../../../../services/AccountSocket/AccountSocketEvents';

export interface IRoom {
    _id: string;
    customerId: string;
    customerNumber: string;
    guids: string[];
    sentURL: string;
    sessionCode: string;
    creationTimestamp: string;
    connectedTimestamp: string;
    lastAgentAction: string;
    lastClientAction: string;
    lastUpdate: string;
    clientType: PlatformType;
    sessionSource: SESSION_SOURCE;
    groups: IGroup[];
}

export interface IOfflineSession {
    room: IRoom;
    lastActionBy: UserType;
    createdBy: string;
    occupied: boolean;
}

export interface IOfflineSessionsController {
    readonly offlineSessions: IOfflineSession[];
    readonly selectedSession: IOfflineSession;
    readonly translate: ITranslate;
    readonly isJoinAvailable: boolean;

    isSessionSelected(session: IOfflineSession): boolean;
    selectSession(session: IOfflineSession): void;
    onJoin(callback: (sessionId: string) => void): void;
}

export class OfflineSessionsController implements IOfflineSessionsController {
    private _selectedGroupsForOfflineAlerts: string[];

    @observable private _offlineSessions: IOfflineSession[] = [];

    @observable private _selectedSession: IOfflineSession = {room: {}} as any as IOfflineSession;

    constructor(
        private _flowManager: IInviteFlowManager,
        public translate: ITranslate,
        private _dbRoomService: IDbRooms,
        private _appState: AppState,
        private _accSettings: GeneralSettings,
        private _inviteSettings: InviteMethodSettings,
        private _currentUser: any,
        private _accountSocketService: IAccountSocketService
    ) {
        this.isSessionSelected = this.isSessionSelected.bind(this);
        this.selectSession = this.selectSession.bind(this);
        this.onJoin = this.onJoin.bind(this);

        this._selectedGroupsForOfflineAlerts = this._inviteSettings
            ? this._inviteSettings.selectedGroupsForOfflineAlerts
            : [];
        const userGroups = this._currentUser.groups || [];

        const isUserPermittedToGetOfflineAlerts =
            this._selectedGroupsForOfflineAlerts.length && this._inviteSettings.showAlertsToSelectedGroups
                ? this._selectedGroupsForOfflineAlerts.some((group) => userGroups.includes(group))
                : true;

        if (isUserPermittedToGetOfflineAlerts) {
            this._accountSocketService.on(ServiceEvents.OFFLINE_DATA, (data: any) => {
                this.updateOfflineSessions(data);
            });

            this.updateOfflineSessions(this._accountSocketService.pendingRooms);
        }
    }

    @computed
    get offlineSessions() {
        const sessions = this._offlineSessions.map((session) => ({
            ...session,
            room: {
                ...session.room,
                sessionCode: this.getSessionCode(session.room),
                creationTimestamp: this.dateFormat(session.room.creationTimestamp),
                connectedTimestamp: this.dateFormat(session.room.connectedTimestamp),
                lastUpdate: this.dateFormat(
                    session.lastActionBy === UserType.dashboard
                        ? session.room.lastAgentAction
                        : session.room.lastClientAction
                )
            }
        }));

        if (this._inviteSettings.showAlertsToSelectedGroups && this._selectedGroupsForOfflineAlerts) {
            sessions.filter((roomData: IOfflineSession) =>
                some(roomData.room.groups, (group: IGroup) => includes(this._selectedGroupsForOfflineAlerts, group._id))
            );
        }

        return orderBy(this._filterSessionsByActiveSessions(sessions), ({room}) => room.lastUpdate, 'desc');
    }

    @computed
    get isJoinAvailable() {
        return !isEmpty(this._selectedSession.room);
    }

    @computed
    get selectedSession() {
        return this._selectedSession;
    }

    isSessionSelected(session: IOfflineSession) {
        return this._selectedSession.room._id === session.room._id;
    }

    @action
    selectSession(session: IOfflineSession) {
        if (session.occupied) {
            return;
        }

        this._selectedSession = session;
    }

    @action
    setOfflineSessions(sessions: IOfflineSession[]) {
        this._offlineSessions = sessions;
    }

    @action
    onJoin(callback: (sessionId: string) => void) {
        callback(this._selectedSession.room.sessionCode);
    }

    @action
    private updateOfflineSessions(data: IOfflineSession[]) {
        this.setOfflineSessions(data);
        this.updateOfflineRoomCount(data);
    }

    private getSessionCode(room: IRoom) {
        const code = isEmpty(room.guids) ? findGuidInUrl(room.sentURL) : room.guids[0];

        if (room.clientType === PlatformType.dynamicAppClient) {
            return code.substr(this._accSettings.guidPrefix.length);
        }

        return code;
    }

    private dateFormat(date: string) {
        return moment(date).format('DD/MM/YYYY HH:mm:ss');
    }

    @computed
    private get offlineSessionsCount() {
        return this._appState.offlineRoomCount;
    }

    private updateOfflineRoomCount(offlineSessions: IOfflineSession[]) {
        let relevantOfflineRooms = offlineSessions;

        if (this._inviteSettings.showAlertsToSelectedGroups && this._selectedGroupsForOfflineAlerts) {
            relevantOfflineRooms = offlineSessions.filter((roomData: IOfflineSession) =>
                some(roomData.room.groups, (group: IGroup) => includes(this._selectedGroupsForOfflineAlerts, group._id))
            );
        }

        this._appState.setOfflineRoomCount(this._filterSessionsByActiveSessions(relevantOfflineRooms).length);
    }

    private _filterSessionsByActiveSessions(offlineSessions: IOfflineSession[]) {
        return filter(offlineSessions, (session) =>
            includes([UserType.dashboard, UserType.client], session.lastActionBy)
        );
    }
}
