'use strict';
import tsRecognizeImageView from './ts-recognize-image.view.html';
import './ts-recognize-image.style.scss';

export function tsRecognizeImage() {
    return {
        template: tsRecognizeImageView,
        scope: true
    };
}
