'use strict';

export const TOOLKIT_DEFAULTS = {
    color: '#00ff00',
    strokeWidth: 5,
    triangleSize: 20,
    textColor: '#000000',
    fontSize: 18,
    textPadding: 3,
    lineHeight: 1.3,
    textBoxBackgroundColor: '#ffe39a',
    textBoxBorderColor: '#dcc07b',
    minTextBoxHeight: 30,
    minTextBoxWidth: 30,
    textBoxBorderWidth: 2,
    textBoxBorderRadius: 2,
    textBoxWidthIncrement: 10,
    pencilWidth: 5,
    numTagWidth: 32,
    numTagHeight: 32,
    numTagFontSize: 14,
    numTagBorderRadius: 100,
    numTagMaxLength: 2,
    textTagWidth: null,
    textTagHeight: 32,
    textTagFontSize: 12,
    textTagBorderRadius: 4,
    textTagPadding: 6,
    textTagMaxLength: 30,
    tagLineHeight: 1,
    tagBorderWidth: 2,
    maxHandDelta: 50
};
