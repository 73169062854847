import React from 'react';
import {GalleryMini} from '../../../gallery/GalleryMini';

import './GalleryBar.scss';

type MiniDashboardLayoutProps = {
    isMobileView: boolean;
};

export const GalleryBar: React.FC<MiniDashboardLayoutProps> = ({isMobileView}) => (
    <div className={`${isMobileView ? 'mini-dashboard-bottom-area' : 'mini-dashboard-right-area'}`}>
        <GalleryMini isMobileView={isMobileView} />
    </div>
);
