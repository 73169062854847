import React from 'react';
import {observer} from 'mobx-react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {GenericModal} from '@techsee/techsee-ui-common/lib/modals/generic';
import {ButtonsToolbar} from '@techsee/techsee-ui-common/lib/forms/buttons/toolbar';
import {SecondaryButton} from '@techsee/techsee-ui-common/lib/forms/buttons/secondary';
import {PrimaryButton} from '@techsee/techsee-ui-common/lib/forms/buttons/primary';
import {DefaultModalHeader} from '@techsee/techsee-ui-common/lib/modals/generic/default-modal-header';
import {SimpleLabel} from '@techsee/techsee-ui-common/lib/forms/labels/simple';
import {IConnectivityCheckModalController} from './connectivityCheckModal.controller';

import './connectivityCheckModal.scss';

interface IMetaEventLog {
    runFrom?: string;
    nextTime?: boolean;
}

interface ConnectivityModalProps {
    controller: IConnectivityCheckModalController;

    onRunTest(meta: IMetaEventLog, goTo?: boolean): void;
}

@observer
export class ConnectivityCheckModal extends TechseeBaseComponent<ConnectivityModalProps> {
    private onRunConnectivityTest(): void {
        this.props.onRunTest({runFrom: 'pop-up'}, true);
        this.props.controller.hide();
    }

    private onClose() {
        this.props.onRunTest({nextTime: true});
        this.props.controller.hide();
    }

    renderInternal(): JSX.Element {
        const Header = () => (
            <DefaultModalHeader>
                {this.props.controller.translate('REACT.INVITE.VIEW.CONNECTIVITY_TEST_HEADER')}
            </DefaultModalHeader>
        );
        const Modal = () => (
            <div className={'labels-container'}>
                <img src={'img/wifi-check.png'} />
                <SimpleLabel className={'main-label'}>
                    {this.props.controller.translate('REACT.INVITE.VIEW.CONECTIVITY_CHECK_MAIN_CONTENT')}
                </SimpleLabel>
                <SimpleLabel className={'second-label'}>
                    {this.props.controller.translate('REACT.INVITE.VIEW.CONECTIVITY_CHECK_CONTENT')}
                </SimpleLabel>
            </div>
        );

        const ToolBar = () => (
            <ButtonsToolbar>
                <SecondaryButton onClick={() => this.onClose()} className={'go-back'}>
                    {this.props.controller.translate('REACT.INVITE.VIEW.NEXT_TIME')}
                </SecondaryButton>
                <PrimaryButton onClick={() => this.onRunConnectivityTest()} className={'save-end-session'}>
                    {this.props.controller.translate('REACT.INVITE.VIEW.RUN_TEST')}
                </PrimaryButton>
            </ButtonsToolbar>
        );

        return (
            <div>
                <GenericModal
                    className={'connectivity-check-container'}
                    header={Header}
                    content={Modal}
                    toolbar={ToolBar}
                    onHideHandler={() => this.onClose()}
                    show={this.props.controller && this.props.controller.isConnectivityModalVisible}></GenericModal>
            </div>
        );
    }
}
