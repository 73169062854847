import React from 'react';
import {computed} from 'mobx';
import {IDomUtilsService} from '@techsee/techsee-client-infra/lib/services/DomUtilsService';
import {InviteGenericStep, InviteStepParams} from '../_InviteGenericStep';
import {IInviteFlowManager, InviteStepsEnum} from '../../../_contracts/InviteContracts';
import {IMainLayoutController} from '../../../../../layouts/main-layout/layout.controller';
import {TermsStepContent} from './content';
import {TermsStepToolbar} from './toolbar';

import './styles.scss';

export class TermsStepController extends InviteGenericStep {
    constructor(
        flowManager: IInviteFlowManager,
        mainLayoutController: IMainLayoutController,
        domUtilsService: IDomUtilsService
    ) {
        const params: InviteStepParams = {
            stepType: InviteStepsEnum.TermsApproval,
            content: () => React.createElement(TermsStepContent, {ctrl: this}),
            toolbar: () => React.createElement(TermsStepToolbar, {ctrl: this}),
            className: 'terms-step'
        };

        super(flowManager, mainLayoutController, domUtilsService, params);

        this.repromptForTos = this.repromptForTos.bind(this);
    }

    @computed
    get displayRepromptControls(): boolean {
        return this.flowManager.inviteState.isTosRejected && !this.flowManager.inviteState.isTosRepromptSent;
    }

    repromptForTos() {
        this.flowManager.repromptClientForTos();
    }
}
