'use strict';
import tsNewScanView from './ts-new-scan.view.html';
import './ts-new-scan.style.scss';

export function tsNewScan() {
    return {
        template: tsNewScanView,
        scope: true
    };
}
