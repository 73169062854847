import React from 'react';
import {inject, IReactComponent} from 'mobx-react';
import './CustomerEndedTheMeeting.scss';

const mapStoresToProps = (stores: any) => ({
    translate: stores.localizationService.translate
});

type CustomerEndedTheMeetingProps = {
    translate: any;
};

const CustomerEndedTheMeetingComponent: React.FC<CustomerEndedTheMeetingProps> = ({translate}) => (
    <div className='customer-ended-the-meeting'>
        <div className='content'>
            <div className='customer-left-icon' />
            <header>{translate('REACT.MULTIPARTY_DASHBOARD.CUSTOMER_ENDED_THE_SESSION.TITLE')}</header>
            <p>{translate('REACT.MULTIPARTY_DASHBOARD.CUSTOMER_ENDED_THE_SESSION.CONTENT')}</p>
        </div>
    </div>
);

export const CustomerEndedTheMeeting = inject(mapStoresToProps)(CustomerEndedTheMeetingComponent) as IReactComponent;
