'use strict';
import EventEmitter from 'events';

/**
 * Helper service for responsive window changes (only orientation atm)
 */
export class TsResponsiveUtilsService extends EventEmitter {
    constructor($window) {
        'ngInject';

        super();

        this.$window = $window;

        this._initListeners();
    }

    _initListeners() {
        this.$window.addEventListener('orientationchange', () => {
            this.emit('orientationchange', this.getOrientation());
        });
    }

    getOrientation() {
        return this.width() > this.height() ? 'landscape' : 'portrait';
    }

    isPortrait() {
        return this.height() > this.width();
    }

    isLandscape() {
        return this.height() < this.width();
    }

    width() {
        return this.$window.document.body.clientWidth;
    }

    height() {
        return this.$window.document.body.clientHeight;
    }
}
