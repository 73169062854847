import {inject, IReactComponent} from 'mobx-react';
import {SessionAbort} from './component';

import './styles.scss';

const mapStoresToProps = (stores: any) => ({
    ctrl: stores.sessionAbortController,
    translate: stores.localizationService.translate.bind(stores.localizationService)
});

const SessionAbortComponent = inject(mapStoresToProps)(SessionAbort) as IReactComponent;

export default SessionAbortComponent;
