import EventEmitter from 'events';
import {action, computed, observable, toJS} from 'mobx';
import find from 'lodash/find';
import {Nullable} from '@techsee/techsee-ui-common/lib/_shared/reusable-types';
import {ITranslate} from '../../services/LocalizationService';
import {MeetingMode, ModePriority} from '@techsee/techsee-common/lib/constants/room.constants';
import cloneDeep from 'lodash/cloneDeep';

export enum AdditionalSessionType {
    offline = 'OFFLINE',
    desktopSharing = 'SCREEN'
}

export type MeetingModes = AdditionalSessionType | MeetingMode;

export enum InternalSessionType {
    desktopSharing = 'DESKTOP_SHARING'
}

export interface IStartWithModesSession {
    _id?: number | string;
    mode: MeetingModes;
    internalMode?: InternalSessionType | MeetingModes;
    priority: ModePriority;
    more?: boolean;
    icon?: string;
    title?: string;
    isDefault?: boolean;
}

export interface ModeChangedCallbackArgs {
    currentMode?: string | null;
    previousMode?: string | null;
}

export interface StartWithModesOptions {
    offlineEnabled?: boolean;
    desktopShareEnabled?: boolean;
    desktopShareOnlyMode?: boolean;
}

export enum StartWithModesEvents {
    MODE_CHANGED = 'mode_changed'
}

export interface IStartWithModesController {
    readonly startWithModes: IStartWithModesSession[];
    readonly isSecondaryVisible: boolean;
    readonly isMoreButton: boolean;
    readonly isOfflineSessionAvailable: boolean;
    readonly isOfflineMode: boolean;
    readonly isDesktopSharingMode: boolean;
    readonly translate: ITranslate;
    readonly defaultMode: string | null;
    readonly currentMode: IStartWithModesSession | null;
    readonly primaryModes: IStartWithModesSession[];
    readonly secondaryModes: IStartWithModesSession[];

    showSecondary(): void;

    hideSecondary(): void;

    setAvailableMeetingModes(startWithModes: IStartWithModesSession[]): void;

    setSelectedMeetingMode(mode: IStartWithModesSession | MeetingMode): void;

    setConfig(options: StartWithModesOptions): void;

    onModeChanged(listener: (args: ModeChangedCallbackArgs) => any): () => void;
}

export class StartWithModesController implements IStartWithModesController {
    @observable private _startWithModes: IStartWithModesSession[];

    @observable private _isSecondaryVisible: boolean;

    @observable private _moreButtonClicked: boolean = false;

    @observable private _currentMode: Nullable<IStartWithModesSession> = null;

    @observable private _defaultMode: Nullable<MeetingModes> = null;

    @observable private _isOfflineSessionAvailable: boolean = false;

    @observable private _config: StartWithModesOptions = {
        offlineEnabled: false,
        desktopShareEnabled: false
    };

    private _emitter: EventEmitter;

    private readonly _translate: ITranslate;

    private readonly currentModeUpdate: () => void;

    constructor(translate: ITranslate, currentModeUpdate: () => void) {
        this._startWithModes = [];
        this._isSecondaryVisible = false;
        this._translate = translate;

        this._emitter = new EventEmitter();

        this.currentModeUpdate = currentModeUpdate;

        this.setSelectedMeetingMode = this.setSelectedMeetingMode.bind(this);
        this.showSecondary = this.showSecondary.bind(this);
        this.hideSecondary = this.hideSecondary.bind(this);
    }

    get translate() {
        return this._translate;
    }

    @computed
    get startWithModes() {
        return this._startWithModes;
    }

    @computed
    get currentMode() {
        return toJS(this._currentMode);
    }

    @computed
    get defaultMode() {
        return toJS(this._defaultMode);
    }

    @computed
    get isSecondaryVisible() {
        return this._isSecondaryVisible;
    }

    @computed
    get isOfflineSessionAvailable() {
        return this._isOfflineSessionAvailable;
    }

    @computed
    get isMoreButton() {
        return this._startWithModes.length > 3 || (!!this._config.offlineEnabled && this._startWithModes.length >= 2);
    }

    @computed
    get isOfflineMode() {
        return !!this._currentMode && this._currentMode.mode === AdditionalSessionType.offline;
    }

    @computed
    get isDesktopSharingMode() {
        return (
            !!this._currentMode &&
            this._currentMode.mode === AdditionalSessionType.desktopSharing &&
            this._currentMode.internalMode === InternalSessionType.desktopSharing &&
            !!this._config.desktopShareEnabled
        );
    }

    @computed
    get primaryModes() {
        return this._startWithModes.reduce((acc: IStartWithModesSession[], item: IStartWithModesSession) => {
            item.isDefault = item.mode === this._defaultMode && item._id === this._currentMode!._id;

            if (
                item.isDefault ||
                item.priority === ModePriority.PRIMARY ||
                (item.priority === ModePriority.SECONDARY && !this.isMoreButton)
            ) {
                return [...acc, item];
            }

            return acc;
        }, []);
    }

    @computed
    get secondaryModes() {
        return this._startWithModes.reduce((acc: IStartWithModesSession[], item: IStartWithModesSession) => {
            if (item.priority === ModePriority.SECONDARY && this.isMoreButton) {
                return [...acc, item];
            }

            return acc;
        }, []);
    }

    @action
    setSelectedMeetingMode(modeToSet: IStartWithModesSession | MeetingMode) {
        if (!this._startWithModes || !modeToSet) {
            return;
        }

        let newModeToSet: IStartWithModesSession = modeToSet as IStartWithModesSession;

        if (typeof modeToSet === 'string') {
            newModeToSet = find(
                this._startWithModes,
                (mode: IStartWithModesSession) => mode.mode === modeToSet
            ) as IStartWithModesSession;
        }

        if (!newModeToSet) {
            return;
        }

        const previousMode = this._currentMode && this._currentMode.mode;

        const startWithMore = find(
            this._startWithModes,
            (startMode: IStartWithModesSession) => startMode.more
        ) as IStartWithModesSession;

        if (startWithMore && newModeToSet.priority === ModePriority.SECONDARY) {
            startWithMore.more = false;
            startWithMore.priority = ModePriority.SECONDARY;
        }

        if (newModeToSet.priority === ModePriority.SECONDARY) {
            newModeToSet.more = true;
            newModeToSet.priority = ModePriority.PRIMARY;
        }

        this._currentMode = newModeToSet;
        this._defaultMode = this._currentMode.mode;

        this.currentModeUpdate();

        this._emitter.emit(StartWithModesEvents.MODE_CHANGED, {currentMode: this._defaultMode, previousMode});
    }

    onModeChanged(listener: (args: ModeChangedCallbackArgs) => any) {
        this._emitter.on(StartWithModesEvents.MODE_CHANGED, listener);

        return () => {
            this._emitter.removeListener(StartWithModesEvents.MODE_CHANGED, listener);
        };
    }

    @action
    setAvailableMeetingModes(startWithModes: IStartWithModesSession[]) {
        const modesOptions = this.adjustStartWithModes(startWithModes);

        this._startWithModes = observable.array(modesOptions, {deep: true});
    }

    @action
    showSecondary() {
        this._moreButtonClicked = true;
        this._isSecondaryVisible = true;
    }

    @action
    hideSecondary() {
        if (this.isSecondaryVisible && this._moreButtonClicked) {
            this._moreButtonClicked = false;

            return;
        }

        this._isSecondaryVisible = false;
    }

    @action
    setConfig(options: StartWithModesOptions) {
        this._config = options;
    }

    private adjustStartWithModes(startWithModes: IStartWithModesSession[]) {
        let modesOptions: IStartWithModesSession[] = [];

        if (this._config.desktopShareOnlyMode && this._config.desktopShareEnabled) {
            modesOptions.push({
                mode: AdditionalSessionType.desktopSharing,
                internalMode: InternalSessionType.desktopSharing,
                priority: ModePriority.PRIMARY,
                title: this.translate('REACT.INVITE.START_WITH_MODES.DESKTOP_SHARING'),
                icon: 'desktop-sharing'
            });

            this.setSelectedMeetingMode(modesOptions[0]);
        } else {
            modesOptions = cloneDeep(startWithModes);

            modesOptions.forEach((mode: IStartWithModesSession) => {
                mode.more = false;
                mode.internalMode = mode.mode;
            });

            if (this._config.desktopShareEnabled) {
                modesOptions.push({
                    mode: AdditionalSessionType.desktopSharing,
                    internalMode: InternalSessionType.desktopSharing,
                    priority: ModePriority.SECONDARY,
                    title: this.translate('REACT.INVITE.START_WITH_MODES.DESKTOP_SHARING'),
                    icon: 'desktop-sharing'
                });
            }

            if (this._config.offlineEnabled) {
                modesOptions.push({
                    mode: AdditionalSessionType.offline,
                    priority: ModePriority.SECONDARY
                });
            }
        }

        return modesOptions.map((mode, index) => {
            mode._id = index;

            return mode;
        });
    }
}
