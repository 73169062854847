import {SpinnerTypeOptions, SpinnerBackgroundColors, SpinnerSizes} from '@techsee/techsee-ui-common/lib/spinner';

import tsIdleLoaderView from './ts-idle-loader.view.html';

import './ts-idle-loader.style.scss';

class TsIdleLoaderController {
    constructor($translate) {
        'ngInject';

        this.spinnerType = SpinnerTypeOptions.CIRCULAR;
        this.backgroundColorName = SpinnerBackgroundColors.Transparent;
        this.sizeName = SpinnerSizes.XSmall;

        this.lang = $translate.use(); // can be account companyName + language
        this.langFr = this.lang.includes('fr_FR');
        this.langIt = this.lang.includes('it_IT');
    }
}

export function tsIdleLoader() {
    return {
        template: tsIdleLoaderView,
        scope: true,
        controller: TsIdleLoaderController,
        controllerAs: 'vm'
    };
}
