// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../../../../img/unsupported-device.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.change-number{width:360px}.change-number .form-phone.with-phone-codes .form-select{flex:1 1 35%}.change-number .btn-toolbar:before,.change-number .btn-toolbar:after{content:none}.change-number .content{text-align:center;font-family:'roboto', Calibri, 'Trebuchet MS', sans-serif;margin-bottom:18px}.change-number .info{color:#ef4f00;font-size:14px;font-weight:700;margin-bottom:0}.change-number .text{font-size:12px}.change-number .image{display:inline-block;width:30px;height:54px;background:url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat;background-size:contain}
`, "",{"version":3,"sources":["webpack://./app/_react_/states/invite-new/invite-wizard/components/change-number/styles.scss"],"names":[],"mappings":"AAAA,eACI,WAAY,CADhB,yDAIQ,YAAa,CAJrB,qEAUY,YAAa,CAVzB,wBAeQ,iBAAkB,CAClB,yDAA0D,CAC1D,kBAAmB,CAjB3B,qBAqBQ,aAAc,CACd,cAAe,CACf,eAAgB,CAChB,eAAgB,CAxBxB,qBA4BQ,cAAe,CA5BvB,sBAgCQ,oBAAqB,CACrB,UAAW,CACX,WAAY,CACZ,4DAA4E,CAC5E,uBAAwB","sourcesContent":[".change-number {\n    width: 360px;\n\n    .form-phone.with-phone-codes .form-select {\n        flex: 1 1 35%;\n    }\n\n    .btn-toolbar {\n        &:before,\n        &:after {\n            content: none;\n        }\n    }\n\n    .content {\n        text-align: center;\n        font-family: 'roboto', Calibri, 'Trebuchet MS', sans-serif;\n        margin-bottom: 18px;\n    }\n\n    .info {\n        color: #ef4f00;\n        font-size: 14px;\n        font-weight: 700;\n        margin-bottom: 0;\n    }\n\n    .text {\n        font-size: 12px;\n    }\n\n    .image {\n        display: inline-block;\n        width: 30px;\n        height: 54px;\n        background: url('../../../../../../../img/unsupported-device.png') no-repeat;\n        background-size: contain;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
