import React from 'react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {InfoBox} from '@techsee/techsee-ui-common/lib/info-box';
import {ITranslate} from '../../../../services/LocalizationService';
import {observer} from 'mobx-react';
import {SimpleLabel} from '@techsee/techsee-ui-common/lib/forms/labels/simple';
import {IRoomInfoController} from './controller';
import {IconButton} from '@techsee/techsee-ui-common/lib/forms/buttons/icon';
import {getRootStore} from '../../../../app.bootstrap';

export interface IRoomInfo {
    ctrl: IRoomInfoController;
    translate: ITranslate;
    upperTitle: string;
    upperData: string;
    bottomData: string;
    bottomIcon: string;
    iconName: string;
}

@observer
export class RoomInfo extends TechseeBaseComponent<IRoomInfo> {
    renderInternal() {
        const {translate, ctrl} = this.props;
        let UpperLine = () => <div></div>;
        let BottomLine = () => <div></div>;
        const {localizationService} = getRootStore();

        if (!ctrl.isDisplayLink || !this.props.bottomData) {
            BottomLine = () => (
                <div className={'same-line'} onClick={() => ctrl.onCopyClick(this.props.bottomData)}>
                    <span
                        className={
                            this.props.bottomData && this.props.bottomData.length ? 'room-link' : 'disabled-link'
                        }>
                        {translate('REACT.INVITE.VIEW.LINK')}
                    </span>
                    <IconButton
                        className={this.props.bottomData && !this.props.bottomData.length ? 'disabled-link' : ''}
                        iconName={this.props.bottomIcon}
                    />
                </div>
            );
        } else {
            BottomLine = () => (
                <div className={'same-line'} onClick={() => ctrl.onCopyClick(this.props.bottomData)}>
                    <SimpleLabel>{this.props.bottomData}</SimpleLabel>
                    <IconButton iconName={this.props.bottomIcon} />
                </div>
            );
        }

        UpperLine = () => (
            <div className={'same-line'} dir={localizationService.getTextDirection()}>
                <SimpleLabel>{translate(this.props.upperTitle)}: </SimpleLabel>
                <SimpleLabel className={'bold-label padding-left-5'}> {this.props.upperData}</SimpleLabel>
            </div>
        );

        return (
            <div>
                <InfoBox
                    className={'info-box'}
                    upperLine={UpperLine}
                    bottomLine={BottomLine}
                    icon={this.props.iconName}></InfoBox>
            </div>
        );
    }
}
