// @ts-ignore
import {getSubDomainFromUrl} from '@techsee/techsee-common/lib/utils';
import {getRootStore} from '../../app.bootstrap';
import {MeetingMode} from '@techsee/techsee-common/lib/constants/room.constants';
import {locales} from '@techsee/techsee-common/lib/helpers/i18n.settings';
import {PromiseUtilsService} from '@techsee/techsee-client-services/lib/services/PromiseUtilsService';
import {getAppTracer} from '../../../app.tracer';
import get from 'lodash/get';
import {UrlUtilsService} from '@techsee/techsee-client-services/lib/services/UrlUtilsService';

declare const BASE_PATH: string;

export const DASHBOARD_LANGUAGE_KEY = 'techseeDashboardLang';

export const SupportedLanguagesEnum = Object.freeze({
    English: 'en_US',
    Dutch: 'nl_NL',
    French: 'fr_FR',
    German: 'de_DE',
    Greek: 'el_GR',
    Hebrew: 'he_IL',
    Arabic: 'ar_AR',
    Italian: 'it_IT',
    Japanese: 'jp_JP',
    Korean: 'ko_KR',
    Polish: 'pl_PL',
    Portuguese: 'pt_PT',
    Russian: 'ru_RU',
    Spanish: 'es_ES',
    Swedish: 'sv_SE',
    Turkish: 'tr_TR',
    Slovak: 'sk_SK',
    Lithuanian: 'lt_LT',
    Danish: 'da_DK',
    Norwegian: 'nv_NO',
    Finnish: 'fi_FI',
    Ukrainian: 'uk_UA',
    Indonesian: 'id_ID',
    Vietnamese: 'vi_VN'
});

const trace = getAppTracer('general.helper');

type CurrentUserParams = {
    alias?: string;
    returnURL?: string;
    userAgent?: string;
    customerId?: string;
    customerNumber?: string;
    customerCountryCode?: string;
    initialState?: string;
    roomId?: string;
    guid?: string;
    roomCode?: string;
    audio?: string;
    subDomain?: string;
    sw?: string;
    prg?: string;
    observe?: string;
    communityId?: string;
    startDate?: string;
    endDate?: string;
};

export function ssoCurrentUser(
    tsUrlUtils: any,
    findCurrentUser: any,
    getAuthToken: any,
    loginWithOneTimeToken: any,
    ott: string
) {
    const browserUtilsService = getRootStore().browserUtilsService;
    const secondLogin = browserUtilsService.getFromSessionStorage('ssoObserver');
    const subDomain = getSubDomainFromUrl(window.location.href),
        alias = location.pathname.split('/')[1],
        origin = window.location.origin,
        observe = tsUrlUtils.getParamValue('observe'),
        communityId = tsUrlUtils.getParamValue('communityId');

    // @ts-ignore
    const returnUrl = origin + (ENV.dev ? '/#' : '') + BASE_PATH + 'login-callback';

    const currentUserParams: CurrentUserParams = {
        alias: secondLogin ? undefined : alias,
        returnURL: returnUrl,
        userAgent: window.navigator && window.navigator.userAgent,
        customerId: tsUrlUtils.getParamValue('r'),
        customerNumber: tsUrlUtils.getParamValue('p'),
        customerCountryCode: tsUrlUtils.getParamValue('c'),
        initialState: tsUrlUtils.getParamValue('is'),
        roomId: tsUrlUtils.getParamValue('roomId'),
        guid: tsUrlUtils.getParamValue('g'),
        roomCode: tsUrlUtils.getParamValue('roomCode'),
        audio: tsUrlUtils.getParamValue('audio'),
        subDomain: subDomain,
        sw: tsUrlUtils.getParamValue('sw'),
        prg: tsUrlUtils.getParamValue('prg'),
        startDate: tsUrlUtils.getParamValue('startDate'),
        endDate: tsUrlUtils.getParamValue('endDate'),
        observe
    };

    if (communityId) {
        currentUserParams.communityId = communityId;
    }

    if (!secondLogin) {
        getRootStore().uiFlavorService.setLastUsedAlias(alias);
    }

    if (observe && !secondLogin) {
        browserUtilsService.saveToSessionStorage('ssoObserver', true);
    }

    if (ott && observe !== 'guest') {
        return loginWithOneTimeToken(ott)
            .then((res: {isRedeemed: boolean}) => {
                if (res.isRedeemed) {
                    if (getAuthToken()) {
                        return {redirect: false, wasRedeemed: true};
                    }

                    if (tsUrlUtils.getParamValue('emb')) {
                        // OTT failure with embedded dashboard should not be blocked
                        return {
                            embeddedDashboardFailure: true,
                            embeddedDashboardFailureError: 'Auth with OTT was not successful'
                        };
                    }
                }

                return findCurrentUser('current', {
                    params: currentUserParams,
                    bypassCache: true
                });
            })
            .catch((err: any) => {
                if (tsUrlUtils.getParamValue('emb')) {
                    console.error(err);

                    // OTT failure with embedded dashboard should not be blocked
                    return {
                        embeddedDashboardFailure: true,
                        embeddedDashboardFailureError: err
                    };
                }

                throw err;
            });
    }

    return PromiseUtilsService.startPromiseWithTimeout(
        () => trace.info('Before login check', currentUserParams),
        3000
    ).then(() =>
        findCurrentUser('current', {
            params: currentUserParams,
            bypassCache: true
        })
    );
}

export function goToDashboard(emb: boolean, sw: MeetingMode) {
    if (sw === MeetingMode.faceMeet.toLowerCase()) {
        return 'multipartyDashboard';
    }

    return emb ? 'dashboard.main' : 'dashboard.entry';
}

export function initClientLanguage(): string {
    const browserUtilsService = getRootStore().browserUtilsService;
    const currentLanguage = browserUtilsService.getFromLocalStorage(DASHBOARD_LANGUAGE_KEY);
    const foundLocales = locales.filter((locale) => locale.value === currentLanguage);

    return foundLocales && foundLocales.length === 1 ? foundLocales[0].name : 'English';
}

export function getDashboardLanguage(currentAccount: any, roomData?: any) {
    const browserUtilsService = getRootStore().browserUtilsService;
    const language = get(roomData, 'agentLanguage');

    if (language) {
        return language;
    }

    if (currentAccount?.protectedSettings?.allowAgentToChangeHisLanguage) {
        const localStorageLang = browserUtilsService.getFromLocalStorage(DASHBOARD_LANGUAGE_KEY);

        return localStorageLang ? localStorageLang : 'en_US';
    }

    return currentAccount?.settings?.language || 'en_US';
}

export function setDashboardLanguage(agentLanguage: string) {
    const browserUtilsService = getRootStore().browserUtilsService;

    browserUtilsService.saveToLocalStorage(DASHBOARD_LANGUAGE_KEY, agentLanguage);
}

export function openLinkInNewTab(url: string) {
    if (!url) {
        return;
    }

    window.open(url);
}

export function handleOAuthCallback(code: string, error: string, db: any, $state: any) {
    const scope = UrlUtilsService.extractQueryParamValueFromUrl(window.location.search, 'scope');
    const state = UrlUtilsService.extractQueryParamValueFromUrl(window.location.search, 'state');
    const errorDescription = UrlUtilsService.extractQueryParamValueFromUrl(window.location.search, 'error_subtype');

    const payload: any = {state};

    if (scope) {
        payload.scope = scope;
    }

    if (code) {
        payload.code = code;
    }

    if (error) {
        payload.error = error;
    }

    if (errorDescription) {
        payload.errorDescription = errorDescription;
    }

    return db.SSO.oAuthLoginCallback({data: payload})
        .then((res: any) => {
            if (!res.data) {
                throw new Error('No redirect URL');
            }

            window.location.href = res.data;
        })
        .catch((err: any) => {
            const traceMessage = `Failed to redirect from OAuth callback: ${err}`;
            const tracePromise = () => trace.error(traceMessage, err);
            const tracePromiseTimeout = 3000;

            return PromiseUtilsService.startPromiseWithTimeout(tracePromise, tracePromiseTimeout)
                .catch((err) => console.error(traceMessage + ' >> trace failed: ' + err))
                .then(() => $state.go('login'));
        });
}

export const LARGE_SCREEN = 1280;
