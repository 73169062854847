import React from 'react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {MeetingMode} from '@techsee/techsee-common/lib/constants/room.constants';
import {IconColors, IconSizes} from '@techsee/techsee-ui-common/lib/icons/large-rounded-icon';
import {observer} from 'mobx-react';
import find from 'lodash/find';
import {BorderRoundedIconButton} from '@techsee/techsee-ui-common/lib/forms/buttons/border-rounded-icon';
import {PLACEMENT_TYPE, PopoverPanel} from '@techsee/techsee-ui-common/lib/popover-panel';
import {IStartWithModesController, IStartWithModesSession, AdditionalSessionType} from './controller';
import {SimpleLabel} from '@techsee/techsee-ui-common/lib/forms/labels/simple';
import {getRootStore} from '../../app.bootstrap';

import './styles.scss';
import classNames from 'classnames';

export interface TsStartWithModesProps {
    startWithModesController: IStartWithModesController;
}

const startWithIconModes = [
    {
        mode: MeetingMode.images,
        iconName: 'images'
    },
    {
        mode: MeetingMode.video,
        iconName: 'video'
    },
    {
        mode: MeetingMode.screen,
        iconName: 'screen'
    },
    {
        mode: MeetingMode.appSharing,
        iconName: 'app-sharing'
    },
    {
        mode: MeetingMode.oneClick,
        iconName: 'camera'
    },
    {
        mode: MeetingMode.coBrowsing,
        iconName: 'cobrowsing'
    },
    {
        mode: MeetingMode.videoApplication,
        iconName: 'video-app'
    },
    {
        mode: AdditionalSessionType.offline,
        iconName: 'offline'
    },
    {
        mode: AdditionalSessionType.desktopSharing,
        iconName: 'desktop-sharing'
    },
    {
        mode: MeetingMode.visualJourney,
        iconName: 'journey-mode'
    },
    {
        mode: MeetingMode.faceMeet,
        iconName: 'facemeet'
    }
];

export function getIconNameByMode(mode: MeetingMode | AdditionalSessionType): string | undefined {
    const findIconMode = find(startWithIconModes, (modeIcon) => modeIcon.mode === mode);

    return findIconMode && findIconMode.iconName;
}

@observer
export class StartWithModes extends TechseeBaseComponent<TsStartWithModesProps> {
    get isOfflineAvailable() {
        return this.props.startWithModesController.isOfflineSessionAvailable;
    }

    get isMoreButton() {
        return this.props.startWithModesController.isMoreButton;
    }

    secondaryModeClick(item: IStartWithModesSession) {
        this.props.startWithModesController.hideSecondary();
        this.props.startWithModesController.setSelectedMeetingMode(item);
    }

    get primaryModes() {
        const {primaryModes, setSelectedMeetingMode, translate} = this.props.startWithModesController;

        return [
            ...primaryModes.map((item) => () => (
                <div className={'button-container default'} onClick={() => setSelectedMeetingMode(item)}>
                    <BorderRoundedIconButton
                        className={classNames('button-style', {default: item.isDefault})}
                        iconName={item.icon || getIconNameByMode(item.mode)}
                        noBorder={true}
                        reverseColor={true}
                        sizeName={item.isDefault ? IconSizes.XLarge : IconSizes.Large}
                        colorName={item.isDefault ? IconColors.DarkBlue : IconColors.Blue}
                    />

                    <SimpleLabel className={classNames('label-center', {default: item.isDefault})}>
                        {item.title || translate('REACT.INVITE.START_WITH_MODES.' + item.mode)}
                    </SimpleLabel>
                </div>
            )),
            this.moreButton
        ];
    }

    get secondaryModes() {
        const {secondaryModes, translate} = this.props.startWithModesController;

        return secondaryModes.map((item) => () => (
            <div
                className={this.isMoreButton ? 'button-secondary-container' : 'button-container'}
                onClick={() => this.secondaryModeClick(item)}>
                <BorderRoundedIconButton
                    className={this.isMoreButton ? 'button-secondary-style' : 'button-style'}
                    iconName={item.icon || getIconNameByMode(item.mode)}
                    noBorder={true}
                    reverseColor={true}
                    sizeName={IconSizes.Large}
                    colorName={IconColors.Blue}
                />

                <SimpleLabel className={'label-center'}>
                    {item.title || translate('REACT.INVITE.START_WITH_MODES.' + item.mode)}
                </SimpleLabel>
            </div>
        ));
    }

    get moreButton() {
        const {showSecondary, translate} = this.props.startWithModesController;

        if (this.isMoreButton && this.secondaryModes.length > 0) {
            return () => (
                <div className={'button-container'} onClick={showSecondary}>
                    <BorderRoundedIconButton
                        iconName={'more button-style'}
                        noBorder={true}
                        reverseColor={true}
                        sizeName={IconSizes.Large}
                        colorName={IconColors.Blue}
                    />

                    <SimpleLabel className={'label-center'}>
                        {translate('REACT.INVITE.START_WITH_MODES.MORE')}
                    </SimpleLabel>
                </div>
            );
        }

        return () => <React.Fragment />;
    }

    renderInternal(): JSX.Element {
        const {translate, isSecondaryVisible, hideSecondary} = this.props.startWithModesController;

        const {localizationService} = getRootStore();

        if (this.primaryModes.length <= 1) {
            return <React.Fragment />;
        }

        return (
            <div className={'use-common-styles start-with-modes-container'}>
                <p dir={localizationService.getTextDirection()} className='text'>
                    {translate('REACT.INVITE.VIEW.SESSION_TYPE')}
                </p>

                {this.primaryModes.map((Item, index) =>
                    this.secondaryModes.length > 0 && index === this.primaryModes.length - 1 ? (
                        <div key={index} className={'container-popover'}>
                            {isSecondaryVisible ? (
                                <PopoverPanel
                                    id={'popover-panel-start-with-modes'}
                                    className={'top-arrow use-common-styles'}
                                    placement={PLACEMENT_TYPE.RIGHT}
                                    onCloseClick={hideSecondary}>
                                    <div
                                        className={classNames('start-with-modes-secondary-buttons', {
                                            'one-mode': this.secondaryModes.length === 1
                                        })}>
                                        {this.secondaryModes.map((Item, indexSecondary) => (
                                            <Item key={indexSecondary} />
                                        ))}
                                    </div>
                                </PopoverPanel>
                            ) : (
                                <React.Fragment />
                            )}

                            <Item key={index} />
                        </div>
                    ) : (
                        <Item key={index} />
                    )
                )}
            </div>
        );
    }
}
