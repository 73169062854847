'use strict';

import forEach from 'lodash/forEach';
import toPairs from 'lodash/toPairs';
import isEqual from 'lodash/isEqual';
import assign from 'lodash/assign';
import {
    MeetingMode,
    SWITCH_MODE_REASONS_MAP,
    SwitchModeReason,
    UserType
} from '@techsee/techsee-common/lib/constants/room.constants';
import {PlatformType} from '@techsee/techsee-common/lib/constants/utils.constant';
import {getMediaTracer} from '@techsee/techsee-media-service/lib/MediaUtils/MediaTracer';
import {ChatApiService} from '@techsee/techsee-chat-api/lib/services/ChatApiService';
import {IMessage, IMessageContent} from '@techsee/techsee-chat-api/lib/contract/contractChatApi';
import {EventLogConstant} from '../../constants/events/event-log.constant';
import {ActionToRequest} from '@techsee/techsee-client-infra/lib/infra/SessionSocketEvents';
// @ts-ignore
import {analyzeClientVersionUpdate} from '@techsee/techsee-common/lib/utils';
const trace = getMediaTracer('DashboardClientApi');

interface IPerformActionMessage {
    action: string;
    data: boolean;
    sender: string;
}

export class ChatApi extends ChatApiService {
    public synced: boolean = false;

    private _ignoreMessageSync: boolean = false;

    private _lastReceivedImageFromCustomer: IMessageContent | null | string = null;

    private _localeHelper: any = null;

    constructor(tsLocaleHelper: any) {
        super(CLIENT_VERSION, PlatformType.dashboard, trace.info);

        this._localeHelper = tsLocaleHelper;
    }

    private _subscribeSocketConnectivity(): void {
        this.onConnected(this.onSocketConnectedHandler);
        this.onDisconnected(this.onSocketDisconnectedHandler);
        this.onConnectionResumed(this.onSocketConnectionResumed);
        this.onConnectionInterrupted(this.onSocketConnectionInterrupted);
    }

    private _subscribeSocketEvents(): void {
        this.onResourcesUpdated((message) => this.preEmit('resourcesUpdated', message));
        this.onICECredentialsChanged((message) => this.preEmit('ICECredentialsChanged', message));
        this.onSocketMismatch((message) => this.preEmit('socketVersion', message));
        this.onRedirectedToStore((message) => this.preEmit('redirectedToStore', message));
        this.onClientVersionStatus((status) => this.preEmit('clientVersionStatus', status));
        this.onMobileNetworkType((mobileNetworkInfo) => this.preEmit('mobileNetworkType', mobileNetworkInfo));
        this.onRoomUpdated((state) => this.preEmit('roomUpdated', state.customerId as string));
        this.onClientDeviceDetails((message) => this._updateTimestampAndEmit('clientDeviceDetails', message));
        this.onSavedImage((message) => this._updateTimestampAndEmit('imageSave', message));
        this.onRoomCode((roomCode) => this._onRoomCode(roomCode));
        this.onException((message) => this._onException(message));
        this.onJoinRoom((roomId) => this._joinRoomCallback(roomId as string));
        this.onReceiveMessage((eventArgs) => this._receiveMessage(eventArgs));
        this.onStatusChanged((eventArgs) => this._statusChanged(eventArgs));
        this.onPerformAction((message: IPerformActionMessage) => this._performAction(message));
        this.onSync((message) => this._sync(message));
        this._localeHelper.on('logEvent', (event: any) => this.sendLog(event));
        this.onClientVersionUpdate((versionDetails) => this._onClientVersionUpdate(versionDetails));
        this.registerSocketEventCallback('wifiScanResultProcessed', (data) =>
            this.preEmit('wifiScanResultProcessed', data)
        );
    }

    private _onClientVersionUpdate(versionDetails: any): void {
        const analyzedVersion = analyzeClientVersionUpdate(CLIENT_VERSION, versionDetails);

        if (analyzedVersion && analyzedVersion.breaking && analyzedVersion.currentIsAnOldVersion) {
            // @ts-ignore
            window.location.reload(true);
        }
    }

    private _sync(message: any): void | undefined {
        trace.info('Sync message received');

        const room = message.room,
            initialStatus = message.initialStatus;

        this.updateTimestamp();
        this.setAccountSettings(room.accountSettings);
        this.setRoomStatus(room.status);
        this.drainAllQueues();

        if (this._ignoreMessageSync) {
            trace.info('Ignoring messages sync');

            this._ignoreMessageSync = false;
            this.setIsSynced(true);
            this.preEmit('sync');

            return;
        }

        forEach(toPairs(this.client), (pair: any) => {
            const param = pair[0],
                oldValue = initialStatus[UserType.client][param],
                newValue = pair[1];

            if (!isEqual(oldValue, newValue)) {
                this.preEmit(this.eventName(param, UserType.client), param, newValue, oldValue);
            }
        });

        forEach(room.messages, (msg: IMessage) => {
            if (this.wasMessageIsAlreadyHandled(msg)) {
                return;
            }

            if (msg.data.private && msg.sender === UserType.dashboard) {
                return;
            }

            if (msg.data.type === 'log') {
                assign(msg.data.message, {time: new Date(msg.timestamp)});
                this.preEmit('log', msg.data.message, msg.sender, msg.isNew);
            } else {
                this.preEmit('message', msg);
            }
        });

        this.setIsSynced(true);
        this.preEmit('sync');
    }

    private _statusChanged(message: any): void {
        this.updateTimestamp();
        this.updateStatus(message);

        if (message.param === 'observer' && message.sender !== UserType.client) {
            this.preEmit('observer', message.value);
        }

        if (this.observer && message.param === 'meeting' && message.value === false) {
            this.preEmit('disconnectObserver');
        }
    }

    private _performAction(message: IPerformActionMessage): void {
        this.updateTimestamp();

        return this.performAction(message);
    }

    private _joinRoomCallback(roomId: string): void {
        this.drainAllQueues();
        this.preEmit('joinRoom', roomId);
    }

    private _onRoomCode(roomCode: string): void {
        this.setRoomCode(roomCode);
        this.preEmit('roomCode', roomCode);
    }

    private _onException(message: any): void {
        this.updateTimestamp();
        this.emitOnException(message);
    }

    private _updateTimestampAndEmit(event: string, message: any): void {
        this.updateTimestamp();
        this.preEmit(event, message);
    }

    private _receiveMessage(msg: IMessage): void | undefined {
        this.updateTimestamp();

        if (this.wasMessageIsAlreadyHandled(msg)) {
            return;
        }

        if (msg.data.type === 'log') {
            assign(msg.data.message, {time: new Date(msg.timestamp)});
            this.preEmit('log', msg.data.message, msg.sender, msg.isNew);
        } else {
            if (msg.isNew && msg.sender === UserType.client && msg.data.type === 'image') {
                this._lastReceivedImageFromCustomer = msg.data.message;
            }

            this.preEmit('message', msg);
        }
    }

    init(): void {
        this.initSocket();
        this._subscribeSocketConnectivity();
        this._subscribeSocketEvents();
    }

    connect(roomId: string, userType: UserType, roomCode?: string, userId?: string): Promise<void> {
        this.setUserType(userType);

        return this.connectToRoom(PlatformType.dashboard, roomId, roomCode, userId);
    }

    disconnect(): void {
        this.disconnectSocket();
    }

    visibilityChanged(state: boolean): void {
        this.setStatus('visible', state);
    }

    setStatus(param: string, value: any, silent: boolean = false) {
        return this.emitChangeStatus(param, value, UserType.dashboard, silent);
    }

    syncRoomStatus(): void {
        this._ignoreMessageSync = true;
        this.sync();
    }

    requestSwitchMode(
        switchModeReason: SwitchModeReason,
        requestMeetingMode?: MeetingMode
    ): Promise<ResponseType | void> {
        if (!SWITCH_MODE_REASONS_MAP[switchModeReason]) {
            return Promise.reject(new Error('Provided switchModeReason is not supported'));
        }

        const {reason, propName} = SWITCH_MODE_REASONS_MAP[switchModeReason];

        if (requestMeetingMode && reason !== SwitchModeReason.userInteraction) {
            return Promise.reject(new Error('requestMeetingMode cannot be passed with reason: ' + switchModeReason));
        }

        const switchModeParams = {trigger: {reason: reason, requestedMode: undefined}};

        (switchModeParams.trigger as any)[propName!] = switchModeReason;
        if (requestMeetingMode && reason === SwitchModeReason.userInteraction) {
            // @ts-ignore
            switchModeParams.trigger.requestedMode = requestMeetingMode;
        }

        return this.socketEmit(ActionToRequest.SWITCH_MODE, switchModeParams);
    }

    _incrementCounter(counterType: any, message?: any): void {
        this.socketEmit('incrementCounter', {counterType, message});
    }

    imageReceived(message: any): void {
        this._incrementCounter(EventLogConstant.counters.receivedImages, message);
    }

    videoReceived(message: any): void {
        this._incrementCounter(EventLogConstant.counters.receivedVideos, message);
    }

    /**
     * Saves video without sending to mobile
     * @param  {String} url video URL
     * @param meta {Object} extra information for the message
     */
    saveVideo(url: string, meta: any): void {
        this.socketEmit('saveVideo', {
            type: 'video',
            private: true,
            message: url,
            meta: meta
        });
    }

    imageSnapped(): void {
        this._incrementCounter(EventLogConstant.counters.snappedImages);
    }

    imageSent(): void {
        this._incrementCounter(EventLogConstant.counters.sentImages);
    }

    imageSaved(): void {
        this._incrementCounter(EventLogConstant.counters.savedImages);
    }

    imageAnnotated(): void {
        this._incrementCounter(EventLogConstant.counters.annotatedImages);
    }

    imageLoaded(url: string): void {
        if (this.roomId) {
            if (url === this._lastReceivedImageFromCustomer) {
                this.emit('imageLoaded', {url});
            }
        }
    }

    sendImage(url: string, meta: any): Promise<ResponseType | void> {
        // TODO incoming url will be a BlobURL that we want to upload to S3
        // and call sendMessage with final url
        return this.sendMessage('image', url, meta)
            .then(() => {
                this.imageSent();
            })
            .catch((err) => {
                this.onReady(() => this.emit('imageSendingFailed', {side: PlatformType.dashboard, url, meta, err}));

                throw err;
            });
    }

    get isReviewingTOS(): boolean {
        if (!this.client) {
            return false;
        }

        return this.client.isReviewingTOS && !this.client.tosAccepted && !this.client.tosRejected;
    }
}
