import {action, computed, observable} from 'mobx';
import {LabelType} from '@techsee/techsee-ui-common/lib/notification-label';
import {GeneralSettings} from '../../models/AccountSettings';

export interface INotificationOptions {
    message: string;
    labelType?: LabelType;
}

export interface IMainLayoutController {
    readonly isLeftBarVisible: boolean;

    readonly accountLogo: string;

    readonly notificationText: string;

    readonly notificationType: LabelType;

    showNotificationMessage(options: string | INotificationOptions): void;

    showLeftBar(): void;

    hideLeftBar(): void;
}

export class MainLayoutController implements IMainLayoutController {
    private _hideNotificationPtr: any = null;

    @observable
    private _isLeftBarVisible = false;

    @observable
    private _notificationText: string = '';

    @observable
    private _notificationType: LabelType = LabelType.Error;

    private _accGeneralSettings: GeneralSettings;

    constructor(accGeneralSettings: GeneralSettings) {
        this._accGeneralSettings = accGeneralSettings;
    }

    @computed
    get isLeftBarVisible() {
        return this._isLeftBarVisible;
    }

    @computed
    get notificationText() {
        return this._notificationText;
    }

    @computed
    get notificationType() {
        return this._notificationType;
    }

    @computed
    get accountLogo() {
        const {logoUrl} = this._accGeneralSettings;

        if (!/https/.test(logoUrl)) {
            return '/img/techsee_logo.png';
        }

        return logoUrl;
    }

    @action
    showLeftBar() {
        this._isLeftBarVisible = true;
    }

    @action
    hideLeftBar() {
        this._isLeftBarVisible = false;
    }

    @action
    showNotificationMessage(options: string | INotificationOptions): void {
        this.hideNotificationMessage();

        const labelOptions: INotificationOptions = {
            message: typeof options === 'string' ? options : options.message,
            labelType: typeof options === 'string' || !options.labelType ? LabelType.Error : options.labelType
        };

        this._hideNotificationPtr = setTimeout(() => {
            this.hideNotificationMessage();
        }, 5 * 1000);

        this._notificationText = labelOptions.message;
        this._notificationType = labelOptions.labelType!;
    }

    @action
    private hideNotificationMessage(): void {
        this._notificationText = '';
        clearTimeout(this._hideNotificationPtr);
    }
}
