'use strict';

import {TOOLKIT_DEFAULTS} from './canvas-toolkit.settings.js';

export class CanvasToolkit {
    constructor(objectGroups) {
        this.color = TOOLKIT_DEFAULTS.color;
        this.objectGroups = objectGroups;
    }

    init(canvas) {
        this.canvas = canvas;
    }

    getAntiscaleFactors() {
        if (this.canvas.viewportTransform[0] <= 1) {
            return {x: 1, y: 1};
        }

        let enforceAmplitude = -Math.cos(this.canvas.viewportTransform[0]);

        if (enforceAmplitude < 0) {
            enforceAmplitude = 0; // distortion of extra
        }

        return {
            x: 1 - (this.canvas.viewportTransform[0] + enforceAmplitude) * 0.16,
            y: 1 - (this.canvas.viewportTransform[3] + enforceAmplitude) * 0.16
        };
    }

    addCircle(x, y) {
        const circle = new fabric.Ellipse({
            top: y,
            left: x,
            originY: 'top',
            originX: 'left',
            rx: 0,
            ry: 0,
            fill: 'transparent',
            scaleX: 0.5,
            scaleY: 0.5,
            stroke: this.color,
            strokeWidth: TOOLKIT_DEFAULTS.strokeWidth
        });

        this.canvas.add(circle);

        return circle;
    }

    addRectangle(x, y) {
        const rectangle = new fabric.Rect({
            top: y,
            left: x,
            originY: 'top',
            originX: 'left',
            width: 0,
            height: 0,
            scaleX: 0.5,
            scaleY: 0.5,
            fill: 'transparent',
            stroke: this.color,
            strokeWidth: TOOLKIT_DEFAULTS.strokeWidth
        });

        this.canvas.add(rectangle);

        return rectangle;
    }

    addSelect(x, y) {
        const rectangle = new fabric.Rect({
            top: y,
            left: x,
            originY: 'top',
            originX: 'left',
            width: 0,
            height: 0,
            fill: 'transparent',
            stroke: 'green',
            strokeWidth: 2
        });

        this.canvas.add(rectangle);

        return rectangle;
    }

    addArrow(x, y) {
        const antyScaleFactor = this.getAntiscaleFactors();

        const line = new fabric.Line([x, y, x, y], {
            originX: 'center',
            originY: 'center',
            stroke: this.color,
            hasControls: false,
            strokeWidth: TOOLKIT_DEFAULTS.strokeWidth * antyScaleFactor.y,
            lockScalingX: true,
            lockScalingY: true
        });

        const triangle = new fabric.Triangle({
            width: TOOLKIT_DEFAULTS.triangleSize,
            height: TOOLKIT_DEFAULTS.triangleSize,
            originX: 'center',
            originY: 'bottom',
            hasControls: false,
            fill: this.color,
            left: x,
            top: y,
            lockScalingX: true,
            lockScalingY: true,
            scaleX: 1 * antyScaleFactor.x,
            scaleY: 1 * antyScaleFactor.y
        });

        this.objectGroups.indexGroup(line, triangle, 'arrow');
        this.canvas.add(line);
        this.canvas.add(triangle);

        return line;
    }

    addText(x, y) {
        const text = new fabric.IText('   ', {
            fontSize: TOOLKIT_DEFAULTS.fontSize,
            fontFamily: 'Helvetica',
            lineHeight: TOOLKIT_DEFAULTS.lineHeight,
            originX: 'left',
            originY: 'top',
            hasControls: false,
            fill: TOOLKIT_DEFAULTS.textColor,
            left: x,
            top: y,
            lockUniScaling: true
        });

        const baloon = new fabric.Rect({
            top: y,
            left: x - TOOLKIT_DEFAULTS.textPadding,
            originX: 'left',
            originY: 'top',
            width: TOOLKIT_DEFAULTS.minTextBoxWidth,
            height: TOOLKIT_DEFAULTS.minTextBoxHeight,
            fill: TOOLKIT_DEFAULTS.textBoxBackgroundColor,
            stroke: TOOLKIT_DEFAULTS.textBoxBorderColor,
            hasControls: false,
            rx: TOOLKIT_DEFAULTS.textBoxBorderRadius,
            ry: TOOLKIT_DEFAULTS.textBoxBorderRadius,
            strokeWidth: TOOLKIT_DEFAULTS.textBoxBorderWidth
        });

        this.objectGroups.indexGroup(text, baloon, 'text');
        this.canvas.add(baloon);
        this.canvas.add(text);
        this.canvas.setActiveObject(text);
        text.enterEditing();

        return text;
    }

    addNumTag(x, y, opts) {
        this._addTag(x, y, 'numtag', opts);
    }

    addTextTag(x, y, opts) {
        this._addTag(x, y, 'texttag', opts);
    }

    getColor() {
        return this.color;
    }

    setColor(color) {
        this.color = color;
        if (this.canvas.isDrawingMode) {
            this.setupPencil();
        }
    }

    setFreeDrawingMode(enable) {
        if (enable) {
            this.setupPencil();
            this.canvas.isDrawingMode = true;
        } else {
            this.canvas.isDrawingMode = false;
        }
    }

    setupPencil() {
        this.canvas.freeDrawingBrush.color = this.color;
        this.canvas.freeDrawingBrush.width = TOOLKIT_DEFAULTS.pencilWidth;
    }

    _addTag(x, y, type, tagText) {
        const [width, height, fontSize, radius] =
            type === 'numtag'
                ? [
                      TOOLKIT_DEFAULTS.numTagWidth,
                      TOOLKIT_DEFAULTS.numTagHeight,
                      TOOLKIT_DEFAULTS.numTagFontSize,
                      TOOLKIT_DEFAULTS.numTagBorderRadius
                  ]
                : [
                      TOOLKIT_DEFAULTS.textTagWidth,
                      TOOLKIT_DEFAULTS.textTagHeight,
                      TOOLKIT_DEFAULTS.textTagFontSize,
                      TOOLKIT_DEFAULTS.textTagBorderRadius
                  ];

        const text = new fabric.IText(tagText || '', {
            fontSize: fontSize,
            fontFamily: 'Helvetica',
            width: width,
            height: height,
            lineHeight: TOOLKIT_DEFAULTS.tagLineHeight,
            originX: 'center',
            originY: 'center',
            hasControls: false,
            fill: '#ffffff',
            left: x,
            top: y,
            lockUniScaling: true
        });

        const baloonWidth = width
                ? width - 2 * TOOLKIT_DEFAULTS.tagBorderWidth
                : text.width + 2 * TOOLKIT_DEFAULTS.textTagPadding,
            minBaloonWidth = TOOLKIT_DEFAULTS.numTagWidth - 2 * TOOLKIT_DEFAULTS.tagBorderWidth;

        const baloon = new fabric.Rect({
            top: y,
            left: x,
            originX: 'center',
            originY: 'center',
            width: baloonWidth > minBaloonWidth ? baloonWidth : minBaloonWidth,
            height: height - 2 * TOOLKIT_DEFAULTS.tagBorderWidth,
            fill: '#000000',
            stroke: 'orange',
            hasControls: false,
            rx: radius,
            ry: radius,
            strokeWidth: TOOLKIT_DEFAULTS.tagBorderWidth
        });

        this.objectGroups.indexGroup(text, baloon, type);
        this.canvas.add(baloon);
        this.canvas.add(text);

        if (!tagText) {
            this.canvas.setActiveObject(text);
            text.enterEditing();
        }

        return text;
    }
}
