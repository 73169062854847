/* eslint-disable max-classes-per-file */
import {TechseeFormBase} from '@techsee/techsee-ui-common/lib/forms/_shared/TechseeFormBase';
import {ValidationRules} from '@techsee/techsee-ui-common/lib/forms/_shared/ValidationRules';
import {ITranslate} from '../../../../../services/LocalizationService';

import {IFieldFactory} from '../../../../../app.contracts';
import {serializable} from 'serializr';
import {observable} from 'mobx';

export class JoinOfflineInfo {
    @serializable @observable sessionId: string = '';
}

export interface JoinOfflineFieldNames {
    sessionId: string;
}

export class JoinOfflineForm extends TechseeFormBase {
    private _translate: ITranslate;

    private _fieldFactory: IFieldFactory;

    constructor(translate: ITranslate, fieldFactory: IFieldFactory) {
        super();

        this._translate = translate;
        this._fieldFactory = fieldFactory;
    }

    get fieldNames(): JoinOfflineFieldNames {
        return {
            sessionId: 'sessionId'
        };
    }

    getFormValues(): JoinOfflineInfo {
        const info = new JoinOfflineInfo();
        const infoProps = Object.getOwnPropertyNames(info);

        Object.getOwnPropertyNames(this.fieldNames).forEach((field) => {
            if (!infoProps.indexOf(field)) {
                console.error(`${field} not exists on JoinOfflineInfo type`);
            } else if (this.fields[field]) {
                (info as any)[field] = this.fields[field].value as string;
            }
        });

        return info;
    }

    createSessionIdField(): void {
        const sessionId = this._fieldFactory('REACT.INVITE.VIEW.SESSION_ID.LABEL');

        sessionId.addRule({
            rule: ValidationRules.required(),
            message: this._translate('REACT.INVITE.VIEW.SESSION_ID.ERROR.EMPTY')
        });

        this.addField(this.fieldNames.sessionId, sessionId);
    }
}
