'use strict';

import get from 'lodash/get';
import {RawQos} from '@techsee/techsee-media-service/lib/qos/raw-qos';
import {getAppTracer} from '../../app.tracer';
import {getRootStore} from '../../_react_/app.bootstrap';

const trace = getAppTracer('AgentQOSService');

export class AgentQOSService {
    constructor(db) {
        'ngInject';

        this.db = db;
        this.environmentDetect = getRootStore().environmentService;
    }

    init(accountData, currentUser, roomData) {
        this.accountData = accountData;
        this.currentUser = currentUser;
        this.roomData = roomData;
    }

    sendQOS(statsReports) {
        return new Promise((resolve) => {
            if (DEBUG_QOS) {
                trace.info('dump all stats:', this._dumpAllStats(statsReports));
            }

            const entry = new RawQos().parse(statsReports);

            entry.type = 'agent';
            entry.enviroment = this._getEnviroment();
            entry.accountId = get(this.accountData, '_id');
            entry.accountName = get(this.accountData, 'companyName');
            entry.userId = get(this.currentUser, '_id');
            entry.roomId = get(this.roomData, '_id');

            this._debugQos(entry);

            resolve(this.db.QOSLog.create(entry));
        });
    }

    _getEnviroment() {
        return {
            browser: this.environmentDetect.browser(),
            deviceType: this.environmentDetect.deviceType(),
            osName: this.environmentDetect.osName()
        };
    }

    _debugQos(entry) {
        if (DEBUG_QOS) {
            const r = entry.raw;

            entry.raw = [];
            entry.raw = r;
        }
    }

    _dumpAllStats(results) {
        let statsString = '';

        results.forEach((res) => {
            statsString += this._dumpOne(res);
        });

        return '[' + statsString + ']';
    }

    _dumpOne(res) {
        let statsString = '{';
        let index = 0;

        Object.keys(res).forEach((k) => {
            statsString += `"${k}": "${res[k]}"`;
            if (++index !== res.length) {
                statsString += ',';
            }
        });
        statsString += '}';

        return statsString;
    }
}
