import React from 'react';
import {GalleryLive} from '../../../gallery/GalleryLive';

import './RightBar.scss';

export const RightBar: React.FC = () => (
    <div className='live-dashboard-right-area'>
        <GalleryLive />
    </div>
);
