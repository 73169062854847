import React from 'react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {LeftBar} from './leftBar/LeftBar';
import {Toast} from '../../../../../components/toast/Toast';
import {observer} from 'mobx-react';
import {Main} from './main/Main';
import {MultipartyDashboardController} from '../../../MultipartyDashboardController';
import {GalleryBar} from './galleryBar/GalleryBar';

import './MiniDashboardLayoutStyles.scss';

type MiniDashboardLayoutProps = {
    controller: MultipartyDashboardController;
};

@observer
export class MiniDashboardLayout extends TechseeBaseComponent<MiniDashboardLayoutProps> {
    renderInternal(): JSX.Element {
        const {controller} = this.props;
        const isMobileView = !controller.isDesktopView && controller.desktopSharingToMobile ? 'mobile-size' : '';

        return (
            <div className='mini-dashboard-root-layout'>
                <div className='mini-dashboard-notifications-container'>
                    <Toast message={controller.notificationMessage} />
                </div>
                <div className='mini-dashboard-middle-area'>
                    <LeftBar />
                    <div className={`mini-dashboard-main-content ${isMobileView}`}>
                        <Main controller={controller} />
                        {controller.showGallery &&
                            !controller.isDesktopView &&
                            controller.isPortrait &&
                            controller.desktopSharingToMobile && <GalleryBar isMobileView={true} />}
                    </div>
                    {controller.showGallery &&
                        ((controller.isDesktopView && controller.desktopSharingToMobile) ||
                            !controller.desktopSharingToMobile ||
                            !controller.isPortrait) && <GalleryBar isMobileView={false} />}
                </div>
            </div>
        );
    }
}
