import React from 'react';
import {observer} from 'mobx-react';
import {action, observable} from 'mobx';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {PrimaryButton} from '@techsee/techsee-ui-common/lib/forms/buttons/primary';
import {ButtonTypeAttributes} from '@techsee/techsee-ui-common/lib/forms/buttons/generic';
import {Icon} from '@techsee/techsee-ui-common/lib/icons/simple-icon';
import {SimpleLabel} from '@techsee/techsee-ui-common/lib/forms/labels/simple';
import {ITranslate} from '../../../services/LocalizationService';
import {AppLauncherTypesEnum} from '@techsee/techsee-common/lib/constants/room.constants';
import './appLauncherModal.scss';

export interface IAppLauncherModal {
    translate: ITranslate;
    labelText: string;
    buttonText: string;
    formErrorText: string;
    accountSettings: any;
    onSubmitAppLauncher: any;
}

@observer
export class AppLauncherModal extends TechseeBaseComponent<IAppLauncherModal> {
    onSubmit = () => {
        this.updateErrorMessage('');

        if (this.validateForm()) {
            this.props.onSubmitAppLauncher(this.selectedField);
        }
    };

    @action
    updateErrorMessage(message: string) {
        this.errorMessage = message ? this.props.translate(message) : '';
    }

    @observable errorMessage: string | undefined;

    @observable selectedField: AppLauncherTypesEnum = AppLauncherTypesEnum.DASHBOARD;

    @action
    onTypeFieldChange = (value: any) => {
        this.selectedField = value;
    };

    validateForm() {
        return this.selectedField !== null && this.selectedField !== undefined;
    }

    renderInternal() {
        const {labelText, buttonText, translate, accountSettings} = this.props;

        const launchInDashboardField = (
            <label id='dashboard' className='radio-button-label' htmlFor='app-launcher-dashboard'>
                {translate('INVITE.VIEW.LAUNCH_IN_DASHBOARD')}
            </label>
        );
        const launchInClientField = (
            <label id='dashboard' className='radio-button-label' htmlFor='app-launcher-client'>
                {translate('INVITE.VIEW.LAUNCH_FOR_CLIENT')}
            </label>
        );

        const dashboardLinkIsValid =
            accountSettings.appLauncher.dashboard.customUrl &&
            accountSettings.appLauncher.dashboard.customUrl.length > 0;
        const clientLinkIsValid =
            accountSettings.appLauncher.client.customUrl && accountSettings.appLauncher.client.customUrl.length > 0;
        const canOpenWindow = dashboardLinkIsValid || clientLinkIsValid;

        return (
            <React.Fragment>
                {canOpenWindow && (
                    <div className={'app-launcher-modal'}>
                        <SimpleLabel>{translate(labelText)}</SimpleLabel>

                        <div className='use-common-styles generic-form'>
                            <div className='radio-group'>
                                {dashboardLinkIsValid && (
                                    <div className='radio-item'>
                                        <label className='radio-container dashboard-checkmark'>
                                            <input
                                                id={'app-launcher-dashboard'}
                                                name={'app-launcher-radio'}
                                                className={'app-launcher-radio'}
                                                value={AppLauncherTypesEnum.DASHBOARD}
                                                type='radio'
                                                onChange={() => this.onTypeFieldChange(AppLauncherTypesEnum.DASHBOARD)}
                                                checked={this.selectedField === AppLauncherTypesEnum.DASHBOARD}
                                            />
                                            <span className='checkmark'></span>
                                        </label>
                                        {launchInDashboardField}
                                    </div>
                                )}
                                {clientLinkIsValid && (
                                    <div className='radio-item'>
                                        <label className='radio-container client-checkmark'>
                                            <input
                                                id={'app-launcher-client'}
                                                name={'app-launcher-radio'}
                                                className={'app-launcher-radio'}
                                                value={AppLauncherTypesEnum.CLIENT}
                                                type='radio'
                                                onChange={() => this.onTypeFieldChange(AppLauncherTypesEnum.CLIENT)}
                                                checked={this.selectedField === AppLauncherTypesEnum.CLIENT}
                                            />
                                            <span className='checkmark'></span>
                                        </label>
                                        {launchInClientField}
                                    </div>
                                )}
                            </div>

                            {this.errorMessage && <div className={'error-message error'}>{this.errorMessage}</div>}

                            <PrimaryButton
                                onClick={this.onSubmit}
                                className={'go-button'}
                                type={ButtonTypeAttributes.Button}>
                                <div>
                                    {translate(buttonText)}
                                    <Icon iconName={'arrow-next'} />
                                </div>
                            </PrimaryButton>
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }
}
