import get from 'lodash/get';
import includes from 'lodash/includes';

import InviteView from './invite.view.html';
import {InviteController} from './invite.controller.js';
import {ChangeSettingsController} from './dialogs/change-settings.controller.js';
import {ChangeAccountController} from './dialogs/change-account.controller.js';
import {SmsStatusNewUi} from './dialogs/sms-status-new-ui.service';
import historyModule from './history/history.module.js';
import offlinePoolModule from './offline-pool/offline-pool.module.js';
import sessionRequestsModule from './session-requests/session-requests.module';
import {getRootStore} from '../../../_react_/app.bootstrap';

import './invite.style.scss';

const inviteView = {
    template: InviteView,
    controller: InviteController,
    controllerAs: 'invite'
};

export default angular
    .module('states.invite.old', [historyModule.name, offlinePoolModule.name, sessionRequestsModule.name])
    .config(['$stateProvider', config])
    .service('SmsStatusNewUi', SmsStatusNewUi)
    .controller('ChangeSettingsController', ChangeSettingsController)
    .controller('ChangeAccountController', ChangeAccountController);

function config($stateProvider) {
    $stateProvider.state('invite.old', {
        params: {
            message: null,
            error: null
        },
        views: {
            'invite-desktop-view': inviteView,
            'invite-mobile-view': inviteView
        },
        resolve: {
            IECheck: ($localStorage, accountData) => {
                'ngInject';
                const refreshInviteCount = get(accountData, 'protectedSettings.refreshInviteCount');
                const environmentService = getRootStore().environmentService;

                // Hack to overcome IE11 poor memory management with SPA
                if (refreshInviteCount && environmentService.isIE11()) {
                    const inviteLoadCounter = $localStorage.inviteLoadCounter || 0;

                    if (inviteLoadCounter >= refreshInviteCount) {
                        $localStorage.inviteLoadCounter = 0;

                        location.reload(true);

                        return Promise.reject();
                    }

                    $localStorage.inviteLoadCounter = inviteLoadCounter + 1;
                }
            },
            localizationService: (accountData) => {
                'ngInject';
                const service = getRootStore().localizationService;
                // FIXME: should change the initial localization service

                service.setAccountData(accountData._id, get(accountData, 'settings.language') || 'en_US');

                return service.init();
            },
            isUserPermittedToGetOfflineAlerts: (accountData, currentUser) => {
                'ngInject';
                const selectedGroupsForOfflineAlerts = get(accountData, 'settings.selectedGroupsForOfflineAlerts', []);

                return selectedGroupsForOfflineAlerts.length && get(accountData, 'settings.showAlertsToSelectedGroups')
                    ? selectedGroupsForOfflineAlerts.some((group) => includes(currentUser.groups, group))
                    : true;
            }
        }
    });
}
