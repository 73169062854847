'use strict';

import tsDashboardTitleView from './ts-dashboard-title.view.html';
import './ts-dashboard-title.style.scss';

class TsDashboardTitleController {
    constructor($scope, $translate, $timeout, tsCanvasAnnotate) {
        'ngInject';

        this.$translate = $translate;
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.annotateService = tsCanvasAnnotate;
    }

    handleAction(event) {
        const mainCtrl = this.$scope.main;

        if (
            mainCtrl.isLiveVideo ||
            mainCtrl.isScreenVideo ||
            mainCtrl.isDesktopSharing ||
            (!mainCtrl.isCanvas && mainCtrl.isVideoApplication)
        ) {
            if (mainCtrl.isPaused && !mainCtrl.videoPausedByDashboard) {
                return;
            }

            mainCtrl.pauseVideo(event);
        } else if (mainCtrl.isScanMode) {
            mainCtrl.cancelScanning();
        } else if (mainCtrl.isCanvas) {
            mainCtrl.videoClicked(null, true);
        }
    }

    get titleString() {
        const main = this.$scope.main;
        const t = this.$translate.instant;

        const titles = [
            {string: t('MAIN.VIEW.MODE_LIVE_VIDEO'), value: main.isLiveVideo && !main.isPaused},
            {
                string: t('MAIN.VIEW.MODE_LIVE_VIDEO_RESUME'),
                value: main.isLiveVideo && main.isPaused && main.videoPausedByDashboard
            },
            {
                string: t('MAIN.VIEW.MODE_LIVE_PAUSED_BY_CUSTOMER'),
                value: main.isLiveVideo && main.isPaused && !main.videoPausedByDashboard
            },
            {string: t('MAIN.VIEW.MODE_SCANNING'), value: main.isScanMode},
            {string: t('MOBILE_MAIN.VIEW.PHOTO_MODE'), value: main.isPhotoTitle},
            {string: t('MAIN.VIEW.MODE_EDIT'), value: main.isCanvas},
            {string: t('MAIN.VIEW.MODE_SCREEN_SHARING'), value: main.isScreenSharing},
            {string: t('MAIN.VIEW.MODE_APPLICATION_SHARING'), value: main.isApplicationSharing},
            {string: t('MAIN.VIEW.MODE_DESKTOP_SHARING'), value: main.isDesktopSharing},
            {string: t('MAIN.VIEW.MODE_AR_MEASUREMENT'), value: main.measurmentAction},
            {string: t('MAIN.VIEW.MODE_LIVE_VIDEO'), value: main.isVideoApplication}
        ].filter((title) => title.value);

        return titles[0] && titles[0].string;
    }
}

export function tsDashboardTitle() {
    return {
        template: tsDashboardTitleView,
        scope: true,
        controller: TsDashboardTitleController,
        controllerAs: 'dashboardTitle'
    };
}
