/* eslint-disable max-classes-per-file */
import React, {ReactInstance} from 'react';
import {inject, observer} from 'mobx-react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {IDesignable} from '@techsee/techsee-ui-common/lib/_shared/reusable-props';
import {SimpleTabs} from '@techsee/techsee-ui-common/lib/tabs/simple';
import {SecondaryButton} from '@techsee/techsee-ui-common/lib/forms/buttons/secondary';
import {Icon} from '@techsee/techsee-ui-common/lib/icons/simple-icon';
import {PrimaryButton} from '@techsee/techsee-ui-common/lib/forms/buttons/primary';
import {AddressTypesEnum} from '@techsee/techsee-common/lib/constants/room.constants';

import {GuidanceProgress, GuidanceProgressProps} from '../components/guidance-progress/GuidanceProgress';
import {ITranslate} from '../../../../services/LocalizationService';
import {IInviteWizardController, InviteDeviceType} from '../../_contracts/InviteContracts';
import {Nullable} from '@techsee/techsee-common';
import ReactDOM from 'react-dom';

interface IStepLayoutProps extends IDesignable {
    h1Text: string;
    h2Text: string;
}

// TODO: Valentyn - move all this component into separate folder called 'shared'
// Avoid putting into one file multiple classes.

@observer
export class InviteStepsLayout extends TechseeBaseComponent<IStepLayoutProps> {
    renderInternal() {
        return (
            <div className={this.props.className}>
                <h1 className='page-header'>{this.props.h1Text}</h1>
                <h2 className='sub-header'>{this.props.h2Text}</h2>
                {this.props.children}
            </div>
        );
    }
}

interface PermissionsStepTabsProps extends GuidanceProgressProps {
    title: string;
    subTitle: string;
    stepClass: string;
}

@observer
export class PermissionsStepTabs extends TechseeBaseComponent<PermissionsStepTabsProps> {
    renderInternal() {
        const {progressList, deviceType, guidanceType, guidanceText, title, subTitle, stepClass} = this.props;

        const tabs = [
            {
                title: deviceType === InviteDeviceType.ios ? 'iOS' : 'Android',
                content: () => (
                    <GuidanceProgress
                        progressList={progressList}
                        deviceType={deviceType}
                        guidanceType={guidanceType}
                        guidanceText={guidanceText}
                    />
                )
            }
        ];

        return (
            <InviteStepsLayout className={stepClass} h1Text={title} h2Text={subTitle}>
                <SimpleTabs tabsList={tabs} uid={guidanceType + '-step-tab'} />
            </InviteStepsLayout>
        );
    }
}

interface IToolbarProps {
    wizardController: IInviteWizardController;
    translate: ITranslate;
}

@inject((inviteStores: any) => ({
    wizardController: inviteStores.inviteWizardController,
    translate: inviteStores.localizationService.translate
}))
@observer
export class SpeakerWifiStepToolbar extends TechseeBaseComponent<IToolbarProps> {
    private nextButtonRef: Nullable<ReactInstance> = null;

    constructor(props: IToolbarProps) {
        super(props);

        this.setNextBtnRef = this.setNextBtnRef.bind(this);
    }

    private get nextText() {
        const {translate, wizardController} = this.props;
        let translateButton = 'REACT.COMMON.BUTTONS.NEXT';
        const nextTextMap: any = {
            [AddressTypesEnum.EMAIL]: 'REACT.EMAIL.SEND_EMAIL',
            [AddressTypesEnum.SMS]: 'REACT.SMS.SEND_SMS',
            [AddressTypesEnum.WHATSAPP]: 'REACT.WHATSAPP.SEND_WHATSAPP'
        };

        if (
            (!wizardController.enableNewInviteWifiStep && wizardController.isOnSpekersStep()) ||
            wizardController.isOnWifiStep()
        ) {
            translateButton = nextTextMap[wizardController.addressType];
        }

        return translate(translateButton);
    }

    private setNextBtnRef(element: ReactInstance) {
        this.nextButtonRef = element;
    }

    private focusButton() {
        if (this.nextButtonRef) {
            // TODO: Wrong implementation!
            // See: https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/no-find-dom-node.md

            // eslint-disable-next-line react/no-find-dom-node
            const btnElement: any = ReactDOM.findDOMNode(this.nextButtonRef);

            if (btnElement && btnElement.focus) {
                btnElement.focus();
            }
        }
    }

    componentDidMount(): void {
        this.focusButton();
    }

    renderInternal() {
        const {wizardController, translate} = this.props;
        const isBackVisible = wizardController.enableNewInviteSpeakersStep && wizardController.isOnWifiStep();
        const isNextVisible = !wizardController.isOnSmsStep();

        const ChangeNumberBtn = wizardController.isOnSmsStep() ? PrimaryButton : SecondaryButton;

        return (
            <React.Fragment>
                {isBackVisible && (
                    <SecondaryButton onClick={() => wizardController.onBackClick()}>
                        <Icon iconName={'arrow-back'} />
                        {translate('REACT.COMMON.BUTTONS.BACK')}
                    </SecondaryButton>
                )}
                {
                    <ChangeNumberBtn
                        onClick={() => wizardController.onChangeNumberClick()}
                        className={'change-number-btn'}>
                        {translate(
                            wizardController.addressType === AddressTypesEnum.EMAIL
                                ? 'REACT.INVITE.SPEAKER_WIFI.CHANGE_ADDRESS'
                                : 'REACT.INVITE.SPEAKER_WIFI.CHANGE_NUMBER'
                        )}
                    </ChangeNumberBtn>
                }
                {isNextVisible && (
                    <PrimaryButton buttonRef={this.setNextBtnRef} onClick={() => wizardController.onNextClick()}>
                        {this.nextText}
                        <Icon iconName={'arrow-next'} />
                    </PrimaryButton>
                )}
            </React.Fragment>
        );
    }
}
