import {TechseeFormBase} from '@techsee/techsee-ui-common/lib/forms/_shared/TechseeFormBase';
import {ValidationRules} from '@techsee/techsee-ui-common/lib/forms/_shared/ValidationRules';
import {ITranslate} from '../../services/LocalizationService';

import {IFieldFactory} from '../../app.contracts';
import {createFieldModel} from '../../app.bootstrap';

export interface IFieldNames {
    customerRef: string;
    countryCode: string;
    phoneNumber: string;
    phoneNumberModel: string;
    email: string;
    startDate: string;
    endDate: string;
}

interface CustomerIdRules {
    minLength: number;
    maxLength: number;
}

export class BasicFormModel extends TechseeFormBase {
    private readonly _translate: ITranslate;

    private _customerIdRules?: CustomerIdRules;

    private _withLabel: boolean;

    private _fieldFactory: IFieldFactory;

    constructor(customerIdValidationRules: CustomerIdRules | undefined, withLabel: boolean, translate: ITranslate) {
        super();

        this._customerIdRules = customerIdValidationRules;
        this._withLabel = withLabel;
        this._translate = translate;
        this._fieldFactory = createFieldModel;
    }

    get fieldNames(): IFieldNames {
        return {
            customerRef: 'customerRef',
            countryCode: 'countryCode',
            phoneNumber: 'phoneNumber',
            phoneNumberModel: 'phoneNumberModel',
            email: 'email',
            startDate: 'startDate',
            endDate: 'endDate'
        };
    }

    createCustomerRefField(): void {
        const customerRef = this._fieldFactory(this._withLabel ? 'REACT.INVITE.VIEW.CUSTOMER_REF.LABEL' : '');

        customerRef.placeholderKey = 'REACT.INVITE.VIEW.CUSTOMER_REFERENCE_PLACEHOLDER';

        if (this._customerIdRules) {
            customerRef.addRule({
                rule: ValidationRules.minLength(this._customerIdRules.minLength),
                message: this._translate('REACT.INVITE_METHOD.VALIDATIONS.CUSTOMER_ID.MIN_LENGTH', {min: ':min'})
            });

            customerRef.addRule({
                rule: ValidationRules.maxLength(this._customerIdRules.maxLength),
                message: this._translate('REACT.INVITE_METHOD.VALIDATIONS.CUSTOMER_ID.MAX_LENGTH', {max: ':max'})
            });
        }

        this.addField(this.fieldNames.customerRef, customerRef);
    }

    createPhoneField(countryCodeEnabled: boolean): void {
        const phone = this._fieldFactory('');
        const phoneNumberModel = this._fieldFactory('');

        phoneNumberModel.placeholderKey = 'REACT.INVITE.VIEW.PHONE_NUMBER';
        const wrongNumMsg = this._translate('REACT.INVITE.VIEW.PHONE_NUM.ERROR.INCORRECT_NUMBER', {min: ':min'});

        phone.addRule({rule: ValidationRules.minLength(6), message: wrongNumMsg});
        phone.addRule({rule: ValidationRules.phoneNumber(), message: wrongNumMsg});

        this.addField(this.fieldNames.phoneNumberModel, phoneNumberModel);
        this.addField(this.fieldNames.phoneNumber, phone);

        if (countryCodeEnabled) {
            this.addField(this.fieldNames.countryCode, this._fieldFactory());
        }
    }

    createEmailField(): void {
        const email = this._fieldFactory('');

        email.placeholderKey = 'REACT.INVITE.VIEW.EMAIL_ADDRESS.LABEL';

        email.addRule({
            rule: ValidationRules.email(),
            message: this._translate('REACT.INVITE.VIEW.EMAIL.ERROR.EMAIL_VALIDATION')
        });

        this.addField(this.fieldNames.email, email);
    }

    createStartDateField(): void {
        const startDate = this._fieldFactory(this._withLabel ? 'REACT.INVITE.VIEW.START_DATE_INPUT_LABEL' : '');

        this.addField(this.fieldNames.startDate, startDate);
    }

    createEndDateField(): void {
        const endDate = this._fieldFactory(this._withLabel ? 'REACT.INVITE.VIEW.END_DATE_INPUT_LABEL' : '');

        this.addField(this.fieldNames.endDate, endDate);
    }
}
