import {action, computed, observable, runInAction} from 'mobx';
import {LOG_EVENTS} from '@techsee/techsee-common/lib/constants/event-logs.constants';
import {
    PinnedUserType,
    OnPinnedVideoChangedCb,
    IMultipartyController,
    MultipartyEvents,
    OnSnapshotRequestedCb,
    MultipartyConnectFailedEvent,
    MultipartyConnectionCreatedEvent,
    MultipartyConnectionDestroyedEvent,
    MultipartyMainVideoMirrorStateChangedEvent,
    MultipartySnapshotAllowedChangedEvent,
    MultipartyStreamCreatedEvent,
    MultipartyStreamDestroyedEvent,
    MultipartyStreamPropertyChangedEvent,
    MultipartyMainVideoPinnedAndStreamingStateChangedEvent,
    MultipartyScreenShareDisabledChangedEvent
} from '@techsee/techsee-media-service-client/';
import {ITranslate} from '../../../../services/LocalizationService';
import {IDbRooms} from '../../../../services/AngularServices/AngularServices';
import {IRoom} from '../../../../models/Room';
import {getRootStore} from '../../../../app.bootstrap';
import {MagicMarkerController} from './MagicMarkerController';
import {toSessionClientRole} from '@techsee/techsee-media-service-client/lib/services/types';
import {SessionClientRole, VideoTypesEnum} from '@techsee/techsee-media-service/lib/MediaConstants';
import {VideoLayoutType} from '@techsee/techsee-common/lib/constants/media.constants';
import {RemoteControlController} from './RemoteControlController';

export interface IVideoController {
    multipartyController?: IMultipartyController;
    isPeerMediaPermissionRejected: boolean;
    canTakeSnapshot: boolean;
    isScreenShareDisabled: boolean;
    allowMagicMarker: boolean;
    videoLayoutType: VideoLayoutType;
    translate: ITranslate;
    setMultipartyController(multipartyController: IMultipartyController): void;
    setIsPeerMediaPermissionsRejected(isClientMediaPermissionRejected: boolean): void;
    setVideoLayoutType(layoutType: VideoLayoutType): void;
    onPinnedUserChangeRequested: OnPinnedVideoChangedCb;
    onSnapshotRequested?: OnSnapshotRequestedCb;
    takeSnapshot(): string | null;
    magicMarkerController: MagicMarkerController;
    setLivePointerConnectedStatus: (val: boolean) => void;
    isMobile: boolean;
    desktopSharingToMobile: boolean;
    setIsPortrait: (isPortrait: boolean) => void;
}

export class VideoController implements IVideoController {
    @observable private _multipartyController?: IMultipartyController;
    @observable private _isPeerMediaPermissionRejected: boolean = false;
    @observable public readonly magicMarkerController: MagicMarkerController;

    @observable private _videoLayoutType: VideoLayoutType = VideoLayoutType.AgentPinnedLayout;
    @observable public onSnapshotRequested?: OnSnapshotRequestedCb = undefined;
    @observable private _canTakeSnapshot = false;
    @observable private _disabledShareScreen = false;
    @observable private _allowMagicMarker = true;
    @observable private _clientClickOnDesktopShare = false;
    @observable private _isMobile = false;
    @observable private _isPortrait = false;
    @observable private _isFullScreen = false;

    @observable private localVideoDimensions: {width: number; height: number} = {width: 640, height: 480};
    @observable private remoteVideoDimensions: {width: number; height: number} = {width: 640, height: 480};
    private remoteController: RemoteControlController;

    constructor(
        public readonly translate: ITranslate,
        private readonly roomData: IRoom,
        private readonly tsChatApi: any,
        private readonly dbRoomsService: IDbRooms,
        private readonly eventLog: (eventType: string, meta?: Record<string, unknown>) => Promise<void>,
        private readonly streamEstablishCb: (event?: any) => void,
        public readonly desktopSharingToMobile: boolean
    ) {
        this.magicMarkerController = new MagicMarkerController(
            this.tsChatApi,
            getRootStore().environmentService.isMobile(),
            eventLog
        );

        this.tsChatApi.on('clientClickOnDesktopShare', (data: string, value: boolean) => {
            runInAction(() => {
                this._disabledShareScreen = value;
            });
        });

        this.tsChatApi.on('clientAgentHasRemoteControl', (data: string, value: boolean) => {
            runInAction(() => {
                this._allowMagicMarker = !value;
            });
        });

        this.tsChatApi.on('clientIsFullScreen', (data: string, value: boolean) => {
            this.magicMarkerController.setIsFullScreen(value);
        });
        this.remoteController = new RemoteControlController(this.tsChatApi, getRootStore().environmentService);

        this._isMobile = getRootStore().environmentService.isMobile();
    }

    @computed
    get multipartyController() {
        return this._multipartyController;
    }

    @computed
    get isMobile() {
        return this._isMobile;
    }

    @computed
    get isPeerMediaPermissionRejected() {
        return this._isPeerMediaPermissionRejected;
    }

    @computed
    public get videoLayoutType(): VideoLayoutType {
        return this._videoLayoutType;
    }

    @computed
    public get canTakeSnapshot(): boolean {
        return this._canTakeSnapshot;
    }

    @computed
    public get isScreenShareDisabled(): boolean {
        return this._disabledShareScreen;
    }

    @computed
    public get allowMagicMarker(): boolean {
        return this._allowMagicMarker;
    }

    public onStreamingStateChanged(): void {
        this.magicMarkerController.setIsFullScreen((this._isFullScreen as boolean) && this._isPortrait);

        this.tsChatApi.setStatus('isFullScreen', this._isFullScreen && this._isPortrait);
    }

    public onPinnedUserChangeRequested = (pinnedVideo: {
        pinnedUserRole: PinnedUserType;
        videoType: VideoTypesEnum;
    }): void => {
        if (pinnedVideo.pinnedUserRole === PinnedUserType.USER && pinnedVideo.videoType === VideoTypesEnum.SCREEN) {
            this.remoteController.setIsActive(true);
        } else {
            this.remoteController.setIsActive(false);
        }

        this.tsChatApi.setStatus('facemeetBigVideoPinnedUser', pinnedVideo);
        this.magicMarkerController.clearRemoteDrawings();
        this.magicMarkerController.setEnabled(false);
        this._multipartyController?.setPinnedVideo(pinnedVideo);

        const connection = this._multipartyController?.getMainConnection();

        this.magicMarkerController.setMirrored(!!connection?.mirrored);

        this.eventLog(LOG_EVENTS.facemeetBigVideoPinnedUserChanged);
    };

    private initializeMagicMarker = (): void => {
        this.magicMarkerController.clearRemoteDrawings();
        this.magicMarkerController.setEnabled(
            this.multipartyController?.getPinnedVideo().pinnedUserRole !== PinnedUserType.NONE
        );
    };

    public takeSnapshot = (): string | null => {
        if (!this._canTakeSnapshot) {
            return null;
        }

        const data = this.multipartyController?.takeSnapshot();

        return data ? `data:image/png;base64,${data}` : null;
    };

    @action
    public setMultipartyController = (multipartyController: IMultipartyController): void => {
        this._multipartyController = multipartyController;

        this.initializeHandlers();
        this.logEvents();
        this.initializeMagicMarker();
    };

    @action
    public setIsPeerMediaPermissionsRejected = (mediaPermissionRejected: boolean): void => {
        this._isPeerMediaPermissionRejected = mediaPermissionRejected;
    };

    @action
    public setVideoLayoutType = (layoutType: VideoLayoutType) => {
        this._videoLayoutType = layoutType;
    };

    @action
    public setIsPortrait = (isPortrait: boolean) => {
        this._isPortrait = isPortrait;

        this.onStreamingStateChanged();
    };

    private logEvents() {
        this._multipartyController!.on(
            MultipartyEvents.streamPropertyChanged,
            (event: MultipartyStreamPropertyChangedEvent) => {
                this.eventLog(LOG_EVENTS.opentok, event);
            }
        );
        this._multipartyController!.on(MultipartyEvents.connectFailed, (event: MultipartyConnectFailedEvent) => {
            this.eventLog(LOG_EVENTS.opentokError.type, event.meta);
        });

        this._multipartyController!.on(
            MultipartyEvents.connectionCreated,
            (event: MultipartyConnectionCreatedEvent) => {
                this.eventLog(LOG_EVENTS.opentokSessionCreated, event);
            }
        );

        this._multipartyController!.on(
            MultipartyEvents.connectionDestroyed,
            (event: MultipartyConnectionDestroyedEvent) => {
                this.eventLog(LOG_EVENTS.opentok, event);
            }
        );

        this._multipartyController!.on(MultipartyEvents.streamDestroyed, (event: MultipartyStreamDestroyedEvent) => {
            const {videoType, clientRole} = event;

            if (videoType === VideoTypesEnum.SCREEN && clientRole === SessionClientRole.USER) {
                this.remoteController.setIsActive(false);
            }

            this.magicMarkerController.setVideoDimensions(
                this._multipartyController?.getPinnedVideo().pinnedUserRole === PinnedUserType.AGENT
                    ? this.localVideoDimensions
                    : this.remoteVideoDimensions
            );

            this.eventLog(LOG_EVENTS.opentok, event);
        });

        this._multipartyController!.on(MultipartyEvents.streamCreated, (event: MultipartyStreamCreatedEvent) => {
            const {videoType, clientRole} = event;

            if (videoType === VideoTypesEnum.SCREEN && clientRole === SessionClientRole.USER) {
                const element = document.getElementById('remote-video-desktop');

                this.remoteController.init(element as HTMLElement);
                this.remoteController.setIsActive(true);
            }

            this.eventLog(LOG_EVENTS.opentok, event);
        });

        this._multipartyController!.on(MultipartyEvents.mediaDeviceAccessDenied, () => {
            this.eventLog(LOG_EVENTS.opentokError.type);
        });
    }

    private initializeHandlers() {
        this._multipartyController?.on(MultipartyEvents.updateTrafficLogs, (meta: any) => {
            this.updateTrafficLogs(meta.videoTrafficDelta, meta.videoDurationDelta);
        });

        this._multipartyController?.on(
            MultipartyEvents.streamPropertyChanged,
            (e: MultipartyStreamPropertyChangedEvent) => {
                if (
                    e.clientRole === toSessionClientRole(this._multipartyController?.getPinnedVideo().pinnedUserRole) &&
                    // @ts-ignore
                    e?.videoType === this._multipartyController.getPinnedVideo().videoType
                ) {
                    this.updateStreamVideoDimensions(e.isOwnConnection, e.videoDimensions);
                }
            }
        );

        this._multipartyController?.on(MultipartyEvents.streamCreated, (e: MultipartyStreamCreatedEvent) => {
            if (e.isOwnConnection) {
                this.streamEstablishCb();
            }
            if (e.clientRole === toSessionClientRole(this._multipartyController?.getPinnedVideo().pinnedUserRole)) {
                this.magicMarkerController.setMirrored(e.mirror);
            }
        });

        this._multipartyController!.on(
            MultipartyEvents.snapshotAllowedChanged,
            (event: MultipartySnapshotAllowedChangedEvent) => {
                runInAction(() => {
                    this._canTakeSnapshot = event.snapshotAllowed;
                });
            }
        );

        this._multipartyController!.on(
            MultipartyEvents.screenShareDisabledChanged,
            (event: MultipartyScreenShareDisabledChangedEvent) => {
                runInAction(() => {
                    this._disabledShareScreen = event.screenShareDisabled || this.tsChatApi.client.clickOnDesktopShare;
                });
            }
        );

        this._multipartyController!.on(
            MultipartyEvents.mainVideoMirrorStateChanged,
            (event: MultipartyMainVideoMirrorStateChangedEvent) => {
                this.magicMarkerController.clearRemoteDrawings();
                this.magicMarkerController.setMirrored(event.mirror);
            }
        );

        this._multipartyController!.on(
            MultipartyEvents.mainVideoPinnedAndStreamingStateChanged,
            (event: MultipartyMainVideoPinnedAndStreamingStateChangedEvent) => {
                if (typeof event.isFullScreen === 'boolean') {
                    this._isFullScreen = event.isFullScreen;
                    this.onStreamingStateChanged();
                }

                runInAction(() => {
                    const savedDimensions =
                        this._multipartyController?.getPinnedVideo().pinnedUserRole === PinnedUserType.AGENT
                            ? this.localVideoDimensions
                            : this.remoteVideoDimensions;

                    this.magicMarkerController.clearRemoteDrawings();
                    this.magicMarkerController.setEnabled(event.isPinnedAndStreaming);
                    this.magicMarkerController.setVideoDimensions(
                        event.videoDimensions ? event.videoDimensions : savedDimensions
                    );
                });
            }
        );

        this._multipartyController!.on(MultipartyEvents.mediaDeviceAccessDenied, () => {
            this.streamEstablishCb({mediaDeviceAccessDenied: true});
        });
    }

    @action
    setLivePointerConnectedStatus(val: boolean) {
        this.remoteController.setLivePointerConnectedStatus(val);
    }

    @action
    private updateStreamVideoDimensions(
        isOwnConnection: boolean,
        videoDimensions: {
            width: number;
            height: number;
        }
    ) {
        if (isOwnConnection) {
            /** @FIXME This state should not be saved here, it should be exposed on multiPartyController */
            this.localVideoDimensions = videoDimensions;
        } else {
            this.remoteVideoDimensions = videoDimensions;
        }

        if (this._multipartyController?.getPinnedVideo().pinnedUserRole !== PinnedUserType.NONE) {
            this.magicMarkerController.clearRemoteDrawings();
            this.magicMarkerController.setVideoDimensions(
                this._multipartyController?.getPinnedVideo().pinnedUserRole === PinnedUserType.AGENT
                    ? this.localVideoDimensions
                    : this.remoteVideoDimensions
            );
        }
    }

    public updateTrafficLogs(videoTrafficDelta: any, videoDurationDelta: any) {
        this.dbRoomsService.setReportedField(this.roomData._id, {
            data: {
                event: {
                    key: 'videoTraffic',
                    value: videoTrafficDelta,
                    type: 'inc'
                }
            }
        });

        this.dbRoomsService.setReportedField(this.roomData._id, {
            data: {
                event: {
                    key: 'videoDuration',
                    value: videoDurationDelta,
                    type: 'inc'
                }
            }
        });
    }
}
