import React from 'react';
import {CSSTransition} from 'react-transition-group';

import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';

import './Toast.scss';

export type ToastProps = {
    message?: string;
    duration?: number;
};

export type ToastState = {
    visible: boolean;
};

const DEFAULT_DURATION = 2500;

export class Toast extends TechseeBaseComponent<ToastProps, ToastState> {
    public readonly state: ToastState = {
        visible: false
    };

    componentDidMount() {
        if (this.props.message) {
            this.showForPeriodOfTime();
        }
    }

    componentDidUpdate(prevProps: ToastProps) {
        if (prevProps.message !== this.props.message && this.props.message) {
            this.showForPeriodOfTime();
        }
    }

    private showForPeriodOfTime() {
        this.setState({visible: true});
        setTimeout(() => this.setState({visible: false}), this.props.duration ?? DEFAULT_DURATION);
    }

    renderInternal(): JSX.Element {
        const {message} = this.props;

        return (
            <>
                <CSSTransition unmountOnExit in={this.state.visible} timeout={200} classNames='toast'>
                    <div className='toast'>{message}</div>
                </CSSTransition>
            </>
        );
    }
}
