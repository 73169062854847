import {action, computed, observable} from 'mobx';
import {IDomUtilsService} from '@techsee/techsee-client-infra/lib/services/DomUtilsService';
import {ITranslate} from '../../services/LocalizationService';
import {getRootStore} from '../../app.bootstrap';

export enum InvitationEndedSessionTypes {
    regular = '0',
    warmTransfer = '1'
}

export interface IInvitationEndedController {
    readonly translate: ITranslate;
    readonly _domUtilsService: IDomUtilsService;
    readonly isSessionEnded: boolean;
    readonly isExpired: boolean;
    readonly sessionEndedMessage: string;
    readonly wtSessionEndedMessages?: string[];
    readonly isPhone: boolean;
    readonly sessionType: InvitationEndedSessionTypes;
    readonly errorMessage?: string;

    loadImage(img: string): string;
    goToInvitePage: () => void;
}

export class InvitationEndedController implements IInvitationEndedController {
    private readonly _translate: ITranslate;

    readonly isSessionEnded: boolean;

    readonly sessionEndedMessage: string;

    readonly isPhone: boolean;

    readonly _domUtilsService: IDomUtilsService;

    readonly goToInvitePage: () => void;

    readonly _sessionType: InvitationEndedSessionTypes = InvitationEndedSessionTypes.regular;

    readonly wtSessionEndedMessages?: string[];

    readonly _isExpired: boolean;

    @observable errorMessage: string | undefined;

    constructor(
        translate: ITranslate,
        domUtilsService: IDomUtilsService,
        sessionState: {ended: boolean; isExpired: boolean; sessionType: InvitationEndedSessionTypes},
        goToInvitePage: () => void
    ) {
        this._translate = translate;
        this._domUtilsService = domUtilsService;
        this.goToInvitePage = goToInvitePage;
        this.isSessionEnded = sessionState.ended;
        this._sessionType = sessionState.sessionType;
        this._isExpired = !!sessionState.isExpired;
        this.isPhone = getRootStore().environmentService.isPhone();
        this.sessionEndedMessage = this.isExpired
            ? this._translate('REACT.OBSERVER_STATE.SESSION_EXPIRED')
            : this._translate('REACT.OBSERVER_STATE.THANK_YOU');
        this.wtSessionEndedMessages =
            this.isExpired && this._sessionType === InvitationEndedSessionTypes.warmTransfer
                ? [
                      this._translate('REACT.INVITATION_ENDED.MEETING_NOT_AVAILABLE'),
                      this._translate('REACT.INVITATION_ENDED.THANK_YOU')
                  ]
                : undefined;
    }

    @computed
    get isExpired() {
        return this._isExpired;
    }

    @computed
    get sessionType() {
        return this._sessionType;
    }

    @action
    updateErrorMessage(message: string, fieldName: string) {
        this.errorMessage = message ? this.translate(message, {field: fieldName}) : '';
    }

    loadImage(img: string) {
        return this._domUtilsService.loadImage(img);
    }

    get translate() {
        return this._translate;
    }
}
