import React from 'react';
import {observer} from 'mobx-react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {InviteDeviceType} from '../../../_contracts/InviteContracts';
import {GuidanceWrapper, GuidanceImage, GuidanceTooltip, GuidanceImages} from './components';

import './styles.scss';

interface IAgentGuidance {
    stepNum: number | string;
    stepText?: string;
    stepType: string;
    deviceType: InviteDeviceType;
}

@observer
export class AgentGuidance extends TechseeBaseComponent<IAgentGuidance> {
    static Wrapper = GuidanceWrapper;

    static Image = GuidanceImage;

    static Images = GuidanceImages;

    static Tooltip = GuidanceTooltip;

    renderInternal() {
        const {deviceType, stepType, stepNum, stepText} = this.props;

        this.addCssClass(deviceType);
        this.addCssClass(stepType);
        this.addCssClass('step-' + stepNum);

        return (
            <GuidanceWrapper className={this.renderCssClasses()}>
                <GuidanceImage name={`${stepType}-${stepNum}`} type={deviceType} isTransparent={stepNum === 'i'} />

                {stepText && <GuidanceTooltip num={stepNum} text={stepText} />}
            </GuidanceWrapper>
        );
    }
}
