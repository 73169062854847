'use strict';

import tsAnnotationBarView from './ts-annotation-bar.view.html';
import {colors, tools} from './ts-annotation-bar.settings';
import './ts-annotation-bar.style.scss';

/**
 * Annotation bar
 */
class TsAnnotationBarController {
    constructor($scope, $rootScope, tsCanvasAnnotate, tsInterfaceHelper) {
        'ngInject';

        this.$scope = $scope;
        this.annotateService = tsCanvasAnnotate;
        this.requireImage = $rootScope.requireImage;
        this.requireSelectableImage = $rootScope.requireSelectableImage;
        this.isMinimized = false;
        this.colors = colors;
        this.tools = tools;
        this.tsInterfaceHelper = tsInterfaceHelper;
    }

    selectTool(tool, ignoreUnselect) {
        if (this.activeTool === tool && !ignoreUnselect) {
            // deselect
            this.activeTool = this.tools.HAND;
        } else {
            this.activeTool = tool;
        }

        this.annotateService.setTool(this.activeTool);
    }

    isActiveTool(tool) {
        if (tool === 'hand') {
            return this.annotateService.getTool() === null;
        }

        return tool === this.annotateService.getTool();
    }

    selectTextTag(text) {
        this.activeTool = 'texttag';
        this.annotateService.setTool(this.activeTool, text);
    }

    selectNumTag(num) {
        this.activeTool = 'numtag';
        this.annotateService.setTool(this.activeTool, num);
    }

    setColor(color) {
        this.annotateService.setColor(color);
    }

    get currentColorHash() {
        return this.annotateService.getColor();
    }

    toggleBar() {
        this.isMinimized = !this.isMinimized;
    }
}

export function tsAnnotationBarDirective() {
    return {
        template: tsAnnotationBarView,
        replace: true,
        restrict: 'E',
        scope: {},
        bindToController: {
            dashboardTags: '='
        },
        controller: TsAnnotationBarController,
        controllerAs: 'vm'
    };
}
