'use strict';

import tsVideoPlayerPopupView from './ts-video-player-popup.view.html';
import lastIndexOf from 'lodash/lastIndexOf';

import './ts-video-player-popup.scss';

class TsVideoPlayerPopupController {
    constructor() {
        'ngInject';
    }

    getVideoType(videoLink) {
        const videoType = videoLink.split('?')[0].substr(lastIndexOf(videoLink, '.') + 1);

        return `video/${videoType}`;
    }
}

export function tsVideoPlayerPopup() {
    return {
        template: tsVideoPlayerPopupView,
        restrict: 'E',
        scope: {},
        bindToController: {
            videoLink: '=',
            isScreenMode: '=',
            closeVideo: '&'
        },
        controller: TsVideoPlayerPopupController,
        controllerAs: 'vm'
    };
}
