import React, {Component} from 'react';

import './styles.scss';

export interface ICopilot {
    init(copilotElement: HTMLDivElement): void;
}

export class Copilot extends Component<ICopilot> {
    private readonly copilotRef: React.RefObject<HTMLDivElement>;

    constructor(props: ICopilot) {
        super(props);
        this.copilotRef = React.createRef();
    }

    componentDidMount() {
        this.initCopilot();
    }

    initCopilot = () => {
        const copilotElement = this.copilotRef.current;

        if (copilotElement) {
            this.props.init(copilotElement);
        }
    };

    render() {
        return <div className={'copilot-container'} ref={this.copilotRef}></div>;
    }
}

export default Copilot;
