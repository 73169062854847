'use strict';

import loginView from './login.view.html';
import {LoginController} from './login.controller.js';
import './login.style.scss';

const loginPageView = {
    template: loginView,
    controller: LoginController,
    controllerAs: 'login'
};

function config($stateProvider) {
    $stateProvider.state('login.old', {
        params: {
            multiUser: null
        },
        views: {
            'login-desktop-view': loginPageView,
            'login-mobile-view': loginPageView
        }
    });
}

export default angular.module('states.login.old', []).config(config).controller('LoginController', LoginController);
