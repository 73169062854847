export enum SentByServerEvents {
    HEARTBEAT_PING = 'heartbeatPing',
    ROOM_CODE = 'roomCode',
    JOIN_ROOM = 'joinRoom',
    EXCEPTION = 'exception',
    STATUS_CHANGED = 'statusChanged',
    SYNC = 'sync',
    OBSERVING = 'observing',
    RECEIVE_MESSAGE = 'receiveMessage',
    SAVED_IMAGE = 'savedImage',
    SAVED_VIDEO = 'savedVideo',
    PERFORM_ACTION = 'performAction',
    MOBILE_NETWORK_TYPE = 'mobileNetworkType',
    ROOM_UPDATED = 'roomUpdated',
    CLIENT_DEVICE_DETAILS = 'clientDeviceDetails',
    DESKTOP_SHARING_UNSUPPORTED = 'desktopSharingUnsupported',
    SOCKET_MISMATCH = 'socketMismatch'
}

export enum SentByDashboardEvents {
    JOIN_ROOM = 'joinRoom',
    HEARTBEAT_PONG = 'heartbeatPong',
    SYNC = 'sync',
    REQUEST_ACTION = 'requestAction'
}

export enum StatusEventParams {
    connected = 'connected',
    videoSupport = 'videoSupport',
    isReviewingTOS = 'isReviewingTOS',
    isOnPreCameraApprovalScreen = 'isOnPreCameraApprovalScreen',
    isPreCameraApprovedByUser = 'isPreCameraApprovedByUser',
    isOnScreenShareCapturingPermission = 'isOnScreenShareCapturingPermission',
    videoHandshakeSuccess = 'videoHandshakeSuccess',
    faceMeetHandshakeSuccess = 'faceMeetHandshakeSuccess',
    screenHandshakeSuccess = 'screenHandshakeSuccess',
    coBrowsingHandshakeSuccess = 'coBrowsingHandshakeSuccess',
    appSharingHandshakeSuccess = 'appSharingHandshakeSuccess',
    videoApplicationHandshakeSuccess = 'videoApplicationHandshakeSuccess',
    isReviewingVideoApplicationCameraAudioDialog = 'isReviewingVideoApplicationCameraAudioDialog',
    imageUploadHandshakeSuccess = 'imageUploadHandshakeSuccess',
    oneClickHandshakeSuccess = 'oneClickHandshakeSuccess',
    inCameraApprovalDialog = 'inCameraApprovalDialog',
    tosAccepted = 'tosAccepted',
    tosRejected = 'tosRejected'
}

export enum ActionToRequest {
    SWITCH_MODE = 'switchMode'
}

export enum LogMessageNames {
    UNABLE_TO_PUBLISH = 'UNABLE_TO_PUBLISH',
    CUSTOMER_ENDED_THE_MEETING = 'CUSTOMER_ENDED_THE_MEETING',
    UNSUPPORTED_DEVICE = 'UNSUPPORTED_DEVICE'
}
