import React from 'react';
import {observer} from 'mobx-react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {CloserButton} from '@techsee/techsee-ui-common/lib/forms/buttons/closer';
import {CollapsiblePanel} from '@techsee/techsee-ui-common/lib/collapsible-panel';
import {SecondaryButton} from '@techsee/techsee-ui-common/lib/forms/buttons/secondary';
import {PrimaryButton} from '@techsee/techsee-ui-common/lib/forms/buttons/primary';
import {ImageTooltip, PreviewActions} from '@techsee/techsee-ui-common/lib/image-tooltip';
import {ISmartSolutionController, ISolutionStep} from './controller';
import orderBy from 'lodash/orderBy';

import './styles.scss';

export interface ISmartSolutionComponent {
    ctrl: ISmartSolutionController;
}

@observer
export class SmartSolutionComponent extends TechseeBaseComponent<ISmartSolutionComponent> {
    renderPreparationSteps() {
        const {solution} = this.props.ctrl;

        return this.renderSteps(solution.preperationSteps!);
    }

    renderSolutionSteps() {
        const {solution} = this.props.ctrl;

        return this.renderSteps(solution.solutionSteps!);
    }

    renderSteps(steps: ISolutionStep[]) {
        return orderBy(steps, 'stepIndex').map((step: ISolutionStep, index: number) => (
            <div
                onClick={() => this.props.ctrl.clickStepHandler(step)}
                key={`step${step.stepIndex}`}
                className='smart-solution__step'>
                {step.image && (
                    <ImageTooltip
                        src={step.image.url}
                        thumbnailSize={{w: 50, h: 50}}
                        previewSize={{w: 215, h: 215}}
                        previewAction={PreviewActions.HOVER}
                    />
                )}
                {step.stepIndex || index + 1}. {step.description}
            </div>
        ));
    }

    renderInternal() {
        const {
            translate,
            isDescriptionOpen,
            isPreparationOpen,
            isResolveOpen,

            toggleDescription,
            togglePreparation,
            toggleResolve,
            showSolution,
            isSmartSolutionOpen,
            hideSmartSolution,
            sendPreparationStepsHandler,
            sendSolutionStepsHandler,

            solution
        } = this.props.ctrl;

        if (!solution || !isSmartSolutionOpen) {
            return <React.Fragment />;
        }

        return (
            <div className='smart-solution'>
                <div className='smart-solution__header use-common-styles'>
                    <CloserButton onClick={hideSmartSolution} className='close-button' />
                </div>

                <div className='smart-solution__body use-common-styles'>
                    <div className='smart-solution__title'>{solution.title}</div>
                    <CollapsiblePanel
                        width='inherit'
                        height='41px'
                        title={translate('REACT.SMART_SOLUTION.COLLAPSE_TITLE_DESCRIPTION')}
                        isOpen={isDescriptionOpen}
                        onToggleClick={toggleDescription}>
                        {solution.description}
                    </CollapsiblePanel>

                    {!!(solution.preperationSteps && solution.preperationSteps.length) && (
                        <CollapsiblePanel
                            width='inherit'
                            height='41px'
                            title={translate('REACT.SMART_SOLUTION.COLLAPSE_TITLE_PREPARATION')}
                            isOpen={isPreparationOpen}
                            onToggleClick={togglePreparation}>
                            {this.renderPreparationSteps()}

                            <SecondaryButton onClick={sendPreparationStepsHandler} className='smart-solution__button'>
                                {translate('REACT.SMART_SOLUTION.PREPARATIONS_STEPS_BUTTON')}
                            </SecondaryButton>
                        </CollapsiblePanel>
                    )}

                    {!!(solution.solutionSteps && solution.solutionSteps.length) && (
                        <div className='smart-solution__solution'>
                            <CollapsiblePanel
                                className='smart-solution__solution-resolve'
                                width='inherit'
                                isOpen={isResolveOpen}
                                title={translate('REACT.SMART_SOLUTION.COLLAPSE_TITLE_RESOLVE_ISSUE')}
                                height='41px'
                                onToggleClick={toggleResolve}>
                                {this.renderSolutionSteps()}

                                <SecondaryButton onClick={sendSolutionStepsHandler} className='smart-solution__button'>
                                    {translate('REACT.SMART_SOLUTION.SOLUTION_STEPS_BUTTON')}
                                </SecondaryButton>
                            </CollapsiblePanel>

                            {!isResolveOpen && (
                                <PrimaryButton onClick={showSolution} className='smart-solution__button'>
                                    {translate('REACT.SMART_SOLUTION.SHOW_SOLUTION_BUTTON')}
                                </PrimaryButton>
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
