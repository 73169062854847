'use strict';

import tsFloatingPanelView from './ts-floating-panel.view.html';
import './ts-floating-panel.style.scss';
import {getRootStore} from '../../_react_/app.bootstrap';

class TsFloatingPanelController {
    constructor($document, $timeout) {
        'ngInject';

        this.$document = $document;
        this.environmentDetect = getRootStore().environmentService;
        this.isMobile = this.environmentDetect.isMobile(getRootStore().displayTabletAsDesktop);
        this.$timeout = $timeout;
    }

    init(element, attrs) {
        // first we make sure that the element is attached at the end
        // of the document, as we want it to float above everything else
        // (act like a modal)
        this._attachToDocument(element);
        // event handlers are applied, make the element draggable and resizable
        this._makePanelDraggable(element);
        // set the header from attrs, if any
        this.floatingPanelHeader = attrs.floatingPanelHeader;
        // prevents the initial fade out of ng-show
        this.$timeout(() => element.removeClass('panel-loading'), 400);
        this.isFullScreen = false;
    }

    toggleFullScreen() {
        this.isFullScreen = !this.isFullScreen;
    }

    _attachToDocument(element) {
        element.detach();
        $(this.$document[0].body).append(element);
    }

    _getEventPosition(e) {
        const ev = e.originalEvent;

        return {
            clientX: this.isMobile && ev.touches ? ev.touches[0].clientX : ev.clientX,
            clientY: this.isMobile && ev.touches ? ev.touches[0].clientY : ev.clientY
        };
    }

    _makePanelDraggable(element) {
        const [start, move, cancel, end] = this.isMobile
            ? ['touchstart', 'touchmove', 'touchcancel', 'touchend']
            : ['mousedown', 'mousemove', 'mouseleave', 'mouseup'];

        element.on(start, (e) => {
            const {clientX, clientY} = this._getEventPosition(e);

            this._isHeld = true;
            const offset = element.offset();

            this._offsetX = clientX - offset.left;
            this._offsetY = clientY - offset.top;
        });

        this.$document.find('body').on(move, (e) => {
            if (this._isHeld) {
                const {clientX, clientY} = this._getEventPosition(e),
                    x = clientX - this._offsetX,
                    y = clientY - this._offsetY;

                element.css({
                    top: y + 'px',
                    left: x + 'px'
                });

                // disable Android pull-to-refresh gesture
                e.preventDefault();
            }
        });

        element.on(cancel, () => {
            this._isHeld = false;
        });

        element.on(end, () => {
            this._isHeld = false;
        });
    }

    close() {
        this.panelVisible = false;
    }
}

function linkFn(scope, element, attrs, ctrl) {
    ctrl.init(element, attrs);
}

export function tsFloatingPanelDirective() {
    return {
        template: tsFloatingPanelView,
        restrict: 'A',
        replace: true,
        transclude: true,
        scope: {},
        bindToController: {
            panelVisible: '='
        },
        controller: TsFloatingPanelController,
        controllerAs: 'vm',
        link: linkFn
    };
}
