import React from 'react';
import classNames from 'classnames';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {PrimaryButton} from '@techsee/techsee-ui-common/lib/forms/buttons/primary';
import {IInvitationEndedController, InvitationEndedSessionTypes} from './controller';
import {observer} from 'mobx-react';
import {SimpleLabel} from '@techsee/techsee-ui-common/lib/forms/labels/simple';

import './styles.scss';

export interface IInvitationEndedView {
    controller: IInvitationEndedController;
}

@observer
export class InvitationEndedView extends TechseeBaseComponent<IInvitationEndedView> {
    renderInternal(): JSX.Element {
        const {controller} = this.props;

        const isWtExpired = controller.sessionType === InvitationEndedSessionTypes.warmTransfer && controller.isExpired;

        const logoFileName = isWtExpired ? 'techsee_logo.png' : 'logo-guest.png';

        const sessionEnded = (
            <div>
                {isWtExpired && (
                    <div>
                        <div className='labels-container'>
                            <SimpleLabel className='primary-label'>
                                {controller.wtSessionEndedMessages && controller.wtSessionEndedMessages[0]}
                            </SimpleLabel>
                            <SimpleLabel className='secondary-label'>
                                {controller.wtSessionEndedMessages && controller.wtSessionEndedMessages[1]}
                            </SimpleLabel>
                        </div>
                        <PrimaryButton className={'back-to-invite-button'} onClick={controller.goToInvitePage}>
                            {controller.translate('REACT.INVITATION_ENDED.GO_TO_INVITE_PAGE')}
                        </PrimaryButton>
                    </div>
                )}
                {!isWtExpired && (
                    <div
                        className={classNames('session-expired', {desktop: !controller.isPhone})}
                        dangerouslySetInnerHTML={{__html: controller.sessionEndedMessage}}></div>
                )}
            </div>
        );

        return (
            <div className='invitation-ended-state'>
                <div className='logo'>
                    <img
                        className={isWtExpired ? 'logo-img ts' : 'logo-img'}
                        src={controller.loadImage(logoFileName)}
                    />
                </div>

                {controller.isSessionEnded && sessionEnded}

                <div className='powered-by'>{controller.translate('DASHBOARD.VIEW.POWERED_BY_TECHSEE')}</div>
            </div>
        );
    }
}
