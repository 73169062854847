import React from 'react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import MainTopBar from '../../../../_react_/layouts/main-layout/main-top-bar/component';
import {DashboardMainTopBar} from './TopBar';
import {IDashboardTopBarController} from './controller';

export interface DashboardTopBarProps {
    controller: IDashboardTopBarController;
}

export class DashboardTopBar extends TechseeBaseComponent<DashboardTopBarProps> {
    renderInternal(): JSX.Element {
        const TopBarComponent = () => <DashboardMainTopBar controller={this.props.controller} />;

        return <MainTopBar topBarComponent={TopBarComponent} accountLogo={this.props.controller.logoUrl} />;
    }
}
