/*
 * Note: this directive works by uploading an image, attaching its url to an
 * img tag and then copying the html of the tag. This poses a problem because,
 * due to browser security measures on the Clipboard API, the clipboard contents
 * can only be edited (a) inside the execution context of a 'user action', or
 * (b) 'very shortly afterwards'.
 * (a) practically means we can use the Clipboard API only in click/tap/etc
 * event handlers, while (b) depends on the browser implementation and extends
 * (a) a bit by allowing use of the API in asynchronous calls initiated by the
 * user-action event handler, and fulfilled very soon (async calls that are
 * completely client-side may work, but accessing a server will be too slow to
 * work, and it cannot be trusted as a solution).
 * For this reason, the implementation follows different code paths when the
 * secure-resources feature is enabled on an account.
 * Non-secure copies, have their final urls 'guessed', since it's easy to do so.
 * Secure copies, work in two parts, by listening to the click event of another
 * element and generating a secure url when that happens. Then when the current
 * element is clicked, that url is used.
 */

'use strict';

import pull from 'lodash/pull';

import './ts-canvas-copy.style.scss';
import tsCanvasCopyView from './ts-canvas-copy.view.html';
import {getRootStore} from '../../_react_/app.bootstrap';

// don't generate more than 1 link every 3 seconds,
// in case someone is playing with the menus
// or two directives use the same element as a target
const SECURE_LINK_DEBOUNCE = 3;

class TsCanvasCopyController {
    // eslint-disable-next-line max-params
    constructor(
        $element,
        $modal,
        $attrs,
        $scope,
        $window,
        $timeout,
        $q,
        db,
        tsCanvasAnnotate,
        tsChatApi,
        tsInterfaceHelper
    ) {
        'ngInject';

        this.$modal = $modal;
        this.$element = $element;
        this.$attrs = $attrs;
        this.$scope = $scope;
        this.$window = $window;
        this.$timeout = $timeout;
        this.$q = $q;
        this.db = db;
        this.annotateService = tsCanvasAnnotate;
        this.chatApi = tsChatApi.service;
        this.environmentDetect = getRootStore().environmentService;
        this.copyNotifierDuration = $attrs.copyNotifierDuration ? parseInt($attrs.copyNotifierDuration, 10) : 0;

        this.useOriginalImage = this.$attrs.useOriginalImage;
        this.tsInterfaceHelper = tsInterfaceHelper;

        // needed to access the compilation-time global BASE_PATH
        // in the directive's template
        this.BASE_PATH = BASE_PATH;

        this.$scope.$on('$destroy', () => {
            if (this.notifier) {
                this.notifier.detach();
            }
        });
    }

    init() {
        if (this.copySecureLink) {
            this._initSecureLinkTarget($(this.$attrs.copySecureTarget));
        }

        let roomId = this.chatApi.roomId;

        if (!roomId && this.preloadedImage && this.preloadedImage.resource) {
            const resourceUrl = this.preloadedImage.resource.url.split('?')[0];
            const s3PathComponents = resourceUrl.split('/'), //['https:', '', host, 'chat-history', number, roomId, imgName]
                fileNameComponents = s3PathComponents[s3PathComponents.length - 1].split('.');

            this.chatApi.roomId = roomId = s3PathComponents[s3PathComponents.length - 2];
            this.fileName = fileNameComponents[0] + '-rev' + Date.now() + '.' + fileNameComponents[1];
        }

        if (roomId && !this.copySecureLink) {
            this.db.Rooms.uploadClipboard(roomId, {data: {}}).then((res) => {
                this.uploadClipboardDirUrl = res.data.uploadDirUrl;
            });
        }

        this.roomId = roomId;
    }

    copy() {
        if (this.copySecureLink && !this.presignedRequest) {
            return;
        }

        const upload = this.presignedRequest || this.uploadClipboardDirUrl ? this._uploadImage() : this.$q.reject();

        upload
            .then(
                (fileName) => {
                    if (this.copySecureLink) {
                        return this.presignedRequest.publicUrl;
                    }

                    return this.uploadClipboardDirUrl + fileName;
                },
                () => this._getImageDataUrl()
            )
            .then((url) => {
                this._createImage(url);
                this._copyImage();
                this._removeImage();

                // TODO For some unknown reason on IE11 the first copy shows the IMG src="", even though the url
                // is correctly set, the DOM element shows the url correctly and no error is presented, still
                // the copied image has empty src. Running the entire copy process a second time seems to
                // solve it. For now, this is a valid workaround of yet another IE11 quirk.
                if (this.environmentDetect.isIE11()) {
                    this._createImage(url);
                    this._copyImage();
                    this._removeImage();
                }
            });

        if (this.copyNotifierDuration) {
            this.showNotifier = true;
            this.$timeout(() => {
                this.showNotifier = false;

                if (this.onCanvasCopy) {
                    this.onCanvasCopy();
                }
            }, this.copyNotifierDuration);
        } else if (this.onCanvasCopy) {
            this.onCanvasCopy();
        }
    }

    _uploadImage() {
        const dataUrl = this._getImageDataUrl();

        const img = this.annotateService.dataUrlToBlob(dataUrl);
        const fileName = this.fileName;

        if (fileName) {
            this.fileName = fileName.replace(/\-rev(\d+)\./, () => `-rev${Date.now()}.`);
        }

        if (this.presignedRequest) {
            return this.sharingService.uploadClipboardImage(img, this.presignedRequest.fileName, this.presignedRequest);
        }

        return this.sharingService.uploadClipboardImage(img, fileName);
    }

    _createImage(url) {
        const img = new Image();

        img.src = url; //will cause the browser to throw a 'resource not found' error because the image wasn't uploaded to amazon yet
        this.$window.document.body.appendChild(img);

        this.img = img;
    }

    _copyImage() {
        const window = this.$window,
            selection = window.getSelection ? window.getSelection() : window.document.selection,
            range = window.document.createRange();

        range.selectNode(this.img);

        if (selection.empty) {
            selection.empty();
        } else if (selection.removeAllRanges) {
            selection.removeAllRanges();
        }

        selection.addRange(range);

        window.document.execCommand('copy'); //this will only work when called from code triggered by user event
    }

    _removeImage() {
        if (this.img) {
            this.img.parentNode.removeChild(this.img);
            this.img = null;
        }
    }

    setupNotifier(notifier) {
        notifier.detach();
        this.$window.document.body.appendChild(notifier[0]);

        // add the fade effect on the next digest cycle to avoid a flash on load
        this.$timeout(() => notifier.addClass('ts-fade'));
        this.notifier = notifier;
    }

    _initSecureLinkTarget(elem) {
        if (!elem || elem.length !== 1) {
            return;
        }

        const element = elem[0];

        if (element.controllers) {
            element.controllers.push(this);
        } else {
            const db = this.db;

            element.controllers = [this];

            element.copyClickHandler = () => {
                if (
                    element.lastSecureDebounce &&
                    moment.utc().diff(element.lastSecureDebounce, 'seconds') < SECURE_LINK_DEBOUNCE
                ) {
                    return;
                }
                element.lastSecureDebounce = moment.utc();

                const fileName = `clipboard-copy-${Date.now()}.jpeg`;

                element.controllers.forEach((ctrl) => ctrl._copyPreparing());

                db.Rooms.uploadClipboard(this.roomId, {
                    data: {
                        fileName: fileName,
                        fileType: 'image/jpeg',
                        shorten: true
                    }
                })
                    .then((res) => {
                        element.controllers.forEach((ctrl) => {
                            ctrl.presignedRequest = res.data;

                            ctrl._copyReady();
                        });
                    })
                    .catch(() => {
                        element.lastSecureDebounce = null;
                    });
            };
        }

        if (element.controllers.length === 1) {
            elem.on('click', element.copyClickHandler);
        }

        this.$scope.$on('$destroy', () => {
            pull(element.controllers, this);

            if (!element.controllers.length) {
                elem.off('click', element.copyClickHandler);
            }
        });

        this._copyNotReady();
    }

    _copyNotReady() {
        this.presignedRequest = null;
        this.$element.removeClass('copy-ready copy-preparing').addClass('copy-not-ready');
    }

    _copyPreparing() {
        this.presignedRequest = null;
        this.$element.removeClass('copy-ready copy-not-ready').addClass('copy-preparing');
    }

    _copyReady() {
        this.$element.removeClass('copy-not-ready copy-preparing').addClass('copy-ready');
    }

    _getImageDataUrl() {
        return this.annotateService.getDataURL({
            useOriginalImage: this.useOriginalImage
        });
    }
}

function linkFn(scope, element, attrs, ctrl) {
    ctrl.init();

    ctrl.setupNotifier(element.find('.ts-canvas-copy-notifier'));
}

export function tsCanvasCopyDirective() {
    return {
        restrict: 'AE',
        template: tsCanvasCopyView,
        transclude: true,
        scope: {},
        bindToController: {
            copyNotifierText: '@',
            onCanvasCopy: '&',
            preloadedImage: '=',
            copySecureLink: '=',
            useOriginalImage: '=',
            sharingService: '='
        },
        controller: TsCanvasCopyController,
        controllerAs: 'vm',
        link: linkFn
    };
}
