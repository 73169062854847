import {action, computed, observable} from 'mobx';
import {IInviteMethodController} from '../invite-method-form/controller';
import {ChangeNumberOrigin, ChangeNumberOrigins, IInviteFlowManager} from '../../../_contracts/InviteContracts';

export interface IChangeNumberController {
    showChangeNumberDialog(origin: ChangeNumberOrigin): void;

    hideChangeNumberDialog(): void;

    onCancelClick(): void;

    onChangeInviteInfoClick(): PromiseLike<void>;

    readonly inviteMethodController: IInviteMethodController;
    readonly isDisplayConfirmationModal: boolean;
    readonly modalMode: ChangeNumberOrigin;
    readonly clientMobileOsName: string;
    readonly clientMobileOsVersion: string;
}

export class ChangeNumberController implements IChangeNumberController {
    @observable private _showConfirmationModal: boolean;

    @observable private _modalMode: ChangeNumberOrigin;

    private readonly _flowManager: IInviteFlowManager;

    private readonly _inviteMethodController: IInviteMethodController;

    constructor(flowManager: IInviteFlowManager, inviteMethod: IInviteMethodController) {
        this._showConfirmationModal = false;
        this._modalMode = ChangeNumberOrigins.MANUAL;

        this._flowManager = flowManager;
        this._inviteMethodController = inviteMethod;
    }

    onChangeInviteInfoClick(): PromiseLike<void> {
        return this._flowManager.changeInviteMethodInfo();
    }

    onCancelClick() {
        this._flowManager.inviteLogger.changeNumberModalCancel();
        this.hideChangeNumberDialog();
        this._inviteMethodController.inviteForm.revertToLastSnapshot();
    }

    @action
    showChangeNumberDialog(origin: ChangeNumberOrigin): void {
        this._flowManager.inviteLogger.changeMethodModalOpen(origin);
        this._showConfirmationModal = true;
        this._modalMode = origin || null;
    }

    @action
    hideChangeNumberDialog(): void {
        this._showConfirmationModal = false;
    }

    @computed
    get isDisplayConfirmationModal(): boolean {
        return this._showConfirmationModal;
    }

    @computed
    get modalMode(): ChangeNumberOrigin {
        return this._modalMode;
    }

    @computed
    get clientMobileOsName(): string {
        return this._flowManager.inviteState.clientMobileOsName;
    }

    @computed
    get clientMobileOsVersion(): string {
        return this._flowManager.inviteState.clientMobileOsVersion;
    }

    get inviteMethodController(): IInviteMethodController {
        return this._inviteMethodController;
    }
}
