import {PlatformType} from '@techsee/techsee-common/lib/constants/utils.constant';

export class TsTranslationHelper {
    constructor(db) {
        'ngInject';

        this.db = db;

        this.accountCustomStrings = {};
    }

    /**
     * gets and stores custom translations for an account
     * @param accountId
     */
    storeAccountCustomStrings(accountId) {
        if (!accountId) {
            return Promise.resolve({});
        }

        return this.db.CustomStrings.byAccount({params: {accountId, platformType: PlatformType.dashboard}}).then(
            (result) => (this.accountCustomStrings = result.data)
        );
    }

    getAccountCustomStrings() {
        return this.accountCustomStrings;
    }
}
