'use strict';

export const VIDEO_MODES = {
    SNAP: 'SNAP',
    CURSOR_SHARING: 'CURSOR_SHARING',
    SCANNING: 'SCANNING',
    MEASUREMENT: 'MEASUREMENT'
};

export const IMAGE_MODES = {
    SCANNING: 'SCANNING',
    MEASUREMENT: 'MEASUREMENT'
};

export const IOS_DEVICES = {
    APPLE: 'apple',
    ANDROID: 'android'
};

export const CLIENT_IN_PHOTO_MODE_STATES = {
    FIRST_TIME: 'firstTime',
    RECCURING: 'reccuring'
};

export const ANNOTATION_COLORS = [
    {
        name: 'black',
        hexCode: '#000000'
    },
    {
        name: 'gray-20',
        hexCode: '#333333'
    },
    {
        name: 'gray-40',
        hexCode: '#666666'
    },
    {
        name: 'gray-60',
        hexCode: '#999999'
    },
    {
        name: 'gray-80',
        hexCode: '#cccccc'
    },
    {
        name: 'white',
        hexCode: '#ffffff'
    },
    {
        name: 'red',
        hexCode: '#ff0000'
    },
    {
        name: 'green',
        hexCode: '#008000'
    },
    {
        name: 'blue',
        hexCode: '#0000ff'
    },
    {
        name: 'yellow',
        hexCode: '#ffff00'
    },
    {
        name: 'cyan',
        hexCode: '#00ffff'
    },
    {
        name: 'magenta',
        hexCode: '#ff00ff'
    },
    {
        name: 'lime',
        hexCode: '#00ff00'
    }
];

export const RESOURCE_TYPES = [
    {
        type: 'VIDEO',
        display: 'Video',
        icon: 'glyphicon glyphicon-facetime-video'
    },
    {
        type: 'IMAGE',
        display: 'Image',
        icon: 'glyphicon glyphicon-picture'
    },
    {
        type: 'PDF',
        display: 'PDF',
        icon: 'glyphicon glyphicon-book'
    },
    {
        type: 'WWW',
        display: 'Webpage',
        icon: 'glyphicon glyphicon-globe'
    }
];

export const STORAGE_TYPES = {
    s3: 'S3',
    temp: 'TEMP',
    ext: 'EXT'
};

export const PHOTO_SELECTION_STATE = {
    IN_PHOTO_CHAT: 'IN_PHOTO_CHAT',
    IN_CAMERA_DIALOG: 'IN_CAMERA_DIALOG'
};

export const MIN_INTERVAL_TO_DRAW_MAGIC_MARKER = 200;

export const MIN_ALLOWED_STROKE_LENGTH = 2;
