import React from 'react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {NotificationLabel} from '@techsee/techsee-ui-common/lib/notification-label';
import {observer} from 'mobx-react';
import {IMainLayoutController} from '../layout.controller';

@observer
export class MainLayoutNotification extends TechseeBaseComponent<{mainLayoutController: IMainLayoutController}> {
    renderInternal(): JSX.Element {
        const {notificationText, notificationType} = this.props.mainLayoutController;

        return (
            <div className={'main-layout-notification'}>
                {notificationText && (
                    <NotificationLabel type={notificationType}>
                        <NotificationLabel.Icon />
                        {notificationText}
                    </NotificationLabel>
                )}
            </div>
        );
    }
}
