'use strict';

export function reverse() {
    /**
     * [return description]
     * @param  {Array}  items       An array of items
     * @return {Array}              The array in reverse
     */
    return (items) => {
        if (items) {
            return items.slice().reverse();
        }

        return [];
    };
}
