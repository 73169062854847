'use strict';

import each from 'lodash/each';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';

import occupiedRoomDialogView from './dialogs/occupied-room.view.html';
import {OccupiedRoomDialogController} from './dialogs/occupied-room.controller.js';
import {SESSION_SOURCE_MAP, UserType} from '@techsee/techsee-common/lib/constants/room.constants';
import {PlatformType} from '@techsee/techsee-common/lib/constants/utils.constant';
import {getRootStore} from '../../../../_react_/app.bootstrap';
import {ServiceEvents} from '../../../../_react_/services/AccountSocket/AccountSocketEvents';
import {sendSentryLog} from '../../../../error-reporting';

export class OfflinePoolController {
    constructor(
        tsStateHelper,
        $scope,
        db,
        accountData,
        isUserPermittedToGetOfflineAlerts,
        tsUrlUtils,
        $filter,
        $modal,
        $rootScope
    ) {
        'ngInject';

        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.stateHelper = tsStateHelper;
        this.db = db;
        this.dateFormat = accountData.protectedSettings.reportsDateFormat;
        this.dateFilter = $filter('date');
        this.accountData = accountData;
        this.$modal = $modal;
        this.UserType = UserType;
        this.urlUtils = tsUrlUtils;
        this.accountSocketService = getRootStore().accountSocketService;

        if (isUserPermittedToGetOfflineAlerts) {
            this.accountSocketService.on(ServiceEvents.OFFLINE_DATA, (data) => this._getOfflineRooms(data));
            this.accountSocketService
                .joinAccountChannel()
                .then((data) => this._getOfflineRooms(data.pendingRooms))
                .catch((err) => sendSentryLog(err));
        }

        this.roomsAwaitingAgent = [];
        this.roomsAwaitingClient = [];
    }

    /*
     * Gets offline rooms, discards those that have no actions yet
     * and separates then in two groups, based on last responder.
     */
    _getOfflineRooms(data) {
        const roomsAwaitingClient = [];
        const roomsAwaitingAgent = [];

        each(data, (entry) => {
            if (entry.lastActionBy === UserType.dashboard) {
                roomsAwaitingClient.push({
                    ...entry,
                    creationTimestamp: this._getDate(entry.room.creationTimestamp),
                    connectedTimestamp: this._getDate(entry.room.connectedTimestamp),
                    sessionCode: this._getSessionCode(entry.room),
                    lastUpdate: this._getDate(entry.room.lastAgentAction),
                    customerNumber: entry.room.customerNumber,
                    customerId: entry.room.customerId,
                    sessionSource: SESSION_SOURCE_MAP[entry.room.sessionSource]
                });
            } else if (entry.lastActionBy === UserType.client) {
                roomsAwaitingAgent.push({
                    ...entry,
                    creationTimestamp: this._getDate(entry.room.creationTimestamp),
                    connectedTimestamp: this._getDate(entry.room.connectedTimestamp),
                    sessionCode: this._getSessionCode(entry.room),
                    lastUpdate: this._getDate(entry.room.lastClientAction),
                    customerNumber: entry.room.customerNumber,
                    customerId: entry.room.customerId,
                    sessionSource: SESSION_SOURCE_MAP[entry.room.sessionSource]
                });
            }
        });

        this.roomsAwaitingClient = sortBy(
            roomsAwaitingClient,
            ({room: {lastAgentAction}}) => lastAgentAction
        ).reverse();
        this.roomsAwaitingAgent = sortBy(roomsAwaitingAgent, ({room: {lastUserAction}}) => lastUserAction).reverse();

        this.$rootScope.safeApply();
    }

    _getSessionCode(room) {
        const code = !room || isEmpty(room, 'guids') ? TsUtils.findGuidInUrl(room.sentURL) : room.guids[0];

        if (room.clientType === PlatformType.dynamicAppClient) {
            return code.substr(this.accountData.guidPrefix.length);
        }

        return code;
    }

    _getDate(dateString) {
        const date = moment(dateString);

        if (date.unix() === 0) {
            return '-';
        }

        return this.dateFilter(dateString, this.dateFormat);
    }

    openRoom(index, lastActionBy) {
        const {occupied, room} =
            lastActionBy === UserType.dashboard ? this.roomsAwaitingClient[index] : this.roomsAwaitingAgent[index];

        if (occupied) {
            return this._showOccupiedModal();
        }

        if (this.accountData.protectedSettings.showRoomIdInUrl) {
            this._redirection(room._id);

            return;
        }

        this.db.Rooms.shortId(room._id).then(({data: {shortId}}) => this._redirection(shortId));
    }

    _redirection(roomId) {
        this.urlUtils.setParamValue('room', roomId);

        this.loading = true;
        this.stateHelper.safeGo('dashboard.entry', {
            room: roomId
        });
    }

    _showOccupiedModal() {
        this.$modal.open({
            animation: true,
            template: occupiedRoomDialogView,
            controller: OccupiedRoomDialogController,
            controllerAs: 'vm'
        });
    }

    backToInvite() {
        this.stateHelper.safeGo('invite');
    }
}
