'use strict';

import isEmpty from 'lodash/isEmpty';

import {CHECK_PENDING_REQUEST_INTERVAL, CHECK_PENDING_REQUEST_ATTEMPTS} from './ts-state-helper.settings';

// Service that wraps $state.go
export class TsStateHelperService {
    constructor($state, $http, tsTimedOperation) {
        'ngInject';

        this.$http = $http;
        this.$state = $state;
        this.timedOperation = tsTimedOperation;
    }

    safeGo(state, params, options) {
        if (this.safeGoInProgress) {
            return;
        }

        this.safeGoInProgress = true;

        let attempts = 0;

        this.checkPendingRequests = () => {
            try {
                if (!isEmpty(this.$http.pendingRequests) && attempts < CHECK_PENDING_REQUEST_ATTEMPTS) {
                    attempts++;

                    return setTimeout(() => {
                        this.checkPendingRequests();
                    }, CHECK_PENDING_REQUEST_INTERVAL);
                }

                this.safeGoInProgress = false;

                this.$state.go(state, params, options);

                this.timedOperation.release();
            } catch (e) {
                this.safeGoInProgress = false;

                throw e;
            }
        };

        this.checkPendingRequests();
    }
}
