'use strict';

export const COBROWSERS = {
    LEADER: 0,
    FOLLOWER: 1
};

export const TIMEOUT = {
    LOADER_TIMEOUT: 10000
};
