'use strict';

import {MediaServiceType} from '@techsee/techsee-media-service/lib/MediaConstants';

export const SENDING_TIMEOUT_INTERVAL = 3000;

export const ENDING_TIMEOUT = 10000;

export const VIDEO_TRAFFIC_LOG_INTERVAL = 5;

export const AGENT_QOS_LOG_INTERVAL = 10;

export const VIDEO_DURATION_LOG_INTERVAL = 10;

export const OBSERVATION_LOG_INTERVAL = 30000;

export const VIDEO_BANDWIDTH_SAMPLE_COUNT_DURING_SESSION = 3;

export const OBSERVER_JOIN_ALERT_TIMEOUT = 5000;

export const OBSERVER_ALERT_COLOR = '#0056D8';

export const USER_REDIRECTED_TIMEOUT = 4000;

export const RECORDING_ERROR_COLOR = 'rgba(239,79,0,0.7)';

export const RECORDING_FAILED_TIMEOUT = 120000;

export const RECORDING_MESSAGE_ALERT_TIMEOUT = 3000;

export const POINTER_UNAVAILABLE_MESSAGE_ALERT_COLOR = '#0056D8';

export const POINTER_UNAVAILABLE_MESSAGE_ALERT_TIMEOUT = 3000;

export const AR_MEASUREMENT_DISTANCE_COPIED_MESSAGE_ALERT_COLOR = '#0056D8';

export const AR_MEASUREMENT_DISTANCE_COPIED_MESSAGE_ALERT_TIMEOUT = 3000;

export const DS_CONTROL_REQUEST_SENT_TO_CUSTOMER_COLOR = '#0066FF';

export const DS_CONTROL_REQUEST_SENT_TO_CUSTOMER_TIMEOUT = 3000;

export const DS_CONTROL_REQUEST_APPROVED_COLOR = '#40CE50';

export const DS_CONTROL_REQUEST_APPROVED_TIMEOUT = 3000;

export const DS_CONTROL_STOPPED_COLOR = '#EF4F00';

export const DS_CONTROL_STOPPED_TIMEOUT = 3000;

export const LOCATION_ADDRESS_STATUS = {
    WAITING: 'WAITING',
    FAILURE: 'FAILURE',
    AVAILABLE: 'AVAILABLE'
};

export const LOCATION_ICONS = {
    UNAVAILABLE: 'unavailable',
    AVAILABLE: 'available'
};

export const BANDWIDTH_SAMPLES_BUFFER_MAX_SIZE = 10;

export const SURVEY_CONSTANTS = {
    TYPES: {
        FAILURE: 'FAILURE',
        QUALITY: 'QUALITY'
    },
    ANSWER_TYPES: {
        YES_NO: 'YES_NO',
        OPEN: 'OPEN',
        SELECT: 'SELECT'
    },
    STATUSES: {
        DONE: 'DONE',
        IGNORED: 'IGNORED'
    },
    THANK_YOU_TIMEOUT: 3000
};

export const API_TO_HINTER_MAP = {
    api: 1,
    api1: 2,
    'demo-api': 3,
    'staging-api': 4,
    'rc-api': 5,
    'test-api': 6,
    'pretest-api': 7,
    'hagrid-api': 8
};

export const MEDIA_SERVICE = MediaServiceType;
