export enum ServiceEvents {
    ACCOUNT_DATA = 'accountData',
    OFFLINE_DATA = 'offlineData',
    FS_DATA = 'fsData',
    PENDING_ROOM_DATA = 'warmTransferData'
}

export enum SentByServerEvents {
    DataSync = 'dataSync'
}
