'use strict';

export class OccupiedRoomDialogController {
    constructor($modalInstance) {
        'ngInject';

        this.$modalInstance = $modalInstance;
    }

    closeMessage() {
        this.$modalInstance.close();
    }
}
