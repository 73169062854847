import {getAppTracer} from '../../../app.tracer';
import {PromiseUtilsService} from '@techsee/techsee-client-services/lib/services/PromiseUtilsService';

const trace = getAppTracer('revokeAccountModule');

function _handleError({
    tsStateHelper,
    authId,
    err
}: {tsStateHelper?: any; authId?: string; err?: Error | unknown} = {}) {
    const traceMessage = `Failed to redirect from sso-action (Auth=${authId}): ${err}`;
    const tracePromise = () => trace.error(traceMessage, err);
    const tracePromiseTimeout = 3000;

    PromiseUtilsService.startPromiseWithTimeout(tracePromise, tracePromiseTimeout)
        .catch((err) => console.error(traceMessage + ' >> trace failed: ' + err))
        .then(() => {
            tsStateHelper.safeGo('embeddedDashboardGeneralError');
        });
}

class RevokeAccountController {
    static $inject = ['tsStateHelper', 'tsUrlUtils', 'db'];

    constructor(tsStateHelper: any, tsUrlUtils: any, db: any) {
        this.init(tsStateHelper, tsUrlUtils, db);
    }

    async init(tsStateHelper: any, tsUrlUtils: any, db: any) {
        const authId = tsUrlUtils.getParamValue('authId'),
            orgId = tsUrlUtils.getParamValue('orgId');

        try {
            const traceMessage = `revokeAccount has loaded, state: (Auth=${authId})`;

            await PromiseUtilsService.startPromiseWithTimeout(() => trace.info(traceMessage), 3000);
            await db.Account.enqueueRevokeAccountRequest({data: {orgId}});

            tsStateHelper.safeGo('invite', {authId});
        } catch (err) {
            _handleError({tsStateHelper, authId, err});

            throw err;
        }
    }
}

declare const angular: any;

declare const ROUTE_BASE_PATH: string;

export default angular
    .module('states.revokeAccount', [])
    .config(config)
    .controller('RevokeAccountController', RevokeAccountController);

const controller = {
    controller: 'RevokeAccountController'
};

config.$inject = ['$stateProvider'];

function config($stateProvider: any) {
    $stateProvider.state('revokeAccount', {
        url: ROUTE_BASE_PATH + 'revoke-account?authId&token&orgId',
        views: {
            'desktop-view': controller,
            'mobile-view': controller
        }
    });
}
