import React from 'react';
import {observer} from 'mobx-react';
import moment from 'moment';
import {SimpleLabel} from '@techsee/techsee-ui-common/lib/forms/labels/simple';
import {IGuidancePopoversController} from './GuidancePopovers.controller';

import './GuidancePopovers.styles.scss';

interface ISpeedTestGuidanceProps {
    ctrl: IGuidancePopoversController;
}

@observer
export class SpeedTestGuidance extends React.Component<ISpeedTestGuidanceProps> {
    render() {
        const {ctrl} = this.props;

        if (!ctrl.speedtestResults) {
            return (
                <div className='guidance-content'>
                    {ctrl.showSpeedtestResults ? (
                        <SimpleLabel>
                            {ctrl.translate('REACT.FLOATING_TOOLBAR.SPEEDTEST_GUIDANCE_CONTENT_PREMIUM')}
                        </SimpleLabel>
                    ) : (
                        <SimpleLabel>{ctrl.translate('REACT.FLOATING_TOOLBAR.SPEEDTEST_GUIDANCE_CONTENT')}</SimpleLabel>
                    )}
                    <img src={ctrl.loadImage('speedtest-guidance.png')} />
                </div>
            );
        }

        const speedtestResults = ctrl.speedtestResults || {};
        const {serverName, serverLocation, download, upload, latency, timestamp} = speedtestResults;

        return (
            <div className='guidance-content speedtest-results'>
                <div className='speedtest-results-client-data'>
                    <div>
                        <b>{ctrl.translate('REACT.FLOATING_TOOLBAR.SPEEDTEST_RESULTS_TIMESTAMP')}</b>
                    </div>
                    <div>
                        <span>{moment(timestamp).format('HH:mm:ss')}</span>
                    </div>
                    <div>
                        <b>{ctrl.translate('REACT.FLOATING_TOOLBAR.SPEEDTEST_RESULTS_SERVER')}</b>
                    </div>
                    <div>
                        <span>{serverName}</span> <span>{serverLocation}</span>
                    </div>
                    <div>
                        <b>{ctrl.translate('REACT.FLOATING_TOOLBAR.SPEEDTEST_CLIENT_IP')}</b>
                    </div>
                    <div>
                        <span>{ctrl.speedtestResults ? ctrl.speedtestResults.clientIP : ''}</span>
                    </div>
                </div>
                <div className='speedtest-results-row'>
                    <span>{ctrl.translate('REACT.FLOATING_TOOLBAR.SPEEDTEST_RESULTS_DOWNLOAD')}</span>
                    <span className='speedtest-results-value'>{download ? download.toFixed(1) : '–'}</span>
                </div>
                <div className='speedtest-results-row'>
                    <span>{ctrl.translate('REACT.FLOATING_TOOLBAR.SPEEDTEST_RESULTS_UPLOAD')}</span>
                    <span className='speedtest-results-value'>{upload ? upload.toFixed(1) : '–'}</span>
                </div>
                <div className='speedtest-results-row'>
                    <span>{ctrl.translate('REACT.FLOATING_TOOLBAR.SPEEDTEST_RESULTS_LATENCY')}</span>
                    <span className='speedtest-results-value'>{latency || '–'}</span>
                </div>
            </div>
        );
    }
}
