'use strict';

import './accept-terms.style.scss';

export class AcceptTermsController {
    constructor($modalInstance, termsPath, dir, readOnly) {
        'ngInject';

        this.$modalInstance = $modalInstance;
        this.termsPath = termsPath;
        this.dir = dir;
        this.readOnly = readOnly;
    }

    accept() {
        this.$modalInstance.close();
    }
}
