import {observable, action, computed} from 'mobx';
import {ITranslate} from '../../../../../services/LocalizationService';
import {IRedirectionService} from '../../../../../services/RedirectionService';
import {IDbObservation} from '../../../../../services/AngularServices/AngularServices';
import orderBy from 'lodash/orderBy';

export interface IObservableSessionsController {
    readonly translate: ITranslate;
    observableUsers: any[];
    displayErrorMessage: boolean;

    setErrorMessage(value: boolean): void;
    setObservableUsers(data: any): void;
    joinSession(sessionId: string): void;
}

export class ObservableSessionsController implements IObservableSessionsController {
    @observable private _observableUsers: any[] = [];

    @observable private _displayErrorMessage: boolean = false;

    constructor(
        public translate: ITranslate,
        private _currentUser: any,
        private _redirectionService: IRedirectionService,
        private _dbObservationService: IDbObservation
    ) {
        this.joinSession = this.joinSession.bind(this);
    }

    joinSession(sessionId: string) {
        const data = {
            shortId: sessionId,
            userName: this._currentUser.userName,
            firstName: this._currentUser.firstName,
            lastName: this._currentUser.lastName,
            addNewSegment: true
        };

        return this._dbObservationService
            .addNew({data})
            .then(() => this._redirectionService.goToDashboardEntry({room: sessionId, observe: 'true'}));
    }

    @computed
    get observableUsers() {
        return this._observableUsers;
    }

    @action
    setObservableUsers(sessions: any[]) {
        this._observableUsers = orderBy(sessions, ['startTimestamp'], ['desc']);
    }

    @computed
    get displayErrorMessage() {
        return this._displayErrorMessage;
    }

    @action
    setErrorMessage(value: boolean) {
        this._displayErrorMessage = value;
    }
}
