import React from 'react';
import {observer} from 'mobx-react';
import {MiniDashboardLoader} from '../../../../../../components/mini-dashboard-loader/mini-dashboard-loader';
import {MultipartyDashboardController} from '../../../../MultipartyDashboardController';
import {Video} from '../../../video/Video';

type MainProps = {
    controller: MultipartyDashboardController;
};

export const Main: React.FC<MainProps> = observer(({controller}) =>
    controller.showLoader ? (
        <div className='loader'>
            <MiniDashboardLoader textLoader={controller.translate('FACE_MEET.VIDEO.CONNECTING')} />
        </div>
    ) : (
        <Video />
    )
);
