import React from 'react';
import {observer} from 'mobx-react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {ILoginConfirmationController} from './controller';
import {ConfirmationModal} from '@techsee/techsee-ui-common/lib/modals/confirmation-modal';

import './styles.scss';

export interface LoginConfirmationProps {
    ctrl: ILoginConfirmationController;
    onConfirmLogin: () => void;
}

@observer
export class LoginConfirmation extends TechseeBaseComponent<LoginConfirmationProps> {
    renderInternal(): JSX.Element {
        const {ctrl, onConfirmLogin} = this.props;

        return (
            <ConfirmationModal
                contentTitle={ctrl.translate('REACT.LOGIN.CONFIRMATION_MODAL.USER_LOGGED_IN', {
                    username: ctrl.username
                })}
                content={ctrl.translate('REACT.LOGIN.CONFIRMATION_MODAL.DESCRIPTION')}
                negativeText={ctrl.translate('REACT.COMMON.BUTTONS.CANCEL')}
                positiveText={ctrl.translate('REACT.COMMON.BUTTONS.CONTINUE')}
                show={ctrl.isLoginConfirmVisible}
                onNegativeClick={ctrl.hide}
                onPositiveClick={onConfirmLogin}
            />
        );
    }
}
