import {configure} from 'mobx';
// @ts-ignore
import DashboardView from './multipartyDashboard.view.html';
import {MultipartyDashboardStores} from './MultipartyDashboardStores';
import '@techsee/techsee-ui-common/lib/_shared/icons.css';
import {getRootStore} from '../../app.bootstrap';
import {MediaServiceType} from '@techsee/techsee-media-service/lib/MediaConstants';

import './multipartyDashboard.scss';
import get from 'lodash/get';
import {getAppTracer} from '../../../app.tracer';
import {MultipartyDashboardRootComponent} from './MultipartyDashboardRootComponent';

const trace = getAppTracer('MultipartyDashboardModule');

//Configures MobX to allow changing state from actions only.
configure({
    enforceActions: 'observed'
});

declare const angular: any;

declare const ROUTE_BASE_PATH: string;

const reactDirectiveWrapper = [
    'reactDirective',
    (reactDirective: any) => reactDirective(MultipartyDashboardRootComponent)
];

const dashboardView = {
    template: DashboardView,
    controller: MultipartyDashboardStores,
    controllerAs: 'multipartyDashboardStores'
};

export default angular
    .module('states.multipartyDashboard', [])
    .config(['$stateProvider', config])
    .directive('dashboardRootComponent', reactDirectiveWrapper);

function config($stateProvider: any) {
    $stateProvider.state('multipartyDashboard', {
        url:
            ROUTE_BASE_PATH +
            'multipartyDashboard?room&roomCode&platform&audio&observe&r&p&c&cid&offline&loadImage&loadVideo&returnQuery&history&sf&sw&mini&emb',
        views: {
            'desktop-view': dashboardView,
            'mobile-view': dashboardView
        },
        resolve: {
            embeddedInfo: [
                'tsUrlUtils',
                (tsUrlUtils: any) => {
                    const browserUtilsService = getRootStore().browserUtilsService;

                    const isMiniDashboardSessionStorage = browserUtilsService.getFromSessionStorage('miniDashboard');
                    const isEmbeddedDashboardSessionStorage =
                        browserUtilsService.getFromSessionStorage('embeddedDashboard');

                    const isMiniDashboard = isMiniDashboardSessionStorage || tsUrlUtils.getParamValue('mini');
                    const isEmbeddedDashboard = isEmbeddedDashboardSessionStorage || tsUrlUtils.getParamValue('mini');

                    if (!isMiniDashboardSessionStorage && isMiniDashboard) {
                        browserUtilsService.saveToSessionStorage('miniDashboard', true);
                    }

                    if (!isEmbeddedDashboardSessionStorage && isEmbeddedDashboard) {
                        browserUtilsService.saveToSessionStorage('embeddedDashboard', true);
                    }

                    return {isMiniDashboard, isEmbeddedDashboard};
                }
            ],
            // Expose the current user data
            currentUser: [
                'tsStateHelper',
                'eventLog',
                'tsUrlUtils',
                'embeddedInfo',
                (tsStateHelper: any, eventLog: any, tsUrlUtils: any, embeddedInfo: any) => {
                    const roomHint = tsUrlUtils.getParamValue('room');
                    const {isMiniDashboard, isEmbeddedDashboard} = embeddedInfo;

                    eventLog.dashboardModuleLoaded({roomHint});

                    return (
                        getRootStore()
                            .dbUserService.find('current')
                            // If not logged-in, redirect to login
                            .catch((err: any) => {
                                trace.error(
                                    'Multiparty Dashboard.module failed - currentUser: ' +
                                        JSON.stringify({
                                            isMinidashboard: isMiniDashboard,
                                            isEmbeddedDashboard: isEmbeddedDashboard
                                        }),
                                    err
                                );

                                tsStateHelper.safeGo(isEmbeddedDashboard ? 'embeddedDashboardGeneralError' : 'login');

                                // Reject to prevent this state from loading
                                return Promise.reject(err);
                            })
                    );
                }
            ],
            // Expose the associated user account data, if he is associated
            accountData: [
                'currentUser',
                (currentUser: any) => {
                    if (!currentUser.accountId) {
                        return [];
                    }

                    return getRootStore().dbAccountService.find(currentUser.accountId);
                }
            ],
            verboseLogs: [
                'accountData',
                'tsExtraLoggingService',
                'eventLog',
                (accountData: any, tsExtraLoggingService: any, eventLog: any) => {
                    tsExtraLoggingService.init(accountData, eventLog);
                }
            ],
            roomData: [
                'tsStateHelper',
                'tsUrlUtils',
                'accountData',
                'eventLog',
                'embeddedInfo',
                (tsStateHelper: any, tsUrlUtils: any, accountData: any, eventLog: any, embeddedInfo: any) => {
                    const roomId = tsUrlUtils.getParamValue('room'),
                        byShortId = !accountData.protectedSettings.showRoomIdInUrl;
                    const {isMiniDashboard, isEmbeddedDashboard} = embeddedInfo;

                    return getRootStore()
                        .dbRoomsService.find(roomId, {params: {byShortId: byShortId}})
                        .then((room) => {
                            eventLog.room = get(room, '_id');

                            return room;
                        })
                        .catch((err: any) => {
                            trace.error(
                                'Multiparty Dashboard.module failed - roomData: ' +
                                    JSON.stringify({
                                        isMinidashboard: isMiniDashboard,
                                        isEmbeddedDashboard: isEmbeddedDashboard
                                    }),
                                err
                            );

                            tsStateHelper.safeGo(isEmbeddedDashboard ? 'embeddedDashboardGeneralError' : 'invite');

                            throw err;
                        });
                }
            ],
            mediaServiceType: ['roomData', (roomData: any) => get(roomData, 'mediaServiceType')],
            loadOpentok: [
                '$rootScope',
                'accountData',
                'mediaServiceType',
                ($rootScope: any, accountData: any, mediaServiceType: any) => {
                    if (mediaServiceType === MediaServiceType.OPENTOK && !getRootStore().environmentService.isIE11()) {
                        const opentokVersion = get(accountData, 'protectedSettings.opentokVersion');

                        return $rootScope.includeJs(`/js/opentok.${opentokVersion}.js`);
                    }

                    return null;
                }
            ],
            sharingService: [
                'tsSharingService',
                'roomData',
                (tsSharingService: any, roomData: any) => {
                    tsSharingService.init(roomData._id);

                    return tsSharingService;
                }
            ]
        }
    });
}
