'use strict';
import {INVITE_STARTED_FROM} from '@techsee/techsee-common/lib/constants/utils.constant';
import {getAuthService} from '@techsee/techsee-client-infra/lib';
import {EventLogService} from '@techsee/techsee-client-infra/lib/services/EventLogService';
import {EVENT_LEVELS, EVENT_SOURCES, LOG_EVENTS} from '@techsee/techsee-common/lib/constants/event-logs.constants';

export class QuickLauncherService {
    constructor(tsUrlConfig, tsUrlUtils, $localStorage) {
        'ngInject';

        const apiUrl = tsUrlConfig.get('API_URL');

        this.auth = getAuthService(apiUrl);
        this.tsUrlUtils = tsUrlUtils;
        this.$localStorage = $localStorage;
        this.eventLogService = new EventLogService(apiUrl);
    }

    ensureToken() {
        return new Promise((resolve) => {
            if (!this.startedFromLauncher) {
                return resolve();
            }

            const ott = this.tsUrlUtils.getParamValue('ott');

            if (!ott) {
                return resolve();
            }

            this.auth
                .redeemOneTimeToken(ott)
                .then((token) => {
                    if (token) {
                        this.$localStorage.auth = {
                            token
                        };

                        this.eventLogService.addEventLog(
                            LOG_EVENTS.QLAuthDashboardSuccessful,
                            undefined,
                            undefined,
                            undefined,
                            EVENT_LEVELS.info,
                            EVENT_SOURCES.dashboard,
                            {ott}
                        );
                    }

                    resolve();
                })
                .catch((err) => {
                    this.eventLogService.addEventLog(
                        LOG_EVENTS.QLAuthDashboardFailed.type,
                        undefined,
                        undefined,
                        undefined,
                        EVENT_LEVELS.warn,
                        EVENT_SOURCES.dashboard,
                        {error: err}
                    );

                    resolve();
                });
        });
    }

    get startedFromLauncher() {
        return this.tsUrlUtils.getParamValue('sf') === INVITE_STARTED_FROM.DESKTOP_LAUNCHER;
    }
}
