import React from 'react';
import {observer} from 'mobx-react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {IconColors, IconSizes} from '@techsee/techsee-ui-common/lib/icons/large-rounded-icon';
import {GuidenceProgressItem} from '../components/guidance-progress/GuidanceProgress';
import {ProgressList} from '../components/progress-list/ProgressList';
import {AgentGuidance} from '../components/agent-guidance/AgentGuidance';
import {InviteStepsLayout} from './_Shared';
import {InviteStepProps} from './_InviteGenericStep';
import {SimpleTabs, ITab} from '@techsee/techsee-ui-common/lib/tabs/simple';
import {InviteDeviceType} from '../../_contracts/InviteContracts';

@observer
export class ScreenShareGuidanceStep extends TechseeBaseComponent<InviteStepProps> {
    componentDidMount() {
        this.props.ctrl.setPagePlacement('invite-terms-user-screen-mirroring');
    }

    get progressItems(): GuidenceProgressItem[] {
        const {translate} = this.props.ctrl;

        return [
            {
                iconColor: IconColors.Blue,
                label: translate('REACT.INVITE.PERMISSIONS.SCREEN_SHARE.USER_PERMISSION'),
                isActive: true,
                icon: 'tos',
                isIconAnimated: false
            },
            {
                iconColor: IconColors.Blue,
                label: translate('REACT.INVITE.PERMISSIONS.SCREEN_SHARE.TITLE'),
                isActive: false,
                icon: 'screen',
                isIconAnimated: false,
                iconNoBorder: true,
                iconSize: IconSizes.XLarge
            }
        ];
    }

    getTab(device: InviteDeviceType) {
        const {progressItems} = this;
        const {translate} = this.props.ctrl;

        return () => (
            <div className={'guidance-progress row'}>
                <div className={'col-left col-sm-4'}>
                    <ProgressList progressList={progressItems} />
                </div>
                <div className={'col-left col-sm-4 mid-col'}>
                    <AgentGuidance
                        stepType={'screen-share-user'}
                        stepNum={1}
                        deviceType={device}
                        stepText={translate('REACT.INVITE.PERMISSIONS.SCREEN_SHARE.ANDROID_USER_PERMISSION')}
                    />
                </div>
                <div className={'col-left col-sm-4'}>&nbsp;</div>
            </div>
        );
    }

    renderInternal() {
        const {clientDeviceType, translate} = this.props.ctrl;
        const tabsList: ITab[] = [
            {
                title: clientDeviceType === InviteDeviceType.ios ? 'iOS' : 'Android',
                content: this.getTab(clientDeviceType)
            }
        ];

        return (
            <InviteStepsLayout
                className={'screen-share-user-step'}
                h1Text={translate('REACT.INVITE.PERMISSIONS.SCREEN_SHARE.TITLE')}
                h2Text={translate('REACT.INVITE.PERMISSIONS.SCREEN_SHARE.SUB_TITLE')}>
                <SimpleTabs tabsList={tabsList} uid={'screen-share-user-tabs'} />
            </InviteStepsLayout>
        );
    }
}
