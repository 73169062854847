import {IEventLogsService} from '../../services/EventsLogService';
import {ChangeNumberOrigin, IKeepCustomerDetailsConfig, InviteStepsEnum} from './_contracts/InviteContracts';
import {InviteMethodInfo} from '../../models/LiveSessionState';
import {EventLogConstant} from '../../../constants/events/event-log.constant';
import cloneDeep from 'lodash/cloneDeep';
//@ts-ignore
import {LOG_EVENTS} from '@techsee/techsee-common/lib/constants/event-logs.constants';
import {AddressTypesEnum} from '@techsee/techsee-common/lib/constants/room.constants';
// @ts-ignore
import {protectCustomerNumber, protectCustomerEmail} from '@techsee/techsee-common/lib/utils';

interface IRoomLogger {
    setRoomId(roomId: string): void;
}

export interface ISmsFlowLogger extends IRoomLogger {
    isSmsReceivedResponse(isRecieved: boolean): void;

    smsResolutionWarning(gateway: string, resolution: string, reason: string): void;

    smsResolutionInfo(gateway: string, resolution: string, reason?: string): void;

    phoneNumberValidationStarted(phoneNumber: string): void;

    phoneNumberValidatedSuccessfully(phoneNumber: string): void;

    phoneNumberValidationFailed(meta: any): void;

    phoneNumberValidatedUnexpectedError(meta: any): void;

    whatsappTimeoutError(phoneNumber: string): void;
}

export interface IInviteFlowLogger extends IRoomLogger {
    changeMethodModalOpen(origin: ChangeNumberOrigin): void;

    changeNumberModalCancel(): void;

    inviteMethodChangedByModal(from: InviteMethodInfo, to: InviteMethodInfo): void;

    inviteWizardStepChanged(to: InviteStepsEnum, from: InviteStepsEnum): void;

    redirectingToDashboard(): void;

    agentSentTosReprompt(): void;

    sendEmailSuccess(): void;

    dashboardInviteViewLoaded(meta: any): void;

    failedToReportDashboardInviteViewLoaded(meta: any): void;

    sendEmailFailed(meta: any): void;

    attemptToStartSession(): void;

    sessionStartedSuccessfully(): void;

    failedToStartSession(meta: any): void;
}

export interface ISessionServiceFlowLogger extends IRoomLogger {
    sessionCreated(): void;

    sessionCreationFailed(meta: any): void;

    createSessionRequest(meta: any): void;

    joinSessionRoom(meta: any): void;

    successfullyJoinedSessionRoom(meta: any): void;

    joinSessionRoomFailed(meta: any): void;

    endSessionFailed(meta: any): void;
}

export class InviteEventsLogger implements IRoomLogger, IInviteFlowLogger, ISmsFlowLogger {
    private readonly _eventLog: IEventLogsService;

    private readonly _accountId: string;

    private readonly _agentId: string;

    private readonly _keepCustomerDetailsConfig: IKeepCustomerDetailsConfig;

    private roomId: string;

    constructor(
        logger: IEventLogsService,
        accountId: string,
        agentId: string,
        keepCustomerDetailsConfig: IKeepCustomerDetailsConfig
    ) {
        this._eventLog = logger;
        this._accountId = accountId;
        this._agentId = agentId;
        this._keepCustomerDetailsConfig = keepCustomerDetailsConfig;

        this.roomId = '';
        this._eventLog.room = '';
    }

    //#region IRoomLogger

    setRoomId(roomId: string) {
        this.roomId = roomId;
        this._eventLog.room = this.roomId;
    }

    //#endregion

    //#region ISmsFlowLogger

    smsResolutionWarning(gateway: string, resolution: string, reason: string) {
        this._eventLog.warn(this.getLogParams(LOG_EVENTS.inviteResolution, {gateway, reason, resolution}));
    }

    smsResolutionInfo(gateway: string, resolution: string, reason?: string) {
        this._eventLog.info(this.getLogParams(LOG_EVENTS.inviteResolution, {gateway, reason, resolution}));
    }

    isSmsReceivedResponse(isSmsReceived: boolean) {
        this._eventLog.info(this.getLogParams(LOG_EVENTS.smsReceivedReply, isSmsReceived));
    }

    phoneNumberValidationStarted(phoneNumber: string) {
        const p = this.maskPhoneNumber(phoneNumber);

        this._eventLog.debug(this.getLogParams(LOG_EVENTS.phoneNumberValidationStarted, {p}));
    }

    phoneNumberValidatedSuccessfully(phoneNumber: string) {
        const p = this.maskPhoneNumber(phoneNumber);

        this._eventLog.debug(this.getLogParams(LOG_EVENTS.phoneNumberValidatedSuccessfully, {p}));
    }

    phoneNumberValidatedUnexpectedError(meta: any) {
        if (meta.phoneNumber) {
            meta.phoneNumber = this.maskPhoneNumber(meta.phoneNumber);
        }

        this._eventLog.error(this.getLogParams(LOG_EVENTS.phoneNumberValidatedUnexpectedError, meta));
    }

    phoneNumberValidationFailed(meta: any) {
        if (meta.phoneNumber) {
            meta.phoneNumber = this.maskPhoneNumber(meta.phoneNumber);
        }

        this._eventLog.error(this.getLogParams(LOG_EVENTS.phoneNumberValidationFailed, meta));
    }

    whatsappTimeoutError(phoneNumber: string) {
        const p = this.maskPhoneNumber(phoneNumber);

        this._eventLog.error(this.getLogParams(LOG_EVENTS.whatsappTimeoutError.type, {p}));
    }

    sendEmailSuccess() {
        this._eventLog.info(this.getLogParams(LOG_EVENTS.sendEmailSuccess));
    }

    sendEmailFailed(meta: any) {
        this._eventLog.error(this.getLogParams(LOG_EVENTS.sendEmailFailed, meta));
    }

    attemptToStartSession() {
        this._eventLog.debug(this.getLogParams(LOG_EVENTS.attemptToStartSession));
    }

    sessionStartedSuccessfully() {
        this._eventLog.debug(this.getLogParams(LOG_EVENTS.sessionStartedSuccessfully));
    }

    failedToStartSession(meta: any) {
        this._eventLog.error(this.getLogParams(LOG_EVENTS.failedToStartSession, meta));
    }

    //#endregion

    //#region ISessionServiceFlowLogger

    createSessionRequest(meta: any) {
        const logMeta = cloneDeep(meta);

        if (meta.customerNumber) {
            logMeta.customerNumber = this.maskPhoneNumber(meta.customerNumber);
        }

        if (meta.customerEmail) {
            logMeta.customerEmail = this.maskEmail(meta.customerEmail);
        }

        this._eventLog.debug(this.getLogParams(LOG_EVENTS.createSessionRequest, logMeta));
    }

    sessionCreated() {
        this._eventLog.debug(this.getLogParams(LOG_EVENTS.sessionCreated));
    }

    sessionCreationFailed(meta: any) {
        this._eventLog.error(this.getLogParams(LOG_EVENTS.sessionCreationFailed, meta));
    }

    joinSessionRoom(meta: any) {
        this._eventLog.debug(this.getLogParams(LOG_EVENTS.joinSessionRoom, meta));
    }

    successfullyJoinedSessionRoom(meta: any) {
        this._eventLog.debug(this.getLogParams(LOG_EVENTS.successfullyJoinedSessionRoom, meta));
    }

    joinSessionRoomFailed(meta: any) {
        this._eventLog.error(this.getLogParams(LOG_EVENTS.joinSessionRoomFailed, meta));
    }

    endSessionFailed(meta: any) {
        this._eventLog.error(this.getLogParams(LOG_EVENTS.endSessionFailed, meta));
    }

    //#endregion

    //#region IInviteFlowLogger

    dashboardInviteViewLoaded(meta: any) {
        this._eventLog.info(this.getLogParams(LOG_EVENTS.dashboardInviteViewLoaded, meta));
    }

    failedToReportDashboardInviteViewLoaded(meta: any) {
        this._eventLog.error(this.getLogParams(LOG_EVENTS.failedToReportdashboardInviteViewLoaded, meta));
    }

    changeMethodModalOpen(origin: ChangeNumberOrigin) {
        this._eventLog.info(this.getLogParams(LOG_EVENTS.inviteInfoModalOpen, {origin}));
    }

    changeNumberModalCancel() {
        this._eventLog.info(this.getLogParams(LOG_EVENTS.inviteInfoModalCanceled));
    }

    inviteMethodChangedByModal(from: InviteMethodInfo, to: InviteMethodInfo) {
        if (this.roomId) {
            this.protectCustomerDetails(from, this._keepCustomerDetailsConfig);
            this.protectCustomerDetails(to, this._keepCustomerDetailsConfig);

            delete from.phoneNumber;
            delete from.countryCode;
            delete to.phoneNumber;
            delete to.countryCode;

            this._eventLog.info(this.getLogParams(LOG_EVENTS.inviteInfoChanged, {from, to}));
        }
    }

    inviteWizardStepChanged(to: InviteStepsEnum, from: InviteStepsEnum) {
        this._eventLog.info(this.getLogParams(LOG_EVENTS.wizardStepChanged, {from, to}));
    }

    redirectingToDashboard() {
        this._eventLog.info(
            this.getLogParams(LOG_EVENTS.inviteResolution, {
                resolution: EventLogConstant.smsResolutionTypes.moveToDashboard
            })
        );
    }

    agentSentTosReprompt() {
        this._eventLog.info(this.getLogParams(LOG_EVENTS.tosRepromptSent));
    }

    //#endregion

    private protectCustomerDetails(
        methodInfo: InviteMethodInfo,
        keepCustomerDetailsConfig: IKeepCustomerDetailsConfig
    ) {
        if (
            methodInfo &&
            methodInfo.addressType === AddressTypesEnum.SMS &&
            !keepCustomerDetailsConfig.keepCustomerMobile
        ) {
            methodInfo.address = methodInfo.address && protectCustomerNumber(methodInfo.address);
        }

        if (
            methodInfo &&
            methodInfo.addressType === AddressTypesEnum.EMAIL &&
            !keepCustomerDetailsConfig.keepCustomerEmailAddress
        ) {
            methodInfo.address = methodInfo.address && protectCustomerEmail(methodInfo.address);
        }
    }

    private maskPhoneNumber(number: string) {
        if (!this._keepCustomerDetailsConfig.keepCustomerMobile) {
            return protectCustomerNumber(number);
        }

        return number;
    }

    private maskEmail(email: string) {
        if (!this._keepCustomerDetailsConfig.keepCustomerEmailAddress) {
            return protectCustomerEmail(email);
        }

        return email;
    }

    private getLogParams(logType: string, meta?: any) {
        const logParams: any = {
            logType: logType,
            accountId: this._accountId,
            userId: this._agentId,
            roomId: this.roomId,
            meta: meta
        };

        if (!this.roomId) {
            delete logParams.roomId;
        }

        return logParams;
    }
}
