'use strict';

import './notified-message.style.scss';

export class NotifiedMessageController {
    constructor($modalInstance, message, isErrorMsg) {
        'ngInject';
        this.message = message;
        this.$modalInstance = $modalInstance;
        this.isErrorMsg = isErrorMsg;
    }

    close() {
        this.$modalInstance.close();
    }
}
