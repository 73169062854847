/* eslint-disable max-params */
'use strict';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import {MIN_LOAD_TIME} from './login.settings.js';
import {LoginConfirmationController} from '../../../_react_/states/login-new/components/login-confirmation/controller';
import {getRootStore} from '../../../_react_/app.bootstrap';

export class LoginController {
    constructor(
        $scope,
        $state,
        tsStateHelper,
        $timeout,
        db,
        auth,
        $translate,
        tsUrlUtils,
        tsLocaleHelper,
        $localStorage,
        tsUrlConfig,
        $window
    ) {
        'ngInject';

        this.$scope = $scope;
        this.$state = $state;
        this.stateHelper = tsStateHelper;
        this.$timeout = $timeout;
        this.db = db;
        this.auth = auth;
        this.$translate = $translate;
        this.tsUrlUtils = tsUrlUtils;
        this.dir = tsLocaleHelper.isRTL() ? 'rtl' : 'ltr';
        this.waiting = false;
        this.urlConfig = tsUrlConfig;
        this.selectedBackend = tsUrlConfig.getBackend().subDomain;
        this.$window = $window;
        this.$localStorage = $localStorage;
        this.browserUtilsService = getRootStore().browserUtilsService;
        this.environmentDetect = getRootStore().environmentService;
        this.isFFOrIE11 = this.environmentDetect.isIE11() || this.environmentDetect.isFF();
        this.currentUser = get($state.params, 'multiUser');
        this.isMultiAccountsUser = this.currentUser && !this.currentUser.accountId;
        this.accounts = get($state.params, 'multiUser.accounts');
        this.isShowPassword = false;
        this.isLoginError = false;

        if (!isEmpty(this.accounts)) {
            this.accounts[0].selected = true;
        }

        this.confirmLogin = this.confirmLogin.bind(this);

        getRootStore()
            .localizationService.init()
            .then(() => {
                const translate = getRootStore().localizationService.translate;

                this.confirmationCtrl = new LoginConfirmationController(translate);
            });

        // Please login to your account
        this.$translate('LOGIN.CONTROLLER.LOGIN_TO_YOUR_ACCOUNT').then((message) => (this.message = message));
    }

    _handleLoginError(err, reqTime) {
        this.isLoginError = true;
        const sleep = Math.max(0, MIN_LOAD_TIME - (Date.now() - reqTime));

        this.$timeout(() => {
            this.waiting = false;

            if (err.status === 401) {
                // Login failed, wrong userName or password<br>Please try again
                this.$translate('LOGIN.CONTROLLER.PLEASE_LOGIN').then((message) => (this.message = message));

                this.isContinueClicked = false;

                return;
            }

            if (err.status === 403 && err.data.confirmLogin) {
                this.confirmationCtrl.setUserName(this.$scope.loginForm.userName);
                this.confirmationCtrl.show();
                this.isContinueClicked = false;

                return;
            }

            if (err.status === 403) {
                this.$translate('LOGIN.CONTROLLER.NOT_ALLOWED').then((message) => (this.message = message));

                this.isContinueClicked = false;

                return;
            }

            if (err.status === 429) {
                this.$translate('LOGIN.CONTROLLER.TOO_MANY_LOGINS').then((message) => (this.message = message));

                this.isContinueClicked = false;

                return;
            }

            if (err.status === 500 || err.status === 502 || err.status === -1) {
                this.$translate('LOGIN.CONTROLLER.BAD_GATEWAY').then((message) => (this.message = message));

                this.isContinueClicked = false;
            }
        }, sleep);
    }

    _loginToSubUser(form) {
        this.selectedAccount = form.selectedMultiAccount ? form.selectedMultiAccount : this.accounts[0]._id;
        const reqTime = Date.now();

        return this.db.Auth.loginSubUser({
            data: {
                accountId: this.selectedAccount
            }
        })
            .then((token) => {
                this.auth.setAuthToken(token.data);

                return this.stateHelper.safeGo('invite');
            })
            .catch((err) => {
                this._handleLoginError(err, reqTime);
            });
    }

    handleEyeClick() {
        this.isShowPassword = !this.isShowPassword;
    }

    confirmLogin() {
        return this.submit(this.$scope.loginForm, true);
    }

    submit(form, confirmLogin) {
        if (this.waiting) {
            return false;
        }

        document.body.tabIndex = -1;
        document.body.focus();

        this.waiting = true;

        if (this.isMultiAccountsUser) {
            this.isContinueClicked = true;
        }

        if (this.currentUser && !this.currentUser.accountId) {
            return this._loginToSubUser(form);
        }

        if (!form.userName || !form.password) {
            // Please enter userName and password to continue
            this.isLoginError = true;
            this.$timeout(() => {
                this.waiting = false;

                this.$translate('LOGIN.CONTROLLER.ENTER_EMAIL_OR_PASSWORD').then((message) => (this.message = message));
            }, MIN_LOAD_TIME);

            this.isContinueClicked = false;

            return false;
        }

        const reqTime = Date.now();

        return this.auth
            .login(form.userName, form.password, confirmLogin)
            .then(() => {
                this.confirmationCtrl.hide();

                const {r, p, roomId, g, roomCode, audio, observe, emb, mini, authId} = this.$state.params;

                if (!roomId && !g && !roomCode) {
                    this.browserUtilsService.saveToLocalStorage('checkForcePasswordChange', true);
                }

                return this.stateHelper.safeGo('invite', {
                    r,
                    p,
                    roomId,
                    g,
                    roomCode,
                    audio,
                    observe,
                    emb,
                    mini,
                    authId
                });
            })
            .catch((err) => {
                this._handleLoginError(err, reqTime);
            });
    }

    selectBackend(input) {
        const subDomain = (input || '').toLowerCase();

        this.urlConfig.setBackend(subDomain);

        this.backendConfigVisible = false;
        this.$timeout(() => {
            this.$window.location.reload(true);
        }, 300);
    }
}
