// @ts-ignore
import SessionEndedStateView from './session-ended.view.html';
import {SessionEndedController, ISessionEndedController} from '../../components/session-ended/controller';
import {getRootStore} from '../../app.bootstrap';

class SessionEndedStateController {
    private sessionEndedController: ISessionEndedController | undefined;

    constructor() {
        this.init();
    }

    init() {
        const translate = getRootStore().localizationService.translate;

        this.sessionEndedController = new SessionEndedController(translate);
    }
}

declare const angular: any;

declare const ROUTE_BASE_PATH: string;

export default angular
    .module('states.sessionEnded', [])
    .config(['$stateProvider', config])
    .controller('SessionEndedStateController', SessionEndedStateController);

const view = {
    template: SessionEndedStateView,
    controller: 'SessionEndedStateController',
    controllerAs: 'sessionEnded'
};

function config($stateProvider: any) {
    $stateProvider.state('sessionEnded', {
        url: ROUTE_BASE_PATH + 'sessionEnded',
        views: {
            'desktop-view': view,
            'mobile-view': view
        }
    });
}
