import {EventEmitter} from 'events';
import {SmartService, ISmartService} from '@techsee/techsee-client-infra/lib/services/SmartService';
// @ts-ignore
import {getBackendUrl} from '@techsee/techsee-common/lib/utils';
// @ts-ignore
import {features, measurements} from '@techsee/techsee-common/lib/constants/smart.constants';
import {LOG_EVENTS} from '@techsee/techsee-common/lib/constants/event-logs.constants';
import {IEventLogsService} from '../../_react_/services/EventsLogService';
import {CoinNames} from '@techsee/techsee-common/lib/constants/measurements.constants';
import debounce from 'lodash/debounce';

export interface ICoodrinates {
    x: number;
    y: number;
}

export interface IMarker extends ICoodrinates {
    active: boolean;
}

export enum MeasureEvents {
    STEP_CHANGED = 'STEP_CHANGED',
    MEASURE_OBJECT_CHANGED = 'MEASURE_OBJECT_CHANGED'
}

export interface IStartMeasureArgs {
    canvas: any;
    imageBlob: string;
    accountId: string;
    roomId: string;
    userId: string;
    measureObject: string;
}

export interface IMeasureService {
    readonly processStep: number;
    readonly measureObject: any;
    readonly isActive: boolean;

    start(canvas: any, imageBlob: string): void;
    stop(): void;

    setToolbarMeasureStates(activate: () => void, deactivate: () => void): void;

    onStepChanged: (callback: (...args: any) => void) => void;
    onMeasureObjectChanged: (callback: (...args: any) => void) => void;
}

// It's a distance between the center of the marker
// and the bottom point of the marker
export const MARKER_OFFSET = 25;

export class MeasureService extends EventEmitter implements IMeasureService {
    private _processStep: number = 0;
    private _isActive: boolean = false;
    private _coinCoordinates: ICoodrinates = {
        x: 0,
        y: 0
    };
    private _markerCoords: {
        marker1: IMarker;
        marker2: IMarker;
    } = {
        marker1: {x: 0, y: 0, active: false},
        marker2: {x: 0, y: 0, active: false}
    };

    private _savedCoords: {
        marker1: IMarker;
        marker2: IMarker;
        coin: ICoodrinates;
    } | null = null;

    private _closeEnough: number = 10;
    private _ctx: any = null;
    private _canvas?: HTMLCanvasElement;
    private _canvasObject?: any;
    private _ratio: number = 1;
    private _smartService: ISmartService;
    private _imageBlob: string = '';
    private _accountId: string = '';
    private _roomId: string = '';
    private _userId: string = '';
    private _measureObject: string = '';
    private _onStepChangedCallback = () => {};
    private _isBusy: boolean = false;
    private _spinner: {i: number; d: number; frameId: null | number} = {
        i: 0,
        d: 0,
        frameId: null
    };
    private _originalImageOptions: {
        scaleX: number;
        scaleY: number;
        w: number;
        h: number;
    } = {
        scaleX: 1,
        scaleY: 1,
        w: 1,
        h: 1
    };
    private _floatingToolbarMeasureHandlers: {activate: () => void; deactivate: () => void} | null = null;
    private _initialCanvasSize: {w: number; h: number} | null = null;
    private _resizeScale: ICoodrinates = {x: 1, y: 1};
    constructor(private _eventLogService: IEventLogsService) {
        super();

        const apiUrl = getBackendUrl(API_URL, {hostname: window.location.hostname, ENV: {}});

        this._smartService = new SmartService(apiUrl, this.getToken());

        this.mouseMoveListener = this.mouseMoveListener.bind(this);
        this.mouseDownListener = this.mouseDownListener.bind(this);
        this.mouseUpListener = this.mouseUpListener.bind(this);
        this.windowResizeListener = debounce(this.windowResizeListener.bind(this), 300);
    }

    get processStep() {
        return this._processStep;
    }

    get measureObject() {
        return this._measureObject;
    }

    get isActive() {
        return this._isActive;
    }

    setToolbarMeasureStates(activate: () => void, deactivate: () => void) {
        this._floatingToolbarMeasureHandlers = {activate, deactivate};
    }

    onStepChanged(callback: (...args: any) => void) {
        this.on(MeasureEvents.STEP_CHANGED, callback);
    }

    onMeasureObjectChanged(callback: (...args: any) => void) {
        this.on(MeasureEvents.MEASURE_OBJECT_CHANGED, callback);
    }

    start(options: IStartMeasureArgs) {
        this._canvas = options.canvas.upperCanvasEl;
        this._imageBlob = options.imageBlob;
        this._accountId = options.accountId;
        this._roomId = options.roomId;
        this._userId = options.userId;
        this._measureObject = options.measureObject;

        this.emit(MeasureEvents.MEASURE_OBJECT_CHANGED, {
            key: this._measureObject,
            // @ts-ignore
            name: CoinNames[this._measureObject]
        });

        if (!this._canvas) {
            throw new Error('Measurement can not be started: canvas is not ready');
        }

        this._isActive = true;

        this._eventLogService.info(this.getLogParams(LOG_EVENTS.measurementStarted));

        this._ctx = this._canvas.getContext('2d');
        this._canvasObject = this._canvas;
        this.setProcessStep(0);

        // setting up initial marker coordinates:
        // 25% from left side of the canvas, 25% from right side of the canvas
        // 50% from bottom and top
        this._markerCoords.marker1.x = this._canvas.width / 4;
        this._markerCoords.marker1.y = this._canvas.height / 2;
        this._markerCoords.marker2.x = this._canvas.width - this._canvas.width / 4;
        this._markerCoords.marker2.y = this._canvas.height / 2;

        this._initialCanvasSize = {
            w: this._canvas.width,
            h: this._canvas.height
        };

        // Calling external callback if they are exists
        if (this._floatingToolbarMeasureHandlers) {
            this._floatingToolbarMeasureHandlers.activate();
        }

        this._canvas.addEventListener('mousemove', this.mouseMoveListener);
        this._canvas.addEventListener('mousedown', this.mouseDownListener);
        this._canvas.addEventListener('mouseup', this.mouseUpListener);
        window.addEventListener('resize', this.windowResizeListener);

        // Clearing the canvas
        this.clear();
    }

    stop() {
        if (this._isActive) {
            this._eventLogService.info(this.getLogParams(LOG_EVENTS.measurementStopped));
        }

        this._isActive = false;
        this.clear();
        this.setBusy(false);
        this.setProcessStep(0);

        if (this._floatingToolbarMeasureHandlers) {
            this._floatingToolbarMeasureHandlers.deactivate();
        }

        if (this._canvas) {
            this._canvas.removeEventListener('mousemove', this.mouseMoveListener);
            this._canvas.removeEventListener('mousedown', this.mouseDownListener);
            this._canvas.removeEventListener('mouseup', this.mouseUpListener);
            window.removeEventListener('resize', this.windowResizeListener);
        }
    }

    private setCoinCoordinates(coords: ICoodrinates) {
        this._coinCoordinates = coords;

        this.saveCoords();
        this.drawTarget(this._coinCoordinates.x, this._coinCoordinates.y);

        this.setBusy(true);
        this.getImageOriginalSize(this._imageBlob)
            .then((img) => {
                if (this._canvas) {
                    this.setOriginalImageOptions(img);

                    // Real coin coordinates depends on mouse absolute position and
                    // relationship between current canvas size and original image size
                    const coinCoords = [
                        Math.floor(this._coinCoordinates.x * this._originalImageOptions.scaleX),
                        Math.floor(this._coinCoordinates.y * this._originalImageOptions.scaleY)
                    ];

                    // Request to AI to get a ratio
                    return this._smartService
                        .analyze(
                            features.measurements,
                            // @ts-ignore
                            [measurements.coin, coinCoords, this._measureObject],
                            this._roomId,
                            this._imageBlob,
                            this._accountId
                        )
                        .then((resp: any) => {
                            if (resp.result.isSuccess) {
                                this.setBusy(false);
                                this._ratio = resp.result.analysisResults[0].ratio;

                                this._eventLogService.info(
                                    this.getLogParams(LOG_EVENTS.measurementSuccess, {ratio: this._ratio})
                                );

                                this.setProcessStep(1);
                                this.clear();
                                this.drawMeasurement();
                            } else {
                                throw new Error(resp.result.errorMessage);
                            }
                        });
                }

                throw new Error('Canvas is not ready');
            })
            .catch((error) => {
                console.warn(error);

                this._eventLogService.error(this.getLogParams(LOG_EVENTS.measurementFailed, {error}));

                this.stop();
            });
    }

    private setOriginalImageOptions(img: any) {
        if (this._canvas) {
            // Calculation the relationship between original image size and canvas size
            const scaleX = img.w / this._canvas.width;
            const scaleY = img.h / this._canvas.height;

            this._originalImageOptions = {
                scaleX,
                scaleY,
                w: img.w,
                h: img.h
            };
        }
    }

    private updateOriginalImageOptions() {
        if (this._canvas) {
            this._originalImageOptions.scaleX = this._originalImageOptions.w / this._canvas.width;
            this._originalImageOptions.scaleY = this._originalImageOptions.h / this._canvas.height;
        }
    }

    private windowResizeListener() {
        if (this._initialCanvasSize && this._canvas && this._savedCoords) {
            // Calculation of resize scale based on current canvas size and canvas size
            // before the resizing.

            this._resizeScale.x = this._canvas.width / this._initialCanvasSize.w;
            this._resizeScale.y = this._canvas.height / this._initialCanvasSize.h;

            // Applying resize scale to all coordinates
            this._coinCoordinates.x = this._savedCoords.coin.x * this._resizeScale.x;
            this._coinCoordinates.y = this._savedCoords.coin.y * this._resizeScale.y;

            this._markerCoords.marker1.x = this._savedCoords.marker1.x * this._resizeScale.x;
            this._markerCoords.marker1.y = this._savedCoords.marker1.y * this._resizeScale.y;

            this._markerCoords.marker2.x = this._savedCoords.marker2.x * this._resizeScale.x;
            this._markerCoords.marker2.y = this._savedCoords.marker2.y * this._resizeScale.y;

            this.clear();
            this.drawMeasurement();
        }
    }

    private saveCoords() {
        this._savedCoords = {
            marker1: {...this._markerCoords.marker1},
            marker2: {...this._markerCoords.marker2},
            coin: {...this._coinCoordinates}
        };

        this.resetResizeScale();
    }

    private resetResizeScale() {
        if (this._canvas) {
            this._initialCanvasSize = {
                w: this._canvas.width,
                h: this._canvas.height
            };
        }

        this._resizeScale.x = 1;
        this._resizeScale.y = 1;
    }

    private setProcessStep(step: number) {
        this._processStep = step;
        this.emit(MeasureEvents.STEP_CHANGED, this._processStep);
    }

    private getImageOriginalSize(src: string): Promise<{w: number; h: number}> {
        if (!this._imageBlob) {
            return Promise.reject('Image was not taken');
        }

        return new Promise((resolve, reject) => {
            var img = new Image();

            img.onload = () => resolve({w: img.width, h: img.height});
            img.onerror = () => reject('Image load failed');

            img.src = src;
        });
    }

    private mouseMoveListener(e: MouseEvent) {
        const {x: mouseX, y: mouseY} = this.getMousePos(e);

        if (this._markerCoords.marker1.active) {
            this._markerCoords.marker1.x = mouseX;
            // Actual marker coordinate is lower on MARKER_OFFSET px then actual mouseY
            this._markerCoords.marker1.y = mouseY + MARKER_OFFSET;

            this.saveCoords();
        } else if (this._markerCoords.marker2.active) {
            this._markerCoords.marker2.x = mouseX;
            // Actual marker coordinate is lower on MARKER_OFFSET px then actual mouseY
            this._markerCoords.marker2.y = mouseY + MARKER_OFFSET;

            this.saveCoords();
        }

        if (this._processStep === 1) {
            this.clear();
            this.drawMeasurement();
        }

        this.drawCursor(mouseX, mouseY);
    }

    private mouseDownListener(e: MouseEvent) {
        const {x: mouseX, y: mouseY} = this.getMousePos(e);

        this.drawCursor(mouseX, mouseY);

        if (this._isBusy) {
            return;
        }

        if (this._processStep === 0) {
            this.setCoinCoordinates({
                x: mouseX,
                y: mouseY
            });
        }

        if (this._processStep === 1) {
            this._markerCoords.marker1.active =
                this.checkCloseEnough(mouseX, this._markerCoords.marker1.x) &&
                this.checkCloseEnough(mouseY, this._markerCoords.marker1.y - MARKER_OFFSET);

            this._markerCoords.marker2.active =
                this.checkCloseEnough(mouseX, this._markerCoords.marker2.x) &&
                this.checkCloseEnough(mouseY, this._markerCoords.marker2.y - MARKER_OFFSET);

            this.clear();
            this.drawMeasurement();
        }
    }

    private mouseUpListener() {
        if (this._processStep === 1) {
            this._markerCoords.marker1.active = false;
            this._markerCoords.marker2.active = false;
        }
    }

    private drawCursor(mouseX: number, mouseY: number) {
        if (!this._canvas) {
            return;
        }

        if (this.processStep === 0) {
            this._canvas.style.cursor = 'crosshair';

            return;
        }

        if (
            this.checkCloseEnough(mouseX, this._markerCoords.marker1.x) &&
            this.checkCloseEnough(mouseY, this._markerCoords.marker1.y - MARKER_OFFSET)
        ) {
            this._canvas.style.cursor = 'pointer';
        } else if (
            this.checkCloseEnough(mouseX, this._markerCoords.marker2.x) &&
            this.checkCloseEnough(mouseY, this._markerCoords.marker2.y - MARKER_OFFSET)
        ) {
            this._canvas.style.cursor = 'pointer';
        } else {
            this._canvas.style.cursor = 'default';
        }
    }

    private getCanvasSize() {
        if (!this._canvas) {
            return;
        }

        const rect = this._canvas.getBoundingClientRect(); // abs. size of element
        const scaleX = this._canvas.width / rect.width; // relationship bitmap vs. element for X
        const scaleY = this._canvas.height / rect.height; // relationship bitmap vs. element for Y

        return {
            rect,
            scaleX,
            scaleY
        };
    }

    private getMousePos(evt: MouseEvent) {
        const canvasSize = this.getCanvasSize();

        if (!this._canvas || !canvasSize) {
            return {x: 0, y: 0};
        }

        return {
            x: (evt.clientX - canvasSize.rect.left) * canvasSize.scaleX, // scale mouse coordinates after they have
            y: (evt.clientY - canvasSize.rect.top) * canvasSize.scaleY // been adjusted to be relative to element
        };
    }

    private drawMeasurement() {
        this.drawTarget(this._coinCoordinates.x, this._coinCoordinates.y);
        this.drawConnectingLine();
        this.drawMarker(this._markerCoords.marker1.x, this._markerCoords.marker1.y);
        this.drawMarker(this._markerCoords.marker2.x, this._markerCoords.marker2.y);
        this.drawDistance();
    }

    private clear() {
        if (this._canvas) {
            this._ctx.clearRect(0, 0, this._canvas.width, this._canvas.height);
        }
    }

    private checkCloseEnough(p1: number, p2: number, closeEnough = this._closeEnough): boolean {
        // Checking each coordinate error
        return Math.abs(p1 - p2) < closeEnough;
    }

    private getMiddleBetweenMarkers(): ICoodrinates {
        // Calculation center point bwtween two markers
        return {
            x: (this._markerCoords.marker2.x + this._markerCoords.marker1.x) / 2,
            y: (this._markerCoords.marker2.y + this._markerCoords.marker1.y) / 2
        };
    }

    private getRotationAngle(): number {
        // Calculation an angle between line that based on markers points and X axis
        return Math.atan2(
            this._markerCoords.marker2.y - this._markerCoords.marker1.y,
            this._markerCoords.marker2.x - this._markerCoords.marker1.x
        );
    }

    private getDistanceBetweenPoints() {
        if (!this._originalImageOptions || !this._resizeScale) {
            return 0;
        }

        this.updateOriginalImageOptions();

        const {scaleX, scaleY} = this._originalImageOptions;
        const {marker1, marker2} = this._markerCoords;

        // Calculation distance between two pointes in PIXESL.
        // Multiplying it to ratio got from AI will provide us result in CM.
        return (
            Math.sqrt(((marker1.x - marker2.x) * scaleX) ** 2 + ((marker1.y - marker2.y) * scaleY) ** 2) * this._ratio
        ).toFixed(1);
    }

    private getToken(): string {
        try {
            return JSON.parse(localStorage.getItem('ngStorage-auth') || '').token;
        } catch (e) {
            return '';
        }
    }

    private setBusy(state: boolean = false) {
        this._isBusy = state;
    }

    private drawConnectingLine() {
        this._ctx.beginPath();
        this._ctx.strokeStyle = '#000';
        this._ctx.moveTo(this._markerCoords.marker1.x, this._markerCoords.marker1.y);
        this._ctx.lineTo(this._markerCoords.marker2.x, this._markerCoords.marker2.y);
        this._ctx.lineWidth = 2;
        this._ctx.setLineDash([6]);
        this._ctx.stroke();

        this._ctx.beginPath();
        this._ctx.strokeStyle = '#fff';
        this._ctx.moveTo(this._markerCoords.marker1.x, this._markerCoords.marker1.y);
        this._ctx.lineTo(this._markerCoords.marker2.x, this._markerCoords.marker2.y);
        this._ctx.lineWidth = 2;
        this._ctx.setLineDash([3]);
        this._ctx.stroke();
    }

    private drawMarker(x: number = 100, y: number = 100) {
        this._ctx.beginPath();
        this._ctx.setLineDash([0]);
        this._ctx.strokeStyle = '#489de9';
        this._ctx.arc(x, y - MARKER_OFFSET, 8, 0, 2 * Math.PI);
        this._ctx.lineWidth = 5;
        this._ctx.stroke();

        this._ctx.moveTo(x, y);
        this._ctx.lineTo(x, y - MARKER_OFFSET + 10);
        this._ctx.lineWidth = 3;
        this._ctx.stroke();
    }

    private drawRoundRectWithText(x: number, y: number, width: number, height: number, angle: number, text: string) {
        this._ctx.font = '14px Arial';

        const padding = 18;
        const borderRadius = 2;
        const boxWidth = this._ctx.measureText(text).width + padding;

        const s = {
            x: x - boxWidth / 2,
            y: y - 10
        };

        this._ctx.save();
        this._ctx.beginPath();
        this._ctx.translate(s.x + boxWidth / 2, s.y + height / 2);
        this._ctx.rotate(angle);

        this.roundRect(-boxWidth / 2, 10, boxWidth, height, borderRadius);

        this._ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
        this._ctx.fill();

        this._ctx.fillStyle = '#ffffff';
        this._ctx.textAlign = 'center';
        this._ctx.textBaseline = 'middle';

        let textY = 21;

        // Prevening fliping text in specific angles
        if (angle < -Math.PI / 2 || angle > Math.PI / 2) {
            this._ctx.rotate(Math.PI);
            textY = -19;
        }

        this._ctx.fillText(text, 0, textY);

        this._ctx.closePath();
        this._ctx.restore();
    }

    private roundRect(x: number, y: number, width: number, height: number, radius: number) {
        this._ctx.beginPath();
        this._ctx.moveTo(x + radius, y);
        this._ctx.arcTo(x + width, y, x + width, y + height, radius);
        this._ctx.arcTo(x + width, y + height, x, y + height, radius);
        this._ctx.arcTo(x, y + height, x, y, radius);
        this._ctx.arcTo(x, y, x + width, y, radius);
        this._ctx.closePath();
    }

    private drawDistance() {
        const middleCoords = this.getMiddleBetweenMarkers();
        const angle = this.getRotationAngle();
        const distance = this.getDistanceBetweenPoints();

        const rectSize = {
            w: 80,
            h: 20
        };

        const rectOptions = {
            ...rectSize,
            angle,
            x: middleCoords.x,
            y: middleCoords.y
        };

        this.drawRoundRectWithText(
            rectOptions.x,
            rectOptions.y,
            rectOptions.w,
            rectOptions.h,
            rectOptions.angle,
            `${distance} cm`
        );
    }

    private drawTarget(x: number, y: number) {
        this._ctx.beginPath();
        this._ctx.setLineDash([0]);
        this._ctx.strokeStyle = '#fff';
        this._ctx.arc(x, y, 14, 0, 2 * Math.PI);
        this._ctx.lineWidth = 2;
        this._ctx.stroke();
        this._ctx.closePath();

        this._ctx.beginPath();
        this._ctx.fillStyle = '#fff';
        this._ctx.arc(x, y, 2, 0, 2 * Math.PI);
        this._ctx.fill();
        this._ctx.closePath();

        this._ctx.beginPath();
        this._ctx.moveTo(x, y - 10);
        this._ctx.lineTo(x, y - 18);

        this._ctx.moveTo(x, y + 10);
        this._ctx.lineTo(x, y + 18);

        this._ctx.moveTo(x + 10, y);
        this._ctx.lineTo(x + 18, y);

        this._ctx.moveTo(x - 10, y);
        this._ctx.lineTo(x - 18, y);

        this._ctx.lineWidth = 2;
        this._ctx.stroke();
        this._ctx.closePath();
    }

    private drawDebugDot(x: number, y: number) {
        this._ctx.beginPath();
        this._ctx.setLineDash([0]);
        this._ctx.strokeStyle = '#fff';
        this._ctx.arc(x, y, 2, 0, 2 * Math.PI);
        this._ctx.lineWidth = 2;
        this._ctx.stroke();
    }

    private getLogParams(logType: string, meta?: any) {
        return {
            logType: logType,
            accountId: this._accountId,
            userId: this._userId,
            roomId: this._roomId,
            meta: meta
        };
    }
}
