import {
    IClientAsDashboardSMSParams,
    IDbSms,
    ISendSmsParams,
    ISendWhatsAppTemplateParams,
    IVisualJourneyParams
} from './AngularServices';

export class DbSmsAdapter implements IDbSms {
    private _dbService: any;

    constructor(db: any) {
        this._dbService = db;
    }

    getSmsStatus(gateway: string, smsId: string, smsStatusParams: any): Promise<any> {
        return this._dbService.SMSStatus.find(`${gateway}/${smsId}`, smsStatusParams);
    }

    sendPrimarySms(params: ISendSmsParams): Promise<any> {
        return this._dbService.PrimarySMS.create(params);
    }

    sendWhatsAppTemplateMessage(params: ISendWhatsAppTemplateParams): Promise<any> {
        return this._dbService.WhatsApp.sendTemplate({data: params});
    }

    getWhatsAppTemplateMessageStatus(messageId: string): Promise<any> {
        return this._dbService.WhatsApp.status(messageId);
    }

    sendSecondarySms(params: ISendSmsParams): Promise<any> {
        return this._dbService.SecondarySMS.create(params);
    }

    validatePhoneNumber(data: {data: {phoneNumber: string; countryCode: string; gateway: string}}): Promise<any> {
        return this._dbService.Sms.validatephonenumber(data);
    }

    sendVisualJourneySMS(data: IVisualJourneyParams): Promise<any> {
        return this._dbService.Sms.visualJourney({data});
    }

    sendClientAsDashboardSMS(data: IClientAsDashboardSMSParams): Promise<any> {
        return this._dbService.Sms.clientAsDashboard({data});
    }

    sendObserverInvitationSMS(data: IClientAsDashboardSMSParams): Promise<any> {
        return this._dbService.Sms.observerInvitation({data});
    }
    sendWarmTransferClientLinkSMS(data: {roomId: string}): Promise<any> {
        return this._dbService.Sms.warmTransfer({data});
    }
}
