import React from 'react';
import {observer} from 'mobx-react';
import {action, observable} from 'mobx';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {PrimaryButton} from '@techsee/techsee-ui-common/lib/forms/buttons/primary';
import {ButtonTypeAttributes} from '@techsee/techsee-ui-common/lib/forms/buttons/generic';
import {Icon} from '@techsee/techsee-ui-common/lib/icons/simple-icon';
import {SimpleLabel} from '@techsee/techsee-ui-common/lib/forms/labels/simple';
import {AddressTypesEnum} from '@techsee/techsee-common/lib/constants/room.constants';
import {IFieldModel, SimpleFieldModel} from '@techsee/techsee-ui-common/lib/forms/_shared/simple-field-model';
import {ICountryInfo} from '@techsee/techsee-ui-common/lib/_contracts/CountryInfo';
import {PhoneNumberField} from '@techsee/techsee-ui-common/lib/forms/phone-number-field';
import {ITranslate} from '../../../services/LocalizationService';
import find from 'lodash/find';

import {IObservableDetails} from './controller';
import './observerModal.scss';

export interface IObserverModal {
    translate: ITranslate;
    labelText: string;
    buttonText: string;
    patterns: {email: RegExp; phone: RegExp} | undefined;
    observerDetails: IObservableDetails;
    formErrorText: string;
    accountSettings: any;
    onSubmitObserver: any;
    countryList?: ICountryInfo[];
    countriesInfoList?: ICountryInfo[];
}

@observer
export class ObserverModal extends TechseeBaseComponent<IObserverModal> {
    onSubmit = () => {
        this.updateErrorMessage('');

        if (this.validateForm()) {
            const value = this.email ? this.email : this.countryCodeAndPhoneNumber.value;

            this.props.onSubmitObserver(value, this.selectedField);
        }
    };

    @action
    updateErrorMessage(message: string) {
        this.errorMessage = message ? this.props.translate(message) : '';
    }

    customerRef: string | undefined;

    email: string | undefined;

    phoneNumberModel: string | undefined;

    phoneNumber: string | undefined;

    @observable errorMessage: string | undefined;

    @observable selectedField: AddressTypesEnum = AddressTypesEnum.SMS;

    @observable countryCodeField: IFieldModel = new SimpleFieldModel({
        value: this.props.accountSettings.selectedCountries[0]
    });

    @observable phoneNumberField: IFieldModel = new SimpleFieldModel({
        value: '',
        placeholder: this.props.translate('REACT.INVITE.VIEW.SESSION_OBSERVATION.PHONE')
    });

    @observable countryCodeAndPhoneNumber: IFieldModel = new SimpleFieldModel({
        value: ''
    });

    @action
    onTypeFieldChange = (value: any) => {
        this.selectedField = value;
    };

    validateForm() {
        if (!this.props.patterns) {
            return false;
        }

        let message = '',
            requiredText = 'REACT.INVITE.VIEW.PHONE_NUM.ERROR.NUMBER_REQUIRED',
            validationErrorText = 'REACT.INVITE.VIEW.PHONE_NUM.ERROR.INCORRECT_NUMBER',
            inputValue = this.countryCodeAndPhoneNumber.value.toString() || '',
            pattern = this.props.patterns.phone;

        if (this.selectedField === AddressTypesEnum.EMAIL) {
            requiredText = 'REACT.INVITE.VIEW.EMAIL.ERROR.EMAIL_REQUIRED';
            validationErrorText = 'REACT.INVITE.VIEW.EMAIL.ERROR.EMAIL_VALIDATION';
            inputValue = this.email || '';
            pattern = this.props.patterns.email;
        }

        if (!inputValue || !pattern.test(inputValue)) {
            message = inputValue ? validationErrorText : requiredText;

            this.updateErrorMessage(message);

            return false;
        }

        return true;
    }

    renderInternal() {
        const {labelText, buttonText, translate, accountSettings, countryList, observerDetails} = this.props;

        const countries = countryList || [];

        const countriesInfoList = countries.filter(
            (country: ICountryInfo) =>
                accountSettings.selectedCountries.indexOf(country.code) > -1 ||
                `+${accountSettings.countryCode}` === country.dial
        );

        const accountDialCode = `+${accountSettings.defaultCountryCode}`;

        const country: ICountryInfo | undefined = find(countriesInfoList, {dial: accountDialCode});

        if (accountSettings.allowInviteBySms && country && country.dial) {
            this.countryCodeField.setValue(country.dial);
        }

        const emailField = (
            <input
                id={'email'}
                type={'text'}
                onChange={(e) => {
                    this.email = e.target.value;
                }}
                placeholder={translate('INVITE.VIEW.EMAIL')}
                disabled={this.selectedField === AddressTypesEnum.SMS}
            />
        );

        const phoneNumberField = (
            <PhoneNumberField
                uid={'phoneNumber'}
                model={this.countryCodeAndPhoneNumber}
                phoneNumberModel={this.phoneNumberField}
                countryCodeModel={this.countryCodeField}
                countryCodesList={countriesInfoList}
                selectedCountries={accountSettings.phoneCountryCodes}
                defaultCountry={accountSettings.defaultCountryCode}
                countryList={countries}
                loadMoreItemsText={translate('REACT.COMMON.COUNTRIES.LOAD_MORE_COUNTRY_CODES')}
                noResultsText={translate('REACT.COMMON.COUNTRIES.NO_RESULTS')}
                disabled={this.selectedField === AddressTypesEnum.EMAIL}
            />
        );

        const observerConnected = (
            <div
                className={'observer-details'}
                dangerouslySetInnerHTML={{
                    __html: translate('REACT.LEFTBAR.POPOVER.SESSION_OBSERVER_CONNECTED', {
                        user: `${observerDetails.firstName} ${observerDetails.lastName}`
                    })
                }}></div>
        );

        return (
            <React.Fragment>
                {!observerDetails.connected && (
                    <div className={'observer-modal'}>
                        <SimpleLabel>{translate(labelText)}</SimpleLabel>

                        <div className='use-common-styles generic-form'>
                            {accountSettings.allowSendByEmail && accountSettings.allowInviteBySms && (
                                <div className='radio-group'>
                                    <div className='radio-item'>
                                        <label className='radio-container email-checkmark' htmlFor='email'>
                                            <input
                                                id={'observer-email'}
                                                name={'observer-radio'}
                                                value={AddressTypesEnum.EMAIL}
                                                type='radio'
                                                onChange={() => this.onTypeFieldChange(AddressTypesEnum.EMAIL)}
                                                checked={this.selectedField === AddressTypesEnum.EMAIL}
                                            />

                                            <span className='checkmark'></span>
                                        </label>
                                        {emailField}
                                    </div>

                                    <div className='radio-item'>
                                        <label className='radio-container phone-checkmark' htmlFor='phone-number'>
                                            <input
                                                id={'observer-phone-number'}
                                                name={'observer-radio'}
                                                value={AddressTypesEnum.SMS}
                                                type='radio'
                                                onChange={() => this.onTypeFieldChange(AddressTypesEnum.SMS)}
                                                checked={this.selectedField === AddressTypesEnum.SMS}
                                            />
                                            <span className='checkmark'></span>
                                        </label>
                                        {phoneNumberField}
                                    </div>
                                </div>
                            )}

                            {!accountSettings.allowSendByEmail && accountSettings.allowInviteBySms && (
                                <div>{phoneNumberField}</div>
                            )}

                            {accountSettings.allowSendByEmail && !accountSettings.allowInviteBySms && (
                                <div>{emailField}</div>
                            )}

                            {this.errorMessage && <div className={'error-message error'}>{this.errorMessage}</div>}

                            <PrimaryButton
                                onClick={this.onSubmit}
                                className={'search-button'}
                                type={ButtonTypeAttributes.Button}>
                                <div>
                                    {translate(buttonText)}
                                    <Icon iconName={'arrow-next'} />
                                </div>
                            </PrimaryButton>
                        </div>
                    </div>
                )}
                {observerDetails.connected && observerConnected}
            </React.Fragment>
        );
    }
}
