import {action, observable} from 'mobx';
import {ITranslate} from '../../../../services/LocalizationService';

export interface ILoginConfirmationController {
    readonly isLoginConfirmVisible: boolean;
    readonly username: string;
    readonly translate: ITranslate;
    setUserName(username: string): void;
    onConfirmLogin: (callback: () => void) => any;
    show(): void;
    hide(): void;
}

export class LoginConfirmationController implements ILoginConfirmationController {
    @observable isLoginConfirmVisible: boolean = false;

    @observable username: string = '';

    constructor(public translate: ITranslate) {
        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
    }

    @action
    onConfirmLogin() {}

    @action
    setUserName(username: string): void {
        this.username = username;
    }

    @action
    show() {
        this.isLoginConfirmVisible = true;
    }

    @action
    hide() {
        this.isLoginConfirmVisible = false;
    }
}
