'use strict';

import {getRootStore} from '../../_react_/app.bootstrap';

/**
 * Applies ng-if, based on the environment, as detected by the
 * tsEnvironmentDetect service
 *
 * Attributes:
 *
 *  ts-environment-if: Environmnent in which if is true
 *                     ('desktop', 'mobile', 'tablet', 'phone')
 */
class TsEnvironmentIfController {
    constructor($element, $attrs, $scope, ngIfDirective) {
        'ngInject';

        this.$scope = $scope;
        this.environmentDetect = getRootStore().environmentService;
        this.browserUtilsService = getRootStore().browserUtilsService;
        this.displayTabletAsDesktop = getRootStore().displayTabletAsDesktop;
        this.ngIfDirective = ngIfDirective[0];
    }

    test(env) {
        return this.environmentDetect.isEnvironment(env, this.displayTabletAsDesktop);
    }

    applyIf(args) {
        this.ngIfDirective.link.apply(this.ngIfDirective, args);
    }

    setIf(attrs, env) {
        attrs.ngIf = () => this.test(env);
    }
}

function linkFn(scope, element, attrs, ctrl) {
    const env = attrs.tsEnvironmentIf;

    ctrl.setIf(attrs, env);
    // eslint-disable-next-line prefer-rest-params
    ctrl.applyIf(arguments);
}

export function tsEnvironmentIfDirective(ngIfDirective) {
    'ngInject';

    const ngIf = ngIfDirective[0];

    return {
        transclude: ngIf.transclude,
        priority: ngIf.priority,
        terminal: ngIf.terminal,
        restrict: ngIf.restrict,
        link: linkFn,
        controller: TsEnvironmentIfController,
        controllerAs: 'vm'
    };
}
