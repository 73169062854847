import React from 'react';
import {observer} from 'mobx-react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {IconColors} from '@techsee/techsee-ui-common/lib/icons/large-rounded-icon';
import {GuidenceProgressItem} from '../components/guidance-progress/GuidanceProgress';
import {PermissionsStepTabs} from './_Shared';
import {InviteStepProps} from './_InviteGenericStep';
import {ProgressList} from '../components/progress-list/ProgressList';
import {AgentGuidance} from '../components/agent-guidance/AgentGuidance';
import {InviteDeviceType, InviteGuidanceBrowser} from '../../_contracts/InviteContracts';
import {observable, action} from 'mobx';

interface IGuidanceCurrentBrowser {
    browserName: InviteGuidanceBrowser;
    images: {name: string; type: InviteDeviceType}[];
    tooltips: {num: string | number; text: string}[];
}

@observer
export class CameraStep extends TechseeBaseComponent<InviteStepProps> {
    private _browserGuidances: Record<string, IGuidanceCurrentBrowser>;

    @observable
    private _currentBrowserGuidance: IGuidanceCurrentBrowser;

    constructor(props: InviteStepProps) {
        super(props);

        const {translate} = props.ctrl;

        this._browserGuidances = {
            [InviteGuidanceBrowser.chrome]: {
                browserName: InviteGuidanceBrowser.chrome,
                images: [{name: 'desktop-sharing-type', type: InviteDeviceType.common}],
                tooltips: [
                    {num: 1, text: translate('REACT.INVITE.GUIDANCE.DESKTOP_SHARING.CHROME_FIRST')},
                    {num: 2, text: translate('REACT.INVITE.GUIDANCE.DESKTOP_SHARING.CHROME_SECOND')},
                    {num: 3, text: translate('REACT.INVITE.GUIDANCE.DESKTOP_SHARING.CHROME_THIRD')}
                ]
            },
            [InviteGuidanceBrowser.safari]: {
                browserName: InviteGuidanceBrowser.safari,
                images: [{name: 'desktop-sharing-type', type: InviteDeviceType.common}],
                tooltips: [{num: 1, text: translate('REACT.INVITE.GUIDANCE.DESKTOP_SHARING.SAFARI_FIRST')}]
            },
            [InviteGuidanceBrowser.firefox]: {
                browserName: InviteGuidanceBrowser.firefox,
                images: [
                    {name: 'desktop-sharing-type-1', type: InviteDeviceType.common},
                    {name: 'desktop-sharing-type-2', type: InviteDeviceType.common}
                ],
                tooltips: [
                    {num: 1, text: translate('REACT.INVITE.GUIDANCE.DESKTOP_SHARING.FIREFOX_FIRST')},
                    {num: 2, text: translate('REACT.INVITE.GUIDANCE.DESKTOP_SHARING.FIREFOX_SECOND')},
                    {num: 3, text: translate('REACT.INVITE.GUIDANCE.DESKTOP_SHARING.FIREFOX_THIRD')}
                ]
            }
        };

        this._currentBrowserGuidance = this._browserGuidances[InviteGuidanceBrowser.chrome];
    }

    componentDidMount() {
        if (this.props.ctrl.isDesktopSharing) {
            this.props.ctrl.setPagePlacement('invite-desktop-sharing-type');
        } else {
            this.props.ctrl.setPagePlacement('invite-camera-permissions');
        }

        this.setBrowserGuidance();
    }

    @action
    setBrowserGuidance() {
        this._currentBrowserGuidance = this._browserGuidances[this.browserName];
    }

    get browserName(): InviteGuidanceBrowser {
        const clientBrowser = this.props.ctrl.clientBrowser && this.props.ctrl.clientBrowser.toLowerCase();

        if (clientBrowser) {
            if (clientBrowser.indexOf(InviteGuidanceBrowser.chrome) !== -1) {
                return InviteGuidanceBrowser.chrome;
            } else if (clientBrowser.indexOf(InviteGuidanceBrowser.firefox) !== -1) {
                return InviteGuidanceBrowser.firefox;
            } else if (clientBrowser.indexOf(InviteGuidanceBrowser.safari) !== -1) {
                return InviteGuidanceBrowser.safari;
            } else if (clientBrowser.indexOf(InviteGuidanceBrowser.edge) !== -1) {
                // For the edge browser using chrome based data
                return InviteGuidanceBrowser.chrome;
            }
        }

        return InviteGuidanceBrowser.chrome;
    }

    renderDesktopSharingCameraApproval() {
        const {translate, desktopShowURL} = this.props.ctrl;

        const progressItems: GuidenceProgressItem[] = [
            {
                iconColor: IconColors.Green,
                label: translate('REACT.INVITE.GUIDANCE.ACCEPT_TOS'),
                isActive: true,
                icon: 'check',
                isIconAnimated: false
            },
            {
                iconColor: IconColors.Blue,
                label: translate('REACT.INVITE.GUIDANCE.SHARING_TYPE_TITLE'),
                isActive: true,
                icon: 'desktop-sharing',
                isIconAnimated: false
            }
        ];

        return (
            <div className='desktop-sharing-guidance'>
                <h1 className='page-header'>{translate('REACT.INVITE.VIEW.DESKTOP_SHARING_TYPE_TITLE')}</h1>
                <h2
                    className='sub-header'
                    dangerouslySetInnerHTML={{
                        __html: translate('REACT.INVITE.VIEW.DESKTOP_SHARING_TYPE_SUB_TITLE', {
                            url: desktopShowURL.displayUrl
                        })
                    }}></h2>

                <div className='desktop-sharing-guidance__content'>
                    <div className='desktop-sharing-guidance__progress'>
                        <ProgressList progressList={progressItems} />
                    </div>

                    <div className='desktop-sharing-guidance__agent'>
                        <AgentGuidance.Wrapper
                            browser={this._currentBrowserGuidance.browserName}
                            className='desktop-sharing-guidance__type'>
                            <AgentGuidance.Images images={this._currentBrowserGuidance.images} />

                            <AgentGuidance.Tooltip list={this._currentBrowserGuidance.tooltips} />
                        </AgentGuidance.Wrapper>
                    </div>
                </div>
            </div>
        );
    }

    renderGeneralCameraApproval() {
        const {clientDeviceType, translate} = this.props.ctrl;

        const progressItems: GuidenceProgressItem[] = [
            {
                iconColor: IconColors.Green,
                label: translate('REACT.INVITE.PERMISSIONS.TERMS.TITLE'),
                isActive: true,
                icon: 'check',
                isIconAnimated: false
            },
            {
                iconColor: IconColors.Blue,
                label: translate('REACT.INVITE.PERMISSIONS.CAMERA.TITLE'),
                isActive: true,
                icon: 'camera',
                isIconAnimated: false
            }
        ];

        return (
            <PermissionsStepTabs
                guidanceType={'camera-allow'}
                progressList={progressItems}
                deviceType={clientDeviceType}
                stepClass={'camera-step'}
                title={translate('REACT.INVITE.PERMISSIONS.CAMERA.TITLE')}
                subTitle={translate('REACT.INVITE.PERMISSIONS.CAMERA.SUB_TITLE')}
                guidanceText={translate('REACT.INVITE.PERMISSIONS.CAMERA.TAP_ALLOW')}
            />
        );
    }

    renderInternal() {
        if (this.props.ctrl.isDesktopSharing) {
            return this.renderDesktopSharingCameraApproval();
        }

        return this.renderGeneralCameraApproval();
    }
}
