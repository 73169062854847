import {EventEmitter} from 'events';

import debounce from 'lodash/debounce';
import values from 'lodash/values';

export class TsScanArea extends EventEmitter {
    constructor($rootScope, $window, $filter, $translate, tsCanvasAnnotate, tsChatApi) {
        'ngInject';
        super();

        this.$rootScope = $rootScope;
        this.$window = $window;
        this.$translate = $translate;
        this.annotateService = tsCanvasAnnotate;

        this.chatApi = tsChatApi.service;

        this.captureClick = this.captureClick.bind(this);

        this.pending = false;
        this.initialized = false;
        this.destroyed = false;
        this.i = 0;
        this.d = 0;

        this._prepareCaptureBtn();
    }

    get closeEnough() {
        const rect = this._getCanvasSize();
        const scale = Math.min(rect.scaleX, rect.scaleY);

        return 10 * scale;
    }

    emit(...args) {
        const phase = this.$rootScope.$$phase;

        if (phase === '$apply' || phase === '$digest') {
            super.emit(...args);
        } else {
            this.$rootScope.$apply(() => super.emit(...args));
        }
    }

    init(canvas) {
        this.canvas = canvas;
        this.ctx = this.canvas.getContext('2d');
        this.initialized = true;
        this.destroyed = false;
        this.annotateService.readonly = true;

        this.initCanvas();

        this.mouseDownListener = this.mouseDown.bind(this);
        this.mouseMoveListener = this.mouseMove.bind(this);
        this.mouseUpListener = this.mouseUp.bind(this);
        this.windowResizeListener = debounce(this.windowResize.bind(this), 200);
        this.rotateListener = this.rotateHandler.bind(this);

        this.canvas.addEventListener('touchstart', this.mouseDownListener);
        this.canvas.addEventListener('touchmove', this.mouseMoveListener);
        this.canvas.addEventListener('touchend', this.mouseUpListener);
        this.canvas.addEventListener('mousedown', this.mouseDownListener);
        this.canvas.addEventListener('mousemove', this.mouseMoveListener);
        this.canvas.addEventListener('mouseup', this.mouseUpListener);
        this.annotateService.on('rotated', this.rotateListener);
        this.$window.addEventListener('resize', this.windowResizeListener);

        this._insertCaptureBtn();

        this.clear();
        this.draw();
    }

    initCanvas() {
        const rectInitialSize = {
            w: this.canvas.width * 0.5,
            h: this.canvas.height * 0.6 - 30
        };

        const rectInitialPos = {
            startX: (this.canvas.width - rectInitialSize.w) / 2,
            startY: (this.canvas.height - rectInitialSize.h) / 2
        };

        this.rect = {
            startX: rectInitialPos.startX,
            startY: rectInitialPos.startY,
            minW: 20,
            minH: 20,
            w: rectInitialSize.w,
            h: rectInitialSize.h
        };

        this.activeHandle = {
            dragTL: false,
            dragTR: false,
            dragBR: false,
            dragBL: false,

            dragT: false,
            dragR: false,
            dragB: false,
            dragL: false
        };

        this.areaDraging = false;

        this.btn = {
            x: this.canvas.width / 2,
            y: this.canvas.height - this.captureBtnRadius * 2 - 15,
            w: this.captureBtnRadius * 2,
            h: this.captureBtnRadius * 2,
            text: this.$translate.instant('MAIN.VIEW.MODE.CAPTURE'),
            iconLoaded: false
        };
    }

    destroy() {
        if (!this.canvas) {
            return false;
        }

        this.initialized = false;
        this.destroyed = true;

        this.canvas.removeEventListener('touchstart', this.mouseDownListener);
        this.canvas.removeEventListener('touchmove', this.mouseMoveListener);
        this.canvas.removeEventListener('touchend', this.mouseUpListener);
        this.canvas.removeEventListener('mousedown', this.mouseDownListener);
        this.canvas.removeEventListener('mousemove', this.mouseMoveListener);
        this.canvas.removeEventListener('mouseup', this.mouseUpListener);
        this.$window.removeEventListener('resize', this.windowResizeListener);
        this.annotateService.removeListener('rotated', this.rotateListener);
        this.annotateService.readonly = false;

        this.clear();
        this._removeCaptureBtn();
    }

    clear() {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    }

    captureClick() {
        const {startX: x, startY: y, w: width, h: height} = this.rect;

        this.emit('areaSelected', {
            rect: {x, y, width, height}
        });

        this.destroy();
    }

    get handlersPositions() {
        const rect = this.rect;

        return {
            tl: {x: rect.startX, y: rect.startY},
            t: {x: rect.startX + rect.w / 2, y: rect.startY},
            tr: {x: rect.startX + rect.w, y: rect.startY},
            r: {x: rect.startX + rect.w, y: rect.startY + rect.h / 2},
            br: {x: rect.startX + rect.w, y: rect.startY + rect.h},
            b: {x: rect.startX + rect.w / 2, y: rect.startY + rect.h},
            bl: {x: rect.startX, y: rect.startY + rect.h},
            l: {x: rect.startX, y: rect.startY + rect.h / 2}
        };
    }

    rotateHandler() {
        this.initCanvas();
        this.clear();
        this.draw();
    }

    windowResize() {
        setTimeout(() => {
            this.clear();
            this.draw();
        }, 100);
    }

    mouseDown(e) {
        const {x: mouseX, y: mouseY} = this._getMousePos(e);
        const pos = this.handlersPositions;

        if (this._checkCapture(mouseX, mouseY)) {
            return false;
        }

        // Top left
        this._setActiveHandler(mouseX, mouseY, pos.tl.x, pos.tl.y, 'dragTL');
        // Top
        this._setActiveHandler(mouseX, mouseY, pos.t.x, pos.t.y, 'dragT');
        // Top right
        this._setActiveHandler(mouseX, mouseY, pos.tr.x, pos.tr.y, 'dragTR');
        // right
        this._setActiveHandler(mouseX, mouseY, pos.r.x, pos.r.y, 'dragR');
        // bottom right
        this._setActiveHandler(mouseX, mouseY, pos.br.x, pos.br.y, 'dragBR');
        // bottom
        this._setActiveHandler(mouseX, mouseY, pos.b.x, pos.b.y, 'dragB');
        // bottom left
        this._setActiveHandler(mouseX, mouseY, pos.bl.x, pos.bl.y, 'dragBL');
        // left
        this._setActiveHandler(mouseX, mouseY, pos.l.x, pos.l.y, 'dragL');

        // Dragging
        this._setAreaDragging(mouseX, mouseY);

        if (this.areaDraging) {
            this.canvas.style.cursor = 'move';
        }
    }

    mouseMove(e) {
        const {x: mouseX, y: mouseY} = this._getMousePos(e);
        const rect = this.rect;

        this.drawCursor(mouseX, mouseY);

        if (this.activeHandle.dragTL) {
            rect.w += rect.startX - mouseX;
            rect.h += rect.startY - mouseY;
            rect.startX = mouseX;
            rect.startY = mouseY;
        } else if (this.activeHandle.dragT) {
            rect.h += rect.startY - mouseY;
            rect.startY = mouseY;
        } else if (this.activeHandle.dragTR) {
            rect.w = Math.abs(rect.startX - mouseX);
            rect.h += rect.startY - mouseY;
            rect.startY = mouseY;
        } else if (this.activeHandle.dragR) {
            rect.w = Math.abs(rect.startX - mouseX);
        } else if (this.activeHandle.dragBR) {
            rect.w = Math.abs(rect.startX - mouseX);
            rect.h = Math.abs(rect.startY - mouseY);
        } else if (this.activeHandle.dragB) {
            rect.h = Math.abs(rect.startY - mouseY);
        } else if (this.activeHandle.dragBL) {
            rect.w += rect.startX - mouseX;
            rect.h = Math.abs(rect.startY - mouseY);
            rect.startX = mouseX;
        } else if (this.activeHandle.dragL) {
            rect.w += rect.startX - mouseX;
            rect.startX = mouseX;
        } else if (this.areaDraging) {
            rect.startX = mouseX - rect.w / 2;
            rect.startY = mouseY - rect.h / 2;
        }

        this.clear();
        this.draw();
    }

    mouseUp() {
        this.activeHandle = {
            dragTL: false,
            dragTR: false,
            dragBR: false,
            dragBL: false,

            dragT: false,
            dragR: false,
            dragB: false,
            dragL: false,

            dragA: false
        };

        this.areaDraging = false;
    }

    busy() {
        this.drawSpinner();
    }

    free() {
        this.clear();
        this.draw();
        this.$window.cancelAnimationFrame(this.requestAnimationFrameId);
    }

    draw() {
        // checking move outside canvas
        this._checkBoundaries();
        // drowing background
        this.ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
        this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
        // clearing selected area
        this.ctx.clearRect(this.rect.startX, this.rect.startY, this.rect.w, this.rect.h);

        // drawing selection rectangle
        this.ctx.strokeStyle = 'black';
        this.ctx.setLineDash([6]);
        this.ctx.strokeRect(this.rect.startX, this.rect.startY, this.rect.w, this.rect.h);
        this.ctx.strokeStyle = 'white';
        this.ctx.setLineDash([6]);
        this.ctx.strokeRect(this.rect.startX - 1, this.rect.startY - 1, this.rect.w + 2, this.rect.h + 2);

        this.drawHandles();
    }

    drawCircle(x, y, radius) {
        this.ctx.fillStyle = '#489de9';
        this.ctx.beginPath();
        this.ctx.arc(x, y, radius, 0, 2 * Math.PI);
        this.ctx.fill();
    }

    drawHandles() {
        const pos = this.handlersPositions;

        // top-left
        this.drawCircle(pos.tl.x, pos.tl.y, this.closeEnough);
        // top
        this.drawCircle(pos.t.x, pos.t.y, this.closeEnough);
        // top-right
        this.drawCircle(pos.tr.x, pos.tr.y, this.closeEnough);
        // rigth
        this.drawCircle(pos.r.x, pos.r.y, this.closeEnough);
        // bottom-right
        this.drawCircle(pos.br.x, pos.br.y, this.closeEnough);
        // bottom
        this.drawCircle(pos.b.x, pos.b.y, this.closeEnough);
        // bottom-left
        this.drawCircle(pos.bl.x, pos.bl.y, this.closeEnough);
        // left
        this.drawCircle(pos.l.x, pos.l.y, this.closeEnough);
    }

    drawCursor(mouseX, mouseY) {
        const pos = this.handlersPositions;

        if (this._checkCloseEnough(mouseX, pos.tl.x) && this._checkCloseEnough(mouseY, pos.tl.y)) {
            this.canvas.style.cursor = 'nw-resize';
        } else if (this._checkCloseEnough(mouseX, pos.t.x) && this._checkCloseEnough(mouseY, pos.t.y)) {
            this.canvas.style.cursor = 'n-resize';
        } else if (this._checkCloseEnough(mouseX, pos.tr.x) && this._checkCloseEnough(mouseY, pos.tr.y)) {
            this.canvas.style.cursor = 'ne-resize';
        } else if (this._checkCloseEnough(mouseX, pos.r.x) && this._checkCloseEnough(mouseY, pos.r.y)) {
            this.canvas.style.cursor = 'e-resize';
        } else if (this._checkCloseEnough(mouseX, pos.br.x) && this._checkCloseEnough(mouseY, pos.br.y)) {
            this.canvas.style.cursor = 'se-resize';
        } else if (this._checkCloseEnough(mouseX, pos.b.x) && this._checkCloseEnough(mouseY, pos.b.y)) {
            this.canvas.style.cursor = 's-resize';
        } else if (this._checkCloseEnough(mouseX, pos.bl.x) && this._checkCloseEnough(mouseY, pos.bl.y)) {
            this.canvas.style.cursor = 'sw-resize';
        } else if (this._checkCloseEnough(mouseX, pos.l.x) && this._checkCloseEnough(mouseY, pos.l.y)) {
            this.canvas.style.cursor = 'w-resize';
        } else if (this.areaDraging) {
            this.canvas.style.cursor = 'move';
        } else if (this._checkCapture(mouseX, mouseY)) {
            this.canvas.style.cursor = 'pointer';
        } else {
            this.canvas.style.cursor = 'default';
        }
    }

    drawSpinner() {
        this.i += 0.025;

        this.ctx.save();
        this.ctx.translate(this.rect.startX + this.rect.w / 2 - 50, this.rect.startY + this.rect.h / 2 - 50);
        this.ctx.clearRect(0, 0, 100, 100);
        this.ctx.beginPath();

        let t = this.i - Math.floor(this.i);
        let t2 = this.i - Math.floor(this.i);

        if (Math.floor(this.i) % 2 === 1) {
            t = 0;
        } else {
            t2 = 1;
        }

        if (Math.floor(this.i) % 2 === 0 && Math.floor(this.i) > this.d * 2) {
            this.d++;
        }

        const y = Math.pow(t, 2) / (Math.pow(t, 2) + Math.pow(1 - t, 2));
        const y2 = Math.pow(t2, 2) / (Math.pow(t2, 2) + Math.pow(1 - t2, 2));

        this.ctx.arc(
            50,
            50,
            25,
            y * 5 + Math.floor(this.i) * 5 + this.d * 1.28,
            y2 * 5 + 0.15 + Math.floor(this.i) * 5 + this.d * 1.28
        );
        this.ctx.lineWidth = 8;
        this.ctx.setLineDash([]);
        this.ctx.strokeStyle = '#1e99fd';
        this.ctx.stroke();
        this.ctx.restore();
        this.requestAnimationFrameId = this.$window.requestAnimationFrame(this.drawSpinner.bind(this));
    }

    _checkBoundaries() {
        const rect = this.rect;

        rect.startX = rect.startX < 0 ? 0 : rect.startX;
        rect.startX = rect.startX + rect.w >= this.canvas.width ? this.canvas.width - rect.w : rect.startX;

        rect.startY = rect.startY < 0 ? 0 : rect.startY;
        rect.startY = rect.startY + rect.h >= this.canvas.height ? this.canvas.height - rect.h : rect.startY;

        rect.w = rect.w < rect.minW ? rect.minW : rect.w;
        rect.h = rect.h < rect.minH ? rect.minH : rect.h;
    }

    _setCursor(x, y, xR, yR, cursor) {
        if (this._checkCloseEnough(x, xR) && this._checkCloseEnough(y, yR)) {
            this.canvas.style.cursor = cursor;
        } else {
            this.canvas.style.cursor = 'default';
        }
    }

    _setActiveHandler(x, y, xR, yR, key) {
        const isClose = this._checkCloseEnough(x, xR) && this._checkCloseEnough(y, yR);

        this.activeHandle[key] = isClose;
    }

    _getMousePos(evt) {
        const clientX = evt.clientX || evt.changedTouches[0].clientX,
            clientY = evt.clientY || evt.changedTouches[0].clientY,
            rect = this.canvas.getBoundingClientRect(), // abs. size of element
            scaleX = this.canvas.width / rect.width, // relationship bitmap vs. element for X
            scaleY = this.canvas.height / rect.height; // relationship bitmap vs. element for Y

        return {
            x: (clientX - rect.left) * scaleX, // scale mouse coordinates after they have
            y: (clientY - rect.top) * scaleY // been adjusted to be relative to element
        };
    }

    _checkCloseEnough(p1, p2, closeEnough = this.closeEnough) {
        return Math.abs(p1 - p2) < closeEnough;
    }

    _checkCapture(x, y) {
        const radius = this.captureBtnRadius;

        return (
            this._checkCloseEnough(x, this.canvas.width / 2, radius) &&
            this._checkCloseEnough(y, this.canvas.height - radius * 2 - 15, radius)
        );
    }

    _setAreaDragging(x, y) {
        const rect = this.rect;

        const noBoundsMoving = !values(this.activeHandle).some((v) => !!v);

        this.areaDraging =
            noBoundsMoving &&
            ((x > rect.startX && x < rect.startX + rect.w) || (y > rect.startY && y < rect.startY + rect.h));
    }

    _prepareCaptureBtn() {
        const captureBtnText = document.createElement('span');

        captureBtnText.innerText = this.$translate.instant('MAIN.VIEW.MODE.CAPTURE');

        this._captureButton = document.createElement('button');
        this._captureButton.setAttribute('id', 'scan-capture-btn');
        this._captureButton.appendChild(captureBtnText);
    }

    _insertCaptureBtn() {
        this._captureButton.addEventListener('click', this.captureClick);
        this.canvas.insertAdjacentElement('afterend', this._captureButton);
    }

    _removeCaptureBtn() {
        this._captureButton.removeEventListener('click', this.captureClick);
        this._captureButton.remove();
    }

    _getCanvasSize() {
        if (!this.canvas) {
            return;
        }

        const rect = this.canvas.getBoundingClientRect(); // abs. size of element
        const scaleX = this.canvas.width / rect.width; // relationship bitmap vs. element for X
        const scaleY = this.canvas.height / rect.height; // relationship bitmap vs. element for Y

        return {
            rect,
            scaleX,
            scaleY
        };
    }
}
