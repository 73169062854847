'use strict';

import isUndefined from 'lodash/isUndefined';
import isArray from 'lodash/isArray';
import filter from 'lodash/filter';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import tsMultiSelectView from './ts-multi-select.view.html';
import './ts-multi-select.style.scss';

class TsMultiSelectController {
    constructor($timeout, $attrs, $element, $document, tsInterfaceHelper) {
        'ngInject';

        this.$timeout = $timeout;
        this.$attrs = $attrs;
        this.$element = $element;
        this.$document = $document;

        this.SINGLE_MODE = 'single';
        this.MULTI_MODE = 'multi';

        this.tsInterfaceHelper = tsInterfaceHelper;

        /**
         * Settings
         */

        // Sets `ng-required` in the view (empty attribute)
        this.required = !!$attrs.required;
        // Sets the hidden input `name` attribute, needed for form validations
        this.controlName = $attrs.controlName || '';
        // `input-model` property with a boolean value representing whether an item is selected
        this.selectedProperty = $attrs.selectedProperty || 'selected';
        // Serves as the default button value when nothing is selected
        this.placeholder = $attrs.placeholder || 'Select Items...';
        // Sets the selection mode (`single` or `multi`)
        this.selectionMode = $attrs.selectionMode || this.MULTI_MODE;
        // `input-model` properties to use as item label (space separated)
        this.itemLabel = $attrs.itemLabel ? $attrs.itemLabel.split(/\s+/) : ['name'];
        // `input-model` properties to display on the button (space separated)
        this.buttonLabel = $attrs.buttonLabel ? $attrs.buttonLabel.split(/\s+/) : ['name'];
        // Sets what model properties to use in `output-model` (space separated)
        this.outputProperties = $attrs.outputProperties && $attrs.outputProperties.split(/\s+/);
        // Restrict amount of `item-label` values that will be shown as the button value
        this.maxLabels = $attrs.maxLabels;
        // Force an item selection if nothing is selected, default to first item (empty attribute)
        this.noEmptyValue = typeof isUndefined($attrs.noEmptyValue);

        this.showTooltipOffset = $attrs.showTooltipOffset ? parseInt($attrs.showTooltipOffset, 10) : 30;
        // Set isOpen
        this.isOpen = false;
    }

    // Lifecycle hook, required AngularJS 1.5+
    $onInit() {
        // Validate required properties
        this.validate();

        this.ensureInitialOutputModel();
        this.ensureSelectedItem();
        this.updateButtonText();
    }

    validate() {
        // Validate `inputModel`
        if (!isArray(this.inputModel)) {
            throw new RangeError('ts-multi-select: `input-model` must be an Array');
        }
    }

    ensureInitialOutputModel() {
        // Make sure `outputModel` has a proper initial value
        if (this.$attrs.outputModel && isUndefined(this.outputModel)) {
            this.outputModel = this.selectionMode === this.MULTI_MODE ? [] : '';
        }
    }

    isBrightMultiSelect() {
        return this.tsInterfaceHelper.useNewInterface || this.inputId === 'big-label' || this.inputId === 'login';
    }

    /**
     * Ensure something is selected when using `noEmptyValue`
     */
    ensureSelectedItem() {
        const selectedItems = this.getSelectedItems();

        // If `noEmptyValue` is set and nothing selected, select the first item
        if (this.noEmptyValue && selectedItems.length === 0) {
            this.selectItem(this.inputModel[0]);
        }
    }

    getSelectedItems() {
        return filter(this.inputModel, (item) => item[this.selectedProperty]);
    }

    getFirstSelectedItem() {
        let matchInx = 0;

        const matchItem = find(this.inputModel, (item, inx) => {
            if (item[this.selectedProperty]) {
                matchInx = inx;

                return true;
            }

            return false;
        });

        return (
            matchItem && {
                item: matchItem,
                inx: matchInx
            }
        );
    }

    getOutputValues(items) {
        const props = this.outputProperties;

        // If no output properties were set, nothing to do
        if (!props) {
            return items;
        }

        // If single output property, return only values
        if (props.length === 1) {
            // If in `single` mode, get value of the selected item
            if (this.selectionMode === this.SINGLE_MODE) {
                return items[0] && items[0][props[0]];
            }

            return map(items, props[0]);
        }

        // Return array of Object values based on specified output properties
        return items.map((item) => {
            const outputItem = {};

            props.forEach((prop) => {
                outputItem[prop] = item[prop];
            });

            return outputItem;
        });
    }

    getButtonText() {
        // Get a new array of only the selected items
        let selectedItems = this.getSelectedItems();
        let selectedCount = selectedItems.length;
        let res = '';

        // If no selected value, show default value
        if (!selectedItems.length) {
            return this.placeholder;
        }

        // If max labels set, cut the data
        if (this.maxLabels) {
            selectedItems = selectedItems.slice(0, this.maxLabels);
            selectedCount = selectedItems.length;
        }

        // Get a string of values based on `buttonLabel`, separated by `,`
        res = selectedItems
            .map((item) => {
                let labels = '';

                this.buttonLabel.forEach((prop) => {
                    labels += ' ' + item[prop];
                });

                return labels;
            })
            .join(', ');

        // Append selected count (e.g. `Internet, TV... (4)`)
        if (this.maxLabels && selectedCount > this.maxLabels) {
            res += '... (' + String(selectedCount) + ')';
        }

        return res;
    }

    updateButtonText() {
        this.buttonText = this.getButtonText();
    }

    updateOutputModel() {
        // If not provided, do nothing
        if (isUndefined(this.outputModel)) {
            return;
        }

        // Get a new array of only the selected items
        const selectedItems = this.getSelectedItems();

        this.outputModel = this.getOutputValues(selectedItems);
    }

    unSelectAll() {
        forEach(this.inputModel, (item) => {
            item[this.selectedProperty] = false;
        });
    }

    selectItem(item) {
        if (!item) {
            return;
        }

        const selectedProperty = this.selectedProperty;

        // If `noEmptyValue` is set, prevent unchecking an item if it's the only one selected
        if (this.noEmptyValue && item[selectedProperty] && this.getSelectedItems().length === 1) {
            this.preventClose = true;

            return;
        }

        // In mode `single`, switch selection and close the dropdown
        if (this.selectionMode === this.SINGLE_MODE && !item[selectedProperty]) {
            this.unSelectAll();
            item[selectedProperty] = true;
            this.focusButton();
        } else {
            // Toggle selected state
            item[selectedProperty] = !item[selectedProperty];

            // Prevent closing the dropdown
            this.preventClose = true;
        }

        this.updateButtonText();
        this.updateOutputModel();

        // Fire custom event listeners
        if (item[selectedProperty] && this.onItemSelect) {
            this.onItemSelect({
                item: angular.copy(item)
            });

            this.updateButtonText();
            this.updateOutputModel();

            return;
        }

        if (this.onItemDeselect) {
            this.onItemDeselect({
                item: item
            });

            this.updateButtonText();
            this.updateOutputModel();
        }
    }

    toggleOpen() {
        if (this.isOpen) {
            // Keep dropdown open when in multi-select mode
            if (this.preventClose && this.selectionMode === this.MULTI_MODE) {
                this.preventClose = false;

                return;
            }

            this.preventClose = false;
            this.close();
        } else {
            this.open();

            // Focus the first item, make sure the dropdown is focusable
            this.$timeout(() => {
                const selectedItem = this.getFirstSelectedItem();

                if (selectedItem) {
                    this.focusByInx(selectedItem.inx);

                    return;
                }

                this.focusFirst();
            }, 20);
        }
    }

    open() {
        if (!isEmpty(this.inputModel)) {
            this.isOpen = true;
        }
    }

    close() {
        this.isOpen = false;
    }

    focusFirst() {
        this.$element.find('.tsms-options').find('li:first-child a').focus();
    }

    focusLast() {
        this.$element.find('.tsms-options').find('li:last-child a').focus();
    }

    focusByInx(inx) {
        this.$element.find('.tsms-options').find('li').eq(inx).find('a').focus();
    }

    focusButton() {
        this.$element.find('.tsms-btn').focus();
    }
}

function linkFn(scope, element, attrs, ctrl) {
    // Add `click` handler to the document to close the dropdown when clicking outside the element
    function _docClickHandler(e) {
        const isChild = element.find(e.target).length > 0;

        if (isChild) {
            return;
        }

        ctrl.close();
        scope.$apply();
    }

    ctrl.$document.on('click', _docClickHandler);

    ctrl.$document.on('$destroy', () => {
        ctrl.$document.off('click', _docClickHandler);
    });

    /**
     * Handle key press events
     * Tab/Shift+Tab
     * Up/Down
     * Esc
     * Space
     */
    element.on('keydown', '.ts-multi-select', (e) => {
        const $target = $(e.target);
        const $parent = $target.parent();
        const keyCode = e.keyCode;
        const isButtonElm = $target.is('button');
        const isLinkElm = $target.is('a');

        // Handle Tab press
        if (keyCode === 9) {
            if (e.shiftKey) {
                // If Shift+Tab pressed on a Link item
                if (isLinkElm) {
                    ctrl.preventClose = true;
                }

                return;
            }

            // If pressed on the BUTTON or any Link item except the last one
            if (isButtonElm || (isLinkElm && !$parent.is('li:last-child'))) {
                ctrl.preventClose = true;
            }

            // Make sure to close if pressing Tab on the last item
            if (isLinkElm && $parent.is('li:last-child')) {
                ctrl.preventClose = false;
            }
        }

        // Handle Up/Down Arrow press
        if (keyCode === 40 || keyCode === 38) {
            e.preventDefault();
            ctrl.preventClose = true;

            // When pressed on the button, open the dropdown and focus the first item
            if (isButtonElm) {
                scope.$apply(() => {
                    ctrl.open();

                    // Make sure the dropdown is focusable
                    ctrl.$timeout(() => {
                        const selectedItem = ctrl.getFirstSelectedItem();

                        if (selectedItem) {
                            ctrl.focusByInx(selectedItem.inx);

                            return;
                        }

                        ctrl.focusFirst();
                    }, 20);
                });

                return;
            }

            // When pressed inside the dropdown
            if (isLinkElm) {
                // Down Arraow
                if (keyCode === 40) {
                    // If last item, focus the first one
                    if (!$parent.next().length) {
                        ctrl.focusFirst();
                    }
                    // Focus the next item
                    $parent.next().find('a').focus();
                }

                // Up Arraow
                if (keyCode === 38) {
                    // If first item, focus the last one
                    if (!$parent.prev().length) {
                        ctrl.focusLast();
                    }
                    // Focus the next item
                    $parent.prev().find('a').focus();
                }
            }
        }

        // Handle Esc press
        if (keyCode === 27) {
            e.preventDefault();
            ctrl.focusButton();
            ctrl.close();
            scope.$apply();
        }

        // Handle Space press
        if (keyCode === 32 && isLinkElm) {
            e.preventDefault();
            $target.trigger('click');
        }
    });

    // Watch `inputModel` changes
    scope.$watch(
        () => ctrl.inputModel,
        (newValue, oldValue) => {
            if (!isEqual(newValue, oldValue)) {
                ctrl.ensureInitialOutputModel();
                ctrl.ensureSelectedItem();
                ctrl.updateButtonText();
            }
        }
    );

    // Watch `outputModel` changes
    scope.$watch(
        () => ctrl.outputModel,
        () => {
            ctrl.updateButtonText();
            ctrl.ensureInitialOutputModel();
        }
    );
}

export function tsMultiSelectDirective() {
    return {
        template: tsMultiSelectView,
        restrict: 'E',
        scope: {},
        bindToController: {
            // Models
            inputModel: ' =',
            outputModel: ' =',
            inputId: '@',
            // Callbacks
            onItemSelect: '&',
            onItemDeselect: '&'
        },
        controller: TsMultiSelectController,
        controllerAs: 'vm',
        require: 'tsMultiSelect',
        link: linkFn
    };
}
