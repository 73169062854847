'use strict';

import get from 'lodash/get';
import {EVENT_LEVELS} from '@techsee/techsee-common/lib/constants/event-logs.constants';
import {PlatformType} from '@techsee/techsee-common/lib/constants/utils.constant';
import {sanitizeChatMessage} from '@techsee/techsee-common/lib/utils';
import isEmpty from 'lodash/isEmpty';
import {UrlUtilsService} from '@techsee/techsee-client-services/lib/services/UrlUtilsService';

export class EventLogService {
    constructor(LogEvents, db) {
        'ngInject';

        this.LogEvents = LogEvents;
        this.EventLevels = EVENT_LEVELS;
        this.db = db;
        this._room = LogEvents.NO_ROOM;
        this._isObserve = this._isObserver();
    }

    /*
     * Getters and setters
     */
    set room(room) {
        this._room = room;
        this._roomsInstance = this.db.Rooms.createInstance({_id: this._room});
    }

    get room() {
        return this._room;
    }

    set userId(userId) {
        this._userId = userId;
    }

    get userId() {
        return this._userId;
    }

    /**
     * PRIVATE
     *
     * Posts event to event log API
     * @param  {Integer} event  Event type
     * @param  {String}  level  Event level
     * @param  {Object}  meta   Event meta data
     *
     * @return Promise object
     */
    _log(event, metaData, level) {
        let meta = metaData;

        if (this._isObserve) {
            meta = isEmpty(meta) ? {isObserve: true} : {...meta, isObserve: true};
        }

        const data = {
            userId: this.userId,
            room: this.room,
            type: get(event, 'type') || event,
            level: get(event, 'level') || level,
            sentBy: PlatformType.dashboard,
            meta
        };

        return this.db.EventLog.create(data);
    }

    _isObserver() {
        return UrlUtilsService.extractQueryParamValueFromUrl(window.location.href, 'observe');
    }

    /**
     * User clicked Send button
     */
    SMSInvited(meta) {
        return this._log(this.LogEvents.events.SMSInvited, meta);
    }

    connectivityCheck(meta) {
        return this._log(this.LogEvents.events.connectivityCheck, meta, 'INFO');
    }

    /**
     * User clicked Send by Email button
     */
    emailInvited() {
        return this._log(this.LogEvents.events.emailInvited);
    }

    savingHistoryImage(meta) {
        return this._log(this.LogEvents.events.savingHistoryImage, meta);
    }

    savingLibraryImage(meta) {
        return this._log(this.LogEvents.events.savingLibraryImage, meta);
    }

    dashboardSendingImage(meta) {
        return this._log(this.LogEvents.events.dashboardSendingImage, meta);
    }

    openingLibraryDialog(meta) {
        return this._log(this.LogEvents.events.openingLibraryDialog, meta);
    }

    savingResource(meta) {
        return this._log(this.LogEvents.events.savingResource, meta);
    }

    savingSnapshot(meta) {
        return this._log(this.LogEvents.events.savingSnapshot, meta);
    }

    uploadingLocalImage(meta) {
        return this._log(this.LogEvents.events.uploadingLocalImage, meta);
    }

    uploadingClipboardImage(meta) {
        return this._log(this.LogEvents.events.uploadingClipboardImage, meta);
    }

    imageUploadStarted(meta) {
        return this._log(this.LogEvents.events.imageUploadStarted, meta);
    }

    imageUploadSuccess(meta) {
        return this._log(this.LogEvents.events.imageUploadSuccess, meta);
    }

    imageUploadFailed(meta) {
        return this._log(this.LogEvents.events.imageUploadFailed, meta);
    }

    imageSendingFailed(meta) {
        return this._log(this.LogEvents.events.imageSendingFailed, meta);
    }

    agentImageDownloadStarted(meta) {
        return this._log(this.LogEvents.events.agentImageDownloadStarted, meta);
    }

    agentImageDownloadSuccess(meta) {
        return this._log(this.LogEvents.events.agentImageDownloadSuccess, meta);
    }

    agentImageDownloadFailed(meta) {
        return this._log(this.LogEvents.events.agentImageDownloadFailed, meta);
    }

    agentLoadedCustomerImage(meta) {
        return this._log(this.LogEvents.events.agentLoadedCustomerImage, meta);
    }

    retryingImageDownload(meta) {
        return this._log(this.LogEvents.events.retryingImageDownload, meta);
    }

    videoDownloadFailed(meta) {
        return this._log(this.LogEvents.events.videoDownloadFailed, meta);
    }

    videoReady() {
        return this._log(this.LogEvents.events.videoReady);
    }

    videoStreamFailed(meta) {
        return this._log(this.LogEvents.events.videoStreamFailed, meta);
    }

    failedToStartRecording(meta) {
        return this._log(this.LogEvents.events.failedToStartRecording, meta);
    }

    failedToStopRecording(meta) {
        return this._log(this.LogEvents.events.failedToStopRecording, meta);
    }

    /**
     * Saves mouse share event to event log
     */
    mouseShareActivated() {
        return this._log(this.LogEvents.events.mouseShareActivated);
    }

    /**
     * Mobile client opened camera
     */
    cameraOpened() {
        return this._log(this.LogEvents.events.cameraOpened);
    }

    idleNotification() {
        return this._log(this.LogEvents.events.idleNotification);
    }

    opentok(meta) {
        return this._log(this.LogEvents.events.opentok, meta);
    }

    opentokError(meta) {
        return this._log(this.LogEvents.events.opentokError, meta);
    }

    turn(meta) {
        return this._log(this.LogEvents.events.turn, meta);
    }

    turnError(meta) {
        return this._log(this.LogEvents.events.turnError, meta);
    }

    clientLocation(meta) {
        return this._log(this.LogEvents.events.clientLocation, meta);
    }

    clientLocationDenied() {
        return this._log(this.LogEvents.events.clientLocationDenied);
    }

    clientLocationError() {
        return this._log(this.LogEvents.events.clientLocationError);
    }

    clientLocationAddress(meta) {
        return this._log(this.LogEvents.events.clientLocationAddress, meta);
    }

    clientLocationAddressError() {
        return this._log(this.LogEvents.events.clientLocationAddressError);
    }

    uncaughtException(meta) {
        return this._log(this.LogEvents.events.uncaughtException, meta);
    }

    roomCancelled() {
        return this._log(this.LogEvents.events.roomCancelled);
    }

    mobileDisconnected() {
        return this._log(this.LogEvents.events.mobileDisconnected);
    }

    videoBandwidthStabilized(meta) {
        return this._log(this.LogEvents.events.videoBandwidthStabilized, meta);
    }

    lowVideoBandwidthDetected(meta) {
        return this._log(this.LogEvents.events.lowVideoBandwidthDetected, meta);
    }

    connectivityDifficulties() {
        return this._log(this.LogEvents.events.connectivityDifficulties);
    }

    imageUploadReason(meta) {
        return this._log(this.LogEvents.events.imageUploadReason, meta);
    }

    photoReason(meta) {
        return this._log(this.LogEvents.events.photoReason, meta);
    }

    agentSwitchedToImageUpload() {
        return this._log(this.LogEvents.events.agentSwitchedToImageUpload);
    }

    agentSwitchedToVideoStream() {
        return this._log(this.LogEvents.events.agentSwitchedToVideoStream);
    }

    agentSwitchedToScreenShare() {
        return this._log(this.LogEvents.events.agentSwitchedToScreenShare);
    }

    agentSwitchedToCoBrowsing() {
        return this._log(this.LogEvents.events.agentSwitchedToCoBrowsing);
    }

    agentSwitchedToPhoto() {
        return this._log(this.LogEvents.events.agentSwitchedToPhoto);
    }

    agentScreenshotFailed(meta) {
        return this._log(this.LogEvents.events.agentScreenshotFailed, meta);
    }

    webRTCSupported(meta) {
        return this._log(this.LogEvents.events.webRTCSupported, meta);
    }

    dashboardModuleLoaded(meta) {
        return this._log(this.LogEvents.events.dashboardModuleLoaded, meta);
    }

    dashboardInviteViewLoaded(meta) {
        return this._log(this.LogEvents.events.dashboardInviteViewLoaded, meta);
    }

    dashboardMainViewLoaded(meta) {
        return this._log(this.LogEvents.events.dashboardMainViewLoaded, meta);
    }

    lowBandwidthDialogResolution(meta) {
        return this._log(this.LogEvents.events.lowBandwidthDialogResolution, meta);
    }

    userAgent(meta) {
        return this._log(this.LogEvents.events.userAgent, meta);
    }

    shareImageSaveLocallySuccess(meta) {
        return this._log(this.LogEvents.events.shareImageSaveLocallySuccess, meta);
    }

    shareImageSaveLocallyDownloadFailure(meta) {
        return this._log(this.LogEvents.events.shareImageSaveLocallyDownloadFailure, meta);
    }

    shareImageSaveLocallySaveFailure(meta) {
        return this._log(this.LogEvents.events.shareImageSaveLocallySaveFailure, meta);
    }

    shareImageCopyToClipboardSuccess(meta) {
        return this._log(this.LogEvents.events.shareImageCopyToClipboardSuccess, meta);
    }

    shareMultipleImagesCopyToClipboardSuccess(meta) {
        return this._log(this.LogEvents.events.shareMultipleImagesCopyToClipboardSuccess, meta);
    }

    shareImageCopyToClipboardUploadFailure(meta) {
        return this._log(this.LogEvents.events.shareImageCopyToClipboardUploadFailure, meta);
    }

    shareMultipleImagesCopyToClipboardCopyFailure(meta) {
        return this._log(this.LogEvents.events.shareMultipleImagesCopyToClipboardCopyFailure, meta);
    }

    shareImageCopyToClipboardCopyFailure(meta) {
        return this._log(this.LogEvents.events.shareImageCopyToClipboardCopyFailure, meta);
    }

    shareCopyTextToClipboardSuccess(meta) {
        return this._log(this.LogEvents.events.shareCopyTextToClipboardSuccess, meta);
    }

    shareCopyTextToClipboardFailure(meta) {
        return this._log(this.LogEvents.events.shareCopyTextToClipboardFailure, meta);
    }

    shareImageFetchSignedDataFailure(meta) {
        return this._log(this.LogEvents.events.shareImageFetchSignedDataFailure, meta);
    }

    snapshotReminderShown(meta) {
        return this._log(this.LogEvents.events.snapshotReminderShown, meta);
    }

    snapshotReminderClosed(meta) {
        return this._log(this.LogEvents.events.snapshotReminderClosed, meta);
    }

    receivedMessage(meta) {
        return this._log(this.LogEvents.events.receivedMessage, sanitizeChatMessage(meta));
    }

    recognizeStart(meta) {
        return this._log(this.LogEvents.events.recognizeStart, meta);
    }

    recognizeSuccess(meta) {
        return this._log(this.LogEvents.events.recognizeSuccess, meta);
    }

    recognizeFail(meta) {
        return this._log(this.LogEvents.events.recognizeFail, meta);
    }

    analyzeStart(meta) {
        return this._log(this.LogEvents.events.analyzeStart, meta);
    }

    analyzeSuccess(meta) {
        return this._log(this.LogEvents.events.analyzeSuccess, meta);
    }

    analyzeFail(meta) {
        return this._log(this.LogEvents.events.analyzeFail, meta);
    }

    audioSupportDetected() {
        return this._log(this.LogEvents.events.audioSupportDetected);
    }

    audioIsNotSupported(meta) {
        return this._log(this.LogEvents.events.audioIsNotSupported, meta);
    }

    failedToEstablishAudioStreaming(meta) {
        return this._log(this.LogEvents.events.failedToEstablishAudioStreaming, meta);
    }

    sendFlashfightGuidance(meta) {
        return this._log(this.LogEvents.events.flashlightGuidanceSent, meta);
    }

    sessionSummary(meta) {
        return this._log(this.LogEvents.events.sessionSummary, meta);
    }

    magicMarkerDrawingReceived() {
        return this._log(this.LogEvents.events.magicMarkerDrawingReceived);
    }

    magicMarkerDrawingSent() {
        return this._log(this.LogEvents.events.magicMarkerDrawingSent);
    }

    arMeasurementActionExecuted(meta) {
        return this._log(this.LogEvents.events.arMeasurementActionExecuted, meta);
    }

    postResourceToSession(meta) {
        return this._log(this.LogEvents.events.postResourceToSession, meta);
    }

    warmTransferRoomActivationSucceeded(meta) {
        return this._log(this.LogEvents.events.warmTransferRoomActivationSucceeded, meta);
    }

    warmTransferRoomActivationFailed(meta) {
        return this._log(this.LogEvents.events.warmTransferRoomActivationFailed, meta);
    }

    getInteractionSummaryFailed(meta) {
        return this._log(this.LogEvents.events.getInteractionSummaryFailed, meta);
    }

    getInteractionSummarySuccess() {
        return this._log(this.LogEvents.events.getInteractionSummarySuccess);
    }

    getInteractionSummaryPreviewsFailed(meta) {
        return this._log(this.LogEvents.events.getInteractionSummaryPreviewsFailed, meta);
    }

    getInteractionSummaryPreviewsSuccess() {
        return this._log(this.LogEvents.events.getInteractionSummaryPreviewsSuccess);
    }

    desktopShareAgentRequestRemoteControl() {
        return this._log(this.LogEvents.events.desktopShareAgentRequestRemoteControl);
    }

    desktopShareAgentStoppedRemoteControl() {
        return this._log(this.LogEvents.events.desktopShareAgentStoppedRemoteControl);
    }

    desktopShareClientApprovedRemoteControl() {
        return this._log(this.LogEvents.events.desktopShareClientApprovedRemoteControl);
    }

    desktopShareClientStoppedRemoteControl() {
        return this._log(this.LogEvents.events.desktopShareClientStoppedRemoteControl);
    }

    cameraApprovalGuidanceClosed(meta) {
        return this._log(this.LogEvents.events.cameraApprovalGuidanceClosed, meta);
    }

    coBrowsingSurflySessionFailed(meta) {
        return this._log(this.LogEvents.events.coBrowsingSurflySessionFailed, meta);
    }

    coBrowsingChangeUrl() {
        return this._log(this.LogEvents.events.coBrowsingSurflyChangeUrl);
    }

    coBrowsingSwitchControl(meta) {
        return this._log(this.LogEvents.events.coBrowsingSurflySwitchControl, meta);
    }

    coBrowsingSurflySessionSuccess() {
        return this._log(this.LogEvents.events.coBrowsingSurflySessionSuccess);
    }

    trace(metaData) {
        return this._log(this.LogEvents.events.trace, metaData);
    }

    traceErr(metaData) {
        return this._log(this.LogEvents.events.traceErr, metaData);
    }

    failedToInitVizSdk(meta) {
        return this._log(this.LogEvents.events.failedToInitVizSdk, meta);
    }

    libraryLoadingFailed(meta) {
        return this._log(this.LogEvents.events.libraryLoadingFailed, meta);
    }
}
