'use strict';
import tsFloatingToolbarView from './ts-floating-toolbar.view.html';
import {TsFloatingToolbarController} from './ts-floating-toolbar.controller';

export function tsFloatingToolbar() {
    return {
        template: tsFloatingToolbarView,
        scope: true,
        controller: TsFloatingToolbarController,
        controllerAs: 'vm'
    };
}
