import React from 'react';
import {Video} from '../../../video/Video';
import {CustomerEndedTheMeeting} from '../../../customerEndedTheMeeting/CustomerEndedTheMeeting';
import {MultipartyDashboardController} from '../../../../MultipartyDashboardController';
import {ScreenLoader} from '../../../../../../components/screen-loader/screen-loader';
import {observer} from 'mobx-react';

type MainProps = {
    controller: MultipartyDashboardController;
};

export const Main: React.FC<MainProps> = observer(({controller}) => {
    if (controller.customerEndedTheMeeting) {
        return <CustomerEndedTheMeeting />;
    }

    return controller.showLoader ? <ScreenLoader /> : <Video />;
});
