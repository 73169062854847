'use strict';

import {
    IClientAsDashboardSMSParams,
    IDbRooms,
    ISendSmsParams
} from '../../_react_/services/AngularServices/AngularServices';
import {ISmsService, SmsService} from '../../_react_/services/SmsService';
import {ISmsSettings} from '../../_react_/models/AccountSettings';
import {ICallback, IPromptCallback, ISmsData} from './ts-sms.settings';
import {InviteEventsLogger} from '../../_react_/states/invite-new/invite.flow.logger';
import {getRootStore} from '../../_react_/app.bootstrap';
import {DbSmsAdapter} from '../../_react_/services/AngularServices/SmsAdapter';

export class TsSmsService {
    private smsService?: ISmsService;

    private inviteFlowLogger?: InviteEventsLogger;

    init(
        dbService: any,
        accountSmsSettings: ISmsSettings,
        accountId: string,
        agentId: string,
        keepCustomerMobile: boolean
    ) {
        const dbSmsAdapter = new DbSmsAdapter(dbService);

        this.inviteFlowLogger = new InviteEventsLogger(getRootStore().eventsLogService, accountId, agentId, {
            keepCustomerMobile
        });
        this.smsService = new SmsService(
            dbService.Rooms as IDbRooms,
            dbSmsAdapter,
            accountSmsSettings,
            this.inviteFlowLogger
        );
    }

    sendSMS(smsData: ISmsData, eventCb: ICallback, allowPrompt: boolean, promptCb: IPromptCallback) {
        if (!this.smsService) {
            throw new Error('TsSmsService is not initialized, please ensure you execute init method.');
        }

        return this.smsService.sendSms(smsData, eventCb, allowPrompt, promptCb);
    }

    sendNotificationSms(smsData: ISendSmsParams) {
        if (!this.smsService) {
            throw new Error('TsSmsService is not initialized, please ensure you execute init method.');
        }

        return this.smsService.sendSimpleSms(smsData);
    }

    setRoomId(roomId: string) {
        if (!this.smsService) {
            throw new Error('TsSmsService is not initialized, please ensure you execute init method.');
        }

        this.inviteFlowLogger!.setRoomId(roomId);
    }

    cleanup() {
        this.smsService && this.smsService.abortSendSms();
    }

    sendClientAsDashboard(data: IClientAsDashboardSMSParams) {
        return this.smsService && this.smsService.sendClientAsDashboard(data);
    }

    sendObserverInvitation(data: IClientAsDashboardSMSParams) {
        return this.smsService && this.smsService.sendObserverInvitation(data);
    }
}
