import React from 'react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {LeftBar} from './leftBar/LeftBar';
import {observer} from 'mobx-react';
import {Main} from './main/Main';

import './liveDashboardLayoutStyles.scss';
import {TopBar} from './topBar/ToptBar';
import {EndMeetingModal} from './endMeetingModal/EndMeetingModal';
import {MultipartyDashboardController} from '../../../MultipartyDashboardController';
import {CustomerEndedTheMeetingModal} from './customerEndedTheMeetingModal/CustomerEndedTheMeetingModal';
import {ClientWebRtcNotSupportedModal} from './clientWebRtcNotSupportedModal/ClientWebRtcNotSupportedModal';
import {RightBar} from './rightBar/RightBar';
import {MessageAlert} from '@techsee/techsee-ui-common/lib/message-alert';

type LiveDashboardLayoutProps = {
    controller: MultipartyDashboardController;
};

@observer
export class LiveDashboardLayout extends TechseeBaseComponent<LiveDashboardLayoutProps> {
    renderInternal(): JSX.Element {
        const {controller} = this.props;

        return (
            <div className='live-dashboard-root-layout'>
                <EndMeetingModal />
                <CustomerEndedTheMeetingModal />
                <ClientWebRtcNotSupportedModal />
                {controller.messageAlert?.display && (
                    <MessageAlert
                        label={controller.messageAlert?.label}
                        color={controller.messageAlert?.color}></MessageAlert>
                )}

                <TopBar />
                <div className='live-dashboard-middle-area'>
                    <LeftBar />
                    <div className='live-dashboard-main-content'>
                        <Main controller={controller} />
                    </div>
                    {controller.showGallery && <RightBar />}
                </div>
            </div>
        );
    }
}
