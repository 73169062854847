'use strict';
import tsSoundToggleView from './ts-sound-toggle.view.html';
import './ts-sound-toggle.style.scss';

export function tsSoundToggle() {
    return {
        template: tsSoundToggleView,
        scope: true
    };
}
