/* eslint-disable max-classes-per-file */
// TODO: Move each class to the dedicated file
import React from 'react';
import {observer} from 'mobx-react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {InfoBoxComponent} from '../../invite-new/invite-top-bar/info-box/component';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {InfoBoxCollapsible} from '@techsee/techsee-ui-common/lib/info-box-collapsible';
import {IDashboardTopBarController} from './controller';
import {InfoBox} from '@techsee/techsee-ui-common/lib/info-box';
import {IconButton} from '@techsee/techsee-ui-common/lib/forms/buttons/icon';
import {AbortButton} from '@techsee/techsee-ui-common/lib/forms/buttons/abort';
import {BorderRoundedIconButton} from '@techsee/techsee-ui-common/lib/forms/buttons/border-rounded-icon';
import {IconColors, IconSizes} from '@techsee/techsee-ui-common/lib/icons/large-rounded-icon';
import {ContextMenuWrapper} from '@techsee/techsee-ui-common/lib/menus/context-menu';
import {MenuItem} from '@techsee/techsee-ui-common/lib/menus/menu-items/MenuItem';
import {RootCloseWrapper} from 'react-overlays';
import {LocationInfoComponent} from './components/location-info/component';
import {PLACEMENT_TYPE} from '@techsee/techsee-ui-common/lib/popover-panel';
import {locales} from '@techsee/techsee-common/lib/helpers/i18n.settings';
import find from 'lodash/find';
import {getRootStore} from '../../../app.bootstrap';

import './styles.scss';

export interface IDashboardTopBar {
    controller: IDashboardTopBarController;
}

@observer
class UserDataContainer extends TechseeBaseComponent<IDashboardTopBar> {
    renderInternal() {
        const {controller} = this.props;
        const connectionInfoData = controller.userConnectionInfo;
        const clientTypeTitle = controller.usingApplication
            ? 'REACT.INVITE.VIEW.APPLICATION'
            : 'REACT.INVITE.VIEW.BROWSER';
        const clientTypeData = controller.usingApplication ? controller.applicationId : controller.clientBrowser;
        const clientLanguage = controller.multiLanguage && controller.clientLanguage;
        const {localizationService} = getRootStore();

        let BottomElement = () => <div></div>;

        const getLangTooltip: any = (languageName: string) => (
            <Tooltip className={'language-tooltip'} id={'language-tooltip'}>
                {controller.translate('REACT.INVITE.VIEW.TOPBAR.LANGUAGE', {language: languageName})}
            </Tooltip>
        );

        if (clientLanguage) {
            const lang = find(locales, {value: clientLanguage});

            BottomElement = () => (
                <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement={PLACEMENT_TYPE.BOTTOM}
                    overlay={getLangTooltip(lang ? lang.name : '')}>
                    <div className={'language'}>{lang ? lang.label : ''}</div>
                </OverlayTrigger>
            );
        }

        return (
            <div className={'section-container'}>
                <div className='section section-box session-info' dir={localizationService.getTextDirection()}>
                    <InfoBoxCollapsible
                        content={() => (
                            <InfoBox
                                className={'info-box'}
                                upperLine={() => (
                                    <InfoBoxComponent
                                        displayHeaders={true}
                                        upperTitle={'REACT.INVITE.VIEW.REF_ID'}
                                        upperData={controller.inviteMethodInfo.customerRef}
                                        bottomTitle={`REACT.INVITE.VIEW.${controller.inviteMethodInfo.addressType}`}
                                        bottomData={controller.inviteMethodInfo.address}
                                        iconName={''}
                                        translate={controller.translate}></InfoBoxComponent>
                                )}
                                bottomLine={() => (
                                    <div
                                        className={'same-line btn-link'}
                                        onClick={() => controller.onCopyClick(controller.sessionInfo.sessionLink)}>
                                        <span className={'room-link'}>
                                            {controller.translate('REACT.INVITE.VIEW.LINK')}
                                        </span>
                                        <IconButton iconName={'copy'} />
                                    </div>
                                )}
                                icon={''}></InfoBox>
                        )}
                        icon={'user'}
                        id={'collapsible-popover'}
                        bottomElement={BottomElement}></InfoBoxCollapsible>
                </div>
                <div className='section section-box network-info' dir={localizationService.getTextDirection()}>
                    <InfoBoxComponent
                        displayHeaders={true}
                        upperTitle={connectionInfoData.topLabel}
                        upperData={connectionInfoData.topLine}
                        bottomTitle={''}
                        bottomData={connectionInfoData.bottomLine}
                        iconName={connectionInfoData.icon}
                        translate={controller.translate}></InfoBoxComponent>
                </div>
                <div className='section section-box' dir={localizationService.getTextDirection()}>
                    <InfoBoxComponent
                        displayHeaders={true}
                        upperTitle={'REACT.INVITE.VIEW.TYPE'}
                        upperData={controller.clientType}
                        bottomTitle={clientTypeTitle}
                        bottomData={clientTypeData}
                        iconName={controller.clientTypeIcon}
                        translate={controller.translate}></InfoBoxComponent>
                </div>
                {controller.enableMobileGeolocation && (
                    <LocationInfoComponent ctrl={controller.locationInfoController} />
                )}
            </div>
        );
    }
}

@observer
class ImagesManagement extends TechseeBaseComponent<IDashboardTopBar> {
    renderInternal() {
        const {controller} = this.props;
        const {
            disabledSaveButton,
            showSaveButton,
            showSendButton,
            disabledSendButton,
            showSharingButton,
            disabledSharingButton,
            showImagesManagement
        } = controller;
        const shouldOpenShareMenu = controller.shouldOpenShareMenu;

        // FIXME: Please, do not create any functions inside render method due to perfotmance reasons!!!
        const SaveButton = showSaveButton
            ? () => (
                  <BorderRoundedIconButton
                      disabled={disabledSaveButton}
                      colorName={IconColors.Blue}
                      iconName={'save'}
                      onClick={controller.onSaveResource}
                      noBorder={true}
                      reverseColor={true}
                      className={'save-button'}
                  />
              )
            : () => <React.Fragment />;

        const SendButton = showSendButton
            ? () => (
                  <BorderRoundedIconButton
                      disabled={disabledSendButton}
                      colorName={IconColors.Blue}
                      iconName={'send'}
                      onClick={controller.onSendImage}
                      noBorder={true}
                      reverseColor={true}
                      className={'send-button'}
                  />
              )
            : () => <React.Fragment />;

        const SharingButton = showSharingButton
            ? () => (
                  <BorderRoundedIconButton
                      disabled={disabledSharingButton}
                      colorName={IconColors.Blue}
                      iconName={'share-by'}
                      noBorder={true}
                      reverseColor={true}
                      className={'share-button'}
                  />
              )
            : () => <React.Fragment />;

        const ImagesContainer = showImagesManagement
            ? () => (
                  <div className='buttons-container'>
                      <RootCloseWrapper event={'click'} onRootClose={controller.onShareClosed}>
                          <ContextMenuWrapper
                              uid={'agent-info-context-menu'}
                              menuItemsData={controller.getContextMenuItems()}
                              itemComponent={MenuItem}
                              className={'agent-info-menu-wrapper'}
                              shouldOpen={shouldOpenShareMenu}>
                              <SharingButton />
                          </ContextMenuWrapper>
                      </RootCloseWrapper>
                      <SaveButton />
                      <SendButton />
                  </div>
              )
            : () => <React.Fragment />;

        return <ImagesContainer />;
    }
}

@observer
export class DashboardMainTopBar extends TechseeBaseComponent<IDashboardTopBar> {
    renderInternal() {
        const {controller} = this.props;
        let classEndSection = 'section session-end splitter abort-session';

        classEndSection = controller.isOfflineRoom ? classEndSection + ' session-end-with-offline' : classEndSection;

        return (
            <React.Fragment>
                <UserDataContainer controller={controller} />
                <ImagesManagement controller={controller} />
                <div className={classEndSection}>
                    {!controller.isObserve && !controller.isOfflineMode && (
                        <AbortButton className={'abort-btn'} onClick={controller.onAbortSession} />
                    )}
                    {controller.isOfflineRoom ||
                    (controller.isObserve && !controller.isOfflineMode) ||
                    controller.isOfflineMode ? (
                        <BorderRoundedIconButton
                            className={'offline-end'}
                            iconName={'logout'}
                            colorName={IconColors.Blue}
                            reverseColor={true}
                            sizeName={IconSizes.Large}
                            noBorder={true}
                            onClick={controller.onLeaveOfflineRoom}
                        />
                    ) : (
                        <React.Fragment />
                    )}
                </div>
            </React.Fragment>
        );
    }
}
