import React from 'react';
import {inject, IReactComponent, observer} from 'mobx-react';
import {CollapsiblePanelAnimated, ExpandState} from '@techsee/techsee-ui-common/lib/collapsible-panel-animated';
import {Stores} from '../../MultipartyDashboardStores';
import {GalleryController, Image} from './GalleryController';
import emptyGalleryPlaceholderImage from '../../../../../../img/empty-gallery-placeholder.png';
import failedImagePlaceholder from '../../../../../../img/failed-image-placeholder.png';

import './GalleryLive.scss';
import {
    Spinner,
    SpinnerBackgroundColors,
    SpinnerColors,
    SpinnerSizes,
    SpinnerTypeOptions
} from '@techsee/techsee-ui-common/lib/spinner';

interface GalleryLiveStoreProps {
    controller: GalleryController;
    translate: (key: string) => string;
}

const Loader: React.FC<{id: string}> = ({id}) => (
    <div key={id} className='gallery-image-live-spinner use-common-styles'>
        <Spinner
            spinnerType={SpinnerTypeOptions.CIRCULAR}
            sizeName={SpinnerSizes.Small}
            backgroundColorName={SpinnerBackgroundColors.Transparent}
            colorName={SpinnerColors.White}
        />
    </div>
);

const mapImages = (image: Image) => {
    if (image.isLoading) {
        return <Loader id={image.id} />;
    }
    if (image.isFailed) {
        return (
            <div key={image.id} className='failed-image'>
                <img src={failedImagePlaceholder} alt='Failed image' />
            </div>
        );
    }

    return <img src={image.image} key={image.id} className='gallery-image-live' alt='Image' />;
};

const GalleryComponent: React.FC<GalleryLiveStoreProps> = observer(({translate, controller}) => {
    const emptyGalleryPlaceholder = (
        <div className='empty-gallery-wrapper'>
            <img src={emptyGalleryPlaceholderImage} alt='No images yet' />
            <div className='empty-gallery-text'>{translate('MAIN.VIEW.NO_IMAGES_YET')}</div>
        </div>
    );

    const images = controller.images.map(mapImages).reverse();

    return (
        <CollapsiblePanelAnimated title={translate('HISTORY.VIEW.IMAGES')} expandState={ExpandState.ALWAYS_EXPANDED}>
            {controller.images.length === 0 ? emptyGalleryPlaceholder : images}
        </CollapsiblePanelAnimated>
    );
});

const mapStoresToProps = (stores: Stores): GalleryLiveStoreProps => ({
    translate: stores.localizationService.translate,
    controller: stores.galleryController
});

export const GalleryLive = inject(mapStoresToProps)(GalleryComponent) as IReactComponent;
