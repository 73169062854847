import React from 'react';
import {observer} from 'mobx-react';
import {IPendingRoomSessionsController} from './controller';
import {Table} from '@techsee/techsee-ui-common/lib/table';
import {ButtonTypeAttributes} from '@techsee/techsee-ui-common/lib/forms/buttons/generic';
import {PrimaryButton} from '@techsee/techsee-ui-common/lib/forms/buttons/primary';
import {IconButton} from '@techsee/techsee-ui-common/lib/forms/buttons/icon';
import {Icon} from '@techsee/techsee-ui-common/lib/icons/simple-icon';
import {SESSION_SOURCE_MAP} from '@techsee/techsee-common/lib/constants/room.constants';
import moment from 'moment';
import {ConfirmationModal} from '@techsee/techsee-ui-common/lib/modals/confirmation-modal';
import {SimpleLabel} from '@techsee/techsee-ui-common/lib/forms/labels/simple';
import classNames from 'classnames';
import './styles.scss';

export interface IPendingRoomSessions {
    ctrl: IPendingRoomSessionsController;
    onJoin: (sessionId: string, byId?: boolean) => Promise<void>;
}

@observer
export class PendingRoomSessions extends React.Component<IPendingRoomSessions> {
    render() {
        const {
            translate,
            pendingRoomSessions,
            selectSession,
            selectedSession,
            isJoinAvailable,
            onJoinbyLink,
            sendSMS,
            isSendSMSModalVisible,
            closeModal,
            openSendSMSModal,
            notificationMessageText,
            notificationMessageStatus
        } = this.props.ctrl;

        const TRANSLATE_PREFIX = 'REACT.INVITE.PENDING_ROOM_TABLE';

        return (
            <div className='offline-sessions'>
                <div className='offline-sessions__title'>{translate(`${TRANSLATE_PREFIX}.TITLE`)}</div>
                <Table striped bordered condensed hover responsive>
                    <thead>
                        <tr>
                            <th className='offline-sessions__firstcol'></th>
                            <th>{translate('REACT.INVITE.VIEW.CUSTOMER_REF.LABEL')}</th>
                            <th>{translate(`${TRANSLATE_PREFIX}.CUSTOMER_NUMBER`)}</th>
                            <th>{translate(`${TRANSLATE_PREFIX}.VJ_NAME`)}</th>
                            <th>{translate(`${TRANSLATE_PREFIX}.SESSION_SOURCE`)}</th>
                            <th>{translate(`${TRANSLATE_PREFIX}.CREATION_TIME`)}</th>
                            <th>{}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pendingRoomSessions.map((session) => (
                            <tr key={session._id} onClick={() => selectSession(session)}>
                                <td>
                                    <input
                                        value={session._id}
                                        type='radio'
                                        checked={selectedSession._id === session._id}
                                    />
                                </td>
                                <td>{session.cid}</td>
                                <td>{session.phoneNumber}</td>
                                <td>{session.metaData?.vjName}</td>
                                <td>{(SESSION_SOURCE_MAP as any)[session.sessionType]}</td>
                                <td>{session.createdOn}</td>
                                <td>
                                    <IconButton
                                        onClick={() => openSendSMSModal()}
                                        className={'send-sms'}
                                        iconName={'sendsms'}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                {notificationMessageText && (
                    <SimpleLabel className={classNames('offline-session-label', notificationMessageStatus)}>
                        {notificationMessageText}
                    </SimpleLabel>
                )}
                <div className='offline-sessions__join'>
                    <PrimaryButton
                        disabled={!isJoinAvailable}
                        onClick={() => onJoinbyLink(this.props.onJoin)}
                        type={ButtonTypeAttributes.Submit}
                        className={'submit'}>
                        {translate('REACT.INVITE.VIEW.JOIN_OFFLINE_SESSION_SUBMIT')}
                        <Icon iconName='arrow-next' />
                    </PrimaryButton>
                    <ConfirmationModal
                        iconVisible={false}
                        header={translate('REACT.INVITE.PENDING_ROOM_SEND_SMS_POPUO_TITLE')}
                        content={translate('REACT.INVITE.PENDING_ROOM_SEND_SMS_POPUO_CONTENT', {
                            customer_ID: selectedSession.phoneNumber
                        })}
                        negativeText={translate('REACT.INVITE.PENDING_ROOM_SEND_SMS_POPUO_NEGATIVE_BUTTON')}
                        positiveText={translate('REACT.INVITE.PENDING_ROOM_SEND_SMS_POPUO_POSITIVE_BUTTON')}
                        show={isSendSMSModalVisible}
                        onPositiveClick={() => sendSMS()}
                        onNegativeClick={() => closeModal()}
                        className={'simple-modal'}
                    />
                </div>
            </div>
        );
    }
}
