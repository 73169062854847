import {GuidenceProgressItem} from '../../invite-new/invite-wizard/components/guidance-progress/GuidanceProgress';
import {IconColors} from '@techsee/techsee-ui-common/lib/icons/large-rounded-icon';

const screenSharePermissionProgress: GuidenceProgressItem[] = [
    {
        iconColor: IconColors.Green,
        label: 'REACT.INVITE.PERMISSIONS.SCREEN_SHARE.USER_PERMISSION',
        isActive: true,
        icon: 'check',
        isIconAnimated: false
    },
    {
        iconColor: IconColors.Blue,
        label: 'REACT.INVITE.PERMISSIONS.SCREEN_SHARE.TITLE',
        isActive: true,
        icon: 'screen',
        isIconAnimated: false,
        iconNoBorder: true
    }
];

const goToApplicationPermissionProgress: GuidenceProgressItem[] = [
    {
        iconColor: IconColors.Blue,
        label: 'REACT.DASHBOARD_GUIDANCE.PERMISSIONS.SCREEN_SHARE.STOP_MIRRORING',
        isActive: true,
        icon: 'screen',
        isIconAnimated: false,
        iconNoBorder: true
    },
    {
        iconColor: IconColors.Grey,
        label: 'REACT.DASHBOARD_GUIDANCE.PERMISSIONS.SCREEN_SHARE.CAMERA_PERMISSION',
        isActive: false,
        icon: 'camera',
        isIconAnimated: false,
        iconNoBorder: true
    }
];

const screenShareProgressTOS: GuidenceProgressItem[] = [
    {
        iconColor: IconColors.Blue,
        label: 'REACT.INVITE.PERMISSIONS.SCREEN_SHARE.USER_PERMISSION',
        isActive: true,
        icon: 'tos',
        isIconAnimated: false
    },
    {
        iconColor: IconColors.Blue,
        label: 'REACT.INVITE.PERMISSIONS.SCREEN_SHARE.TITLE',
        isActive: false,
        icon: 'screen',
        isIconAnimated: false,
        iconNoBorder: true
    }
];

const desktopSharingCameraApproval: GuidenceProgressItem[] = [
    {
        iconColor: IconColors.Green,
        label: 'REACT.INVITE.GUIDANCE.ACCEPT_TOS',
        isActive: true,
        icon: 'check',
        isIconAnimated: false
    },
    {
        iconColor: IconColors.Blue,
        label: 'REACT.INVITE.GUIDANCE.SHARING_TYPE_TITLE',
        isActive: true,
        icon: 'desktop-sharing',
        isIconAnimated: false
    }
];

export interface IGuidanceSettings {
    stepNum: number;
    stepType: string;
    stepText: string;
    headerTitle: string;
    headerSubTitle: string;
    progress: GuidenceProgressItem[];
    buttonText?: string;
}

export const TosGuidance = {
    stepNum: 1,
    stepType: 'terms-allow',
    stepText: 'REACT.INVITE.GUIDANCE.TAP_ALLOW',
    headerTitle: 'REACT.INVITE.PERMISSIONS.TERMS.TITLE',
    headerSubTitle: 'REACT.INVITE.PERMISSIONS.TERMS.SUB_TITLE',
    progress: []
};

export const TosRejected = {
    stepNum: 1,
    stepType: 'terms-reject',
    stepText: '',
    headerTitle: 'REACT.INVITE.GUIDANCE_REJECTED.TITLE',
    headerSubTitle: 'REACT.INVITE.GUIDANCE_REJECTED.SUB_TITLE',
    progress: [],
    buttonText: 'REACT.INVITE.GUIDANCE_REJECTED.BUTTON_TEXT'
};

export const AllowCameraGuidance = {
    stepNum: 1,
    stepType: 'camera-allow',
    stepText: 'REACT.INVITE.GUIDANCE.TAP_ALLOW',
    headerTitle: 'REACT.INVITE.PERMISSIONS.CAMERA.TITLE',
    headerSubTitle: 'REACT.INVITE.PERMISSIONS.CAMERA.SUB_TITLE',
    progress: []
};

export const AppShareTOS = {
    stepNum: 1,
    stepType: 'app-share-user',
    stepText: 'REACT.INVITE.PERMISSIONS.APP_SHARE.USER_PERMISSION',
    headerTitle: 'REACT.INVITE.PERMISSIONS.APP_SHARE.TITLE',
    headerSubTitle: 'REACT.INVITE.PERMISSIONS.APP_SHARE.SUB_TITLE',
    progress: []
};

export const ScreenShareTOS = {
    stepNum: 1,
    stepType: 'screen-share-user',
    stepText: 'REACT.INVITE.PERMISSIONS.SCREEN_SHARE.ANDROID_USER_PERMISSION',
    headerTitle: 'REACT.INVITE.PERMISSIONS.SCREEN_SHARE.TITLE',
    headerSubTitle: 'REACT.INVITE.PERMISSIONS.SCREEN_SHARE.SUB_TITLE',
    progress: screenShareProgressTOS
};

export const ScreenShareAa = {
    stepNum: 1,
    stepType: 'screen-share',
    stepText: 'REACT.INVITE.PERMISSIONS.SCREEN_SHARE.ANDROID_PERMISSION',
    headerTitle: 'REACT.INVITE.PERMISSIONS.SCREEN_SHARE.TITLE',
    headerSubTitle: 'REACT.INVITE.PERMISSIONS.SCREEN_SHARE.SUB_TITLE',
    progress: screenSharePermissionProgress
};

export const ScreenShareIaOne = {
    stepNum: 1,
    stepType: 'screen-share',
    stepText: 'REACT.INVITE.PERMISSIONS.SCREEN_SHARE.IOS_PRESS_START_BROADCAST',
    headerTitle: 'REACT.INVITE.PERMISSIONS.SCREEN_SHARE.TITLE',
    headerSubTitle: 'REACT.INVITE.PERMISSIONS.SCREEN_SHARE.SUB_TITLE',
    progress: screenSharePermissionProgress
};

export const ScreenShareIaTwo = {
    stepNum: 2,
    stepType: 'screen-share',
    stepText: 'REACT.INVITE.PERMISSIONS.SCREEN_SHARE.IOS_TAP_START_BROADCAST',
    headerTitle: 'REACT.INVITE.PERMISSIONS.SCREEN_SHARE.TITLE',
    headerSubTitle: 'REACT.INVITE.PERMISSIONS.SCREEN_SHARE.SUB_TITLE',
    progress: screenSharePermissionProgress
};

export const DesktopShareAllowSharingGuidance = {
    stepNum: 3,
    stepType: 'desktop-sharing',
    stepText: 'REACT.INVITE.GUIDANCE.SHARING_TYPE_PREFERENCE',
    headerTitle: 'REACT.INVITE.VIEW.DESKTOP_SHARING_TYPE_TITLE',
    headerSubTitle: 'REACT.INVITE.VIEW.DESKTOP_SHARING_TYPE_SUB_TITLE',
    progress: desktopSharingCameraApproval
};

export const GoToApplicationGuidance = {
    stepNum: 1,
    stepType: 'go-to-application',
    stepText: 'REACT.DASHBOARD_GUIDANCE.GUIDANCE.SCREEN_SHARE.STEP',
    headerTitle: 'REACT.DASHBOARD_GUIDANCE.GUIDANCE.SCREEN_SHARE.TITLE',
    headerSubTitle: 'REACT.DASHBOARD_GUIDANCE.GUIDANCE.SCREEN_SHARE.SUB_TITLE',
    progress: goToApplicationPermissionProgress
};

export const GoToApplicationNote = {
    stepNum: 'i',
    stepType: 'go-to-application',
    stepText: 'REACT.DASHBOARD_GUIDANCE.GUIDANCE.SCREEN_SHARE.STEP_NOTE',
    headerTitle: 'REACT.DASHBOARD_GUIDANCE.GUIDANCE.SCREEN_SHARE.TITLE',
    headerSubTitle: 'REACT.DASHBOARD_GUIDANCE.GUIDANCE.SCREEN_SHARE.SUB_TITLE',
    progress: goToApplicationPermissionProgress
};
