import React from 'react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {TsSessionSummaryService} from './ts-session-summary.service';
import {SessionSummary} from '../../../_react_/states/dashboard/session-summary/SessionSummary';

interface TsSessionSummaryProps {
    service: TsSessionSummaryService;

    onSummaryCompleted(): void;
}

export class TsSessionSummary extends TechseeBaseComponent<TsSessionSummaryProps> {
    renderInternal(): JSX.Element {
        return (
            <SessionSummary
                onSummaryCompleted={this.props.onSummaryCompleted}
                controller={this.props.service.controller!}
            />
        );
    }
}
