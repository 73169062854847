'use strict';

import filter from 'lodash/filter';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import every from 'lodash/every';
import startsWith from 'lodash/startsWith';
import find from 'lodash/find';
import {countryCodes} from '@techsee/techsee-country-codes/lib/index';

import tsCountrySelectView from './ts-country-select.view.html';
import './ts-country-select.style.scss';

class TsCountrySelectController {
    constructor($localStorage, $scope, $rootScope, $window, $translate, tsInterfaceHelper) {
        'ngInject';

        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.$localStorage = $localStorage;
        this.$window = $window;
        this.$translate = $translate;
        this.useNewInterface = tsInterfaceHelper.useNewInterface;
        $translate('INVITE.VIEW.MORE_COUNTRY_CODES').then((text) => {
            this.moreCountryCodes = text;
        });

        this.init();
    }

    onKeyChange() {
        this.keepCountryCodeOnNextUpdate = true;
    }

    selectedItemChange(item) {
        if (item && item.display === this.moreCountryCodes) {
            this.moreCountries = true;
            this.selectedCountryCodeClicked();
        }
    }

    _getCountryByCode(countryCode) {
        return find(this.allCountryCodes, (country) => country.code === countryCode);
    }

    _getAllDefaultCountries(defaultCountry) {
        return filter(this.allCountryCodes, (country) => country.dial === defaultCountry);
    }

    _updateCountries(codes) {
        this.allCountryCodes = map(codes, (country) => {
            country.adminSelect = false;

            return country;
        });

        const defaultFromAdmin = `+${this.accountCountryCode}`;
        const isDefaultCode = (country) => country.dial === defaultFromAdmin;

        const availableCountries = map(this.selectedCountries, (country) => this._getCountryByCode(country));
        const selectedCountryCodes = filter(availableCountries, (country) => !isDefaultCode(country));
        let defaultCountryCode = filter(availableCountries, (country) => isDefaultCode(country));

        if (isEmpty(defaultCountryCode)) {
            defaultCountryCode = this._getAllDefaultCountries(defaultFromAdmin);
        }

        this.model = defaultCountryCode[0].dial;
        this.lastModel = this.model;
        this.selectedCountries = defaultCountryCode.concat(selectedCountryCodes);
        this.selectedCountries.push({display: this.moreCountryCodes, dial: '', flag: '', code: ''});
    }

    init() {
        if (this.addCountryCodeToSms) {
            // Done async due to md-autocomplete limitation
            setTimeout(() => {
                this.model = this.accountCountryCode && `+${this.accountCountryCode}`;
                this.lastModel = this.model;
            }, 0);
        }
    }

    _createFilterFor(query) {
        const lowercaseQuery = query.toLowerCase();

        this.keepCountryCodeOnNextUpdate = true;

        return function (state) {
            return state.dial.indexOf(lowercaseQuery) >= 0 || state.display.toLowerCase().indexOf(lowercaseQuery) >= 0;
        };
    }

    getCountryCodes(query) {
        return Promise.resolve(countryCodes)
            .then((codes) => {
                if (!this.loaded) {
                    this._updateCountries(codes);
                    this.loaded = true;
                }

                return codes;
            })
            .then((codes) => {
                if (!this.moreCountries) {
                    return this.selectedCountries;
                }

                return codes;
            })
            .then((codes) => {
                let updatedCodes = codes;

                if (this.moreCountries) {
                    this.selectedCountries = filter(
                        this.selectedCountries,
                        (country) => country.display !== this.moreCountryCodes
                    );

                    const notSelectedCountries = filter(codes, (country) =>
                        every(this.selectedCountries, (selectedCountry) => country.dial !== selectedCountry.dial)
                    );

                    map(this.selectedCountries, (country) => {
                        country.adminSelect = true;
                    });

                    updatedCodes = this.selectedCountries.concat(notSelectedCountries);
                }

                if (isEmpty(query) || !this.keepCountryCodeOnNextUpdate || !this.initialCountryCodesGet) {
                    this.initialCountryCodesGet = true;

                    return updatedCodes;
                }

                this.keepCountryCodeOnNextUpdate = false;

                return updatedCodes.filter(this._createFilterFor(query));
            });
    }

    onCountryCodeBlur() {
        if (this.lastModel && !this.model) {
            this.model = this.lastModel;
        }
    }

    selectedCountryCodeClicked() {
        if (this.sms) {
            return;
        }

        if (this.model) {
            this.lastModel = this.model;
        }

        this.model = null;

        const input = angular.element('#countryCodes');

        input.focus();
        input.blur();
        input.focus();
    }

    getCurrentCountryCode() {
        if (startsWith(this.model, '+')) {
            return this.model.substring(1);
        }

        return this.model;
    }

    validateCountryCode(countryCode) {
        if (!countryCode) {
            return false;
        }

        if (!this.$localStorage.countryCodes) {
            return true;
        }

        const dial = `+${countryCode}`;

        return find(this.$localStorage.countryCodes, (code) => code.dial === dial);
    }
}

function linkFn(scope) {
    scope.vm.init();
    scope.control = scope.vm;
}

export function tsCountrySelectDirective() {
    return {
        restrict: 'E',
        template: tsCountrySelectView,
        scope: {
            control: '='
        },
        bindToController: {
            model: '=selectedCountryCodeText',
            addCountryCodeToSms: '=',
            accountCountryCode: '=',
            selectedCountries: '=',
            sms: '=',
            countryCodesPlaceHolder: '=',
            countryCodesNoMatch: '='
        },
        controller: TsCountrySelectController,
        controllerAs: 'vm',
        require: [],
        link: linkFn
    };
}
