'use strict';

import {getRootStore} from '../../_react_/app.bootstrap';
import forEach from 'lodash/forEach';

export class TsVideoUtilsService {
    constructor($rootScope, $location, $window, $q, db, ErrorDialogModal) {
        'ngInject';

        this.$rootScope = $rootScope;
        this.$location = $location;
        this.$q = $q;
        this.db = db;
        this.environmentDetect = getRootStore().environmentService;
        this.errorDialogModal = ErrorDialogModal;

        this.URL = $window.URL;
        this.PLAY_IMG_MIME = 'image/jpeg';
        this.PLAY_IMG_QUALITY = 0.8;
        this.SNAP_IMG_MIME = 'image/jpeg';
        this.SNAP_IMG_QUALITY = 0.8;

        this.preloader = this.$rootScope.requireImage('video-preloader.gif');
    }

    _snapToObjectUrl(dataUrl) {
        const bytes = atob(dataUrl.split(';base64,')[1]),
            buffer = new ArrayBuffer(bytes.length),
            rawData = new Uint8Array(buffer);

        for (let i = 0; i < bytes.length; i++) {
            rawData[i] = bytes.charCodeAt(i);
        }

        return this.URL.createObjectURL(new Blob([rawData], {type: this.PLAY_IMG_MIME}));
    }

    createThumbnail(url, playSymbolUrl) {
        return this.$q((resolve) => {
            const video = document.createElement('video');

            const snapImage = (video, cb) => {
                const canvas = document.createElement('canvas');

                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;

                const ctx = canvas.getContext('2d');

                ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

                if (!playSymbolUrl) {
                    const dataUrl = canvas.toDataURL(this.PLAY_IMG_MIME, this.PLAY_IMG_QUALITY);
                    const success = dataUrl.length > 1000;

                    cb(success ? this._snapToObjectUrl(dataUrl) : null);

                    return;
                }

                const img = new Image();

                img.onload = () => {
                    ctx.drawImage(
                        img,
                        0,
                        0,
                        img.width,
                        img.height,
                        canvas.width * 0.5 - img.width * 0.5,
                        canvas.height * 0.5 - img.height * 0.5,
                        img.width,
                        img.height
                    );

                    const dataUrl = canvas.toDataURL(this.PLAY_IMG_MIME, this.PLAY_IMG_QUALITY);
                    const success = dataUrl.length > 1000;

                    cb(success ? this._snapToObjectUrl(dataUrl) : null);
                };
                img.src = playSymbolUrl;
            };

            const timeupdate = function () {
                snapImage(video, function (snap) {
                    if (snap) {
                        video.removeEventListener('timeupdate', timeupdate);
                        video.pause();

                        resolve(snap);
                    }
                });
            };

            video.addEventListener('loadeddata', function () {
                snapImage(video, function (snap) {
                    if (snap) {
                        video.removeEventListener('timeupdate', timeupdate);

                        resolve(snap);
                    }
                });
            });

            video.addEventListener('timeupdate', timeupdate);
            video.crossOrigin = 'Anonymous';

            if (!this.environmentDetect.isIE11()) {
                video.preload = 'metadata';
            }

            video.src = url;

            // Load video in Safari / IE11
            video.muted = true;
            video.playsInline = true;

            const playPromise = video.play();

            if (playPromise) {
                playPromise.catch(() => {
                    // Automatic playback failed
                    this.errorDialogModal.show('ERROR_DIALOG.VIEW.VIDEO_FAILED_TO_LOAD');
                });
            }
        });
    }

    createThumbnailsForVideos(history) {
        return this.$q((resolve) => {
            if ((history && history.videos && history.videos > 0) || (history && history.hasVideoRecordings)) {
                const promises = [];

                forEach(history.records, (record) => {
                    forEach(record.videos, (video) => {
                        video.video = video.url;
                        video.isVideo = true;
                        video.url = this.preloader;

                        let promise;

                        if (video.isRecordedVideo) {
                            promise = this.createThumbnail(video.video, null);
                        } else {
                            promise = this.db.HistoryVideo.resign(video._id, {
                                bypassCache: true
                            }).then((resp) => {
                                if (!resp || !resp.data) {
                                    return;
                                }

                                video.video = resp.data.url;

                                return this.createThumbnail(resp.data.url, null);
                            });
                        }

                        promises.concat(
                            promise.then((thumbnail) => {
                                video.url = thumbnail;
                            })
                        );
                    });
                });

                this.$q.all(promises);
            }

            resolve(history);
        });
    }

    createSnapshot(videoEl) {
        return this.$q((resolve) => {
            if (!videoEl || !videoEl.src) {
                return resolve();
            }

            const canvas = document.createElement('canvas');

            canvas.width = videoEl.videoWidth;
            canvas.height = videoEl.videoHeight;

            const ctx = canvas.getContext('2d');

            ctx.drawImage(videoEl, 0, 0, canvas.width, canvas.height);

            const dataUrl = canvas.toDataURL(this.SNAP_IMG_MIME, this.SNAP_IMG_QUALITY);
            const success = dataUrl.length > 1000;

            resolve(success ? dataUrl : null);
        });
    }
}
