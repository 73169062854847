'use strict';

import some from 'lodash/some';
import values from 'lodash/values';
import isEmpty from 'lodash/isEmpty';
import './scan-result.style.scss';

import patterns from '@techsee/techsee-common/lib/constants/utils.patterns';

import {SCANNERS} from '../../../../services/ts-scan/ts-scan.settings';

export class ScanResultController {
    constructor($modalInstance, $timeout, $window, type, result, dir, searchTags) {
        'ngInject';

        this.$modalInstance = $modalInstance;
        this.$timeout = $timeout;
        this.$window = $window;
        this.type = type;
        this.result = result;
        this.dir = dir;
        this.searchTags = searchTags;
        this.copyResults = {};

        this.SCANNERS = SCANNERS;
    }

    resultsFound() {
        return this.result && some(values(this.result), (results) => !isEmpty(results));
    }

    displayText() {
        const {text} = this.result;

        return text;
    }

    get text() {
        const text = this.result.text;

        const raw = text.rawAligned || (text.lines && text.lines.join('\n')) || text.raw;

        return raw
            .split('\n')
            .map((substr) => substr.replace(patterns.url, '<a href="$&" target="_blank">$&</a>'))
            .join('\n');
    }

    getSelection(element) {
        const selection = this.$window.getSelection();

        if (!$(element).find(selection.focusNode).length) {
            return '';
        }

        return selection.toString();
    }

    copySuccess(e, id) {
        this.copyResults[id] = 'SCAN.RESULT.COPY_SUCCEED';
        e.clearSelection();

        this.clearMessage(id);
    }

    copyError(e, id) {
        this.copyResult[id] = 'SCAN.RESULT.COPY_FAILED';

        this.clearMessage(id);
    }

    clearMessage(id) {
        this.$timeout(1000).then(() => (this.copyResults[id] = ''));
    }

    close() {
        this.$modalInstance.close();
    }

    searchScanResult(i) {
        this.$modalInstance.close({
            search: true,
            searchTerm: this.results[i].value
        });
    }
}
