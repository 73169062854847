import {observable, action, computed} from 'mobx';
import {ITranslate} from '../../services/LocalizationService';
import {MeetingMode} from '@techsee/techsee-common/lib/constants/room.constants';
import {getIconNameByMode} from '../start-with-modes/component';
import {IStartWithModesSession} from '../start-with-modes/controller';

export interface IModeSwitchModalController {
    readonly translate: ITranslate;
    readonly isVisible: boolean;
    readonly modeTitle: string;
    readonly icons: string[];

    show(item: IStartWithModesSession, currentMode: MeetingMode): void;
    hide(): void;
    modeSwitchConfirmed(): void;
    modeSwitchRejected(): void;
}

export class ModeSwitchModalController implements IModeSwitchModalController {
    private readonly _translate: ITranslate;

    private readonly _handleModalResponse: (response: boolean, modes: any) => void;

    @observable private _modeTitle: string = '';

    @observable private _isVisible: boolean;

    @observable private _modes: {currentMode: {}; newMode: {}; icons: any[]} = {
        currentMode: {},
        newMode: {},
        icons: []
    };

    constructor(translate: ITranslate, handleModalResponse: (response: boolean) => void) {
        this._translate = translate;
        this._handleModalResponse = handleModalResponse;
        this._isVisible = false;

        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.modeSwitchConfirmed = this.modeSwitchConfirmed.bind(this);
        this.modeSwitchRejected = this.modeSwitchRejected.bind(this);
    }

    @action
    show(item: IStartWithModesSession, currentMode: MeetingMode) {
        this._isVisible = true;
        this._modeTitle = this._translate('REACT.DASHBOARD.MODE_SWITCH_CONFIRMATION.HEADER.' + item.mode);
        this._modes = {
            currentMode: currentMode,
            newMode: item,
            icons: [getIconNameByMode(currentMode), getIconNameByMode(item.mode)]
        };
    }

    @action
    hide(): void {
        this._isVisible = false;
    }

    modeSwitchConfirmed(): void {
        this.hide();
        this._handleModalResponse(true, this._modes);
    }

    modeSwitchRejected(): void {
        this.hide();
        this._handleModalResponse(false, this._modes);
    }

    @computed
    get isVisible() {
        return this._isVisible;
    }

    @computed
    get icons() {
        return this._modes.icons;
    }

    @computed
    get modeTitle() {
        return this._modeTitle;
    }

    get translate() {
        return this._translate;
    }
}
