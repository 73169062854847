'use strict';
import tsRightBarView from './ts-right-bar.view.html';
import './ts-right-bar.style.scss';
import {ImageShareLayouts} from '../../ts-image-share-modes/ts-image-share-modes.directive';
import {analysisOrigin} from '@techsee/techsee-common/lib/constants/smart.constants';

class TsRightBarController {
    constructor(tsInterfaceHelper, $translate) {
        'ngInject';

        this.tsInterfaceHelper = tsInterfaceHelper;
        this.$translate = $translate;
    }

    imagesPreviewWidth() {
        return this.tsInterfaceHelper.useNewInterface ? 110 : 95;
    }

    imagesPreviewHeight() {
        return this.tsInterfaceHelper.useNewInterface ? 140 : 120;
    }

    getBadgeValue(image) {
        let badgeValue = image.resourceIndex;

        if (this.isSelfServiceResource(image)) {
            badgeValue +=
                ' ' +
                (image.extra.eveAnalysis.panel === 'CABLE'
                    ? this.$translate.instant(`MAIN.VIEW.ANALYZE_SUMMARY.${image.extra.eveAnalysis.deviceName}`)
                    : this.$translate.instant(`MAIN.VIEW.ANALYZE_SUMMARY.${image.extra.eveAnalysis.panel}`));
        }

        return badgeValue;
    }

    getImageLayout(image) {
        if (this.isSelfServiceResource(image)) {
            return ImageShareLayouts.SELF_SERVICE;
        }

        return ImageShareLayouts.DEFAULT;
    }

    isSelfServiceResource(resource) {
        const eveAnalysis = resource && resource.extra && resource.extra.eveAnalysis;

        return eveAnalysis && (eveAnalysis.panel || eveAnalysis.origin === analysisOrigin.comcast);
    }
}

export function tsRightBar() {
    return {
        template: tsRightBarView,
        controller: TsRightBarController,
        controllerAs: 'tsRightBar',
        scope: true
    };
}
