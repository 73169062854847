import {
    EVENT_TYPES,
    ICallback,
    IPromptCallback,
    ISmsData,
    SMS_POLLING_DURING_PROMPT_ATTEMPTS,
    SMS_POLLING_DURING_PROMPT_INTERVAL,
    SMS_POLLING_INTERVAL,
    SMS_TIMEOUT,
    SmsError,
    SmsErrorLevel,
    WHATSAPP_POLLING_DURING_PROMPT_ATTEMPTS,
    WHATSAPP_POLLING_INTERVAL
} from './ts-sms.settings';
import get from 'lodash/get';
import {
    Gateways,
    ISmsDeliveryHelper,
    SMS_HANDLE_RESULT,
    SmsDeliveryHelper
} from '@techsee/techsee-common/lib/helpers/sms-delivery';
import {ISmsSettings} from '../../_react_/models/AccountSettings';
import {EventLogConstant} from '../../constants/events/event-log.constant';
import {IDbRooms, IDbSms} from '../../_react_/services/AngularServices/AngularServices';
import {Event} from './SmsServiceFlowEvent';
import {ISmsFlowLogger} from '../../_react_/states/invite-new/invite.flow.logger';
import {Nullable} from '@techsee/techsee-common';
import {AppState} from '../../_react_/app.state';
import {sleep} from '../../../../__tests__/utils/async-utils';

export class SendSmsServiceFlow {
    private _dbRooms: IDbRooms;

    private _dbSmsService: IDbSms;

    private _smsServiceLogger: ISmsFlowLogger;

    private _smsSettings: ISmsSettings;

    private timeouts: number[] = [];

    private previousGateway: string = '';

    private previousGatewaySmsId: string = '';

    private whatsAppDeliveredStatus = ['delivered', 'read'];

    private whatsAppFailedStatus = ['rejected', 'undeliverable'];

    private whatsAppPendingStatus = ['submitted'];

    constructor(dbRooms: IDbRooms, dbSmsService: IDbSms, smsSettings: ISmsSettings, eventLog: ISmsFlowLogger) {
        this._dbRooms = dbRooms;
        this._dbSmsService = dbSmsService;
        this._smsSettings = smsSettings;
        this._smsServiceLogger = eventLog;
        this._updateSmsError = this._updateSmsError.bind(this);
    }

    async sendSMS(
        smsData: ISmsData,
        progressCb: ICallback,
        allowPrompt: boolean,
        promptCb: IPromptCallback,
        allowSendByWhatsApp: boolean
    ): Promise<any> {
        const {messageId, success} = await this._tryWhatsApp(
            smsData,
            progressCb,
            allowSendByWhatsApp,
            this._smsServiceLogger
        );

        if (success) {
            return {messageId, success: true};
        }

        const smsResult = await this._sendSms(smsData, progressCb);

        if (smsResult && smsResult.success === false) {
            return smsResult;
        }

        return this._handleSMSResponse(
            {
                isPrimary: smsResult.isPrimary,
                gateway: smsResult.gateway,
                smsId: smsResult.smsId
            },
            smsData,
            progressCb,
            allowPrompt,
            promptCb
        );
    }

    private async _tryWhatsApp(
        smsData: ISmsData,
        progressCb: ICallback,
        allowSendByWhatsApp: boolean,
        smsServiceLogger: ISmsFlowLogger
    ): Promise<{
        messageId: string | null;
        success: boolean;
    }> {
        if (!allowSendByWhatsApp) {
            return {messageId: null, success: false};
        }

        const {messageId, success} = await this.sendWhatsAppTemplateMessage(smsData, progressCb);

        if (!success) {
            this._switchToSMSMessage(progressCb);

            return {messageId, success};
        }
        if (await this._doesWhatsAppDelivered(messageId)) {
            this.emitProgress(
                new Event(EVENT_TYPES.WHATSAPP_DELIVERED, {statusMessage: 'F.WHATSAPP_DELIVERED'}),
                progressCb
            );

            return {messageId, success: true};
        }
        smsServiceLogger.whatsappTimeoutError(smsData.countryCode + smsData.phone);

        this._switchToSMSMessage(progressCb);

        return {messageId, success: false};
    }

    async sendWhatsAppTemplateMessage(
        whatsAppData: any,
        progressCb: ICallback
    ): Promise<{
        messageId: string;
        success: boolean;
    }> {
        this.emitProgress(
            new Event(EVENT_TYPES.SENDING_WHATSAPP, {statusMessage: 'INVITE.CONTROLLER.SENDING_WHATSAPP'}),
            progressCb
        );

        try {
            const {
                data: {message_uuid: messageId}
            } = await this._dbSmsService.sendWhatsAppTemplateMessage({
                toNumber: whatsAppData.countryCode + whatsAppData.phone,
                roomId: whatsAppData.roomId,
                link: whatsAppData.link
            });

            return {messageId, success: true};
        } catch (error) {
            return {messageId: '', success: false};
        }
    }

    dispose() {
        //TODO: Need to accomplish disposing correctly.
        //TODO: Currently intervals are not collected to "timeouts" so we cannot clear them.
        this.timeouts.forEach((timeout) => clearTimeout(timeout));

        this.previousGateway = '';
        this.previousGatewaySmsId = '';
        this.timeouts = [];
    }

    private emitProgress(event: Event, progressCb?: Function) {
        if (progressCb) {
            progressCb(event.asObject());
        }
    }

    _switchToSMSMessage(cb?: Function) {
        this.emitProgress(
            new Event(EVENT_TYPES.WHATSAPP_ERROR, {statusMessage: 'INVITE.CONTROLLER.SENDING_WHATSAPP_FAILED'}),
            cb
        );
        this.emitProgress(
            new Event(EVENT_TYPES.SWITCHING_TO_SMS, {statusMessage: 'INVITE.CONTROLLER.SWITCHING_TO_SMS'}),
            cb
        );
    }

    async _doesWhatsAppDelivered(messageId: string): Promise<boolean> {
        for (let i = 0; i < WHATSAPP_POLLING_DURING_PROMPT_ATTEMPTS; i++) {
            const now = Date.now();

            const {data: statusResponse} = await this._dbSmsService.getWhatsAppTemplateMessageStatus(messageId);

            if (this.whatsAppDeliveredStatus.includes(statusResponse.status)) {
                return true;
            }
            if (this.whatsAppFailedStatus.includes(statusResponse.status)) {
                return false;
            }
            if (i + 1 < WHATSAPP_POLLING_DURING_PROMPT_ATTEMPTS) {
                await sleep(WHATSAPP_POLLING_INTERVAL * 1000 - (Date.now() - now), 'polling interval');
            }
        }

        return false;
    }

    _updateSmsError(gateway: string, error: any, reason: string, cb?: ICallback) {
        let smsError: SmsError = new SmsError(error, SmsErrorLevel.ERROR);

        if (typeof error === 'object') {
            smsError = error;
        }

        this._smsServiceLogger.smsResolutionWarning(
            gateway,
            EventLogConstant.smsResolutionTypes.displayErrorMessage,
            reason || smsError.Message
        );

        this.emitProgress(new Event(EVENT_TYPES.ERROR, {smsError}), cb);
    }

    async _sendSms(smsData: ISmsData, cb: ICallback): Promise<any> {
        // Sending SMS...
        this.emitProgress(new Event(EVENT_TYPES.SENDING_SMS, {statusMessage: 'INVITE.CONTROLLER.SENDING_SMS'}), cb);
        this.previousGateway = '';
        this.previousGatewaySmsId = '';

        const smsGatewayConfig = get(this._smsSettings, 'smsGatewayConfig');
        const primaryGateway = smsGatewayConfig && smsGatewayConfig.primary;
        let chosenPrimaryGatway = primaryGateway;
        const data: any = {
            phone: smsData.phone,
            roomId: smsData.roomId,
            link: smsData.link,
            chromeDetection: smsData.chromeDetection,
            cameraModeOnly: smsData.cameraModeOnly,
            showSmsPreview: smsData.showSmsPreview,
            gateway: primaryGateway,
            offline: smsData.offline,
            useNumberLookup: smsData.smsUseNumberLookup,
            countryCode: smsData.countryCode,
            audio: smsData.audio
        };

        if (smsData.startWithAgentType) {
            data.startWithAgentType = smsData.startWithAgentType;
        }

        return this._dbSmsService
            .sendPrimarySms(data)
            .then((sms) => {
                chosenPrimaryGatway = sms.gateway || primaryGateway || Gateways.NEXMO;

                this.previousGateway = chosenPrimaryGatway;
                this.previousGatewaySmsId = sms._id;

                const report = {
                    data: {event: {key: 'primaryGateway', value: `${chosenPrimaryGatway} - ${sms._id}`}}
                };

                this._dbRooms.setReportedField(smsData.roomId, report);

                if (get(sms, 'gatewayTimeout') || get(sms, 'gatewayInternalError')) {
                    // Gateway timeout, try to resend
                    this._smsServiceLogger.smsResolutionInfo(
                        chosenPrimaryGatway,
                        EventLogConstant.smsResolutionTypes.resendSms,
                        'Gateway timeout'
                    );

                    return this._resendSMS(smsData, null, cb);
                }

                return {isPrimary: true, gateway: chosenPrimaryGatway, smsId: sms._id};
            })
            .catch((error: any) => {
                if (error.status === 429) {
                    this.emitProgress(
                        new Event(EVENT_TYPES.ERROR, {customError: 'INVITE.CONTROLLER.SMS_CANNOT_BE_SENT_GENERAL'}),
                        cb
                    );
                } else if (error.status === 461) {
                    this.emitProgress(
                        new Event(EVENT_TYPES.ERROR, {
                            customError: 'INVITE.CONTROLLER.SMS_CANNOT_BE_SENT_INVALID_TELEPHONE'
                        }),
                        cb
                    );
                } else if (error.status === 462) {
                    this.emitProgress(
                        new Event(EVENT_TYPES.ERROR, {
                            customError: 'INVITE.CONTROLLER.SMS_CANNOT_BE_SENT_INVALID_NUMBER'
                        }),
                        cb
                    );
                } else {
                    this.emitProgress(
                        new Event(EVENT_TYPES.ERROR, {customError: 'INVITE.CONTROLLER.SMS_CANNOT_BE_SENT_TO_NUMBER'}),
                        cb
                    );
                }

                throw error;
            });
    }

    _handlePrompt(
        smsProcessCompleted: any,
        smsProcessFailed: any,
        promptCb: IPromptCallback,
        gateway: string,
        smsId: string,
        allowResending: boolean,
        smsData: ISmsData,
        cb: ICallback,
        allowPrompt: boolean
    ) {
        let arrivalInterval: any = null;

        const cleanupInterval = () => {
            if (arrivalInterval) {
                clearInterval(arrivalInterval);
                arrivalInterval = null;
            }
        };

        let attempt = 0;
        const {modalAbort, modalCompletion}: any = promptCb();

        // Continually check client load and DR while prompt is displayed
        arrivalInterval = setInterval(() => {
            attempt++;

            const assertAttempt = () => {
                if (attempt > SMS_POLLING_DURING_PROMPT_ATTEMPTS) {
                    return cleanupInterval();
                }
            };

            this._checkSmsDelivery(
                gateway,
                smsId,
                smsData.roomId,
                (gateway: string, clientConnected: boolean) => {
                    if (!arrivalInterval) {
                        return;
                    }

                    cleanupInterval();

                    // handle case the modal instance is already closed
                    modalAbort(() => this._smsProcessCompleted(smsData.roomId, gateway, clientConnected));
                },
                (isBlockedFromRetry: boolean, smsHandleResult: SMS_HANDLE_RESULT) => {
                    if (!arrivalInterval) {
                        return;
                    }

                    cleanupInterval();

                    // handle case the modal instance is already closed
                    modalAbort(() =>
                        this._smsProcessFailed(
                            gateway,
                            isBlockedFromRetry,
                            allowResending,
                            smsData,
                            smsHandleResult,
                            cb,
                            allowPrompt,
                            () => {}
                        )
                    );
                },
                () => assertAttempt(),
                cb
            );
        }, SMS_POLLING_DURING_PROMPT_INTERVAL * 1000);

        return modalCompletion()
            .then((result: any) => {
                cleanupInterval();

                const answer = get(result, 'answer');

                if (answer === 'abort') {
                    return result.completionFunc && result.completionFunc();
                }

                const roomEventData = {event: {key: 'smsReceivedReply', value: !!answer}};

                return Promise.all([
                    this._dbRooms.setReportedField(smsData.roomId, {data: roomEventData}),
                    this._smsServiceLogger.isSmsReceivedResponse(!!answer)
                ]).then(() => {
                    if (answer) {
                        return smsProcessCompleted(gateway, true);
                    }

                    if (allowResending) {
                        return this._resendSMS(smsData, null, cb).then((result: any) => {
                            if (result && result.success === false) {
                                return result;
                            }

                            return this._handleSMSResponse(
                                {
                                    isPrimary: result.isPrimary,
                                    gateway: result.gateway,
                                    smsId: result.smsId
                                },
                                smsData,
                                cb,
                                allowPrompt,
                                promptCb
                            );
                        });
                    }

                    this._updateSmsError(
                        gateway,
                        new SmsError('INVITE.CONTROLLER.SMS_CANNOT_BE_SENT_TEMP', SmsErrorLevel.INFO),
                        '',
                        cb
                    );

                    smsProcessFailed(true);
                });
            })
            .catch(() => {
                clearInterval(arrivalInterval);
                arrivalInterval = null;

                if (allowResending) {
                    return this._resendSMS(smsData, null, cb).then((result: any) => {
                        if (result && result.success === false) {
                            return result;
                        }

                        return this._handleSMSResponse(
                            {
                                isPrimary: result.isPrimary,
                                gateway: result.gateway,
                                smsId: result.smsId
                            },
                            smsData,
                            cb,
                            allowPrompt,
                            promptCb
                        );
                    });
                }

                this._updateSmsError(gateway, 'INVITE.CONTROLLER.SMS_CANNOT_BE_SENT_TO_NUMBER', '', cb);

                smsProcessFailed(true);

                return;
            });
    }

    _handleSMSResponse(
        {isPrimary, gateway, smsId}: any,
        smsData: ISmsData,
        progressCb: ICallback,
        allowPrompt: boolean,
        promptCb: IPromptCallback
    ) {
        return new Promise((resolve, reject) => {
            const customSmsTimeout = isPrimary
                ? this._smsSettings.smsPromptInitialTimeout
                : this._smsSettings.smsPromptSecondaryTimeout;
            const timeout = customSmsTimeout || SMS_TIMEOUT;
            const attempts = timeout / SMS_POLLING_INTERVAL;

            let attempt = 1;

            const smsProcessCompleted = (gateway: string, clientConnected: boolean) => {
                resolve(this._smsProcessCompleted(smsData.roomId, gateway, clientConnected));
            };

            const smsProcessFailed = (isBlockedFromRetry: boolean, smsHandleResult: Nullable<SMS_HANDLE_RESULT>) =>
                this._smsProcessFailed(
                    gateway,
                    isBlockedFromRetry,
                    isPrimary,
                    smsData,
                    smsHandleResult,
                    progressCb,
                    allowPrompt,
                    promptCb
                )
                    .then((result: any) => resolve(result))
                    .catch((err: any) => reject(err));

            const smsProcessNotCompleted = (smsHandleResult?: SMS_HANDLE_RESULT) => {
                this.emitProgress(new Event(EVENT_TYPES.ERROR, {statusMessage: false}), progressCb);

                if (allowPrompt && isPrimary) {
                    this._smsServiceLogger.smsResolutionInfo(
                        gateway,
                        EventLogConstant.smsResolutionTypes.displayPrompt
                    );

                    return this._handlePrompt(
                        smsProcessCompleted,
                        smsProcessFailed,
                        promptCb,
                        gateway,
                        smsId,
                        isPrimary,
                        smsData,
                        progressCb,
                        allowPrompt
                    )
                        .then((result: any) => resolve(result))
                        .catch((err: any) => reject(err));
                }

                if (!isPrimary) {
                    this._updateSmsError(
                        gateway,
                        new SmsError('INVITE.CONTROLLER.SMS_CANNOT_BE_SENT_TEMP', SmsErrorLevel.INFO),
                        '',
                        progressCb
                    );
                }

                smsProcessFailed(false, smsHandleResult || null);
            };

            const pushAttempt = (attemptFunc: any) =>
                // eslint-disable-next-line @typescript-eslint/no-implied-eval
                this.timeouts.push(setTimeout(attemptFunc, SMS_POLLING_INTERVAL * 1000) as any as number);

            const timeoutAttempt = () => {
                this._checkSmsDelivery(
                    gateway,
                    smsId,
                    smsData.roomId,
                    smsProcessCompleted,
                    smsProcessFailed,
                    () => {
                        if (attempt > attempts) {
                            return smsProcessNotCompleted(SMS_HANDLE_RESULT.TIMEOUT);
                        }

                        attempt++;

                        pushAttempt(timeoutAttempt);
                    },
                    progressCb
                ).catch(() => {
                    this._updateSmsError(gateway, 'INVITE.CONTROLLER.SMS_CANNOT_BE_SENT_TO_NUMBER', '', progressCb);

                    smsProcessFailed(true, null);
                });
            };

            pushAttempt(timeoutAttempt);
        });
    }

    _analyzeSmsDelivery(gateway: string, deliveryData: any, onlyCheckForSuccess: boolean, cb: ICallback) {
        const smsDeliveryHelper: ISmsDeliveryHelper = new SmsDeliveryHelper(this._updateSmsError);

        // @ts-ignore
        const smsResult = smsDeliveryHelper.analyzeSmsDelivery(gateway, deliveryData, onlyCheckForSuccess, cb);

        if (smsResult === SMS_HANDLE_RESULT.DELIVERED) {
            this.emitProgress(new Event(EVENT_TYPES.SMS_DELIVERED, {}), cb);

            return SMS_HANDLE_RESULT.DELIVERED;
        }

        if (onlyCheckForSuccess) {
            return SMS_HANDLE_RESULT.NOTHING;
        }

        return smsResult;
    }

    _smsProcessCompleted(roomId: string, gateway: string, clientConnected: boolean) {
        this._smsServiceLogger.smsResolutionInfo(
            gateway,
            EventLogConstant.smsResolutionTypes.moveToDashboard,
            clientConnected ? 'Client clicked on the SMS' : `${gateway} SMS delivered`
        );

        const report = {data: {event: {key: 'gatewayUsedForInvite', value: `${gateway ? gateway : 'none'}`}}};

        this._dbRooms.setReportedField(roomId, report);

        return {success: true, clientConnected};
    }

    _smsProcessFailed(
        gateway: string,
        isBlockedFromRetry: boolean,
        allowResending: boolean,
        smsData: ISmsData,
        previousSmsHandleResult: Nullable<SMS_HANDLE_RESULT>,
        cb: ICallback,
        allowPrompt: boolean,
        promptCb: IPromptCallback
    ) {
        this.emitProgress(new Event(EVENT_TYPES.UNKNOWN, {statusMessage: false}), cb);

        if (!isBlockedFromRetry && allowResending) {
            this._smsServiceLogger.smsResolutionInfo(gateway, EventLogConstant.smsResolutionTypes.resendSms);

            return this._resendSMS(smsData, previousSmsHandleResult, cb).then((result: any) => {
                if (result && result.success === false) {
                    return result;
                }

                return this._handleSMSResponse(
                    {
                        isPrimary: result.isPrimary,
                        gateway: result.gateway,
                        smsId: result.smsId
                    },
                    smsData,
                    cb,
                    allowPrompt,
                    promptCb
                );
            });
        }

        if (previousSmsHandleResult === SMS_HANDLE_RESULT.TIMEOUT) {
            this.emitProgress(
                new Event(EVENT_TYPES.ERROR, {
                    smsError: new SmsError('INVITE.CONTROLLER.SMS_TIMEOUT_FAILED', SmsErrorLevel.INFO)
                }),
                cb
            );
        } else {
            this.emitProgress(new Event(EVENT_TYPES.ERROR, {}), cb);
        }

        return Promise.resolve({success: false});
    }

    _checkSmsDelivery(
        gateway: string,
        smsId: string,
        roomId: string,
        smsProcessCompleted: any,
        smsProcessFailed: any,
        smsProcessNotCompleted: any,
        cb: ICallback
    ) {
        const smsStatusParams = {params: {checkClient: true, roomId}, bypassCache: true};
        const isProcessComplete = (gateway: string, clientConnected: boolean) => {
            this.emitProgress(new Event(EVENT_TYPES.CLIENT_RECEIVED, {}), cb);

            return smsProcessCompleted(gateway, clientConnected);
        };

        return this._dbSmsService.getSmsStatus(gateway, smsId, smsStatusParams).then((entry: any) => {
            if (get(entry, 'clientLoaded')) {
                return isProcessComplete(gateway, true);
            }

            const deliveryData = get(entry, 'sms.deliveryData');

            const smsHandleResult = this._analyzeSmsDelivery(gateway, deliveryData, false, cb);

            switch (smsHandleResult) {
                case SMS_HANDLE_RESULT.DELIVERED:
                    return isProcessComplete(gateway, false);
                case SMS_HANDLE_RESULT.BLOCKING_FAILURE:
                    return smsProcessFailed(true, smsHandleResult);
                case SMS_HANDLE_RESULT.FAILURE:
                case SMS_HANDLE_RESULT.FAILURE_AND_NOT_USE_CUSTOM_FROM:
                    return smsProcessFailed(false, smsHandleResult);
                default:
                    break;
            }

            const checkPreviousSMSPromise =
                gateway !== this.previousGateway && this.previousGatewaySmsId
                    ? this._dbSmsService.getSmsStatus(this.previousGateway, this.previousGatewaySmsId, smsStatusParams)
                    : Promise.resolve();

            return checkPreviousSMSPromise
                .then((previousSmsEntry: any) => {
                    if (get(previousSmsEntry, 'clientLoaded')) {
                        return isProcessComplete(this.previousGateway, true);
                    }

                    if (
                        this._analyzeSmsDelivery(this.previousGateway, deliveryData, true, cb) ===
                        SMS_HANDLE_RESULT.DELIVERED
                    ) {
                        return isProcessComplete(this.previousGateway, false);
                    }

                    return smsProcessNotCompleted();
                })
                .catch(() => smsProcessNotCompleted());
        });
    }

    _resendSMS(smsData: ISmsData, previousSmsHandleResult: Nullable<SMS_HANDLE_RESULT>, cb: ICallback): Promise<any> {
        const {smsGatewayConfig} = this._smsSettings;

        // If the secondary gateway is disabled, don't send a second sms
        if (smsGatewayConfig && smsGatewayConfig.isSecondaryDisabled) {
            if (previousSmsHandleResult === SMS_HANDLE_RESULT.TIMEOUT) {
                this.emitProgress(
                    new Event(EVENT_TYPES.ERROR, {
                        smsError: new SmsError('INVITE.CONTROLLER.SMS_TIMEOUT_FAILED', SmsErrorLevel.INFO)
                    }),
                    cb
                );
            } else {
                this.emitProgress(
                    new Event(EVENT_TYPES.ERROR, {
                        smsError: new SmsError('INVITE.CONTROLLER.SMS_CANNOT_BE_SENT_TEMP', SmsErrorLevel.INFO)
                    }),
                    cb
                );
            }

            return Promise.resolve({success: false});
        }

        const secondaryGateway = smsGatewayConfig && smsGatewayConfig.secondary;

        // Resending SMS...
        this.emitProgress(new Event(EVENT_TYPES.RESENDING_SMS, {statusMessage: 'INVITE.CONTROLLER.RESENDING_SMS'}), cb);

        const data: any = {
            phone: smsData.phone,
            roomId: smsData.roomId,
            chromeDetection: smsData.chromeDetection,
            cameraModeOnly: smsData.cameraModeOnly,
            showSmsPreview: smsData.showSmsPreview,
            gateway: secondaryGateway,
            offline: smsData.offline,
            isResend: true,
            countryCode: smsData.countryCode,
            audio: smsData.audio
        };

        if (smsData.startWithAgentType) {
            data.startWithAgentType = smsData.startWithAgentType;
        }

        if (previousSmsHandleResult === SMS_HANDLE_RESULT.FAILURE_AND_NOT_USE_CUSTOM_FROM) {
            data.doNotUseCustomFrom = true;
        }

        return this._dbSmsService
            .sendSecondarySms(data)
            .then((sms: any) => {
                const chosenSecondaryGateway = sms.gateway || secondaryGateway || Gateways.TWILIO;

                const report = {
                    data: {event: {key: 'secondaryGateway', value: `${secondaryGateway} - ${sms._id}`}}
                };

                this._dbRooms.setReportedField(smsData.roomId, report);

                return {isPrimary: false, gateway: chosenSecondaryGateway, smsId: sms._id};
            })
            .catch((error: any) => {
                if (error.status === 429) {
                    this.emitProgress(
                        new Event(EVENT_TYPES.ERROR, {customError: 'INVITE.CONTROLLER.SMS_CANNOT_BE_SENT_GENERAL'}),
                        cb
                    );
                } else if (error.status === 461) {
                    this.emitProgress(
                        new Event(EVENT_TYPES.ERROR, {
                            customError: 'INVITE.CONTROLLER.SMS_CANNOT_BE_SENT_INVALID_TELEPHONE'
                        }),
                        cb
                    );
                } else if (error.status === 462) {
                    this.emitProgress(
                        new Event(EVENT_TYPES.ERROR, {
                            customError: 'INVITE.CONTROLLER.SMS_CANNOT_BE_SENT_INVALID_NUMBER'
                        }),
                        cb
                    );
                } else {
                    this.emitProgress(
                        new Event(EVENT_TYPES.ERROR, {customError: 'INVITE.CONTROLLER.SMS_CANNOT_BE_SENT_TO_NUMBER'}),
                        cb
                    );
                }
            });
    }
}
