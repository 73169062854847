import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {IUserAccount} from '../../../states/login-new/components/login-form/controller';
import {IAccountSwitchController} from './controller';

export interface IAccountSwitch {
    ctrl: IAccountSwitchController;
}

@observer
export class AccountSwitch extends Component<IAccountSwitch> {
    render() {
        const {ctrl} = this.props;

        return (
            <div className='account-switch-popover'>
                <div className='account-switch-current'>{ctrl.companyName}</div>
                <div className='account-switch-items'>
                    {ctrl.accounts.map((acc: IUserAccount) => (
                        <div
                            className='account-switch-item'
                            onClick={() => ctrl.onSwitchUserClick(acc._id)}
                            key={acc._id}>
                            {acc.companyName}
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}
