import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {BorderRoundedIcon, BorderSizeOption, IconSizes} from '@techsee/techsee-ui-common/lib/icons/large-rounded-icon';
import {SimpleLabel} from '@techsee/techsee-ui-common/lib/forms/labels/simple';

export interface IGenericHeader {
    onClose: () => void;
    className: string;
    labelText: string;
    shouldShowCloseIcon: boolean;
}

@observer
export class GenericHeader extends Component<IGenericHeader> {
    render() {
        const {onClose, className, shouldShowCloseIcon, labelText} = this.props;

        return (
            <div className={className}>
                {shouldShowCloseIcon && (
                    <div onClick={() => onClose()} className={'use-common-styles close close-style'}>
                        <BorderRoundedIcon
                            borderSize={BorderSizeOption.THIN}
                            iconName={'close'}
                            sizeName={IconSizes.Small}
                        />
                    </div>
                )}
                <SimpleLabel className={'simple-label'}>{labelText}</SimpleLabel>
            </div>
        );
    }
}
