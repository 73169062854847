'use strict';
import tsSessionInfoView from './ts-session-info.view.html';
import './ts-session-info.style.scss';

export function tsSessionInfo() {
    return {
        template: tsSessionInfoView,
        scope: true
    };
}
