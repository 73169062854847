import {IDbVideoRecordings} from './AngularServices';

export class DbVideoRecordings implements IDbVideoRecordings {
    constructor(db: any) {
        Object.defineProperty(this, '_dbService', {
            value: db,
            enumerable: false
        });
    }

    byCustomerId(params: any): Promise<{data: any}> {
        return (this as any)._dbService.VideoRecordings.byCustomerId(params);
    }

    byCustomerNumber(params: any): Promise<{data: any}> {
        return (this as any)._dbService.VideoRecordings.byCustomerNumber(params);
    }
}
