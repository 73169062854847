import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import indexOf from 'lodash/indexOf';
import isObject from 'lodash/isObject';
import includes from 'lodash/includes';
import map from 'lodash/map';
import find from 'lodash/find';

import {
    FlowType,
    EveReportStepCodes,
    STEPS_ENUM,
    FRONTIER_STEPS_ENUMS,
    WarmTransferInitiator
} from '@techsee/techsee-common/lib/constants/room.constants';
import {
    analysisOrigin,
    features,
    deviceSets,
    poseDetectionDeviceSets,
    ledOptions,
    statusLeds
} from '@techsee/techsee-common/lib/constants/smart.constants';
import {SmartService} from '@techsee/techsee-client-infra/lib/services/SmartService';
import {getBackendUrl} from '@techsee/techsee-common/lib/utils';

const apiUrl = getBackendUrl(API_URL, {hostname: window.location.hostname, ENV: {}});

const RECENT_ISSUES_TOP_LIMIT = 3;
const FILTER_SUCCESSFUL_STEPS_COMCAST = [
    STEPS_ENUM.FRONT_ANALYZE,
    STEPS_ENUM.REAR_ANALYZE,
    STEPS_ENUM.REAR_ANALYZE_VERIFY,
    STEPS_ENUM.CABLE_ANALYZE_COAX,
    STEPS_ENUM.CABLE_ANALYZE_HDMI,
    STEPS_ENUM.CABLE_ANALYZE_POWER,
    STEPS_ENUM.ALL_CABLES_CONNECTED,
    STEPS_ENUM.ANALYZE_REMOTE
];

const FILTER_SUCCESSFUL_STEPS_FRONTIER = [
    FRONTIER_STEPS_ENUMS.FRONT_ANALYZE,
    FRONTIER_STEPS_ENUMS.REAR_ANALYZE,
    FRONTIER_STEPS_ENUMS.CABLE_ANALYZE_COAX,
    FRONTIER_STEPS_ENUMS.CABLE_ANALYZE_LAN,
    FRONTIER_STEPS_ENUMS.CABLE_ANALYZE_ONT,
    FRONTIER_STEPS_ENUMS.CABLE_ANALYZE_BROADBAND,
    FRONTIER_STEPS_ENUMS.CABLE_ANALYZE_POWER,
    FRONTIER_STEPS_ENUMS.ALL_CABLES_CONNECTED,
    FRONTIER_STEPS_ENUMS.STICKER_ANALYZE,
    FRONTIER_STEPS_ENUMS.LED_STATUS_ANALYZE,
    FRONTIER_STEPS_ENUMS.LED_STATUS_ANALYSIS_RESULT
];

export class TsDeviceClassificationService {
    constructor($rootScope, tsChatApi, db, tsLocaleHelper, $localStorage, eventLog) {
        'ngInject';

        this._rootScope = $rootScope;
        this._chatApi = tsChatApi.service;
        this._db = db;
        this._localeHelper = tsLocaleHelper;
        this.$localStorage = $localStorage;
        this._isComponentMount = false;
        this.displayCustomPanel = false;
        this.eventLog = eventLog;
        this.countMessagesArrived = 0;
        this.resetService();

        this._messageHandler = this._messageHandler.bind(this);
    }

    get isSelfService() {
        return this._params && this._params.flowType === FlowType.selfService;
    }

    get isAutoClassifyEnabled() {
        return this._params && this._params.agentAssistSettings.enableAutoClassification;
    }

    get isAnalysisVisible() {
        return this.isAnalysisAvailable && this.analysisData.display && this._isComponentMount;
    }

    get isAnalysisAvailable() {
        return (
            this.isSelfService &&
            (this._getAnalysisDevice() !== null || !isEmpty(get(this.analysisData, 'reportedField.analyzedFlow')))
        );
    }

    get isCustomeAnalysisPanelAvailable() {
        return this.displayCustomPanel;
    }

    get isComcast() {
        return get(this.analysisData, 'rear.origin') === analysisOrigin.comcast;
    }

    get isFrontier() {
        return get(this.analysisData, 'front.origin') === analysisOrigin.frontier;
    }

    get isIdanPlus() {
        return (
            get(this.analysisData, 'rear.origin') === analysisOrigin.idanPlus ||
            get(this.analysisData, 'front.origin') === analysisOrigin.idanPlus
        );
    }

    _getEveReportedFields() {
        return this._db.Rooms.eveReportedField(this._params.roomId).then((result) => result.data.eveFieldsForReports);
    }

    get isSolutionVisible() {
        return this._isComponentMount && this.selectedIssueId !== null;
    }

    get isImagePreviewVisible() {
        return this._isComponentMount && this.imagePreviewUrl !== null;
    }

    //Indicates if issue was detected on smart server
    get isAnalysisIssueRecognized() {
        return this.isAnalysisAvailable && !isNaN(parseInt(this.analysisData.rear.issueId, 10));
    }

    //Indicates if issue found in recognized device issue list
    get isAnalysisIssueAvailable() {
        const analysisDevice = this._getAnalysisDevice();

        if (!analysisDevice) {
            return false;
        }

        return (
            analysisDevice.commonIssues.filter(
                (issue) => parseInt(issue.issueId, 10) === parseInt(this.analysisData.rear.issueId, 10)
            ).length > 0
        );
    }

    classifyDevice(imageData) {
        return this._analyze(imageData, features.classification, null, RECENT_ISSUES_TOP_LIMIT, true, true, true);
    }

    _customAnalysis(imageData, analysisResults) {
        // TODO: the code in this function should be moved to a dedicated "smart" package, and joined with code from EVE apps

        const deviceId = analysisResults && analysisResults.deviceId;

        switch (deviceId) {
            case '34':
                return Promise.resolve({isNest: true});
            case '31': // alexa
                return this._analyze(imageData, features.classification, [deviceSets.alexaModes]).then((response) => {
                    const customAnalysisResults = get(response, 'result.analysisResults');

                    if (isEmpty(customAnalysisResults) || customAnalysisResults[0].deviceId === 'None') {
                        this.eventLog.analyzeFail({imageData, err: 'did not recognize alexa'});

                        return false;
                    }

                    const ledColor = customAnalysisResults[0].deviceName;

                    switch (customAnalysisResults[0].deviceName) {
                        case 'orange':
                            return {
                                issueId: 25,
                                ledAnalysis: [{led: 'Orange light', result: `${ledColor}-light-icon.png`}]
                            };
                        case 'yellow':
                            return {
                                issueId: 26,
                                ledAnalysis: [{led: 'Yellow light', result: `${ledColor}-light-icon.png`}]
                            };
                        case 'red':
                            return {
                                issueId: 27,
                                ledAnalysis: [{led: 'Red light', result: `${ledColor}-light-icon.png`}]
                            };
                        case 'green':
                            return {
                                issueId: 28,
                                ledledsStatusAnalysis: [{led: 'Green light', result: `${ledColor}-light-icon.png`}]
                            };
                        default:
                            return {};
                    }
                });
            case '32': // philips
                return this._analyze(imageData, features.poseDetection, [poseDetectionDeviceSets.philipsLeds]).then(
                    (response) => {
                        const customAnalysisResults = get(response, 'result.analysisResults');

                        if (!customAnalysisResults || customAnalysisResults[0].deviceId === '0') {
                            this.eventLog.analyzeFail({imageData, err: 'did not recognize philips'});

                            return false;
                        }

                        const ledCount = filter(
                            customAnalysisResults,
                            (result) => result.text === 'led' && result.status === 'on'
                        ).length;
                        const success = 'success-icon.png';
                        const failed = 'failed-icon.png';

                        switch (ledCount) {
                            case 0:
                                return {
                                    issueId: 29,
                                    ledAnalysis: [
                                        {led: 'Power', result: failed},
                                        {led: 'LAN connection', result: failed},
                                        {led: 'Internet connection', result: failed}
                                    ]
                                };
                            case 1:
                                return {
                                    issueId: 30,
                                    ledAnalysis: [
                                        {led: 'Power', result: success},
                                        {led: 'LAN connection', result: failed},
                                        {led: 'Internet connection', result: failed}
                                    ]
                                };
                            case 2:
                                return {
                                    issueId: 31,
                                    ledAnalysis: [
                                        {led: 'Power', result: success},
                                        {led: 'LAN connection', result: success},
                                        {led: 'Internet connection', result: failed}
                                    ]
                                };
                            default:
                                return {
                                    ledAnalysis: [
                                        {led: 'Power', result: success},
                                        {led: 'LAN connection', result: success},
                                        {led: 'Internet connection', result: success}
                                    ]
                                };
                        }
                    }
                );
            default:
                this.eventLog.analyzeFail({
                    imageData,
                    err: `Device ${deviceId} is not supported for custom analysis`
                });

                return Promise.reject(new Error(`Device ${deviceId} is not supported for custom analysis`));
        }
    }

    _analyze(
        imageData,
        featureType,
        featureOptions,
        pageSize,
        includeExtraInfo,
        includeCommonIssues,
        includeRelatedIssues
    ) {
        const imageDataType = imageData.imgData.startsWith('data:') ? 'BASE64' : 'URL';

        const currentToken = get(this.$localStorage, 'auth.token');

        if (!this.token || currentToken !== this.token) {
            this.token = currentToken;

            if (!this.token) {
                return Promise.reject(new Error('Not authorized to analyze'));
            }

            this._smartService = new SmartService(apiUrl, this.token);
        }

        return this._smartService
            .analyze(
                featureType,
                featureOptions,
                this._params.roomId,
                imageData.imgData,
                this._params.accountId,
                imageDataType,
                pageSize,
                includeExtraInfo,
                includeCommonIssues,
                includeRelatedIssues
            )
            .catch((err) => {
                if (!indexOf([466, 467, 468], get(err, 'response.data.smartErrorCode'))) {
                    this._localeHelper.deviceAnalyzingError();
                }

                this.eventLog.analyzeFail({err: 'something wrong with server'});
                this.eventLog.recognizeFail({err: 'something wrong with server'});

                throw err;
            });
    }

    customAnalysis(analysisResults, imageData) {
        return this._customAnalysis({imgData: imageData}, analysisResults).then((response) => {
            if (!response) {
                return false;
            }

            if (response.isNest) {
                const issues = [32, 33, 34, 35];
                const promise = [];

                issues.forEach((issue) => {
                    promise.push(
                        this._db.Smart.solutions(issue, {bypassCache: true}).then(
                            (solutions) => solutions && solutions.data && solutions.data[0]
                        )
                    );
                });

                return Promise.all(promise);
            }

            if (response.issueId) {
                return this._db.Smart.solutions(response.issueId, {bypassCache: true}).then((solutions) => ({
                    solution: solutions && solutions.data && [solutions.data[0]],
                    ledAnalysis: response.ledAnalysis
                }));
            }

            return response;
        });
    }

    selectAnalysisIssue() {
        this.selectAnalysisDevice();
        this.solutionOpen(this.analysisData.rear.issueId);
    }

    selectAnalysisDevice() {
        const device = this.devicesList.filter((d) => parseInt(d.deviceId, 10) === this.analysisData.rear.deviceId)[0];

        this.setRecognizedDevice(device);
    }

    selectDevice(device) {
        this.deviceListClose();
        this.setRecognizedDevice(device);
    }

    setRecognizedDevice(device) {
        if (device) {
            const reletedIssues = device.relatedHistoryIssues.concat(device.relatedResourceIssues);
            const limitIssuesTo =
                RECENT_ISSUES_TOP_LIMIT < reletedIssues.length ? RECENT_ISSUES_TOP_LIMIT : reletedIssues.length;

            device.relatedIssues = reletedIssues.slice(0, limitIssuesTo);
        }

        this.imgBack = device ? this.getDeviceImage(device, 'BACK') : null;
        this.imgFront = device ? this.getDeviceImage(device, 'FRONT') : null;

        this.recognizedDevice = device;
        this._rootScope.safeApply();
    }

    isIssueSelected(deviceId, issueId) {
        return (
            this.recognizedDevice &&
            this.recognizedDevice.deviceId === deviceId &&
            parseInt(this.selectedIssueId, 10) === parseInt(issueId, 10)
        );
    }

    get deviceName() {
        if (!this.isFrontier) {
            return this.isIdanPlus ? get(this.analysisData, 'rear.origin') : get(this.analysisData, 'rear.deviceName');
        }

        if (
            get(this.analysisData, 'front.deviceName') &&
            get(this.analysisData, 'front.deviceName') === 'Unknown' &&
            get(this.analysisData, 'rear.deviceName') &&
            get(this.analysisData, 'rear.deviceName') !== 'Unknown'
        ) {
            return get(this.analysisData, 'rear.deviceName');
        }

        return get(this.analysisData, 'front.deviceName');
    }

    _messageHandler(message) {
        if (this.isSelfService) {
            const analysisData = get(message, 'data.extra.eveAnalysis');

            if (message.data.type === 'image' && isObject(analysisData)) {
                const isRearPanel = analysisData.panel === 'REAR';

                this.addImagesToSuccessfulSteps({name: analysisData.step, image: get(message, 'data.message')});
                this.analysisData[isRearPanel ? 'rear' : 'front'] = analysisData;

                if (this.isIdanPlus) {
                    this.analysisData.successfulSteps.push({
                        name: isRearPanel ? 'REAR_ANALYZE' : 'FRONT_ANALYZE',
                        image: {url: get(message, 'data.message')}
                    });

                    if (isRearPanel) {
                        this.analysisData.cables = [{connected: false, message: this.analysisData.rear.analysis}];
                    }
                }

                this._rootScope.safeApply();
            }

            if (this.isComcast) {
                this.getRearPanelCablesStatus();
                this._rootScope.safeApply();
            }

            if (this.isFrontier) {
                this.getLedsStatus();
                this._rootScope.safeApply();
            }

            this.countMessagesArrived++;
        }
    }

    getWarmTransferStep(steps) {
        const index = steps.findIndex((step) => step.code === STEPS_ENUM.WARM_TRANSFER);

        if (index > 0) {
            this.analysisData.warmTransferStep = steps[index - 1];
        }
    }

    successfulSteps(steps) {
        const successfulSteps = filter(
            steps,
            (step) =>
                includes(
                    this.isComcast ? FILTER_SUCCESSFUL_STEPS_COMCAST : FILTER_SUCCESSFUL_STEPS_FRONTIER,
                    step.name
                ) && step.code === EveReportStepCodes.NEXT
        );

        this.analysisData.successfulSteps = successfulSteps;
    }

    addImagesToSuccessfulSteps(stepWithImage) {
        map(this.analysisData.successfulSteps, (step) => {
            if (stepWithImage.name === step.name) {
                step.image = {
                    url: stepWithImage.image
                };
            }
        });
    }

    getLedsStatus() {
        if (!get(this.analysisData, 'front.ledStatus')) {
            return;
        }

        this.analysisData.ledStatus = [
            {name: ledOptions.power, status: statusLeds.off, translate: 'POWER_LED_'},
            {name: ledOptions.internet, status: statusLeds.off, translate: 'INTERNET_LED_'},
            {name: ledOptions.broadband, status: statusLeds.off, translate: 'NETWORK_LED_'},
            {name: ledOptions.wifi, status: statusLeds.off, translate: 'WIFI_LED_'}
        ];

        const ledsStatus = this.analysisData.front.ledStatus;

        map(ledsStatus, (led) => {
            const ledStatusChange = find(
                this.analysisData.ledStatus,
                (analysisLed) => analysisLed && led && analysisLed.name === led.name
            );

            if (ledStatusChange) {
                ledStatusChange.status = led.status;
            }
        });
    }

    getRearPanelCablesStatus() {
        this.analysisData.cables = [
            {name: 'coax', connected: true, translate: 'COAX_CABLE_'},
            {name: 'hdmi', connected: true, translate: 'HDMI_CABLE_'},
            {name: 'power', connected: true, translate: 'POWER_CABLE_'}
        ];
        const disconnectedCables = this.analysisData.rear.cablesDisconnected;

        map(
            disconnectedCables,
            (cable) =>
                (find(this.analysisData.cables, (analysisCable) => analysisCable.name === cable).connected = false)
        );
    }

    _autoSelectDeviceIfNeeded() {
        const {enableDefaultDevice, defaultDeviceId} = this._params.agentAssistSettings;

        if (this.isSingleDevice || enableDefaultDevice) {
            let defaultDevice = null;

            if (!this.isSingleDevice && enableDefaultDevice && defaultDeviceId) {
                defaultDevice = find(this.devicesList, (device) => device.deviceId === defaultDeviceId);
            }

            // default to first device
            if (!defaultDevice && this.devicesList && this.devicesList.length > 0) {
                defaultDevice = this.devicesList[0];
            }

            defaultDevice && this.setRecognizedDevice(defaultDevice);
        }
    }

    _getAnalysisDevice() {
        if (this.analysisData.rear !== null && this.devicesList instanceof Array) {
            const device = this.devicesList.filter((d) => parseInt(d.deviceId, 10) === this.analysisData.rear.deviceId);

            if (device.length > 0) {
                return device[0];
            }
        }

        return null;
    }

    _getDevices(params) {
        const requestQueryParams = {
            params: {
                roomId: params.roomId,
                pageSize: RECENT_ISSUES_TOP_LIMIT,
                includeCommonIssues: true,
                includeRelatedIssues: true
            }
        };

        this._initPromise = new Promise((resolve, reject) => {
            this._db.Smart.getDevices(params.deviceSetId, requestQueryParams)
                .then((devices) => {
                    this.devicesList = devices.data;
                    this.isSingleDevice = this.devicesList.length === 1;
                    this.isDeviceSelectionEnabled = !this.isSingleDevice;
                    this._autoSelectDeviceIfNeeded();
                    this._rootScope.safeApply();
                    resolve();
                })
                .catch(reject);
        });

        return this._initPromise;
    }

    //#region LyfiCycle helpers

    initService(params) {
        if (this._initPromise !== null) {
            return this._initPromise;
        }

        if (!params) {
            return Promise.reject(new Error('params are required'));
        }

        this._params = params;

        if (!params.agentAssistSettings.isEnabled) {
            return Promise.resolve();
        }

        this.isCustomAnalysis = this._params && this._params.deviceSetId === deviceSets.assurantDevices;
        this.demoDevices = this._params && this._params.deviceSetId === deviceSets.demoDevices;

        if (this.isSelfService) {
            this._chatApi.on('message', this._messageHandler);
            if (this.demoDevices) {
                this.isWarmTransferRequested = true;
                this.analysisData.successfulSteps = [];
                this.analysisData.warmTransferStep = {name: 'GUIDE_OR_CONNECT_AGENT_STEP'};

                return Promise.resolve();
            }

            return this._getEveReportedFields()
                .then((reportedField) => {
                    if (reportedField && reportedField.isWarmTransferRequested) {
                        if (
                            (!this.isFrontier && !this.analysisData.rear) ||
                            (this.isFrontier && !this.analysisData.front)
                        ) {
                            this.analysisData[this.isFrontier ? 'front' : 'rear'] = {deviceName: 'Unknown'};
                            this.analysisData.warmTransferStep = {
                                name: this.isFrontier ? 'FRONT_ANALYZE' : 'REAR_ANALYZE'
                            };

                            if (reportedField.warmTransferInitiator === undefined) {
                                this.analysisData.reportedField.warmTransferInitiator = WarmTransferInitiator.CUSTOMER;
                            }
                        }

                        this.analysisData.reportedField = reportedField;
                        const steps = this.analysisData.reportedField.steps;

                        if (steps) {
                            this.getWarmTransferStep(steps);
                            this.successfulSteps(steps);
                        }

                        this.isWarmTransferRequested = true;
                        this._rootScope.safeApply();
                    }

                    return this._getDevices(params);
                })
                .catch((err) => console.error(err));
        }

        return this._getDevices(params);
    }

    resetService() {
        this._chatApi.off('message', this._messageHandler);
        this.devicesList = null;
        this.analysisData = {display: true, front: null, rear: null};
        this.recognizedDevice = null;
        this.selectedIssueId = null;
        this.imagePreviewUrl = null;
        this.isDeviceSelectionVisible = false;
        this._initPromise = null;
    }

    getDeviceImage(device, imgType) {
        if (isEmpty(device)) {
            return null;
        }

        const foundImage = device.images.filter((image) => image.deviceImageType === imgType);

        return isEmpty(foundImage) ? null : foundImage[0];
    }

    componentDidMount() {
        this._isComponentMount = true;
        this._rootScope.safeApply();
    }

    componentDidUnmount() {
        this._isComponentMount = false;
        this._rootScope.safeApply();
    }

    //#endregion

    //#region Open/Close panels

    analysisOpen() {
        this.solutionClose();
        this.imagePreviewClose();
        this.deviceListClose();
        this.analysisData.display = true;
        this._rootScope.safeApply();
    }

    analysisClose() {
        this.analysisData.display = false;
        this._rootScope.safeApply();
    }

    solutionOpen(issueId) {
        this.imagePreviewClose();
        this.analysisClose();
        this.deviceListClose();
        this.selectedIssueId = issueId;
        this._rootScope.safeApply();
    }

    solutionClose() {
        this.selectedIssueId = null;
        this._rootScope.safeApply();
    }

    imagePreviewOpen(imageUrl) {
        this.solutionClose();
        this.analysisClose();
        this.imagePreviewUrl = imageUrl;
        this._rootScope.safeApply();
    }

    imagePreviewClose() {
        this.imagePreviewUrl = null;
        this._rootScope.safeApply();
    }

    deviceListOpen() {
        this.isDeviceSelectionVisible = true;
        this._rootScope.safeApply();
    }

    deviceListClose() {
        this.isDeviceSelectionVisible = false;
        this._rootScope.safeApply();
    }

    //#endregion
}
