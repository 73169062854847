import {IDbUser, IObservableByAccountResponse, IUserUpdateParams} from './AngularServices';
import {IFilter} from '../../states/invite-new/invite-wizard/components/observable-form/controller';

export class DbUserAdapter implements IDbUser {
    constructor(db: any) {
        Object.defineProperty(this, '_dbService', {
            value: db,
            enumerable: false
        });
    }

    update(userId: string, params: IUserUpdateParams): Promise<void> {
        return (this as any)._dbService.User.update(userId, params);
    }

    find(userId: string): Promise<any> {
        return (this as any)._dbService.User.find(userId);
    }

    getAccountChannelData(): Promise<any> {
        return (this as any)._dbService.User.getAccountChannelData();
    }

    observableByAccount(accountId: string, filter?: IFilter): Promise<{data: IObservableByAccountResponse}> {
        return (this as any)._dbService.User.observableByAccount({params: {id: accountId, filter}});
    }
}
