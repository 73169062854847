import {IFieldModel} from '@techsee/techsee-ui-common/lib/forms/_shared/simple-field-model';
import {TechseeFormBase} from '@techsee/techsee-ui-common/lib/forms/_shared/TechseeFormBase';
import {ValidationRules} from '@techsee/techsee-ui-common/lib/forms/_shared/ValidationRules';
import {ITranslate} from '../../../../services/LocalizationService';
import {IFieldFactory} from '../../../../app.contracts';

export class LoginForm extends TechseeFormBase {
    private readonly _translate: ITranslate;

    private readonly _createFieldModel: IFieldFactory;

    constructor(translate: ITranslate, createFieldModel: IFieldFactory) {
        super();

        this._translate = translate;
        this._createFieldModel = createFieldModel;

        this.addField('username', this.createUsernameField());
        this.addField('password', this.createPasswordField());
        this.addField('account', this.createAccountField());
    }

    private createUsernameField(): IFieldModel {
        const username = this._createFieldModel(' ');

        username.placeholderKey = this._translate('REACT.LOGIN.VIEW.USERNAME');

        username.addRule({
            rule: ValidationRules.required(),
            message: this._translate('REACT.LOGIN.VIEW.USERNAME_REQUIRED')
        });

        username.setIconName('user');

        return username;
    }

    private createPasswordField(): IFieldModel {
        const password = this._createFieldModel(' ');

        password.placeholderKey = this._translate('REACT.LOGIN.VIEW.PASSWORD');

        password.addRule({
            rule: ValidationRules.required(),
            message: this._translate('REACT.LOGIN.VIEW.PASSWORD_REQUIRED')
        });

        password.setIconName('lock');

        return password;
    }

    private createAccountField(): IFieldModel {
        const account = this._createFieldModel('REACT.LOGIN.VIEW.ACCOUNT');

        account.setReadonly(true);

        return account;
    }
}
