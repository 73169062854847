/* eslint-disable init-declarations */
import {AppRootStore, IAppRootStore} from './app.root.store';
import {LocalizableFieldModel, ILocalizable} from './models/LocalizableFieldModel';
import {IAppBootstrapParams, IFieldFactory} from './app.contracts';

let rootStore: IAppRootStore;
let fieldModelFactory: IFieldFactory;

//Initializes react application
export function reactAppBootstrap(dependencies: IAppBootstrapParams): IAppRootStore {
    //First we init the root store, wich includes all application wide single instance services.
    const store = new AppRootStore(dependencies);

    store.initRootServices();
    rootStore = store;

    //Then we init the factory for creating the localizable fields
    fieldModelFactory = (translationKey?: string): ILocalizable => {
        const field = new LocalizableFieldModel(rootStore.localizationService, rootStore.appState);

        field.labelKey = translationKey || '';

        return field;
    };

    return rootStore;
}

//Returns applications root store
export function getRootStore(): IAppRootStore {
    if (!rootStore) {
        throw 'root store was not initialized, insure you have bootstrapped the application';
    }

    return rootStore;
}

//Uses field model factory to create localizable fields
export function createFieldModel(translationKey?: string): ILocalizable {
    if (!fieldModelFactory) {
        throw new Error('Field model factory is not registered');
    }

    return fieldModelFactory(translationKey);
}
