import React from 'react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {ITranslate} from '../../../../services/LocalizationService';

import './styles.scss';

export interface ILeftSide {
    translate: ITranslate;
}

export class LeftSide extends TechseeBaseComponent<ILeftSide> {
    renderInternal() {
        const {translate} = this.props;

        return (
            <div className={'login-left-side'}>
                <div className={'page-title'}>
                    <h3>{translate('REACT.LOGIN.VIEW.WELCOME_TO')}</h3>
                    <h1>{translate('REACT.LOGIN.VIEW.VISUAL_SUPPORT')}</h1>
                </div>
            </div>
        );
    }
}

export default LeftSide;
