import tsImageViewPanelTemplate from './ts-image-view-panel.view.html';
import './ts-image-view-panel.style.scss';

class TsImageViewPanelController {
    constructor($rootScope, tsDeviceClassificationService) {
        'ngInject';

        this.requireImage = $rootScope.requireImage;
        this.service = tsDeviceClassificationService;
    }
}

export function tsImageViewPanel() {
    return {
        template: tsImageViewPanelTemplate,
        replace: true,
        scope: {},
        controller: TsImageViewPanelController,
        controllerAs: 'vm'
    };
}
