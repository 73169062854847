import React from 'react';
import {observer} from 'mobx-react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {SimpleTabs, ITab} from '@techsee/techsee-ui-common/lib/tabs/simple';
import {AgentGuidance} from '../components/agent-guidance/AgentGuidance';
import {InviteStepsLayout} from './_Shared';
import {InviteStepProps} from './_InviteGenericStep';
import {InviteDeviceType} from '../../_contracts/InviteContracts';

@observer
export class StepSpeakerTabs extends TechseeBaseComponent<InviteStepProps> {
    componentDidMount() {
        this.props.ctrl.setPagePlacement('sms-speakers-step');
    }

    renderInternal() {
        const {translate} = this.props.ctrl;

        const tabsList: ITab[] = [];

        tabsList.push({title: 'Android', content: speakerTabs(InviteDeviceType.android)});
        tabsList.push({title: 'iOS', content: speakerTabs(InviteDeviceType.ios)});

        return (
            <InviteStepsLayout
                className={'speaker-step'}
                h1Text={translate('REACT.SPEAKER.TURN_ON')}
                h2Text={translate('REACT.SPEAKER.MAKE_SURE_THEY_HEAR_YOU')}>
                <SimpleTabs tabsList={tabsList} uid={'speaker-step-tabs'} />
            </InviteStepsLayout>
        );

        function speakerTabs(device: InviteDeviceType) {
            return () => (
                <React.Fragment>
                    <AgentGuidance
                        stepType={'speaker'}
                        stepNum={1}
                        deviceType={device}
                        stepText={translate('REACT.SPEAKER.TAP_THE_BUTTON')}
                    />
                </React.Fragment>
            );
        }
    }
}
