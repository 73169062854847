import {EVENT_TYPES} from './ts-sms.settings';

export class Event {
    readonly _type: EVENT_TYPES = EVENT_TYPES.UNKNOWN;

    readonly _data: any;

    constructor(type: EVENT_TYPES, data: any) {
        this._type = type;
        this._data = data;
    }

    get Type() {
        return this._type;
    }

    get Data() {
        return this._data;
    }

    asObject(): Record<string, any> {
        return {type: this._type, data: this._data};
    }
}
