// @ts-ignore
import storeView from './redirectToStore.view.html';
import {TsRedirectToStore} from './TsRedirectToStore';

function config($stateProvider) {
    $stateProvider.state('dashboard.store', {
        url: '/store',
        views: {
            'desktop-dashboard-view': {
                template: storeView,
                controller: () => {},
                controllerAs: 'store'
            },
            'mobile-dashboard-view': {
                template: storeView,
                controller: () => {},
                controllerAs: 'store'
            }
        }
    });
}

export default angular
    .module('states.dashboard.store', [])
    .directive('tsRedirectToStore', ['reactDirective', (reactDirective) => reactDirective(TsRedirectToStore)])
    .config(['$stateProvider', config]);
