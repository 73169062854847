'use strict';

import tsSnapshotReminderView from './ts-snapshot-reminder.view.html';
import './ts-snapshot-reminder.scss';
import isUndefined from 'lodash/isUndefined';
import {compareUnderThreshold} from '@techsee/techsee-common/lib/utils';

class TsSnapshotReminderController {
    constructor($element, $attrs, $rootScope, $scope, $timeout, tsSnapshotReminderService, eventLog) {
        'ngInject';

        this.requireImage = $rootScope.requireImage;
        this.$timeout = $timeout;
        this.$element = $element;
        this.eventLog = eventLog;
        this.visible = false;

        this.tsSnapshotReminderService = tsSnapshotReminderService;

        $scope.$watch(
            () => this.tsSnapshotReminderService.pop,
            () => {
                if (this.tsSnapshotReminderService.pop) {
                    this.show();
                }
            }
        );

        this.tsSnapshotReminderService.on('snapshotTaken', () => this._snapshotTaken());
    }

    _snapshotTaken() {
        this.hide({reason: 'snapshotTaken'});
    }

    show() {
        if (this._timer) {
            this.$timeout.cancel(this._timer);
        }

        this.visible = true;

        this._timer = this.$timeout(() => this.hide({reason: 'autoClose'}), this.duration * 1000);
    }

    hide(options) {
        if (this._timer) {
            this.$timeout.cancel(this._timer);
            this._timer = null;
        }

        this.visible = false;

        this.eventLog.snapshotReminderClosed(options);

        this.tsSnapshotReminderService.hide();
    }

    getContainerStyle() {
        if (!this.visible) {
            return;
        }

        const containerLocation = this.getReminderLocation();

        const location = containerLocation;

        if (!location) {
            return {};
        }

        const container = this.$element.find('.ts-snapshot-reminder');

        if (!isUndefined(location.top)) {
            location.top -= container.height() + 30;

            location.top = Math.ceil(location.top);
        }

        if (!isUndefined(location.left)) {
            if (this.popDirection === 'left') {
                location.left -= 23;
            } else {
                location.left -= container.width() / 2 - 11;
            }

            location.left = Math.ceil(location.left);
        }

        if (!(location.top === 0 && location.left === 0)) {
            if (
                this.previousLocation &&
                !compareUnderThreshold(this.previousLocation.left, location.left) &&
                !compareUnderThreshold(this.previousLocation.top, location.top)
            ) {
                return this.previousLocation;
            }
            this.previousLocation = location;
        }

        return location;
    }

    closeClicked() {
        this.hide({reason: 'closedByAgent'});
    }
}

export function tsSnapshotReminder() {
    return {
        template: tsSnapshotReminderView,
        restrict: 'E',
        scope: {},
        bindToController: {
            duration: '=',
            message: '@',
            popDirection: '=',
            dir: '=',
            getReminderLocation: '&'
        },
        controller: TsSnapshotReminderController,
        controllerAs: 'vm'
    };
}
