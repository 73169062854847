import {observable, action} from 'mobx';
import {LOG_EVENTS} from '@techsee/techsee-common/lib/constants/event-logs.constants';
// @ts-ignore
import {protectCustomerNumber} from '@techsee/techsee-common/lib/utils';
import {ITranslate} from '../../../../../services/LocalizationService';
import {IEventLogsService} from '../../../../../services/EventsLogService';

export interface LookupLogParams {
    roomId?: string;
    accountId?: string;
    userId?: string;
}

export interface LookupShowParams {
    enteredPhone: string;
    validatedPhone: string;
    isFromChangeNumber?: boolean;
    logData?: LookupLogParams;
}

export interface ILookupNumberController {
    readonly translate: ITranslate;
    readonly showModal: boolean;
    readonly enteredPhone: string;
    readonly validatedPhone: string;

    showLookupModal(options: LookupShowParams): Promise<any>;
    cancel(): void;
    accept(): void;
}

export enum LookupModalPlace {
    INVITE_WIZARD = 'INVITE_WIZARD',
    CHANGE_NUMBER_MODAL = 'CHANGE_NUMBER_MODAL'
}

export enum LookupModalAction {
    ACCEPT_VALIDATED_NUMBER = 'ACCEPT_VALIDATED',
    CONTINUE_WITH_CURRENT_NUMBER = 'CONTINUE_WITH_CURRENT_NUMBER'
}

export class LookupNumberController {
    @observable enteredPhone: string = '';

    @observable validatedPhone: string = '';

    @observable showModal: boolean = false;

    private _resolver = {resolve: (value: unknown) => value, reject: () => {}};

    private _logParams: LookupLogParams = {};

    constructor(
        public translate: ITranslate,
        private _eventLogService: IEventLogsService,
        private _keepCustomerNumber: boolean
    ) {
        this.cancel = this.cancel.bind(this);
        this.accept = this.accept.bind(this);
    }

    @action
    showLookupModal({enteredPhone, validatedPhone, isFromChangeNumber, logData = {}}: LookupShowParams) {
        this._logParams = logData;

        return new Promise((resolve, reject) => {
            this._resolver.resolve = resolve;
            this._resolver.reject = reject;

            this.enteredPhone = enteredPhone;
            this.validatedPhone = validatedPhone;
            this.showModal = true;

            this._logLookupAction({
                modalShowedAt: isFromChangeNumber
                    ? LookupModalPlace.CHANGE_NUMBER_MODAL
                    : LookupModalPlace.INVITE_WIZARD
            });
        });
    }

    @action
    cancel() {
        this.showModal = false;

        this._logLookupAction({action: LookupModalAction.CONTINUE_WITH_CURRENT_NUMBER});

        this._resolver.reject();
    }

    @action
    accept() {
        this.showModal = false;

        this._logLookupAction({action: LookupModalAction.ACCEPT_VALIDATED_NUMBER});

        this._resolver.resolve(null);
    }

    private _logLookupAction(meta: any) {
        this._eventLogService.info({
            logType: LOG_EVENTS.smsNumberLookupUserAction,
            accountId: this._logParams.accountId || 'none',
            userId: this._logParams.userId || 'none',
            roomId: this._logParams.roomId || 'none',
            meta: {
                ...meta,
                enteredPhone: this._protecCustomerNumber(this.enteredPhone),
                validatedPhone: this._protecCustomerNumber(this.validatedPhone)
            }
        });
    }

    private _protecCustomerNumber(number: string) {
        return !this._keepCustomerNumber ? protectCustomerNumber(number) : number;
    }
}
