import React from 'react';
import {observer} from 'mobx-react';
import {FormInput} from '@techsee/techsee-ui-common/lib/forms/input';
import {PrimaryButton} from '@techsee/techsee-ui-common/lib/forms/buttons/primary';
import {ButtonTypeAttributes} from '@techsee/techsee-ui-common/lib/forms/buttons/generic';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {IJoinOfflineController} from './controller';
import {ITranslate} from '../../../../../services/LocalizationService';
import {Icon} from '@techsee/techsee-ui-common/lib/icons/simple-icon';

export interface IJoinOfflineFormProps {
    ctrl: IJoinOfflineController;
    translate: ITranslate;
    onJoin: (sessionId: string, byId?: boolean) => Promise<void>;
    getLink?: (sessionId: string) => string;
    warmTransfer?: boolean;
}

@observer
export class JoinOfflineForm extends TechseeBaseComponent<IJoinOfflineFormProps> {
    renderInternal() {
        const {ctrl, translate, onJoin, warmTransfer, getLink} = this.props;
        const {form, sessionIdError, onChange, onSubmit, onSubmitWt} = ctrl;

        return (
            <form
                className='join-offline-info'
                onSubmit={(event) => (!warmTransfer ? onSubmit(event, onJoin) : onSubmitWt(event, onJoin, getLink))}>
                <div className='join-offline-info__field'>
                    <FormInput
                        uid={'offline-session-id'}
                        onChange={onChange}
                        model={form.fields[form.fieldNames.sessionId]}
                    />
                    {!!(sessionIdError && form.fields[form.fieldNames.sessionId].value) && (
                        <div className='join-offline-info__error'>
                            {translate('REACT.INVITE.VIEW.SESSION_ID.ERROR.NOT_EXIST')}
                        </div>
                    )}
                </div>

                <div className='join-offline-info__button'>
                    <PrimaryButton
                        disabled={!form.fields[form.fieldNames.sessionId].value}
                        type={ButtonTypeAttributes.Submit}
                        className={'submit'}>
                        {translate('REACT.INVITE.VIEW.JOIN_OFFLINE_SESSION_SUBMIT')}
                        <Icon iconName='arrow-next' />
                    </PrimaryButton>
                </div>
            </form>
        );
    }
}

export default JoinOfflineForm;
