'use strict';

import tsImageLoaderView from './ts-image-loader.view.html';
import './ts-image-loader.style.scss';

/**
 * This directive creates an ObjectURL, from a local image.
 *
 * Attributes:
 *
 *  image-model: Model for the Image ObjectURL
 */

class TsImageLoaderController {
    constructor($element, $attrs, $scope, $window) {
        'ngInject';

        this.URL = $window.URL;
        this.$scope = $scope;
    }

    getImageURL(imageFile) {
        this.$scope.$apply(() => {
            this.model = URL.createObjectURL(imageFile);
            this.onImageSelect({imageFile: this.model});
        });
    }

    upload(file) {
        if (!file) {
            return;
        }

        if (!/^image\//.test(file.type)) {
            this.onFailedLoad
                ? this.onFailedLoad({
                      err: {
                          fileTypeError: true,
                          details: {
                              type: file.type,
                              size: file.size
                          }
                      }
                  })
                : this.onImageSelect({imageFile: null});

            return;
        }

        this.getImageURL(file);
    }
}

export function tsImageLoaderDirective() {
    return {
        template: tsImageLoaderView,
        transclude: true,
        replace: true,
        restrict: 'E',
        scope: {},
        bindToController: {
            model: '=imageModel',
            onImageSelect: '&',
            onFailedLoad: '&'
        },
        controller: TsImageLoaderController,
        controllerAs: 'vm'
    };
}
