import {ITranslate} from '../../services/LocalizationService';
import {action, computed, observable} from 'mobx';
import {IDbReports} from '../../services/AngularServices/AngularServices';
import {IAgentPerformanceData, INTERVAL_LOAD_DATA} from './contracts';
import {IBrowserUtilsService} from '@techsee/techsee-client-infra/lib/services/BrowserUtilsService';
import {defineNonEnumerable} from '@techsee/techsee-common/lib/core/object-utils';
import {Nullable} from '@techsee/techsee-common';
import {IAuthExpiryService} from '../../../services/ts-auth-expiry/ts-auth-expiry.service';
import {DeviceCategory} from '@techsee/techsee-common/lib/constants/utils.constant';
import {getRootStore} from '../../app.bootstrap';
import {IEventLogsService} from '../../services/EventsLogService';
import {LOG_EVENTS} from '@techsee/techsee-common/lib/constants/event-logs.constants';

export interface IAgentPerformanceController {
    translate: ITranslate;
    position: {x: number; y: number};
    boundaries: {left: number; top: number; right: number; bottom: number} | string;
    wrapperElementClass: string;
    agentPerformanceData: Nullable<IAgentPerformanceData>;
    isUserRole: boolean;
    enableCustomerSurvey: boolean;
    enableAgentSurvey: boolean;
    shouldOpenAgentSruvey: boolean;
    deviceCategory: DeviceCategory;
    toggleAgentSurvey(): void;
    setPosition(position: {x: number; y: number}): void;
    setFirstPosition(position: {x: number; y: number}): void;
    leftInitialDelta: number;
    topInitialPosition: number;
    isFirstPosition: boolean;
}

export class AgentPerformanceController implements IAgentPerformanceController {
    private readonly _translate: ITranslate;

    private readonly _dbReportsService: IDbReports;

    private readonly _browserUtilsService: IBrowserUtilsService;

    private readonly _authExpiryService: IAuthExpiryService;

    private _deviceCategory: DeviceCategory;

    isUserRole: boolean = false;

    @observable enableCustomerSurvey: boolean = false;

    @observable enableAgentSurvey: boolean = false;

    @observable shouldOpenAgentSruvey: boolean = false;

    @observable private _position: {x: number; y: number} = {x: 0, y: 0};

    @observable private _wrapperElementClass: string = 'body';

    @observable private _boundaries: {left: number; top: number; right: number; bottom: number} | string = {
        left: 0,
        top: 0,
        right: window.innerWidth,
        bottom: window.innerHeight
    };

    @observable private _agentPerformanceData: Nullable<IAgentPerformanceData> = null;

    @observable checkInterval: any;

    leftInitialDelta: number = 38;

    topInitialPosition: number = 170;

    @observable isFirstPosition: boolean = true;

    constructor(
        translate: ITranslate,
        dbReportsService: IDbReports,
        isUserRole: boolean,
        browserUtilsService: IBrowserUtilsService,
        authExpiryService: IAuthExpiryService,
        private _eventLogService: IEventLogsService,
        enableCustomerSurvey: boolean,
        enableAgentSurvey: boolean,
        private currentUser: any
    ) {
        this._translate = translate;
        this._dbReportsService = dbReportsService;
        this.isUserRole = isUserRole;
        this._browserUtilsService = browserUtilsService;
        this._authExpiryService = authExpiryService;
        this.enableCustomerSurvey = enableCustomerSurvey;
        this.enableAgentSurvey = enableAgentSurvey;

        this.toggleAgentSurvey = this.toggleAgentSurvey.bind(this);
        this.setFirstPosition = this.setFirstPosition.bind(this);

        const environmentDetect = getRootStore().environmentService;

        this._deviceCategory =
            environmentDetect.deviceCategory(getRootStore().displayTabletAsDesktop) || DeviceCategory.desktop;

        defineNonEnumerable(this, '_browserUtilsService', browserUtilsService);
        defineNonEnumerable(this, '_authExpiryService', authExpiryService);
    }

    get deviceCategory() {
        return this._deviceCategory;
    }

    @computed
    get agentPerformanceData() {
        return this._agentPerformanceData;
    }

    @action
    setFirstPosition(position: {x: number; y: number}) {
        this.setPosition(position);
        this.isFirstPosition = false;
    }

    @action
    setPosition(position: {x: number; y: number}) {
        this._position = position;
    }

    @action
    setBoundries(boundaries: {left: number; top: number; right: number; bottom: number} | string) {
        this._boundaries = boundaries;
    }

    @action
    setElementWrapper(wrapperElementClass: string) {
        this._wrapperElementClass = wrapperElementClass;
    }

    @computed
    get position() {
        return this._position;
    }

    @computed
    get boundaries() {
        return this._boundaries;
    }

    @computed
    get wrapperElementClass() {
        return this._wrapperElementClass;
    }

    @action
    private _setAgentPerformanceData(agentPerformanceData: IAgentPerformanceData) {
        this._agentPerformanceData = agentPerformanceData;
        this._agentPerformanceData.average = this._agentPerformanceData.average || 0;
    }

    @action
    init() {
        this.loadData();

        if (!this.checkInterval) {
            this.loadDataInterval();
        }
    }

    @action
    loadData() {
        this._getAgentPerformanceData()
            .then((agentPerformanceData: Nullable<IAgentPerformanceData>) => {
                if (!agentPerformanceData) {
                    clearInterval(this.checkInterval);
                    this.checkInterval = null;

                    this._eventLogService.info({
                        logType: LOG_EVENTS.agentPerformance,
                        userId: this.currentUser._id,
                        accountId: this.currentUser.accountId,
                        meta: {description: 'Data is no longer unavilable, probably due to user of group configuration'}
                    });

                    return this._browserUtilsService.saveToSessionStorage('shouldNotDisplayAgentPerformance', true);
                }

                this._setAgentPerformanceData(agentPerformanceData as IAgentPerformanceData);
                this.setBoundries({left: 0, top: 0, right: window.innerWidth, bottom: window.innerHeight});
            })
            .catch((err: any) => {
                console.log(err);
            });
    }

    loadDataInterval() {
        this.checkInterval = setInterval(() => {
            if (this._authExpiryService.isSessionExpired) {
                return;
            }

            this.loadData();
        }, INTERVAL_LOAD_DATA);
    }

    private _getAgentPerformanceData(): any {
        const now = new Date();

        return this._dbReportsService
            .agentPerformance({params: {now}})
            .then((res: {data: IAgentPerformanceData}) => res.data);
    }

    get translate() {
        return this._translate;
    }

    @action toggleAgentSurvey() {
        this.shouldOpenAgentSruvey = !this.shouldOpenAgentSruvey;
    }
}
