import {RoomInfo} from './component';
import {inject, IReactComponent} from 'mobx-react';

import './styles.scss';

const mapStoresToProps = (stores: any) => ({
    ctrl: stores.roomInfoController,
    translate: stores.localizationService.translate.bind(stores.localizationService)
});

const RoomInfoComponent = inject(mapStoresToProps)(RoomInfo) as IReactComponent;

export default RoomInfoComponent;
