import React, {Component} from 'react';
import {observer} from 'mobx-react';
import moment from 'moment';

import {AppState} from '../../../app.state';
import {ITranslate} from '../../../services/LocalizationService';
// @ts-ignore
import {reportToMomentDateFormat} from '@techsee/techsee-common/lib/utils';

export interface IAgentInfoUsernameProps {
    appState: AppState;
    reportsDateFormat: string;
    translate: ITranslate;
}

@observer
export class AgentInfoUsername extends Component<IAgentInfoUsernameProps> {
    render() {
        const {appUser} = this.props.appState;
        const _momentDateFormat = reportToMomentDateFormat(this.props.reportsDateFormat);
        const _translate = this.props.translate;

        if (!appUser) {
            return null;
        }

        const _lastLogin = moment(appUser.lastLogin).local().format(_momentDateFormat).toString();

        return (
            <div>
                <div>
                    {appUser.firstName} {appUser.lastName}
                </div>
                <div className={'lastLogin'}>
                    {_translate('REACT.LEFTBAR.USER.LAST_LOGIN')} {_lastLogin}
                </div>
            </div>
        );
    }
}
