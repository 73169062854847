'use strict';

import pick from 'lodash/pick';
import {SURVEY_ANSWER_MAX_LENGTH} from '@techsee/techsee-common/lib/constants/room.constants';
import './survey.style.scss';

import {SURVEY_CONSTANTS} from '../dashboard.settings.js';

export class SurveyController {
    constructor($modalInstance, failure, questions, dir) {
        'ngInject';

        this.$modalInstance = $modalInstance;
        this.failure = failure;
        this.questions = questions;
        this.dir = dir;

        this.SURVEY_ANSWER_MAX_LENGTH = SURVEY_ANSWER_MAX_LENGTH;
        this.ANSWER_TYPES = SURVEY_CONSTANTS.ANSWER_TYPES;

        questions.filter((q) => q.answerType === this.ANSWER_TYPES.OPEN).forEach((q) => (q.answer = ''));
        questions.filter((q) => q.answerType === this.ANSWER_TYPES.SELECT).forEach((q) => (q.answer = q.options[0]));
    }

    submit(form) {
        if (form.$invalid) {
            return;
        }

        const answers = this.questions.map((question) => pick(question, ['question', 'answerType', 'answer']));

        this.$modalInstance.close(answers);
    }

    close() {
        this.$modalInstance.close();
    }
}
