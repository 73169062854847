'use strict';

import get from 'lodash/get';

import tsCobrowsingControlView from './ts-cobrowsing-control.view.html';
import './ts-cobrowsing-control.style.scss';
import {HTTPS} from '@techsee/techsee-common/lib/constants/utils.constant';
class TsCobrowsingController {
    constructor($scope, $rootScope, tsChatApi, tsCobrowsingService, $translate) {
        'ngInject';

        this.requireImage = $rootScope.requireImage;
        this.chatApi = tsChatApi.service;
        this.drawMode = false;
        this.tsCobrowsing = tsCobrowsingService;
        this.httpsPlaceholder = HTTPS;
        this.tsCobrowsing.on(this.tsCobrowsing.EVENTS.INIT_ERROR, (err) => console.error(err));
        this.tsCobrowsing.on(this.tsCobrowsing.EVENTS.ERROR, (err) => console.error(err));
        this.tsCobrowsing.on(this.tsCobrowsing.EVENTS.CONTROL_SWITCH, () => $scope.$applyAsync());
        $translate('MAIN.VIEW.CHANGE_URL_TITLE').then((t) => {
            this.title = t;
        });

        $translate('MAIN.VIEW.CHANGE_URL_BUTTON').then((t) => {
            this.changeUrlButton = t;
        });

        this.content = 'content';
    }

    showMediaButtons() {
        return get(this.chatApi, 'accountSettings.allowUploadImagesFromVideoAndPhoto');
    }

    get currentUrl() {
        return get(this.chatApi, 'dashboard.coBrowsingSettings.url');
    }

    relocateUrl(newValue) {
        this._cancelDraw();
        this.onRelocate({url: newValue});
    }

    draw() {
        this.drawMode = !this.drawMode;

        this.onDrawRequest({enabled: this.drawMode});
    }

    switchControl() {
        this._cancelDraw();
        this.tsCobrowsing.switchControl();
    }

    _cancelDraw() {
        if (this.drawMode) {
            this.onDrawRequest({enabled: false});
            this.drawMode = false;
        }
    }

    isInControl() {
        return this.tsCobrowsing.isInControl;
    }

    isPaused() {
        return this.tsCobrowsing.isPaused;
    }

    getControlIcon() {
        return this.isInControl() ? 'cb-hand' : 'cb-eye';
    }
}

export function tsCobrowsingControlDirective() {
    'ngInject';

    return {
        template: tsCobrowsingControlView,
        replace: true,
        restrict: 'E',
        scope: {},
        controller: TsCobrowsingController,
        controllerAs: 'vm',
        bindToController: {
            onRelocate: '&',
            onSwitchControl: '&',
            onDrawRequest: '&'
        }
    };
}
