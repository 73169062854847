import get from 'lodash/get';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import {ITranslate} from '../../_react_/services/LocalizationService';
import {ITsEnvironmentDetect} from '@techsee/techsee-common/lib/helpers/ts-environment-detect';
import {getAppTracer} from '../../app.tracer';
import {KnownMediaStream, MediaServiceType} from '@techsee/techsee-media-service/lib/MediaConstants';
import {UserType} from '@techsee/techsee-common/lib/constants/room.constants';
import {IMediaSubscriber} from '@techsee/techsee-media-service/lib/MediaContracts';
import {getRootStore} from '../../_react_/app.bootstrap';
import {EventEmitter} from 'events';
// @ts-ignore
import {isAudioAvailableInMode} from '@techsee/techsee-common/lib/utils';

const trace = getAppTracer('AudioService');

export enum ALERT_COLORS {
    ERROR_DASHBOARD = 'rgba(239,79,0,1)',
    ERROR_MOBILE_DASHBOARD = 'rgba(255,0,0,1)',
    SUCCESS = '#00CF49',
    CONNECTING = '#0056D8'
}

export enum AudioNotificationEvents {
    CONNECTING = 'connecting',
    CONNECTED = 'connected',
    INPUT_ACCEPTED = 'inputAccepted',
    INPUT_REJECTED = 'inputRejected',
    MUTED = 'muted',
    UNMUTED = 'unmuted'
}

export enum AudioToastMessages {
    CONNECTING = 'DASHBOARD_MINI.USER_AUDIO_CONNECTIVITY_INDICATION_CONNECTING',
    CONNECTED = 'DASHBOARD_MINI.USER_AUDIO_CONNECTIVITY_INDICATION_SUCCESS',
    MUTED = 'DASHBOARD_MINI.USER_MUTED_THE_MICROPHONE',
    UNMUTED = 'DASHBOARD_MINI.USER_UNMUTE_THE_MICROPHONE',
    INPUT_ACCEPTED = 'DASHBOARD_MINI.USER_ACCEPTED_MICROPHONE_PERMISSIONS',
    INPUT_REJECTED = 'DASHBOARD_MINI.USER_REJECTED_MICROPHONE_PERMISSIONS'
}

export interface IAudioService {
    audioMessageAlertColor: ALERT_COLORS;
    audioMessageAlertLabel: string;
    isAudioAlertAvailable: boolean;
    isAudioSupportedByDashboard: boolean;
    audioSubscribers: IMediaSubscriber[];
    isSoundMuted: boolean;
    voipSpeakerImage: string;
    voipSpeakerText: string;
    voipMicImage: string;
    voipMicText: string;

    hasAudioStream(): boolean;
    clientStream(): IMediaSubscriber | undefined;
    isMicMuted(): boolean;
    initAudioAlert(): void;
    establishAudioStream(): void;
    toggleMute(event: any): void;
    toggleSound(event: any): void;
    enableVoipDuringSession(): void;
    createSubscriber(): Promise<void>;
    checkClientAudioHandshake(): void;
    checkIfVoipIsNotEnable(): void;
    observerConnected(): Promise<void>;
}

interface IMuteAudioParams {
    data: {
        shouldMute: boolean;
        userType: UserType;
    };
}

type MuteAudioRoom = (roomId: string, data: IMuteAudioParams) => Promise<any>;

export class AudioService extends EventEmitter implements IAudioService {
    static $inject = ['accountData', 'tsChatApi', 'eventLog'];

    private _accountData: any;

    private _chatApi: any;

    private _translate: ITranslate;

    private _dashboardMediaService: any;

    private _audioMessageAlertColor: ALERT_COLORS = ALERT_COLORS.CONNECTING;

    private _audioMessageAlertLabel: string = '';

    private _audioAlertTimer: any;

    private _isAudioAlertAvailable: boolean = false;

    private _environmentDetect: ITsEnvironmentDetect;

    private _isAudioEnabledForCurrentRoom: boolean;

    private _isMobile: boolean;

    private _eventLog: any;

    private _audioSubscribers: IMediaSubscriber[] = [];

    private _observerUser: string = '';

    private _muteAudio: MuteAudioRoom;

    private _enableAudioForObserver: boolean;

    private _audioHandshakeSuccess = false;

    constructor(
        accountData: any,
        tsChatApi: any,
        eventLog: any,
        translate: ITranslate,
        dashboardMediaService: any,
        isAudioEnabledForCurrentRoom: boolean,
        observerUser: string,
        muteAudio: MuteAudioRoom,
        enableAudioForObserver: boolean
    ) {
        super();

        this._accountData = accountData;
        this._chatApi = tsChatApi.service;
        this._translate = translate;
        this._dashboardMediaService = dashboardMediaService;
        this._environmentDetect = getRootStore().environmentService;
        this._isMobile = this._environmentDetect.isMobile(getRootStore().displayTabletAsDesktop);
        this._isAudioEnabledForCurrentRoom = isAudioEnabledForCurrentRoom;
        this._eventLog = eventLog;
        this._observerUser = observerUser;
        this._muteAudio = muteAudio;
        this._enableAudioForObserver = enableAudioForObserver;

        this.checkIfVoipIsNotEnable = this.checkIfVoipIsNotEnable.bind(this);
        this.establishAudioStream = this.establishAudioStream.bind(this);
        this.observerConnected = this.observerConnected.bind(this);

        this.initHandlers();
    }

    setAudioMutedStatus(value: boolean) {
        return this._muteAudio(this._chatApi.roomId, {
            data: {shouldMute: value, userType: this._observerUser ? UserType.supervisor : UserType.dashboard}
        });
    }

    private audioStreamNotEstablish() {
        this.setAudioAlert(
            this._isMobile ? ALERT_COLORS.ERROR_MOBILE_DASHBOARD : ALERT_COLORS.ERROR_DASHBOARD,
            'DASHBOARD.VIEW.AUDIO_CONNECTIVITY_INDICATION_ERROR'
        );
        this._chatApi.setStatus('audioHandshakeSuccess', false);
        this._audioHandshakeSuccess = false;
    }

    private audioStreamFailed(eventArgs: any) {
        this.audioStreamNotEstablish();
        this._eventLog.failedToEstablishAudioStreaming({err: eventArgs && eventArgs.err});
    }

    private get isAudioCanBeEstablish() {
        return (
            this._environmentDetect.isAudioSupported() &&
            get(this._dashboardMediaService, 'deviceSupportInfo.hasMicrophone')
        );
    }

    private setAudioAlert(color: ALERT_COLORS, label: string) {
        const audioAlertTimer = get(this._accountData, 'protectedSettings.audioStartMode.alertTimer');

        clearTimeout(this._audioAlertTimer);
        this._audioAlertTimer = null;
        this._isAudioAlertAvailable = true;
        this._audioMessageAlertColor = color;
        this._audioMessageAlertLabel = this._translate(label);

        if (color !== ALERT_COLORS.CONNECTING) {
            this._audioAlertTimer = setTimeout(() => {
                this._isAudioAlertAvailable = false;
            }, audioAlertTimer);
        }
    }

    private initHandlers() {
        this._dashboardMediaService.onAudioStreamFailed((eventArgs: any) => {
            this.emit('audioEvent', {
                type: AudioNotificationEvents.INPUT_REJECTED,
                text: AudioToastMessages.INPUT_REJECTED
            });
            this.audioStreamFailed(eventArgs);
        });

        this._chatApi.on('clientAudioMuted', (data: string, value: boolean) => {
            this.emit('audioEvent', {
                type: value ? AudioNotificationEvents.MUTED : AudioNotificationEvents.UNMUTED,
                text: value ? AudioToastMessages.MUTED : AudioToastMessages.UNMUTED
            });
        });

        this._chatApi.on('clientAudioHandshakeSuccess', (data: string, value: boolean) => {
            if (value) {
                this.emit('audioEvent', {
                    type: AudioNotificationEvents.INPUT_ACCEPTED,
                    text: AudioToastMessages.INPUT_ACCEPTED
                });
                this.emit('audioEvent', {
                    type: AudioNotificationEvents.CONNECTED,
                    text: AudioToastMessages.CONNECTED
                });
            } else {
                this.emit('audioEvent', {
                    type: AudioNotificationEvents.INPUT_REJECTED,
                    text: AudioToastMessages.INPUT_REJECTED
                });
            }
        });
    }

    get isAudioSupportedByDashboard() {
        return this.isAudioCanBeEstablish && this._isAudioEnabledForCurrentRoom;
    }

    get audioSubscribers() {
        return this._audioSubscribers;
    }

    get audioMessageAlertColor() {
        return this._audioMessageAlertColor;
    }

    get audioMessageAlertLabel() {
        return this._audioMessageAlertLabel;
    }

    get isAudioAlertAvailable() {
        return this._isAudioAlertAvailable;
    }

    //deprecated
    get isSoundMuted() {
        let isMuted = true;

        forEach(this.audioSubscribers, (subscriber: IMediaSubscriber) => {
            isMuted = isMuted && !!(subscriber && subscriber.isSoundMuted);
        });

        return isMuted;
    }

    clientStream() {
        return find(
            this.audioSubscribers,
            (subscriber: IMediaSubscriber) => subscriber && subscriber.streamType === KnownMediaStream.USER_AUDIO_STREAM
        );
    }

    get voipSpeakerImage() {
        if (this._isMobile) {
            return this.isSoundMuted
                ? 'mobile-dashboard/speaker-mute-circle.png'
                : 'mobile-dashboard/speaker-loud-circle.png';
        }

        return this.isSoundMuted ? 'speaker-mute-white.png' : 'speaker-loud-white.png';
    }

    get voipSpeakerText() {
        return this.isSoundMuted
            ? this._translate('MOBILE_MAIN.VIEW.UNMUTE_SPEAKER')
            : this._translate('MOBILE_MAIN.VIEW.MUTE_SPEAKER');
    }

    get voipMicImage() {
        if (this._isMobile) {
            return this.isMicMuted() ? 'mobile-dashboard/audio-off.png' : 'mobile-dashboard/audio-on.png';
        }

        return this.isMicMuted() ? 'mobile-dashboard/mic-mute.png' : 'mobile-dashboard/mic-unmute.png';
    }

    get voipMicText() {
        if (this._isMobile) {
            return this.isMicMuted()
                ? this._translate('MOBILE_MAIN.VIEW.AUDIO_OFF')
                : this._translate('MOBILE_MAIN.VIEW.AUDIO_ON');
        }

        return this.isMicMuted()
            ? this._translate('MOBILE_MAIN.VIEW.UNMUTE_MIC')
            : this._translate('MOBILE_MAIN.VIEW.MUTE_MIC');
    }

    isMicMuted() {
        if (this._observerUser) {
            const currentObserver = find(
                get(this._chatApi, 'dashboard.observersAudioMuted'),
                (observer) => observer._id === this._observerUser
            );

            if (currentObserver) {
                return !!currentObserver.audioMuted;
            }

            return true;
        }

        return !!get(this._chatApi, 'dashboard.audioMuted');
    }

    checkClientAudioHandshake() {
        if (get(this._chatApi, 'client.audioHandshakeSuccess') === false) {
            this.setAudioAlert(
                this._isMobile ? ALERT_COLORS.ERROR_MOBILE_DASHBOARD : ALERT_COLORS.ERROR_DASHBOARD,
                'DASHBOARD.VIEW.AUDIO_CONNECTIVITY_INDICATION_ERROR'
            );
        }
    }

    initAudioAlert() {
        this.setAudioAlert(ALERT_COLORS.CONNECTING, 'DASHBOARD.VIEW.AUDIO_CONNECTIVITY_INDICATION_CONNECTING');
        this.emit('audioEvent', {
            type: AudioNotificationEvents.CONNECTING,
            text: AudioToastMessages.CONNECTING
        });
        if (
            get(this._chatApi, 'client.audioHandshakeSuccess') === false ||
            get(this._chatApi, 'dashboard.audioHandshakeSuccess') === false
        ) {
            this.setAudioAlert(
                this._isMobile ? ALERT_COLORS.ERROR_MOBILE_DASHBOARD : ALERT_COLORS.ERROR_DASHBOARD,
                'DASHBOARD.VIEW.AUDIO_CONNECTIVITY_INDICATION_ERROR'
            );
        }
    }

    establishAudioStream() {
        const isOpentok = this._dashboardMediaService.mediaServiceType === MediaServiceType.OPENTOK;

        if (this.hasAudioStream()) {
            this.setAudioAlert(ALERT_COLORS.SUCCESS, 'DASHBOARD.VIEW.AUDIO_CONNECTIVITY_INDICATION_SUCCESS');

            if (!this._audioHandshakeSuccess && isOpentok) {
                this.setAudioMutedStatus(false);
            }

            this._audioHandshakeSuccess = true;
            this._chatApi.setStatus('audioHandshakeSuccess', true);

            if (!isOpentok) {
                this.pauseAudioStream(this.isMicMuted());
            }
        }
    }

    hasAudioStream() {
        const clientStream = this.clientStream();

        return !!(clientStream && clientStream.hasAudio && clientStream.isPlaying);
    }

    enableVoipDuringSession() {
        const isVoipEnabled = this.isAudioSupportedByDashboard;
        const clientAudioSupport = get(this._chatApi, 'client.audioSupport');

        this._chatApi.setStatus('audioSupport', isVoipEnabled);

        if (isVoipEnabled && clientAudioSupport) {
            try {
                if (!this._dashboardMediaService.isVoipEnabled) {
                    this._dashboardMediaService.enableVoipDuringSession();
                    this._eventLog.audioSupportDetected();
                }
            } catch (e) {
                trace.error('error while trying to enabling voip  during session: ', e && e.toString());
            }
        } else {
            if (!this.isAudioCanBeEstablish && this._isAudioEnabledForCurrentRoom) {
                this.audioStreamNotEstablish();
            }

            this._eventLog.audioIsNotSupported({
                meta: {
                    isVoipEnabled,
                    hasMicrophone: get(this._dashboardMediaService, 'deviceSupportInfo.hasMicrophone'),
                    clickApproveAudio: this._isAudioEnabledForCurrentRoom,
                    isDeviceSupportAudio: this._environmentDetect.isAudioSupported(),
                    clientAudioSupport
                }
            });
        }
    }

    createSubscriber() {
        const subscribersPromises = [];

        subscribersPromises.push(
            this._dashboardMediaService.createSubscriber({
                container: document.querySelector('.audio-subscriber-container-client'),
                streamType: KnownMediaStream.USER_AUDIO_STREAM
            })
        );

        if (this._observerUser) {
            subscribersPromises.push(
                this._dashboardMediaService.createSubscriber({
                    container: document.querySelector('.audio-subscriber-container-agent'),
                    streamType: KnownMediaStream.AGENT_AUDIO_STREAM
                })
            );
        }

        return Promise.all(subscribersPromises).then((subscribers: IMediaSubscriber[]) => {
            this._audioSubscribers = subscribers;
        });
    }

    observerConnected() {
        if (!this.isAudioSupportedByDashboard || !this._enableAudioForObserver) {
            return Promise.resolve();
        }

        return this._dashboardMediaService
            .createSubscriber({
                container: document.querySelector('.audio-subscriber-container-observer'),
                streamType: KnownMediaStream.OBSERVER_AUDIO_STREAM
            })
            .then((subscriber: IMediaSubscriber) => {
                this._audioSubscribers.push(subscriber);
            });
    }

    displayAudioButton() {
        return (
            isAudioAvailableInMode(get(this._chatApi, 'client.mode')) &&
            get(this._chatApi, 'dashboard.audioSupport') &&
            get(this._chatApi, 'client.audioSupport') &&
            get(this._chatApi, 'dashboard.audioHandshakeSuccess') !== false &&
            get(this._chatApi, 'client.audioHandshakeSuccess') !== false
        );
    }

    toggleMute(event: any) {
        if (event) {
            event.stopPropagation();
        }

        this.pauseAudioStream(!this.isMicMuted());
    }

    pauseAudioStream(value: boolean) {
        this.setAudioMutedStatus(value).then(() => this._dashboardMediaService.pauseAudioStream(value));
    }

    //deprecated
    toggleSound(event: any) {
        if (event) {
            event.stopPropagation();
        }

        forEach(
            this.audioSubscribers,
            (subscriber: IMediaSubscriber) => subscriber && subscriber.muteSound(!this.isSoundMuted)
        );
    }

    checkIfVoipIsNotEnable() {
        if (!get(this._dashboardMediaService, 'isVoipEnabled')) {
            this._chatApi.setStatus('audioSupport', false);

            if (!isEmpty(this.audioSubscribers)) {
                this.audioSubscribers.forEach((audioSubscriber: IMediaSubscriber) =>
                    this._dashboardMediaService.destroySubscriber(audioSubscriber.container)
                );
            }
        }
    }
}
