import {inject, IReactComponent} from 'mobx-react';
import {SessionHistory} from './SessionHistory';

import './styles.scss';

const mapStoresToProps = (stores: any) => ({
    ctrl: stores.sessionHistoryController,
    translate: stores.localizationService.translate.bind(stores.localizationService)
});

const SessionHistoryComponent = inject(mapStoresToProps)(SessionHistory) as IReactComponent;

export default SessionHistoryComponent;
