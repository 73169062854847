'use strict';

import get from 'lodash/get';
import {EventEmitter} from 'events';
import {getRootStore} from '../../_react_/app.bootstrap';

export class TsSnapshotReminderService extends EventEmitter {
    constructor(tsInterfaceHelper, eventLog, tsChatApi) {
        'ngInject';

        super();

        this.useNewInterface = tsInterfaceHelper.useNewInterface;
        this.eventLog = eventLog;
        this.chatApi = tsChatApi.service;
        this.browserUtilsService = getRootStore().browserUtilsService;
    }

    init(settings) {
        if (this.browserUtilsService.getFromSessionStorage(`agentSnapshotReminderPopDisabled_${settings.roomId}`)) {
            return;
        }

        this._settings = settings;

        this._isVideoFocused = true;
        this._isVideoReady = false;
        this._isActive = this.useNewInterface && get(this._settings, 'enabled');
        this._timesPopped = 0;
        this._timeElapsedMs = 0;
        this._timer = null;

        this.hide();

        this._initialized = true;
    }

    hide() {
        this.pop = false;

        // Rewind timer if needed
        if (this._isActive) {
            this._updateTimer();
        }
    }

    show() {
        if (!this._isActive) {
            return;
        }

        this._timesPopped++;
        this.pop = true;

        this.eventLog.snapshotReminderShown({time: this._timesPopped, maxTimes: this._settings.maxTimes});

        if (this._timesPopped >= this._settings.maxTimes) {
            this._disableReminder();
        }
    }

    cleanup() {
        if (!this._initialized) {
            return;
        }

        this._isActive = false;

        this._initialized = false;
    }

    videoReady(isReady) {
        if (!this._isActive) {
            return;
        }

        this._isVideoReady = isReady;

        this._updateTimer();
    }

    snapshotTaken() {
        if (!this._isActive) {
            return;
        }

        this._disableReminder();

        this._updateTimer();

        this.pop = false;

        this.emit('snapshotTaken');
    }

    videoFocusChanged(isFocused) {
        if (!this._isActive) {
            return;
        }

        this._isVideoFocused = isFocused;

        this._updateTimer();
    }

    _updateTimer() {
        const shouldBeTiming = this._isVideoFocused && this._isVideoReady;

        if (shouldBeTiming && !this._timer) {
            this._startTimestamp = Date.now();
            this._timer = setTimeout(
                () => {
                    this._timer = null;
                    this._timeElapsedMs = 0;

                    this.show();
                },
                this._settings.timeout * 1000 - this._timeElapsedMs
            );
        } else if (!shouldBeTiming && this._timer) {
            clearTimeout(this._timer);
            this._timer = null;

            if (!this._isVideoFocused) {
                this._timeElapsedMs = 0;
            } else {
                this._timeElapsedMs += Date.now() - this._startTimestamp;
            }
        }
    }

    _disableReminder() {
        this._isActive = false;

        this.browserUtilsService.saveToSessionStorage(
            `agentSnapshotReminderPopDisabled_${this._settings.roomId}`,
            true
        );
    }
}
