import {IMultipartyController, MultipartyEvents} from '@techsee/techsee-media-service-client';
import {MultipartyStreamPropertyChangedEvent} from '@techsee/techsee-media-service-client/lib/services/MultipartyEventTypes';
import {EventEmitter} from 'events';
import {getRootStore} from '../../../app.bootstrap';

export interface IMediaInputStateService {
    mediaPermissionRejected(): void;
    on(event: string, listener: (...args: any[]) => void): void;
}

export enum MediaInputMessage {
    CONNECTING = 'DASHBOARD_MINI.USER_AUDIO_CONNECTIVITY_INDICATION_CONNECTING',
    CONNECTED = 'DASHBOARD_MINI.USER_AUDIO_CONNECTIVITY_INDICATION_SUCCESS',
    USER_MUTED = 'DASHBOARD_MINI.USER_MUTED_THE_MICROPHONE',
    USER_UNMUTED = 'DASHBOARD_MINI.USER_UNMUTE_THE_MICROPHONE',
    USER_INPUT_ACCEPTED = 'DASHBOARD_MINI.USER_ACCEPTED_MEDIA_PERMISSIONS',
    USER_INPUT_REJECTED = 'DASHBOARD_MINI.USER_REJECTED_MEDIA_PERMISSIONS'
}

export enum MediaInputNotificationEvent {
    CONNECTING = 'connecting',
    CONNECTED = 'connected',
    USER_INPUT_ACCEPTED = 'inputAccepted',
    USER_INPUT_REJECTED = 'inputRejected',
    USER_MUTED = 'muted',
    USER_UNMUTED = 'unmuted'
}

export class MediaInputStateService extends EventEmitter implements IMediaInputStateService {
    constructor(
        private readonly multipartyController: IMultipartyController,
        private readonly isAudioDisabled: boolean,
        private readonly roomId: string
    ) {
        super();

        this.initializeHandlers();
    }

    mediaPermissionRejected() {
        if (!this.isAudioDisabled) {
            this.reportAndEmitMediaInputStateEvent(
                MediaInputNotificationEvent.USER_INPUT_REJECTED,
                MediaInputMessage.USER_INPUT_REJECTED
            );
        }
    }

    private initializeHandlers() {
        if (!this.isAudioDisabled) {
            this.reportAndEmitMediaInputStateEvent(
                MediaInputNotificationEvent.CONNECTING,
                MediaInputMessage.CONNECTING
            );
            this.multipartyController?.on(MultipartyEvents.streamPropertyChanged, this.onVideoAudioStatusEvent);
        }

        this.multipartyController!.on(MultipartyEvents.streamCreated, this.onStreamCreatedEvent);
    }

    terminateHandlers() {
        this.multipartyController.off(MultipartyEvents.streamCreated, this.onStreamCreatedEvent);
        this.multipartyController.off(MultipartyEvents.streamPropertyChanged, this.onVideoAudioStatusEvent);
    }

    private onStreamCreatedEvent = (meta: any) => {
        if (!this.isAudioDisabled && meta.isOwnConnection) {
            if (meta.hasAudio) {
                this.reportAndEmitMediaInputStateEvent(
                    MediaInputNotificationEvent.CONNECTED,
                    MediaInputMessage.CONNECTED
                );
            }
        }

        if (!this.isAudioDisabled && !meta.isOwnConnection) {
            if (meta.hasAudio) {
                this.reportAndEmitMediaInputStateEvent(
                    MediaInputNotificationEvent.USER_INPUT_ACCEPTED,
                    MediaInputMessage.USER_INPUT_ACCEPTED
                );
            }
        }
    };

    private onVideoAudioStatusEvent = (event: MultipartyStreamPropertyChangedEvent) => {
        if (!event.isOwnConnection) {
            if (event.hasAudio) {
                this.reportAndEmitMediaInputStateEvent(
                    MediaInputNotificationEvent.USER_UNMUTED,
                    MediaInputMessage.USER_UNMUTED
                );
            } else {
                this.reportAndEmitMediaInputStateEvent(
                    MediaInputNotificationEvent.USER_MUTED,
                    MediaInputMessage.USER_MUTED
                );
            }
        }
    };

    private reportAndEmitMediaInputStateEvent = (type: MediaInputNotificationEvent, text: MediaInputMessage) => {
        this.reportMediaInputStateEvent(type);
        this.emitMediaInputStateEvent(type, text);
    };

    private reportMediaInputStateEvent = (type: MediaInputNotificationEvent) => {
        getRootStore().dbRoomsService.setReportedField(this.roomId, {
            data: {
                event: {
                    key: 'audioStatus',
                    value: {type},
                    type: 'push'
                }
            }
        });
    };

    private emitMediaInputStateEvent = (type: MediaInputNotificationEvent, text: MediaInputMessage) => {
        const eventData: IMediaInputStateEvent = {
            type,
            text
        };

        this.emit('mediaInputState', eventData);
    };
}

export interface IMediaInputStateEvent {
    type: MediaInputNotificationEvent;
    text: MediaInputMessage;
}
