/* eslint-disable camelcase,indent */
import {action, runInAction} from 'mobx';
import get from 'lodash/get';
import find from 'lodash/find';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import {IBrowserUtilsService} from '@techsee/techsee-client-infra/lib/services/BrowserUtilsService';
import {IDomUtilsService} from '@techsee/techsee-client-infra/lib/services/DomUtilsService';
import {
    AddressTypesEnum,
    MeetingMode,
    ModePriority,
    UserType
} from '@techsee/techsee-common/lib/constants/room.constants';
import {Nullable} from '@techsee/techsee-common';
// @ts-ignore
import {getDesktopShowUrl} from '@techsee/techsee-common/lib/utils';
import {DeepReadonly} from '@techsee/techsee-common/lib/core/data-structures';
import {LabelType} from '@techsee/techsee-ui-common/lib/notification-label';
import {InviteState} from './_contracts/InviteState';
import {AccountSettings, ModesOption} from '../../models/AccountSettings';
import {
    IAuthService,
    IDbEmail,
    IDbHistory,
    IDbObservation,
    IDbOtt,
    IDbRooms,
    IDbSso,
    IDbUser,
    IDbVideoRecordings
} from '../../services/AngularServices/AngularServices';

import {
    ConnectionStatesEnum,
    DeviceDetails,
    ISessionService,
    SessionConnectResult,
    StartSessionParams,
    SubscriptionDisposer
} from '../../services/Session/SessionContracts';
import {OperationResult} from '../../models/OperationResult';
import {InviteWizardController} from './invite-wizard/InviteWizard.Controller';
import {InviteMethodController} from './invite-wizard/components/invite-method-form/controller';
import {JoinOfflineController} from './invite-wizard/components/join-offline-form/controller';
import {
    IOfflineSessionsController,
    OfflineSessionsController
} from './invite-wizard/components/offline-sessions/controller';
import {
    IPendingRoomSessionsController,
    PendingRoomSessionsController
} from './invite-wizard/components/PendingRoomSessions/controller';
import {SessionHistoryController} from '../../components/session-history/controller';
import {createFieldModel, getRootStore} from '../../app.bootstrap';
import {ChangeNumberController} from './invite-wizard/components/change-number/controller';
import {ILookupNumberController} from './invite-wizard/components/lookup-number/controller';
import {IAgentSettingsController} from '../../layouts/main-layout/agent-settings/controller';
import {ISmsService} from '../../services/SmsService';
import {ILocalizationService} from '../../services/LocalizationService';
import {SessionAbortController} from './invite-top-bar/session-abort/controller';
import {RoomInfoController} from './invite-top-bar/room-info/controller';
import {IRedirectionService} from '../../services/RedirectionService';
import {IMainLayoutController, MainLayoutController} from '../../layouts/main-layout/layout.controller';
import {InviteSmsFlow} from './invite.sms.flow';
import {IInviteStateManager} from './invite.persister';
import {ChangeNumberOrigins, ICopilotSettings, IInviteFlowManager, InviteStepsEnum} from './_contracts/InviteContracts';
import {IInviteFlowLogger} from './invite.flow.logger';
import {ReactText} from 'react';
import {StartWithModesController} from '../../components/start-with-modes/controller';
import {InviteMethodInfo, NetworkInfo} from '../../models/LiveSessionState';
import {AppState} from '../../app.state';
import {InviteTopBarController} from './invite-top-bar/controller';
import {TempAccountSettings} from '../../layouts/main-layout/main-top-bar/controller.base';
import {IAgentPerformanceController} from '../../components/agent-performance/controller';
import {InviteStartedFrom} from '@techsee/techsee-common/lib/constants/utils.constant';
import {onSocketVersionHandler} from '../../services/Session/SessionEventHandler';
import {EVENT_TYPES} from '../../../services/ts-sms/ts-sms.settings';
import {IAccountSocketService} from '../../services/AccountSocket/AccountSocketService';
import {accountTypes, RolesConstant, VideoFilterType} from '@techsee/techsee-common/lib/constants/account.constants';
import {ISelectVideoPublisherController} from '../../components/select-video-publisher/controller';
import {ObservableController} from './invite-wizard/components/observable-form/controller';
import {
    IObservableSessionsController,
    ObservableSessionsController
} from './invite-wizard/components/observable-sessions/controller';
import {ISimpleModalController} from '../../components/simple-modal/controller';
import {JoinRoomInfo} from '@techsee/techsee-common/lib/data-contracts/JoinRoomInfo';
import {LOG_EVENTS} from '@techsee/techsee-common/lib/constants/event-logs.constants';
import {EventLogParams, IEventLogsService} from '../../services/EventsLogService';
import {IJsApiService, MessagesStatus, MessageType} from '../../services/JsApiService';
import {getAppTracer} from '../../../app.tracer';
import {ITsEnvironmentDetect} from '@techsee/techsee-common/lib/helpers/ts-environment-detect';
import {HD_RESOLUTION} from '@techsee/techsee-media-service/lib/MediaConstants';
import {VJHistoryFilterController} from '../../components/vj-filter/controller';
import {CopilotService, ICopilotService} from '../../services/CopilotService';

function _tryDecodeUri(uri: string): string {
    try {
        return decodeURIComponent(uri).trim();
    } catch (e) {
        return uri;
    }
}

const trace = getAppTracer('InviteFlow');

export class InviteFlowManager implements IInviteFlowManager {
    private readonly _accSettings: AccountSettings;

    private readonly _sessionService: ISessionService;

    private readonly _smsService: ISmsService;

    private readonly _inviteFlowLogger: IInviteFlowLogger;

    private readonly _stateManager: IInviteStateManager;

    private _jsApiService: IJsApiService;

    private _localizationService: ILocalizationService;

    private _redirectionService: IRedirectionService;

    private _browserUtilsService: IBrowserUtilsService;

    private _sessionSubscriptions: SubscriptionDisposer[];

    private _domUtilsService: IDomUtilsService;

    private _accountSocketService: IAccountSocketService;

    private _inviteSmsFlow: Nullable<InviteSmsFlow> = null;

    private _agentPerformanceController: Nullable<IAgentPerformanceController>;

    _selectVideoPublisherController: Nullable<ISelectVideoPublisherController>;

    private _inviteTopBarController?: InviteTopBarController;

    private _roomInfoController?: RoomInfoController;

    private _sessionAbortController?: SessionAbortController;

    private _inviteMethodController?: InviteMethodController;

    private _joinOfflineController?: JoinOfflineController;

    private _observableController?: ObservableController;

    private _offlineSessionsController?: IOfflineSessionsController;

    private _pendingRoomSessionsController?: IPendingRoomSessionsController;

    private _observableSessionsController?: IObservableSessionsController;

    private _sessionHistoryController?: SessionHistoryController;

    private _changeNumberController?: ChangeNumberController;

    private _agentSettingsController: IAgentSettingsController;

    private _inviteWizardController?: InviteWizardController;

    private _mainLayoutController?: IMainLayoutController;

    private _startWithModesController?: StartWithModesController;

    private _lookupNumberController?: ILookupNumberController;

    private _vjHistoryFilterController?: VJHistoryFilterController;

    private _dbSsoService: IDbSso;

    private _dbHistoryService: IDbHistory;

    private _eventsLogService: IEventLogsService;

    private _dbVideoRecordingsService: IDbVideoRecordings;

    private _dbRoomsService: IDbRooms;

    private _dbObservationService: IDbObservation;

    private _dbEmailService: IDbEmail;

    private _dbUserService: IDbUser;

    private _dbOttService: IDbOtt;

    private accountId: string;

    private readonly _currentUser: any;

    private readonly _mobileClientURL: string;

    private readonly _isMobile: boolean;

    readonly unauthorizedModal: ISimpleModalController;

    readonly appState: AppState;

    private _copilotService: ICopilotService | undefined;

    private _authService: IAuthService;

    private _copilotElement?: HTMLDivElement;

    private _readyPromise: any = {promise: null, resolver: null, rejector: null, isReady: false};

    private _startWithModeChangedCallback = () => {};

    // eslint-disable-next-line max-params
    constructor(
        accountSettings: AccountSettings,
        inviteStateManager: IInviteStateManager,
        inviteFlowLogger: IInviteFlowLogger,
        localizationService: ILocalizationService,
        sessionService: ISessionService,
        smsService: ISmsService,
        redirectionService: IRedirectionService,
        dbHistoryService: IDbHistory,
        dbVideoRecordingsService: IDbVideoRecordings,
        dbSsoService: IDbSso,
        dbRoomsService: IDbRooms,
        dbObservationService: IDbObservation,
        accountId: string,
        browserUtilsService: IBrowserUtilsService,
        agentSettingsController: IAgentSettingsController,
        currentUser: object,
        domUtilsService: IDomUtilsService,
        mobileClientURL: string,
        appState: AppState,
        agentPerformanceController: Nullable<IAgentPerformanceController>,
        lookupNumberController: ILookupNumberController,
        accountSocketService: IAccountSocketService,
        dbEmailService: IDbEmail,
        dbUserService: IDbUser,
        selectVideoPublisherController: Nullable<ISelectVideoPublisherController>,
        unauthorizedModal: ISimpleModalController,
        environmentService: ITsEnvironmentDetect,
        dbOttService: IDbOtt,
        authService: IAuthService
    ) {
        this._readyPromise.promise = new Promise((resolve, reject) => {
            this._readyPromise.resolver = resolve;
            this._readyPromise.rejector = reject;
        });

        this._authService = authService;
        this._sessionSubscriptions = [];
        this._stateManager = inviteStateManager;
        this.unauthorizedModal = unauthorizedModal;
        this._agentPerformanceController = agentPerformanceController;
        this._selectVideoPublisherController = selectVideoPublisherController;
        this._accSettings = accountSettings;
        this._localizationService = localizationService;
        this._sessionService = sessionService;
        this._smsService = smsService;
        this._redirectionService = redirectionService;
        this._domUtilsService = domUtilsService;
        this._inviteFlowLogger = inviteFlowLogger;
        this._dbHistoryService = dbHistoryService;
        this._dbSsoService = dbSsoService;
        this._dbOttService = dbOttService;
        this._dbVideoRecordingsService = dbVideoRecordingsService;
        this._browserUtilsService = browserUtilsService;
        this._dbRoomsService = dbRoomsService;
        this._dbObservationService = dbObservationService;
        this._dbEmailService = dbEmailService;
        this._dbUserService = dbUserService;
        this._agentSettingsController = agentSettingsController;
        this._lookupNumberController = lookupNumberController;
        this.accountId = accountId;
        this._currentUser = currentUser;
        this._mobileClientURL = mobileClientURL;
        this._accountSocketService = accountSocketService;
        this.appState = appState;
        this._eventsLogService = getRootStore().eventsLogService;
        this.translate = this.translate.bind(this);
        this.initCopilot = this.initCopilot.bind(this);
        this._isMobile = environmentService.isMobile(getRootStore().displayTabletAsDesktop);
        const {socketManager, jsApiService} = getRootStore();
        const useTrace =
            get(this._accSettings, 'generalSettings.verboseLogging') === undefined
                ? TRACING.ROOM_CHANNEL
                : get(this._accSettings, 'generalSettings.verboseLogging');

        this._jsApiService = jsApiService;

        if (this._accSettings.inviteMethodSettings.copilot.enabled) {
            this._copilotService = new CopilotService(
                get(this._accSettings, 'inviteMethodSettings.copilot.version'),
                this.accountId,
                this._currentUser._id
            );
        }

        this._authService.on('newToken', (token: string) => {
            if (this._copilotElement) {
                this.initCopilot(this._copilotElement);
            }
        });

        if (useTrace && socketManager.sessionSocket && socketManager.sessionSocket.roomChannelTracer) {
            socketManager.sessionSocket.roomChannelTracer.setCurrentUserId(this._currentUser._id.toString());
            socketManager.sessionSocket.roomChannelTracer.setLoggingFunction(
                (logFn: {traceMessage: string; roomInfo: JoinRoomInfo; userId?: string; extraData?: any}) => {
                    const {traceMessage, roomInfo, userId, extraData} = logFn;
                    const roomId = roomInfo && roomInfo.roomId;
                    const logParams: EventLogParams = {
                        logType: `${LOG_EVENTS.roomChannelTrace}: ${traceMessage}`,
                        userId: userId,
                        meta: {
                            roomInfo,
                            extraData
                        }
                    };

                    if (roomId) {
                        logParams.roomId = roomId;
                    }

                    this._eventsLogService.info(logParams);
                }
            );
        }

        const initPromises = [this._localizationService.init(), this._stateManager.onReady];

        Promise.all(initPromises).then(() => {
            this._stateManager.enableAutoSave();
            this.initInviteFlowControllers();
            this.setInitialWizardState();
            this.subscribeSessionEvents();
            this.initInviteMethod();
        });
    }

    //#region Contract Implementation

    get onReady(): Promise<void> {
        return this._readyPromise.promise;
    }

    get isSmsFlowInProgress(): boolean {
        return this.inviteState.smsFlowProgress.length > 0;
    }

    //Readonly state for passing further to controllers.
    get inviteState(): DeepReadonly<InviteState> {
        return this._stateManager.inviteState as DeepReadonly<InviteState>;
    }

    get inviteLogger(): IInviteFlowLogger {
        return this._inviteFlowLogger;
    }

    get copilotSettings(): ICopilotSettings {
        return get(this._accSettings, 'inviteMethodSettings.copilot');
    }

    async initCopilot(copilotElement: HTMLDivElement): Promise<void> {
        if (!get(this._accSettings, 'inviteMethodSettings.copilot')) {
            return;
        }

        try {
            await this._copilotService?.init(copilotElement);

            this._copilotService?.cameraPermissionSet(false);

            this._copilotElement = copilotElement;
        } catch (err) {
            const logParams: EventLogParams = {
                logType: LOG_EVENTS.failedToInitVizSdk.type,
                userId: this._currentUser._id,
                meta: {
                    err
                }
            };

            this._eventsLogService.error(logParams);
        }
    }

    get desktopShowURL(): {url: string; displayUrl: string} {
        const subDomain = get(this._accSettings, 'generalSettings.subDomain');
        const domainZoneSuffix = get(this._accSettings, 'generalSettings.domainZoneSuffix');

        return getDesktopShowUrl(subDomain, domainZoneSuffix);
    }

    private get _shouldApplyVideoFilter(): boolean {
        return !!(
            get(this._accSettings, 'generalSettings.enabledVirtualBackgroundInFaceMeet') &&
            (get(this._accSettings, 'generalSettings.enabledImageReplacementAgent') ||
                get(this._accSettings, 'generalSettings.enabledBlurAgent'))
        );
    }

    startSession(): Promise<void> {
        this.inviteLogger.attemptToStartSession();
        this.inviteMethodController.setCreatingSessionInProgress(true);

        return this.inviteMethodController
            .updateInviteState()
            .then(() => {
                const isOffline = this._startWithModesEnabled && this.startWithModesController.isOfflineMode;
                const isDesktopSharing =
                    this._startWithModesEnabled && this.startWithModesController.isDesktopSharingMode;

                const sessionParams: StartSessionParams = {
                    audio: this.inviteState.inviteMethodInfo.enableAudio,
                    video: this.inviteState.inviteMethodInfo.enableVideo,
                    clientLanguage: this.inviteState.inviteMethodInfo.clientLanguage,
                    customerNumber: this.inviteMethodController.isPhoneNumberEnabled
                        ? this.inviteState.inviteMethodInfo.address
                        : undefined,
                    customerEmail: this.inviteMethodController.isInviteByEmail
                        ? this.inviteState.inviteMethodInfo.address
                        : undefined,
                    userType: UserType.dashboard,
                    mobileClientURL: this._mobileClientURL,
                    offline: isOffline,
                    isDesktopSharing: isDesktopSharing,
                    measure: this.inviteState.inviteMethodInfo.enableMeasure,
                    hdEnabled:
                        !this._isMobile && this._accSettings.inviteMethodSettings.videoResolution === HD_RESOLUTION
                };

                if (this._shouldApplyVideoFilter) {
                    sessionParams.videoFilterType = get(this._accSettings, 'generalSettings.enabledBlurAgent')
                        ? VideoFilterType.BLUR
                        : VideoFilterType.BACKGROUND;
                }

                if (this.inviteMethodController.isCustomerRefEnabled) {
                    sessionParams.customerId = this.inviteState.inviteMethodInfo.customerRef;
                }

                this._browserUtilsService.saveToSessionStorage(
                    'InviteMethod',
                    this.inviteState.inviteMethodInfo.addressType
                );

                const currentMode = get(this.startWithModesController, 'currentMode.mode');

                if (this._startWithModesEnabled && currentMode && !this.startWithModesController.isOfflineMode) {
                    sessionParams.startWithAgentType = currentMode as MeetingMode;
                }

                if (currentMode === MeetingMode.videoApplication) {
                    sessionParams.referralRegion = this.inviteMethodController.selectedReferralRegion;
                }

                if (
                    currentMode === MeetingMode.coBrowsing &&
                    get(this._accSettings, 'inviteMethodSettings.coBrowsing.defaultURL')
                ) {
                    sessionParams.startUrlCobrowsing =
                        (get(
                            this._inviteMethodController,
                            'inviteForm.fields[coBrowsingInitialUrl].value'
                        ) as string) ||
                        (get(this._accSettings, 'inviteMethodSettings.coBrowsing.defaultURL') as string);
                }

                return this._sessionService
                    .startSession(sessionParams)
                    .then((startSessionResult: OperationResult<SessionConnectResult>) => {
                        runInAction(() => {
                            this.rw_inviteState.sessionInfo.sessionRoomId = startSessionResult.data!.sessionRoomId;

                            if (startSessionResult.data) {
                                this.rw_inviteState.sessionInfo.sessionId = startSessionResult.data.sessionShortId;
                                this.rw_inviteState.sessionInfo.sessionLink = startSessionResult.data.sessionLinkUrl;
                            }

                            this.rw_inviteState.sessionInfo.meetingMode = sessionParams.startWithAgentType;
                            this.rw_inviteState.sessionInfo.isOffline = sessionParams.offline;
                            this.rw_inviteState.sessionInfo.isSessionActive = !sessionParams.offline;
                            this.rw_inviteState.sessionInfo.isDesktopSharing = isDesktopSharing;

                            this._inviteFlowLogger.setRoomId(this.inviteState.sessionInfo.sessionRoomId);
                        });

                        this.inviteTopBarController &&
                            this.inviteTopBarController.setInviteMethodInfo(this.rw_inviteState.inviteMethodInfo);

                        return {startSessionResult, sessionParams};
                    });
            })
            .then((res) => {
                const isFSE = this._currentUser.role === RolesConstant.FIELD_SERVICES_ENGINEER;
                const isClientAsDashboard =
                    this._selectVideoPublisherController && !this._selectVideoPublisherController.viewVideoPublisher;

                this.inviteLogger.sessionStartedSuccessfully();
                if (this._startWithModesEnabled && this.startWithModesController.isDesktopSharingMode) {
                    if (res.startSessionResult.data) {
                        this.setInvitationCode(
                            res.startSessionResult.data.sessionShortId,
                            res.startSessionResult.data.prefixLength
                        );
                        this.inviteWizardController.displayInviteByCode();
                    }
                } else if (res.sessionParams.offline) {
                    console.log('offline');

                    return this.startOfflineSession();
                } else if (isFSE && isClientAsDashboard) {
                    return (console.log('isFSE && isClientAsDashboard'),
                    this.isEmailAddressType ? this.sendClientAsDashboardEmail() : this.sendClientAsDashboardSMS()).then(
                        () => this._redirectionService.goToClientSide(this.rw_inviteState.sessionInfo.sessionLink)
                    );
                } else {
                    console.log('else');
                    this.inviteWizardController.displaySpeakerGuidance();
                }
            });
    }

    endSession(keepRoom?: boolean): Promise<void> {
        return this._sessionService.endSession(keepRoom).then(() => this.resetWizard());
    }

    resetWizard() {
        runInAction(() => {
            this.rw_inviteState.sessionInfo.sessionRoomId = '';
            this.rw_inviteState.sessionInfo.sessionId = '';
            this.rw_inviteState.sessionInfo.sessionLink = '';

            this.rw_inviteState.sessionInfo.isSessionActive = false;

            this._inviteFlowLogger.setRoomId('');
            this._accSettings.generalSettings.nativeAppId = '';
        });

        this.inviteMethodController.resetInviteState();
        this.inviteMethodController.resetInviteForm();
        this._stateManager.resetState();
        this.abortCurrentSmsFlow();
        this.inviteWizardController.displayInviteForm();

        this._initStartWithModes();
    }

    onStartModeChanged(callback: () => void) {
        this._startWithModeChangedCallback = callback;
    }

    sendVisualJourneySMS() {
        return this.inviteMethodController.updateInviteState().then(() =>
            this._smsService.sendVisualJourney({
                visualJourney: this.inviteState.inviteMethodInfo.visualJourneyAlias,
                phone: this.inviteState.inviteMethodInfo.phoneNumber,
                countryCode: this.inviteState.inviteMethodInfo.countryCode!.replace(/[^0-9]/g, ''),
                customerId: this.inviteState.inviteMethodInfo.customerRef,
                source: InviteStartedFrom.INVITE_WIZARD
            })
        );
    }
    sendWarmTransferClientLinkSMS(sessionId: string) {
        return this._smsService.sendWarmTransferClientLink({roomId: sessionId});
    }

    sendVisualJourneyEmail() {
        return this.inviteMethodController
            .updateInviteState()
            .then(() =>
                this._dbEmailService.sendVisualJourneyEmail({
                    data: {
                        to: this.inviteState.inviteMethodInfo.address,
                        source: InviteStartedFrom.INVITE_WIZARD,
                        crid: this.inviteState.inviteMethodInfo.customerRef,
                        visualJourney: this.inviteState.inviteMethodInfo.visualJourneyAlias
                    }
                })
            )
            .then(() => this.inviteLogger.sendEmailSuccess())
            .catch((err) => {
                this.inviteLogger.sendEmailFailed({err});

                throw err;
            });
    }

    _getClientAsDashboardUrl(roomId: string) {
        const host = window.location.origin;
        const shortId = this.inviteState.sessionInfo.sessionId;
        const enableAudio = this.inviteState.inviteMethodInfo.enableAudio;

        const audio = enableAudio ? `&audio=${enableAudio}` : '';
        const room = this._accSettings.inviteMethodSettings.showRoomIdInUrl ? `roomId=${roomId}` : `g=${shortId}`;

        return `${host}/?${room}${audio}`;
    }

    sendClientAsDashboardEmail() {
        const roomId = this.inviteState.sessionInfo.sessionRoomId;
        const url = this._getClientAsDashboardUrl(roomId);

        return this.inviteMethodController.updateInviteState().then(() =>
            this._dbEmailService.sendClientAsDashboardEmail({
                roomId,
                url,
                to: this.inviteState.inviteMethodInfo.address,
                crid: this.inviteState.inviteMethodInfo.customerRef
            })
        );
    }

    sendClientAsDashboardSMS() {
        const roomId = this.inviteState.sessionInfo.sessionRoomId;
        const url = this._getClientAsDashboardUrl(roomId);

        return this.inviteMethodController.updateInviteState().then(() =>
            this._smsService.sendClientAsDashboard({
                roomId,
                url,
                phone: this.inviteState.inviteMethodInfo.address
            })
        );
    }

    sendInviteSms() {
        this.abortCurrentSmsFlow();

        this._inviteSmsFlow = new InviteSmsFlow(
            this._stateManager,
            this._smsService,
            this._accSettings,
            this.translate
        );
        this._inviteSmsFlow.sendSms().catch(() => {
            this.changeNumberController.showChangeNumberDialog(ChangeNumberOrigins.SMSFAILED);
        });
    }

    repromptClientForTos(): void {
        runInAction(() => (this.rw_inviteState.isTosRepromptSent = true));
        this._sessionService.repromptClientForTos();
        this.inviteLogger.agentSentTosReprompt();
    }

    sendEmail(): Promise<void> {
        const msg = {
            type: MessageType.emailSent,
            techseeSessionId: this._stateManager.inviteState.sessionInfo.sessionId,
            customerId: this._stateManager.inviteState.inviteMethodInfo.customerRef,
            status: MessagesStatus.Success,
            sessionType: this._stateManager.inviteState.sessionInfo.meetingMode,
            to: this.inviteState.inviteMethodInfo.address,
            url: this.inviteState.sessionInfo.sessionLink
        };

        return this._dbEmailService
            .sendEmail({
                data: {
                    to: this.inviteState.inviteMethodInfo.address,
                    url: this.inviteState.sessionInfo.sessionLink,
                    crid: this.inviteState.inviteMethodInfo.customerRef
                }
            })
            .then(() => {
                this.inviteLogger.sendEmailSuccess();
                this._jsApiService.EmailSent(msg);
            })
            .catch((err) => {
                msg.status = MessagesStatus.Failed;
                this._jsApiService.EmailSent(msg);
                this.inviteLogger.sendEmailFailed({err});
            });
    }

    changeInviteMethodInfo(): PromiseLike<void> {
        return this.inviteMethodController
            .updateInviteState()
            .then(() => {
                this._dbRoomsService.setReportedField(this.inviteState.sessionInfo.sessionRoomId, {
                    data: {event: {key: 'phoneNumberEditedTimestamps', value: new Date(), type: 'push'}}
                });

                this.changeNumberController.hideChangeNumberDialog();
                this._sessionService.updateCustomerInfo(
                    this.inviteState.inviteMethodInfo.address,
                    this.inviteState.inviteMethodInfo.addressType
                );

                if (
                    this.isSmsFlowInProgress ||
                    this.changeNumberController.modalMode === ChangeNumberOrigins.UNSUPPORTED
                ) {
                    this._dbRoomsService.allowNewClient(this.inviteState.sessionInfo.sessionRoomId);
                    this.isEmailAddressType ? this.sendEmail() : this.sendInviteSms();
                }
            })
            .catch((err) => console.error(err));
    }

    @action
    setDisplayedStep(step: string) {
        this.rw_inviteState.lastVisibleStep = step;
    }

    @action
    updateInviteMethod(inviteMethodInfo: InviteMethodInfo): void {
        this.rw_inviteState.inviteMethodInfo.addressType = inviteMethodInfo.addressType;
        this.rw_inviteState.inviteMethodInfo.customerRef = inviteMethodInfo.customerRef;
        this.rw_inviteState.inviteMethodInfo.address = inviteMethodInfo.address;
        this.rw_inviteState.inviteMethodInfo.phoneNumber = inviteMethodInfo.phoneNumber;
        this.rw_inviteState.inviteMethodInfo.countryCode = inviteMethodInfo.countryCode;
        this.rw_inviteState.inviteMethodInfo.visualJourney = inviteMethodInfo.visualJourney;
        this.rw_inviteState.inviteMethodInfo.visualJourneyAlias = inviteMethodInfo.visualJourneyAlias;
        this.rw_inviteState.inviteMethodInfo.enableAudio = inviteMethodInfo.enableAudio;
        this.rw_inviteState.inviteMethodInfo.enableVideo = inviteMethodInfo.enableVideo;
        this.rw_inviteState.inviteMethodInfo.enableMeasure = inviteMethodInfo.enableMeasure;
        this.rw_inviteState.inviteMethodInfo.referralRegion = inviteMethodInfo.referralRegion;

        if (this._accSettings.inviteMethodSettings.multiLanguage) {
            const clientLanguages = this.inviteMethodController.clientLanguages;

            if (inviteMethodInfo.clientLanguage) {
                this.rw_inviteState.inviteMethodInfo.clientLanguage = inviteMethodInfo.clientLanguage;
            } else if (clientLanguages && clientLanguages.length > 0) {
                const accountLanguage = find(
                    clientLanguages,
                    (lang) => lang.value === this._accSettings.inviteMethodSettings.dashboardLanguage
                );

                this.rw_inviteState.inviteMethodInfo.clientLanguage = (accountLanguage || clientLanguages[0]).value;
            } else {
                this.rw_inviteState.inviteMethodInfo.clientLanguage =
                    this._accSettings.inviteMethodSettings.dashboardLanguage;
            }
        } else {
            this.rw_inviteState.inviteMethodInfo.clientLanguage =
                this._accSettings.inviteMethodSettings.dashboardLanguage;
        }
    }

    translate(token: string, data?: {[key: string]: ReactText}): string {
        return this._localizationService.translate(token, data);
    }

    dispose(): void {
        this.unsubscribeSessionEvents();
        this._stateManager.disableAutoSave();
        this._stateManager.resetState();
    }

    //#endregion

    //#region Controllers

    get inviteTopBarController() {
        return this.returnOrThrowIfNull(this._inviteTopBarController);
    }

    get roomInfoController() {
        return this.returnOrThrowIfNull(this._roomInfoController);
    }

    get sessionAbortController() {
        return this.returnOrThrowIfNull(this._sessionAbortController);
    }

    get startWithModesController() {
        return this.returnOrThrowIfNull(this._startWithModesController);
    }

    get inviteMethodController() {
        return this.returnOrThrowIfNull(this._inviteMethodController);
    }

    get joinOfflineController() {
        return this.returnOrThrowIfNull(this._joinOfflineController);
    }

    get observableController() {
        return this.returnOrThrowIfNull(this._observableController);
    }

    get offlineSessionsController() {
        return this.returnOrThrowIfNull(this._offlineSessionsController);
    }
    get PendingRoomSessionsController() {
        return this.returnOrThrowIfNull(this._pendingRoomSessionsController);
    }

    get observableSessionsController() {
        return this.returnOrThrowIfNull(this._observableSessionsController);
    }

    get sessionHistoryController() {
        return this.returnOrThrowIfNull(this._sessionHistoryController);
    }

    get changeNumberController() {
        return this.returnOrThrowIfNull(this._changeNumberController);
    }

    get inviteWizardController() {
        return this.returnOrThrowIfNull(this._inviteWizardController);
    }

    get mainLayoutController() {
        return this.returnOrThrowIfNull(this._mainLayoutController);
    }

    get lookupNumberController() {
        return this.returnOrThrowIfNull(this._lookupNumberController);
    }

    get vjHistoryFilterController() {
        return this.returnOrThrowIfNull(this._vjHistoryFilterController);
    }

    private initInviteFlowControllers() {
        this._mainLayoutController = new MainLayoutController(this._accSettings.generalSettings);

        this._inviteTopBarController = new InviteTopBarController(
            this._accSettings as any as TempAccountSettings,
            this.inviteState,
            this.translate
        );

        this._roomInfoController = new RoomInfoController();
        this._sessionAbortController = new SessionAbortController(this);

        this._startWithModesController = new StartWithModesController(
            this._localizationService.translate,
            this._startWithModeChangedCallback
        );

        this._inviteMethodController = new InviteMethodController(
            this,
            this._accSettings.inviteMethodSettings,
            this._smsService,
            createFieldModel,
            this._browserUtilsService
        );

        this._joinOfflineController = new JoinOfflineController(this, createFieldModel);

        const observableConfig = {
            keepCustomerMobile: this._accSettings.generalSettings.keepCustomerMobile,
            keepCustomerEmailAddress: this._accSettings.generalSettings.keepCustomerEmailAddress
        };

        this._observableController = new ObservableController(
            this,
            createFieldModel,
            this._localizationService.translate,
            observableConfig
        );
        this._observableSessionsController = new ObservableSessionsController(
            this._localizationService.translate,
            this._currentUser,
            this._redirectionService,
            this._dbObservationService
        );

        this._offlineSessionsController = new OfflineSessionsController(
            this,
            this._localizationService.translate,
            this._dbRoomsService,
            this.appState,
            this._accSettings.generalSettings,
            this._accSettings.inviteMethodSettings,
            this._currentUser,
            this._accountSocketService
        );
        this._pendingRoomSessionsController = new PendingRoomSessionsController(
            this,
            this._localizationService.translate,
            this._dbRoomsService,
            this.appState,
            this._accSettings.generalSettings,
            this._accSettings.inviteMethodSettings,
            this._currentUser,
            this._accountSocketService
        );

        this._vjHistoryFilterController = new VJHistoryFilterController(
            this,
            this._accSettings.inviteMethodSettings,
            this.accountId,
            this._redirectionService,
            this._accSettings.generalSettings
        );

        this._sessionHistoryController = new SessionHistoryController(
            this,
            this._accSettings.inviteMethodSettings,
            this._dbHistoryService,
            this._dbVideoRecordingsService,
            this.accountId,
            this._redirectionService
        );

        this._changeNumberController = new ChangeNumberController(this, this.inviteMethodController);

        this._inviteWizardController = new InviteWizardController(
            this,
            this.mainLayoutController,
            this.inviteMethodController,
            this.joinOfflineController,
            this._offlineSessionsController,
            this._pendingRoomSessionsController,
            this.changeNumberController,
            this._localizationService.translate,
            this._accSettings.generalSettings,
            this._accSettings.inviteMethodSettings,
            this._agentSettingsController,
            this._browserUtilsService,
            this._currentUser,
            this._dbRoomsService,
            this.inviteState,
            this._domUtilsService,
            this._agentPerformanceController,
            this._observableController,
            this._observableSessionsController,
            this._dbUserService,
            this.unauthorizedModal
        );

        if (this._startWithModesEnabled) {
            this._initStartWithModes();
        }
    }

    private _getModesOptions() {
        const modesOptions = get(this._accSettings, 'inviteMethodSettings.startWithModes.modesOptions');

        return filter(
            modesOptions,
            (modeOptions) =>
                !(
                    modeOptions.mode === MeetingMode.visualJourney &&
                    isEmpty(this.inviteMethodController.visualJourniesList)
                )
        );
    }

    private _initStartWithModes() {
        const modesOptions = this._getModesOptions();
        const defaultMode = get(this._accSettings, 'inviteMethodSettings.startWithModes.defaultMode');
        const defaultStartWithMode = modesOptions.find(
            (startWithMode: ModesOption) => startWithMode.mode === defaultMode
        );
        const startWithModes = modesOptions
            .filter((startWithMode: ModesOption) => startWithMode.mode !== defaultMode)
            .sort((startWithMode: ModesOption) => (startWithMode.priority === ModePriority.PRIMARY ? -1 : 1));

        if (defaultStartWithMode) {
            startWithModes.unshift(defaultStartWithMode);
        }

        this.startWithModesController.setConfig({
            offlineEnabled: this._offlineSessionsEnabled,
            desktopShareEnabled: this._desktopShareEnabled,
            desktopShareOnlyMode: this._desktopShareOnlyMode
        });

        this.startWithModesController.setAvailableMeetingModes(startWithModes);
        this.startWithModesController.setSelectedMeetingMode(defaultMode);
    }

    private initInviteMethod() {
        this.inviteMethodController.resetInviteState();

        const errorStatus = this._browserUtilsService.getParamValue('error');

        if (this._inviteWizardController && errorStatus && errorStatus.indexOf('422') > -1) {
            this._inviteWizardController.unauthorizedModal.show();
        }

        const isStartedFromLauncher =
            this._browserUtilsService.getParamValue('sf') === InviteStartedFrom.DESKTOP_LAUNCHER;
        const {allowExternalCustomerDetails, enableQuickLaunch} = this._accSettings.inviteMethodSettings;
        const allowAutofillInviteFields = allowExternalCustomerDetails || (enableQuickLaunch && isStartedFromLauncher);

        // this won't work on localhost as browserUtilsService can't get params properly because of # in URL
        if (allowAutofillInviteFields) {
            const customerRef = this._browserUtilsService.getParamValue('r');
            const phoneNumber = this._browserUtilsService.getParamValue('p');
            const countryCode = this._browserUtilsService.getParamValue('c');

            if (customerRef) {
                this.inviteMethodController.setCustomerRef(_tryDecodeUri(customerRef));
            }

            if (phoneNumber) {
                let decodedPhoneNumber = _tryDecodeUri(phoneNumber);

                // Remove spaces
                decodedPhoneNumber = decodedPhoneNumber.replace(/ /g, '');

                this.inviteMethodController.setPhoneNumber(decodedPhoneNumber);
            }

            if (countryCode) {
                let decodedCountryCode = _tryDecodeUri(countryCode);

                // Remove spaces
                decodedCountryCode = decodedCountryCode.replace(/ /g, '');

                this.inviteMethodController.setCountryCode(decodedCountryCode);
            }
        }
    }

    private get _startWithModesEnabled(): boolean {
        return get(this._accSettings, 'inviteMethodSettings.startWithModes.enabled');
    }

    private get _offlineSessionsEnabled(): boolean {
        return get(this._accSettings, 'inviteMethodSettings.accountOfflineSessions');
    }

    private get _desktopShareEnabled(): boolean {
        return get(this._accSettings, 'inviteMethodSettings.allowDesktopSharing');
    }

    private get _desktopShareOnlyMode(): boolean {
        return get(this._accSettings, 'inviteMethodSettings.desktopShareOnlyMode');
    }

    private returnOrThrowIfNull<T>(controller?: T): T {
        if (!controller) {
            throw new Error('Controller is not ready yet (See callstack to identify which).');
        }

        return controller;
    }

    //#endregion

    //#region Session Events

    private subscribeSessionEvents() {
        this._sessionSubscriptions.push(this._sessionService.onClientEndedMeeting(this.endSession.bind(this)));
        this._sessionSubscriptions.push(
            this._sessionService.onClientUnsupportedDevice(this.onUnsupportedDevice.bind(this))
        );
        this._sessionSubscriptions.push(this._sessionService.onHandshakeSuccess(this.onHandshakeSuccess.bind(this)));
        this._sessionSubscriptions.push(this._sessionService.onClientTosResult(this.onTosResult.bind(this)));
        this._sessionSubscriptions.push(this._sessionService.onClientDeviceDetails(this.onDeviceDetails.bind(this)));
        this._sessionSubscriptions.push(
            this._sessionService.onDesktopSharingUnsupported(this.onDesktopSharingUnsupported.bind(this))
        );
        this._sessionSubscriptions.push(this._sessionService.onClientConnect(this.onConnected.bind(this)));
        this._sessionSubscriptions.push(this._sessionService.onClientDisconnect(this.onDisconnected.bind(this)));
        this._sessionSubscriptions.push(this._sessionService.onClientTosView(this.onClientTosView.bind(this)));
        // TODO: Pre-Camera approval not implemented yet. missing spec and design from sasha and hagai
        // this._sessionSubscriptions.push(this._sessionService.onClientPreCameraView(this.inviteWizardController.displayPreCameraGuidance));
        this._sessionSubscriptions.push(
            this._sessionService.onClientCameraView(this.inviteWizardController.displayCameraGuidance)
        );
        this._sessionSubscriptions.push(
            this._sessionService.onScreenShareCapturingPermission(this.onScreenShareCapturingPermission.bind(this))
        );
        this._sessionSubscriptions.push(this._sessionService.onClientNetworkInfo(this.clientNetworkInfo.bind(this)));
        this._sessionSubscriptions.push(this._sessionService.onSocketVersionChanged(onSocketVersionHandler.bind(this)));
        this._sessionSubscriptions.push(
            this._sessionService.onVideoApplicationCameraAudioDialog(
                this.onVideoApplicationCameraAudioDialog.bind(this)
            )
        );
        this._sessionSubscriptions.push(this._sessionService.onForceTimeout(this.resetWizard.bind(this)));
    }

    private unsubscribeSessionEvents() {
        while (this._sessionSubscriptions.length > 0) {
            const disposer = this._sessionSubscriptions.pop();

            disposer && disposer();
        }
    }

    private onTosResult(isApproved: boolean) {
        if (!isApproved) {
            if (this._sessionService.clientState.isReviewingTOS) {
                if (!this.inviteState.isTosRejected) {
                    const rejectMsg = this.translate('REACT.INVITE.PERMISSIONS.TERMS.TOS_REJECTED');

                    this.mainLayoutController.showNotificationMessage(rejectMsg);
                }

                runInAction(() => (this.rw_inviteState.isTosRejected = true));
            } else {
                this.endSession();
            }
        }
    }

    private onHandshakeSuccess() {
        this.inviteLogger.redirectingToDashboard();
        this.redirectToDashboard();
    }

    @action
    private onDeviceDetails(details: DeviceDetails): void {
        if (!this.inviteState.sessionInfo.isOffline) {
            this.rw_inviteState.clientMobileType = details.clientDevice;
            this.rw_inviteState.clientMobileBrowser = details.clientBrowser;
            this.rw_inviteState.clientMobileOsName = details.osName;
            this.rw_inviteState.clientMobileOsVersion = details.osVersion;
            this.rw_inviteState.usingApplication = details.usingApplication;
            this.rw_inviteState.sessionInfo.clientJoinedSession = true;
        }
    }

    @action
    private onDesktopSharingUnsupported() {
        const unsupportedNotification = {
            message: this.translate('REACT.INVITE.VIEW.DESKTOP_SHARING_UNSUPPORTED'),
            labelType: LabelType.Error
        };

        this._domUtilsService.setPagePlacement('invite-desktop-sharing-unsupported');
        this.mainLayoutController.showNotificationMessage(unsupportedNotification);
    }

    @action
    private onConnected(): void {
        this._jsApiService.sessionConnected({
            type: MessageType.sessionConnected,
            techseeSessionId: this._stateManager.inviteState.sessionInfo.sessionId,
            customerNumber: this._stateManager.inviteState.inviteMethodInfo.address,
            customerId: this._stateManager.inviteState.inviteMethodInfo.customerRef,
            sessionType: this._stateManager.inviteState.sessionInfo.meetingMode
        });

        this.rw_inviteState.connectionState = ConnectionStatesEnum.HIGH;
    }

    @action
    private onDisconnected(): void {
        this.rw_inviteState.connectionState = ConnectionStatesEnum.OFFLINE;
    }

    @action
    private clientNetworkInfo(networkInfo: NetworkInfo): void {
        if (!this.inviteState.sessionInfo.isOffline) {
            this.rw_inviteState.clientNetworkInfo.connectionType = networkInfo.connectionType;
            this.rw_inviteState.clientNetworkInfo.downlinkMax = networkInfo.downlinkMax;
            this.rw_inviteState.connectionState = ConnectionStatesEnum.HIGH;
        }
    }

    private onUnsupportedDevice() {
        this.changeNumberController.showChangeNumberDialog(ChangeNumberOrigins.UNSUPPORTED);
    }

    private onClientTosView() {
        const isDesktopSharing = this._startWithModesEnabled && this.startWithModesController.isDesktopSharingMode;
        const meetingMode = this.inviteState.sessionInfo.meetingMode;

        const appSharingTOS = meetingMode === MeetingMode.appSharing,
            screenSharingTOS = !isDesktopSharing && meetingMode === MeetingMode.screen,
            videoApplicationTOS = meetingMode === MeetingMode.videoApplication;

        switch (true) {
            case appSharingTOS:
                this.inviteWizardController.displayAppShareTOSGuidance();
                break;
            case screenSharingTOS:
                this.inviteWizardController.displayScreenShareTOSGuidance();
                break;
            case videoApplicationTOS:
                this.inviteWizardController.displayVideoApplicationTOSGuidance();
                break;
            default:
                this.inviteWizardController.displayTosGuidance();
                break;
        }
    }

    private onScreenShareCapturingPermission() {
        if (this.inviteState.sessionInfo.meetingMode === MeetingMode.screen) {
            this.inviteWizardController.displayScreenShareCaptureGuidance();
        }
    }

    private onVideoApplicationCameraAudioDialog() {
        this.inviteWizardController.displayVideoApplicationCameraAudioDialog();
    }

    //endregion

    //Read/Write enabled state for using internally.
    private get rw_inviteState(): InviteState {
        return this._stateManager.inviteState;
    }

    @action
    resetAddressField() {
        this.rw_inviteState.inviteMethodInfo.address = '';
    }

    private get smsFlowProgressError(): boolean {
        return this.rw_inviteState.smsFlowProgress.some((val) => val.name === EVENT_TYPES.ERROR);
    }

    private get smsFlowProgressSuccess(): boolean {
        return this.rw_inviteState.smsFlowProgress.some(
            (val) => val.name === EVENT_TYPES.CLIENT_RECEIVED || val.name === EVENT_TYPES.WAITING_FOR_CLIENT
        );
    }

    private redirectToDashboard(): void {
        if (!this.inviteState.sessionInfo.isOffline) {
            const sessionRoomId = this.inviteState.sessionInfo.sessionRoomId;
            const sessionId = this.inviteState.sessionInfo.sessionId;
            const goToExternalDashboard = get(this._accSettings, 'v3.enabled');
            const showRoomId = get(this._accSettings, 'showRoomIdInUrl');

            this.abortCurrentSmsFlow();
            this.setDisplayedStep(InviteStepsEnum.Dashboard);

            if (goToExternalDashboard) {
                const data = {
                    roomId: showRoomId ? sessionRoomId : undefined,
                    shortId: showRoomId ? undefined : sessionId
                };

                return this._redirectionService.goToExternalDashboard(data);
            }

            const roomIdToRedirect = this._accSettings.inviteMethodSettings.showRoomIdInUrl ? sessionRoomId : sessionId;

            this.dispose();

            const loadMultipartyDashboard = this._sessionService.clientState.mode === MeetingMode.faceMeet;

            this._redirectionService.goToDashboard({room: roomIdToRedirect}, loadMultipartyDashboard, sessionRoomId);
        }
    }

    private abortCurrentSmsFlow(): void {
        if (this._inviteSmsFlow !== null) {
            this._inviteSmsFlow.cancel();
            this._inviteSmsFlow = null;
        }

        runInAction(() => (this.rw_inviteState.smsFlowProgress.length = 0));
    }

    joinOfflineSession(sessionId: string) {
        let sessionCode = sessionId;
        const guidPrefix = this._accSettings.generalSettings.guidPrefix;

        if (sessionId.substr(0, guidPrefix.length) !== guidPrefix) {
            sessionCode = `${guidPrefix}${sessionCode}`;
        }

        return this._sessionService
            .joinBySessionId(sessionCode)
            .then((startSessionResult: OperationResult<SessionConnectResult>) => {
                runInAction(() => {
                    this.rw_inviteState.sessionInfo.sessionId = startSessionResult.data!.sessionShortId;
                    this.rw_inviteState.sessionInfo.sessionRoomId = startSessionResult.data!.sessionRoomId;

                    this._inviteFlowLogger.setRoomId(this.inviteState.sessionInfo.sessionRoomId);
                });
            })
            .then(() => {
                this.abortCurrentSmsFlow();
                this.setDisplayedStep(InviteStepsEnum.Dashboard);

                const paramToRedirect = this._accSettings.inviteMethodSettings.showRoomIdInUrl
                    ? {room: this.inviteState.sessionInfo.sessionRoomId}
                    : {room: this.inviteState.sessionInfo.sessionId};

                this.dispose();

                this._redirectionService.goToDashboardEntry(paramToRedirect);
            });
    }

    joinWtSession(agentLink: string) {
        getRootStore().redirectionService.goToUrl(agentLink);
    }

    private logInviteViewLoaded() {
        const ssoAuthId = this._browserUtilsService.getParamValue('authId');

        this.inviteLogger.dashboardInviteViewLoaded({ssoAuthId});

        if (ssoAuthId) {
            return this._dbSsoService
                .dashboardInviteViewLoaded(ssoAuthId)
                .catch((err) =>
                    trace.error(
                        `Failed to report invite page load with for account ${this.accountId} authId ${ssoAuthId}`,
                        err
                    )
                );
        }

        return Promise.resolve();
    }

    private setInitialWizardState() {
        const finalizeInit = (initalStepName: string) => {
            this._readyPromise.isReady = true;
            this._readyPromise.resolver();
            this.logInviteViewLoaded();
            this.displayWizardStep(initalStepName);
        };

        if (this.inviteState.sessionInfo.isSessionActive && this.smsFlowProgressError && !this.smsFlowProgressSuccess) {
            this.endSession();
            finalizeInit(InviteStepsEnum.InviteMethod);

            return;
        }

        if (!this.inviteState.sessionInfo.isSessionActive) {
            finalizeInit(InviteStepsEnum.InviteMethod);

            return;
        }

        const joinSessionParams = {
            sessionId: this.inviteState.sessionInfo.sessionId,
            roomId: this.inviteState.sessionInfo.sessionRoomId,
            sessionUrl: this.inviteState.sessionInfo.sessionLink,
            userType: UserType.dashboard,
            byShortId: false
        };

        this._sessionService
            .joinSession(joinSessionParams)
            .then(() => {
                if (this._sessionService.clientState.tosRejected && !this._sessionService.clientState.isReviewingTOS) {
                    this.endSession();

                    return;
                } else if (this._sessionService.clientState.tosRejected !== this.inviteState.isTosRejected) {
                    this.onTosResult(!this._sessionService.clientState.tosRejected);
                }

                this._inviteFlowLogger.setRoomId(this.inviteState.sessionInfo.sessionRoomId);
                const stepInLocalState = this.evaluateStepFromClientState();

                finalizeInit(stepInLocalState);
            })
            .catch(() => {
                this.inviteMethodController.resetInviteForm();
                this._stateManager.resetState();
                finalizeInit(InviteStepsEnum.InviteMethod);
            });
    }

    private evaluateStepFromClientState(): string {
        const {clientState} = this._sessionService;

        if (
            (clientState.mode === MeetingMode.video && clientState.videoHandshakeSuccess) ||
            (clientState.mode === MeetingMode.images && clientState.imageUploadHandshakeSuccess) ||
            (clientState.mode === MeetingMode.oneClick && clientState.oneClickHandshakeSuccess) ||
            (clientState.mode === MeetingMode.videoApplication && clientState.videoApplicationHandshakeSuccess) ||
            (clientState.mode === MeetingMode.screen && clientState.screenHandshakeSuccess) ||
            (clientState.mode === MeetingMode.appSharing && clientState.appSharingHandshakeSuccess)
        ) {
            return InviteStepsEnum.Dashboard;
        }

        // Note: I am not clear if this line should be
        // if (clientState.isReviewingTOS && !clientState.tosAccepted && !clientState.tosRejected) {
        // or not. For now I leave it as is, if bug will re-open we'll investigate
        if (clientState.isReviewingTOS) {
            return InviteStepsEnum.TermsApproval;
        }

        if (clientState.isOnPreCameraApprovalScreen) {
            return InviteStepsEnum.PreCameraApproval;
        }

        if (clientState.inCameraApprovalDialog) {
            return InviteStepsEnum.CameraApproval;
        }

        if (!clientState.firstConnected) {
            return this.inviteState.lastVisibleStep;
        }

        if (clientState.isReviewingVideoApplicationCameraAudioDialog) {
            return InviteStepsEnum.VideoApplicationCameraAudioDialog;
        }

        return InviteStepsEnum.InviteMethod;
    }

    private displayWizardStep(stepName: string) {
        switch (stepName) {
            case InviteStepsEnum.SpeakerGuidance:
                this.inviteWizardController.displaySpeakerGuidance();
                break;
            case InviteStepsEnum.WifiGuidance:
                this.inviteWizardController.displayWifiGuidance();
                break;
            case InviteStepsEnum.SendSmsFlow:
                this.inviteWizardController.displaySmsFlow();
                break;
            case InviteStepsEnum.TermsApproval:
                this.inviteWizardController.displayTosGuidance();
                break;
            case InviteStepsEnum.PreCameraApproval:
                // TODO: Pre-Camera approval not implemented yet
                // this.inviteWizardController.displayPreCameraGuidance();
                break;
            case InviteStepsEnum.CameraApproval:
                this.inviteWizardController.displayCameraGuidance();
                break;
            case InviteStepsEnum.Dashboard:
                this.redirectToDashboard();
                break;
            case InviteStepsEnum.AppShareGuidance:
                this.inviteWizardController.displayAppShareTOSGuidance();
                break;
            case InviteStepsEnum.ScreenShareGuidance:
                this.inviteWizardController.displayScreenShareTOSGuidance();
                break;
            case InviteStepsEnum.ScreenShareCaptureGuidance:
                this.inviteWizardController.displayScreenShareCaptureGuidance();
                break;
            case InviteStepsEnum.VideoApplicationGuidance:
                this.inviteWizardController.displayVideoApplicationTOSGuidance();
                break;
            case InviteStepsEnum.VideoApplicationCameraAudioDialog:
                this.inviteWizardController.displayVideoApplicationCameraAudioDialog();
                break;
            case InviteStepsEnum.InviteByCode:
                this.inviteWizardController.displayInviteByCode();
                break;
            case InviteStepsEnum.InviteMethod:
            default:
                this.inviteWizardController.displayInviteForm();
                break;
        }
    }

    @action
    private setInvitationCode(code: string, prefixLength: number) {
        this.rw_inviteState.sessionInfo.inviteByCode = code.substr(prefixLength);
    }

    private get isEmailAddressType() {
        return this.inviteState.inviteMethodInfo.addressType === AddressTypesEnum.EMAIL;
    }

    private startOfflineSession() {
        return this.isEmailAddressType ? this.sendEmail() : this.sendInviteSms();
    }
}
