import {action, computed, observable} from 'mobx';

export interface IAppUser {
    _id: string;
    firstName: string;
    lastName: string;
    role: string;
    lastLogin: number;
}

export class AppState {
    @observable private _appUser?: IAppUser;

    @observable private _offlineRoomCount?: string;

    @observable private _pendingRoomCount?: string;

    @observable accountId: string = '';

    @observable accountName: string = '';

    @observable dashboardLanguage: string = '';

    @computed get agentId(): string {
        return this.appUser ? this.appUser._id : '';
    }

    @computed get agentRole(): string {
        return this.appUser ? this.appUser.role : '';
    }

    @computed
    get agentFullName(): string {
        if (!this._appUser) {
            return '';
        }

        return `${this._appUser.firstName} ${this._appUser.lastName}`;
    }

    @computed
    get appUser(): Readonly<IAppUser> | undefined {
        return this._appUser;
    }

    @action
    setAppUser(user: IAppUser): void {
        this._appUser = user;
    }

    @computed
    get offlineRoomCount(): string | undefined {
        return this._offlineRoomCount;
    }

    @computed
    get pendingRoomCount(): string | undefined {
        return this._pendingRoomCount;
    }

    @action
    setOfflineRoomCount(offlineRoomCount: number): void {
        this._offlineRoomCount = `${offlineRoomCount}`;
    }

    @action
    setPendingRoomCount(pendingRoomCount: number): void {
        this._pendingRoomCount = `${pendingRoomCount}`;
    }
}
