import React from 'react';
import {action, observable} from 'mobx';
import {
    AlignEnum,
    ILeftBarItemData,
    ILeftBarSection,
    LeftBarControllerBase,
    LeftBarSection,
    WizardSectionTypes
} from '../../../layouts/main-layout/left-bar-base/controller';
import {ITranslate} from '../../../services/LocalizationService';
import {GeneralSettings} from '../../../models/AccountSettings';
import SessionHistory from '../../../components/session-history';
import {IAgentInfoController} from '../../../layouts/main-layout/agent-info-menu/controller';
import {AccountSwitch} from '../../../layouts/main-layout/account-switch/AccountSwitch';
import {IAccountSwitchController} from '../../../layouts/main-layout/account-switch/controller';
import {AccountSwitchIcon} from '../../../layouts/main-layout/account-switch/AccountSwitchIcon';
import {GenericHeader} from '../../../components/common-forms/genericHeader';
import {ITsEnvironmentDetect} from '@techsee/techsee-common/lib/helpers/ts-environment-detect';
import {openLinkInNewTab} from '../../helper/general.helper';
import VJHistoryFilter from '../../../components/vj-filter';

interface ILeftBarController {
    readonly isHistorySearchEnabled: boolean;
    readonly isVJHistorySearchEnabled: boolean;
}

export class WizardLeftBarController extends LeftBarControllerBase implements ILeftBarController {
    @observable _leftBarSections: ILeftBarSection[];

    _environmentService: ITsEnvironmentDetect;

    constructor(
        translate: ITranslate,
        environmentService: ITsEnvironmentDetect,
        private readonly _settings: GeneralSettings,
        private _currentUser: any,
        private agentInfoCtrl: IAgentInfoController,
        private accountSwitchCtrl: IAccountSwitchController
    ) {
        super(translate);

        this._environmentService = environmentService;
        this.onLeftBarItemClicked = this.onLeftBarItemClicked.bind(this);
        this._leftBarSections = this.getLeftBarItems();
    }

    get isHistorySearchEnabled(): boolean {
        return this._settings.isHistorySearchEnabled;
    }

    get isVJHistorySearchEnabled(): boolean {
        return this._settings.isVJHistorySearchEnabled;
    }

    get isAppLauncherEnabled(): boolean {
        return this._settings.appLauncher?.enabled;
    }

    get isAccountSwitchEnabled(): boolean {
        return Boolean(this._currentUser.parentSubUserId);
    }

    @action
    getHeaderComponent(itemData: ILeftBarItemData, sectionType: WizardSectionTypes) {
        switch (sectionType) {
            case WizardSectionTypes.HISTORY:
                return (
                    <GenericHeader
                        onClose={() => this.cleanSelectedByItem(itemData)}
                        className={'generic-form-header'}
                        shouldShowCloseIcon={true}
                        labelText={this.translate('INVITE.VIEW.SEARCH_CUSTOMER')}
                    />
                );

            case WizardSectionTypes.SWITCH_USER:
                return (
                    <GenericHeader
                        onClose={() => this.cleanSelectedByItem(itemData)}
                        className={'account-switch-header'}
                        shouldShowCloseIcon={true}
                        labelText={this.translate('INVITE.VIEW.SWITCH_ACCOUNT_TITLE')}
                    />
                );

            case WizardSectionTypes.VJ_HISTORY_FILTER:
                return (
                    <GenericHeader
                        onClose={() => this.cleanSelectedByItem(itemData)}
                        className={'generic-form-header'}
                        shouldShowCloseIcon={true}
                        labelText={this.translate('INVITE.VIEW.VJ_IS_SEARCH')}
                    />
                );

            default:
                return <React.Fragment />;
        }
    }

    @action
    cleanSelectedStates() {
        if (!this._isInsideContent) {
            this.executeItem(
                (item) => item.isSelected,
                (section) =>
                    section.sectionType === WizardSectionTypes.HISTORY ||
                    section.sectionType === WizardSectionTypes.VJ_HISTORY_FILTER ||
                    section.sectionType === WizardSectionTypes.SWITCH_USER,
                (item) => {
                    item.isSelected = false;
                }
            );
        }
    }

    @action
    getLeftBarItems() {
        const sections = [];

        const inviteSection = this.getInviteSection();

        sections.push(inviteSection);

        if (this.isAccountSwitchEnabled) {
            const accountSwitchSection = this.getAccountSwitchSection();

            sections.push(accountSwitchSection);
        }

        if (this.isHistorySearchEnabled) {
            inviteSection.setDisplaySeparator(true);
            const historySection = this.getHistorySection();

            sections.push(historySection);
        }

        if (this.isAppLauncherEnabled) {
            inviteSection.setDisplaySeparator(true);
            const appLauncherSection = this.getAppLauncherSection(this._settings.appLauncher?.customUrl);

            sections.push(appLauncherSection);
        }

        if (this.isVJHistorySearchEnabled) {
            inviteSection.setDisplaySeparator(true);
            const vjHistorySection = this.getVJSection();

            sections.push(vjHistorySection);
        }

        sections.push(this.getHelpSection());

        return sections;
    }

    getInviteSection() {
        return new LeftBarSection(
            AlignEnum.TOP,
            false,
            [
                {
                    uid: 'invite',
                    icon: () => 'invite',
                    isActive: true,
                    isSelected: false,
                    onItemClicked: function () {},
                    isDisabled: false,
                    isHovered: false,
                    tooltip: () => this.translate('REACT.LEFTBAR.TOOLTIP.INVITE')
                }
            ],
            WizardSectionTypes.INVITE
        );
    }

    getHistorySection() {
        return new LeftBarSection(
            AlignEnum.TOP,
            false,
            [
                {
                    uid: 'history',
                    icon: () => 'history',
                    isActive: false,
                    isSelected: false,
                    onItemClicked: function () {},
                    isDisabled: false,
                    isHovered: false,
                    content: () => <SessionHistory />,
                    tooltip: () => this.translate('REACT.LEFTBAR.TOOLTIP.HISTORY')
                }
            ],
            WizardSectionTypes.HISTORY
        );
    }

    getAppLauncherSection(url: string) {
        return new LeftBarSection(
            AlignEnum.TOP,
            false,
            [
                {
                    uid: 'appLauncher',
                    icon: () => 'quick-launch',
                    isActive: false,
                    isSelected: false,
                    onItemClicked: function () {
                        openLinkInNewTab(url);
                    },
                    isDisabled: false,
                    isHovered: false,
                    tooltip: () => this.translate('REACT.LEFTBAR.TOOLTIP.APP_LAUNCHER')
                }
            ],
            WizardSectionTypes.APP_LAUNCHER
        );
    }

    getVJSection() {
        return new LeftBarSection(
            AlignEnum.TOP,
            false,
            [
                {
                    uid: 'vjHistory',
                    icon: () => 'Int-summary',
                    isActive: false,
                    isSelected: false,
                    onItemClicked: function () {},
                    isDisabled: false,
                    isHovered: false,
                    content: () => <VJHistoryFilter />,
                    tooltip: () => this.translate('REACT.LEFTBAR.TOOLTIP.VJ_HISTORY')
                }
            ],
            WizardSectionTypes.VJ_HISTORY_FILTER
        );
    }

    getAccountSwitchSection() {
        return new LeftBarSection(
            AlignEnum.BOTTOM,
            false,
            [
                {
                    uid: 'account-switch',
                    icon: () => <AccountSwitchIcon text={this.accountSwitchCtrl.shortCompanyName} />,
                    onItemClicked: function () {},
                    isActive: false,
                    isSelected: false,
                    isDisabled: false,
                    isHovered: false,
                    tooltip: () => this.translate('INVITE.VIEW.SWITCH_ACCOUNT_TOOLTIP'),
                    content: () => <AccountSwitch ctrl={this.accountSwitchCtrl} />
                }
            ],
            WizardSectionTypes.SWITCH_USER
        );
    }

    getHelpSection() {
        const profileButton = {
            uid: 'profile',
            icon: () => 'profile',
            isDisabled: false,
            isActive: false,
            isSelected: false,
            isHovered: false,
            onItemClicked: function () {},
            tooltip: () => this.translate('REACT.DASHBOARD.VIEW.TOOLTIP.SETTINGS')
        };

        const buttonGroup = [];

        buttonGroup.push(profileButton);

        return new LeftBarSection(AlignEnum.BOTTOM, false, buttonGroup, WizardSectionTypes.HELP, this.agentInfoCtrl);
    }

    @action
    onLeftBarItemClicked(item: ILeftBarItemData): void {
        if (item.content) {
            item.isSelected = !item.isSelected;
        } else {
            item.onItemClicked();
        }
    }
}
