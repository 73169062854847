import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import RoomInfo from './room-info/index';
import SessionAbort from './session-abort/index';
import InfoBoxComponent from './info-box/index';
import {IInviteTopBarController} from './controller';
import {getRootStore} from '../../../app.bootstrap';

import './styles.scss';

@inject('inviteTopBarController')
@observer
export default class InviteTopBar extends Component<{inviteTopBarController: IInviteTopBarController}> {
    render() {
        const {inviteTopBarController} = this.props;
        const connectionInfoData = inviteTopBarController.userConnectionInfo;
        const clientTypeTitle = inviteTopBarController.usingApplication
                ? 'REACT.INVITE.VIEW.APPLICATION'
                : 'REACT.INVITE.VIEW.BROWSER',
            clientTypeData = inviteTopBarController.usingApplication
                ? inviteTopBarController.applicationId
                : inviteTopBarController.clientBrowser,
            clientTypeIcon = inviteTopBarController.isDesktopSharing ? 'desktop-sharing' : 'mobile';
        const {localizationService} = getRootStore();

        return (
            <React.Fragment>
                <div className={'section-container'}>
                    <div className='section section-box' dir={localizationService.getTextDirection()}>
                        <InfoBoxComponent
                            displayHeaders={true}
                            upperTitle={'REACT.INVITE.VIEW.REF_ID'}
                            upperData={inviteTopBarController.inviteMethodInfo.customerRef}
                            bottomTitle={`REACT.INVITE.VIEW.${inviteTopBarController.inviteMethodInfo.addressType}`}
                            bottomData={inviteTopBarController.inviteMethodInfo.address}
                            clientLanguage={
                                inviteTopBarController.multiLanguage &&
                                inviteTopBarController.inviteMethodInfo.clientLanguage
                            }
                            iconName={'user'}></InfoBoxComponent>
                    </div>
                    <div className='section section-box' dir={localizationService.getTextDirection()}>
                        <RoomInfo
                            upperTitle={'REACT.INVITE.VIEW.ID'}
                            upperData={inviteTopBarController.sessionInfo.sessionId}
                            bottomIcon={'copy'}
                            bottomData={inviteTopBarController.sessionInfo.sessionLink}
                            iconName={inviteTopBarController.inviteMethodInfo.addressType.toLowerCase()}></RoomInfo>
                    </div>
                    <div className='section section-box network-info' dir={localizationService.getTextDirection()}>
                        <InfoBoxComponent
                            displayHeaders={true}
                            upperTitle={connectionInfoData.topLabel}
                            upperData={connectionInfoData.topLine}
                            bottomTitle={''}
                            bottomData={connectionInfoData.bottomLine}
                            iconName={connectionInfoData.icon}
                            translate={inviteTopBarController.translate}></InfoBoxComponent>
                    </div>
                    <div className='section section-box' dir={localizationService.getTextDirection()}>
                        <InfoBoxComponent
                            displayHeaders={true}
                            upperTitle={'REACT.INVITE.VIEW.TYPE'}
                            upperData={inviteTopBarController.clientDevice}
                            bottomTitle={clientTypeTitle}
                            bottomData={clientTypeData}
                            iconName={clientTypeIcon}
                            translate={inviteTopBarController.translate}></InfoBoxComponent>
                    </div>
                </div>
                {inviteTopBarController.sessionInfo.isSessionActive && (
                    <div className='section session-end splitter'>
                        <SessionAbort />
                    </div>
                )}
            </React.Fragment>
        );
    }
}
