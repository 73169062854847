/* eslint-disable indent */
import React from 'react';
import {observer} from 'mobx-react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {ITranslate} from '../../services/LocalizationService';
import {IShareScreenPopupController} from './controller';
import {PrimaryButton} from '@techsee/techsee-ui-common/lib/forms/buttons/primary';
import {CloserButton} from '@techsee/techsee-ui-common/lib/forms/buttons/closer';

import './styles.scss';

export interface IShareScreenPopup {
    ctrl: IShareScreenPopupController;
    translate: ITranslate;
    agentIsSharing: boolean;
    startSharing?: () => void;
    stopSharing?: () => void;
    closePopover: () => void;
}

@observer
export class ShareScreenPopup extends TechseeBaseComponent<IShareScreenPopup> {
    renderInternal() {
        const {ctrl, translate, closePopover} = this.props;
        const {isSharingScreen} = ctrl;

        return (
            <div className='use-common-styles'>
                <CloserButton onClick={closePopover} className={'close-share'} />
                <div className={'header'}>
                    {isSharingScreen
                        ? translate('REACT.MULTIPARTY_DASHBOARD.SHARE_SCREEN_POPUP.STOP_SHARING.TITLE')
                        : translate('REACT.MULTIPARTY_DASHBOARD.SHARE_SCREEN_POPUP.START_SHARING.TITLE')}
                </div>
                <div className={'description'}>
                    {isSharingScreen
                        ? translate('REACT.MULTIPARTY_DASHBOARD.SHARE_SCREEN_POPUP.STOP_SHARING.DESCRIPTION')
                        : translate('REACT.MULTIPARTY_DASHBOARD.SHARE_SCREEN_POPUP.START_SHARING.DESCRIPTION')}
                </div>
                <div className={isSharingScreen ? 'screen-share-button stop' : 'screen-share-button'}>
                    <PrimaryButton
                        onClick={isSharingScreen ? ctrl.stopShareScreen : ctrl.shareScreen}
                        disabled={ctrl.isSharingScreenDisabled}>
                        {isSharingScreen
                            ? translate('REACT.MULTIPARTY_DASHBOARD.SHARE_SCREEN_POPUP.STOP_SHARING.BUTTON')
                            : translate('REACT.MULTIPARTY_DASHBOARD.SHARE_SCREEN_POPUP.START_SHARING.BUTTON')}
                    </PrimaryButton>
                </div>
            </div>
        );
    }
}
