import {getAppTracer} from '../../../app.tracer';
import {PromiseUtilsService} from '@techsee/techsee-client-services/lib/services/PromiseUtilsService';

const trace = getAppTracer('reactivateAccountModule');

function _handleError({
    tsStateHelper,
    authId,
    err
}: {tsStateHelper?: any; authId?: string; err?: Error | unknown} = {}) {
    const traceMessage = `Failed to redirect from sso-action (Auth=${authId}): ${err}`;
    const tracePromise = () => trace.error(traceMessage, err);
    const tracePromiseTimeout = 3000;

    PromiseUtilsService.startPromiseWithTimeout(tracePromise, tracePromiseTimeout)
        .catch((err) => console.error(traceMessage + ' >> trace failed: ' + err))
        .then(() => {
            tsStateHelper.safeGo('embeddedDashboardGeneralError');
        });
}

class ReactivateAccountController {
    static $inject = ['tsStateHelper', 'tsUrlUtils', 'db'];

    constructor(tsStateHelper: any, tsUrlUtils: any, db: any) {
        this.init(tsStateHelper, tsUrlUtils, db);
    }

    async init(tsStateHelper: any, tsUrlUtils: any, db: any) {
        const authId = tsUrlUtils.getParamValue('authId'),
            initialState = tsUrlUtils.getParamValue('is'),
            emb = tsUrlUtils.getParamValue('emb'),
            mini = tsUrlUtils.getParamValue('mini'),
            orgId = tsUrlUtils.getParamValue('orgId');

        try {
            const traceMessage = `reactivateAccount has loaded, state: ${initialState}, (Auth=${authId})`;

            await PromiseUtilsService.startPromiseWithTimeout(() => trace.info(traceMessage), 3000);
            await db.Account.sendReactivationDetails({data: {orgId}});

            tsStateHelper.safeGo('invite', {authId, emb, mini});
        } catch (err) {
            _handleError({tsStateHelper, authId, err});

            throw err;
        }
    }
}

declare const angular: any;

declare const ROUTE_BASE_PATH: string;

export default angular
    .module('states.reactivateAccount', [])
    .config(config)
    .controller('ReactivateAccountController', ReactivateAccountController);

const controller = {
    controller: 'ReactivateAccountController'
};

config.$inject = ['$stateProvider'];

function config($stateProvider: any) {
    $stateProvider.state('reactivateAccount', {
        url:
            ROUTE_BASE_PATH +
            'reactivate-account?authId&token&r&p&c&is&roomId&g&roomCode&audio&observe&emb&mini&prg&orgId&startDate&endDate',
        views: {
            'desktop-view': controller,
            'mobile-view': controller
        }
    });
}
