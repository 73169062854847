import LoginController from './components/login-form/controller';
import {LoginConfirmationController} from './components/login-confirmation/controller';
import {createFieldModel, getRootStore} from '../../app.bootstrap';

interface Stores {
    [name: string]: object;
}

export class LoginStores {
    stores?: Stores;

    //We use this type of injection, because ng-annotate-loader not runs on TypeScript files.
    static $inject = ['$scope'];

    constructor($scope: any) {
        this.initStores().then((stores) => {
            $scope.$apply(() => (this.stores = stores));
        });
    }

    private initStores(): PromiseLike<Stores> {
        return new Promise((resolve) => {
            const initPromises: PromiseLike<any | void>[] = [];
            const rootStore = getRootStore();
            const {localizationService, applicationService, redirectionService, browserUtilsService, dbUserService} =
                rootStore;

            initPromises.push(localizationService.init());

            const stores: Stores = {};

            Promise.all(initPromises).then(() => {
                const loginConfirmationController = new LoginConfirmationController(localizationService.translate);

                const loginController = new LoginController(
                    localizationService.translate,
                    applicationService,
                    redirectionService,
                    createFieldModel,
                    loginConfirmationController,
                    browserUtilsService,
                    dbUserService
                );

                defineStore('loginController', loginController);
                defineStore('loginConfirmationController', loginConfirmationController);
                defineStore('localizationService', localizationService);

                resolve(stores);
            });

            /*
             Because of technical issue with Angular digest loop,
             We define stores as non enumerable properties. This helps to avoid infinite digest of angular.
            */
            function defineStore(storeName: string, value: any) {
                Object.defineProperty(stores, storeName, {
                    value: value,
                    enumerable: false
                });
            }
        });
    }
}
