import {inject, IReactComponent} from 'mobx-react';
import * as view from './view';

const mapStoresToProps = (stores: any) => ({
    ctrl: stores.loginController,
    translate: stores.localizationService.translate.bind(stores.localizationService)
});

const LoginFormView = inject(mapStoresToProps)(view.LoginFormView) as IReactComponent;

export {LoginFormView};
export default LoginFormView;
