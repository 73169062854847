import get from 'lodash/get';
// @ts-ignore
import {ArMeasurementActionTypesEnum} from '@techsee/techsee-common/lib/constants/room.constants';
import {EventEmitter} from 'events';

export interface IArMeasurementService {
    isSupported(): boolean;
    isActive(): boolean;
    getMeasuredDistances(): [];
    getMarkedPointsCount(): number;
    setActiveState(): void;
    addMeasurementPoint(): void;
    undoLastPoint(): void;
    copyDistances(): void;
    canAddPoint(): boolean;
    canUndoPoint(): boolean;
    canCopyDistance(): boolean;
}

export class ArMeasurementService extends EventEmitter implements IArMeasurementService {
    private _chatApi: any;

    private _sharingService: any;

    private _eventLog: any;

    private _accountSettings: any;

    constructor(chatApi: any, sharingService: any, eventLog: any, accountSettings: any) {
        super();

        this._chatApi = chatApi;
        this._sharingService = sharingService;
        this._eventLog = eventLog;
        this._accountSettings = accountSettings;
    }

    setActiveState(): void {
        const measurementState = this.getDashboardMeasurementState();

        this._chatApi.setStatus('measurement', {
            isEnabled: measurementState.isEnabled,
            isActive: !measurementState.isActive
        });
    }

    addMeasurementPoint(): void {
        this._chatApi.requestAction('addMeasurementPoint');
        this._logAction(ArMeasurementActionTypesEnum.ADD_POINT);
    }

    canAddPoint(): boolean {
        return this._accountSettings?.enableMarkPoints && this.isActive();
    }

    canUndoPoint(): boolean {
        return this._accountSettings?.enableMarkPoints && this.isActive() && this.getMarkedPointsCount();
    }

    canCopyDistance(): boolean {
        return this.getMeasuredDistances().length > 0;
    }

    getMarkedPointsCount(): number {
        const measurementState = this.getClientMeasurementState();

        return get(measurementState, 'markedPoints.length');
    }

    isActive(): boolean {
        const measurementState = this.getClientMeasurementState();

        return measurementState && measurementState.isActive;
    }

    isSupported(): boolean {
        const measurementState = this.getClientMeasurementState();

        return measurementState && measurementState.isSupported;
    }

    getMeasuredDistances(): [] {
        const measurementState = this.getClientMeasurementState();

        return get(measurementState, 'measuredDistances') || [];
    }

    undoLastPoint(): void {
        this._chatApi.requestAction('undoLastPoint');
        this._logAction(ArMeasurementActionTypesEnum.UNDO_POINT);
    }

    private getClientMeasurementState(): any {
        return get(this._chatApi, 'client.measurement');
    }

    private getDashboardMeasurementState(): any {
        return get(this._chatApi, 'dashboard.measurement');
    }

    copyDistances(): void {
        if (!this.canCopyDistance) {
            return;
        }

        // Format measured distances
        const measuredDistances = this.getMeasuredDistances();
        const dataToCopy = measuredDistances.join(this._accountSettings.unit + ', ') + this._accountSettings.unit;
        const distancesData = {type: 'text', data: dataToCopy};

        this._sharingService.copyTextToClipboard(distancesData);
        this._logAction(ArMeasurementActionTypesEnum.COPY_DISTANCE);
        this.emit('distancesCopied');
    }

    private _logAction(actionType: ArMeasurementActionTypesEnum) {
        let action;

        switch (actionType) {
            case ArMeasurementActionTypesEnum.ACTIVATE_MEASURE:
                action = 'Measure activated by Agent';
                break;

            case ArMeasurementActionTypesEnum.END_MEASURE:
                action = 'Measure ended by Agent';
                break;

            case ArMeasurementActionTypesEnum.ADD_POINT:
                action = 'A point was marked (added) by Agent';
                break;

            case ArMeasurementActionTypesEnum.MEASURE_DETECTED:
                action = 'Distance measuring was detected - executed by Agent';
                break;

            case ArMeasurementActionTypesEnum.COPY_DISTANCE:
                action = 'Distances copied to clipboard by Agent';
                break;

            case ArMeasurementActionTypesEnum.UNDO_POINT:
                action = 'Last point mark was undone by Agent';
                break;

            default:
                break;
        }

        if (action) {
            this._eventLog.arMeasurementActionExecuted({action});
        }
    }
}
