import {inject, IReactComponent} from 'mobx-react';
import {LookupNumber} from './component';

import './styles.scss';

const mapStoresToProps = (stores: any) => ({
    ctrl: stores.lookupNumberController
});

export default inject(mapStoresToProps)(LookupNumber) as IReactComponent;
