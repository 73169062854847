/* eslint-disable indent */
// eslint-disable-next-line max-classes-per-file
import React from 'react';
import {observer} from 'mobx-react';
import {FormInput} from '@techsee/techsee-ui-common/lib/forms/input';
import {ProgressLine} from '@techsee/techsee-ui-common/lib/progress/line';
import {PrimaryButton} from '@techsee/techsee-ui-common/lib/forms/buttons/primary';
import {ButtonTypeAttributes} from '@techsee/techsee-ui-common/lib/forms/buttons/generic';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {PhoneNumberField} from '@techsee/techsee-ui-common/lib/forms/phone-number-field';
import {IInviteMethodController} from './controller';
import {ITranslate} from '../../../../../services/LocalizationService';
import {FormFancyDropDown} from '@techsee/techsee-ui-common/lib/forms/form-fancy-drop-down';
import {ReferralRegionItem, ClientLanguageItem, VisualJourneyItem} from '../../../../../models/AccountSettings';
import {SimpleLabel} from '@techsee/techsee-ui-common/lib/forms/labels/simple';
import {SwitchButton} from '@techsee/techsee-ui-common/lib/forms/buttons/switch';
import {AddressTypesEnum} from '@techsee/techsee-common/lib/constants/room.constants';
import {SelectVideoPublisher} from '../../../../../components/select-video-publisher/component';
import WhatsAppIcon from '../../../../../../../img/whatsapp-icon.png';
import './styles.scss';

export interface IInviteMethodFormProps {
    ctrl: IInviteMethodController;
    translate: ITranslate;
    addressType: AddressTypesEnum;
    onSubmit?: () => void;
    isChangeNumber?: boolean;
}

interface IInviteMethodForm {
    ctrl: IInviteMethodController;
    translate: ITranslate;

    renderMutualFields(fieldsToAdd: JSX.Element): JSX.Element;
}

@observer
class EmailInviteMethodFormView extends TechseeBaseComponent<IInviteMethodForm> {
    renderInternal(): JSX.Element {
        const {ctrl, renderMutualFields} = this.props;
        const {inviteForm} = ctrl;
        const emailField = (
            <React.Fragment>
                {ctrl.isInviteByEmail && (
                    <FormInput uid={'address'} model={inviteForm.fields[inviteForm.fieldNames.address]} />
                )}
            </React.Fragment>
        );

        return <React.Fragment>{renderMutualFields(emailField)}</React.Fragment>;
    }
}

@observer
class SmsInviteMethodFormView extends TechseeBaseComponent<IInviteMethodForm> {
    renderInternal(): JSX.Element {
        const {ctrl, translate, renderMutualFields} = this.props;
        const {inviteForm} = ctrl;
        const showProgressLine = ctrl.validateNumberInProgress || ctrl.creatingSessionInProgress;
        const progressLabel = ctrl.validateNumberInProgress
            ? translate('REACT.INVITE.VIEW.PHONE_NUM.VALIDATING_NUMBER')
            : translate('REACT.INVITE.VIEW.CONNECT_SESSION');

        const phoneNumberField = (
            <React.Fragment>
                {' '}
                {ctrl.isPhoneNumberEnabled && (
                    <PhoneNumberField
                        uid={'address'}
                        model={inviteForm.fields[inviteForm.fieldNames.address]}
                        phoneNumberModel={inviteForm.fields[inviteForm.fieldNames.phoneNumber]}
                        countryCodeModel={inviteForm.fields[inviteForm.fieldNames.countryCode] || null}
                        countryCodesList={ctrl.countriesInfo}
                        selectedCountries={ctrl.phoneCountryCodes}
                        defaultCountry={ctrl.defaultCountryCode}
                        countryList={ctrl.countryList}
                        loadMoreItemsText={translate('REACT.COMMON.COUNTRIES.LOAD_MORE_COUNTRY_CODES')}
                        noResultsText={translate('REACT.COMMON.COUNTRIES.NO_RESULTS')}
                        icon={ctrl.allowSendByWhatsApp ? WhatsAppIcon : undefined}
                    />
                )}
                {showProgressLine && <ProgressLine label={progressLabel} isInline={true} />}{' '}
            </React.Fragment>
        );
        const whatAppNotice = ctrl.allowSendByWhatsApp && (
            <>
                <div className={'whatsapp-notice'}>
                    <SimpleLabel>{translate('REACT.INVITE.VIEW.WHATSAPP_NOTICE')}</SimpleLabel>
                </div>
            </>
        );

        return (
            <React.Fragment>
                {whatAppNotice}
                {renderMutualFields(phoneNumberField)}
            </React.Fragment>
        );
    }
}

@observer
export class InviteMethodFormView extends TechseeBaseComponent<IInviteMethodFormProps> {
    constructor(props: IInviteMethodFormProps) {
        super(props);

        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.renderMutualFields = this.renderMutualFields.bind(this);
    }

    private onFormSubmit(event: React.FormEvent) {
        event.preventDefault();

        if (event.target instanceof HTMLElement) {
            event.target.tabIndex = -1;
            event.target.focus();
        }

        if (this.props.onSubmit) {
            this.props.onSubmit();
        }
    }

    renderTabContent(addressType: AddressTypesEnum) {
        switch (addressType) {
            case AddressTypesEnum.EMAIL:
                return (
                    <EmailInviteMethodFormView
                        ctrl={this.props.ctrl}
                        translate={this.props.translate}
                        renderMutualFields={this.renderMutualFields}
                    />
                );
            default:
                return (
                    <SmsInviteMethodFormView
                        ctrl={this.props.ctrl}
                        translate={this.props.translate}
                        renderMutualFields={this.renderMutualFields}
                    />
                );
        }
    }

    renderInternal() {
        const {addressType} = this.props;

        return (
            <form className={'customer-sms-info'} onSubmit={this.onFormSubmit}>
                {this.renderTabContent(addressType)}
                {this.props.onSubmit && <PrimaryButton type={ButtonTypeAttributes.Submit} className={'fake-submit'} />}
            </form>
        );
    }

    // eslint-disable-next-line eslint-comments/disable-enable-pair
    /*eslint-disable complexity */
    renderMutualFields(fieldsToAdd: JSX.Element) {
        const {ctrl, translate} = this.props;
        const {inviteForm, _selectVideoPublisherController, showSelectVideoPublisher} = ctrl;

        return (
            <React.Fragment>
                {showSelectVideoPublisher && _selectVideoPublisherController && (
                    <SelectVideoPublisher ctrl={_selectVideoPublisherController} />
                )}
                {ctrl.isCustomerRefEnabled && !this.props.isChangeNumber && (
                    <FormInput uid={'customerRef'} model={inviteForm.fields[inviteForm.fieldNames.customerRef]} />
                )}
                {fieldsToAdd}
                {ctrl.isCobrowsingSelectedMode && (
                    <FormInput
                        uid={'coBrowsingInitialUrl'}
                        model={inviteForm.fields[inviteForm.fieldNames.coBrowsingInitialUrl]}
                    />
                )}
                {ctrl.isLanguageSelectionEnabled && !ctrl.isVisualJourneySelected && !this.props.isChangeNumber && (
                    <FormFancyDropDown
                        className='client-language'
                        uid='client-language'
                        options={ctrl.clientLanguages}
                        optionComponent={({data}: {data: ClientLanguageItem}) => (
                            <div className='item'>{data.name}</div>
                        )}
                        model={inviteForm.fields[inviteForm.fieldNames.selectedLanguage]}
                        optionValueKey='name'
                    />
                )}
                {ctrl.isVisualJourneySelected && (
                    <FormFancyDropDown
                        className='visual-journey'
                        uid='visual-journey'
                        options={ctrl.visualJourniesList}
                        optionComponent={({data}: {data: VisualJourneyItem}) => <div className='item'>{data.name}</div>}
                        model={inviteForm.fields[inviteForm.fieldNames.visualJourney]}
                        optionValueKey='name'
                    />
                )}
                {ctrl.isVideoApplicationSelected && (
                    <FormFancyDropDown
                        className='referral-configuration'
                        uid='referral-configuration'
                        options={ctrl.referralRegionList}
                        optionComponent={({data}: {data: ReferralRegionItem}) => (
                            <div className='item'>{data.name}</div>
                        )}
                        model={inviteForm.fields[inviteForm.fieldNames.referralRegion]}
                        optionValueKey='name'
                    />
                )}
                {ctrl.displayStartWithAudio && ctrl.isAudioSupportedMode && !this.props.isChangeNumber && (
                    <div className={'invite-toggle-button'}>
                        <SimpleLabel>{translate('REACT.INVITE.VIEW.START_AUDIO_DURING_SESSION')}</SimpleLabel>
                        <SwitchButton value={ctrl.enableAudio} onChange={ctrl.audioForRoomToggle} />
                    </div>
                )}
                {ctrl.displayStartWithVideo && ctrl.isVideoSupportedMode && !this.props.isChangeNumber && (
                    <div className={'invite-toggle-button'}>
                        <SimpleLabel>{translate('REACT.INVITE.VIEW.START_VIDEO_DURING_SESSION')}</SimpleLabel>
                        <SwitchButton value={!!ctrl.enableVideo} onChange={ctrl.videoForRoomToggle} />
                    </div>
                )}
                {ctrl.measurementAccountSettings &&
                    ctrl.measurementAccountSettings.enabled &&
                    ctrl.isMeasureSupportedMode && (
                        <div className={'invite-toggle-button'}>
                            <SimpleLabel>{translate('REACT.INVITE.VIEW.INITIATE_WITH_MEASUREMENT')}</SimpleLabel>
                            <SwitchButton value={ctrl.enableMeasurement} onChange={ctrl.measureForRoomToggle} />
                        </div>
                    )}
            </React.Fragment>
        );
    }
}

export default InviteMethodFormView;
