import React from 'react';
import classNames from 'classnames';

interface IGuidanceWrapperProps {
    children: React.ReactNode | React.ReactNode[];
    className?: string;
    browser?: string;
}

export const BrowserContext = React.createContext<Pick<IGuidanceWrapperProps, 'browser'>>({});

const GuidanceWrapper: React.FunctionComponent<IGuidanceWrapperProps> = (props: IGuidanceWrapperProps) => (
    <BrowserContext.Provider value={{browser: props.browser}}>
        <div className={classNames('agent-guidance', props.className, props.browser)}>
            <div className='agent-guidance-wrapper'>{props.children}</div>
        </div>
    </BrowserContext.Provider>
);

export default GuidanceWrapper;
