import get from 'lodash/get';
import includes from 'lodash/includes';
import {
    IClientAsDashboardSMSParams,
    IDbRooms,
    IDbSms,
    ISendSmsParams,
    IVisualJourneyParams
} from './AngularServices/AngularServices';
import {OperationResult} from '../models/OperationResult';
import {ISmsSettings} from '../models/AccountSettings';
import {
    EVENT_TYPES,
    ICallback,
    IPromptCallback,
    ISmsData,
    IWhatsAppTemplateData
} from '../../services/ts-sms/ts-sms.settings';
import {SendSmsServiceFlow} from '../../services/ts-sms/SendSmsServiceFlow';
import {ISmsFlowLogger} from '../states/invite-new/invite.flow.logger';
import {Event} from '../../services/ts-sms/SmsServiceFlowEvent';

export interface IResponseSms {
    success: boolean;
    clientConnected?: boolean;
}

export interface ISmsService {
    sendSms(
        smsData: ISmsData,
        progressCb: ICallback,
        allowPrompt?: boolean,
        promptCb?: IPromptCallback
    ): Promise<IResponseSms>;

    sendSimpleSms(smsData: ISendSmsParams): void;

    abortSendSms(): void;

    sendWhatsAppTemplateMessage(whatsAppData: IWhatsAppTemplateData): Promise<any>;

    validatePhoneNumber(phoneNumber: string, countryCode: string): PromiseLike<OperationResult>;

    sendVisualJourney(data: IVisualJourneyParams): Promise<any>;

    sendClientAsDashboard(data: IClientAsDashboardSMSParams): Promise<any>;

    sendObserverInvitation(data: IClientAsDashboardSMSParams): Promise<any>;

    sendWarmTransferClientLink(data: {roomId: string}): Promise<any>;
}

export enum ValidationResultCodes {
    SUCCESS = 'SUCCESS',
    LANDLINE = 'LANDLINE',
    UNREACHABLE = 'UNREACHABLE',
    INVALIDNUMBER = 'INVALIDNUMBER',
    UNEXPECTED = 'UNEXPECTED',
    TIMEOUT = 'TIMEOUT'
}

export class SmsService implements ISmsService {
    private _dbRooms: IDbRooms;

    private _dbSmsService: IDbSms;

    private _smsSettings: ISmsSettings;

    private _smsEventLogs: ISmsFlowLogger;

    private _sendSmsFlow?: SendSmsServiceFlow | null;

    private allowSendByWhatsApp: boolean;

    ValidationResultCodes = ValidationResultCodes;

    constructor(
        dbRooms: IDbRooms,
        dbSms: IDbSms,
        smsSettings: ISmsSettings,
        smsEventLogs: ISmsFlowLogger,
        allowSendByWhatsApp: boolean = false
    ) {
        this._dbRooms = dbRooms;
        this._dbSmsService = dbSms;
        this._smsSettings = smsSettings;
        this._smsEventLogs = smsEventLogs;
        this.allowSendByWhatsApp = allowSendByWhatsApp;
    }

    sendSimpleSms(smsData: ISendSmsParams) {
        const smsGatewayConfig = get(this._smsSettings, 'smsGatewayConfig');

        smsData.gateway = smsData.gateway || get(smsGatewayConfig, 'primary');

        return this._dbSmsService.sendPrimarySms(smsData);
    }

    sendWhatsAppTemplateMessage(whatsAppData: IWhatsAppTemplateData): Promise<any> {
        // @ts-ignore
        return this._sendSmsFlow.sendWhatsAppTemplateMessage(whatsAppData);
    }

    sendSms(
        smsData: ISmsData,
        progressCb: ICallback,
        allowPrompt: boolean,
        promptCb: IPromptCallback
    ): Promise<IResponseSms> {
        if (this._sendSmsFlow !== null) {
            this.abortSendSms();
        }

        this._sendSmsFlow = new SendSmsServiceFlow(
            this._dbRooms,
            this._dbSmsService,
            this._smsSettings,
            this._smsEventLogs
        );

        return this._sendSmsFlow
            .sendSMS(smsData, progressCb, allowPrompt, promptCb, this.allowSendByWhatsApp)
            .catch((error) => {
                throw {...error, success: false};
            })
            .then((result: any) => {
                this.abortSendSms();

                return result;
            });
    }

    sendVisualJourney(data: IVisualJourneyParams) {
        return this._dbSmsService.sendVisualJourneySMS(data);
    }

    sendWarmTransferClientLink(data: {roomId: string}) {
        return this._dbSmsService.sendWarmTransferClientLinkSMS(data);
    }

    sendClientAsDashboard(data: IClientAsDashboardSMSParams) {
        return this._dbSmsService.sendClientAsDashboardSMS(data);
    }

    sendObserverInvitation(data: IClientAsDashboardSMSParams) {
        return this._dbSmsService.sendObserverInvitationSMS(data);
    }

    abortSendSms(): void {
        this._sendSmsFlow && this._sendSmsFlow.dispose();
        this._sendSmsFlow = null;
    }

    validatePhoneNumber(phoneNumber: string, countryCode: string): PromiseLike<OperationResult> {
        this._smsEventLogs.phoneNumberValidationStarted(phoneNumber);

        return new Promise((resolve) => {
            const isTimeoutEnabled = true;
            const validationTimeout = 15 * 1000;
            let isTimedOut = false;

            let timeOutPtr = isTimeoutEnabled
                ? setTimeout(() => {
                      isTimedOut = true;
                      // TODO adi add unexpected error log
                      //this._smsEventLogs.phoneNumberValidatedUnexpectedError({err: 'validating number Timeout', phoneNumber: phoneNumber});
                      resolve(OperationResult.getSuccessResult());
                  }, validationTimeout)
                : null;

            const resetTimeout = () => {
                timeOutPtr && clearInterval(timeOutPtr);
                timeOutPtr = null;
            };

            const validatePhoneParams = {
                data: {phoneNumber, countryCode, gateway: this._smsSettings.smsGatewayConfig.primary}
            };

            this._dbSmsService
                .validatePhoneNumber(validatePhoneParams)
                .then((response) => {
                    resetTimeout();

                    if (!isTimedOut) {
                        const result = response.data;
                        const failedResults = [
                            this.ValidationResultCodes.INVALIDNUMBER,
                            this.ValidationResultCodes.LANDLINE,
                            this.ValidationResultCodes.UNREACHABLE
                        ];

                        if (result && result.code && includes(failedResults, result.code)) {
                            this._smsEventLogs.phoneNumberValidationFailed({result});

                            resolve(OperationResult.getFailResult(result.code, result));

                            return;
                        }
                        this._smsEventLogs.phoneNumberValidatedSuccessfully(phoneNumber);

                        resolve(OperationResult.getSuccessResult());
                    }
                })
                .catch((err) => {
                    this._smsEventLogs.phoneNumberValidatedUnexpectedError({err, phoneNumber});

                    resetTimeout();

                    if (!isTimedOut) {
                        resolve(OperationResult.getSuccessResult());
                    }
                });
        });
    }
}
