'use strict';

import {AuthService} from './auth/auth.service';
import {DbService} from './db/db.service';
import {TsUrlUtilsService} from './ts-url-utils/ts-url-utils.service';
import {TsVideoUtilsService} from './ts-video-utils/ts-video-utils.service';
import {TsCanvasAnnotateService} from './ts-canvas-annotate/ts-canvas-annotate.service';
import {TsVideoControlService} from './ts-video-control/ts-video-control.service';
import {ChatApiNgService} from './ts-chat-api/ChatApi-ng.service';
import {TsLocaleHelperService} from './ts-locale-helper/ts-locale-helper.service';
import {TokenInterceptor} from './token-interceptor/token-interceptor.service';
import {EventLogService} from './event-log/event-log.service';
import {AgentQOSService} from './qos/agent-qos.service';
import {TsBusyService} from './ts-busy/ts-busy.service';
import {TsTimedOperationService} from './ts-timed-operation/ts-timed-operation.service';
import {TsVisibilityChange} from './ts-visibility-change/ts-visibility-change.service';
import {TsResponsiveUtilsService} from './ts-responsive-utils/ts-responsive-utils.service';
import {TsHelpBrowserService} from './ts-help-browser/ts-help-browser.service';
import {TsWizardHelperService} from './ts-wizard-helper/ts-wizard-helper.service';
import {TsExceptionHandler} from './ts-exception-handler/ts-exception-handler.service';
import {TsScanService} from './ts-scan/ts-scan.service';
import {TsSessionData} from './ts-session-data/ts-session-data.service';
import {CustomTranslateLoader} from './custom-translate-loader/custom-translate-loader.service';
import {TsTranslationHelper} from './ts-translation-helper/ts-translation-helper.service';
import {TsInterfaceHelperService} from './ts-interface-helper/ts-interface-helper.service';
import {TsStateHelperService} from './ts-state-helper/ts-state-helper.service';
import {TsSharingService} from './ts-sharing-service/ts-sharing-service.service';
import {TsSnapshotReminderService} from './ts-snapshot-reminder/ts-snapshot-reminder.service';
import {TsScanArea} from './ts-scan-area/ts-scan-area.service';
import {TsSmsService} from './ts-sms/ts-sms.service';
import {TsCobrowsingService} from './ts-cobrowsing/ts-cobrowsing-service.service';
import {UiFlavorNgService} from './ts-ui-flavor/ts-ui-flavor-ng.service';
import {AuthExpiryNgService} from './ts-auth-expiry/ts-auth-expiry-ng.service';
import {QuickLauncherService} from './ts-quick-launcher/ts-quick-launcher.service';
import {MeasureNgService} from './ts-measure/ts-measure-ng.service';
import {TsExtraLoggingService} from './ts-extra-logging/ts-extra-logging.service';
import {TsBandwidthMeasurementService} from './ts-bandwidth-measurement/ts-bandwidth-measurement.service';

export default angular
    .module('app.services', ['tsUrlConfig.service'])
    .service('auth', AuthService)
    .service('db', DbService)
    .service('tsUrlUtils', TsUrlUtilsService)
    .service('tsVideoUtils', TsVideoUtilsService)
    .service('tsCanvasAnnotate', TsCanvasAnnotateService)
    .service('tsVideoControl', TsVideoControlService)
    .service('tsChatApi', ChatApiNgService)
    .service('tsLocaleHelper', TsLocaleHelperService)
    .service('tokenInterceptor', TokenInterceptor)
    .service('eventLog', EventLogService)
    .service('qos', AgentQOSService)
    .service('tsBusy', TsBusyService)
    .service('tsTimedOperation', TsTimedOperationService)
    .service('tsVisibilityChange', ['$window', TsVisibilityChange])
    .service('tsResponsiveUtils', TsResponsiveUtilsService)
    .service('tsHelpBrowser', TsHelpBrowserService)
    .service('tsWizardHelper', TsWizardHelperService)
    .service('tsExceptionHandler', TsExceptionHandler)
    .service('tsScan', TsScanService)
    .service('tsSessionData', TsSessionData)
    .service('customTranslateLoader', CustomTranslateLoader)
    .service('tsTranslationHelper', TsTranslationHelper)
    .service('tsInterfaceHelper', TsInterfaceHelperService)
    .service('tsStateHelper', TsStateHelperService)
    .service('tsSharingService', TsSharingService)
    .service('tsSnapshotReminderService', TsSnapshotReminderService)
    .service('tsScanArea', TsScanArea)
    .service('tsSmsService', TsSmsService)
    .service('tsCobrowsingService', TsCobrowsingService)
    .service('UiFlavorNg', UiFlavorNgService)
    .service('authExpiry', AuthExpiryNgService)
    .service('tsQuickLauncher', QuickLauncherService)
    .service('tsMeasure', MeasureNgService)
    .service('tsExtraLoggingService', TsExtraLoggingService)
    .service('tsBandwidthMeasurementService', TsBandwidthMeasurementService);
