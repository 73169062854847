import {Nullable} from '@techsee/techsee-ui-common/lib/_shared/reusable-types';
import {getRootStore} from '../../../_react_/app.bootstrap';
import {SessionSummaryController} from '../../../_react_/states/dashboard/session-summary/SessionSummary.controller';
import {ITag} from '@techsee/techsee-ui-common/lib/tags-panel';

export interface INewSessionSummary {
    allowNewSessionSummary: boolean;
    descriptionMaxLength: number;
}

export class TsSessionSummaryService {
    public controller: Nullable<SessionSummaryController> = null;

    init(roomId: string, db: any, imageTags: ITag[], newSessionSummarySettings: INewSessionSummary) {
        const rootStore = getRootStore();
        const translate = rootStore.localizationService.translate;

        this.controller = new SessionSummaryController(
            rootStore.dbHistoryService,
            rootStore.eventsLogService,
            roomId,
            translate,
            imageTags,
            newSessionSummarySettings
        );
    }

    showSummary() {
        this.controller!.showSessionSummary();
    }
}
