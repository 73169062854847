import {ITranslate} from '../../services/LocalizationService';
import {MeetingMode, ReasonForClosing, UserType} from '@techsee/techsee-common/lib/constants/room.constants';
import {IRoom} from '../../models/Room';
import {action, computed, observable, reaction, runInAction} from 'mobx';
import {ConnectionStatesEnum, DeviceDetails, SocketVersionConfig} from '../../services/Session/SessionContracts';
import {IDbLocation, IDbRooms, IDbResource} from '../../services/AngularServices/AngularServices';
import {DashboardState} from '../dashboard/_contracts/DashboardState';
import get from 'lodash/get';
import {onSocketVersionHandler} from '../../services/Session/SessionEventHandler';
import {NetworkInfo} from '../../models/LiveSessionState';
import {EventLogConstant} from '../../../constants/events/event-log.constant';

const {isInIframe} = require('@techsee/techsee-common/lib/utils'); // eslint-disable-line global-require

// @ts-ignore
import {CONNECTION_STATUS} from '../../../services/ts-locale-helper/ts-locale-helper.settings.js';
import {getRootStore} from '../../app.bootstrap';
import {IEventLogsService} from '../../services/EventsLogService';
// @ts-ignore
import {LOG_EVENTS} from '@techsee/techsee-common/lib/constants/event-logs.constants';
import {ClientType, PlatformType} from '@techsee/techsee-common/lib/constants/utils.constant';
import {TsTimedOperationService} from '../../../services/ts-timed-operation/ts-timed-operation.service';
import {ENDING_TIMEOUT} from './MultipartyDashboardSettings';
import includes from 'lodash/includes';
import {SessionClientRole, SessionClientType, VideoTypesEnum} from '@techsee/techsee-media-service/lib/MediaConstants';
import filter from 'lodash/filter';
import assign from 'lodash/assign';
import {getAppTracer} from '../../../app.tracer';
import {ILocaleHelperService, IStateHelper, IUrlUtils} from '../../services/AngularServices/AngularInterfaces';
import {IVisibilityChange} from '../../../services/ts-visibility-change/ts-visibility-change.service';
import {IUser} from '../../models/User';
import {LeftBarController} from './components/layouts/miniDashboardLayout/leftBar/LeftBarController';
import {IVideoController, VideoController} from './components/video/VideoController';
import {IMediaInputStateEvent, MediaInputStateService} from './services/MediaInputStateService';
import {
    AudioState,
    createMultipartyController,
    DesktopSharingEvent,
    IMultipartyController,
    MultipartyEvents,
    MultipartySessionSettings,
    MultipartyStreamDestroyedEvent
} from '@techsee/techsee-media-service-client/';
import {LiveLeftBarController} from './components/layouts/liveDashboardLayout/leftBar/LiveLeftBarController';
import {DashboardTopBarController} from '../dashboard/dashboard-top-bar/controller';
import {TempAccountSettings} from '../../layouts/main-layout/main-top-bar/controller.base';
import {SimpleModalController} from '../../components/simple-modal/controller';
import {SnapshotService} from './services/SnapshotService';
import {GalleryController} from './components/gallery/GalleryController';
import {uuidv4} from '@techsee/techsee-common/lib/core/uuid';
import {VideoLayoutType} from '@techsee/techsee-common/lib/constants/media.constants';
import {ShareScreenPopupController} from '../../components/twoWay-share-screen/controller';
import {
    screenSharedBy,
    VideoFilterType,
    TriggeredDataCollectionSource
} from '@techsee/techsee-common/lib/constants/account.constants';
import {MultipartyServiceVideoElementCreatedEvent, PinnedUserType} from '@techsee/techsee-media-service-client';
import {RemoteControlController} from './components/video/RemoteControlController';
import {RemoteControlPopupController} from '../../components/remote-control-section/controller';
import {
    AutoDataCollectionServices,
    IAutoDataCollectionServices
} from '@techsee/techsee-client-services/lib/services/AIAutoCollectionServices';

const trace = getAppTracer('MultipartyDashboardController');
const MESSAGE_ALERT_NOTIFICATION_COLOR = '#686868E5';
const MESSAGE_ALERT_NOTIFICATION_TIMEOUT = 3000;

type Coordinates = {
    lon: number;
    lat: number;
    altitude: number;
    accuracy: number;
};

export class MultipartyDashboardController {
    private _connectionStatus: any;
    private _connected: any;
    private eventParams: any = {};

    public readonly miniDashboard: boolean;
    public readonly embeddedDashboard: boolean;

    private _observe: boolean;
    private _playAudio: boolean;
    private _playVideo?: boolean;
    private _videoFilter?: VideoFilterType;
    private sessionInitializationInProgress: boolean = false;
    private multipartyControllerInitializationInProgress: boolean = false;
    private meetingSyncEnabled: boolean = false;
    private clientMediaPermissionsPopupShown = false;
    private saveOnLocalStorage = false;
    private triggeredDataCollectionOnMagicMarkerEnabled = false;

    public readonly videoController: IVideoController;
    @observable public readonly liveLeftBarController: LiveLeftBarController;
    public readonly liveTopBarController: DashboardTopBarController;
    public readonly leftBarController: LeftBarController;
    public readonly galleryController: GalleryController;
    public readonly autoDataCollectionServices: IAutoDataCollectionServices;

    private _dbRoomsService: IDbRooms;
    private _dbResourceService: IDbResource;
    private _dbLocationService: IDbLocation;
    private _eventsLogService: IEventLogsService;
    private _timedOperation: TsTimedOperationService;
    private _visibilityChange: IVisibilityChange;
    private _mediaInputStateService?: MediaInputStateService;
    private _sessionEndModalController?: SimpleModalController;
    private _customerEndedTheMeetingModalController?: SimpleModalController;
    private _clientWebRtcNotSupportedModalController?: SimpleModalController;
    private snapshotService: SnapshotService;
    public remoteControlController?: RemoteControlController;

    @observable
    private isClientPointerUnavailable: boolean = false;

    @observable
    private _customerEndedTheMeeting: boolean = false;

    @observable
    private _isLivePointerRunning: boolean = false;

    @observable
    private lastEvent: {text?: string; timestamp?: number} = {};

    @observable
    private videoLayoutType: VideoLayoutType = VideoLayoutType.AgentPinnedLayout;

    @observable
    private alertMessageTimeout?: any;

    @observable
    messageAlert: {label: string; color: string; display: boolean} | null = null;

    @observable private _enabledDesktopSharingInTwoWay: boolean = false;

    @observable public shareScreenPopupController: ShareScreenPopupController;
    @observable public remoteControlPopupController?: RemoteControlPopupController;
    @observable private isClientSharing: boolean = false;
    @observable private _isDesktopView: boolean = false;
    @observable private _isPortrait: boolean = false;

    constructor(
        $window: any,
        private readonly _localeHelper: ILocaleHelperService,
        public readonly translate: ITranslate,
        private readonly _tsChatApi: any,
        private readonly _tsUrlUtils: IUrlUtils,
        private readonly _currentUser: IUser,
        private _roomData: IRoom,
        private readonly _dashboardState: DashboardState,
        private readonly _logEvents: EventLogConstant,
        private readonly _tsStateHelper: IStateHelper,
        private readonly _accountData: any,
        sharingService: any
    ) {
        this._observe = !!this._tsUrlUtils.getParamValue('observe');
        this._localeHelper.clear();
        this._connectionStatus = CONNECTION_STATUS;
        this._connected = CONNECTION_STATUS.WAITING;
        this._eventsLogService = getRootStore().eventsLogService;
        this._dbRoomsService = getRootStore().dbRoomsService;
        this._dbResourceService = getRootStore().dbResourceService;
        this._dbLocationService = getRootStore().dbLocationService;
        this._timedOperation = getRootStore().timedOperationService;
        const isDesktopView = getRootStore().environmentService.isDesktop();

        this.miniDashboard = getRootStore().browserUtilsService.getFromSessionStorage('miniDashboard');
        this.embeddedDashboard = getRootStore().browserUtilsService.getFromSessionStorage('embeddedDashboard');
        this.saveOnLocalStorage = isInIframe(window) || (this.miniDashboard && !isDesktopView);

        this._visibilityChange = getRootStore().visibilityChangeService;
        this.shareScreenPopupController = new ShareScreenPopupController(this.translate);
        this.desktopSharingToggleControl = this.desktopSharingToggleControl.bind(this);

        this.galleryController = new GalleryController();
        this.videoController = new VideoController(
            translate,
            this._roomData,
            this._tsChatApi,
            this._dbRoomsService,
            this.eventLog,
            this.faceMeetMediaPermission,
            this.desktopSharingToMobile
        );
        this.snapshotService = new SnapshotService(
            $window,
            sharingService,
            getRootStore().dbHistoryService,
            this._tsChatApi
        );

        this.setLastEvent = this.setLastEvent.bind(this);

        this._playAudio = _roomData.initiateWithAudio || _tsUrlUtils.getParamValue('audio') === 'yes';
        this._playVideo = _roomData.initiateWithVideo;

        this._visibilityChange.visible(() => this._tsChatApi.visibilityChanged(true));
        this._visibilityChange.hidden(() => this._tsChatApi.visibilityChanged(false));

        const portrait = window?.matchMedia('(orientation: portrait)');

        if (portrait) {
            this.setIsPortrait(portrait?.matches);

            portrait.addEventListener('change', (e) => {
                this.setIsPortrait(e?.matches);
            });
        }

        const isDesktop = getRootStore().environmentService.isDesktop(false);

        this.setIsDesktopView(isDesktop);

        const isEnableDesktopSharing = this._isDesktopView || this.desktopSharingToMobile;

        this._enabledDesktopSharingInTwoWay =
            this._accountData.protectedSettings?.v2_1?.enabled &&
            this._accountData.protectedSettings?.desktopSharing.enabled &&
            this._accountData.protectedSettings?.faceMeet.videoLayout === VideoLayoutType.BigMainVideoLayout &&
            isEnableDesktopSharing;

        if (
            this._accountData.protectedSettings.desktopSharing.enableRemoteControl &&
            this._enabledDesktopSharingInTwoWay
        ) {
            this.remoteControlPopupController = new RemoteControlPopupController(this.translate);
            this.remoteControlPopupController.setToggleRequestRemoteControlCallback(this.desktopSharingToggleControl);
            this.remoteControlController = new RemoteControlController(
                this._tsChatApi,
                getRootStore().environmentService
            );
        }

        const isBlurAndSupported =
            get(this._roomData, 'videoFilterType') === VideoFilterType.BLUR &&
            get(this._accountData, 'protectedSettings.faceMeet.virtualBackground.enabledBlurAgent');
        const isVirtualBackgroundAndSupported =
            get(this._accountData, 'protectedSettings.faceMeet.virtualBackground.enabledImageReplacementAgent') &&
            get(this._roomData, 'videoFilterType') === VideoFilterType.BACKGROUND;

        this._videoFilter =
            get(this._accountData, 'protectedSettings.faceMeet.virtualBackground.enabledInFaceMeet') &&
            (isBlurAndSupported || isVirtualBackgroundAndSupported)
                ? (get(this._roomData, 'videoFilterType') as VideoFilterType)
                : VideoFilterType.NONE;
        /**
         * Check if previous session is on and disconnect from it
         * @TODO move to prepare/check method in tsChatApi service
         */
        if (this._tsChatApi.roomId) {
            this._tsChatApi.disconnect();
        }

        // Report room as started to avoid having the room be reported as connected before it is started
        this._roomData.start(this._roomData._id);

        this.leftBarController = new LeftBarController(
            this._dashboardState,
            () => this.endMeeting(this._logEvents.closedByTypes.dashboard),
            this.shareScreenPopupController,
            this._enabledDesktopSharingInTwoWay,
            this.translate,
            this.setLastEvent,
            this._accountData.protectedSettings.desktopSharing.enableRemoteControl,
            this._isDesktopView
        );

        const tempAccSettings: TempAccountSettings = {
            generalSettings: {
                emailImageSharing: get(this._accountData, 'protectedSettings.emailImageSharing'),
                localImageSaving: get(this._accountData, 'protectedSettings.localImageSaving'),
                logoUrl: this._accountData.settings.logoUrl,
                enableMobileGeolocation: this._accountData.protectedSettings.enableMobileGeolocation,
                nativeAppId: get(this._accountData, 'protectedSettings.liveSdk.nativeAppId')
            },
            inviteMethodSettings: {
                multiLanguage: this._accountData.settings.multiLanguage
            }
        };

        this.triggeredDataCollectionOnMagicMarkerEnabled = get(
            this._accountData,
            'protectedSettings.triggeredDataCollection.enableOnMagicMarker'
        );

        this.liveTopBarController = new DashboardTopBarController(
            tempAccSettings,
            this._dashboardState,
            this.translate,
            (_, closeBy) => {
                if (this._customerEndedTheMeeting) {
                    this.endMeeting(closeBy);
                } else {
                    this._sessionEndModalController?.show();
                }
            }
        );

        this.liveLeftBarController = new LiveLeftBarController(
            translate,
            this._enabledDesktopSharingInTwoWay,
            this._accountData.protectedSettings.desktopSharing.enableRemoteControl,
            this.shareScreenPopupController
        );

        this._onMediaInputStateEvent = this._onMediaInputStateEvent.bind(this);
        this._canTakeSnapshot = this._canTakeSnapshot.bind(this);
        this._takeSnapshot = this._takeSnapshot.bind(this);
        this.eventLog(LOG_EVENTS.userAgent, {
            side: PlatformType.dashboard,
            userAgent: window.navigator.userAgent,
            clientVersion: CLIENT_VERSION
        });

        this.connectToMeeting();
        this._initSimpleModals();
        this.downloadInitialSnapshots();

        reaction(
            () => ({
                layoutType: this.videoLayoutType,
                canTakeSnapshot: this.videoController.canTakeSnapshot
            }),
            ({layoutType, canTakeSnapshot}) => {
                this.videoController.setVideoLayoutType(layoutType);

                if (layoutType === VideoLayoutType.BigMainVideoLayout) {
                    this.videoController.onSnapshotRequested =
                        !this.miniDashboard && canTakeSnapshot ? this.onSnapshotRequested : undefined;
                    this.leftBarController.onSnapshotRequested = this.miniDashboard
                        ? this.onSnapshotRequested
                        : undefined;
                    this.leftBarController.isSnapshotEnabled = canTakeSnapshot;
                }
            }
        );

        reaction(
            () => ({
                isScreenShareDisabled: this.videoController.isScreenShareDisabled
            }),
            ({isScreenShareDisabled}) => {
                this.liveLeftBarController.setScreenShareDisabled(isScreenShareDisabled);
                this.leftBarController.setScreenShareDisabled(isScreenShareDisabled);

                const text = isScreenShareDisabled
                    ? this.leftBarController.translatedShareScreenText.toastClientStartSharingScreen
                    : this.leftBarController.translatedShareScreenText.toastClientStopSharingScreen;

                setTimeout(() => this.leftBarController.setLastEvent(this.translate(text)), 1000);
            }
        );

        const autoDataCollection =
            this._accountData.protectedSettings && this._accountData.protectedSettings.autoDataCollection;

        this.autoDataCollectionServices = new AutoDataCollectionServices();
        this.autoDataCollectionServices.initAutoDataCollection(
            autoDataCollection,
            this._canTakeSnapshot,
            this._takeSnapshot,
            this.snapshotService.snapshotToImg,
            this.snapshotService.uploadAutoCollectedImage
        );

        if (this.triggeredDataCollectionOnMagicMarkerEnabled && this.videoController.allowMagicMarker) {
            this._handleTriggeredDataCollection = this._handleTriggeredDataCollection.bind(this);
            this._handleSentTriggeredDataCollection = this._handleSentTriggeredDataCollection.bind(this);
            this._handleReceivedTriggeredDataCollection = this._handleReceivedTriggeredDataCollection.bind(this);

            this.videoController.magicMarkerController.on('magicMarkerSent', this._handleSentTriggeredDataCollection);
            this.videoController.magicMarkerController.on(
                'magicMarkerReceived',
                this._handleReceivedTriggeredDataCollection
            );
        }
    }

    private async _handleSentTriggeredDataCollection(): Promise<void> {
        return this._handleTriggeredDataCollection(TriggeredDataCollectionSource.Agent);
    }

    private async _handleReceivedTriggeredDataCollection(): Promise<void> {
        return this._handleTriggeredDataCollection(TriggeredDataCollectionSource.Customer);
    }

    private async _handleTriggeredDataCollection(source: TriggeredDataCollectionSource): Promise<void> {
        try {
            const image = await this._takeSnapshot();

            const snappedImg = this.snapshotService.snapshotToImg(image.base64img);

            if (snappedImg.size) {
                await this.snapshotService.uploadTriggeredCollectedImage(snappedImg.img, source);
            }
        } catch (e) {
            trace.warn('Failed collecting triggered data image', e);
        }
    }

    @computed
    public get showGallery(): boolean {
        return this.videoLayoutType === VideoLayoutType.BigMainVideoLayout;
    }

    private _canTakeSnapshot(): boolean {
        return (
            this.videoController.canTakeSnapshot &&
            this.videoController?.multipartyController?.getPinnedVideo().pinnedUserRole !== PinnedUserType.AGENT
        );
    }

    private async _takeSnapshot() {
        const snapshot = await this.videoController.takeSnapshot();

        if (!snapshot) {
            throw new Error('Cannot take snap!');
        }

        return {base64img: snapshot};
    }

    @action
    private setLastEvent(text: string, timestamp?: number): void {
        runInAction(() => {
            this.lastEvent = {text, timestamp};
        });
    }

    @action
    private setIsDesktopView(isDesktopView: boolean): void {
        this._isDesktopView = isDesktopView;
    }

    @action
    private setIsPortrait(isPortrait: boolean): void {
        this._isPortrait = isPortrait;
        this.videoController.setIsPortrait(isPortrait as boolean);
    }

    private downloadInitialSnapshots = async () => {
        const urls = await this.snapshotService.fetchSnapshotUrlsFromHistory(this._roomData._id);

        urls.forEach((image) => this.galleryController.addImage(uuidv4(), image));
    };

    private setReportedField = async (data: {data: {event: {key: string; value: any; type?: string}}}) => {
        this._dbRoomsService.setReportedField(this._roomData._id, data);
    };

    private onSnapshotRequested = async () => {
        const id = uuidv4();
        const snapshot = this.videoController.takeSnapshot();

        if (!snapshot) {
            return;
        }

        this.galleryController.addImageLoadingPlaceholder(id);
        const uploadResult = await this.snapshotService.uploadSnapshot(snapshot);

        if (uploadResult) {
            this.galleryController.addImage(id, snapshot);
        } else {
            this.galleryController.addImageFailedPlaceholder(id);
        }
    };

    private eventLog = (logType: string, meta?: any): Promise<void> =>
        this._eventsLogService
            .info({
                logType,
                userId: this._currentUser._id,
                accountId: this._currentUser.accountId,
                roomId: this._roomData._id,
                meta
            })
            .catch(() => trace.warn(`event log ${logType} failed`));

    private eventLogError = (logType: string, meta?: any): Promise<void> =>
        this._eventsLogService
            .error({
                logType,
                userId: this._currentUser._id,
                accountId: this._currentUser.accountId,
                roomId: this._roomData._id,
                meta
            })
            .catch(() => trace.warn(`event log ${logType} failed`));

    private handleLivePointerStatus() {
        this.videoController?.setLivePointerConnectedStatus(this._isLivePointerRunning);
        this.liveLeftBarController.setRemoteControlDisabled(!(this._isLivePointerRunning && this.isClientSharing));
        this.remoteControlPopupController?.setRemoteControlButtonDisabled(
            !(this._isLivePointerRunning && this.isClientSharing)
        );
    }

    private handleAgentHasRemoteControlStatus() {
        this.remoteControlPopupController?.setInControl(this.agentHasRemoteControl);
        this.liveLeftBarController.setRemoteControlActive(this.agentHasRemoteControl);
        this.liveLeftBarController.setRemoteControlContentVisibility(
            this.agentHasRemoteControl && this._accountData.protectedSettings?.desktopSharing.enableRemoteControl
        );
    }

    @action
    private initHandlers() {
        this._tsChatApi.on('sync', () => this.syncMeeting());

        this._tsChatApi.on('clientClickOnDesktopShare', (data: string, value: boolean) => {
            this.shareScreenPopupController.setIsSharingScreenDisabled(value);
        });

        this._tsChatApi.on('livePointerConnected', () => {
            this._isLivePointerRunning = get(this._tsChatApi, 'livePointer.connected');
            this.handleLivePointerStatus();
        });

        this._tsChatApi.on('clientAgentHasRemoteControl', () => {
            this.handleAgentHasRemoteControlStatus();
            this._handleDesktopSharingAgentControlStatus();
        });

        // eslint-disable-next-line complexity
        this._tsChatApi.on('log', (log: any, sender: any, isNew: boolean) => {
            switch (log.name) {
                case 'MEDIA_PERMISSION_REJECTED':
                    this._mediaInputStateService?.mediaPermissionRejected();
                    break;
                case 'CUSTOMER_ENDED_THE_MEETING':
                    runInAction(() => {
                        this._customerEndedTheMeeting = true;
                    });
                    this.terminateMediaInputStateEvents();
                    this.videoController.multipartyController?.disconnect();
                    this._customerEndedTheMeetingModalController?.show();
                    break;
                case 'CLIENT_LOCATION_DENIED':
                    this.eventLog(LOG_EVENTS.clientLocationDenied);
                    break;
                case 'CLIENT_LOCATION_ERROR':
                    this.eventLog(LOG_EVENTS.clientLocationError);
                    break;
                case 'CLIENT_LOCATION':
                    if (this._tsChatApi.client.location) {
                        this.eventLog(LOG_EVENTS.clientLocation, {coords: this._tsChatApi.client.location});
                        this._getAddress(this._tsChatApi.client.location).then(() =>
                            this._localeHelper.handleMessage(log, sender, isNew)
                        );
                    }
                    break;
                case 'TOS_REJECTED':
                    this._shouldRouteToSessionEndedState();
                    break;
                default:
                    break;
            }

            if (this._shouldHandleLogMessage(log.name)) {
                this._localeHelper.handleMessage(log, sender, isNew);
            }
        });

        this._tsChatApi.on('endMeetingAction', () => this.endMeeting(this._logEvents.closedByTypes.simpleApi));
        this._tsChatApi.on('socketVersion', (message: SocketVersionConfig) => onSocketVersionHandler(message));
        this._tsChatApi.on('clientMode', () => {
            const clientMode = get(this._tsChatApi, 'client.mode');

            clientMode && this._roomData.changeAgent(clientMode.toUpperCase());
        });

        this._tsChatApi.on('clientDeviceDetails', (deviceDetails: DeviceDetails) => {
            runInAction(() => {
                this.liveLeftBarController.setScreenShareDisabled(ClientType.desktop_web !== deviceDetails.clientType);
                this.leftBarController.setIsEnableScreenShareButtons(
                    ClientType.desktop_web === deviceDetails.clientType || this.desktopSharingToMobile
                );
            });
        });

        this._tsChatApi.on('clientConnected', () => {
            const clientConnected = get(this._tsChatApi, 'client.connected');

            if (clientConnected) {
                this._roomData.connect(this._roomData._id);

                this._localeHelper.mobileClientConnected();

                if (!get(this._tsChatApi, 'client.visible') && this._connected !== this._connectionStatus.IDLE) {
                    this._localeHelper.mobileClientHidden();
                    this._connected = this._connectionStatus.IDLE;
                }

                return;
            }

            this._localeHelper.mobileClientDisconnected();
        });
        this._tsChatApi.on('clientLocation', (param: any, coords: any) => {
            this._getAddress(coords);
        });
        this._tsChatApi.on('roomRejected', () => {
            this._tsChatApi.sendLog(new Error('Room rejected'));
        });
        this._tsChatApi.on('clientVisible', (param: any, state: any) => {
            if (state) {
                this._localeHelper.mobileClientVisible();
            } else {
                this._localeHelper.mobileClientHidden();
            }

            this._connected = state ? this._connectionStatus.CONNECTED : this._connectionStatus.IDLE;
        });

        this._tsChatApi.on('mobileNetworkType', (networkInfo: any) => {
            const mobileNetworkInfo = NetworkInfo.fromDataObject(networkInfo);

            runInAction(() => {
                if (this._dashboardState) {
                    this._dashboardState.clientNetworkInfo.downlinkMax = mobileNetworkInfo.downlinkMax;
                    this._dashboardState.clientNetworkInfo.connectionType = mobileNetworkInfo.connectionType;
                }
            });

            const type = mobileNetworkInfo.connectionType;

            switch (type.toLowerCase()) {
                case 'cellular':
                    this._localeHelper.mobileNetworkCell(type);
                    break;
                case 'unsupported':
                case 'wifi':
                    this._localeHelper.mobileNetworkWifi(type);
                // eslint-disable-next-line no-fallthrough
                default:
                    break;
            }
        });
        this._tsChatApi.on('roomUpdated', (customerId: any) => {
            this._roomData.customerId = customerId;
        });

        this._localeHelper.on('newEvent', (eventLog: any) => {
            this.handleLog(eventLog);
        });

        this._localeHelper.on('clientConnectionStatus', (status: any) => (this._connected = status));
    }

    _shouldHandleLogMessage(logName: string) {
        switch (logName) {
            case 'CLIENT_LOCATION':
                return false;
            case 'CUSTOMER_IN_PHOTOGALLERY_PAGE':
                return !this._observe;
            default:
                return true;
        }
    }

    _getAddress(coords: Coordinates) {
        return this._dbLocationService
            .address({params: {lon: coords.lon.toString(), lat: coords.lat.toString(), lang: 'en_US'}})
            .then((res: {data: [{formattedAddress: string}]}) => {
                const loc = res.data[0];

                this.eventParams.address = loc.formattedAddress;
                this._updateTopbarAddress(coords, loc.formattedAddress);
                this._localeHelper.updateLogs();
                this.eventLog(LOG_EVENTS.clientLocationAddress, {formattedAddress: loc.formattedAddress});
            })
            .catch(() => {
                // if retrieval fails, just show the coordinates
                this.eventParams.address = `${coords.lon}, ${coords.lat}`;
                this._updateTopbarAddress(coords);
                this._localeHelper.updateLogs();
                this.eventLog(LOG_EVENTS.clientLocationAddressError);
            })
            .finally(() => {
                if (this.embeddedDashboard) {
                    const data: {x: number; y: number; address: string; z?: number; accuracy?: number} = {
                        x: coords.lon,
                        y: coords.lat,
                        address: this.eventParams.address || ''
                    };

                    if (coords.altitude) {
                        data.z = coords.altitude;
                    }

                    if (coords.accuracy) {
                        data.accuracy = coords.accuracy;
                    }

                    this._dbRoomsService.customerGeolocation(this._roomData._id, {data});
                }
            });
    }

    handleLog(eventLog: any) {
        const text = this.translate(eventLog.text, {
            device: get(this.eventParams, 'device'),
            address: get(this.eventParams, 'address')
        });

        if (!text) {
            return;
        }

        runInAction(() => {
            this.lastEvent = {
                text,
                timestamp: new Date().getTime()
            };
        });
    }

    endMeeting(closedBy: string, reason?: ReasonForClosing) {
        return Promise.resolve()
            .then(() => {
                if (!this._timedOperation.lockAttempt(ENDING_TIMEOUT)) {
                    return Promise.resolve();
                }

                const meeting = this._tsChatApi.dashboard.meeting;

                this._tsChatApi.setStatus('meeting', false);
                this._tsChatApi.disconnect();
                this.terminateMediaInputStateEvents();
                this.videoController.multipartyController?.disconnect();
                this._connected = CONNECTION_STATUS.DISCONNECTED;
                this.autoDataCollectionServices.end();

                if (meeting) {
                    return this._roomData.end(closedBy, reason).catch((err: any) => console.error(err));
                }

                return Promise.resolve();
            })
            .finally(() => {
                this._timedOperation.release();
                this._shouldRouteToSessionEndedState();
            });
    }

    _shouldRouteToSessionEndedState() {
        this.embeddedDashboard ? this._tsStateHelper.safeGo('sessionEnded') : this._tsStateHelper.safeGo('invite');
    }

    connectToMeeting() {
        trace.info('Connecting to meeting');

        const initialSyncHandler = () => {
            if (get(this._tsChatApi, 'dashboard.clickOnDesktopShare')) {
                setTimeout(() => {
                    this.leftBarController.setLastEvent(
                        this.translate('DASHBOARD_MINI.TWO_WAY.SHARE_SCREEN.STOPPED.TOAST')
                    );
                }, 2000);
            }

            this._tsChatApi.setStatus('clickOnDesktopShare', false);
            trace.info('Initial sync');
            this.initHandlers();

            Promise.resolve()
                .then(() => this.syncLanguage())
                .then(() => (this.meetingSyncEnabled = true))
                .then(() => this.syncMeeting())
                .catch((error) => {
                    this.meetingSyncEnabled = true;
                    trace.warn('Error during start meeting flow', error && error.toString());
                });
        };

        this._tsChatApi.once('sync', initialSyncHandler);

        if (this._observe) {
            this._tsChatApi.connect(this._roomData._id, UserType.supervisor, null, this._currentUser._id);
        } else {
            const roomCode = this._tsUrlUtils.getParamValue('roomCode');

            this._tsChatApi.connect(this._roomData._id, UserType.dashboard, roomCode);
        }
    }

    syncMeeting() {
        if (!this.meetingSyncEnabled) {
            trace.info('Sync arrived from BE, but not ready to sync yet');

            return Promise.resolve();
        }

        trace.info('Start syncMeeting flow');

        return Promise.resolve()
            .then(() => this.syncRoom())
            .then(() => this.syncMediaSession())
            .then(() => this.handleDesktopSharingControlStatus())
            .then(() => this._handleAgentPointerAvailability())
            .catch((error) => {
                trace.info('Sync meeting flow error', error);
            });
    }

    setMessageAlert(label: string, color: string, timeout: number) {
        if (this.miniDashboard) {
            this.lastEvent = {text: label};

            return;
        }

        clearTimeout(this.alertMessageTimeout);

        this.messageAlert = {label, color, display: true};
        this.alertMessageTimeout = setTimeout(() => {
            runInAction(() => {
                this.messageAlert = {label: '', color: '', display: false};
                clearTimeout(this.alertMessageTimeout);
                this.alertMessageTimeout = null;
            });
        }, timeout);
    }

    @action
    desktopSharingToggleControl() {
        const isAgentPermittedToControl = this._accountData.protectedSettings?.desktopSharing.enableRemoteControl;

        this._tsChatApi.setStatus(
            'agentRequestRemoteControl',
            isAgentPermittedToControl && !this.agentHasRemoteControl
        );
        this.setMessageAlert(
            this.translate(
                !this.agentHasRemoteControl
                    ? 'DASHBOARD.DESKTOP_SHARING_REQUEST_SENT_TO_CUSTOMER'
                    : 'DASHBOARD.DESKTOP_SHARING_REQUEST_STOPPED'
            ),
            MESSAGE_ALERT_NOTIFICATION_COLOR,
            MESSAGE_ALERT_NOTIFICATION_TIMEOUT
        );

        if (this.agentHasRemoteControl) {
            this.eventLog(LOG_EVENTS.desktopShareAgentStoppedRemoteControl);
        } else {
            this.eventLog(LOG_EVENTS.desktopShareAgentRequestRemoteControl);
        }
    }

    _hidePointerAlert() {
        this.saveOnLocalStorage
            ? getRootStore().browserUtilsService.getFromLocalStorage(
                  `hidePointerUnavailableAlert_${this._roomData._id}`
              )
            : getRootStore().browserUtilsService.getFromSessionStorage('hidePointerUnavailableAlert');
    }

    _dontShowPointerAlertAgain() {
        this.saveOnLocalStorage
            ? getRootStore().browserUtilsService.saveToLocalStorage(
                  `hidePointerUnavailableAlert_${this._roomData._id}`,
                  true
              )
            : getRootStore().browserUtilsService.saveToSessionStorage('hidePointerUnavailableAlert', true);
    }

    _handleAgentPointerAvailability() {
        const availabilityStatus = this._tsChatApi.client.livePointerUnavailable;

        if (availabilityStatus !== null && availabilityStatus !== this.isClientPointerUnavailable) {
            this.isClientPointerUnavailable = availabilityStatus;
        }

        if (this.isClientPointerUnavailable) {
            this._hidePointerAlert();
            this._dontShowPointerAlertAgain();
            const message = this.translate('DASHBOARD.VIEW.POINTER_UNAVAILABLE');

            this.setMessageAlert(message, MESSAGE_ALERT_NOTIFICATION_COLOR, MESSAGE_ALERT_NOTIFICATION_TIMEOUT);
        }

        return Promise.resolve();
    }

    get agentHasRemoteControl() {
        return this._tsChatApi?.client?.agentHasRemoteControl;
    }

    @action
    _handleDesktopSharingAgentControlStatus() {
        if (this.agentHasRemoteControl === null) {
            return;
        }

        if (!this.agentHasRemoteControl) {
            this._tsChatApi.setStatus('agentRequestRemoteControl', false);
        }

        const toastMessageText = this.agentHasRemoteControl
            ? this.translate('REACT.MULTIPARTY_DASHBOARD.REMOTE_CONTROL.TOAST.REQUEST.APPROVED')
            : this.translate('DASHBOARD.DESKTOP_SHARING_REQUEST_STOPPED');

        this.setMessageAlert(toastMessageText, MESSAGE_ALERT_NOTIFICATION_COLOR, MESSAGE_ALERT_NOTIFICATION_TIMEOUT);

        if (this.agentHasRemoteControl) {
            this.eventLog(LOG_EVENTS.desktopShareClientApprovedRemoteControl);
        } else {
            this.eventLog(LOG_EVENTS.desktopShareClientStoppedRemoteControl);
        }

        return Promise.resolve();
    }

    @action
    handleDesktopSharingControlStatus() {
        runInAction(() => {
            this._isLivePointerRunning = get(this._tsChatApi, 'livePointer.connected');
            this.leftBarController.setIsLivePointerRunning(this._isLivePointerRunning);
            this.handleLivePointerStatus();
            this.handleAgentHasRemoteControlStatus();
        });
    }

    syncLanguage() {
        this._tsChatApi.setStatus('language', this._localeHelper.getLocale());

        return Promise.resolve();
    }

    private initMediaInputStateEvents() {
        if (this.miniDashboard) {
            this._mediaInputStateService?.on('mediaInputState', this._onMediaInputStateEvent);
        }
    }

    private terminateMediaInputStateEvents() {
        if (this.miniDashboard) {
            this._mediaInputStateService?.off('mediaInputState', this._onMediaInputStateEvent);
            this._mediaInputStateService?.terminateHandlers();
        }
    }

    _onMediaInputStateEvent(data: IMediaInputStateEvent) {
        const text = data.text;
        const timestamp = new Date().getTime();

        runInAction(() => {
            this.lastEvent = {text: this.translate(text), timestamp};
        });
    }

    faceMeetMediaPermissionFailed = () => {
        this._tsChatApi.sendLog('MEDIA_PERMISSION_REJECTED');
        this._tsChatApi.setStatus('mediaPermissionsAccepted', false);
        this._tsChatApi.setStatus('mediaPermissionsRejected', true);
    };
    faceMeetMediaPermissionSuccess = () => {
        this._tsChatApi.setStatus('mediaPermissionsAccepted', true);
        this._tsChatApi.setStatus('mediaPermissionsRejected', false);
    };

    faceMeetMediaPermission = (data: any): void => {
        if (data?.mediaDeviceAccessDenied) {
            this.faceMeetMediaPermissionFailed();

            return;
        }

        this.faceMeetMediaPermissionSuccess();
    };

    private _convertImageToBlob = (url: string) => {
        const blobToBase64 = (blob: any) => {
            const reader = new FileReader();

            reader.readAsDataURL(blob);

            return new Promise((resolve) => {
                reader.onloadend = () => {
                    resolve(reader.result);
                };

                reader.onerror = () => {
                    resolve('');
                };
            });
        };

        const loadImage = (url: string) =>
            fetch(`${url}`)
                .then((res) => res.blob())
                .then((blob) => blobToBase64(blob))
                .then((base64) => base64)
                .catch((err) => {
                    trace.warn(
                        'error while converting default background replacement image to base64',
                        JSON.stringify(err)
                    );

                    return '';
                });

        return loadImage(url);
    };

    private getBackgroundReplacementImg = async () => {
        let imgUrl;

        if (
            get(this._accountData, 'protectedSettings.faceMeet.virtualBackground.enabledInFaceMeet') &&
            this._roomData.videoFilterType === VideoFilterType.BACKGROUND &&
            get(this._accountData, 'protectedSettings.faceMeet.virtualBackground.enabledImageReplacementAgent')
        ) {
            try {
                const res = await this._dbResourceService.default(this._currentUser.accountId);

                imgUrl = this._convertImageToBlob(res.data) || '';

                this.eventLog(LOG_EVENTS.gettingImageBackgroundResourceSuccess, {imgUrl});
            } catch (err) {
                trace.warn('error during getting default background replacement image', JSON.stringify(err));
                this.eventLog(LOG_EVENTS.gettingImageBackgroundResourceFailed, {error: err});
            }
        }

        return imgUrl;
    };

    syncMediaSession() {
        const clientMode = get(this._tsChatApi, 'client.mode');

        const clientWebRtcSupported = get(this._tsChatApi, 'client.webRtcSupported') !== false;

        if (!clientWebRtcSupported && !this.clientMediaPermissionsPopupShown) {
            this.clientMediaPermissionsPopupShown = true;
            this._clientWebRtcNotSupportedModalController?.show();
        }

        if (!clientMode) {
            //No need to connect to media session while mode is unknown
            return Promise.resolve();
        }

        const mediaSessionModes = [MeetingMode.faceMeet];
        const isMediaSessionMode = includes(mediaSessionModes, this._tsChatApi.client.mode);

        if (this.sessionInitializationInProgress) {
            return Promise.resolve();
        }

        if (isMediaSessionMode) {
            const mediaSessionParams: {
                sessionId: string;
                clientType: SessionClientType;
                clientRole: SessionClientRole;
                credentials: {sessionId?: string; token?: string; tokenClient?: string; apiKey?: string};
            } = {
                sessionId: this._roomData._id,
                clientType: SessionClientType.GUEST,
                clientRole: this._observe ? SessionClientRole.OBSERVER : SessionClientRole.AGENT,
                credentials: {}
            };

            let paramsReadyPromise = Promise.resolve();

            this.sessionInitializationInProgress = true;

            const syncOTCredentials = () => {
                let opentokToken = this._roomData.opentok.tokenAgent;

                if (this._observe) {
                    const tokenObserve = filter(
                        this._roomData.opentok.tokenObservers,
                        (observer) => observer.userId === this._currentUser._id.toString()
                    );

                    opentokToken = tokenObserve && tokenObserve[0] && tokenObserve[0].token;
                }

                this._roomData.opentok.token = opentokToken;

                mediaSessionParams.credentials = assign({}, this._roomData.opentok);

                // when client or agent select None as default camera in admin,
                // flag startSessionWithVideo will be false and the default camera will become the Front camera
                const selectedCamera = get(this._accountData, 'protectedSettings.faceMeet.agentDefaultCamera');
                const agentStartSessionWithVideo = get(
                    this._accountData,
                    'protectedSettings.faceMeet.agentStartSessionWithVideo'
                );
                const cameraEnabled = this._playVideo !== undefined ? this._playVideo : agentStartSessionWithVideo;

                const mediaServiceSettings: MultipartySessionSettings = {
                    cameraEnabled,
                    selectedCamera,
                    mediaServiceType: this._roomData.mediaServiceType,
                    audioState: this._playAudio ? AudioState.ENABLED : AudioState.DISABLED,
                    enableDesktopSharing: this._enabledDesktopSharingInTwoWay,
                    videoFilter: this._videoFilter,
                    ipWhitelist: get(this._accountData, 'protectedSettings.useOnlyOpenTokAllowedIPS')
                };

                const isPeerMediaPermissionRejected =
                    !this._tsChatApi.client.mediaPermissionsAccepted && this._tsChatApi.client.mediaPermissionsRejected;

                const {sessionId, token, apiKey} = mediaSessionParams.credentials;

                if (
                    !this.multipartyControllerInitializationInProgress &&
                    !this.videoController.multipartyController &&
                    sessionId &&
                    token &&
                    apiKey
                ) {
                    this.multipartyControllerInitializationInProgress = true;
                    this._tsChatApi.setStatus('facemeetBigVideoPinnedUser', 'none');

                    return this._createMultipartyController(mediaServiceSettings, {sessionId, token, apiKey});
                }
                this.videoController.setIsPeerMediaPermissionsRejected(isPeerMediaPermissionRejected);
            };

            if (!this._roomData.opentok.init || this._observe) {
                paramsReadyPromise = this._roomData
                    .initOpentok(this._observe ? UserType.supervisor : UserType.dashboard)
                    .then((roomData) => {
                        this._roomData = roomData;
                        syncOTCredentials();
                    });
            } else {
                syncOTCredentials();
            }

            return paramsReadyPromise
                .then(() => {
                    if (
                        !mediaSessionParams.credentials ||
                        Object.getOwnPropertyNames(mediaSessionParams.credentials).length < 2
                    ) {
                        return Promise.resolve();
                    }

                    this._tsChatApi.setStatus('opentok', {
                        session: {
                            sessionId: mediaSessionParams.credentials.sessionId,
                            token: mediaSessionParams.credentials.tokenClient,
                            apiKey: mediaSessionParams.credentials.apiKey
                        }
                    });

                    return Promise.resolve();
                })
                .catch((error) => {
                    trace.warn('error during connection to media session', error);
                })
                .finally(() => (this.sessionInitializationInProgress = false));
        } else if (!isMediaSessionMode) {
            // if (isSessionActive) {
            //     disconnectFromSession()
            // }
        }

        return Promise.resolve();
    }

    syncRoom() {
        const {faceMeetVideoLayout} = this._tsChatApi.accountSettings;

        // Initial connectivity, in case user already connected during invite
        if (this._connected === CONNECTION_STATUS.WAITING && this._tsChatApi.client.connected) {
            this._connected = CONNECTION_STATUS.CONNECTED;
        }

        const clientDisconnected = !this._tsChatApi.client.connected && this._tsChatApi.client.connected !== undefined;
        const isOffline = clientDisconnected || this._connected === this._connectionStatus.IDLE;

        runInAction(() => {
            if (this._dashboardState) {
                this._dashboardState.connectionState = isOffline
                    ? ConnectionStatesEnum.OFFLINE
                    : ConnectionStatesEnum.HIGH;
            }
            if (faceMeetVideoLayout) {
                this.videoLayoutType = faceMeetVideoLayout;
            }

            if (get(this._tsChatApi, 'client.clientType')) {
                this.liveLeftBarController.setScreenShareDisabled(
                    ClientType.desktop_web !== get(this._tsChatApi, 'client.clientType')
                );
                this.leftBarController.setIsEnableScreenShareButtons(
                    ClientType.desktop_web === get(this._tsChatApi, 'client.clientType') || this.desktopSharingToMobile
                );
            }
        });

        if (this._tsChatApi.dashboard.roomStarted || clientDisconnected) {
            return Promise.resolve(this._roomData);
        }

        return this._roomData.start(this._roomData._id).then((roomData) => {
            this._roomData = roomData;

            this._tsChatApi.setStatus('roomStarted', true);

            return roomData;
        });
    }

    @action
    private _multipartyControllerInitializeHandlers(controller: IMultipartyController) {
        controller.on(MultipartyEvents.streamDestroyed, (event: MultipartyStreamDestroyedEvent) => {
            const {videoType, clientRole} = event;

            if (videoType === VideoTypesEnum.SCREEN) {
                if (clientRole === SessionClientRole.USER) {
                    this.liveLeftBarController.setRemoteControlDisabled(true);
                    this.remoteControlPopupController?.setRemoteControlButtonDisabled(true);
                    this.isClientSharing = false;
                    this._tsChatApi.setStatus('facemeetBigVideoPinnedUser', undefined);
                } else if (clientRole === SessionClientRole.AGENT) {
                    this._tsChatApi.setStatus('clickOnDesktopShare', false);
                }
            }

            if (videoType === VideoTypesEnum.SCREEN) {
                this.shareScreenPopupController.setIsSharingScreen(false);
            }
        });

        controller.on(MultipartyEvents.streamCreated, (event: MultipartyStreamDestroyedEvent) => {
            const {videoType, clientRole} = event;

            if (videoType === VideoTypesEnum.SCREEN && clientRole === SessionClientRole.USER) {
                this.liveLeftBarController.setRemoteControlDisabled(!this._isLivePointerRunning);
                this.remoteControlPopupController?.setRemoteControlButtonDisabled(!this._isLivePointerRunning);
                this.isClientSharing = true;
            }
        });

        controller.on(MultipartyEvents.videoElementCreated, (e: MultipartyServiceVideoElementCreatedEvent) => {
            // @ts-ignore
            const streamScreenType = e.element?.srcObject.getVideoTracks()[0].getSettings().displaySurface;

            if (streamScreenType) {
                const data = {
                    event: {
                        key: 'desktopSharingStatus',
                        value: {type: 'desktopSharingStarted', shareBy: screenSharedBy.Agent, streamScreenType},
                        type: 'push'
                    }
                };

                this.setReportedField({data});
            }
        });

        controller.on(MultipartyEvents.desktopSharing, (event: DesktopSharingEvent) => {
            if (event.userRole !== SessionClientRole.AGENT) {
                return;
            }

            if (event.clickOnDesktopShare) {
                this._tsChatApi.setStatus('clickOnDesktopShare', true);
                this.eventLog(LOG_EVENTS.clickOnDesktopShare);
            }

            if (event.desktopSharing) {
                this._tsChatApi.setStatus('clickOnDesktopShare', true);
                this.eventLog(LOG_EVENTS.agentDesktopSharing);
            }

            if (event.clickOnStopDesktopShare) {
                this._tsChatApi.setStatus('clickOnDesktopShare', false);
                this.eventLog(LOG_EVENTS.clickOnStopDesktopShare);
            }

            if (event.failedToDesktopShare) {
                this._tsChatApi.setStatus('clickOnDesktopShare', false);
                this.eventLogError(LOG_EVENTS.failedToDesktopShare, {err: event?.err});
            }
        });
    }

    private _initSimpleModals() {
        this._sessionEndModalController = new SimpleModalController(
            '',
            this.translate('REACT.MULTIPARTY_DASHBOARD.END_SESSION_MODAL.TITLE'),
            this.translate('REACT.MULTIPARTY_DASHBOARD.END_SESSION_MODAL.CONTENT'),
            this.translate('REACT.COMMON.BUTTONS.CANCEL'),
            this.translate('REACT.MULTIPARTY_DASHBOARD.END_SESSION_MODAL.CONFIRM'),
            () => {
                this.endMeeting(EventLogConstant.closedByTypes.dashboard);
            }
        );

        this._customerEndedTheMeetingModalController = new SimpleModalController(
            '',
            this.translate('REACT.MULTIPARTY_DASHBOARD.CUSTOMER_ENDED_THE_SESSION_MODAL.TITLE'),
            this.translate('REACT.MULTIPARTY_DASHBOARD.CUSTOMER_ENDED_THE_SESSION_MODAL.CONTENT'),
            undefined,
            this.translate('REACT.COMMON.BUTTONS.OK')
        );

        this._clientWebRtcNotSupportedModalController = new SimpleModalController(
            '',
            this.translate('REACT.MULTIPARTY_DASHBOARD.WEBRTC_MODAL_TITLE'),
            this.translate('REACT.MULTIPARTY_DASHBOARD.WEBRTC_MODAL_MESSAGE'),
            undefined,
            this.translate('REACT.MULTIPARTY_DASHBOARD.CANCEL_BUTTON'),
            () => this._clientWebRtcNotSupportedModalController?.hide(),
            undefined
        );
    }

    private _createMultipartyController(
        mediaServiceSettings: MultipartySessionSettings,
        credentials: {sessionId: string; token: string; apiKey: string}
    ) {
        this.getBackgroundReplacementImg().then((signedImgUrl?: string | unknown) => {
            if (signedImgUrl) {
                mediaServiceSettings.backgroundReplacementImg = signedImgUrl as string;
            }

            return createMultipartyController(credentials, mediaServiceSettings, SessionClientRole.AGENT).then(
                (controller) => {
                    controller.setPinnedVideo(this._tsChatApi.dashboard.facemeetBigVideoPinnedUser);
                    this.videoController.setMultipartyController(controller);
                    this.shareScreenPopupController.setShareScreenCallback(
                        controller.shareScreen,
                        controller.stopShareScreen
                    );

                    this._multipartyControllerInitializeHandlers(controller);

                    if (!this._mediaInputStateService && this.videoController.multipartyController) {
                        this._mediaInputStateService = new MediaInputStateService(
                            this.videoController.multipartyController,
                            !this._playAudio,
                            this._roomData._id
                        );
                        this.initMediaInputStateEvents();
                    }
                    this.multipartyControllerInitializationInProgress = false;
                }
            );
        });
    }

    private _updateTopbarAddress(coords: Coordinates, formattedAddress = '') {
        const {lat, lon, altitude, accuracy} = coords;
        const googleMapsQuery = formattedAddress ? encodeURIComponent(formattedAddress) : `${lat},${lon}`;
        const googleMapsLink = `https://www.google.com/maps/search/?api=1&query=${googleMapsQuery}`;

        runInAction(() => {
            this._dashboardState.clientLocationInfo = {
                lat,
                lon,
                altitude,
                accuracy,
                formattedAddress,
                googleMapsLink,
                isDenied: false,
                isFailed: false
            };
        });
    }

    get notificationMessage() {
        return this.lastEvent.text;
    }

    get isDesktopView(): boolean {
        return this._isDesktopView;
    }

    get desktopSharingToMobile(): boolean {
        return get(this._accountData, 'protectedSettings.v2_1.desktopSharingToMobile');
    }

    get sessionEndModalController() {
        return this._sessionEndModalController;
    }

    @computed
    get customerEndedTheMeeting() {
        return this._customerEndedTheMeeting;
    }

    @computed
    get isPortrait() {
        return this._isPortrait;
    }

    get customerEndedTheMeetingModalController() {
        return this._customerEndedTheMeetingModalController;
    }

    get clientWebRtcNotSupportedModalController() {
        return this._clientWebRtcNotSupportedModalController;
    }

    @computed
    get showLoader() {
        return this.videoController.multipartyController === undefined;
    }

    @computed
    get isLivePointerRunning() {
        return this._isLivePointerRunning;
    }
}
