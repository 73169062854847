/* eslint-disable max-classes-per-file */
import {TechseeFormBase} from '@techsee/techsee-ui-common/lib/forms/_shared/TechseeFormBase';
import {ValidationRules} from '@techsee/techsee-ui-common/lib/forms/_shared/ValidationRules';
import {ITranslate} from '../../../services/LocalizationService';
import {IFieldFactory} from '../../../app.contracts';
import {observable} from 'mobx';
import {serializable} from 'serializr';
import {IPasswordSettings} from '../../../models/AccountSettings';
import {IFieldModel} from '@techsee/techsee-ui-common/lib/forms/_shared/simple-field-model';
import {makeHumanReadableSet} from '@techsee/techsee-common/lib/helpers/strings.helper';

export interface AgentSettingsFieldNames {
    firstName: string;
    lastName: string;
    clientLanguage: string;
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
}

export interface PasswordOptions {
    requireDigit: boolean;
    requireUppercase: boolean;
    requireSpecial: boolean;
}

export interface IAgentSettingsForm {
    createFirstNameField(initial: string): void;
    createLastNameField(initial: string): void;
    createClientLanguageField(initial: string): void;
    createOldPasswordField(): void;
    createNewPasswordField(): void;
    createConfirmPasswordField(): void;
    getFormValues(): void;
    validateForm(): PromiseLike<boolean>;
    displayFieldsErrors(): void;
    resetFormFields(): void;

    readonly fields: any;
    readonly isValid: boolean;
    readonly fieldNames: AgentSettingsFieldNames;
}

export class AgentSettingsInfo {
    @serializable @observable firstName: string = '';

    @serializable @observable lastName: string = '';

    @serializable @observable oldPassword: string = '';

    @serializable @observable newPassword: string = '';

    @serializable @observable confirmPassword: string = '';
}

export class AgentSettingsForm extends TechseeFormBase implements IAgentSettingsForm {
    constructor(
        private translate: ITranslate,
        private fieldFactory: IFieldFactory,
        private passwordSettings: IPasswordSettings
    ) {
        super();
    }

    get fieldNames(): AgentSettingsFieldNames {
        return {
            firstName: 'firstName',
            lastName: 'lastName',
            clientLanguage: 'clientLanguage',
            oldPassword: 'oldPassword',
            newPassword: 'newPassword',
            confirmPassword: 'confirmPassword'
        };
    }

    createFirstNameField(initial: string): void {
        const field = this.fieldFactory('REACT.APP.AGENT_SETTINGS.FIRST_NAME');

        field.setValue(initial);

        field.addRule({
            rule: ValidationRules.required(),
            message: this.translate('REACT.APP.AGENT_SETTINGS.FIRST_NAME_REQUIRED')
        });

        this.addField(this.fieldNames.firstName, field);
    }

    createLastNameField(initial: string): void {
        const field = this.fieldFactory('REACT.APP.AGENT_SETTINGS.LAST_NAME');

        field.setValue(initial);

        field.addRule({
            rule: ValidationRules.required(),
            message: this.translate('REACT.APP.AGENT_SETTINGS.LAST_NAME_REQUIRED')
        });

        this.addField(this.fieldNames.lastName, field);
    }

    createClientLanguageField(initial: string): void {
        const field = this.fieldFactory('REACT.APP.AGENT_SETTINGS.CLIENT_LANGUAGE');

        field.setValue(initial);

        field.addRule({
            rule: ValidationRules.required(),
            message: this.translate('REACT.APP.AGENT_SETTINGS.CLIENT_LANGUAGE_REQUIRED')
        });

        this.addField(this.fieldNames.clientLanguage, field);
    }

    createOldPasswordField(): void {
        const field = this.fieldFactory('REACT.APP.AGENT_SETTINGS.OLD_PASSWORD');

        this.addField(this.fieldNames.oldPassword, field);
    }

    createNewPasswordField(): void {
        const field = this.fieldFactory('REACT.APP.AGENT_SETTINGS.NEW_PASSWORD');

        field.addRule({
            rule: ValidationRules.required(),
            message: this.translate('REACT.APP.AGENT_SETTINGS.NEW_PASSWORD_REQUIRED')
        });

        this.passwordValidationRules(field);

        field.setDisabled(true);

        this.addField(this.fieldNames.newPassword, field);
    }

    createConfirmPasswordField(): void {
        const field = this.fieldFactory('REACT.APP.AGENT_SETTINGS.CONFIRM_PASSWORD');

        field.addRule({
            rule: ValidationRules.required(),
            message: this.translate('REACT.APP.AGENT_SETTINGS.CONFIRM_PASSWORD_REQUIRED')
        });

        this.passwordValidationRules(field);

        field.setDisabled(true);

        this.addField(this.fieldNames.confirmPassword, field);
    }

    getFormValues(): AgentSettingsInfo {
        const info = new AgentSettingsInfo();
        const infoProps = Object.getOwnPropertyNames(info);

        Object.getOwnPropertyNames(this.fieldNames).forEach((field) => {
            if (!infoProps.indexOf(field)) {
                console.error(`${field} not exists on InviteMethodInfo type`);
            } else if (this.fields[field]) {
                (info as any)[field] = this.fields[field].value as string;
            }
        });

        return info;
    }

    // TODO: move password creation into ui-common package
    private passwordValidationRules(field: IFieldModel) {
        if (this.passwordSettings.allowCustomPasswordPolicy) {
            this.createPasswordRules(field, {
                requireDigit: this.passwordSettings.requireDigit,
                requireUppercase: this.passwordSettings.requireUppercase,
                requireSpecial: this.passwordSettings.requireSpecial
            });

            if (this.passwordSettings.minLength) {
                field.addRule({
                    rule: ValidationRules.minLength(this.passwordSettings.minLength),
                    message: this.translate('REACT.APP.AGENT_SETTINGS.PASSWORD_MINLENGTH', {
                        length: this.passwordSettings.minLength
                    })
                });
            }
        } else {
            field.addRule({
                rule: ValidationRules.password(),
                message: this.translate('REACT.APP.AGENT_SETTINGS.PASSWORD_PATTERN')
            });
        }
    }

    private createPasswordRules(field: IFieldModel, options: PasswordOptions) {
        const regexps = [];
        const messages = [];
        const t = this.translate;

        if (options.requireDigit) {
            regexps.push(/(?=.*\d)/);
            messages.push(t('REACT.APP.AGENT_SETTINGS.PASSWORD_REQUIRE_DIGIT'));
        }

        if (options.requireUppercase) {
            regexps.push(/(?=.*[A-Z])/);
            messages.push(t('REACT.APP.AGENT_SETTINGS.PASSWORD_REQUIRE_UPPERCASE'));
        }

        if (options.requireSpecial) {
            regexps.push(/(?=.*[~`!#$€@()%\^&*+=\-\[\]\\';,\/\\{\\}\u007C\\"\u003A<>\?])/);
            messages.push(t('REACT.APP.AGENT_SETTINGS.PASSWORD_REQUIRE_SPECIAL'));
        }

        if (regexps.length) {
            regexps.push(/.*$/);

            const finalRule = new RegExp(regexps.map((r) => r.source).join(''));
            const finalMessage = `${t('REACT.APP.AGENT_SETTINGS.PASSWORD_REQUIRE_PHRASE')} ${makeHumanReadableSet(
                messages,
                ',',
                t('REACT.COMMON.PHRASES.AND')
            )}`;

            field.addRule({
                rule: ValidationRules.regexp(finalRule),
                message: finalMessage
            });
        }
    }
}
