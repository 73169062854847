import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import get from 'lodash/get';

import tsDeviceClassificationView from './ts-device-classification.view.html';
import tsSmartAnalysisView from './ts-smart-analysis.view.html';
import tsDeviceClassificationViewSmartAssist from './ts-device-classification-smart-assist.view.html';
import './ts-device-classification.style.scss';
import {
    SpinnerBackgroundColors,
    SpinnerColors,
    SpinnerSizes,
    SpinnerTypeOptions
} from '@techsee/techsee-ui-common/lib/spinner';
import {compareUnderThreshold} from '@techsee/techsee-common/lib/utils';

class TsDeviceClassificationController {
    constructor(
        $scope,
        $rootScope,
        $window,
        tsLocaleHelper,
        tsBusy,
        tsDeviceClassificationService,
        ErrorDialogModal,
        eventLog,
        tsChatApi
    ) {
        'ngInject';

        this.eventLog = eventLog;
        this.service = tsDeviceClassificationService;
        this.spinnerOptions = {
            type: SpinnerTypeOptions.CIRCULAR,
            backgroundColor: SpinnerBackgroundColors.Transparent,
            size: SpinnerSizes.Large,
            color: SpinnerColors.LightBlue
        };

        $scope.$watch('vm.imageData', this._handleImageDataChange.bind(this));

        $scope.$watch('service.recognizedDevice', () => {
            this.service.recognizedDevice && this.onDeviceRecognized({device: this.service.recognizedDevice});
        });

        $scope.$watch('service.isAnalysisVisible', () => {
            if (this.service.isAnalysisVisible) {
                this.service.selectAnalysisDevice();
            }
        });

        $scope.$on('$destroy', () => this.service.componentDidUnmount());

        this._busy = tsBusy;
        this._$window = $window;
        this.errorDialogModal = ErrorDialogModal;
        this.tsSmartAnalysisView = tsSmartAnalysisView;
        this.tsDeviceClassificationView = tsDeviceClassificationView;
        this.dir = tsLocaleHelper.isRTL() ? 'rtl' : 'ltr';
        this.requireImage = $rootScope.requireImage;
        this.detectionInProgress = false;
        this.isInManualFlow = false;
        this.showLedAnalysis = true;
        this.tsChatApi = tsChatApi.service;
    }

    onSelectDeviceClick() {
        if (this.onDeviceSelected) {
            this.onDeviceSelected();
        }

        this.service.deviceListOpen();
    }

    onAnalyzeClickInternal() {
        this.isInManualFlow = true;
        this.service.selectDevice(null);
        this.onAnalyzeClick();
    }

    onCustomAnalysisClickInternal() {
        this.ledAnalysisError = false;
        this.eventLog.analyzeStart();
        this.onCustomAnalysisClick();
    }

    isButtonsDisabled() {
        return this.detectionInProgress || this.isInManualFlow;
    }

    getContainerStyle() {
        const topBarHeight = 100; //top bar height + some spacing
        const meetingModesHeight = 140;
        const bottomBarHeight = 60; //bottom bar height + some spacing
        const sectionTitle = 45; //title height + some spacing
        const sectionButtons = 55; //buttons height + some spacing

        const reservedSpace = topBarHeight + meetingModesHeight + bottomBarHeight + sectionTitle + sectionButtons;
        const maxHeight = this._$window.innerHeight - reservedSpace;

        if (this.previousMaxHeight && !compareUnderThreshold(this.previousMaxHeight, maxHeight)) {
            return {
                'max-height': this.previousMaxHeight
            };
        }

        this.previousMaxHeight = maxHeight;

        return {
            'max-height': maxHeight
        };
    }

    onIssueClicked(issue) {
        this.service.solutionOpen(issue.issueId);
        this.onCommonIssueOpen({issue: issue});
    }

    _handleImageDataChange(imageData) {
        if (imageData && imageData.customAnalysis) {
            this.analysisResults.solution = [];
            this.analysisLoader = true;
            this._customAnalysis(imageData);

            return;
        }

        if (this.service.isCustomAnalysis && this.currentImageData === imageData) {
            return;
        }

        if (this.detectionInProgress) {
            return;
        }

        const setNotInProgress = () => {
            this.isInManualFlow = false;
            this.detectionInProgress = false;
            this._busy.free();
        };

        if (isEmpty(imageData) || !imageData.imgData) {
            setNotInProgress();

            return;
        }

        if (
            !this.service.isAutoClassifyEnabled &&
            imageData.eventSource !== this.availableEventSources.MANUAL_ANALYZE
        ) {
            setNotInProgress();

            return;
        }

        this.hideSolutionModal();
        this.currentImageData = imageData;
        this.detectionInProgress = true;
        this._busy.busy();
        this.service.setRecognizedDevice(null);

        this.service
            .classifyDevice(imageData)
            .then(this._processAnalyzeResults.bind(this, imageData))
            .finally(setNotInProgress);
    }

    _processAnalyzeResults(imageData, analysisData) {
        const analysisResults = get(analysisData, 'result.analysisResults');

        this.showCustomAnalysisPanel();

        if (this.service.isCustomAnalysis) {
            this.ledAnalysisError = false;
            if (isEmpty(analysisResults) || analysisResults[0].deviceId === 'None') {
                this.service.displayCustomPanel = !!this.analysisResults;
                this.errorDialogModal.show("Can't recognize the device");
                this.eventLog.recognizeFail({imageData});

                return;
            }

            this.service.displayCustomPanel = true;
            this.analysisResults = analysisResults[0];
            this.eventLog.recognizeSuccess({imageData, analysisResults: this.analysisResults});
            this.isNestDevice = analysisResults[0].deviceName === 'nest';

            this.showLedAnalysis = !this.isNestDevice;
            if (this.isNestDevice) {
                this._customAnalysis(imageData);
            }

            return;
        } else if (isEmpty(analysisResults)) {
            return;
        }

        analysisData.result.analysisResults = orderBy(analysisResults, 'confidenceLevel', 'desc');
        this.service.setRecognizedDevice(analysisData.result.analysisResults[0]);
    }

    _customAnalysis(imageData) {
        if (imageData) {
            this.service
                .customAnalysis(this.analysisResults, imageData.imgData)
                .then((response) => {
                    if (!response) {
                        this.ledAnalysisError = true;

                        return;
                    }

                    this.eventLog.analyzeSuccess({response});
                    this.analysisResults.solution = this.isNestDevice ? response : response.solution;
                    this.analysisResults.ledAnalysis = this.isNestDevice ? {} : response.ledAnalysis;
                })
                .finally(() => {
                    this.analysisLoader = false;
                });
        }
    }

    onSolutionClickInternal(solution) {
        this.onSolutionClick({solution: solution});
    }
}

export function tsDeviceClassification() {
    return {
        template: tsDeviceClassificationViewSmartAssist,
        scope: {},
        replace: true,
        controller: TsDeviceClassificationController,
        controllerAs: 'vm',
        bindToController: {
            imageData: '=',
            availableEventSources: '=',
            onCommonIssueOpen: '&',
            onAnalyzeClick: '&',
            hideSolutionModal: '&',
            onCustomAnalysisClick: '&',
            showCustomAnalysisPanel: '&',
            onDeviceRecognized: '&',
            onDeviceSelected: '&',
            onSolutionClick: '&'
        }
    };
}
