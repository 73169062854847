'use strict';

import PatternsConstant from '@techsee/techsee-common/lib/constants/utils.patterns';
import {RolesConstant} from '@techsee/techsee-common/lib/constants/account.constants';
import {EventLogConstant} from './events/event-log.constant';
import STATUS_MESSAGES from '@techsee/techsee-common/lib/constants/status-messages.constant';
import {mediaFrameUIParams} from './media-frame-ui-params/media-frame-ui-params.constant';

export default angular
    .module('app.constant', [])
    .constant('patternsConstant', PatternsConstant)
    .constant('ROLES', RolesConstant)
    .constant('LogEvents', EventLogConstant)
    .constant('STATUS_MESSAGES', STATUS_MESSAGES)
    .constant('mediaFrameUIParams', mediaFrameUIParams);
