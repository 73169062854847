'use strict';

import get from 'lodash/get';
import find from 'lodash/find';

import './change-settings.style.scss';

export class ChangeAccountController {
    constructor($modalInstance, db, currentUser, accountData, $localStorage, auth, tsUrlUtils, tsStateHelper, dir) {
        'ngInject';

        this.$modalInstance = $modalInstance;
        this.db = db;
        this.auth = auth;
        this.stateHelper = tsStateHelper;
        this.tsUrlUtils = tsUrlUtils;
        this.$localStorage = $localStorage;
        this.accountData = accountData;
        this.dir = dir;
        this.data = angular.copy(currentUser);
        this.accountId = this.data.accountId;
        this.multiUserAccounts = [];
        this.parentSubUserId = get(this.data, 'parentSubUserId');

        this._getMultiUserAccounts();
    }

    _getMultiUserAccounts() {
        this.db.User.find(this.parentSubUserId, {bypassCache: true}).then((user) => {
            this.multiUserAccounts = user.accounts;
            find(this.multiUserAccounts, (account) => account._id === this.accountId).selected = true;
        });
    }

    /**
     * Handle modal exit (e.g. `cancel` button, ESC press, etc.)
     */
    cancel() {
        this.$modalInstance.dismiss('cancel');
    }

    submit(form) {
        if (form.$invalid) {
            return;
        }

        if (!this.selectedMultiAccount || this.selectedMultiAccount === this.accountId) {
            return this.cancel();
        }

        return this.db.Auth.loginSubUser({
            data: {
                accountId: this.selectedMultiAccount
            }
        }).then((token) => {
            this.auth.setAuthToken(token.data);

            return this.$modalInstance.close({reload: true});
        });
    }
}
