import React from 'react';
import {inject, IReactComponent, observer} from 'mobx-react';
import {CollapsiblePanelAnimated, ExpandState} from '@techsee/techsee-ui-common/lib/collapsible-panel-animated';
import {Stores} from '../../MultipartyDashboardStores';
import {GalleryController, Image} from './GalleryController';
import emptyGalleryPlaceholderImage from '../../../../../../img/empty-gallery-mini.png';
import loader from '../../../../../../img/dots-spinner-white.gif';

import './GalleryMini.scss';
import failedImagePlaceholder from '../../../../../../img/failed-image-placeholder.png';

interface GalleryMiniStoreProps {
    controller: GalleryController;
    translate: (key: string) => string;
    isMobileView?: boolean;
}

const Loader: React.FC<{id: string; classes: string}> = ({id, classes}) => (
    <div key={id} className={`gallery-image-mini-spinner ${classes}`}>
        <img className={'img'} src={loader} alt='Loading' />
    </div>
);

const mapImages = (image: Image, isMobileView: boolean) => {
    const mobileViewClass = isMobileView ? 'gallery-image-mini-mobile' : '';

    if (image.isLoading) {
        return <Loader key={image.id} id={image.id} classes={`${mobileViewClass}`} />;
    }
    if (image.isFailed) {
        return (
            <div key={image.id} className='failed-image-mini'>
                <img src={failedImagePlaceholder} alt='Failed image' />
            </div>
        );
    }

    return <img src={image.image} key={image.id} className={`gallery-image-mini ${mobileViewClass}`} alt='Image' />;
};

const GalleryComponent: React.FC<GalleryMiniStoreProps> = observer(({translate, controller, isMobileView}) => {
    const title = (
        <div className={`header ${!isMobileView ? 'header-desktop' : 'header-mobile'}`}>
            {translate('HISTORY.VIEW.IMAGES')}
        </div>
    );

    const emptyGalleryPlaceholder = (
        <div className={`empty-gallery-mini-wrapper ${isMobileView ? 'empty-gallery-mini-wrapper-mobile' : ''}`}>
            <img src={emptyGalleryPlaceholderImage} alt='No images yet' />
            <div className='empty-gallery-mini-text'>{translate('MAIN.VIEW.NO_IMAGES_YET')}</div>
        </div>
    );

    let images: JSX.Element | JSX.Element[] = controller.images
        .map((image) => mapImages(image, isMobileView as boolean))
        .reverse();

    images = isMobileView ? <div className={'gallery-images-list-mini-mobile-wrapper'}>{images}</div> : images;

    return (
        <CollapsiblePanelAnimated
            className='gallery-images-list-mini'
            title={title}
            expandState={ExpandState.ALWAYS_EXPANDED}
            unstyledHeader>
            {controller.images.length === 0 ? emptyGalleryPlaceholder : images}
        </CollapsiblePanelAnimated>
    );
});

const mapStoresToProps = (stores: Stores): GalleryMiniStoreProps => ({
    translate: stores.localizationService.translate,
    controller: stores.galleryController
});

export const GalleryMini = inject(mapStoresToProps)(GalleryComponent) as IReactComponent;
