import React from 'react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {SimpleLabel} from '@techsee/techsee-ui-common/lib/forms/labels/simple';
import {IRedirectToStoreController} from './redirectToStore.controller';

import './redirectToStore.style.scss';

interface TsRedirectToStoreProps {
    controller: IRedirectToStoreController;
}

export class TsRedirectToStore extends TechseeBaseComponent<TsRedirectToStoreProps> {
    renderInternal(): JSX.Element {
        const {controller} = this.props;
        let classLabelStyle = '';
        let appNotInstallImg = 'app-not-install.png';
        const clientOs = controller.isIos ? 'ios' : 'android';
        const mainLabel = controller.invalidVersion
            ? 'REACT.DASHBOARD.VIEW.APP_INVALID_VERSION_LABEL'
            : 'REACT.DASHBOARD.VIEW.APP_NOT_INSTALLED_LABEL';
        let subLabel = controller.isIos
            ? 'REACT.DASHBOARD.VIEW.APP_NOT_INSTALLED_IOS'
            : 'REACT.DASHBOARD.VIEW.APP_NOT_INSTALLED_ANDROID';

        if (controller.invalidVersion) {
            subLabel = 'REACT.DASHBOARD.VIEW.APP_INVALID_VERSION_SUB_LABEL';
        }

        if (!controller.useNewInterface) {
            classLabelStyle = 'old-ui';
            appNotInstallImg = 'app-not-install-old.png';
        }

        return (
            <div className={'redirect-to-store-container'}>
                <img className={'img-app-not-install'} src={controller.domUtilsService.loadImage(appNotInstallImg)} />
                <SimpleLabel dir={controller.dir} className={classLabelStyle}>
                    {controller.translate(mainLabel)}
                </SimpleLabel>
                <SimpleLabel dir={controller.dir} className={classLabelStyle + ' bold-simple-label'}>
                    {controller.translate(subLabel)}
                </SimpleLabel>
                {controller.invalidVersion ? (
                    <React.Fragment />
                ) : (
                    <div className={'img-guidance-container'}>
                        <img
                            className={'img-guidance img-guidance-margin-right'}
                            src={controller.domUtilsService.loadImage(`redirect-store_${clientOs}1.png`)}
                        />
                        <div className={'img2-container'}>
                            <img
                                className={'img-guidance'}
                                src={controller.domUtilsService.loadImage(`redirect-store_${clientOs}2.png`)}
                            />
                            <div className={`wait-rectangle ${clientOs}`}>
                                <span>{controller.translate('REACT.DASHBOARD.VIEW.WAIT')}</span>
                            </div>
                        </div>
                        <img
                            className={'img-guidance img-guidance-margin-left'}
                            src={controller.domUtilsService.loadImage(`redirect-store_${clientOs}3.png`)}
                        />
                    </div>
                )}
            </div>
        );
    }
}
