import get from 'lodash/get';

import InviteView from './invite-root.view.html';
import InviteRootController from './invite-root.controller';
import InviteNewModule from '../../_react_/states/invite-new/invite.module';
import InviteOldModule from './invite-old/invite.module';
import {MATOMO_ANALYTICS} from '@techsee/techsee-common/lib/analytic-scripts/matomo-analytics';
import {PromiseUtilsService} from '@techsee/techsee-client-services/lib/services/PromiseUtilsService';
import {getAppTracer} from '../../app.tracer';

const trace = getAppTracer('invite-root.module');
const inviteRootView = {
    template: InviteView,
    controller: InviteRootController,
    controllerAs: 'inviteRoot'
};

export default angular.module('states.invite', [InviteNewModule.name, InviteOldModule.name]).config(config);

function config($stateProvider) {
    'ngInject';

    $stateProvider.state('invite', {
        url:
            ROUTE_BASE_PATH +
            'invite?authId&r&p&c&is&roomId&g&roomCode&audio&observe&token&sf&aid&ott&error&emb&mini&tablet&sw&prg&startDate&endDate',
        views: {
            'desktop-view': inviteRootView,
            'mobile-view': inviteRootView
        },
        onEnter: ($rootScope, accountData, tsExtraLoggingService, eventLog, tsUrlUtils) => {
            tsExtraLoggingService.init(accountData, eventLog);
            const enableMatomoAnalytics = get(accountData, 'protectedSettings.enableMatomoAnalytics');

            if (enableMatomoAnalytics) {
                $rootScope.loadScript(MATOMO_ANALYTICS.TRACKING);
                $rootScope.loadScript(MATOMO_ANALYTICS.FORM_ANALYTICS);
            }

            const roomId = tsUrlUtils.getParamValue('roomId'),
                g = tsUrlUtils.getParamValue('g'),
                roomCode = tsUrlUtils.getParamValue('roomCode'),
                room = roomId || g || roomCode;

            return PromiseUtilsService.startPromiseWithTimeout(
                () => trace.info(`invite module has loaded! account: ${accountData._id.toString()} - room: ${room}`),
                3000
            );
        },
        resolve: {
            currentUser: InviteRootController.currentUser,
            accountData: InviteRootController.accountData
        }
    });
}
