'use strict';

import cloneDeep from 'lodash/cloneDeep';
import last from 'lodash/last';
import findLast from 'lodash/findLast';

const MAX_HISTORY_SIZE = 10;

export class CanvasHistory {
    constructor() {
        this.history = [];
    }

    /*
     * Ties history to a fabric Canvas
     *
     * @param canvas {Object} fabric.js canvas
     */
    init(canvas) {
        this.canvas = canvas;
        this.reset();
    }

    reset() {
        this.history.length = 0;
        this.save();
    }

    isEmpty() {
        return this.history.length <= 1;
    }

    save(options = {}) {
        if (!this.undoFlag) {
            const data = cloneDeep(this.canvas.toDatalessJSON(['groupId', 'hasControls']));

            if (options.callback) {
                this.push({
                    callback: options.callback,
                    data: data
                });
            } else {
                this.push({data});
            }
        }
    }

    undo(callback) {
        if (this.history.length > 1) {
            this.undoFlag = true;
            const current = this.history.pop();

            if (current.callback) {
                current.callback(() => (this.undoFlag = false));
            } else {
                this.canvas.loadFromDatalessJSON(last(this.history).data, () => {
                    this.undoFlag = false;
                    callback();
                    this.canvas.renderAll();
                });
            }
        }
    }

    reload(callback) {
        if (this.history.length >= 1) {
            this.undoFlag = true;
            const lastDataState = findLast(this.history, (entry) => !!entry.data);

            this.canvas.loadFromDatalessJSON(lastDataState.data, () => {
                let index = this.history.length - 1;
                let lastState = this.history[index];

                while (lastState.callback) {
                    lastState.callback();
                    index--;
                    lastState = this.history[index];
                }

                this.undoFlag = false;
                callback();
                this.canvas.renderAll();
            });
        }
    }

    push(entry) {
        if (this.history.length === MAX_HISTORY_SIZE + 1) {
            this.history.shift();
        }
        this.history.push(entry);
    }
}
