import {calculateSizeWithRatio, SizeWithRatioParams} from './size-with-ratio';
import {VideoPositioningStrategy, VideoSizeResult} from './abstractions';

export class StretchedVideoStrategy extends VideoPositioningStrategy {
    constructor(annotationCanvas, window, _domQuery) {
        super();

        this._annotationCanvas = annotationCanvas;
        this._window = window;
        this._domQuery = _domQuery;
    }

    getVideoNewSize(videoMeta) {
        const {maxWidth, maxHeight} = this._getSizeLimits();
        const calcParams = new SizeWithRatioParams(maxWidth, maxHeight, videoMeta.isPortrait, videoMeta.aspectRatio);
        const {newWidth, newHeight} = calculateSizeWithRatio(calcParams);
        const newSize = new VideoSizeResult(newWidth, newHeight);

        return newSize;
    }

    updateVideoSize(elVideo, videoNewSize, videoMeta) {
        super.updateVideoSize(elVideo, videoNewSize, videoMeta);
        this._annotationCanvas.get(0).width = videoMeta.streamWidth;
        this._annotationCanvas.get(0).height = videoMeta.streamHeight;
        this._annotationCanvas.css('width', videoNewSize.width);
        this._annotationCanvas.css('height', videoNewSize.height);
        this._annotationCanvas.css('margin-left', 0);
    }

    updateVideoLocation(videoSize, elContainer, videoWasManuallyMoved) {
        super.updateVideoLocation(videoSize, elContainer, videoWasManuallyMoved);

        elContainer.css({
            left: 0,
            right: 'auto',
            top: 0,
            bottom: 'auto',
            display: '',
            width: ''
        });
    }

    _getSizeLimits() {
        const mainContainer = this._domQuery('.dashboard-main-content-container');

        return {
            maxWidth: this._getMaxWidth(mainContainer),
            maxHeight: this._getMaxHeight(mainContainer)
        };
    }

    _getMaxHeight(mainContainer) {
        const oldFooter = this._domQuery('.dashboard-bottom-bar');
        const topBar = this._domQuery('.app-top-bar');
        const windowHeight = this._window.innerHeight;
        const topBarHeight = topBar.length > 0 ? 90 : 10;
        const bottomBarHeight = oldFooter.length > 0 ? 55 : 0;
        const mainContainerPadding = parseInt(mainContainer.css('padding-bottom'), 10);

        return windowHeight - topBarHeight - bottomBarHeight - mainContainerPadding;
    }

    _getMaxWidth(mainContainer) {
        return mainContainer.width();
    }
}
