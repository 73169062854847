import {SessionHistorySettings} from '../../models/AccountSettings';
import {SearchFields} from '@techsee/techsee-common/lib/constants/session-history.constants';
import {getRootStore} from '../../app.bootstrap';
import {IInviteFlowManager} from '../../states/invite-new/_contracts/InviteContracts';
import {IDbHistory, IDbVideoRecordings} from '../../services/AngularServices/AngularServices';
import {IRedirectionService} from '../../services/RedirectionService';
import {GenericFormController, IGenericFormController} from '../common-forms/controller';
import {BasicFormModel} from '../common-forms/form-model';

export interface ISessionHistoryController {
    searchSessionHistory(): Promise<boolean | void>;
    onHistorySessionSearch(event: any): void;

    formController: IGenericFormController;
}

export class SessionHistoryController implements ISessionHistoryController {
    readonly formController: IGenericFormController;

    private _dbHistoryService: IDbHistory;

    private _dbVideoRecordingsService: IDbVideoRecordings;

    private formModel: BasicFormModel;

    private accountId: string;

    private _redirectionService: IRedirectionService;

    constructor(
        flowManager: IInviteFlowManager,
        accSettings: SessionHistorySettings,
        dbHistoryService: IDbHistory,
        dbVideoRecordingsService: IDbVideoRecordings,
        accountId: string,
        redirectionService: IRedirectionService
    ) {
        const customerIdRules = {
            minLength: accSettings.customerIdValidationRules.minLength,
            maxLength: accSettings.customerIdValidationRules.maxLength
        };

        this.formModel = new BasicFormModel(customerIdRules, false, flowManager.translate);
        this.formController = new GenericFormController(
            flowManager.translate,
            accSettings,
            this.formModel,
            this.onHistorySessionSearch.bind(this),
            {
                enablePhoneNumberField: accSettings.keepCustomerMobile,
                enableCustomerRefField: true,
                enableEmailField: false
            }
        );

        this._dbHistoryService = dbHistoryService;
        this._dbVideoRecordingsService = dbVideoRecordingsService;
        this._redirectionService = redirectionService;
        this.accountId = accountId;
    }

    onHistorySessionSearch(event: any) {
        event && event.preventDefault();

        if (!this.formController || this.formController.isLoading) {
            return;
        }

        const customerId = this.formController.formCustomerId;
        const phoneNumberValue = this.formController.formPhoneNumberValue;

        if (!customerId && !phoneNumberValue) {
            this.formController.validateForm();

            return;
        }

        this.formController.setIsLoading(true);

        if (
            (customerId && this.formController.selectedField === SearchFields.customerRef) ||
            (phoneNumberValue && this.formController.selectedField === SearchFields.phoneNumber)
        ) {
            this.searchSessionHistory()
                .then((dataFound) => {
                    this.formController.setFormError(!dataFound);
                })
                .catch(() => {
                    this.formController.setIsLoading(false);
                });
        } else {
            this.formController.setFormError(true);
            this.formController.setIsLoading(false);
        }
    }

    searchSessionHistory(): Promise<boolean | void> {
        const customerId =
            this.formController.selectedField === SearchFields.customerRef ? this.formController.formCustomerId : '';
        const phoneNumberValue =
            this.formController.selectedField === SearchFields.phoneNumber
                ? this.formController.formPhoneNumberValue
                : '';

        const promises = [];

        if (customerId && this.formController.selectedField === SearchFields.customerRef) {
            promises.push(
                this._dbHistoryService.byCustomerId({
                    params: {
                        customerId,
                        accountId: this.accountId
                    }
                }),
                this._dbVideoRecordingsService.byCustomerId({
                    params: {
                        customerId
                    }
                })
            );
        } else if (phoneNumberValue && this.formController.selectedField === SearchFields.phoneNumber) {
            promises.push(
                this._dbHistoryService.byCustomerNumber({
                    params: {
                        customerNumber: phoneNumberValue,
                        accountId: this.accountId
                    }
                }),
                this._dbVideoRecordingsService.byCustomerNumber({
                    params: {
                        customerNumber: phoneNumberValue
                    }
                })
            );
        }

        return Promise.all(promises).then(([recordHistory, videoRecordingHistory]) => {
            const {files, images, messages, records, videos} = recordHistory.data;
            const {browserUtilsService} = getRootStore();
            const recordingHistory = videoRecordingHistory.data;

            if (
                files ||
                images ||
                messages ||
                records.length ||
                videos ||
                (recordingHistory && recordingHistory.length)
            ) {
                const byCustomerHistory = records.some((record: any) => record.customerId === customerId);
                const byCustomerRecording = recordingHistory.some((record: any) => record.customerId === customerId);

                browserUtilsService.saveToSessionStorage('sessionHistory', recordHistory.data);
                browserUtilsService.saveToSessionStorage('videoRecordingsHistory', recordingHistory);

                if (customerId && (byCustomerHistory || byCustomerRecording)) {
                    this._redirectionService.goToHistoryByCustomerRef(customerId);
                } else if (phoneNumberValue) {
                    this._redirectionService.goToHistoryByPhoneNumber(phoneNumberValue);
                }

                this.formController.setIsLoading(false);

                return true;
            }

            this.formController.setIsLoading(false);

            return false;
        });
    }
}
