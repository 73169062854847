'use strict';
import tsVideoPauseView from './ts-video-pause.view.html';
import './ts-video-pause.style.scss';

export function tsVideoPause() {
    return {
        template: tsVideoPauseView,
        scope: true
    };
}
