import {IDbSmart} from './AngularServices';

export class DbSmartAdapter implements IDbSmart {
    private _dbService: any;

    constructor(db: any) {
        this._dbService = db;
    }

    solutions(issueId: string): Promise<any> {
        return this._dbService.Smart.solutions(issueId);
    }
}
