import React from 'react';
import {observer} from 'mobx-react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {PrimaryButton} from '@techsee/techsee-ui-common/lib/forms/buttons/primary';
import {Icon} from '@techsee/techsee-ui-common/lib/icons/simple-icon';
import {TermsStepController} from './controller';

@observer
export class TermsStepToolbar extends TechseeBaseComponent<{ctrl: TermsStepController}> {
    renderInternal(): JSX.Element {
        const {translate, displayRepromptControls} = this.props.ctrl;

        return (
            <>
                {displayRepromptControls && (
                    <PrimaryButton onClick={this.props.ctrl.repromptForTos}>
                        {translate('REACT.INVITE.PERMISSIONS.TERMS.REPROMPT_BTN')}
                        <Icon iconName={'arrow-next'} />
                    </PrimaryButton>
                )}
            </>
        );
    }
}
