import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {Button} from 'react-bootstrap';

export interface IAccountSwitchIcon {
    text: string;
}

@observer
export class AccountSwitchIcon extends Component<IAccountSwitchIcon> {
    render() {
        const {text} = this.props;

        return (
            <div className='account-switch-icon'>
                <Button className='button-style section-item use-common-styles'>{text}</Button>
            </div>
        );
    }
}
