import {TechseeFormBase} from '@techsee/techsee-ui-common/lib/forms/_shared/TechseeFormBase';
import {ValidationRules} from '@techsee/techsee-ui-common/lib/forms/_shared/ValidationRules';
import {AddressTypesEnum} from '@techsee/techsee-common/lib/constants/room.constants';
import {InviteMethodSettings} from '../../../../../models/AccountSettings';
import {ITranslate} from '../../../../../services/LocalizationService';
import {IFieldFactory} from '../../../../../app.contracts';
import {InviteMethodInfo} from '../../../../../models/LiveSessionState';

const patterns = require('@techsee/techsee-common/lib/constants/utils.patterns.js');

export interface InviteFieldNames {
    customerRef: string;
    address: string;
    selectedLanguage: string;
    countryCode: string;
    phoneNumber: string;
    visualJourney: string;
    referralRegion: string;
    coBrowsingInitialUrl: string;
}

export class InviteMethodForm extends TechseeFormBase {
    private _accountSettings: InviteMethodSettings;

    private _translate: ITranslate;

    private _fieldFactory: IFieldFactory;

    constructor(accSettings: InviteMethodSettings, translate: ITranslate, fieldFactory: IFieldFactory) {
        super();

        this._accountSettings = accSettings;
        this._translate = translate;
        this._fieldFactory = fieldFactory;
    }

    get fieldNames(): InviteFieldNames {
        return {
            customerRef: 'customerRef',
            address: 'address',
            selectedLanguage: 'selectedLanguage',
            countryCode: 'countryCode',
            phoneNumber: 'phoneNumber',
            visualJourney: 'visualJourney',
            referralRegion: 'referralRegion',
            coBrowsingInitialUrl: 'coBrowsingInitialUrl'
        };
    }

    getFormValues(): InviteMethodInfo {
        const info = new InviteMethodInfo();
        const infoProps = Object.getOwnPropertyNames(info);

        Object.getOwnPropertyNames(this.fieldNames).forEach((field) => {
            if (!infoProps.indexOf(field)) {
                console.error(`${field} not exists on InviteMethodInfo type`);
            } else if (this.fields[field]) {
                (info as any)[field] = this.fields[field].value as string;
            }
        });

        return info;
    }

    createCustomerRefField(): void {
        const customerRef = this._fieldFactory('REACT.INVITE.VIEW.CUSTOMER_REF.LABEL');

        customerRef.addRule({
            rule: ValidationRules.required(),
            message: this._translate('REACT.INVITE.VIEW.ID_REQUIRED')
        });

        customerRef.addRule({
            rule: ValidationRules.minLength(this._accountSettings.customerIdValidationRules.minLength),
            message: this._translate('REACT.INVITE_METHOD.VALIDATIONS.CUSTOMER_ID.MIN_LENGTH', {min: ':min'})
        });

        customerRef.addRule({
            rule: ValidationRules.maxLength(this._accountSettings.customerIdValidationRules.maxLength),
            message: this._translate('REACT.INVITE_METHOD.VALIDATIONS.CUSTOMER_ID.MAX_LENGTH', {max: ':max'})
        });

        this.addField(this.fieldNames.customerRef, customerRef);
    }

    createCoBrowsingInitialUrlField(): void {
        const coBrowsingInitialUrl = this._fieldFactory('REACT.INVITE.VIEW.SELECT_CO_BROWSING_URL');

        coBrowsingInitialUrl.addRule({
            rule: ValidationRules.required(),
            message: this._translate('INVITE.CONTROLLER.EMPTY_COBROWSING_URL')
        });

        coBrowsingInitialUrl.addRule({
            rule: ValidationRules.regexp(patterns.coBrowsingStartUrl),
            message: this._translate('INVITE.CONTROLLER.INVALID_COBROWSING_URL')
        });

        this.addField(this.fieldNames.coBrowsingInitialUrl, coBrowsingInitialUrl);
    }

    createAddressField(countryCodeEnabled: boolean, addressType: AddressTypesEnum): void {
        let address = this._fieldFactory('REACT.INVITE.VIEW.ADDRESS.LABEL');

        if (addressType === AddressTypesEnum.EMAIL) {
            address = this._fieldFactory('REACT.INVITE.VIEW.EMAIL_ADDRESS.LABEL');
            address.addRule({
                rule: ValidationRules.required(),
                message: this._translate('REACT.INVITE.VIEW.EMAIL.ERROR.EMAIL_REQUIRED')
            });

            address.addRule({
                rule: ValidationRules.email(),
                message: this._translate('REACT.INVITE.VIEW.EMAIL.ERROR.EMAIL_VALIDATION')
            });
            this.addField(this.fieldNames.address, address);

            return;
        }

        const wrongNumMsg = this._translate('REACT.INVITE.VIEW.PHONE_NUM.ERROR.INCORRECT_NUMBER', {min: ':min'});
        const phoneField = this._fieldFactory();
        const countryCodeField = this._fieldFactory();

        phoneField.addRule({
            rule: ValidationRules.required(),
            message: this._translate('REACT.INVITE.VIEW.PHONE_NUM.ERROR.NUMBER_REQUIRED')
        });

        countryCodeField.addRule({
            rule: ValidationRules.required(),
            message: this._translate('REACT.INVITE.VIEW.PHONE_NUM.ERROR.COUNTRY_CODE_REQUIRED')
        });

        address.addRule({
            rule: ValidationRules.required(),
            message: ''
        });

        phoneField.addRule({rule: ValidationRules.minLength(6), message: wrongNumMsg});
        phoneField.addRule({rule: ValidationRules.phoneNumber(), message: wrongNumMsg});

        this.addField(this.fieldNames.address, address);
        this.addField(this.fieldNames.phoneNumber, phoneField);

        if (countryCodeEnabled) {
            this.addField(this.fieldNames.countryCode, countryCodeField);
        }
    }

    createLanguageField(): void {
        const selectedLanguage = this._fieldFactory('REACT.INVITE.VIEW.SELECT_LANGUAGE');

        selectedLanguage.addRule({
            rule: ValidationRules.required(),
            message: this._translate('REACT.INVITE.VIEW.SELECT_LANGUAGE.ERROR.REQUIRED')
        });

        selectedLanguage.setReadonly(true);
        this.addField(this.fieldNames.selectedLanguage, selectedLanguage);
    }

    createVisualJourneyField(): void {
        const visualJourney = this._fieldFactory('REACT.INVITE.VIEW.SELECT_VISUAL_JOURNEY');

        visualJourney.addRule({
            rule: ValidationRules.required(),
            message: this._translate('REACT.INVITE.VIEW.VISUAL_JOURNEY.ERROR.REQUIRED')
        });

        visualJourney.setReadonly(true);
        this.addField(this.fieldNames.visualJourney, visualJourney);
    }

    createReferralRegionField(): void {
        const referralRegion = this._fieldFactory('REACT.INVITE.VIEW.SELECT_REFERRAL_REGION');

        referralRegion.setReadonly(true);
        this.addField(this.fieldNames.referralRegion, referralRegion);
    }
}
