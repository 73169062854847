'use strict';
import tsResourceCheckboxView from './ts-resource-checkbox.view.html';
import './ts-resource-checkbox.style.scss';

class TsResourceCheckboxController {
    constructor($scope, tsSharingService) {
        'ngInject';

        this.checkboxId = tsSharingService.uuidv4();
    }

    onClick() {
        if (this.onCheckboxClicked) {
            this.onCheckboxClicked({
                resource: this.resource,
                resourceType: this.resourceType
            });
        }
    }

    checked() {
        return this.isChecked({resource: this.resource, resourceType: this.resourceType});
    }
}

export function tsResourceCheckbox() {
    return {
        restrict: 'E',
        replace: true,
        scope: {},
        controller: TsResourceCheckboxController,
        controllerAs: 'vmCheckbox',
        bindToController: {
            resource: '=',
            resourceType: '@',
            onCheckboxClicked: '&',
            isChecked: '&'
        },
        template: tsResourceCheckboxView
    };
}
