'use strict';
import tsImageEditToolsView from './ts-image-edit-tools.view.html';
import './ts-image-edit-tools.style.scss';

export function tsImageEditTools() {
    return {
        template: tsImageEditToolsView,
        scope: true
    };
}
