'use strict';

import {PasswordErrorType} from '@techsee/techsee-common/lib/constants/utils.constant';

import './change-settings.style.scss';

export class ChangeSettingsController {
    constructor($modalInstance, db, currentUser, accountData, dir, passwordOnly) {
        'ngInject';

        this.$modalInstance = $modalInstance;
        this.db = db;
        this.accountData = accountData;
        this.dir = dir;
        this.data = angular.copy(currentUser);
        this.passwordOnly = passwordOnly;
        this.showOldPassword = false;
        this.showNewPassword = false;
        this.showConfirmPassword = false;
    }

    /**
     * Handle modal exit (e.g. `cancel` button, ESC press, etc.)
     */
    cancel() {
        this.$modalInstance.dismiss('cancel');
    }

    editUser(data) {
        const toUpdate = {
            firstName: data.firstName,
            lastName: data.lastName
        };

        if (data.newPassword) {
            toUpdate.oldPassword = data.oldPassword;
            toUpdate.newPassword = data.newPassword;
        }

        return this.db.User.update(data._id, toUpdate).then(() => toUpdate);
    }

    submit(form) {
        if (form.$invalid) {
            return;
        }

        if (this.passwordOnly && !this.data.oldPassword) {
            this.wrongPassword = true;

            return;
        }

        this.saving = true;
        this.editUser(this.data)
            .then((user) => {
                this.saving = false;
                this.$modalInstance.close({
                    firstName: user.firstName,
                    lastName: user.lastName
                });
            })
            .catch((err) => {
                this.saving = false;

                if (err.data && err.data.message === PasswordErrorType.passwordMismatch) {
                    this.wrongPassword = true;
                }

                if (err.data && err.data.message === PasswordErrorType.usedPassword) {
                    this.usedPassword = true;
                }
            });
    }
}
