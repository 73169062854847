import {computed, observable} from 'mobx';
import {SimpleFieldModel, IFieldModel} from '@techsee/techsee-ui-common/lib/forms/_shared/simple-field-model';
import {ILocalizationService} from '../services/LocalizationService';
import {AppState} from '../app.state';

export interface ILocalizable extends IFieldModel {
    labelKey: string;
    placeholderKey: string;
}

export class LocalizableFieldModel extends SimpleFieldModel implements ILocalizable {
    private _appState: Readonly<AppState>;

    private _localization: ILocalizationService;

    @observable placeholderKey: string = '';

    @observable labelKey: string = '';

    constructor(localization: ILocalizationService, appState: Readonly<AppState>) {
        super();

        this._localization = localization;
        this._appState = appState;
    }

    @computed get placeholder(): string {
        return this.getTranslatedString(this.placeholderKey);
    }

    @computed get label(): string {
        return this.getTranslatedString(this.labelKey);
    }

    private getTranslatedString(translationKey: string): string {
        if (translationKey.length > 0 && this._appState.dashboardLanguage.length > 0) {
            return this._localization.translate(translationKey);
        }

        return '';
    }
}
