import React, {Component} from 'react';
import get from 'lodash/get';
import {ISessionEndedController} from './controller';
// @ts-ignore
import sessionEndedImg from '../../../../img/sessionEnded.png';

import './session-ended.scss';

export interface ISessionEnded {
    controller: ISessionEndedController;
}

export class SessionEnded extends Component<ISessionEnded> {
    render() {
        const {controller} = this.props;
        let sessionEndedText = '';

        if (get(controller, 'translate')) {
            sessionEndedText = controller.translate('REACT.SESSION_ENDED');
        }

        return (
            <div className={'session-ended-container'}>
                <img src={sessionEndedImg} alt='session ended' className={'session-ended-img'} />

                <h1 className={'session-ended-text'}>{sessionEndedText}</h1>
            </div>
        );
    }
}
