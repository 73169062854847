import {ITranslate} from '../../services/LocalizationService';

export interface ISessionEndedController {
    readonly translate: ITranslate;
}

export class SessionEndedController implements ISessionEndedController {
    private readonly _translate: ITranslate;

    constructor(translate: ITranslate) {
        this._translate = translate;
    }

    get translate() {
        return this._translate;
    }
}
