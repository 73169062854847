import React from 'react';
import {Provider} from 'mobx-react';
import {Stores} from './MultipartyDashboardStores';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {getRootStore} from '../../app.bootstrap';
import {DashboardContainer} from './components/layouts/MultipartyDashboardLayout';
import {MiniDashboardLoader} from '../../components/mini-dashboard-loader/mini-dashboard-loader';
import {ScreenLoader} from '../../components/screen-loader/screen-loader';

export class MultipartyDashboardRootComponent extends TechseeBaseComponent<Stores> {
    private loader = () => {
        let isMiniDashboard;

        try {
            isMiniDashboard = getRootStore().browserUtilsService.getFromSessionStorage('miniDashboard');
        } catch {
            isMiniDashboard = true;
        }

        if (isMiniDashboard) {
            let textLoader;

            try {
                textLoader = getRootStore().localizationService.translate('MAIN.VIEW.IDLE_CONNECTING');
            } catch (err) {
                textLoader = '';
            }

            return (
                <div className={'loader'}>
                    <MiniDashboardLoader textLoader={textLoader} />
                </div>
            );
        }

        return <ScreenLoader />;
    };

    renderInternal() {
        const stores = this.props.stores as Stores;

        if (typeof stores !== 'object') {
            return this.loader();
        }

        const providerStores: Stores = {};

        Object.getOwnPropertyNames(stores).forEach((storeName) => {
            providerStores[storeName] = stores[storeName];
        });

        return (
            <Provider {...providerStores}>
                <DashboardContainer />
            </Provider>
        );
    }
}
