/* eslint-disable indent */
import React from 'react';
import {observer} from 'mobx-react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {ITranslate} from '../../services/LocalizationService';
import {IVJHistoryFilterController} from './controller';
import {VJForm} from '../vj-form/form';

import './styles.scss';

export interface IVJHistoryFilter {
    ctrl: IVJHistoryFilterController;
    translate: ITranslate;
    onSubmit?: () => void;
}

@observer
export class VJHistoryFilter extends TechseeBaseComponent<IVJHistoryFilter> {
    renderInternal() {
        const {ctrl, translate} = this.props;

        return (
            <VJForm
                ctrl={ctrl.formController}
                translate={translate}
                formErrorText={'INVITE.VIEW.HISTORY_SEARCH.NO_RESULTS'}
                labelText={'INVITE.VIEW.VJ_HISTORY_SEARCH.VJ_HISTORY'}
                buttonText={'INVITE.VIEW.VJ_SEARCH'}
            />
        );
    }
}
