import React from 'react';

import {IDomUtilsService} from '@techsee/techsee-client-infra/lib/services/DomUtilsService';
import {InviteGenericStep, InviteStepParams} from '../_InviteGenericStep';
import {IInviteFlowManager, InviteStepsEnum} from '../../../_contracts/InviteContracts';
import {IMainLayoutController} from '../../../../../layouts/main-layout/layout.controller';
import {InviteByCodeStepContent} from './content';

import './styles.scss';
import {computed} from 'mobx';

export class InviteByCodeStepController extends InviteGenericStep {
    constructor(
        flowManager: IInviteFlowManager,
        mainLayoutController: IMainLayoutController,
        domUtilsService: IDomUtilsService
    ) {
        const params: InviteStepParams = {
            stepType: InviteStepsEnum.InviteByCode,
            content: () => React.createElement(InviteByCodeStepContent, {step: this}),
            toolbar: () => null,
            className: 'invite-by-code-step',
            isLeftBarVisible: true
        };

        super(flowManager, mainLayoutController, domUtilsService, params);

        this.loadImage = this.loadImage.bind(this);
    }

    @computed
    get inviteCode(): string {
        return this.flowManager.inviteState.sessionInfo.inviteByCode || '';
    }

    loadImage(img: string): string {
        return this.domUtilsService.loadImage(`agent-guidance/common/${img}`);
    }
}
