import React, {Component} from 'react';
// @ts-ignore
import dotsSpinner from '../../../../img/dots-spinner.gif';

import './mini-dashboard-loader.scss';

interface IMiniDashboardLoader {
    textLoader: string;
}

export class MiniDashboardLoader extends Component<IMiniDashboardLoader> {
    render() {
        const {textLoader} = this.props;

        return (
            <div className={'mini-dashboard-loader'}>
                <img className={'img'} src={dotsSpinner} alt={'Loading'} />
                {textLoader && <p className={'text'}>{textLoader}...</p>}
            </div>
        );
    }
}
