import React from 'react';
import {IMenuItemData} from '@techsee/techsee-ui-common/lib/menus/context-menu';
import {ITranslate} from '../../../services/LocalizationService';
import {IApplicationService} from '../../../services/ApplicationService';
import {GeneralSettings} from '../../../models/AccountSettings';
import {RolesConstant} from '@techsee/techsee-common/lib/constants/account.constants';
import {IRedirectionService} from '../../../services/RedirectionService';
import {IAgentSettingsController} from '../agent-settings/controller';
import {AppState} from '../../../app.state';
import {AgentInfoUsername} from './username';

export interface IAgentInfoController {
    onAdminClick(): Promise<void>;

    onSettingsClick(): void;

    onLogoutClick(): void;

    getContextMenuItems(): IMenuItemData[];
}

export class AgentInfoController implements IAgentInfoController {
    private _appService: IApplicationService;

    private _accGeneralSettings: GeneralSettings;

    private _contextMenuItems?: IMenuItemData[];

    private _translate: ITranslate;

    private _redirectionService: IRedirectionService;

    private _agentSettingsController: IAgentSettingsController;

    constructor(
        appService: IApplicationService,
        accGeneralSettings: GeneralSettings,
        translate: ITranslate,
        redirectionService: IRedirectionService,
        agentSettingsController: IAgentSettingsController,
        private _appState: AppState
    ) {
        this._appService = appService;
        this._accGeneralSettings = accGeneralSettings;
        this._translate = translate;
        this._redirectionService = redirectionService;
        this._agentSettingsController = agentSettingsController;

        this.onAdminClick = this.onAdminClick.bind(this);
        this.onLogoutClick = this.onLogoutClick.bind(this);
        this.onSettingsClick = this.onSettingsClick.bind(this);
    }

    async onAdminClick() {
        await this._redirectionService.goToAdmin();
    }

    onLogoutClick(): void {
        this._appService.logout().then((res) => {
            if (res.data) {
                this._redirectionService.goToUrl(res.data);

                return;
            }

            this._redirectionService.goToRootUrl();
        });
    }

    onSettingsClick(): void {
        this._agentSettingsController.openAgentSettings();
    }

    getContextMenuItems(): IMenuItemData[] {
        if (this._contextMenuItems) {
            return this._contextMenuItems;
        }

        this._contextMenuItems = [];

        if (this._appState.appUser) {
            this._contextMenuItems.push(
                {
                    data: {
                        text: (
                            <AgentInfoUsername
                                appState={this._appState}
                                translate={this._translate}
                                reportsDateFormat={this._accGeneralSettings.reportsDateFormat}
                            />
                        ),
                        className: 'username',
                        icon: ''
                    },
                    onClick: () => undefined
                },
                {
                    data: {text: 'separator', icon: ''},
                    onClick: () => undefined
                }
            );
        }

        const hasAdminLink =
            this._accGeneralSettings.enableLinkToAdmin &&
            this._appService.isAgentInRole([
                RolesConstant.SYSADMIN,
                RolesConstant.ADMIN,
                RolesConstant.SUPERADMIN,
                RolesConstant.USERS_ADMIN,
                RolesConstant.REPORTING_ADMIN,
                RolesConstant.KNOWLEDGE_EXPERT,
                RolesConstant.MANAGER
            ]);

        this._contextMenuItems.push({
            data: {text: this._translate('REACT.APP.AGENT-INFO.SETTINGS-LINK'), icon: 'settings'},
            onClick: this.onSettingsClick
        });

        this._contextMenuItems.push({
            data: {text: 'separator', icon: ''},
            onClick: () => undefined
        });

        if (hasAdminLink) {
            this._contextMenuItems.push({
                data: {text: this._translate('REACT.APP.AGENT-INFO.ADMIN-LINK'), icon: 'admin'},
                onClick: this.onAdminClick
            });

            this._contextMenuItems.push({
                data: {text: 'separator', icon: ''},
                onClick: () => undefined
            });
        }
        this._contextMenuItems.push({
            data: {text: this._translate('REACT.APP.AGENT-INFO.LOGOUT-LINK'), className: 'logout', icon: 'logout'},
            onClick: this.onLogoutClick
        });

        return this._contextMenuItems;
    }
}
