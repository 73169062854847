'use strict';

import {PlatformType} from '@techsee/techsee-common/lib/constants/utils.constant';
import startsWith from 'lodash/startsWith';

// Service that makes sure that all errors are logged
export class TsExceptionHandler {
    constructor($window, eventLog) {
        'ngInject';

        this.$window = $window;
        this.eventLog = eventLog;
    }

    init() {
        this.$window.onerror = (msg, src, line, col) => this._errorHandler(msg, src, line, col);
    }

    _errorHandler(msg, src, line, col) {
        if (startsWith(msg, 'Uncaught Error: [$rootScope:infdig]')) {
            return true;
        }

        const meta = {
            clientType: PlatformType.dashboard,
            error: {msg, src, line, col}
        };

        this.eventLog.uncaughtException(meta);
    }
}
