import React, {useEffect, useRef} from 'react';
import {observer} from 'mobx-react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {IAgentPerformanceController} from './controller';
import {DraggblePanel} from '@techsee/techsee-ui-common/lib/draggble-panel';
import {ProgressColors, ProgressLineWithValue} from '@techsee/techsee-ui-common/lib/progress/progress-line-with-value';
import {CarouselDots} from '@techsee/techsee-ui-common/lib/carousel';
import {SimpleLabel} from '@techsee/techsee-ui-common/lib/forms/labels/simple';
import {PrimaryButton} from '@techsee/techsee-ui-common/lib/forms/buttons/primary';
import {Icon} from '@techsee/techsee-ui-common/lib/icons/simple-icon';
import {AgentPerformanceElements} from '@techsee/techsee-common/lib/constants/account.constants';
import {Rating} from '@techsee/techsee-ui-common/lib/rating';

import './styles.scss';

export interface IAgentPerformanceWidget {
    controller: IAgentPerformanceController;
}

@observer
export class AgentPerformanceWidget extends TechseeBaseComponent<IAgentPerformanceWidget> {
    renderInternal(): JSX.Element {
        const {controller} = this.props;

        if (!controller) {
            return <React.Fragment />;
        }

        const {
            translate,
            agentPerformanceData,
            position,
            isUserRole,
            boundaries,
            wrapperElementClass,
            deviceCategory,
            enableCustomerSurvey,
            enableAgentSurvey,
            shouldOpenAgentSruvey,
            toggleAgentSurvey
        } = controller;

        if (!agentPerformanceData) {
            return <React.Fragment />;
        }

        const {
            average,
            monthlyGoal,
            month,
            today,
            week,
            userRating,
            groupRating,
            questions,
            elementsToDisplay,
            showCustomerSummary,
            showAgentSummary
        } = agentPerformanceData;
        const color = isUserRole ? ProgressColors.Secondary : ProgressColors.Default;

        const CustomerSurvey = () => {
            if (!elementsToDisplay.includes(AgentPerformanceElements.CustomerSurveyData) || !enableCustomerSurvey) {
                return <React.Fragment />;
            }

            return (
                <div className={'survey-container'}>
                    <SimpleLabel className={'label-text-large'}>
                        {translate('REACT.INVITE.AGENT_PERFORMANCE.CUSTOMER_SURVEY')}
                    </SimpleLabel>
                    {isUserRole && (
                        <div>
                            <div>
                                <SimpleLabel className={'label-title'}>
                                    {translate('REACT.INVITE.AGENT_PERFORMANCE.ME')}
                                </SimpleLabel>
                            </div>
                            <div className={'rating-value'}>
                                <Rating value={Math.round(userRating)} max={5} />
                                <SimpleLabel className={'label-title'}>{`${userRating}/5`}</SimpleLabel>
                            </div>
                        </div>
                    )}
                    {showCustomerSummary && (
                        <div>
                            <SimpleLabel className={'label-title summary-line'}>
                                <span className='summary-percentage'>
                                    {isUserRole
                                        ? userRating > 0
                                            ? `${userRating * 20}%`
                                            : 'N/A '
                                        : groupRating > 0
                                          ? `${groupRating * 20}%`
                                          : 'N/A '}
                                </span>
                                <span className='summary-title'>
                                    {isUserRole
                                        ? translate('REACT.INVITE.AGENT_PERFORMANCE.MY_CUSTOMER_SATISFACTION')
                                        : translate('REACT.INVITE.AGENT_PERFORMANCE.TEAM_CUSTOMER_SATISFACTION')}
                                </span>
                            </SimpleLabel>
                        </div>
                    )}
                    <div>
                        <SimpleLabel className={'label-title'}>
                            {translate('REACT.INVITE.AGENT_PERFORMANCE.TEAM')}
                        </SimpleLabel>
                    </div>
                    <div className={'rating-value'}>
                        <Rating className='group-rating' value={Math.round(groupRating)} max={5} />
                        <SimpleLabel className={'label-title'}>{`${groupRating}/5`}</SimpleLabel>
                    </div>
                </div>
            );
        };

        const AgentQuestions = (props: any) => (
            <div className='question-container'>
                <div className='question'> {props.content.question}</div>
                <div className='percentage'>{props.content.percent}% yes</div>
            </div>
        );

        const AgentSurvey = () => {
            if (!elementsToDisplay.includes(AgentPerformanceElements.AgentSurveyData) || !enableAgentSurvey) {
                return <React.Fragment />;
            }

            const contents = questions.map((question, index) => <AgentQuestions content={question} key={index} />);

            return (
                <div className={'agent-survey-container'}>
                    <SimpleLabel className={'label-text-large'}>
                        {translate('REACT.INVITE.AGENT_PERFORMANCE.AGENT_SURVEY')}
                    </SimpleLabel>
                    {showAgentSummary && (
                        <div>
                            <SimpleLabel className={'label-title summary-line'}>
                                <span className='summary-percentage'>
                                    {questions.length ? `${questions[0].percent}%` : 'N/A'}
                                </span>
                                <span className='summary-title'>
                                    {translate('REACT.INVITE.AGENT_PERFORMANCE.TEAM_AGENT_SATISFACTION')}
                                </span>
                            </SimpleLabel>
                        </div>
                    )}
                    {isUserRole ? (
                        <CarouselDots contents={contents} interval={10000} />
                    ) : (
                        <PrimaryButton
                            className={shouldOpenAgentSruvey ? 'agent-button disabled-btn' : 'agent-button'}
                            onClick={shouldOpenAgentSruvey ? undefined : toggleAgentSurvey}>
                            {translate('REACT.INVITE.AGENT_PERFORMANCE.SEE_THE_Q&A')}
                            <Icon iconName={'arrow-next'} />
                        </PrimaryButton>
                    )}
                </div>
            );
        };

        const ManagerQuestions = () => {
            if (
                !elementsToDisplay.includes(AgentPerformanceElements.AgentSurveyData) ||
                !enableAgentSurvey ||
                !shouldOpenAgentSruvey
            ) {
                return <React.Fragment />;
            }

            return (
                <div className={'agent-panel'}>
                    <div className={'manager-container'}>
                        <div className={'manager-close'} onClick={toggleAgentSurvey}>
                            <Icon iconName={'arrow-back'} />
                            <div className={'manager-back'}>{translate('REACT.INVITE.AGENT_PERFORMANCE.BACK')}</div>
                        </div>
                        <div className={'manager-header'}>
                            <SimpleLabel className={'label-text-large'}>
                                {translate('REACT.INVITE.AGENT_PERFORMANCE.AGENT_SURVEY')}
                            </SimpleLabel>
                        </div>
                        <div className={'manager-questions'}>
                            {questions.map((question, index) => (
                                <div className={'manager-question'} key={index}>
                                    <AgentQuestions content={question} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            );
        };

        const Content = () => {
            if (!elementsToDisplay.includes(AgentPerformanceElements.SessionCount)) {
                return <React.Fragment />;
            }

            return (
                <div className={'content-container'}>
                    <SimpleLabel className={'label-text-large'}>
                        {translate('REACT.INVITE.AGENT_PERFORMANCE.MONTH')}
                    </SimpleLabel>
                    <div className={'labels-container'}>
                        <div className={'stats-today-week-container use-common-styles'}>
                            <SimpleLabel className={'label-text'}>
                                {translate('REACT.INVITE.AGENT_PERFORMANCE.TODAY')}
                            </SimpleLabel>
                            <SimpleLabel className={'label-number'}>{today}</SimpleLabel>
                        </div>
                        <div className={'stats-today-week-container week-label'}>
                            <SimpleLabel className={'label-text'}>
                                {translate('REACT.INVITE.AGENT_PERFORMANCE.WEEK')}
                            </SimpleLabel>
                            <SimpleLabel className={'label-number'}>{week}</SimpleLabel>
                        </div>
                    </div>
                    <div className={'progress-container'}>
                        <ProgressLineWithValue
                            className={isUserRole ? '' : 'progress-bar-first'}
                            color={average && month < average ? ProgressColors.Error : color}
                            maxValue={monthlyGoal}
                            label={translate(
                                isUserRole
                                    ? 'REACT.INVITE.AGENT_PERFORMANCE.ME'
                                    : 'REACT.INVITE.AGENT_PERFORMANCE.TEAM_AVERAGE'
                            )}
                            value={month}
                        />

                        {isUserRole && (
                            <ProgressLineWithValue
                                className={'progress-bar-second'}
                                color={ProgressColors.Default}
                                maxValue={monthlyGoal}
                                label={translate('REACT.INVITE.AGENT_PERFORMANCE.TEAM_AVERAGE')}
                                value={average}
                            />
                        )}
                    </div>
                </div>
            );
        };

        const Widget = () => {
            const widgetRef = useRef<HTMLDivElement>(null);
            const {leftInitialDelta, topInitialPosition, isFirstPosition} = controller;

            useEffect(() => {
                if (isFirstPosition) {
                    const leftInitialPosition =
                        (widgetRef?.current?.parentNode as HTMLDivElement)?.clientWidth + leftInitialDelta;

                    controller.setFirstPosition({x: leftInitialPosition, y: topInitialPosition});
                }
            });

            return (
                <DraggblePanel
                    className={'agent-performance use-common-styles'}
                    position={position}
                    bounds={boundaries}
                    handle={'.agent-performance'}
                    wrapperElementClass={wrapperElementClass}
                    deviceCategory={deviceCategory}
                    offset={{left: 0, top: 0, right: 0, bottom: 0}}>
                    <div ref={widgetRef}>
                        <Content />
                        <CustomerSurvey />
                        <AgentSurvey />
                        <ManagerQuestions />
                    </div>
                </DraggblePanel>
            );
        };

        return <Widget />;
    }
}
