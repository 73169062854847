import React from 'react';
import {SimpleLabel} from '@techsee/techsee-ui-common/lib/forms/labels/simple';
import {IGuidancePopoversController} from './GuidancePopovers.controller';
import {PrimaryButton} from '@techsee/techsee-ui-common/lib/forms/buttons/primary';

import './GuidancePopovers.styles.scss';

interface IFlashlightGuidanceProps {
    ctrl: IGuidancePopoversController;
    onSend(): void;
    isTurnedOn: boolean;
}

export class FlashlightGuidance extends React.Component<IFlashlightGuidanceProps> {
    render() {
        const {ctrl, onSend, isTurnedOn} = this.props;

        return (
            <div className='guidance-content flashlight'>
                <SimpleLabel>
                    {isTurnedOn
                        ? ctrl.translate('REACT.FLOATING_TOOLBAR.FLASHLIGHT_GUIDE_TURN_OFF_INSTRUCTION')
                        : ctrl.translate('REACT.FLOATING_TOOLBAR.FLASHLIGHT_GUIDE_TURN_ON_INSTRUCTION')}
                </SimpleLabel>

                <PrimaryButton onClick={() => onSend()}>
                    {ctrl.translate('REACT.FLOATING_TOOLBAR.FLASHLIGHT_GUIDANCE_SEND')}
                </PrimaryButton>
                {isTurnedOn ? (
                    <img src={ctrl.loadImage('flashlight-on-guidance.png')} />
                ) : (
                    <img src={ctrl.loadImage('flashlight-off-guidance.png')} />
                )}
            </div>
        );
    }
}
