import {observable, action, toJS} from 'mobx';
import {ITranslate} from '../../../services/LocalizationService';

export interface ISolutionStep {
    description: string;
    stepIndex: number;
    image?: {
        type: string;
        url: string;
    };
}

export interface ISolutionItem {
    title: string;
    description: string;
    preperationSteps?: ISolutionStep[];
    solutionSteps?: ISolutionStep[];
}

export interface ISmartSolutionController {
    isDescriptionOpen: boolean;
    isPreparationOpen: boolean;
    isResolveOpen: boolean;
    isSmartSolutionOpen: boolean;
    solutions: ISolutionItem[];

    solution: ISolutionItem;

    translate: ITranslate;

    toggleDescription(): void;
    togglePreparation(): void;
    toggleResolve(): void;
    showSolution(): void;
    showSmartSolution(solution: ISolutionItem): void;
    hideSmartSolution(): void;

    onSendPreparationSteps(callback: (steps?: ISolutionStep[]) => void): void;
    onSendSolutionSteps(callback: (steps?: ISolutionStep[]) => void): void;

    sendPreparationStepsHandler(): void;
    sendSolutionStepsHandler(): void;
    clickStepHandler(step: ISolutionStep): void;
}

export class SmartSolutionController implements ISmartSolutionController {
    @observable isDescriptionOpen = true;

    @observable isPreparationOpen = true;

    @observable isResolveOpen = false;

    @observable isSmartSolutionOpen = false;

    @observable solutions: ISolutionItem[] = [];

    @observable solution!: ISolutionItem;

    private sendPreparationStepsCallback: (steps?: ISolutionStep[]) => void = () => {};

    private sendSolutionStepsCallback: (steps?: ISolutionStep[]) => void = () => {};

    private clickStepCallback: (step?: ISolutionStep) => void = () => {};

    constructor(public translate: ITranslate) {
        this.toggleDescription = this.toggleDescription.bind(this);
        this.togglePreparation = this.togglePreparation.bind(this);
        this.toggleResolve = this.toggleResolve.bind(this);
        this.showSolution = this.showSolution.bind(this);
        this.hideSmartSolution = this.hideSmartSolution.bind(this);
        this.sendPreparationStepsHandler = this.sendPreparationStepsHandler.bind(this);
        this.sendSolutionStepsHandler = this.sendSolutionStepsHandler.bind(this);
        this.clickStepHandler = this.clickStepHandler.bind(this);
    }

    @action
    showSmartSolution(solution?: ISolutionItem) {
        if (solution) {
            this.solution = solution;
        }

        this.setDefaultCollapse();

        this.isSmartSolutionOpen = true;
    }

    onSendPreparationSteps(callback: (steps: ISolutionStep[] | undefined) => void) {
        this.sendPreparationStepsCallback = callback;
    }

    onSendSolutionSteps(callback: (steps: ISolutionStep[] | undefined) => void) {
        this.sendSolutionStepsCallback = callback;
    }

    onClickStep(callback: (step: ISolutionStep | undefined) => void) {
        this.clickStepCallback = callback;
    }

    sendPreparationStepsHandler() {
        this.sendPreparationStepsCallback(toJS(this.solution.preperationSteps));
    }

    sendSolutionStepsHandler() {
        this.sendSolutionStepsCallback(toJS(this.solution.solutionSteps));
    }

    clickStepHandler(step: ISolutionStep) {
        this.clickStepCallback(toJS(step));
    }

    @action
    setDefaultCollapse() {
        this.isDescriptionOpen = true;
        this.isPreparationOpen = true;
        this.isResolveOpen = false;
    }

    @action
    hideSmartSolution() {
        this.isSmartSolutionOpen = false;

        this.setDefaultCollapse();
    }

    @action
    toggleDescription() {
        this.isDescriptionOpen = !this.isDescriptionOpen;
    }

    @action
    togglePreparation() {
        this.isPreparationOpen = !this.isPreparationOpen;
    }

    @action
    toggleResolve() {
        this.isResolveOpen = !this.isResolveOpen;
    }

    @action
    showSolution() {
        this.isResolveOpen = true;
        this.isDescriptionOpen = false;
        this.isPreparationOpen = false;
    }
}
