'use strict';

import tsPopoverModalView from './ts-popover-modal.view.html';
import './ts-popover-modal.style.scss';

class TsPopoverModalController {
    onGoButton() {
        this.isOpen = false;
        this.goClick({newValue: this.changedValue});
    }

    clickedCancel() {
        this.isOpen = false;
    }
}

function linkFn(scope, element, attrs, ctrl) {
    ctrl.changedValue = ctrl.currentValue;
}

export function tsPopoverModalDirective() {
    return {
        template: tsPopoverModalView,
        scope: true,
        transclude: true,
        controller: TsPopoverModalController,
        bindToController: {
            titlePopover: '=',
            buttonText: '=',
            currentValue: '=',
            templateId: '@',
            goClick: '&'
        },
        controllerAs: 'vm',
        link: linkFn
    };
}
