import React from 'react';
import {observer} from 'mobx-react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {InviteStepProps} from './_InviteGenericStep';

@observer
export class PreCameraStep extends TechseeBaseComponent<InviteStepProps> {
    renderInternal() {
        // const {clientDeviceType, translate} = this.props.ctrl;
        // const progressItems: Array<GuidenceProgressItem> = [
        //     {
        //         iconColor: IconColors.Green,
        //         label: translate('REACT.INVITE.PERMISSIONS.TERMS.TITLE'),
        //         isActive: true,
        //         icon: 'check',
        //         isIconAnimated: false
        //     },
        //     {
        //         iconColor: IconColors.Blue,
        //         label: translate('REACT.INVITE.PERMISSIONS.CAMERA.TITLE'),
        //         isActive: true,
        //         icon: 'camera',
        //         isIconAnimated: false
        //     }
        // ];
        // return (
        //     <PermissionsStepTabs guidanceType={'camera-allow'}
        //                          progressList={progressItems}
        //                          deviceType={clientDeviceType} stepClass={'camera-step'}
        //                          title={translate('REACT.INVITE.PERMISSIONS.CAMERA.TITLE')}
        //                          subTitle={translate('REACT.INVITE.PERMISSIONS.CAMERA.SUB_TITLE')}
        //                          guidanceText={translate('REACT.INVITE.PERMISSIONS.CAMERA.TAP_ALLOW')}/>
        // );
        return <div>Hello World for PRECAMERA permissions</div>;
    }
}
