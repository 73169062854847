'use strict';

export function removeExtension() {
    /**
     * [return description]
     * @param  {String}  fileName    An string contains filename.ext
     * @return {String}              fileName without .ext
     */
    return (fileName) => fileName.replace(/\.[^\.]+$/g, '');
}
