import {observable, action, computed} from 'mobx';
import {AgentSettingsForm, IAgentSettingsForm} from './model';
import {ITranslate} from '../../../services/LocalizationService';
import {IFieldModel} from '@techsee/techsee-ui-common/lib/forms/_shared/simple-field-model';
import {PasswordErrorType} from '@techsee/techsee-common/lib/constants/utils.constant';
import {DbUserAdapter} from '../../../services/AngularServices/UserAdapter';
import {AppState, IAppUser} from '../../../app.state';
import {IPasswordSettings, GeneralSettings} from '../../../models/AccountSettings';
import {locales} from '@techsee/techsee-common/lib/helpers/i18n.settings';
import {getRootStore} from '../../../app.bootstrap';
import {IEventLogsService} from '../../../services/EventsLogService';
import {LOG_EVENTS} from '@techsee/techsee-common/lib/constants/event-logs.constants';
import {IBrowserUtilsService} from '@techsee/techsee-client-infra/lib/services/BrowserUtilsService';
import {
    DASHBOARD_LANGUAGE_KEY,
    SupportedLanguagesEnum,
    initClientLanguage
} from '../../../states/helper/general.helper';
import orderBy from 'lodash/orderBy';

export interface IAgentSettingsController {
    closeAgentSettings(): void;
    openAgentSettings(): void;
    openAgentPasswordSettings(): void;
    onFormSubmit(e: React.FormEvent<HTMLFormElement>): void;
    handleOldPasswordChange(): void;
    toggleShowOldPassword(): void;
    toggleShowNewPassword(): void;
    toggleShowConfirmPassword(): void;
    translate(token: string, data?: {[key: string]: string}): string;

    readonly isDisplayedAgentSettings: boolean;
    readonly clientLanguage: string;
    readonly passwordOnly: boolean;
    readonly showOldPassword: boolean;
    readonly showNewPassword: boolean;
    readonly showConfirmPassword: boolean;
    readonly error: string;
    readonly agentSettingsForm: IAgentSettingsForm;
    readonly enableMultiLanguage: boolean;
    readonly supportedLanguages: Array<any>;
}

export class AgentSettingsController implements IAgentSettingsController {
    @observable isDisplayedAgentSettings: boolean = false;

    @observable clientLanguage: string = '';

    @observable passwordOnly: boolean = false;

    @observable _showOldPassword: boolean = false;

    @observable _showNewPassword: boolean = false;

    @observable _showConfirmPassword: boolean = false;

    @observable error: string = '';

    translate: ITranslate;

    agentSettingsForm: IAgentSettingsForm;

    generalSettings: GeneralSettings;

    eventLogsService: IEventLogsService;

    browserUtilsService: IBrowserUtilsService;

    accountLanguage: string;

    constructor(
        translate: ITranslate,
        createFieldModel: any,
        private dbUserService: DbUserAdapter,
        private currentUser: any,
        private appState: AppState,
        passwordSettings: IPasswordSettings,
        generalSettings: GeneralSettings,
        browserUtilsService: IBrowserUtilsService,
        accountLanguage: string
    ) {
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.closeAgentSettings = this.closeAgentSettings.bind(this);
        this.toggleShowOldPassword = this.toggleShowOldPassword.bind(this);
        this.toggleShowNewPassword = this.toggleShowNewPassword.bind(this);
        this.toggleShowConfirmPassword = this.toggleShowConfirmPassword.bind(this);
        this.handleOldPasswordChange = this.handleOldPasswordChange.bind(this);
        this.translate = translate;
        this.generalSettings = generalSettings;
        this.eventLogsService = getRootStore().eventsLogService;
        this.browserUtilsService = browserUtilsService;
        this.accountLanguage = accountLanguage;
        this.clientLanguage = initClientLanguage();

        this.agentSettingsForm = new AgentSettingsForm(translate, createFieldModel, passwordSettings);
        this.createFields();
    }

    @computed
    get newPasswordField(): IFieldModel {
        return this.agentSettingsForm.fields[this.agentSettingsForm.fieldNames.newPassword];
    }

    @computed
    get oldPasswordField(): IFieldModel {
        return this.agentSettingsForm.fields[this.agentSettingsForm.fieldNames.oldPassword];
    }

    @computed
    get confirmPasswordField(): IFieldModel {
        return this.agentSettingsForm.fields[this.agentSettingsForm.fieldNames.confirmPassword];
    }

    @computed
    get showOldPassword() {
        return this._showOldPassword;
    }

    @computed
    get showNewPassword() {
        return this._showNewPassword;
    }

    @computed
    get showConfirmPassword() {
        return this._showConfirmPassword;
    }

    @computed
    get enableMultiLanguage() {
        return this.generalSettings.enableMultiLanguage;
    }

    @computed
    get supportedLanguages() {
        const supportedLanguages = locales.filter((locale) =>
            Object.keys(SupportedLanguagesEnum).find((supportedLanguage) => supportedLanguage === locale.name)
        );
        const dbLanguage = supportedLanguages.find((language) => language.value === this.accountLanguage);

        if (!dbLanguage) {
            const languageToAdd = locales.find((language) => language.value === this.accountLanguage);

            if (languageToAdd) {
                supportedLanguages.push(languageToAdd);
            }
        }

        return orderBy(supportedLanguages, ['name'], ['asc']);
    }

    @action
    onFormSubmit(e: React.FormEvent<HTMLFormElement>): void {
        e.preventDefault();

        if (e.target instanceof HTMLElement) {
            e.target.tabIndex = -1;
            e.target.focus();
        }

        this.resetError();

        this.agentSettingsForm.validateForm().then((isValid) => {
            if (isValid) {
                const {firstName, lastName, clientLanguage, oldPassword, newPassword, confirmPassword} =
                    this.agentSettingsForm.getFormValues() as any;

                if (this.passwordOnly && !oldPassword) {
                    this.setError(this.translate('REACT.APP.AGENT_SETTINGS.OLD_PASSWORD_INVALID'));

                    return;
                }

                if (newPassword !== confirmPassword) {
                    this.setError(this.translate('REACT.APP.AGENT_SETTINGS.PASSWORDS_NOT_MATCH'));

                    return;
                }

                const values: any = {firstName, lastName, oldPassword, newPassword};

                const userParams: any = Object.keys(values).reduce(
                    (acc, key) => (values[key] ? {...acc, [key]: values[key]} : acc),
                    {}
                );

                this.dbUserService
                    .update(this.currentUser._id, userParams)
                    .then(() => {
                        const user: IAppUser = {
                            ...(this.appState.appUser as IAppUser),
                            firstName,
                            lastName
                        };

                        this.appState.setAppUser(user);
                        this.closeAgentSettings();
                        this.handleClientLanguageChange(clientLanguage);
                    })
                    .catch((err) => {
                        if (err.data) {
                            if (err.data.message === PasswordErrorType.usedPassword) {
                                this.setError(this.translate('REACT.APP.AGENT_SETTINGS.USED_PASSWORD'));

                                return;
                            }

                            if (err.data.message === PasswordErrorType.passwordMismatch) {
                                this.setError(this.translate('REACT.APP.AGENT_SETTINGS.OLD_PASSWORD_INVALID'));

                                return;
                            }
                        }
                        this.setError(this.translate('REACT.APP.AGENT_SETTINGS.SERVER_ERROR'));
                    });
            } else {
                this.agentSettingsForm.displayFieldsErrors();
            }
        });
    }

    @action
    setError(error: string) {
        this.error = error;
    }

    @action
    resetError() {
        this.error = '';
    }

    @action
    closeAgentSettings(): void {
        this.isDisplayedAgentSettings = false;
        this.passwordOnly = false;
        this._showOldPassword = false;
        this._showNewPassword = false;
        this._showConfirmPassword = false;
        this.agentSettingsForm.resetFormFields();
        this.resetError();
        this.createFields();
    }

    @action
    openAgentSettings(): void {
        this.isDisplayedAgentSettings = true;
    }

    @action
    openAgentPasswordSettings(): void {
        this.isDisplayedAgentSettings = true;
        this.passwordOnly = true;
    }

    @action
    toggleShowOldPassword(): void {
        this._showOldPassword = !this._showOldPassword;
    }

    @action
    toggleShowNewPassword(): void {
        this._showNewPassword = !this._showNewPassword;
    }

    @action
    toggleShowConfirmPassword(): void {
        this._showConfirmPassword = !this._showConfirmPassword;
    }

    handleOldPasswordChange(): void {
        this.newPasswordField.setDisabled(!this.oldPasswordField.value);
        this.confirmPasswordField.setDisabled(!this.oldPasswordField.value);
    }

    private createFields(): void {
        this.agentSettingsForm.createFirstNameField(this.appState.appUser!.firstName);
        this.agentSettingsForm.createLastNameField(this.appState.appUser!.lastName);
        this.agentSettingsForm.createClientLanguageField(this.clientLanguage);
        this.agentSettingsForm.createOldPasswordField();
        this.agentSettingsForm.createNewPasswordField();
        this.agentSettingsForm.createConfirmPasswordField();
    }

    private handleClientLanguageChange(clientLanguage: string) {
        if (!clientLanguage) {
            return;
        }

        const foundLocales = locales.filter((locale) => locale.name === clientLanguage);
        const selectedLanguageValue = foundLocales && foundLocales.length === 1 && foundLocales[0].value;

        if (this.appState.dashboardLanguage !== selectedLanguageValue) {
            // Set the new language value into the local storage
            this.browserUtilsService.saveToLocalStorage(DASHBOARD_LANGUAGE_KEY, selectedLanguageValue);
            // Write an event log for this language change
            this.writeLanguageChangeEventLog(selectedLanguageValue);
            // Refresh the page
            window.location.reload();
        }
    }

    private getLogParams(logType: string, meta?: any) {
        const logParams: any = {
            logType: logType,
            accountId: this.appState.accountId,
            userId: this.appState.appUser?._id,
            meta: meta
        };

        return logParams;
    }

    private writeLanguageChangeEventLog(selectedLanguageValue: false | string) {
        const meta = {
            previousLanguage: this.appState.dashboardLanguage,
            currentLanguage: `"${selectedLanguageValue}"`
        };

        this.eventLogsService.info(this.getLogParams(LOG_EVENTS.agentChangedLanguage, meta));
    }
}
