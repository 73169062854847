'use strict';

import map from 'lodash/map';
import each from 'lodash/each';

export class CanvasMathUtils {
    /**
     * Calculates the angle of a line object
     *
     * @param line {Object} fabric.Line whos angle is calculated
     * @return {Number} angle in degrees
     */
    static lineAngle(line) {
        const deltaX = line.x2 - line.x1,
            deltaY = line.y2 - line.y1,
            angle = (Math.atan2(deltaY, deltaX) * 180) / Math.PI;

        return angle;
    }

    /**
     * Selects closest ratio to the current, from a list of allowed ones
     *
     * @param currentRatio {Number} currentRatio
     * @param allowedRatios {Array} list of allowed ratios
     * @return {Number} a ratio selected from allowedRatios
     */
    static selectBestRatio(currentRatio, allowedRatios) {
        const ratioDiffs = map(allowedRatios, (ratio) => Math.abs(currentRatio - ratio));

        let minDiff = Infinity,
            selectedRatio = currentRatio;

        each(ratioDiffs, (diff, index) => {
            if (diff < minDiff) {
                minDiff = diff;
                selectedRatio = allowedRatios[index];
            }
        });

        return selectedRatio;
    }

    /**
     * Compares two ratios and resizes a set of dimensions based on which of
     * the two ratios must be maintained.
     * The second ratio is considered to belong to a rectangle that must fit in
     * a rectangle with the first ratio, so what we decide is whether the outer
     * rectangle will become larger or the inner will become smaller.
     *
     * @param outRatio {Number} Ratio of enclosing rectangle
     * @param inRatio {Number} Ratio of enclosed rectangle
     * @param inOrOut {String} "in" or "out", select which ratio to respect
     * @param inWidth {Number} width used for resizing
     * @param inHeight {Number} height used for resizing
     * @return {Object} Resized width and height
     */
    static fixInOutRatio(outRatio, inRatio, inOrOut, inWidth, inHeight) {
        const selectedRatio = inOrOut === 'in' ? inRatio : outRatio;
        let width = 0;
        let height = 0;

        if (outRatio > inRatio) {
            width = inHeight * selectedRatio;
            height = inHeight;
        } else {
            width = inWidth;
            height = inWidth / selectedRatio;
        }

        return {
            width: width,
            height: height
        };
    }

    /* Takes an offset to a position and modifies it so that
     * the resulting position will stay within the specified bounds
     *
     * @param pos {Number} x, or y position that is shifted
     * @param delta {Number} amount by which to shift the position
     * @param min {Number} min new value for pos
     * @param max {Number} max new value for pos
     * @return {Number} the modified delta, that will not throw pos out of bounds
     */
    static clampOffset(pos, delta, min, max) {
        if (pos - delta < min) {
            return delta < 0 ? delta : 0;
        } else if (pos - delta > max) {
            return delta > 0 ? delta : 0;
        }

        return delta;
    }
}
