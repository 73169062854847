import {IClientAsDashboardEmailParams, IDbEmail} from './AngularServices';
import {InviteStartedFrom} from '@techsee/techsee-common/lib/constants/utils.constant';

export class DbEmailAdapter implements IDbEmail {
    private _dbService: any;

    constructor(db: any) {
        this._dbService = db;
    }

    sendEmail(data: {data: {to: string; url: string; crid: string}}): Promise<void> {
        return this._dbService.Email.send(data);
    }

    sendVisualJourneyEmail(data: {
        data: {to: string; source: InviteStartedFrom; crid: string; visualJourney: string};
    }): Promise<void> {
        return this._dbService.Email.visualJourney(data);
    }

    sendClientAsDashboardEmail(data: IClientAsDashboardEmailParams): Promise<any> {
        return this._dbService.Email.clientAsDashboard({data});
    }

    sendObserverInvitationEmail(data: IClientAsDashboardEmailParams): Promise<any> {
        return this._dbService.Email.ObserverInvitation({data});
    }
}
