import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import React from 'react';
import {SimpleLabel} from '@techsee/techsee-ui-common/lib/forms/labels/simple';
import {IDashboardFooterController} from './controller';

import './styles.scss';

interface IDashboardFooter {
    controller: IDashboardFooterController;
}

export class DashboardFooter extends TechseeBaseComponent<IDashboardFooter> {
    renderInternal(): JSX.Element {
        const {sessionId, translate} = this.props.controller;

        return (
            <div className={'dashboard-footer'}>
                <SimpleLabel className={'label-footer'}>
                    {translate('REACT.INVITE.VIEW.ID')}: {sessionId}
                </SimpleLabel>
            </div>
        );
    }
}
