import {action, runInAction} from 'mobx';
import {IWebRtcDetector} from '@techsee/techsee-client-infra/lib/services/DetectWebRtcService';
import {ClientInfo} from '@techsee/techsee-common/lib/data-contracts/ClientInfo';
import {PlatformType} from '@techsee/techsee-common/lib/constants/utils.constant';
import {getBrowserInfo} from '@techsee/techsee-client-infra/lib/shared/browser-info';
import {AppState, IAppUser} from '../../app.state';
import {getRootStore} from '../../app.bootstrap';
import {MultipartyDashboardController} from './MultipartyDashboardController';
import {DashboardState} from '../dashboard/_contracts/DashboardState';
import get from 'lodash/get';
import {AddressTypesEnum} from '@techsee/techsee-common/lib/constants/room.constants';
import {ConnectionTypesEnum} from '../../services/Session/SessionContracts';
import {EventLogConstant} from '../../../constants/events/event-log.constant';
import assign from 'lodash/assign';
import {IEventLogsService} from '../../services/EventsLogService';
import {LOG_EVENTS} from '@techsee/techsee-common/lib/constants/event-logs.constants';
import {ILocaleHelperService, IStateHelper, IUrlUtils} from '../../services/AngularServices/AngularInterfaces';
import {IRoom} from '../../models/Room';
import {IUser} from '../../models/User';
import {getDashboardLanguage} from '../helper/general.helper';

export interface Stores {
    [key: string]: any;
}

export interface IMultipartyDashboardStores {
    stores?: Stores;
}

export class MultipartyDashboardStores implements IMultipartyDashboardStores {
    public stores?: Stores;

    private _dashboardState: DashboardState;
    private _logEvents: EventLogConstant;
    private _eventsLogService: IEventLogsService;

    private _tsChatApi: any;

    //We use this type of injection, because ng-annotate-loader not runs on TypeScript files.
    static $inject = [
        '$scope',
        '$window',
        'currentUser',
        'accountData',
        'tsChatApi',
        'tsUrlUtils',
        'tsLocaleHelper',
        'roomData',
        'tsStateHelper',
        'sharingService'
    ];

    constructor(
        $scope: any,
        private readonly $window: any,
        private readonly _currentUser: IUser,
        private readonly _currentAccount: any,
        tsChatApi: any,
        private readonly _tsUrlUtils: IUrlUtils,
        private readonly _tsLocaleHelper: ILocaleHelperService,
        private readonly _roomData: IRoom,
        private readonly _tsStateHelper: IStateHelper,
        private readonly tsSharingService: any
    ) {
        this._tsChatApi = tsChatApi.service;
        this._logEvents = EventLogConstant;
        this._eventsLogService = getRootStore().eventsLogService;
        this._dashboardState = new DashboardState();

        this.initStores().then((stores: Stores) => {
            $scope.$apply(() => (this.stores = stores));
        });
    }

    private async initStores(): Promise<Stores> {
        const {appState, localizationService, webRtcDetector} = getRootStore();

        this._eventsLogService.room = this._roomData._id;

        this.updateAppState(appState);
        this.updateDashboardState();
        await this.webRtcSupport();

        localizationService.setAccountData(appState.accountId, appState.dashboardLanguage);

        await this.getClientInfo(webRtcDetector);
        await localizationService.init();

        const dashboardController = new MultipartyDashboardController(
            this.$window,
            this._tsLocaleHelper,
            localizationService.translate,
            this._tsChatApi,
            this._tsUrlUtils,
            this._currentUser,
            this._roomData,
            this._dashboardState,
            this._logEvents,
            this._tsStateHelper,
            this._currentAccount,
            this.tsSharingService
        );

        const stores: Stores = {};
        const defineStore = this.defineStores(stores);

        defineStore('localizationService', localizationService);
        defineStore('applicationState', appState);
        defineStore('dashboardController', dashboardController);
        defineStore('leftBarController', dashboardController.leftBarController);
        defineStore('videoController', dashboardController.videoController);
        defineStore('galleryController', dashboardController.galleryController);

        defineStore('liveLeftBarController', dashboardController.liveLeftBarController);
        defineStore('liveTopBarController', dashboardController.liveTopBarController);
        defineStore('sessionEndModalController', dashboardController.sessionEndModalController);
        defineStore(
            'customerEndedTheMeetingModalController',
            dashboardController.customerEndedTheMeetingModalController
        );
        defineStore(
            'clientWebRtcNotSupportedModalController',
            dashboardController.clientWebRtcNotSupportedModalController
        );
        defineStore('shareScreenPopupController', dashboardController.shareScreenPopupController);
        defineStore('remoteControlPopupController', dashboardController.remoteControlPopupController);

        return stores;
    }

    private updateDashboardState() {
        runInAction(() => {
            if (!this._dashboardState) {
                return;
            }

            this._dashboardState.sessionInfo.sessionRoomId = this._roomData._id;
            this._dashboardState.sessionInfo.sessionLink = this._roomData.sentURL;
            this._dashboardState.sessionInfo.isSessionActive = true;
            this._dashboardState.sessionInfo.isOffline = this._roomData.offline;
            this._dashboardState.sessionInfo.clientJoinedSession = !!this._roomData.clientDeviceBrowser;

            this._dashboardState.clientMobileType = this._roomData.clientDevice;
            this._dashboardState.clientMobileBrowser = this._roomData.clientDeviceBrowser;
            this._dashboardState.clientMobileOsName = get(this._roomData, 'clientOsObject.name') as unknown as string;
            this._dashboardState.clientMobileOsVersion = get(
                this._roomData,
                'clientOsObject.version'
            ) as unknown as string;
            this._dashboardState.inviteMethodInfo.customerRef = this._roomData.customerId;
            this._dashboardState.inviteMethodInfo.addressType =
                this._roomData.customerEmail !== 'none' ? AddressTypesEnum.EMAIL : AddressTypesEnum.SMS;
            this._dashboardState.inviteMethodInfo.address =
                this._roomData.customerEmail !== 'none' ? this._roomData.customerEmail : this._roomData.customerNumber;
            this._dashboardState.inviteMethodInfo.clientLanguage = this._roomData.clientLanguage;

            const mobileNetworkInfo = get(this._roomData, 'mobileNetworkInfo');

            // @ts-ignore
            if (mobileNetworkInfo && typeof mobileNetworkInfo.connectionType === 'string') {
                // @ts-ignore
                this._dashboardState.clientNetworkInfo.connectionType = mobileNetworkInfo.connectionType.toUpperCase();
            } else {
                this._dashboardState.clientNetworkInfo.connectionType = ConnectionTypesEnum.NETWORK;
            }

            this._dashboardState.clientNetworkInfo.downlinkMax =
                get(this._roomData, 'mobileNetworkInfo.downlinkMax') || 0;
            this._dashboardState.isOfflineRoom =
                this._roomData.offline && this._tsUrlUtils.getParamValue('offline') !== 'true';
            this._dashboardState.isOfflineMode = this._tsUrlUtils.getParamValue('offline') === 'true';
            this._dashboardState.isObserve = !!this._tsUrlUtils.getParamValue('observe');
        });
    }

    private webRtcSupport() {
        return getRootStore()
            .webRtcDetector.getWebRtcInfo()
            .then((webRTCSupportResult) => {
                this._tsChatApi.setWebRTCSupport(webRTCSupportResult);
                this._eventsLogService.info({
                    logType: LOG_EVENTS.webRTCSupported,
                    userId: this._currentUser._id,
                    accountId: this._currentUser.accountId,
                    meta: assign(webRTCSupportResult, {side: PlatformType.dashboard})
                });
            });
    }

    @action
    private updateAppState(appState: AppState): void {
        appState.accountId = this._currentAccount._id;
        appState.dashboardLanguage = getDashboardLanguage(this._currentAccount, this._roomData);
        appState.accountName = this._currentAccount.companyName;
        appState.setAppUser(this._currentUser as IAppUser);
    }

    private async getClientInfo(detectRTC: IWebRtcDetector): Promise<ClientInfo> {
        const webRtcInfo = await detectRTC.getWebRtcInfo();
        const clientInfo: ClientInfo = {
            platformType: PlatformType.dashboard,
            clientVersion: CLIENT_VERSION,
            browserInfo: getBrowserInfo(),
            webRtcInfo: webRtcInfo
        };

        return clientInfo;
    }

    //#endregion

    /*
        Because of technical issue with Angular digest loop,
        We define stores as non enumerable properties. This helps to avoid infinite digest of angular.
    */
    private defineStores(stores: Stores) {
        return (storeName: string, value: any) => {
            Object.defineProperty(stores, storeName, {
                value: value,
                enumerable: false
            });
        };
    }
}
