import {VideoPositioningStrategy, VideoSizeResult} from './abstractions';

export class MinimizedCircleVideoStrategy extends VideoPositioningStrategy {
    constructor(window, domQuery) {
        super();
        this._domQuery = domQuery;
        this._window = window;
    }

    getVideoNewSize() {
        const {maxWidth, maxHeight} = this._getSizeLimits();

        return new VideoSizeResult(maxWidth, maxHeight);
    }

    updateVideoSize(elVideo, videoNewSize, videoMeta) {
        super.updateVideoSize(elVideo, videoNewSize, videoMeta);
        if (this._domQuery(elVideo).is('video')) {
            elVideo.css('object-fit', 'cover');
        }
    }

    updateVideoLocation(videoSize, elContainer, videoWasManuallyMoved) {
        super.updateVideoLocation(videoSize, elContainer, videoWasManuallyMoved);

        const elementCss = {display: 'flex'};

        if (!videoWasManuallyMoved) {
            const mainContainer = this._domQuery('.dashboard-main-content-container');
            const x = mainContainer.offset().left + 17;
            const y = mainContainer.offset().top + 20;

            elementCss.left = x + 'px';
            elementCss.top = y + 'px';
            elementCss.right = 'auto';
            elementCss.bottom = 'auto';
        }

        elContainer.css(elementCss);
    }

    _getSizeLimits() {
        const isLargeScreen = this._window.matchMedia('(min-width: 1280px)').matches;

        return {
            maxWidth: isLargeScreen ? 120 : 91,
            maxHeight: isLargeScreen ? 120 : 91
        };
    }
}
