'use strict';

export const RECONNECTION_SETTINGS = {
    SHOW_RECONNECTION_STATUS: false,
    TIMEOUT: 3
};

export const ACCENT_CLASS = {
    NORMAL: 0,
    WARNING: 1,
    DANGER: 2
};

export const RTL_LANGUAGES = ['he_IL'];

export const CONNECTION_STATUS = {
    WAITING: 0,
    CONNECTED: 1,
    DISCONNECTED: 2,
    RECONNECTING: 3,
    IDLE: 4
};

export const LOG_REPETITION_TYPE = {
    // show this log message only once
    SINGLE: 0,
    // show this log anytime
    MULTI: 1,
    // filter by unique id
    UNIQUE: 2
};

export const LOG_MESSAGES = (() => {
    // stub function for i18n support
    const $translate = (m) => m;

    return {
        CLIENT_CONNECTED: {
            group: -1,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.CLIENT_CONNECTED'),
            accent: ACCENT_CLASS.NORMAL
        },
        CLIENT_VISIBLE: {
            group: -1,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.CLIENT_VISIBLE'),
            accent: ACCENT_CLASS.NORMAL
        },
        CLIENT_HIDDEN: {
            group: -1,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.CLIENT_IDLE'),
            accent: ACCENT_CLASS.WARNING
        },
        CLIENT_RECONNECTING: {
            group: -1,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.CLIENT_RECONNECTING'),
            accent: ACCENT_CLASS.WARNING
        },
        CLIENT_DISCONNECTED: {
            group: -1,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.CLIENT_DISCONNECTED'),
            accent: ACCENT_CLASS.WARNING
        },
        CLIENT_LEFT_OFFLINE: {
            group: -1,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.CLIENT_LEFT_OFFLINE'),
            accent: ACCENT_CLASS.WARNING
        },
        // Client loading App
        CLIENT_LOADING_APP: {
            group: 0,
            repetition: LOG_REPETITION_TYPE.SINGLE,
            message: $translate('LOGS.CLIENT_LOADING_APP'),
            accent: ACCENT_CLASS.NORMAL
        },
        CLIENT_NETWORK_CELLULAR: {
            group: -1,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.CLIENT_NETWORK_CELLULAR'),
            accent: ACCENT_CLASS.NORMAL
        },
        CLIENT_NETWORK_WIFI: {
            group: -1,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.CLIENT_NETWORK_WIFI'),
            accent: ACCENT_CLASS.NORMAL
        },

        // Waiting for customer to accept terms of service
        WAITING_FOR_TOS_ACCEPTANCE: {
            group: 0,
            repetition: LOG_REPETITION_TYPE.SINGLE,
            message: $translate('LOGS.WAITING_FOR_TOS_ACCEPTANCE'),
            accent: ACCENT_CLASS.WARNING
        },

        // Customer accepted the terms of service
        TOS_ACCEPTED: {
            group: 0,
            repetition: LOG_REPETITION_TYPE.SINGLE,
            message: $translate('LOGS.TOS_ACCEPTED'),
            accent: ACCENT_CLASS.NORMAL
        },

        // Customer has not selected to accept or reject camera access after a while
        CAMERA_DIALOG_IDLE_ALERT: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.CAMERA_DIALOG_IDLE_ALERT'),
            accent: ACCENT_CLASS.WARNING
        },

        // Switch customer to photo chat
        FORCE_SWITCH_TO_PHOTO: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.FORCE_SWITCH_TO_PHOTO'),
            accent: ACCENT_CLASS.WARNING
        },

        // Switch customer to image upload chat
        FORCE_SWITCH_TO_IMAGE_UPLOAD: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.FORCE_SWITCH_TO_IMAGE_UPLOAD'),
            accent: ACCENT_CLASS.WARNING
        },

        // Live video is not supported
        VIDEO_IS_NOT_SUPPORTED: {
            group: 0,
            repetition: LOG_REPETITION_TYPE.SINGLE,
            message: $translate('LOGS.VIDEO_IS_NOT_SUPPORTED'),
            accent: ACCENT_CLASS.WARNING
        },

        // Live audio is not supported
        AUDIO_IS_NOT_SUPPORTED: {
            group: 0,
            repetition: LOG_REPETITION_TYPE.SINGLE,
            message: $translate('LOGS.AUDIO_IS_NOT_SUPPORTED'),
            accent: ACCENT_CLASS.WARNING
        },

        // Client device: {device}
        BROWSER_DETECTED: {
            group: 0,
            repetition: LOG_REPETITION_TYPE.SINGLE,
            message: $translate('LOGS.BROWSER_DETECTED'),
            accent: ACCENT_CLASS.NORMAL
        },

        // Customer device is not supported
        UNSUPPORTED_DEVICE: {
            group: 0,
            repetition: LOG_REPETITION_TYPE.SINGLE,
            message: $translate('LOGS.UNSUPPORTED_DEVICE'),
            accent: ACCENT_CLASS.WARNING
        },

        IMAGE_UPLOAD_UNSUPPORTED: {
            group: 0,
            repetition: LOG_REPETITION_TYPE.SINGLE,
            message: $translate('LOGS.IMAGE_UPLOAD_UNSUPPORTED'),
            accent: ACCENT_CLASS.WARNING
        },

        // Customer\'s device is trying to load Chrome browser
        LOADING_CHROME_BROWSER: {
            group: 0,
            repetition: LOG_REPETITION_TYPE.SINGLE,
            message: $translate('LOGS.LOADING_CHROME_BROWSER'),
            accent: ACCENT_CLASS.NORMAL
        },

        // Detecting customer\'s device ability to broadcast video
        DETECTING_BROADCAST_ABILITY: {
            group: 0,
            repetition: LOG_REPETITION_TYPE.SINGLE,
            message: $translate('LOGS.DETECTING_BROADCAST_ABILITY'),
            accent: ACCENT_CLASS.NORMAL
        },

        // Waiting for customer to tap on the \'Approve\' camera access button...
        PROMPT_VIDEO_DEVICE: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.UNIQUE,
            message: $translate('LOGS.PROMPT_VIDEO_DEVICE'),
            accent: ACCENT_CLASS.WARNING
        },

        // Waiting for customer to tap on the \'Approve\' microphone access button...
        PROMPT_AUDIO_DEVICE: {
            group: 1,
            repetition: LOG_REPETITION_TYPE.SINGLE,
            message: $translate('LOGS.PROMPT_AUDIO_DEVICE'),
            accent: ACCENT_CLASS.WARNING
        },

        // Customer's camera failed to publish video
        UNABLE_TO_PUBLISH: {
            group: 1,
            repetition: LOG_REPETITION_TYPE.SINGLE,
            message: $translate('LOGS.UNABLE_TO_PUBLISH'),
            accent: ACCENT_CLASS.DANGER
        },

        // Video session started
        VIDEOSESSION_STARTED: {
            group: 1,
            repetition: LOG_REPETITION_TYPE.SINGLE,
            message: $translate('LOGS.VIDEOSESSION_STARTED'),
            accent: ACCENT_CLASS.NORMAL
        },

        // Customer\'s device started a video broadcast
        MEDIA_PERMISSION_ALLOW: {
            group: 1,
            repetition: LOG_REPETITION_TYPE.SINGLE,
            message: $translate('LOGS.MEDIA_PERMISSION_ALLOW'),
            accent: ACCENT_CLASS.NORMAL
        },

        // Customer\'s device started a video broadcast
        VIDEO_READY: {
            group: 1,
            repetition: LOG_REPETITION_TYPE.SINGLE,
            message: $translate('LOGS.VIDEO_READY'),
            accent: ACCENT_CLASS.NORMAL
        },

        // Customer failed to start video broadcast
        BROADCAST_FAILED: {
            group: 1,
            repetition: LOG_REPETITION_TYPE.SINGLE,
            message: $translate('LOGS.BROADCAST_FAILED'),
            accent: ACCENT_CLASS.DANGER
        },

        // Customer rejected terms of service
        TOS_REJECTED: {
            group: 1,
            repetition: LOG_REPETITION_TYPE.SINGLE,
            message: $translate('LOGS.TOS_REJECTED'),
            accent: ACCENT_CLASS.DANGER
        },

        // Customer\'s device failed to stream Video
        VIDEOSTREAM_FAILED: {
            group: 1,
            repetition: LOG_REPETITION_TYPE.SINGLE,
            message: $translate('LOGS.VIDEOSTREAM_FAILED'),
            accent: ACCENT_CLASS.DANGER
        },

        // Customer\'s device failed to use OneClick photo
        PHOTO_FAILED: {
            group: 1,
            repetition: LOG_REPETITION_TYPE.SINGLE,
            message: $translate('LOGS.PHOTO_FAILED'),
            accent: ACCENT_CLASS.DANGER
        },

        // Connected in PhotoChat mode
        CONNECTED_IN_IMAGE_UPLOAD_MODE: {
            group: 1,
            repetition: LOG_REPETITION_TYPE.SINGLE,
            message: $translate('LOGS.CONNECTED_IN_IMAGE_UPLOAD_MODE'),
            accent: ACCENT_CLASS.NORMAL
        },

        // Connected in OneClickChat mode
        CONNECTED_IN_PHOTO_MODE: {
            group: 1,
            repetition: LOG_REPETITION_TYPE.SINGLE,
            message: $translate('LOGS.CONNECTED_IN_PHOTO_MODE'),
            accent: ACCENT_CLASS.NORMAL
        },

        // Waiting for Customer to tap the camera button
        WAITING_FOR_TAP_THE_CAMERA_BUTTON: {
            group: 1,
            repetition: LOG_REPETITION_TYPE.SINGLE,
            message: $translate('LOGS.WAITING_FOR_TAP_THE_CAMERA_BUTTON'),
            accent: ACCENT_CLASS.WARNING
        },

        // Customer\'s device failed to open the Video Camera
        VIDEOCAMERA_FAILED: {
            group: 1,
            repetition: LOG_REPETITION_TYPE.SINGLE,
            message: $translate('LOGS.VIDEOCAMERA_FAILED'),
            accent: ACCENT_CLASS.DANGER
        },

        // Video paused by customer
        VIDEO_PAUSED_BY_CUSTOMER: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.VIDEO_PAUSED_BY_CUSTOMER'),
            accent: ACCENT_CLASS.NORMAL
        },

        // Video paused by expert
        VIDEO_PAUSED_BY_EXPERT: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.VIDEO_PAUSED_BY_EXPERT'),
            accent: ACCENT_CLASS.NORMAL
        },

        // Video resumed
        VIDEO_RESUMED: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.VIDEO_RESUMED'),
            accent: ACCENT_CLASS.NORMAL
        },

        // Screen share paused by customer
        SCREEN_SHARE_PAUSED_BY_CUSTOMER: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.SCREEN_SHARE_PAUSED_BY_CUSTOMER'),
            accent: ACCENT_CLASS.NORMAL
        },

        // Screen share paused by expert
        SCREEN_SHARE_PAUSED_BY_EXPERT: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.SCREEN_SHARE_PAUSED_BY_EXPERT'),
            accent: ACCENT_CLASS.NORMAL
        },

        // Screen share resumed
        SCREEN_SHARE_RESUMED: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.SCREEN_SHARE_RESUMED'),
            accent: ACCENT_CLASS.NORMAL
        },

        // App share paused by customer
        APP_SHARE_PAUSED_BY_CUSTOMER: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.APP_SHARE_PAUSED_BY_CUSTOMER'),
            accent: ACCENT_CLASS.NORMAL
        },

        // App share paused by expert
        APP_SHARE_PAUSED_BY_EXPERT: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.APP_SHARE_PAUSED_BY_EXPERT'),
            accent: ACCENT_CLASS.NORMAL
        },

        // App share resumed
        APP_SHARE_RESUMED: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.APP_SHARE_RESUMED'),
            accent: ACCENT_CLASS.NORMAL
        },

        // Audio muted
        AUDIO_MUTED: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.AUDIO_MUTED'),
            accent: ACCENT_CLASS.NORMAL
        },

        // Audio unmuted
        AUDIO_UNMUTED: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.AUDIO_UNMUTED'),
            accent: ACCENT_CLASS.NORMAL
        },

        // Customer received the image
        GOT_IMAGE: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.UNIQUE,
            message: $translate('LOGS.GOT_IMAGE'),
            accent: ACCENT_CLASS.NORMAL
        },

        // Customer received the video
        GOT_VIDEO: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.UNIQUE,
            message: $translate('LOGS.GOT_VIDEO'),
            accent: ACCENT_CLASS.NORMAL
        },

        // Customer received the text message
        GOT_TEXT: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.UNIQUE,
            message: $translate('LOGS.GOT_TEXT'),
            accent: ACCENT_CLASS.NORMAL
        },

        // Customer sent the text message
        SENT_TEXT: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.UNIQUE,
            message: $translate('LOGS.SENT_TEXT'),
            accent: ACCENT_CLASS.NORMAL
        },

        // Customer click continue session
        END_MEETING_CANCELLED: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.UNIQUE,
            message: $translate('LOGS.END_MEETING_CANCELLED'),
            accent: ACCENT_CLASS.NORMAL
        },

        // Customer clicked "End Meeting" button
        END_MEETING_POP_UP: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.UNIQUE,
            message: $translate('LOGS.END_MEETING_POP_UP'),
            accent: ACCENT_CLASS.WARNING
        },

        // Customer shown an upload confirmation pop up
        UPLOAD_CONFIRMATION_POP_UP: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.UNIQUE,
            message: $translate('LOGS.UPLOAD_CONFIRMATION_POP_UP'),
            accent: ACCENT_CLASS.NORMAL
        },

        // Customer approved upload confirmation pop up
        UPLOAD_CONFIRMATION_OK: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.UNIQUE,
            message: $translate('LOGS.UPLOAD_CONFIRMATION_OK'),
            accent: ACCENT_CLASS.NORMAL
        },

        // Customer cancelled upload confirmation pop up
        UPLOAD_CONFIRMATION_CANCEL: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.UNIQUE,
            message: $translate('LOGS.UPLOAD_CONFIRMATION_CANCEL'),
            accent: ACCENT_CLASS.WARNING
        },

        // Customer in Photo Gallery page
        IN_GALLERY_PAGE: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.IN_GALLERY_PAGE'),
            accent: ACCENT_CLASS.NORMAL
        },

        // Customer switched to PhotoChat mode
        CUSTOMER_SWITCHED_TO_IMAGE_UPLOAD: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.UNIQUE,
            message: $translate('LOGS.CUSTOMER_SWITCHED_TO_IMAGE_UPLOAD'),
            accent: ACCENT_CLASS.NORMAL
        },

        // Customer switched to OneClickChat mode
        CUSTOMER_SWITCHED_TO_PHOTO: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.UNIQUE,
            message: $translate('LOGS.CUSTOMER_SWITCHED_TO_PHOTO'),
            accent: ACCENT_CLASS.NORMAL
        },

        // Customer switched to VideoChat mode
        CUSTOMER_SWITCHED_TO_VIDEOCHAT: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.UNIQUE,
            message: $translate('LOGS.CUSTOMER_SWITCHED_TO_VIDEOCHAT'),
            accent: ACCENT_CLASS.NORMAL
        },

        // Customer switched to ScreenShare mode
        CUSTOMER_SWITCHED_TO_SCREENSHARE: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.UNIQUE,
            message: $translate('LOGS.CUSTOMER_SWITCHED_TO_SCREENSHARE'),
            accent: ACCENT_CLASS.NORMAL
        },

        // Customer switched to ApplicationSharing mode
        CUSTOMER_SWITCHED_TO_APPLICATION_SHARING: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.UNIQUE,
            message: $translate('LOGS.CUSTOMER_SWITCHED_TO_APPLICATION_SHARING'),
            accent: ACCENT_CLASS.NORMAL
        },

        // Customer ended the meeting
        CUSTOMER_ENDED_THE_MEETING: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.CUSTOMER_ENDED_THE_MEETING'),
            accent: ACCENT_CLASS.WARNING,
            endsMeeting: true
        },

        // Customer opened the camera
        CUSTOMER_OPENED_THE_CAMERA: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.CUSTOMER_OPENED_THE_CAMERA'),
            accent: ACCENT_CLASS.NORMAL
        },

        // Waiting for customer to take a photo, and tap the Save or Use button
        WAITING_FOR_TAP_SAVE_BUTTON: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.WAITING_FOR_TAP_SAVE_BUTTON'),
            accent: ACCENT_CLASS.NORMAL
        },

        // Customer closed the camera, back to PhotoChat page
        CUSTOMER_CLOSED_THE_CAMERA: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.CUSTOMER_CLOSED_THE_CAMERA'),
            accent: ACCENT_CLASS.NORMAL
        },

        // Customer is uploading an image
        CUSTOMER_UPLOADING_AN_IMAGE: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.CUSTOMER_UPLOADING_AN_IMAGE'),
            accent: ACCENT_CLASS.NORMAL
        },

        // Customer is uploading a video
        CUSTOMER_UPLOADING_A_VIDEO: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.CUSTOMER_UPLOADING_A_VIDEO'),
            accent: ACCENT_CLASS.NORMAL
        },

        // Customer failed uploading a video due to size limitation
        CUSTOMER_FAILED_UPLOADING_VIDEO_OVER_MAX_SIZE: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.CUSTOMER_FAILED_UPLOADING_VIDEO_OVER_MAX_SIZE'),
            accent: ACCENT_CLASS.WARNING
        },

        CUSTOMER_S3_IMAGE_UPLOAD_FAILED: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.CUSTOMER_S3_IMAGE_UPLOAD_FAILED'),
            accent: ACCENT_CLASS.DANGER
        },

        CUSTOMER_S3_VIDEO_UPLOAD_FAILED: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.CUSTOMER_S3_VIDEO_UPLOAD_FAILED'),
            accent: ACCENT_CLASS.DANGER
        },

        // Customer is in PhotoChat page
        CUSTOMER_IN_IMAGE_UPLOAD: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.CUSTOMER_IN_IMAGE_UPLOAD'),
            accent: ACCENT_CLASS.NORMAL
        },

        // Customer is in Photo Gallery page
        CUSTOMER_IN_PHOTOGALLERY_PAGE: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.CUSTOMER_IN_PHOTOGALLERY_PAGE'),
            accent: ACCENT_CLASS.NORMAL
        },

        // Customer exited Photo Gallery page
        CUSTOMER_CLOSED_PHOTOGALLERY_PAGE: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.CUSTOMER_CLOSED_PHOTOGALLERY_PAGE'),
            accent: ACCENT_CLASS.NORMAL
        },

        // Failed to connecto to TechSee Video Streaming service. Switching to PhotoChat mode
        DASHBOARD_CONNECT_TO_STREAM_FAILED: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.DASHBOARD_CONNECT_TO_STREAM_FAILED'),
            accent: ACCENT_CLASS.DANGER
        },

        // Client fail to connect to TechSee Video Streaming service. Switching to PhotoChat mode
        CLIENT_CONNECT_TO_STREAM_FAILED: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.CLIENT_CONNECT_TO_STREAM_FAILED'),
            accent: ACCENT_CLASS.DANGER
        },

        // Failed to receive video stream from mobile device. Switching to PhotoChat mode
        SUBSCRIBE_FAILED: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.SUBSCRIBE_FAILED'),
            accent: ACCENT_CLASS.DANGER
        },

        // Failed to receive video stream from mobile device. Switching to PhotoChat mode
        BAD_BANDWIDTH: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.BAD_BANDWIDTH'),
            accent: ACCENT_CLASS.DANGER
        },

        // Mobile client network issue detected. Possible reasons: No internet connection
        // or blocking by Firewall or Proxy server. Switching to PhotoChat mode
        CLIENT_NETWORK_ISSUE: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.CLIENT_NETWORK_ISSUE'),
            accent: ACCENT_CLASS.DANGER
        },

        // Network issue detected. Possible reasons: No internet connection
        // or blocking by Firewall or Proxy server. Switching to PhotoChat mode
        DASHBOARD_NETWORK_ISSUE: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.DASHBOARD_NETWORK_ISSUE'),
            accent: ACCENT_CLASS.DANGER
        },

        // Failed to receive audio stream from mobile device
        DASHBOARD_SUBSCRIBE_AUDIO_FAILED: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('DASHBOARD_SUBSCRIBE_AUDIO_FAILED'),
            accent: ACCENT_CLASS.DANGER
        },

        // Fail to start Audio Streaming
        DASHBOARD_PUBLISH_AUDIO_FAILED: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.DASHBOARD_PUBLISH_AUDIO_FAILED'),
            accent: ACCENT_CLASS.DANGER
        },

        // Client fail to start Video Streaming. Switching to PhotoChat mode
        PUBLISH_FAILED: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.PUBLISH_FAILED'),
            accent: ACCENT_CLASS.DANGER
        },

        // Client fail to start Audio Streaming
        CLIENT_PUBLISH_AUDIO_FAILED: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.CLIENT_PUBLISH_AUDIO_FAILED'),
            accent: ACCENT_CLASS.DANGER
        },

        // Client failed to receive audio stream from the dashboard
        CLIENT_SUBSCRIBE_AUDIO_FAILED: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.CLIENT_SUBSCRIBE_AUDIO_FAILED'),
            accent: ACCENT_CLASS.DANGER
        },

        VIDEO_BANDWIDTH_TOO_LOW: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.SINGLE,
            message: $translate('LOGS.VIDEO_BANDWIDTH_TOO_LOW'),
            accent: ACCENT_CLASS.NORMAL
        },

        CONNECTIVITY_DIFFICULTIES: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.CONNECTIVITY_DIFFICULTIES'),
            accent: ACCENT_CLASS.WARNING
        },

        LOW_VIDEO_BANDWIDTH_DETECTED: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.LOW_VIDEO_BANDWIDTH_DETECTED'),
            accent: ACCENT_CLASS.WARNING
        },

        VIDEO_BANDWIDTH_STABILIZED: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.VIDEO_BANDWIDTH_STABILIZED'),
            accent: ACCENT_CLASS.NORMAL
        },

        CLIENT_LOCATION: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.SINGLE,
            message: $translate('LOGS.CLIENT_LOCATION'),
            accent: ACCENT_CLASS.WARNING
        },

        CLIENT_LOCATION_ERROR: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.SINGLE,
            message: $translate('LOGS.CLIENT_LOCATION_ERROR'),
            accent: ACCENT_CLASS.DANGER
        },

        ENTERED_OFFLINE_ROOM: {
            group: -1,
            repetition: LOG_REPETITION_TYPE.SINGLE,
            message: $translate('LOGS.ENTERED_OFFLINE_ROOM'),
            accent: ACCENT_CLASS.WARNING
        },

        // Customer is reloading to reprompt camera permissions dialog
        RE_PROMPTING_PERMISSIONS: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.RE_PROMPTING_PERMISSIONS'),
            accent: ACCENT_CLASS.NORMAL
        },

        OBJECT_ANALYZING_ERROR: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('OBJECT_RECOGNITION.OBJECT_NOT_KNOWN'),
            accent: ACCENT_CLASS.WARNING
        },
        OBJECT_ANALYZING_SUCCESS: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('OBJECT_RECOGNITION.OBJECT_RECOGNIZED'),
            accent: ACCENT_CLASS.NORMAL
        },
        OBJECT_ANALYZING_START_TRACKING: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('OBJECT_RECOGNITION.START_TRACKING'),
            accent: ACCENT_CLASS.NORMAL
        },
        DEVICE_ANALYZING_ERROR: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.MULTI,
            message: $translate('LOGS.OBJECT_ANALYZING_ERROR'),
            accent: ACCENT_CLASS.DANGER
        },

        WAITING_FOR_CAMERA_PRE_APPROVAL: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.SINGLE,
            message: $translate('LOGS.WAITING_FOR_CAMERA_PRE_APPROVAL'),
            accent: ACCENT_CLASS.NORMAL
        },

        CAMERA_PRE_APPROVAL_ACCEPTED: {
            group: 2,
            repetition: LOG_REPETITION_TYPE.SINGLE,
            message: $translate('LOGS.CAMERA_PRE_APPROVAL_ACCEPTED'),
            accent: ACCENT_CLASS.NORMAL
        }
    };
})();
