import {action, computed, observable} from 'mobx';
import {IDomUtilsService} from '@techsee/techsee-client-infra/lib/services/DomUtilsService';
import {IFieldModel, SimpleFieldModel} from '@techsee/techsee-ui-common/lib/forms/_shared/simple-field-model';
import {ITranslate} from '../../services/LocalizationService';
import {getRootStore} from '../../app.bootstrap';
// @ts-ignore
import patterns from '@techsee/techsee-common/lib/constants/utils.patterns';

export interface IGuestController {
    readonly translate: ITranslate;
    readonly _domUtilsService: IDomUtilsService;
    readonly isSessionEnded: boolean;
    readonly sessionEndedMessage: string;
    readonly isGuestLogin: boolean;
    readonly isPhone: boolean;
    readonly joinInProgress: boolean;
    readonly errorMessage?: string;
    firstName: IFieldModel;
    lastName: IFieldModel;

    loadImage(img: string): string;
    continueAsGuest(): void;
    goToLogin: () => void;
    joinSession: () => void;
}

export class GuestController implements IGuestController {
    private readonly _translate: ITranslate;

    readonly isSessionEnded: boolean;

    readonly sessionEndedMessage: string;

    readonly isPhone: boolean;

    readonly _domUtilsService: IDomUtilsService;

    readonly goToLogin: () => void;

    readonly _joinSession: (firstName: string, lastName: string) => Promise<void>;

    @observable private _isGuest: boolean = false;

    @observable joinInProgress: boolean = false;

    @observable firstName: IFieldModel = new SimpleFieldModel({value: '', placeholder: 'First Name'});

    @observable lastName: IFieldModel = new SimpleFieldModel({value: '', placeholder: 'Last Name'});

    @observable errorMessage: string | undefined;

    constructor(
        translate: ITranslate,
        domUtilsService: IDomUtilsService,
        goToLogin: () => void,
        joinSession: (firstName: string, lastName: string) => Promise<void>,
        sessionState: {ended: boolean; isExpired: boolean}
    ) {
        this._translate = translate;
        this._domUtilsService = domUtilsService;
        this.goToLogin = goToLogin;
        this._joinSession = joinSession;
        this.isSessionEnded = sessionState.ended;
        this.isPhone = getRootStore().environmentService.isPhone();
        this.sessionEndedMessage = sessionState.isExpired
            ? this._translate('REACT.OBSERVER_STATE.SESSION_EXPIRED')
            : this._translate('REACT.OBSERVER_STATE.THANK_YOU');

        this.continueAsGuest = this.continueAsGuest.bind(this);
        this.joinSession = this.joinSession.bind(this);
    }

    @action
    continueAsGuest() {
        this._isGuest = true;
    }

    @computed
    get isGuestLogin() {
        return this._isGuest;
    }

    _validateFieldValue(fieldValue: string) {
        const pattern = new RegExp(patterns.numbersAndNonAlphabetCharacters),
            requiredText = 'REACT.OBSERVER_STATE.FIELD_IS_REQUIRED',
            validationErrorText =
                fieldValue && fieldValue.length > 1
                    ? 'REACT.OBSERVER_STATE.FIELD_IS_INVALID_SPECIAL_CHAR'
                    : 'REACT.OBSERVER_STATE.FIELD_IS_INVALID';

        return (!fieldValue && requiredText) || (pattern.test(fieldValue) && validationErrorText) || '';
    }

    validateForm() {
        let message = '',
            isFormValid = true;

        const firstName = this.firstName.value.toString() || '',
            lastName = this.lastName.value.toString() || '',
            firstNameValidation = this._validateFieldValue(firstName),
            lastNameValidation = this._validateFieldValue(lastName);

        if (firstNameValidation || lastNameValidation) {
            message = firstNameValidation ? firstNameValidation : lastNameValidation;

            const fieldName = firstNameValidation
                ? 'REACT.OBSERVER_STATE.FIRST_NAME_FIELD'
                : 'REACT.OBSERVER_STATE.LAST_NAME_FIELD';

            this.updateErrorMessage(message, this.translate(fieldName));

            isFormValid = false;
        }

        return isFormValid;
    }

    @action
    updateErrorMessage(message: string, fieldName: string) {
        this.errorMessage = message ? this.translate(message, {field: fieldName}) : '';
    }

    @action
    joinSession() {
        this.updateErrorMessage('', '');

        if (!this.validateForm()) {
            return;
        }

        this.joinInProgress = true;
        const firstName = this.firstName.value.toString(),
            lastName = this.lastName.value.toString();

        return this._joinSession(firstName, lastName).finally(() => {
            this.joinInProgress = false;
        });
    }

    loadImage(img: string) {
        return this._domUtilsService.loadImage(img);
    }

    get translate() {
        return this._translate;
    }
}
