import {action, computed, observable} from 'mobx';
import {ITranslate} from '../../../services/LocalizationService';

export interface IConnectivityCheckModalController {
    translate: ITranslate;
    readonly isConnectivityModalVisible: boolean;
    show(): void;
    hide(): void;
}

export class ConnectivityCheckModalController implements IConnectivityCheckModalController {
    @observable private _isVisible: boolean;

    private _translate: ITranslate;

    constructor(translate: ITranslate) {
        this._translate = translate;
        this._isVisible = false;

        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
    }

    get translate() {
        return this._translate;
    }

    @computed
    get isConnectivityModalVisible() {
        return this._isVisible;
    }

    @action
    show() {
        this._isVisible = true;
    }

    @action
    hide() {
        this._isVisible = false;
    }
}
