import {observable} from 'mobx';
import {createModelSchema, list, object} from 'serializr';
import {SendSmsStatus} from './SendSmsStatus';

import {InviteStepsEnum} from './InviteContracts';
import {InviteMethodInfo, NetworkInfo, SessionInfo, SessionStateBase} from '../../../models/LiveSessionState';

export class InviteState extends SessionStateBase {
    @observable smsFlowProgress: SendSmsStatus[] = [];

    @observable lastVisibleStep: string = InviteStepsEnum.InviteMethod;

    @observable isTosRejected: boolean = false;

    @observable isTosRepromptSent: boolean = false;
}

createModelSchema(InviteState, {
    inviteMethodInfo: object(InviteMethodInfo),
    sessionInfo: object(SessionInfo),
    clientNetworkInfo: object(NetworkInfo),
    smsFlowProgress: list(object(SendSmsStatus)),
    '*': true
});
