import React from 'react';
import {observer} from 'mobx-react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {ISimpleModalController} from './controller';
import {ConfirmationModal} from '@techsee/techsee-ui-common/lib/modals/confirmation-modal';
import classNames from 'classnames';

interface SimpleModalProps {
    ctrl: ISimpleModalController;
}

@observer
export class SimpleModal extends TechseeBaseComponent<SimpleModalProps> {
    renderInternal(): JSX.Element {
        const {ctrl} = this.props;

        return (
            <ConfirmationModal
                contentTitle={ctrl.contentTitle}
                content={ctrl.content}
                negativeText={ctrl.negativeText}
                positiveText={ctrl.positiveText}
                show={ctrl.isModalVisible}
                onPositiveClick={() => ctrl.onPositiveClick()}
                onNegativeClick={() => ctrl.onNegativeClick()}
                className={classNames('simple-modal', ctrl.modalClassName)}
            />
        );
    }
}
