/* eslint-disable @typescript-eslint/no-explicit-any */
'use strict';

import {action, computed, observable} from 'mobx';

interface IMessagesService {
    resources: any[];
    dispose(): void;
}

export class MessagesService implements IMessagesService {
    @observable private readonly _resources: any[] = [];

    @observable imageCounter: number = 0;

    @observable videoCounter: number = 0;

    private _chatApi: any;

    static $inject = ['tsChatApi'];

    constructor(tsChatApi: any) {
        this._chatApi = tsChatApi.service;
        this._messageHandler = this._messageHandler.bind(this);
        this._chatApi.on('message', this._messageHandler);
    }

    @action
    private _messageHandler(message: any): void {
        if (!message.data.private && message.data.type === 'image') {
            this.imageCounter++;
        } else if (!message.data.private && message.data.type === 'video') {
            this.videoCounter++;
        }

        if (message.data.type === 'image' || message.data.type === 'video') {
            this._resources.push(message);
        }
    }

    @computed
    get resources() {
        return this._resources;
    }

    @action
    dispose() {
        this._chatApi.removeListener('message', this._messageHandler);
        this._resources.length = 0;
    }
}
