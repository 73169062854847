import React from 'react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {PrimaryButton} from '@techsee/techsee-ui-common/lib/forms/buttons/primary';
import {AddressTypesEnum} from '@techsee/techsee-common/lib/constants/room.constants';

import './ts-idle-mode.style.scss';

interface TsIdleModeProps {
    notification: string;
    inviteMethod: string;
    buttonText: string;

    onSendSmsClick(): void;

    isSmsSent: boolean;
    dir: string;
    connectingText: string;
}

function _isSmsSendButtonDisabled(isSmsSent: any) {
    return isSmsSent || typeof isSmsSent === 'undefined';
}

export class TsIdleMode extends TechseeBaseComponent<TsIdleModeProps> {
    renderInternal(): JSX.Element {
        return (
            <div className={'idle-mode-container'} dir={this.props.dir}>
                <div className={'connecting-text'}>{this.props.connectingText}</div>
                <div className={'idle-mode'}>
                    {this.props.isSmsSent ? <div className={'sms-sent-img'} /> : <div className={'idle-img'}></div>}
                </div>

                <div className={'idle-notification'} dangerouslySetInnerHTML={{__html: this.props.notification}}></div>

                {this.props.inviteMethod === AddressTypesEnum.SMS && (
                    <PrimaryButton
                        disabled={_isSmsSendButtonDisabled(this.props.isSmsSent)}
                        onClick={() => this.props.onSendSmsClick()}>
                        {this.props.buttonText}
                    </PrimaryButton>
                )}
            </div>
        );
    }
}
