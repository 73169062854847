'use strict';

import throttle from 'lodash/throttle';
import assign from 'lodash/assign';
import get from 'lodash/get';
import debounce from 'lodash/debounce';

import ResizeSensor from 'css-element-queries/src/ResizeSensor';
import {VideoMetaData} from './video-positioning/abstractions';
import {OldMinimizedVideoStrategy} from './video-positioning/minimized-old-video';
import {StretchedVideoStrategy} from './video-positioning/maximized-stretched-video';
import {MinimizedCircleVideoStrategy} from './video-positioning/minimized-circle-video';
import {KnownMediaStream, VideoResolutionFormat} from '@techsee/techsee-media-service/lib/MediaConstants';
import {
    MousePressStatus,
    mouseClickType,
    MouseButtonPosition
} from '@techsee/techsee-common/lib/constants/remote-control.constants';
import {getAppTracer} from '../../app.tracer';
import {getRootStore} from '../../_react_/app.bootstrap';
import {CanvasDrawer} from '@techsee/techsee-client-services/lib/services/CanvasDrawerService';
import {CanvasEvents} from '@techsee/techsee-client-infra/lib/helpers/CanvasEvents';

const trace = getAppTracer('TsVideoSubscriberController');

class TsVideoSubscriberController {
    // eslint-disable-next-line max-params
    constructor(
        db,
        tsChatApi,
        $window,
        tsResponsiveUtils,
        $timeout,
        tsScan,
        tsScanArea,
        $rootScope,
        $modal,
        mediaFrameUIParams,
        tsInterfaceHelper,
        $scope,
        tsSnapshotReminderService,
        eventLog
    ) {
        'ngInject';

        this.$modal = $modal;
        this.db = db;
        this.chatApi = tsChatApi.service;
        this.$window = $window;
        this.environmentDetect = getRootStore().environmentService;
        this.isIE11 = this.environmentDetect.isIE11();
        this.displayTabletAsDesktop = getRootStore().displayTabletAsDesktop;
        this.$timeout = $timeout;
        this.tsScan = tsScan;
        this.tsScanArea = tsScanArea;
        this.mediaFrameUIParams = mediaFrameUIParams;
        this.tsInterfaceHelper = tsInterfaceHelper;
        this.tsSnapshotReminder = tsSnapshotReminderService;
        this.canvasDrawer = null;
        this.responsiveUtils = tsResponsiveUtils;
        this._pointer = null;
        this.isTablet = this.environmentDetect.isTablet(this.displayTabletAsDesktop);
        this.settings = {};
        this._eventLog = eventLog;
        this._element = null;
        this._containerWidth = 1;
        this._containerHeight = 1;
        this._isHeld = false;
        this._isMoving = false;
        this.isPainting = false;
        this.lastY = 0;
        this.lastX = 0;
        this.drawingCanvasFadeTimeout = null;
        this.paintedCordinates = [];
        this.mouseClicks = null;
        this.mousePressStatus = null;
        this.mouseButtonPosition = MouseButtonPosition.Left;
        this.updateCursorPosition = debounce(this._updateCursorPosition.bind(this), 5);
        this.mouseClickType = mouseClickType.Click;

        this._frame = {
            left: 0,
            top: 0,
            width: 1,
            height: 1
        };

        $scope.$watch(
            () => this.isMinimized,
            () => {
                this._updateFrameAndOffset();
                this.videoResolutionValue && this._videoResolutionDimensionChange(this.videoResolutionValue);
            }
        );

        $rootScope.$watch(
            () => this.settings.mode,
            () => {
                this._isHeld = false;
                this._isMoving = false;
            }
        );

        $rootScope.$watch(
            () => this.settings.focus,
            () => {
                this._updateAfterProcessHasEnded();
            }
        );

        $rootScope.$watch(
            () => this.settings.isClientVisible,
            () => {
                this._updateFrameAndOffset();
            }
        );

        this._videoSubscriberStateChanged = this._videoSubscriberStateChanged.bind(this);
        this.updateFrame = throttle(this._updateFrame.bind(this), this.isIE11 ? 1000 : 200);
        this._updateAfterProcessHasEnded = this._updateAfterProcessHasEnded.bind(this);
        this._updateFrameAndOffset = this._updateFrameAndOffset.bind(this);

        this._scrollScreenOnce = this._scrollScreenOnce.bind(this);
        this.scrollScreen = debounce(this._scrollScreenOnce, 5, {
            leading: true,
            trailing: false
        });

        this._videoResolutionDimensionChange = this._videoResolutionDimensionChange.bind(this);
        this._handleClickType = this._handleClickType.bind(this);
    }

    get videoStreamWidth() {
        return this.videoSubscriber && this.videoSubscriber.mediaElement ? this.videoSubscriber.videoWidth : 0;
    }

    get videoStreamHeight() {
        return this.videoSubscriber && this.videoSubscriber.mediaElement ? this.videoSubscriber.videoHeight : 0;
    }

    get annotationCanvas() {
        return this._annotationCanvas || (this._annotationCanvas = this._element.find('.video-annotations'));
    }

    get localDrawingCanvas() {
        return this._localDrawingCanvas || (this._localDrawingCanvas = this._element.find('.local-drawing-canvas'));
    }

    get remoteDrawingCanvas() {
        return this._remoteDrawingCanvas || (this._remoteDrawingCanvas = this._element.find('.remote-drawing-canvas'));
    }

    _isAnnotationMode() {
        const mode = this.settings.mode,
            {SCANNING} = this.MODES;

        return mode === SCANNING;
    }

    init(element) {
        this._element = element;
        this._onDraw = (param, data) => {
            this._updateCanvas(param, data);

            if (data.length) {
                if (this.onMagicMarkerReceived) {
                    this.onMagicMarkerReceived();
                }

                this._eventLog.magicMarkerDrawingReceived();
            }
        };

        this.mobile = this.environmentDetect.isMobile();
        this.isPhone = this.environmentDetect.isPhone();

        if (!this._pointer && this.mobile && this.settings.enableDashboardMagicMarker) {
            this._pointer = $('<div>').addClass('live-pointer-mobile').hide();
            this._element.append(this._pointer);
        }

        this.chatApi.on('clientDrawings', this._onDraw);

        const [start, move, cancel, end] =
            this.isTablet || this.isPhone
                ? ['touchstart', 'touchmove', 'touchcancel', 'touchend']
                : ['mousedown', 'mousemove', 'mouseleave', 'mouseup'];
        let startX = 0;
        let startY = 0;

        this._element.on(start, (e) => {
            const canvas = this.annotationCanvas.get(0);

            if (this.remoteControl) {
                this._mouseClick(e);
                const extraCursorData = {type: this.mouseClickType};

                this.mousePressStatus = MousePressStatus.Down;
                this.updateCursorPosition(e, extraCursorData);
            }

            if (e.target !== canvas) {
                this._isHeld = true;

                return;
            }

            this._isHeld = true;
            ({x: startX, y: startY} = this._getCursorPos(e));
        });

        this._element.on(move, (e) => {
            if (this._isHeld && !this.tsScanArea.initialized) {
                if (!this.isMovingTimeout) {
                    this.isMovingTimeout = setTimeout(() => {
                        this._isMoving = true;
                        if (this.isIE11) {
                            this.applySettings();
                        }
                    }, 200);
                }

                const ev = e.originalEvent;
                const clientX = this.mobile && ev.touches ? ev.touches[0].clientX : ev.clientX;
                const clientY = this.mobile && ev.touches ? ev.touches[0].clientY : ev.clientY;
                const x = clientX - this._element.width() / 2;
                const y = clientY - this._element.height() / 2;

                if (!this.settings.focus) {
                    this._element.css({
                        top: y + 'px',
                        left: x + 'px',
                        right: 'auto'
                    });
                }

                if (this._isAnnotationMode() && !this.isScanCaptureDesign) {
                    const canvas = this.annotationCanvas.get(0);
                    const ctx = canvas.getContext('2d');

                    ctx.clearRect(0, 0, canvas.width, canvas.height);

                    let cx = 0;
                    let cy = 0;

                    ({x: cx, y: cy} = this._getCursorPos(e));

                    const sx = cx > startX ? startX : cx,
                        sy = cy > startY ? startY : cy,
                        width = Math.abs(cx - startX),
                        height = Math.abs(cy - startY);

                    ctx.strokeStyle = 'green';
                    ctx.strokeRect(sx, sy, width, height);
                }

                // disable Android pull-to-refresh gesture
                e.preventDefault();
            }
        });

        this._element.on(cancel, () => {
            if (!this._isAnnotationMode()) {
                this._isHeld = false;
            }

            clearTimeout(this.isMovingTimeout);
            this.isMovingTimeout = null;

            this._isMoving = false;
        });

        this._element.on(end, (e) => {
            if (this.remoteControl) {
                this._handleClickType();
                this._mouseClick(e);
                const extraCursorData = {type: this.mouseClickType};

                this.mousePressStatus = MousePressStatus.Up;
                this.updateCursorPosition(e, extraCursorData);
            }

            clearTimeout(this.isMovingTimeout);

            this.isMovingTimeout = null;

            if (this._isMoving && this._isAnnotationMode()) {
                const {x, y} = this._getCursorPos(e),
                    rect = {
                        x: x > startX ? startX : x,
                        y: y > startY ? startY : y,
                        width: Math.abs(x - startX),
                        height: Math.abs(y - startY)
                    };

                if (this.settings.mode === this.MODES.SCANNING && !this.isScanCaptureDesign) {
                    this.tsScan.emit('areaSelected', {rect});
                }
            }

            if (!this._isMoving && !this.settings.focus) {
                this.restoreFromFocusLoss();
            }

            this._isHeld = false;
            setTimeout(() => (this._isMoving = false));
        });

        if (this.remoteControl) {
            this._element.bind('keyup keydown', (event) => {
                this.chatApi.setStatusFast('keyboard', {
                    altKey: event.altKey,
                    ctrlKey: event.ctrlKey,
                    shiftKey: event.shiftKey,
                    key: event.key,
                    keyCode: event.keyCode,
                    type: event.type
                });
            });

            this.$window.addEventListener('wheel', this.scrollScreen);
        }

        this.$window.addEventListener('resize', this._updateAfterProcessHasEnded);

        this._initMainContainerSizeSensor();
    }

    onDrawingCanvasStartDraw(e) {
        if (!this.settings.isPaused) {
            const {
                detail: {data}
            } = e;
            const {x, y} = this._getCursorPos(data);

            this.chatApi.setStatusFast('drawings', [], true);

            if (this.canvasDrawer) {
                this.canvasDrawer.startDrawing(x, y);
            }
        }
    }

    onDrawingCanvasDraw(e) {
        if (!this.settings.isPaused && this.canvasDrawer && this.canvasDrawer.isPainting) {
            const {
                detail: {data}
            } = e;
            const {x, y} = this._getCursorPos(data);

            this.canvasDrawer.addToDrawing(x, y, {
                width: this.localDrawingCanvas.get(0).width,
                height: this.localDrawingCanvas.get(0).height
            });
        }

        if (e && this.settings.focus && this.settings.enabled && !this.settings.isPaused) {
            const {
                detail: {data}
            } = e;

            this.updateCursorPosition(data);
        }
    }

    onDrawingCanvasEndDraw() {
        if (!this.canvasDrawer || !this.canvasDrawer.isPainting) {
            return;
        }

        const getDrawings = this.canvasDrawer.getDrawings();

        if (!this.settings.isPaused) {
            this.chatApi.setStatusFast('drawings', getDrawings, true);
        }

        this.canvasDrawer.endDrawing();

        if (getDrawings.length && !this.settings.isPaused) {
            if (this.onMagicMarkerDrawn) {
                this.onMagicMarkerDrawn();
            }

            this._eventLog.magicMarkerDrawingSent();
        }
    }

    initLocalDrawingCanvas(element) {
        if (this.canvasDrawer) {
            return;
        }

        const canvasEventsHelper = new CanvasEvents();
        const floatingElements = [];

        const drawingOptions = {
            lineWidth: this.settings.magicMarker.dashboard.lineWidth,
            lineWidthRefResolution: {width: 640, height: 480},
            strokeColorRGB: this.settings.magicMarker.dashboard.strokeColorRgb
        };
        const timeUntilDrawingStartFading = this.settings.magicMarker.dashboard.timeUntilDrawingStartFading;

        this.canvasDrawer = new CanvasDrawer(element, drawingOptions, undefined, timeUntilDrawingStartFading);

        if (!this.isMiniDashboard && !this.mobile) {
            const floatingBar = document.querySelector('ts-floating-toolbar');
            const snapshotButton = document.querySelector('.magic-marker-screenshot');
            const recognizeWrapper = document.querySelector('.ts-recognize-wrapper');

            floatingElements.push(floatingBar);
            floatingElements.push(snapshotButton);
            floatingElements.push(recognizeWrapper);
        }

        if (this.isMiniDashboard) {
            const miniDashboardImgStrip = document.querySelector('.ts-mini-dashboard-img-strip');

            floatingElements.push(miniDashboardImgStrip);
        }
        const touchRelevant = this.isTablet || this.isPhone;

        canvasEventsHelper.initListeners(element, touchRelevant, floatingElements);

        element.addEventListener('drawingCanvasStartDraw', (e) => this.onDrawingCanvasStartDraw(e));
        element.addEventListener('drawingCanvasDraw', (e) => this.onDrawingCanvasDraw(e));
        element.addEventListener('drawingCanvasEndDraw', () => this.onDrawingCanvasEndDraw());
    }

    destroy() {
        this.$window.removeEventListener('wheel', this.scrollScreen);
        this.$window.removeEventListener('resize', this._updateAfterProcessHasEnded);
        this.chatApi.removeListener('clientDrawings', this._onDraw);
        this.localDrawingCanvas[0].removeEventListener('drawingCanvasStartDraw', this.onDrawingCanvasStartDraw);
        this.localDrawingCanvas[0].removeEventListener('drawingCanvasDraw', this.onDrawingCanvasDraw);
        this.localDrawingCanvas[0].removeEventListener('drawingCanvasEndDraw', this.onDrawingCanvasEndDraw);

        if (this.canvasDrawer) {
            this.canvasDrawer.destroy();
        }

        this.canvasDrawer = null;

        if (this.remoteCanvasDrawer) {
            this.remoteCanvasDrawer.destroy();
        }

        this.remoteCanvasDrawer = null;

        this._annotationCanvas = null;
        this._localDrawingCanvas = null;
        this._remoteDrawingCanvas = null;

        if (this.videoSubscriber) {
            this.mediaService.destroySubscriber(this.videoSubscriber.container);
            this.videoSubscriber = null;
            this.onVideoSubscriberChanged && this.onVideoSubscriberChanged(null);
        }

        if (this._pointer) {
            this._pointer.hide();
        }

        this._element.hide();

        this._disposeMainContainerSizeSensor();
    }

    _updateCanvas(_, data) {
        if (data.length) {
            if (this.remoteCanvasDrawer) {
                this.remoteCanvasDrawer.drawPoints(data, {
                    width: this.remoteDrawingCanvas.get(0).width,
                    height: this.remoteDrawingCanvas.get(0).height
                });
            }
        }
    }

    applySettings() {
        trace.info('applySettings');
        this.MODES = this.settings.MODES;

        // If it the thumbnail is being moved, we prevent it from becoming
        // full when the mouse button is released
        if (this._isMoving) {
            this._isMoving = false;
            this.settings.focus = false;
            this.minimizedVideoMoved = true;
        } else if (this.settings.focus) {
            this.minimizedVideoMoved = false;
        }

        this._element.toggleClass('video-focused', this.settings.focus);
        this._element.toggleClass('video-minimized', !this.settings.focus);
        this._element.toggleClass(
            'video-cursor-sharing',
            this.settings.focus && this.settings.mode === this.MODES.CURSOR_SHARING
        );
        this._element.toggleClass('video-scanning', this.settings.mode === this.MODES.SCANNING);

        if (this.settings.mode === this.MODES.CURSOR_SHARING) {
            this.annotationCanvas.css('z-index', '-1');
        } else {
            this.annotationCanvas.css('z-index', '');
        }

        const $sizeTarget = $(this.settings.sizeTarget);

        if (!this.settings.enabled) {
            const padding = this.tsInterfaceHelper.getPadding;

            this._containerWidth = Math.max(
                $sizeTarget.width() - 100,
                this._element.width() - 100,
                this.$window.innerWidth - padding.width
            );

            this._containerHeight = Math.max(
                $sizeTarget.height(),
                this._element.height(),
                this.$window.innerHeight - padding.height
            );

            this._element.hide();
        }

        if (this.settings.enabled) {
            if (!this.videoSubscriber) {
                const subscriberParams = {
                    streamType: KnownMediaStream.USER_VIDEO_STREAM,
                    container: this._element[0]
                };

                this.mediaService.createSubscriber(subscriberParams).then((subscriber) => {
                    this.videoSubscriber = subscriber;
                    subscriber.onStateChanged(this._videoSubscriberStateChanged);
                    subscriber.onDispose(() => (this.videoSubscriber = null));
                    this.onVideoSubscriberChanged && this.onVideoSubscriberChanged(subscriber);

                    if (this.settings.enableDashboardMagicMarker) {
                        this.initLocalDrawingCanvas(this.localDrawingCanvas.get(0));
                    }

                    if (this.settings.enableCustomerMagicMarker && !this.remoteCanvasDrawer) {
                        const drawingOptions = {
                            lineWidth: this.settings.magicMarker.client.lineWidth,
                            lineWidthRefResolution: {width: 640, height: 480},
                            strokeColorRGB: this.settings.magicMarker.client.strokeColorRgb
                        };
                        const timeUntilDrawingStartFading =
                            this.settings.magicMarker.client.timeUntilDrawingStartFading;

                        this.remoteCanvasDrawer = new CanvasDrawer(
                            this.remoteDrawingCanvas.get(0),
                            drawingOptions,
                            undefined,
                            timeUntilDrawingStartFading
                        );
                    }
                });
            }
        }

        if (this.settings.mode === this.MODES.SCANNING && this.isScanCaptureDesign) {
            this.$timeout(() => this._initScanMode(), 300);
        }

        if (this.settings.mode !== this.MODES.SCANNING && this.tsScanArea.initialized && this.isScanCaptureDesign) {
            this.tsScanArea.destroy();
        }

        this._updateAfterProcessHasEnded();
    }

    _videoSubscriberStateChanged(videoResolution) {
        if (!this.videoSubscriber) {
            return;
        }

        if (videoResolution) {
            this.videoResolutionValue = videoResolution.resolutionFormat;
            this._videoResolutionDimensionChange(videoResolution.resolutionFormat);
        }

        const trackContainer =
            this.isStretchedVideoMode && !this.isMiniDashboard
                ? this._element.find('.video-tools-container')
                : this._getVideoRootElement();
        const [start, move, cancel] = this.mobile
            ? ['touchstart', 'touchmove', 'touchcancel']
            : ['mousedown', 'mousemove', 'mouseleave'];

        this.tsSnapshotReminder.videoReady(this.videoSubscriber.isPlaying);
        if (this.videoSubscriber.isPlaying) {
            trace.info('Video is playing');
            if (trackContainer.length && !trackContainer.data('isTracked')) {
                trackContainer.data('tracked', true);
                trackContainer.on(start, (e) => this._detectCursor(e));
                trackContainer.on(move, (e) => this._detectCursor(e));
                trackContainer.on(cancel, () => this._detectCursor(null));

                if (!this.mobile) {
                    trackContainer.on('auxclick', (e) => this._mouseClick(e, 'auxclick'));
                    trackContainer.on('contextmenu', (e) => e.preventDefault());
                }
            }

            this._element.show();
        } else {
            trace.info('Video is NOT playing');
            if (trackContainer.length) {
                trackContainer.data('tracked', undefined);
                trackContainer.off(start);
                trackContainer.off(move);
                trackContainer.off(cancel);
                trackContainer.off('auxclick');
                trackContainer.off('contextmenu');
            }

            if (this._pointer) {
                this._pointer.hide();
            }

            this._element.hide();
        }
        this._updateFrameAndOffset();
    }

    _initScanMode() {
        const canvas = this.annotationCanvas.get(0);

        this.tsScanArea.init(canvas);
    }

    _updateOffset(rootElement) {
        const $root = rootElement ? rootElement : this._getVideoRootElement();

        if ($root.length) {
            this._frame.top = $root.offset().top;
            this._frame.left = $root.offset().left;
        }
    }

    _updateFrame() {
        const $root = this._getVideoRootElement();

        if (!$root.length || !this.videoSubscriber || !this.videoSubscriber.isPlaying) {
            return;
        }

        if (this.isStretchedVideoMode && this.videoStreamWidth > 0 && this.videoStreamHeight > 0) {
            //We hide the element before updating it, to prevent the visual "jump" of video.
            const minimizedProcessFlag = this._element.data('isMinimizedProcessed');

            if (!this.settings.focus && !minimizedProcessFlag) {
                this._element.css('display', 'none');
                this._element.data('isMinimizedProcessed', true);
            } else if (this.settings.focus) {
                this._element.removeData('isMinimizedProcessed');
            }

            this.updateFrameNew($root);

            return;
        }

        this._frame = $root.offset();
        this._getFrameDimensions();

        if (this.settings.focus) {
            this.minimizedVideoMoved = false;
            this._element.css({top: '', left: '', position: 'relative'});
            $root.width(this._frame.width);
            $root.height(this._frame.height);
        } else {
            this._detectCursor(null);

            if (this._pointer) {
                this._pointer.hide();
            }

            if (this.minimizedVideoMoved) {
                return;
            }

            const minimizedVideoDimensions = this._getMinimizedVideoDimensions();

            $root.width(minimizedVideoDimensions.width);
            $root.height(minimizedVideoDimensions.height);
            this._element.width(minimizedVideoDimensions.width);
            this._element.height(minimizedVideoDimensions.height);
            if (!this.mobile) {
                this._element.offset(this._getMinimizeVideoOffset(minimizedVideoDimensions));
            }
        }

        // Handle layout issues in IE
        if (this.isIE11) {
            this._element.width($root.width());
            this._element.find('.OT_video-element').css({width: '', height: ''});
        }

        this._updateOffset($root);

        const isLandscapeViewOnMobile = this.mobile && this.responsiveUtils.isLandscape();

        if (isLandscapeViewOnMobile) {
            this._element.css('width', this._frame.width);
            this._element.css('height', this._frame.height);
        }

        this._updateCanvasesDimensions();

        if (!this.isIE11) {
            this.annotationCanvas.css('margin-left', -this._frame.width / 2);
        }

        this._element.find('.request-approval').css('margin-left', this._frame.width + 10);
    }

    updateFrameNew(videoElement) {
        this.isMinimized = !this.settings.focus;

        const videoMeta = new VideoMetaData(this.videoStreamWidth, this.videoStreamHeight);

        let videoStrategy = null;

        if (this.isMinimized) {
            if (this.isRoundedFloatingVideo) {
                videoStrategy = new MinimizedCircleVideoStrategy(this.$window, $);
            } else {
                videoStrategy = new OldMinimizedVideoStrategy(this.$window, (newVideoSize) =>
                    this._getMinimizeVideoOffset(newVideoSize)
                );
            }
        } else {
            videoStrategy = new StretchedVideoStrategy(this.annotationCanvas, this.$window, $);
        }

        const videoNewSize = videoStrategy.getVideoNewSize(videoMeta);

        videoStrategy.updateVideoSize(videoElement, videoNewSize, videoMeta);
        videoStrategy.updateVideoLocation(
            videoNewSize,
            this._element,
            this.isMinimized ? this.minimizedVideoMoved : false
        );

        this._frame.width = videoNewSize.width;
        this._frame.height = videoNewSize.height;
        this._frame.top = videoElement.offset().top;
        this._frame.left = videoElement.offset().left;

        this._updateCanvasesDimensions();
    }

    _updateCanvasesDimensions() {
        if (this.settings.enableDashboardMagicMarker && this.localDrawingCanvas.get(0)) {
            this.localDrawingCanvas.get(0).width = this.videoStreamWidth;
            this.localDrawingCanvas.get(0).height = this.videoStreamHeight;
            this.localDrawingCanvas.css('width', this._frame.width);
            this.localDrawingCanvas.css('height', this._frame.height);
        }

        if (this.settings.enableCustomerMagicMarker) {
            this.remoteDrawingCanvas.get(0).width = this.videoStreamWidth;
            this.remoteDrawingCanvas.get(0).height = this.videoStreamHeight;
            this.remoteDrawingCanvas.css('width', this._frame.width);
            this.remoteDrawingCanvas.css('height', this._frame.height);
        }
    }

    _getMinimizeVideoOffset(videoDimensions) {
        if (!this.tsInterfaceHelper.useNewInterface) {
            return {
                top: this.mediaFrameUIParams.MINIMIZED_VIDEO_TOP_OFFSET,
                left: this.mediaFrameUIParams.MINIMIZED_VIDEO_LEFT_OFFSET
            };
        }

        const minimizedOffset = {};

        if (this.isMinimizedToBottomRight) {
            const verticalFactor =
                this.mediaFrameUIParams.MINIMIZED_VIDEO_RIGHT_OFFSET_NEW_BOTTOM_RIGHT + videoDimensions.width + 10;
            const horizontalFactor =
                this.mediaFrameUIParams.MINIMIZED_VIDEO_TOP_OFFSET_NEW_BOTTOM_RIGHT + videoDimensions.height + 10;

            minimizedOffset.top = this.$window.innerHeight - horizontalFactor;
            minimizedOffset.left = this.$window.innerWidth - verticalFactor;
        } else {
            const verticalFactor = (this.mediaFrameUIParams.MINIMIZED_VIDEO_MAX_WIDTH - videoDimensions.width) / 2;

            minimizedOffset.top = this.mediaFrameUIParams.MINIMIZED_VIDEO_TOP_OFFSET_NEW;
            minimizedOffset.left = this.mediaFrameUIParams.MINIMIZED_VIDEO_LEFT_OFFSET_NEW + verticalFactor;
        }

        return minimizedOffset;
    }

    _getFrameDimensions() {
        const padding = this.tsInterfaceHelper.getPadding;

        this._containerWidth =
            this.$window.innerWidth - padding.width - this.mediaFrameUIParams.MOBILE_VIDEO_CONTAINER_PADDING;
        this._containerHeight = this.$window.innerHeight - padding.height;

        if (this._containerWidth / this._containerHeight > this.videoStreamWidth / this.videoStreamHeight) {
            this._frame.height = this._containerHeight;
            this._frame.width = (this._containerHeight * this.videoStreamWidth) / this.videoStreamHeight;
        } else {
            this._frame.width = this._containerWidth;
            this._frame.height = (this._containerWidth * this.videoStreamHeight) / this.videoStreamWidth;
        }
    }

    _getMinimizedVideoDimensions() {
        if (this.mobile) {
            return {
                width: this.mediaFrameUIParams.MOBILE_DASHBOARD_MINIMIZED_VIDEO_WIDTH,
                height: this.mediaFrameUIParams.MOBILE_DASHBOARD_MINIMIZED_VIDEO_HEIGHT
            };
        }

        const minimizedVideoDimensions = {};

        if (this.videoStreamWidth > this.videoStreamHeight) {
            minimizedVideoDimensions.width = this.mediaFrameUIParams.MINIMIZED_VIDEO_MAX_WIDTH;
            minimizedVideoDimensions.height =
                (this.mediaFrameUIParams.MINIMIZED_VIDEO_MAX_HEIGHT * this.videoStreamHeight) / this.videoStreamWidth;
        } else {
            minimizedVideoDimensions.width =
                (this.mediaFrameUIParams.MINIMIZED_VIDEO_MAX_WIDTH * this.videoStreamWidth) / this.videoStreamHeight;
            minimizedVideoDimensions.height = this.mediaFrameUIParams.MINIMIZED_VIDEO_MAX_HEIGHT;
        }

        return minimizedVideoDimensions;
    }

    _getCursorPos(e) {
        const ev = e.originalEvent;

        const pageX = get(e, 'touches[0].pageX') || get(ev, 'changedTouches[0].pageX') || e.pageX;
        const pageY = get(e, 'touches[0].pageY') || get(ev, 'changedTouches[0].pageY') || e.pageY;

        const x = (this.videoStreamWidth * (pageX - this._frame.left)) / this._frame.width,
            y = (this.videoStreamHeight * (pageY - this._frame.top)) / this._frame.height;

        return {
            x: Math.max(0, Math.min(x, this.videoStreamWidth)),
            y: Math.max(0, Math.min(y, this.videoStreamHeight)),
            originalX: pageX,
            originalY: pageY
        };
    }

    _displayLivePointerForAgent(cursorPosition) {
        const maxPointerPositionX = Math.min(cursorPosition.originalX, this._frame.width + this._frame.left);

        const updatedCursor = {
            // eslint-disable-next-line max-len

            x: Math.max(this._frame.left, maxPointerPositionX) - this._frame.left,
            y: Math.max(0, Math.min(cursorPosition.originalY, this._frame.top + this._frame.height) - this._frame.top)
        };

        this._pointer
            .css({
                left: Math.round(updatedCursor.x) + 'px',
                top: Math.round(updatedCursor.y) + 'px'
            })
            .show();
    }

    _mouseClick(e) {
        if (this.settings.remoteControl) {
            return;
        }

        switch (e.button) {
            case 0:
                this.mouseButtonPosition = MouseButtonPosition.Left;
                break;
            case 1:
                this.mouseButtonPosition = MouseButtonPosition.Middle;
                break;
            case 2:
                this.mouseButtonPosition = MouseButtonPosition.Right;
                break;
            default:
                this.mouseButtonPosition = MouseButtonPosition.Left;
        }
    }

    _detectCursor(e) {
        if (this.settings.mode !== this.MODES.CURSOR_SHARING || (!this.isLivePointerEnabled && this.mobile)) {
            return;
        }

        if (this.isStretchedVideoMode && e && e.target) {
            if (!$(e.target).hasClass('video-tools-container') && !this.isMiniDashboard) {
                this.chatApi.setStatus('cursor', null, true);

                return;
            }
        }

        if (e && this.settings.focus && this.settings.enabled && !this.settings.isPaused) {
            this.updateCursorPosition(e);

            return;
        }

        this.chatApi.setStatus('cursor', null, true);
    }

    _initMainContainerSizeSensor() {
        if (!this._mainContainerSizeSensor) {
            const mainContainerElement = $('.dashboard-main-content-container');

            this._mainContainerSizeSensor = new ResizeSensor(mainContainerElement, () => {
                this._updateFrameAndOffset();
            });
        }
    }

    _updateCursorPosition(e, extraCursorData) {
        const cursorPosition = this._getCursorPos(e);
        const cursorData = assign({...extraCursorData}, this._getCursorPos(e), {
            image: {
                width: this.videoStreamWidth,
                height: this.videoStreamHeight
            },
            mousePressStatus: this.mousePressStatus,
            buttonPosition: this.mouseButtonPosition
        });

        if (this.mobile && (!this.isLivePointerEnabledOnMobile || this.settings.enableDashboardMagicMarker)) {
            return;
        }

        if (this._pointer) {
            this._displayLivePointerForAgent(cursorPosition);
        }

        this.chatApi.setStatusFast('cursor', cursorData);
    }

    _disposeMainContainerSizeSensor() {
        if (this._mainContainerSizeSensor && this._mainContainerSizeSensor.reset) {
            ResizeSensor.detach($('.dashboard-main-content-container'));
            this._mainContainerSizeSensor = null;
        }
    }

    _updateAfterProcessHasEnded() {
        this.timeOutId = setTimeout(this._updateFrameAndOffset, 0);
    }

    _getVideoRootElement() {
        return this.videoSubscriber ? $(this.videoSubscriber.mediaElement) : [];
    }

    _updateFrameAndOffset() {
        this._updateOffset();
        this.updateFrame();
    }

    _scrollScreenOnce(event) {
        const scrollData = {x: 0, y: (event.deltaY + event.wheelDeltaY) / 2};

        this.chatApi.setStatusFast('mouseScroll', scrollData, true);
    }

    _videoResolutionDimensionChange(videoResolution) {
        return !this.isMinimized
            ? this.onVideoResolutionChanged({resolutionFormat: videoResolution})
            : this.onVideoResolutionChanged({resolutionFormat: VideoResolutionFormat.NONE});
    }

    _handleClickType() {
        if (this.mouseClicks === null) {
            this.mouseClicks = 0;
        }

        this.mouseClicks++;

        if (this.mouseClicks === 1) {
            this.mouseClickType = mouseClickType.Click;
            this.timer = setTimeout(() => {
                this.mouseClicks = 0;
            }, 200);
        } else {
            clearTimeout(this.timer);
            this.mouseClickType = mouseClickType.DblClick;
            this.mouseClicks = 0;
        }
    }
}

function linkFn(scope, element, attrs, ctrl) {
    ctrl.init(element);

    scope.$watchCollection(
        () => ctrl.settings,
        (settings) => {
            if (settings) {
                ctrl.applySettings();
            }
        }
    );

    scope.$on('$destroy', () => ctrl.destroy());
}

export function tsVideoSubscriberDirective() {
    return {
        restrict: 'A',
        scope: {},
        bindToController: {
            /*
             * {
             *     enabled: Boolean, // creates or removes container
             *     mode: String, // the video mode,
             *     MODES: Object, // video modes constants: SNAP, CURSOR_SHARING, IDENTIFY
             *     focus: Boolean, // is not minimizes
             *     sizeTarget: jQuery // DOM element to pick initial container's width and height properties
             * }
             */
            settings: '=tsVideoSettings',
            checkIfTagsExist: '&tsCheckIfTagsExist',
            searchForTags: '&tsSearchForTags',
            restoreFromFocusLoss: '&tsRestoreFromFocusLoss',
            isMinimizedToBottomRight: '=',
            isStretchedVideoMode: '=',
            isRoundedFloatingVideo: '=',
            isScanCaptureDesign: '=',
            isLivePointerEnabledOnMobile: '=',
            isLivePointerEnabled: '=',
            onVideoSubscriberChanged: '=',
            remoteControl: '=',
            mediaService: '=',
            isMiniDashboard: '=',
            onVideoResolutionChanged: '&',
            onMagicMarkerDrawn: '&',
            onMagicMarkerReceived: '&'
        },
        controller: TsVideoSubscriberController,
        controllerAs: 'vm',
        link: linkFn
    };
}
