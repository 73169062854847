import {getRootStore} from '../../_react_/app.bootstrap';
import every from 'lodash/every';
import forEach from 'lodash/forEach';
import includes from 'lodash/includes';
import cloneDeep from 'lodash/cloneDeep';
import last from 'lodash/last';
import {SIGNED_URL_EXPIRATION_BUFFER} from '../ts-chat-api/ts-chat-api.settings';
import get from 'lodash/get';
import has from 'lodash/has';
import {senderTypes} from '@techsee/techsee-common/lib/constants/resource.constants';
import utils from '@techsee/techsee-common/lib/utils';
import {RestApiService} from '@techsee/techsee-client-infra/lib/services/RestApiService';
import moment from 'moment';

const supportedTypes = {
    url: 'url',
    dataUrl: 'dataUrl',
    text: 'text'
};

const parseExpiration = (dateString, format, duration) =>
    moment.utc(dateString, format).add(duration, 'seconds').unix();

function _getResourceExpireTime(url) {
    const expires = utils.getResourceExpireTime(url, parseExpiration);

    return expires - SIGNED_URL_EXPIRATION_BUFFER;
}

export class TsSharingService {
    constructor(tsCanvasAnnotate, db, $window, eventLog, tsChatApi) {
        'ngInject';

        this._tsCanvasAnnotate = tsCanvasAnnotate;
        this._roomDb = db.Rooms;
        this._historyImageDb = db.HistoryImage;
        this._$window = $window;
        this._eventLog = eventLog;
        this.isIE = getRootStore().environmentService.isIE11();
        this.chatApi = tsChatApi.service;
        this.uploadAutoCollectedImage = this.uploadAutoCollectedImage.bind(this);
        this.uploadTriggeredCollectedImage = this.uploadTriggeredCollectedImage.bind(this);
    }

    init(roomId) {
        this.roomId = roomId;
    }

    _validateParams(params, requiresSignedData = true) {
        const isArray = Array.isArray(params);

        let validatedParams = cloneDeep(params);

        if (!isArray) {
            validatedParams = [validatedParams];
        }

        this._validateInput(validatedParams, requiresSignedData);

        return validatedParams;
    }

    copyToClipboard(params, purpose = 'COPY_TO_CLIPBOARD') {
        const validatedParams = this._validateParams(params);

        const promises = [];
        const fileNames = [];

        forEach(params, (param) => {
            const {blobImage, fileName} = param.signedData;

            promises.push(this.uploadClipboardImage(blobImage, fileName, param.signedData));
            fileNames.push(fileName);
        });

        return Promise.all(promises)
            .then(() => {
                try {
                    this._executeCopyToClipboard(validatedParams);

                    validatedParams.length > 1
                        ? this._eventLog.shareMultipleImagesCopyToClipboardSuccess({purpose, fileNames})
                        : this._eventLog.shareImageCopyToClipboardSuccess({purpose, fileNames});
                } catch (err) {
                    validatedParams.length > 1
                        ? this._eventLog.shareMultipleImagesCopyToClipboardCopyFailure({
                              purpose,
                              fileNames,
                              err
                          })
                        : this._eventLog.shareImageCopyToClipboardCopyFailure({purpose, fileNames, err});

                    return Promise.reject('copy');
                }
            })
            .catch((err) => this._eventLog.shareImageCopyToClipboardUploadFailure({purpose, fileNames, err}))
            .then();
    }

    copyTextToClipboard(textParam, purpose = 'COPY_TEXT_TO_CLIPBOARD') {
        try {
            this._validateParams(textParam, false);

            const textCopied = this._executeCopyTextToClipboard(textParam);

            textCopied
                ? this._eventLog.shareCopyTextToClipboardSuccess({purpose, textParam})
                : this._eventLog.shareCopyTextToClipboardFailure({purpose, textParam});
        } catch (err) {
            this._eventLog.shareCopyTextToClipboardFailure({purpose, textParam, err});
        }
    }

    uploadClipboardImage(img, fileName, presignedRequest) {
        if (!this.roomId) {
            return Promise.reject();
        }

        this._eventLog.uploadingClipboardImage();

        return new Promise((resolve, reject) => {
            this.uploadImageS3(
                img,
                fileName,
                true,
                (id, payload) => {
                    if (presignedRequest && presignedRequest.signedRequest) {
                        const url = presignedRequest.signedRequest;

                        if (moment().unix() < _getResourceExpireTime(url)) {
                            return Promise.resolve({data: presignedRequest});
                        }
                    }

                    return this._roomDb.uploadClipboard(id, payload);
                },
                resolve,
                reject
            );
        });
    }

    /**
     * Upload an image.
     * While this function doesn't communicate directly with the chat server,
     * it's part of the chat functionality we want exposed to the frontend.
     *
     * @param img - ObjectUrl that we will upload to the server
     * @param options - Object [optional], with extra parameters
     * @param cbSuccess - callback on successful upload. parameter is
     *                    the publicUrl of the uploaded file
     * @param cbErr - callback on error
     */
    uploadRoomImage(img, options, cbSuccess, cbErr) {
        if (!this.roomId) {
            return cbErr();
        }

        const roomUploadCb = (id, payload) => {
            if (has(options, 'isTemp')) {
                payload.data.isTemp = options.isTemp;
            }

            return this._roomDb.createUrlForUpload(id, payload);
        };

        this.uploadImageS3(img, get(options, 'fileName') || null, true, roomUploadCb, cbSuccess, cbErr);
    }

    uploadAutoCollectedImage(img) {
        return new Promise((resolve, reject) => {
            if (!this.roomId) {
                return reject();
            }

            const roomUploadCb = (id, payload) => {
                payload.data.isAuto = true;

                return this._roomDb.createUrlForUpload(id, payload);
            };

            this.uploadImageS3(
                img,
                null,
                false,
                roomUploadCb,
                () => resolve(),
                () => reject()
            );
        });
    }

    uploadTriggeredCollectedImage(img, source) {
        return new Promise((resolve, reject) => {
            if (!this.roomId) {
                return reject();
            }

            const roomUploadCb = (id, payload) => {
                payload.data.triggeredSource = source;

                return this._roomDb.createUrlForUpload(id, payload);
            };

            this.uploadImageS3(
                img,
                null,
                false,
                roomUploadCb,
                () => resolve(),
                () => reject()
            );
        });
    }

    /*
     * Uploads an image using a signed S3 link and a custom signing function
     *
     * @param img - Image Blob or ObjectURL (one use, revoked in the function)
     * @param fileName - String filename that will be used for the upload
     * @param cbSign - Signing callback:(resourceId, payload)
     *                  payload has { fileName, fileType, fileSize }
     * @param cbSuccess - callback on successful upload. parameter is
     *                    the publicUrl of the uploaded file
     * @param cbError - callback on error
     *
     */
    uploadImageS3(img, fileName, loggingEnabled, cbSign, cbSuccess, cbErr) {
        this._generateImageFileName(img, fileName, (blob, imgName) => {
            URL.revokeObjectURL(img);
            const mediaFileSizeInKB = TsUtils.convertToSizeString(blob.size);

            if (loggingEnabled) {
                this._eventLog.imageUploadStarted({mediaFileName: imgName, ImageSize: mediaFileSizeInKB});
            }

            const imageUploadTimeout = get(this.accountSettings, 'imageUploadTimeoutSeconds');

            return cbSign(this.roomId, {
                data: {
                    fileName: imgName,
                    fileType: blob.type,
                    fileSize: blob.size
                }
            })
                .then((response) => {
                    const url = response.data.signedRequest,
                        publicUrl = response.data.publicUrl;

                    const http = new RestApiService('', undefined, {
                        'Content-Type': blob.type
                    });

                    http.put(url, blob, {
                        timeout: imageUploadTimeout * 1000,
                        responseType: 'text'
                    })
                        .then((resp) => {
                            if (loggingEnabled) {
                                this._eventLog.imageUploadSuccess({
                                    mediaFileName: imgName,
                                    ImageSize: mediaFileSizeInKB,
                                    status: resp?.status ?? ''
                                });
                            }

                            if (cbSuccess) {
                                return cbSuccess(publicUrl);
                            }
                        })
                        .catch((err) => {
                            if (loggingEnabled) {
                                this._eventLog.imageUploadFailed({
                                    url: url,
                                    status: err?.status ?? '',
                                    error: err,
                                    failurePhase: 'Uploading to S3',
                                    mediaFileName: imgName
                                });
                            }

                            cbErr();
                        });
                })
                .catch((err) => {
                    if (loggingEnabled) {
                        this._eventLog.imageUploadFailed({
                            err,
                            failurePhase: 'Image Signing',
                            mediaFileName: imgName
                        });
                    }

                    cbErr();
                });
        });
    }

    generateImageFileName(img, fileName) {
        return new Promise((resolve) => {
            this._generateImageFileName(img, fileName, (blob, imgFileName) => resolve({blob, imgFileName}));
        });
    }

    _generateImageFileName(img, fileName, cb) {
        this.imageToBlob(img, (blob) => {
            const ext = blob.type.match(/image\/(.*)/i);
            const imgFileName = fileName || `${Date.now()}-dashboard` + (ext && ext[1] ? `.${ext[1]}` : '');

            return cb(blob, imgFileName);
        });
    }

    /**
     * Convert an ObjectURL to a Blob (can also accept a Blob and return it)
     *
     * @param {String | Blob} img - An ObjectURL string or a Blob
     * @param {function(Blob)} callback - Callback method to be called with the Blob result
     */
    imageToBlob(img, callback) {
        if (img instanceof Blob) {
            // img is already a Blob
            return callback(img);
        }

        // img is an ObjectUrl, but we need to send a blob, which can only be
        // retrieved through an xhr self-request (responseType blob, wouldn't
        // work with zepto or jquery, which is why plain xhr is used)
        const xhr = new XMLHttpRequest();

        xhr.open('GET', img, true);
        xhr.responseType = 'blob';
        xhr.onload = () => {
            if (xhr.status === 200 || xhr.status === 0) {
                return callback(xhr.response);
            }
        };
        xhr.send();
    }

    sendByEmail(mailTrigger, params) {
        return this.copyToClipboard(params, 'SEND_BY_EMAIL').then(() => this._$window.open(mailTrigger));
    }

    sendImagesToClient(params) {
        const promises = [];

        forEach(params, (param) => {
            const {blobImage, fileName} = param.signedData;

            const options = {fileName};

            promises.push(
                new Promise((resolve, reject) => {
                    this._eventLog.dashboardSendingImage({library: true, mediaFileName: fileName});
                    this.uploadRoomImage(
                        blobImage,
                        options,
                        (url) => {
                            this.chatApi
                                .sendImage(url, {
                                    sharedBy: senderTypes.DASHBOARD,
                                    mediaFileName: fileName
                                })
                                .then(() => resolve())
                                .catch((err) => reject(err));
                        },
                        (err) => reject(err)
                    );
                })
            );
        });

        return Promise.all(promises);
    }

    download(params) {
        this._validateInput([params], false);

        const fileName = params.fileName || 'img.jpg';
        const saveImage = (base64Image) => {
            if (this.isIE) {
                const blob = this._tsCanvasAnnotate.dataUrlToBlob(base64Image);

                // IE11 does not support data urls
                this._$window.navigator.msSaveBlob(blob, fileName);
            } else {
                const dataUrl = params.useOriginalImage
                    ? this._tsCanvasAnnotate.dataUrlToObjectUrl(base64Image)
                    : base64Image;
                const downloadElement = this._$window.document.createElement('a');

                downloadElement.setAttribute('download', fileName);
                downloadElement.href = dataUrl;

                this._$window.document.body.appendChild(downloadElement);
                downloadElement.click();
                this._$window.document.body.removeChild(downloadElement);
            }
        };

        if (params.type === supportedTypes.url) {
            /** TODO: Theoretically, the signed URL may be already expired, so we need to resign it.
             *        Currently, because of time constraints we're leaving it as known issue. If there will be complains
             *        from customer, we will add logic to resign URL.
             */
            return new Promise((resolve, reject) => {
                getImageAsBase64(params.url, params._id, this._historyImageDb.resign)
                    .then((base64image) => {
                        try {
                            saveImage(base64image);
                        } catch (err) {
                            this._eventLog.shareImageSaveLocallySaveFailure({params, err});

                            return reject('save');
                        }
                        this._eventLog.shareImageSaveLocallySuccess(params);
                        resolve();
                    })
                    .catch((err) => {
                        this._eventLog.shareImageSaveLocallyDownloadFailure({params, err});
                        reject('download');
                    });
            });
        }

        if (params.type === supportedTypes.dataUrl) {
            try {
                saveImage(this._tsCanvasAnnotate.getDataURL({useOriginalImage: params.useOriginalImage}));
            } catch (err) {
                this._eventLog.shareImageSaveLocallySaveFailure({params, err});

                return Promise.reject('save');
            }

            this._eventLog.shareImageSaveLocallySuccess(params);

            return Promise.resolve();
        }

        return Promise.reject('unexpected');
    }

    getSignedDataForImageSharing(params) {
        this._validateInput([params], false);

        if (!this.roomId) {
            return Promise.reject('Room id not exists');
        }

        const fileName = `clipboard-copy-${this.uuidv4()}-${Date.now()}.jpeg`;
        const payload = {
            data: {
                fileName: fileName,
                fileType: 'image/jpeg',
                shorten: true
            }
        };

        const promises = [];

        promises.push(this._roomDb.uploadClipboard(this.roomId, payload).then((result) => result.data));

        if (params.type === supportedTypes.dataUrl) {
            const dataUrl = this._tsCanvasAnnotate.getDataURL({useOriginalImage: params.useOriginalImage});

            promises.push(this._tsCanvasAnnotate.dataUrlToBlob(dataUrl));
        }

        if (params.type === supportedTypes.url) {
            promises.push(
                getImageAsBase64(params.url, params._id, this._historyImageDb.resign).then((base64Image) =>
                    this._tsCanvasAnnotate.dataUrlToBlob(base64Image)
                )
            );
        }

        return Promise.all(promises)
            .then(([signedData, blobImage]) => {
                signedData.blobImage = blobImage;

                return signedData;
            })
            .catch((err) => {
                if (!params.tryAgain) {
                    this.getSignedDataForImageSharing({...params, tryAgain: true});
                } else {
                    this._eventLog.shareImageFetchSignedDataFailure({params, err});
                    throw 'signing';
                }
            });
    }

    _validateInput(params, requiresSignedData) {
        const isSupportedTypes = every(params, (param) => !includes(supportedTypes, param.type));

        if (isSupportedTypes) {
            throw `provided 'type' is not supported. Supported types are: ${Object.keys(supportedTypes).join()}`;
        }

        if (requiresSignedData && !every(params, (param) => param.signedData)) {
            throw "'signedData' should be provided as part of params.";
        }

        if (!every(params, (param) => param.type !== 'url' || typeof param.url !== 'undefined')) {
            throw "If type is 'url', url should be provided.";
        }

        if (!every(params, (param) => param.type !== 'dataUrl' || typeof param.useOriginalImage !== 'undefined')) {
            throw "If type is 'dataUrl', useOriginalImage should be provided.";
        }

        if (!every(params, (param) => param.type !== 'text' || typeof param.data === 'string')) {
            throw "If type is 'text', only text params should be provided.";
        }
    }

    _executeCopyToClipboard(params) {
        const imageElementsArray = [];
        let index = 0;

        forEach(params, () => {
            imageElementsArray.push(new Image());
        });

        const selection = this._$window.getSelection ? this._$window.getSelection() : this._$window.document.selection;
        const range = this._$window.document.createRange();

        //will cause the browser to throw a 'resource not found' error because the image wasn't uploaded to amazon yet
        forEach(imageElementsArray, (imageElement) => {
            imageElement.src = params[index++].signedData.publicUrl;
        });

        if (selection.empty) {
            selection.empty();
        } else if (selection.removeAllRanges) {
            selection.removeAllRanges();
        }

        forEach(imageElementsArray, (imageElement) => {
            this._$window.document.body.appendChild(imageElement);
        });

        range.setStartBefore(imageElementsArray[0]);
        range.setEndAfter(last(imageElementsArray));

        selection.addRange(range);

        //this will only work when called from code triggered by user event
        this._$window.document.execCommand('copy');

        forEach(imageElementsArray, (imageElement) => {
            this._$window.document.body.removeChild(imageElement);
        });
    }

    _executeCopyTextToClipboard(itemToCopy) {
        const dummyTextAreaElement = this._$window.document.createElement('textarea');

        dummyTextAreaElement.style.position = 'fixed';
        dummyTextAreaElement.style.opacity = '0';

        // Important Notes:
        // 1. Chrome uses the value property, while FireFox uses textContent property
        // 2. document.execCommand('copy') must be triggered by the user. Therefore, this function won't work when you debug it
        dummyTextAreaElement.value = itemToCopy.data;
        dummyTextAreaElement.textContent = itemToCopy.data;

        this._$window.document.body.appendChild(dummyTextAreaElement);

        dummyTextAreaElement.focus();
        dummyTextAreaElement.select();

        const copySucceeded = this._$window.document.execCommand('copy');

        this._$window.document.removeChild(dummyTextAreaElement);

        return copySucceeded;
    }

    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            // eslint-disable-next-line no-bitwise
            const r = (Math.random() * 16) | 0,
                // eslint-disable-next-line no-bitwise
                v = c === 'x' ? r : (r & 0x3) | 0x8;

            return v.toString(16);
        });
    }

    imageAsBase64(url, options) {
        return getImageAsBase64(url, undefined, undefined, options);
    }
}

function getImageAsBase64(url, resourceId, resignUrl, options = {}) {
    // resign or not, if resign - use-credentials
    const toDataUrl = (url) =>
        new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();

            xhr.onload = function () {
                const reader = new FileReader();

                reader.onloadend = function () {
                    resolve(reader.result);
                };

                reader.readAsDataURL(xhr.response);
            };

            xhr.onerror = (error) => reject(error);
            xhr.open('GET', url);
            xhr.setRequestHeader('Cache-Control', 'no-cache');
            xhr.responseType = 'blob';
            xhr.send();
        });

    const loadImageAndConvert = (url) =>
        new Promise((resolve, reject) => {
            const img = new Image();

            img.onload = function () {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                canvas.width = (options && options.width) || img.width;
                canvas.height = (options && options.height) || img.height;

                if (options && options.width) {
                    ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas.width, canvas.height);
                } else {
                    ctx.drawImage(img, 0, 0);
                }

                resolve(canvas.toDataURL('image/jpeg'));
            };

            img.crossOrigin = 'Anonymous';
            img.src = url;
            img.onerror = reject;
        });

    return loadImageAndConvert(url).catch(() => {
        resignUrl(resourceId, {bypassCache: true}).then((resigned) => toDataUrl(resigned.data.url));
    });
}
