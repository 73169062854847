'use strict';

// Disables ng-animate on directives that don't play well with it, like the
// ui-bootstrap carousel which is very sensitive and gets messed up often
class DisableNgAnimateController {
    constructor($element, $attrs, $scope, $animate) {
        'ngInject';

        this.$element = $element;
        this.$animate = $animate;
    }

    disableAnimate() {
        this.$animate.enabled(false, this.$element);
    }
}

function linkFn(scope, element, attrs, ctrl) {
    ctrl.disableAnimate();
}

export function disableNgAnimateDirective() {
    return {
        restrict: 'A',
        controller: DisableNgAnimateController,
        link: linkFn
    };
}
