import {observable} from 'mobx';
import {ITranslate} from '../../../_react_/services/LocalizationService';
import {IDomUtilsService} from '@techsee/techsee-client-infra/lib/services/DomUtilsService';

export interface IRedirectToStoreController {
    translate: ITranslate;
    domUtilsService: IDomUtilsService;
    isIos: boolean;
    dir: string;
    invalidVersion: boolean;
    useNewInterface: boolean;

    updateVersion(invalidVersion: boolean): void;
}

export class RedirectToStoreController implements IRedirectToStoreController {
    @observable private _isVisible: boolean;

    private _isIos: boolean;

    private _translate: ITranslate;

    private _domUtilsService: IDomUtilsService;

    private _dir: string;

    private _invalidVersion: boolean;

    private _useNewInterface: boolean;

    constructor(
        translate: ITranslate,
        isIos: boolean,
        domUtilsService: IDomUtilsService,
        dir: string,
        useNewInterface: boolean,
        invalidVersion: boolean
    ) {
        this._translate = translate;
        this._isVisible = false;
        this._isIos = isIos;
        this._domUtilsService = domUtilsService;
        this._dir = dir;
        this._invalidVersion = invalidVersion;
        this._useNewInterface = useNewInterface;
    }

    updateVersion(invalidVersion: boolean) {
        this._invalidVersion = invalidVersion;
    }

    get useNewInterface() {
        return this._useNewInterface;
    }

    get invalidVersion() {
        return this._invalidVersion;
    }

    get dir() {
        return this._dir;
    }

    get domUtilsService() {
        return this._domUtilsService;
    }

    get translate() {
        return this._translate;
    }

    get isIos() {
        return this._isIos;
    }
}
