import React from 'react';
import {BrowserContext} from './GuidanceWrapper';

export interface IGuidanceImageParams {
    type: string;
    name: string;
}

export interface IGuidanceImage extends IGuidanceImageParams {
    isTransparent?: boolean;
}

const getImage = ({type, name, browser}: IGuidanceImageParams & {browser?: string}) => {
    try {
        return require(`img/agent-guidance/${type}/${browser ? `${browser}/` : ''}${name}.png`); // eslint-disable-line global-require
    } catch (error) {
        return '';
    }
};

const GuidanceImage: React.FunctionComponent<IGuidanceImage> = (props: IGuidanceImage) => (
    <BrowserContext.Consumer>
        {({browser}) => (
            <div
                className={'img'}
                style={{
                    backgroundImage: props.isTransparent
                        ? 'transparent'
                        : `url(${getImage({type: props.type, name: props.name, browser})})`
                }}
            />
        )}
    </BrowserContext.Consumer>
);

export default GuidanceImage;
