import React from 'react';
import {observer} from 'mobx-react';
import {action, computed, toJS} from 'mobx';
import filter from 'lodash/filter';
import some from 'lodash/some';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {ITag, TagsPanel} from '@techsee/techsee-ui-common/lib/tags-panel';
import {ScrollableContent} from '@techsee/techsee-ui-common/lib/scrollable-content';
import {BorderRoundedIconButton} from '@techsee/techsee-ui-common/lib/forms/buttons/border-rounded-icon';
import {IconColors, IconSizes} from '@techsee/techsee-ui-common/lib/icons/large-rounded-icon';
import {CollapsiblePanel} from '@techsee/techsee-ui-common/lib/collapsible-panel';
import {SessionSummaryImage} from './SessionSummaryImage';
import {ISessionSummaryController, TSImageResource} from './SessionSummary.contracts';
import {ITab, SimpleTabs} from '@techsee/techsee-ui-common/lib/tabs/simple';
import {PrimaryButton} from '@techsee/techsee-ui-common/lib/forms/buttons/primary';
import {FormInput} from '@techsee/techsee-ui-common/lib/forms/input';
import {TextBox} from '@techsee/techsee-ui-common/lib/text-box';

@observer
export class ModalContent extends TechseeBaseComponent<{controller: ISessionSummaryController}> {
    constructor(props: {controller: ISessionSummaryController}) {
        super(props);

        this.onTagClick = this.onTagClick.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
        this.onImageClicked = this.onImageClicked.bind(this);
        this.renderTabs = this.renderTabs.bind(this);
        this.renderTags = this.renderTags.bind(this);
        this.renderComment = this.renderComment.bind(this);
        this.onChangeTags = this.onChangeTags.bind(this);
        this.onCommentChange = this.onCommentChange.bind(this);
    }

    @action
    private onDeleteClick(tag: ITag): void {
        const {currentResource} = this.props.controller;
        const currentTag = filter(
            currentResource && currentResource.informationTags,
            (informationTag: ITag) => informationTag.id === tag.id
        );

        currentTag[0].className = '';
    }

    @action
    private onTagClick(tag: ITag): void {
        const {currentResource} = this.props.controller;
        const currentTag = filter(
            currentResource && currentResource.informationTags,
            (informationTag: ITag) => informationTag.id === tag.id
        );

        currentTag[0].className = 'selected';
    }

    @action
    private onCommentChange(comment: string): void {
        const {currentResource} = this.props.controller;

        if (currentResource) {
            currentResource.comment = comment;
        }
    }

    @computed
    private get currentResourceTags(): ITag[] {
        const {currentResource} = this.props.controller;

        return currentResource && currentResource.informationTags ? toJS(currentResource.informationTags) : [];
    }

    private isDisplayTagBadge(item: TSImageResource): boolean {
        return some(item && item.informationTags, (tag: ITag) => tag.className === 'selected');
    }

    @action
    private onImageClicked(item: TSImageResource, index: number) {
        this.props.controller.setIsSelected(index);
        this.props.controller.setCurrentResource(item);
    }

    private renderTags() {
        const {translate, newTagToAdd, addNewTag, allowNewSessionSummary, isTablet} = this.props.controller;
        let tagPanelClassName = '';

        if (!allowNewSessionSummary) {
            tagPanelClassName = isTablet ? 'tag-panel is-tablet' : 'tag-panel';
        }

        return (
            <React.Fragment>
                {allowNewSessionSummary ? (
                    <div className={'input-add-tag-container'}>
                        <FormInput
                            uid={'input-new-tag'}
                            className={`input-field ${isTablet ? 'is-tablet' : ''}`}
                            model={newTagToAdd}
                            onEnterKey={addNewTag}
                        />
                        <PrimaryButton onClick={addNewTag} className={`add-tag-button ${isTablet ? 'is-tablet' : ''}`}>
                            {translate('REACT.DASHBOARD.VIEW.SESSION_SUMMARY.ADD_BUTTON')}
                        </PrimaryButton>
                    </div>
                ) : (
                    <React.Fragment />
                )}
                <TagsPanel
                    className={tagPanelClassName}
                    tags={this.currentResourceTags}
                    onTagClick={this.onTagClick}
                    onDeleteClick={this.onDeleteClick}
                />
            </React.Fragment>
        );
    }

    renderComment() {
        const {commentMaxLength, comment, translate} = this.props.controller;

        return (
            <TextBox
                initialText={comment}
                className='description'
                placeholder={translate('REACT.DASHBOARD.VIEW.SESSION_SUMMARY.DESCRIPTION_PLACEHOLDER')}
                maxLenght={commentMaxLength}
                height={'88px'}
                onChange={this.onCommentChange}
            />
        );
    }

    onChangeTags(indexTab: number) {
        const {allowNewSessionSummary, isTagsOpen, setTagsOpen, disableExpandButton, setTabIndex} =
            this.props.controller;

        setTabIndex(indexTab);

        if (!indexTab && allowNewSessionSummary) {
            if (isTagsOpen) {
                setTagsOpen();
            }

            disableExpandButton(true);
        } else {
            disableExpandButton(false);
        }
    }

    renderTabs() {
        const {
            displayErrorMessage,
            badgeCount,
            isTagsOpen,
            setTagsOpen,
            disabledExpand,
            tabIndex,
            allowNewSessionSummary,
            translate,
            isTablet
        } = this.props.controller;
        const tabsList: ITab[] = [];

        if (allowNewSessionSummary) {
            tabsList.push({
                title: translate('REACT.DASHBOARD.VIEW.SESSION_SUMMARY.DESCRIPTION'),
                content: this.renderComment
            });
        }

        tabsList.push({
            title: translate('REACT.DASHBOARD.VIEW.SESSION_SUMMARY.TAGS'),
            badgeText: badgeCount && badgeCount > 0 ? badgeCount.toString() : '',
            content: this.renderTags
        });

        return (
            <React.Fragment>
                {displayErrorMessage ? (
                    <div></div>
                ) : (
                    <CollapsiblePanel
                        className={`collapsible-container ${isTablet ? 'is-tablet' : ''}`}
                        isOpen={isTagsOpen}
                        title={translate('REACT.DASHBOARD.VIEW.SESSION_SUMMARY.IMAGE_DETAILS')}
                        height={isTablet ? '190px' : '186px'}
                        width={'350px'}
                        onToggleClick={setTagsOpen}
                        disabled={disabledExpand}>
                        <SimpleTabs
                            tabsList={tabsList}
                            uid={'unique-tabs'}
                            setHandlers={({setActiveTab}: any) => setActiveTab(tabIndex)}
                            onChange={this.onChangeTags}
                        />
                    </CollapsiblePanel>
                )}
            </React.Fragment>
        );
    }

    renderInternal(): JSX.Element {
        const {screenshots, translate, displayErrorMessage, countImageMarked} = this.props.controller;

        return (
            <div className={'content-body'}>
                <div className={'left-container'}>
                    <SessionSummaryImage
                        className={'img-container'}
                        translate={translate}
                        isError={displayErrorMessage}
                        image={
                            (displayErrorMessage
                                ? {url: 'img/error-background.png'}
                                : this.props.controller.currentResource) as TSImageResource
                        }
                        maxHeight={342}
                        maxWidth={348}
                        isMainImage={true}
                    />
                    {this.renderTabs()}
                </div>
                <ScrollableContent width={'125px'} height={'500px'}>
                    {!displayErrorMessage ? (
                        <div className={'resources-container room-resources-list'}>
                            {screenshots.map((item: TSImageResource, index: number) => (
                                <SessionSummaryImage
                                    key={index}
                                    index={index}
                                    className={'room-resource'}
                                    image={item}
                                    onImageClicked={() => this.onImageClicked(item, index)}
                                    height={100}
                                    width={110}
                                    onFavoriteClicked={this.props.controller.setIsMarked}
                                    displayFavoriteBadge={item.isMarked}
                                    displayTagBadge={this.isDisplayTagBadge(item)}
                                    displaySharedBadge={!!item.sharedBy}
                                    translate={translate}
                                />
                            ))}
                        </div>
                    ) : (
                        <div className={'resources-container'}>
                            <SessionSummaryImage
                                className={'room-resource'}
                                translate={translate}
                                isError={displayErrorMessage}
                                image={{url: ''} as any as TSImageResource}
                                height={100}
                                width={110}
                            />
                        </div>
                    )}
                </ScrollableContent>
                <div className={'main-section'}>
                    <h3>{translate('REACT.DASHBOARD.VIEW.MAIN_HEADER_SUMMARY')}</h3>
                    <span className={'sub-header'}>{translate('REACT.DASHBOARD.VIEW.SUB_HEADER_SUMMARY')}</span>
                    <div className={`same-line select-deselect-position ${displayErrorMessage ? 'error' : ''}`}>
                        <BorderRoundedIconButton
                            onClick={() => this.props.controller.setSelectDeselectAll(true)}
                            iconName={'star'}
                            colorName={IconColors.Yellow}
                            sizeName={IconSizes.Medium}
                            reverseColor={true}></BorderRoundedIconButton>
                        <span className={'select-deselect-button'}>
                            {translate('REACT.DASHBOARD.VIEW.SELECT_ALL', {count: countImageMarked})}
                        </span>
                    </div>
                    <div className={`same-line ${displayErrorMessage ? 'error' : ''}`}>
                        <BorderRoundedIconButton
                            onClick={() => this.props.controller.setSelectDeselectAll(false)}
                            colorName={IconColors.Grey}
                            iconName={'star'}
                            sizeName={IconSizes.Medium}
                            reverseColor={true}
                        />
                        <span className={'select-deselect-button'}>
                            {translate('REACT.DASHBOARD.VIEW.DESELECT_ALL')}
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}
