import moment = require('moment');
import {Moment} from 'moment';
import get from 'lodash/get';
import {Nullable} from '@techsee/techsee-common';
import {DashboardAppMediaService, UserVideoStateEventArgs} from './DashboardAppMediaService';
import {getMediaTracer} from '@techsee/techsee-media-service/lib/MediaUtils/MediaTracer';
export const VIDEO_TRAFFIC_CHECK_INTERVAL = 2;
export const VIDEO_DURATION_CHECK_INTERVAL = 4;

const tracer = getMediaTracer('MediaStatsLogger');

export class MediaStatsDataCollector {
    private videoLoggingEnabled: boolean = false;

    private videoDurationLastCheck: Nullable<Moment> = null;

    private videoDurationDelta: number = 0;

    private trafficDelta: number = 0;

    private trafficLoggerPtr: any = null;

    private durationLoggerPtr: any = null;

    private readonly trafficBufferByTrack: Map<string, number> = new Map();

    private readonly dashboardMediaService: DashboardAppMediaService;

    constructor(dashboardMediaService: DashboardAppMediaService) {
        this.dashboardMediaService = dashboardMediaService;
        this.dashboardMediaService.onUserVideoStateChange((eventArgs: UserVideoStateEventArgs) => {
            this.setVideoDurationLoggingState(eventArgs.isReady);
        });
    }

    enableVideoLogging(flag: boolean) {
        if (this.videoLoggingEnabled === flag) {
            return;
        }

        if (!flag) {
            this.updateDurationData();
        } else if (this.videoDurationLastCheck !== null) {
            this.videoDurationLastCheck = moment.utc();
        }

        this.videoLoggingEnabled = flag;

        this.syncStatsLogs().catch(() => undefined);
    }

    getTrafficDelta() {
        return this.updateTrafficData().then(() => {
            const trafficDelta = this.trafficDelta;

            this.trafficDelta = 0;

            return trafficDelta;
        });
    }

    restoreTrafficDelta(delta: number) {
        this.trafficDelta += delta;
    }

    getVideoDurationDelta() {
        this.updateDurationData();

        const delta = this.videoDurationDelta;

        this.videoDurationDelta = 0;

        return Promise.resolve(delta);
    }

    restoreVideoDurationDelta(delta: number) {
        this.videoDurationDelta += delta;
    }

    clearVideoLogging() {
        this.resetFields();

        return Promise.resolve();
    }

    private syncStatsLogs() {
        return Promise.resolve()
            .then(() => this.syncVideoTrafficLogger())
            .then(() => this.syncVideoDurationLogger())
            .catch((error: any) => {
                tracer.warn('Error sync media stats', error);
            });
    }

    private syncVideoTrafficLogger() {
        if (!this.videoLoggingEnabled) {
            if (this.trafficLoggerPtr) {
                clearInterval(this.trafficLoggerPtr);
                this.trafficLoggerPtr = null;
            }

            return Promise.resolve();
        }

        if (!this.trafficLoggerPtr) {
            this.trafficLoggerPtr = setInterval(() => this.updateTrafficData(), VIDEO_TRAFFIC_CHECK_INTERVAL * 1000);
        }

        return Promise.resolve();
    }

    private syncVideoDurationLogger() {
        if (!this.videoLoggingEnabled) {
            if (this.durationLoggerPtr) {
                clearInterval(this.durationLoggerPtr);
                this.durationLoggerPtr = null;
            }

            return Promise.resolve();
        }

        if (!this.durationLoggerPtr) {
            this.durationLoggerPtr = setInterval(() => this.updateDurationData(), VIDEO_DURATION_CHECK_INTERVAL * 1000);
        }

        return Promise.resolve();
    }

    private setVideoDurationLoggingState(isActive: boolean) {
        if (isActive) {
            this.videoDurationLastCheck = moment.utc();

            return;
        }

        this.updateDurationData();

        this.videoDurationLastCheck = null;
    }

    private updateTrafficData() {
        return this.dashboardMediaService
            .getUserVideoStats(true)
            .then((userVideoStats: any) => {
                const bytesReceived = get(userVideoStats.parsedTrackStats, 'video.bytesReceived');

                if (bytesReceived) {
                    const previousTraffic = this.trafficBufferByTrack.get(userVideoStats.trackId) || 0;

                    this.trafficBufferByTrack.set(userVideoStats.trackId, bytesReceived);
                    this.trafficDelta += bytesReceived - previousTraffic;
                }
            })
            .catch(() => {
                //TODO - Log
            });
    }

    private updateDurationData() {
        if (!this.videoLoggingEnabled || !this.videoDurationLastCheck) {
            return;
        }

        const now = moment.utc();

        this.videoDurationDelta += now.diff(this.videoDurationLastCheck, 'seconds', true);

        this.videoDurationLastCheck = now;
    }

    private resetFields() {
        this.videoLoggingEnabled = false;
        this.videoDurationLastCheck = null;
        this.videoDurationDelta = 0;
        this.trafficDelta = 0;

        clearInterval(this.trafficLoggerPtr);
        this.trafficLoggerPtr = null;

        clearInterval(this.durationLoggerPtr);
        this.durationLoggerPtr = null;

        this.trafficBufferByTrack.clear();
    }
}
