import {observable, action, computed} from 'mobx';
import {ITranslate} from '../../../services/LocalizationService';
import {DashboardState} from '../_contracts/DashboardState';
import {InviteDeviceType} from '../../invite-new/_contracts/InviteContracts';
import {
    IGuidanceSettings,
    AllowCameraGuidance,
    TosGuidance,
    AppShareTOS,
    ScreenShareTOS,
    ScreenShareAa,
    ScreenShareIaOne,
    DesktopShareAllowSharingGuidance,
    GoToApplicationGuidance,
    TosRejected
} from './contract';
import {DeepReadonly} from '@techsee/techsee-common/lib/core/data-structures';
import {EventLogConstant} from '../../../../constants/events/event-log.constant';

export interface IDashboardGuidanceController {
    readonly translate: ITranslate;
    readonly isVisible: boolean;
    readonly isScreenSharingIOS: boolean;
    readonly isGoToApplicationIOS: boolean;
    readonly settings: IGuidanceSettings;
    readonly deviceType: InviteDeviceType;
    readonly isButtonVisible: boolean;
    readonly isCameraApprovalShown: boolean;

    show(): void;
    hide(): void;
    endMeeting(): void;
    setTosGuidance(): void;
    setTosRejected(onClickButton: (...args: any) => void): void;
    setAppSharingTosGuidance(): void;
    setGoToApplicationGuidance(): void;
    setScreenShareTosGuidance(): void;
    setCameraApprovalGuidance(isDesktopShare: boolean): void;
    setScreenShareCapturingGuidance(): void;
    onButtonClick(): void;
}

export class DashboardGuidanceController implements IDashboardGuidanceController {
    private readonly _translate: ITranslate;

    private _dashboardState: DashboardState;

    @observable private _isVisible: boolean;

    @observable private headerTitle: string = '';

    @observable private headerSubTitle: string = '';

    @observable private _progressItems: object = {};

    @observable private _settings: IGuidanceSettings = {
        stepNum: 1,
        stepType: '',
        stepText: '',
        headerTitle: '',
        headerSubTitle: '',
        progress: [],
        buttonText: ''
    };

    @observable private _onButtonClick?: (...args: any) => void;

    @observable private _args?: any;

    constructor(
        translate: ITranslate,
        dashboardState: DeepReadonly<DashboardState>,
        private _endMeeting: Function
    ) {
        this._translate = translate;
        this._isVisible = false;
        this._dashboardState = dashboardState;

        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.endMeeting = this.endMeeting.bind(this);
        this.setTosGuidance = this.setTosGuidance.bind(this);
        this.setAppSharingTosGuidance = this.setAppSharingTosGuidance.bind(this);
        this.setGoToApplicationGuidance = this.setGoToApplicationGuidance.bind(this);
        this.setScreenShareTosGuidance = this.setScreenShareTosGuidance.bind(this);
        this.setCameraApprovalGuidance = this.setCameraApprovalGuidance.bind(this);
        this.setScreenShareCapturingGuidance = this.setScreenShareCapturingGuidance.bind(this);
        this.setTosRejected = this.setTosRejected.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
    }

    @action
    updateGuidance(details: IGuidanceSettings) {
        this._settings.stepNum = details.stepNum;
        this._settings.stepType = details.stepType;
        this._settings.stepText = this.translate(details.stepText);
        this._settings.headerTitle = this.translate(details.headerTitle);
        this._settings.headerSubTitle = this.translate(details.headerSubTitle);
        this._settings.progress = details.progress;
        if (details.buttonText) {
            this._settings.buttonText = this.translate(details.buttonText);
        }

        this.show();
    }

    @action
    setTosGuidance() {
        this.updateGuidance(TosGuidance);
    }

    @action
    setTosRejected(onClickButton: (...args: any) => void) {
        this.updateGuidance(TosRejected);
        this._onButtonClick = onClickButton;
        this._args = [false, EventLogConstant.closedByTypes.dashboard];
    }

    onButtonClick() {
        this._onButtonClick && this._onButtonClick(...this._args);
        this.hide();
    }

    @computed
    get isButtonVisible() {
        return !!this._onButtonClick;
    }

    @action
    setAppSharingTosGuidance() {
        this.updateGuidance(AppShareTOS);
    }

    @action
    setGoToApplicationGuidance() {
        if (this.deviceType === InviteDeviceType.ios) {
            this.updateGuidance(GoToApplicationGuidance);
        }
    }

    @action
    setScreenShareTosGuidance() {
        this.updateGuidance(ScreenShareTOS);
    }

    @action
    setCameraApprovalGuidance(isDesktopSharing: boolean) {
        isDesktopSharing
            ? this.updateGuidance(DesktopShareAllowSharingGuidance)
            : this.updateGuidance(AllowCameraGuidance);
    }

    @action
    setScreenShareCapturingGuidance() {
        this.deviceType === InviteDeviceType.ios
            ? this.updateGuidance(ScreenShareIaOne)
            : this.updateGuidance(ScreenShareAa);
    }

    @action
    show() {
        this._isVisible = true;
    }

    @action
    hide(): void {
        this._isVisible = false;
    }

    @action
    endMeeting(): void {
        this._endMeeting();
    }

    @computed
    get isVisible() {
        return this._isVisible;
    }

    @computed
    get settings() {
        return this._settings;
    }

    @computed
    get deviceType() {
        if (this._settings.stepType === DesktopShareAllowSharingGuidance.stepType) {
            return InviteDeviceType.common;
        }

        return this._dashboardState.clientOs.toLowerCase().indexOf(InviteDeviceType.ios) > -1
            ? InviteDeviceType.ios
            : InviteDeviceType.android;
    }

    @computed
    get isScreenSharingIOS() {
        return this.deviceType === InviteDeviceType.ios && this.settings.stepType === ScreenShareAa.stepType;
    }

    @computed
    get isGoToApplicationIOS() {
        return this.deviceType === InviteDeviceType.ios && this.settings.stepType === GoToApplicationGuidance.stepType;
    }

    get translate() {
        return this._translate;
    }

    @computed
    get isCameraApprovalShown(): boolean {
        return this.isVisible && this.settings.stepType === AllowCameraGuidance.stepType;
    }
}
