'use strict';

// in milliseconds

export const MESSAGE_TIMEOUT = 5000;

export const SESSION_TYPES = {
    LIVE: 'LIVE',
    OFFLINE: 'OFFLINE',
    SCREEN_SHARING: 'SCREEN',
    APPLICATION_SHARING: 'APPLICATION_SHARING',
    CO_BROWSING: 'CO_BROWSING'
};

export const OFFLINE_ROOM_UPDATE_INTERVAL = 40000;
