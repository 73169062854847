import React from 'react';
import {observer} from 'mobx-react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {IWizardHeaderComponent} from '@techsee/techsee-ui-common/lib/wizard/contracts';
import {IInviteWizardController} from '../_contracts/InviteContracts';
import {InviteGenericStep} from './steps/_InviteGenericStep';

import '@techsee/techsee-ui-common/lib/wizard/components/header/header.css';

@observer
export class InviteWizardHeader extends TechseeBaseComponent<IWizardHeaderComponent<IInviteWizardController>> {
    renderInternal() {
        const {wizardController, wizClassName} = this.props;

        this.addCssClass(wizClassName);
        this.addCssClass('wizard-default-header');
        this.addCssClass(this.props.className);

        return (
            <div className={this.renderCssClasses()}>
                <ul className={`steps-list steps-${wizardController.wizardHeaderGroups.length}`}>
                    {wizardController.wizardHeaderGroups.map((group, index) => {
                        const currentStep = wizardController.currentStep as InviteGenericStep;
                        const itemClasses = ['step'];

                        currentStep && group.steps.indexOf(currentStep.stepType) >= 0 && itemClasses.push('active');

                        return (
                            <React.Fragment key={index}>
                                <li key={index} className={itemClasses.join(' ')}>
                                    <div className={'step-circle'}>{index + 1}</div>
                                    {group.title}
                                </li>
                            </React.Fragment>
                        );
                    })}
                </ul>
                <div className='steps-list__line'></div>
            </div>
        );
    }
}
