import {TsDeviceClassificationService} from './ts-device-classification.service';
import {tsDeviceClassification} from './ts-device-classification.directive';
import {tsImageViewPanel} from './ts-image-view-panel/ts-image-view-panel.directive';
import {tsSolutionWidget} from './ts-solution-widget/ts-solution-widget.directive';
import {tsAnalysisSummaryDirective} from './ts-analysis-summary/ts-analysis-summary.directive';
import {ErrorDialogModal} from '../../../states/dashboard/main/dialogs/error-dialog.service';

angular
    .module('ts-device-classification-module', [])
    .service('ErrorDialogModal', ErrorDialogModal)
    .service('tsDeviceClassificationService', TsDeviceClassificationService)
    .directive('tsDeviceClassification', tsDeviceClassification)
    .directive('tsSolutionWidget', tsSolutionWidget)
    .directive('tsImageViewPanel', tsImageViewPanel)
    .directive('tsAnalysisSummary', tsAnalysisSummaryDirective);
