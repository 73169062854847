'use strict';
import {resourceKind} from '@techsee/techsee-common/lib/constants/resource.constant';

import mainView from './main.view.html';
import mainNewView from './main-new.view.html';
import mainMobileView from './main-mobile.view.html';
import mainMiniView from './main-mini.view.html';
import mainMiniMobileView from './main-mini-mobile.view.html';
import {MainController} from './main.controller.js';

function config($stateProvider) {
    $stateProvider.state('dashboard.main', {
        url: '/main',
        views: {
            'desktop-dashboard-view': {
                template: mainView + mainNewView,
                controller: 'MainController',
                controllerAs: 'main'
            },
            'mobile-dashboard-view': {
                template: mainMobileView,
                controller: 'MainController',
                controllerAs: 'main'
            },
            'mini-dashboard-view': {
                template: mainMiniView,
                controller: 'MainController',
                controllerAs: 'main'
            },
            'mini-mobile-dashboard-view': {
                template: mainMiniMobileView,
                controller: 'MainController',
                controllerAs: 'main'
            }
        },
        resolve: {
            // Expose the associated user account data, if he is associated
            accountData: (db, currentUser) => {
                if (!currentUser.accountId) {
                    return [];
                }

                return db.Account.find(currentUser.accountId, {bypassCache: true});
            },
            scanners: (tsScan, accountData, roomData) => tsScan.getScanners(accountData, roomData)
        }
    });
}

export default angular.module('states.dashboard.main', []).config(config).controller('MainController', MainController);
