'use strict';

export class SendTextController {
    constructor(tsChatApi, $modalInstance, placeholder, readonly, $rootScope, endMeeting) {
        'ngInject';

        this.chatApi = tsChatApi.service;
        this.$modalInstance = $modalInstance;
        this.text = placeholder;
        this.readonly = readonly;
        this.showNotification = false;
        this.endMeeting = endMeeting;

        // Close Modal dialog on route changing
        $rootScope.$on('$locationChangeSuccess', () => $modalInstance.dismiss());
    }

    ok() {
        if (!this.text) {
            return (this.showNotification = true);
        }

        this.$modalInstance.close(this.text);
    }

    cancel() {
        this.$modalInstance.dismiss();
    }
}
