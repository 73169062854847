'use strict';

import tsModalImageView from './ts-modal-image.view.html';
import tsModalImagePopupView from './ts-modal-image-popup.view.html';
import {TsModalImagePopupController} from './ts-modal-image-popup.controller.js';
import './ts-modal-image.style.scss';

/**
 * Creates an image that when clicked is shown in full-size as a modal.
 *
 * Attributes:
 *
 * image-src: url of the image
 * image-title: (optional) shown beneath the mimimized image
 * image-text: (optional) text to show beneath the image, when the modal is open
 * image-note: (optiona) a small warning note, in red, shown between the image
 *             and its text, when the modal is open
 */

class TsModalImageController {
    constructor($element, $attrs, $scope, $rootScope, $modal, $translate) {
        'ngInject';

        this.$modal = $modal;
        this.$modalInstance = null;

        this.src = $rootScope.requireImage($attrs.imageSrc);

        this.title = $translate.instant($attrs.imageTitle);
        this.text = $translate.instant($attrs.imageText);
        this.note = $translate.instant($attrs.imageNote);
    }

    toggle() {
        if (this.$modalInstance) {
            this.$modalInstance.close();
            this.$modalInstance = null;
        } else {
            this.$modalInstance = this.$modal.open({
                animation: true,
                template: tsModalImagePopupView,
                controller: TsModalImagePopupController,
                controllerAs: 'modalImage',
                windowClass: 'ts-modal-image-popup',
                resolve: {
                    imageData: {
                        src: this.src,
                        title: this.title,
                        note: this.note,
                        text: this.text
                    }
                }
            });
            this.$modalInstance.result.catch(() => false);
        }
    }
}

export function tsModalImageDirective() {
    return {
        template: tsModalImageView,
        replace: true,
        restrict: 'AE',
        scope: {},
        controller: TsModalImageController,
        controllerAs: 'vm'
    };
}
