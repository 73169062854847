/* eslint-disable indent */
import React from 'react';
import {observer} from 'mobx-react';
import {action, computed, observable} from 'mobx';
import {IObservableSessionsController} from './controller';
import {Table} from '@techsee/techsee-ui-common/lib/table';
import {Icon} from '@techsee/techsee-ui-common/lib/icons/simple-icon';
import {getIconNameByMode} from '../../../../../components/start-with-modes/component';
import isEmpty from 'lodash/isEmpty';
import {IObservableByAccountResponse} from '../../../../../services/AngularServices/AngularServices';
import moment from 'moment';

import './styles.scss';

export interface IObservableSessions {
    ctrl: IObservableSessionsController;
    noSessionsComponent: React.ReactNode;
    onSearchObservableRooms: () => Promise<IObservableByAccountResponse>;
}

@observer
export class ObservableSessions extends React.Component<IObservableSessions> {
    componentDidMount() {
        this.onRefreshClick();
    }

    @observable private _disableRefreshButton: boolean = false;

    @observable private _timeRefresh: string = '';

    private onRefreshClick = () => {
        this.setTimeRefresh(moment().format('HH:mm:ss').toString());
        this.setDisabledRefreshButton(true);
        this.props.ctrl.setErrorMessage(false);
        this.props
            .onSearchObservableRooms()
            .then((data) => this.props.ctrl.setObservableUsers(data))
            .catch(() => this.props.ctrl.setErrorMessage(true))
            .finally(() => this.setDisabledRefreshButton(false));
    };

    @computed
    private get timeRefresh() {
        return this._timeRefresh;
    }

    @action
    private setTimeRefresh(time: string) {
        this._timeRefresh = time;
    }

    @action
    private setDisabledRefreshButton(value: boolean) {
        this._disableRefreshButton = value;
    }

    @computed
    private get disableRefreshButton() {
        return this._disableRefreshButton;
    }

    render() {
        const {translate, observableUsers, joinSession, displayErrorMessage} = this.props.ctrl;

        const TRANSLATE_PREFIX = 'REACT.INVITE.VIEW.SESSION_OBSERVATION';
        const noSessionsExists = isEmpty(observableUsers);
        const translations = noSessionsExists
            ? translate(`${TRANSLATE_PREFIX}.TITLE`)
            : `${translate(`${TRANSLATE_PREFIX}.TITLE`)} (${translate(
                  'REACT.INVITE.VIEW.SESSION_OBSERVATION.TITLE_SESSIONS'
              )})`;

        return (
            <div className='observable-sessions'>
                <div className='observable-sessions__title'>
                    {translations}:
                    <div>
                        <button
                            disabled={this.disableRefreshButton}
                            className={'observable-sessions__refresh-button'}
                            onClick={this.onRefreshClick}>
                            <Icon className={'observable-sessions__icon-refresh'} iconName={'arrows-circle'}></Icon>
                            Refresh
                        </button>
                        <span>{this.timeRefresh}</span>
                    </div>
                </div>
                {displayErrorMessage ? (
                    <div className={'observable-sessions__error'}>{translate(`${TRANSLATE_PREFIX}.ERROR`)}</div>
                ) : (
                    <React.Fragment />
                )}
                {noSessionsExists ? (
                    this.props.noSessionsComponent
                ) : (
                    <Table striped condensed responsive>
                        <thead>
                            <tr>
                                <th>{translate(`${TRANSLATE_PREFIX}.AGENT`)}</th>
                                <th>{translate(`${TRANSLATE_PREFIX}.USER_NAME`)}</th>
                                <th>{translate(`${TRANSLATE_PREFIX}.START_TIME`)}</th>
                                <th>{translate(`${TRANSLATE_PREFIX}.DURATION`)}</th>
                                <th>{translate(`${TRANSLATE_PREFIX}.SESSION_ID`)}</th>
                                <th>{translate(`${TRANSLATE_PREFIX}.REFERENCE_ID`)}</th>
                                <th>{translate(`${TRANSLATE_PREFIX}.CUSTOMER_DETAILES`)}</th>
                                <th>{translate(`${TRANSLATE_PREFIX}.SESSION_TYPE`)}</th>
                                <th className='observable-sessions__lastcol'></th>
                            </tr>
                        </thead>
                        <tbody>
                            {observableUsers.map((session, index) => (
                                <tr key={index}>
                                    <td>{session.agentName}</td>
                                    <td>{session.userName}</td>
                                    <td>{session.startTimestamp}</td>
                                    <td>{session.duration}</td>
                                    <td>{session.sessionId}</td>
                                    <td>{session.customerId}</td>
                                    <td>{session.customerDetails}</td>
                                    <td>
                                        <Icon
                                            className={'observable-sessions__session-type'}
                                            iconName={getIconNameByMode(session.sessionType) || 'video'}
                                        />
                                    </td>
                                    <td>
                                        <div
                                            onClick={() => joinSession(session.sessionId)}
                                            className={'observable-sessions__join'}>
                                            Join{' '}
                                            <Icon
                                                className={'observable-sessions__icon'}
                                                iconName={'two-arrows-right'}></Icon>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                )}
            </div>
        );
    }
}
