'use strict';

import './end-meeting.style.scss';

export class EndMeetingDialogController {
    constructor($modalInstance) {
        'ngInject';

        this.$modalInstance = $modalInstance;
    }

    confirm() {
        this.$modalInstance.close(true);
    }

    closeMessage() {
        this.$modalInstance.close();
    }
}
