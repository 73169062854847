import {MeetingMode} from '@techsee/techsee-common/lib/constants/room.constants';
import {IBrowserUtilsService} from '@techsee/techsee-client-infra/lib/services/BrowserUtilsService';

export function updateFallbackModesNotification(
    mode: MeetingMode,
    roomId: string,
    browserUtilsService: IBrowserUtilsService
) {
    let fallbackModes = browserUtilsService.getFromSessionStorage('fallbackModes');

    if (!fallbackModes) {
        fallbackModes = {};
    }

    if (!fallbackModes[roomId]) {
        fallbackModes[roomId] = [];
    }

    if (fallbackModes[roomId].includes(mode)) {
        return false;
    }

    fallbackModes[roomId].push(mode);

    browserUtilsService.saveToSessionStorage('fallbackModes', fallbackModes);

    return true;
}
