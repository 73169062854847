import {
    IDbHistory,
    IHistoryImageParams,
    IHistoryGetByCustomerIdParams,
    IHistoryRoomParams,
    IHistorySearchData,
    IHistoryGetByCustomerIdOrPhoneNumberParams,
    IHistoryGetbyCustomerNumberParams
} from './AngularServices';
import {IResourceResponse} from '../../states/dashboard/session-summary/SessionSummary.contracts';

export class DbHistoryAdapter implements IDbHistory {
    constructor(db: any) {
        Object.defineProperty(this, '_dbService', {
            value: db,
            enumerable: false
        });
    }

    room(params: IHistoryRoomParams): Promise<IResourceResponse> {
        return (this as any)._dbService.History.room(params);
    }

    byCustomerId(params: IHistoryGetByCustomerIdParams): Promise<{data: IHistorySearchData}> {
        return (this as any)._dbService.History.byCustomerId(params);
    }

    byCustomerIdOrNumber(params: IHistoryGetByCustomerIdOrPhoneNumberParams): Promise<{data: IHistorySearchData}> {
        return (this as any)._dbService.History.byCustomerIdOrNumber(params);
    }

    byCustomerNumber(params: IHistoryGetbyCustomerNumberParams): Promise<{data: IHistorySearchData}> {
        return (this as any)._dbService.History.byCustomerNumber(params);
    }

    update(resourceId: string, postParams: IHistoryImageParams): Promise<void> {
        return (this as any)._dbService.HistoryImage.update(resourceId, postParams);
    }
}
