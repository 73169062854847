'use strict';

export class SMSStatusController {
    constructor($modalInstance, message, question, dir, tsChatApi) {
        'ngInject';

        this.$modalInstance = $modalInstance;
        this.message = message;
        this.question = question;
        this.dir = dir;

        // Close dialog if mobile connected
        tsChatApi.service.on('clientConnected', () => {
            this.answer(true);
        });
    }

    answer(result) {
        this.$modalInstance.close({answer: result});
    }

    ok() {
        this.$modalInstance.close();
    }
}
