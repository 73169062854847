import React from 'react';
import {observer} from 'mobx-react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import LeftSide from '../left-side/index';
import RightSide from '../right-side/index';
import LoginForm from '../login-form/index';
import LoginConfirmation from '../login-confirmation';

import './style.scss';

@observer
export class LoginLayoutComponent extends TechseeBaseComponent {
    renderInternal() {
        this.addCssClass('login-root-layout use-common-styles');

        return (
            <div className={this.renderCssClasses()}>
                <div className={'login-left-side'}>
                    <LeftSide />
                </div>
                <LoginForm></LoginForm>
                <div className={'login-right-side'}>
                    <RightSide />
                </div>

                <LoginConfirmation />
            </div>
        );
    }
}

export default LoginLayoutComponent;
