import React from 'react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {observer} from 'mobx-react';
import {SimpleLabel} from '@techsee/techsee-ui-common/lib/forms/labels/simple';
import {GenericModal} from '@techsee/techsee-ui-common/lib/modals/generic';
import {ISpeedtestController} from './SpeedtestModal.controller';

import './SpeedtestModal.style.scss';

interface SpeedtestPopoverProps {
    isVisible: boolean;
    ctrl: ISpeedtestController;

    onCloseGuidance(): void;
}

@observer
export class SpeedtestModal extends TechseeBaseComponent<SpeedtestPopoverProps> {
    renderInternal(): JSX.Element {
        const {isVisible, ctrl, onCloseGuidance} = this.props;

        // TODO: Please, do not create any functions inside render method due to performance resons.
        // (On each render new function will be created and a part of memory will be in use)
        const Header = () => <SimpleLabel>{ctrl.translate('REACT.SPEEDTEST_MODAL.VIEW.HEADER')}</SimpleLabel>;
        const Content = () => (
            <div className='content-speedtest'>
                <SimpleLabel>{ctrl.translate('REACT.SPEEDTEST_MODAL.VIEW.CONTENT')}</SimpleLabel>
                <img src={ctrl.loadImage('speedtest-guidance.png')} />
            </div>
        );
        const Toolbar = () => <React.Fragment />;

        return (
            <GenericModal
                className={'speedtest-panel'}
                header={Header}
                content={Content}
                toolbar={Toolbar}
                onHideHandler={onCloseGuidance}
                show={isVisible}></GenericModal>
        );
    }
}
