'use strict';

import isUndefined from 'lodash/isUndefined';

class EmailValidatorController {
    constructor(db, $q, $attrs, $scope) {
        'ngInject';

        this.db = db;
        this.$q = $q;

        // Load options
        // `allowInitial` - Don't validate the first loaded value
        //
        // e.g. `<input type="text" email-validator="{ allowInitial: true }"`
        this.options = $scope.$eval($attrs.emailValidator, $scope) || {};
    }

    /**
     * Check if there's already a user with that email
     *
     * @param  {email}  email The email to search for
     */
    isEmailAvailable(email) {
        return this.db.User.isEmailAvailable({
            params: {
                email: email
            }
        }).then((res) => res.data);
    }
}

function linkFn(scope, element, attrs, ctrl) {
    const selfCtrl = ctrl[0];
    const emailInput = ctrl[1];
    let initialValue = '';

    emailInput.$asyncValidators.emailTaken = (modelValue) => {
        // Cache the initial model value for future reference
        if (isUndefined(initialValue)) {
            initialValue = modelValue;
        }

        // If `allowInitial` is set, consider the initial value always valid
        if (!modelValue || (selfCtrl.options.allowInitial && modelValue === initialValue)) {
            return selfCtrl.$q.when(true);
        }

        return selfCtrl.isEmailAvailable(modelValue).then((isAvailable) => {
            if (!isAvailable) {
                return selfCtrl.$q.reject(false);
            }

            return true;
        });
    };
}

export function emailValidatorDirective() {
    return {
        restrict: 'A',
        scope: {},
        controller: EmailValidatorController,
        controllerAs: 'vm',
        require: ['emailValidator', 'ngModel'],
        link: linkFn
    };
}
