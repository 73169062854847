'use strict';

export const EXPORT_SETTINGS = {
    subSamplingThreshold: 1024 * 1024,
    defaultMime: 'image/jpeg',
    imageQuality: 0.8
};

// This class manages exporting from a FabricJS canvas to an
// ObjectURL that can be used for sending images to the user
export class CanvasExport {
    constructor(URL) {
        this.URL = URL;
    }

    init(canvas) {
        this.canvas = canvas.getElement();
    }

    /**
     * Get the ObjectURL of the canvas (resized if it's over the threshold)
     *
     * @return ObjectURL
     */
    getObjectURL(options = {}) {
        if (!this.canvas) {
            return null;
        }

        if (options.useOriginalCanvas) {
            return this._getCanvasObjectURL(this.canvas, 1);
        }

        if (this.canvas.width * this.canvas.height > EXPORT_SETTINGS.subSamplingThreshold) {
            return this._getSubSampledObjectURL();
        }

        return this._getCanvasObjectURL(this.canvas);
    }

    getDataURL(
        {format = EXPORT_SETTINGS.defaultMime, quality = EXPORT_SETTINGS.imageQuality, x, y, w, h} = {},
        srcCanvas = this.canvas
    ) {
        if (x || y || w || h) {
            const canvas = document.createElement('canvas'),
                ctx = canvas.getContext('2d');

            canvas.width = w;
            canvas.height = h;

            ctx.drawImage(srcCanvas, x, y, w, h, 0, 0, w, h);

            return canvas.toDataURL(format, quality);
        }

        return srcCanvas.toDataURL(format, quality);
    }

    /**
     * Creates a subsampled canvas and copies the current one to it,
     * then it returns its ObjectURL
     *
     * @return ObjectURL
     */
    _getSubSampledObjectURL() {
        // create second canvas and get its context
        const smallCanvas = angular.element('<canvas/>')[0],
            ctx = smallCanvas.getContext('2d'),
            width = this.canvas.width / 2,
            height = this.canvas.height / 2;

        // resize it
        smallCanvas.width = width;
        smallCanvas.height = height;

        // copy data of original canvas, on the resized one
        ctx.drawImage(this.canvas, 0, 0, width, height);

        return this._getCanvasObjectURL(smallCanvas);
    }

    /**
     * Get the ObjectURL of a canvas
     *
     * @param canvas - target canvas
     * @return ObjectURL
     */
    _getCanvasObjectURL(canvas, quality) {
        const dataUrl = canvas.toDataURL(EXPORT_SETTINGS.defaultMime, quality || EXPORT_SETTINGS.imageQuality);

        return this.dataUrlToObjectUrl(dataUrl);
    }

    /**
     * Converts the dataURL that we can obtain from the canvas, to a Blob
     *
     * @param dataUrl - data url of the canvas
     * @returns {Blob} - Blob of the image
     */
    dataUrlToBlob(dataUrl) {
        const urlComponents = dataUrl.split(';base64,'),
            mimeType = urlComponents[0].split(':')[1],
            bytes = atob(urlComponents[1]),
            buffer = new ArrayBuffer(bytes.length),
            rawData = new Uint8Array(buffer);

        for (let i = 0; i < bytes.length; i++) {
            rawData[i] = bytes.charCodeAt(i);
        }

        return new Blob([rawData], {type: mimeType});
    }

    fabricSrcToDataUrl(img, options) {
        const canvas = document.createElement('canvas'),
            context = canvas.getContext('2d');

        canvas.width = img.width;
        canvas.height = img.height;

        context.drawImage(img, 0, 0, img.width, img.height);

        return this.getDataURL(options, canvas);
    }

    /**
     * Converts the dataURL that we can obtain from the canvas, to an ObjectURL
     * that we can feed to the chat api service
     *
     * @param dataUrl - data url of the canvas
     * @returns {ObjectURL} - ObjectURL of the image
     */
    dataUrlToObjectUrl(dataUrl) {
        return this.URL.createObjectURL(this.dataUrlToBlob(dataUrl));
    }
}
