'use strict';

import './entry.style.scss';
import './entry-wizard.style.scss';
import {getRootStore} from '../../../_react_/app.bootstrap';

export class EntryController {
    // eslint-disable-next-line max-params
    constructor(
        $timeout,
        $state,
        $translate,
        $localStorage,
        db,
        roomData,
        tsLocaleHelper,
        tsUrlUtils,
        $modal,
        $scope,
        LogEvents,
        patternsConstant,
        wizardState,
        accountData,
        currentUser,
        tsInterfaceHelper
    ) {
        'ngInject';

        this.$state = $state;
        this.$timeout = $timeout;
        this.$translate = $translate;
        this.$localStorage = $localStorage;
        this.db = db;
        this.roomData = roomData;
        this.$modal = $modal;
        this.dir = tsLocaleHelper.isRTL() ? 'rtl' : 'ltr';
        this.LogEvents = LogEvents;
        this.mobilePhonePattern = patternsConstant.mobilePhone;
        this._applyWizardState(wizardState, accountData, currentUser);
        this.cameraModeOnly = accountData.settings ? accountData.settings.cameraModeOnly : false;
        this.chromeDetection = accountData.protectedSettings ? accountData.protectedSettings.chromeDetection : false;
        this.isIE11 = getRootStore().environmentService.isIE11();
        this.tsInterfaceHelper = tsInterfaceHelper;
        this.platform = this.wizardState.platform;
    }

    _applyWizardState(wizardState, accountData, currentUser) {
        if (
            wizardState.showWizard &&
            !(accountData.settings && accountData.settings.showWizard && currentUser.showWizard)
        ) {
            // main state tampers with the wizard state so if the back button
            // is pressed, we may need to reset it according to settings
            wizardState.showWizard = false;
        }
        this.wizardState = wizardState;
        this.showWizard = wizardState.showWizard;
    }

    showInstructionsFor(platform) {
        this.platform = platform;
        this.wizardState.platform = platform;

        if (platform && this._platformSelectCb) {
            this._platformSelectCb();
            this._platformSelectCb = null;
        }
    }

    _waitPlatformSelection(cb) {
        this._platformSelectCb = cb;
    }

    isVisible(platform) {
        return this.platform === platform;
    }

    inSelectScreen() {
        return !this.platform;
    }

    back() {
        this.showInstructionsFor(null);
    }
}
