'use strict';

import forEach from 'lodash/forEach';

/**
 * CanvasObjectGroups is a class that takes care of composite objects,
 * currently, text-with-baloon and arrow. The current implementation only
 * really supports couples
 */
export class CanvasObjectGroups {
    constructor() {
        this.reset();
    }

    reset() {
        this.groups = {};
        this.groupCnt = 0;
    }

    isGrouped(obj) {
        return !!obj.groupId;
    }

    /*
     * When a part of a composite object is deleted, we want it all
     * to be removed. This function takes care to not re-run for the
     * second object.
     *
     * @param obj {Object} object initally removed
     */
    removeRestGroup(canvas, obj) {
        const group = this.groups[obj.groupId];

        if (!group.deleting) {
            const obj2 = this.getGroupedObject(obj);

            group.deleting = true;
            canvas.remove(obj2);
        } else {
            this.groups[obj.groupId] = null;
        }
    }

    getGroupedObject(obj) {
        const group = this.groups[obj.groupId];

        for (const key in group) {
            if (key !== obj.type) {
                return group[key];
            }
        }

        return null;
    }

    getGroup(obj) {
        return this.groups[obj.groupId];
    }

    /* recreate all indexed groups, based on the groupIds found on the
     * list of objects provided. Needed after undo.
     *
     * @param objects {Array} list of canvas objects
     */
    rebuildGroupIndexes(objects) {
        forEach(objects, (obj) => {
            if (obj.groupId) {
                if (!this.groups[obj.groupId]) {
                    this.groups[obj.groupId] = {};
                }
                this.groups[obj.groupId][obj.type] = obj;
            }
        });
    }

    /*
     * add a new group (pair) to the index
     */
    indexGroup(obj1, obj2, type) {
        const groupId = this.getNewGroupId();

        obj1.groupId = groupId;
        obj2.groupId = groupId;

        this.groups[groupId] = {
            [obj1.type]: obj1,
            [obj2.type]: obj2,
            type: type
        };
    }

    /*
     * create a new id for a group
     */
    getNewGroupId() {
        const groupId = 'group_' + this.groupCnt;

        this.groupCnt++;

        return groupId;
    }
}
