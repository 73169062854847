import React from 'react';
import {
    AlignEnum,
    DashboardSectionTypes,
    ILeftBarItemData,
    ILeftBarSection,
    LeftBarControllerBase,
    LeftBarSection
} from '../../../../../../layouts/main-layout/left-bar-base/controller';
import {ITranslate} from '../../../../../../services/LocalizationService';
import {action, observable} from 'mobx';
import {ShareScreenPopupComponent} from '../../../../../../components/twoWay-share-screen';
import {RemoteControlPopupComponent} from '../../../../../../components/remote-control-section';
import RemoteControlIcon from '../../../../../../../../img/remote-control-icon.svg';
import {ShareScreenPopupController} from '../../../../../../components/twoWay-share-screen/controller';

export class LiveLeftBarController extends LeftBarControllerBase {
    @observable _leftBarSections: ILeftBarSection[] = [];
    @observable _agentHasRemoteControl: boolean = false;

    constructor(
        translate: ITranslate,
        enabledDesktopSharingInTwoWay: boolean,
        private readonly enableRemoteControl: boolean,
        private readonly shareScreenPopupController: ShareScreenPopupController
    ) {
        super(translate);

        this._leftBarSections = this.getLeftBarItems();

        if (enabledDesktopSharingInTwoWay) {
            this.addScreenSharingSection();
        }
    }

    @action
    cleanSelectedStates() {
        this.executeItem(
            (item) => item.isSelected,
            (section) => section.sectionType === DashboardSectionTypes.TWO_WAY,
            (item) => {
                item.isSelected = false;
            }
        );
    }

    @action
    setRemoteControlContentVisibility(value: boolean) {
        this.executeItem(
            () => true,
            (section) => section.sectionType === DashboardSectionTypes.TWO_WAY,
            (item) => {
                if (item.uid === 'remoteControl') {
                    item.hideContent = value;
                }
            }
        );
    }

    @action
    setScreenShareDisabled(value: boolean) {
        this.executeItem(
            () => true,
            (section) => section.sectionType === DashboardSectionTypes.TWO_WAY,
            (item) => {
                if (item.uid === 'shareScreen') {
                    item.isDisabled = value;
                }
            }
        );
    }

    @action
    setRemoteControlDisabled(value: boolean) {
        this.executeItem(
            () => true,
            (section) => section.sectionType === DashboardSectionTypes.TWO_WAY,
            (item) => {
                if (item.uid === 'remoteControl') {
                    item.isDisabled = value;
                }
            }
        );
    }

    @action
    setRemoteControlActive(value: boolean) {
        this._agentHasRemoteControl = value;

        this.executeItem(
            () => true,
            (section) => section.sectionType === DashboardSectionTypes.TWO_WAY,
            (item) => {
                if (item.uid === 'remoteControl') {
                    item.isActive = value;
                    item.isHovered = value;
                    item.isSelected = false;
                }
            }
        );
    }

    getHeaderComponent() {
        return <React.Fragment />;
    }

    @action
    getLeftBarItems() {
        const profileButton: ILeftBarItemData = {
            uid: 'profile',
            icon: () => 'profile',
            isDisabled: false,
            isActive: false,
            isSelected: false,
            isHovered: false,
            onItemClicked: function () {},
            tooltip: () => this.translate('REACT.DASHBOARD.VIEW.TOOLTIP.SETTINGS')
        };

        const helpSection = new LeftBarSection(AlignEnum.BOTTOM, false, [profileButton], DashboardSectionTypes.HELP);

        return [helpSection];
    }

    @action
    private addScreenSharingSection() {
        this._leftBarSections.push(
            new LeftBarSection(
                AlignEnum.TOP,
                false,
                [
                    {
                        uid: 'twoWay',
                        icon: () => 'facemeet',
                        isDisabled: false,
                        isActive: true,
                        isSelected: false,
                        isHovered: false,
                        onItemClicked: function () {},
                        tooltip: () => this.translate('REACT.INVITE.START_WITH_MODES.FACE_MEET')
                    }
                ],
                DashboardSectionTypes.TWO_WAY
            ),
            new LeftBarSection(
                AlignEnum.TOP,
                false,
                [
                    {
                        uid: 'shareScreen',
                        icon: () => 'Screen-Share',
                        isDisabled: true,
                        isActive: false,
                        isSelected: false,
                        isHovered: false,
                        onItemClicked: function () {},
                        content: () => <ShareScreenPopupComponent closePopover={this.cleanSelectedStates} />,
                        tooltip: () =>
                            this.shareScreenPopupController?.isSharingScreen
                                ? this.translate('REACT.MULTIPARTY_DASHBOARD.SHARE_SCREEN_POPUP.STOP_SHARING.TITLE')
                                : this.translate('REACT.MULTIPARTY_DASHBOARD.SHARE_SCREEN_POPUP.START_SHARING.TITLE')
                    }
                ],
                DashboardSectionTypes.TWO_WAY
            )
        );

        !!this.enableRemoteControl &&
            this._leftBarSections.push(
                new LeftBarSection(
                    AlignEnum.TOP,
                    false,
                    [
                        {
                            uid: 'remoteControl',
                            icon: () => (
                                <img className={'request-control-img'} src={RemoteControlIcon} alt='Remote Control' />
                            ),
                            isDisabled: false,
                            isActive: this._agentHasRemoteControl,
                            isSelected: false,
                            isHovered: false,
                            hideContent: false,
                            onItemClicked: () => {},
                            content: () => <RemoteControlPopupComponent closePopover={this.cleanSelectedStates} />,
                            tooltip: () =>
                                this._agentHasRemoteControl
                                    ? this.translate('REACT.MULTIPARTY_DASHBOARD.REMOTE_CONTROL.STOP.STOP_BUTTON')
                                    : this.translate(
                                          'REACT.MULTIPARTY_DASHBOARD.REMOTE_CONTROL.POPUP.START_SHARING.TITLE'
                                      )
                        }
                    ],
                    DashboardSectionTypes.TWO_WAY
                )
            );
    }

    @action
    onLeftBarItemClicked(item: ILeftBarItemData, sectionType: DashboardSectionTypes): void {
        if (item.isDisabled) {
            return;
        }

        this.cleanSelectedStates();

        if (item.content) {
            item.isSelected = !item.isSelected;
        } else {
            item.onItemClicked();
        }
    }
}
