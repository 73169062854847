import React from 'react';

import '../styles.scss';

interface IGuidanceTooltipSingle {
    text?: string;
    num?: number | string;
}

interface IGuidanceTooltipProps extends IGuidanceTooltipSingle {
    list?: IGuidanceTooltipSingle[];
}

const Tooltip = (props: {num: number | string; text: string; index?: number}) => (
    <div className={`agent-guidance-tooltip step-${props.num}`}>
        <span className={'step-num'}>{props.num}</span>
        {props.text}
    </div>
);

const GuidanceTooltip: React.FunctionComponent<IGuidanceTooltipProps> = ({num, text, list}: IGuidanceTooltipProps) =>
    list ? (
        <React.Fragment>
            {list.map((tooltip: IGuidanceTooltipSingle, index: number) => (
                <Tooltip key={`guidanceTooltip${index}`} num={tooltip.num!} text={tooltip.text!} />
            ))}
        </React.Fragment>
    ) : (
        <Tooltip num={num!} text={text!} />
    );

export default GuidanceTooltip;
