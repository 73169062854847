'use strict';
// @ts-ignore
import {EVENT_LEVELS, LOG_EVENTS} from '@techsee/techsee-common/lib/constants/event-logs.constants';
import {IHash} from '@techsee/techsee-ui-common/lib/_shared/reusable-types';

export type EventLogLevels = EVENT_LEVELS.debug | EVENT_LEVELS.error | EVENT_LEVELS.info | EVENT_LEVELS.warn;

export interface EventLogConstant {
    events: IHash<Record<string, any>>;
    counters: IHash<string>;
    closedByTypes: IHash<string>;
    smsResolutionTypes: IHash<string>;
    NO_ROOM: string;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EventLogConstant: EventLogConstant = {
    // Supported event types
    events: LOG_EVENTS,

    // Supported room counters
    counters: {
        receivedImages: 'receivedImages',
        receivedVideos: 'receivedVideos',
        snappedImages: 'snappedImages',
        sentImages: 'sentImages',
        savedImages: 'savedImages',
        annotatedImages: 'annotatedImages',
        clientSentImages: 'clientSentImages',
        clientSentVideos: 'clientSentVideos',
        clientReceivedImages: 'clientReceivedImages',
        smsSent: 'smsSent'
    },

    // Supported `closed by` types
    closedByTypes: {
        noOne: 'NO_ONE',
        dashboard: 'DASHBOARD',
        system: 'SYSTEM',
        mobile: 'MOBILE',
        chat: 'CHAT',
        simpleApi: 'SIMPLE_API'
    },

    smsResolutionTypes: {
        moveToDashboard: 'MOVE_TO_DASHBOARD',
        resendSms: 'RESEND_SMS',
        displayPrompt: 'DISPLAY_PROMPT',
        displayErrorMessage: 'DISPLAY_ERROR_MESSAGE'
    },

    NO_ROOM: 'none'
};
