'use strict';

import {disableNgAnimateDirective} from './disable-ng-animate/disable-ng-animate.directive.js';
import {emailValidatorDirective} from './email-validator/email-validator.directive.js';
import {highlightControlDirective} from './highlight-control/highlight-control.directive.js';
import {tsScrollDownDirective} from './ts-scroll-down/ts-scroll-down.directive.js';
import {tsVideoSubscriberDirective} from './ts-video-subscriber/ts-video-subscriber.directive.js';
import {tsEnvironmentIfDirective} from './ts-environment-if/ts-environment-if.directive.js';
import {tsMouseWheelDirective} from './ts-mousewheel/ts-mousewheel.directive.js';
import {tsDetachableWidgetDirective} from './ts-detachable-widget/ts-detachable-widget.directive.js';
import {tsCanvasCopyDirective} from './ts-canvas-copy/ts-canvas-copy.directive.js';
import {tsShowPasswordDirective} from './ts-show-password/ts-show-password.directive.js';
import {compareToDirective} from './compare-to/compare-to.directive.js';
import {compileDirective} from './compile/compile.directive';
import {tsTableScrollFixDirective} from './ts-table-scroll-fix/ts-table-scroll-fix.directive';
import {tsAccordionHeightFixDirective} from './ts-accordion-height-fix/ts-accordion-height-fix.directive';
import {tsBadgedImageDirective} from './ts-badged-image/ts-badged-image.directive';
import {tsOnloadDirective} from './ts-onload/ts-onload.directive';
import {tsPopoverModalDirective} from './popover-modal/ts-popover-modal.directive';

export default angular
    .module('app.directives', ['passwordValidator.directive'])
    .directive('disableNgAnimate', disableNgAnimateDirective)
    .directive('emailValidator', emailValidatorDirective)
    .directive('highlightControl', highlightControlDirective)
    .directive('tsScrollDown', tsScrollDownDirective)
    .directive('tsVideoSubscriber', tsVideoSubscriberDirective)
    .directive('tsEnvironmentIf', tsEnvironmentIfDirective)
    .directive('tsMouseWheel', tsMouseWheelDirective)
    .directive('tsDetachableWidget', tsDetachableWidgetDirective)
    .directive('tsCanvasCopy', tsCanvasCopyDirective)
    .directive('tsShowPassword', tsShowPasswordDirective)
    .directive('compareTo', compareToDirective)
    .directive('compile', compileDirective)
    .directive('tsTableScrollFix', tsTableScrollFixDirective)
    .directive('tsAccordionHeightFix', tsAccordionHeightFixDirective)
    .directive('tsBadgedImage', tsBadgedImageDirective)
    .directive('tsOnload', tsOnloadDirective)
    .directive('tsPopoverModal', tsPopoverModalDirective);
