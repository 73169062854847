import React from 'react';
import {observable, computed, action} from 'mobx';
import {observer} from 'mobx-react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {PrimaryButton} from '@techsee/techsee-ui-common/lib/forms/buttons/primary';
import {SecondaryButton} from '@techsee/techsee-ui-common/lib/forms/buttons/secondary';
import {ButtonTypeAttributes} from '@techsee/techsee-ui-common/lib/forms/buttons/generic';
import {SimpleLabel} from '@techsee/techsee-ui-common/lib/forms/labels/simple';
import {FormInput} from '@techsee/techsee-ui-common/lib/forms/input';
import {MessageAlert} from '@techsee/techsee-ui-common/lib/message-alert';
import {SimpleTabs, ITab} from '@techsee/techsee-ui-common/lib/tabs/simple';
import {IFieldModel, SimpleFieldModel} from '@techsee/techsee-ui-common/lib/forms/_shared/simple-field-model';
import {
    Spinner,
    SpinnerBackgroundColors,
    SpinnerColors,
    SpinnerSizes,
    SpinnerTypeOptions
} from '@techsee/techsee-ui-common/lib/spinner';
import {Datepicker} from '@techsee/techsee-ui-common/lib/datepicker';
import moment from 'moment';

import './styles.scss';

export const SESSIONS_PER_PAGE = 20;
export const MAX_PAGING_BUTTONS_PER_LINE = 10;

export enum tabsNames {
    All = 0,
    Images = 1
}

export interface IHistorySearchByTagsView {
    controller: any;
}

const defaultTabsContent: Array<Array<object>> = [];

@observer
export class HistorySearchByTags extends TechseeBaseComponent<IHistorySearchByTagsView> {
    @observable tags: IFieldModel = new SimpleFieldModel({
        value: '',
        placeholder: this.props.controller.translate('HISTORY.VIEW.SEARCH_BY_TAGS.TAGS_PLACEHOLDER')
    });

    @observable tabsTitles: string[] = [];

    @computed
    get disable() {
        return this.disableSubmit();
    }

    state = {
        tabsContent: defaultTabsContent,
        totalSessionsCount: 0,
        currentSessionsPage: 1,
        sessionsPagingButtons: [],
        isLoading: false,
        startDate: this.getFormattedDate(),
        endDate: this.getFormattedDate(),
        messageAlert: null,
        alertMessageTimeout: null,
        tagValue: '',
        currentTabIndex: 0,
        totalSessionsNumber: 0
    };

    constructor(props: {controller: any}) {
        super(props);

        this.renderTabs = this.renderTabs.bind(this);
    }

    renderTabs(index: number = -1) {
        const tabsList: ITab[] = [];

        if (index > -1) {
            this.setState({currentTabIndex: index});
        }

        let allTab: any = this.state.tabsContent[tabsNames.All]?.length
            ? this.state.tabsContent[tabsNames.All]
            : this.getNoResultsText();

        let imagesTab: any = this.state.tabsContent[tabsNames.Images]?.length
            ? this.state.tabsContent[tabsNames.Images]
            : '';

        allTab = this.state.isLoading ? (
            <Spinner
                backgroundColorName={SpinnerBackgroundColors.Transparent}
                colorName={SpinnerColors.Blue}
                spinnerType={SpinnerTypeOptions.DASH}
                sizeName={SpinnerSizes.Large}
            />
        ) : (
            allTab
        );
        imagesTab = this.state.isLoading ? (
            <Spinner
                backgroundColorName={SpinnerBackgroundColors.Transparent}
                colorName={SpinnerColors.Blue}
                spinnerType={SpinnerTypeOptions.DASH}
                sizeName={SpinnerSizes.Large}
            />
        ) : (
            imagesTab
        );

        tabsList.push({
            title: this.getTabTitle(tabsNames.All),
            content: () => <div className='items-container use-common-styles'>{allTab}</div>
        });
        tabsList.push({
            title: this.getTabTitle(tabsNames.Images),
            content: () => <div className='items-container image-container use-common-styles'>{imagesTab}</div>
        });

        return (
            <div className='main'>
                <SimpleTabs
                    uid={'search-by-tags-tabs-list'}
                    tabsList={tabsList}
                    onChange={(tabIndex: number) => this.renderTabs(tabIndex)}
                />
            </div>
        );
    }

    @action
    getTabTitle = (tabIndex: number): string => {
        if (this.tabsTitles.length > tabIndex) {
            this.tabsTitles[tabIndex] = '';
        } else {
            this.tabsTitles.push('');
        }

        switch (tabIndex) {
            case tabsNames.All:
                this.tabsTitles[tabIndex] = this.props.controller.translate('HISTORY.VIEW.ALL');
                // eslint-disable-next-line no-case-declarations
                const numOfSessions = this.props.controller.filteredData?.records?.length;

                if (numOfSessions > 0) {
                    this.tabsTitles[tabIndex] += ` (${numOfSessions})`;
                }
                break;

            case tabsNames.Images:
                this.tabsTitles[tabIndex] = this.props.controller.translate('HISTORY.VIEW.IMAGES');
                // eslint-disable-next-line no-case-declarations
                const numOfImages = this.props.controller.filteredData?.images;

                if (numOfImages > 0) {
                    this.tabsTitles[tabIndex] += ` (${numOfImages})`;
                }
                break;

            default:
                break;
        }

        return this.tabsTitles[tabIndex];
    };

    setSessionsResultsPaging = (totalSessionsNumber: number, currentPage = 1): JSX.Element => {
        if (!totalSessionsNumber) {
            this.setState({sessionsPagingButtons: []});

            return <></>;
        }

        const numOfPages = Math.floor(totalSessionsNumber / SESSIONS_PER_PAGE) + 1;
        const buttons = [];

        if (numOfPages) {
            for (let i = 1; i <= numOfPages; i++) {
                buttons.push(
                    <PrimaryButton
                        key={i}
                        disabled={currentPage === i}
                        onClick={() => this.onSessionPagingButtonClicked(i)}
                        className={'history-paging-button'}>
                        {i}
                    </PrimaryButton>
                );
                if (i % MAX_PAGING_BUTTONS_PER_LINE === 0) {
                    buttons.push(<br />);
                }
            }
            buttons.splice(
                0,
                0,
                <PrimaryButton
                    key={0}
                    disabled={currentPage === 1}
                    onClick={() => this.onSessionPagingButtonClicked(-1, true)}
                    className={'history-paging-edge-button'}>
                    Prev
                </PrimaryButton>
            );
            buttons.push(
                <PrimaryButton
                    key={buttons.length + 1}
                    disabled={currentPage === numOfPages}
                    onClick={() => this.onSessionPagingButtonClicked(-1, false, true)}
                    className={'history-paging-edge-button'}>
                    Next
                </PrimaryButton>
            );
        }

        this.setState({
            currentSessionsPage: currentPage,
            totalSessionsCount: totalSessionsNumber,
            sessionsPagingButtons: buttons
        });

        return buttons.length ? <>{buttons}</> : <></>;
    };

    setTabContent = (tabIndex: number): any => {
        const tabsContent: any = this.state.tabsContent;

        tabsContent[tabIndex] = [];

        switch (tabIndex) {
            case tabsNames.All:
                // eslint-disable-next-line no-case-declarations
                const allRecords: [any] = this.props.controller.filteredData?.records;

                allRecords?.length &&
                    allRecords.forEach((record: any) => {
                        const rec = {...record, _id: record.roomId};

                        tabsContent[tabIndex].push(
                            <div key={record._id}>
                                <div
                                    key={record._id}
                                    onClick={() => this.props.controller.loadRoom(rec, rec.images, rec.videos)}
                                    className={'btn btn-block history-session-button'}
                                    role={'button'}>
                                    <p>{moment(record.timestamp).format('LLL')}</p> <p>{record.images.length} images</p>
                                </div>
                            </div>
                        );
                    });
                break;

            case tabsNames.Images:
                // eslint-disable-next-line no-case-declarations
                const records: [any] = this.props.controller.filteredData?.records;

                records?.length &&
                    records.map(
                        (record: any) =>
                            record?.images?.length &&
                            tabsContent[tabIndex].push(
                                <div key={`${record._id}_${record.timestamp}`} className='timestamp-container'>
                                    {this.getFormattedDate(record.timestamp, 'LLL')}
                                </div>
                            ) &&
                            record.images.forEach((image: any) => {
                                const rec = {...record, _id: record.roomId};
                                const imageTags = image.tags.map((tag: any) => tag.text).join(', ');

                                tabsContent[tabIndex].push(
                                    <div key={`${record._id}_${image._id}`}>
                                        <img
                                            key={image._id}
                                            src={image.url}
                                            alt={''}
                                            title={imageTags}
                                            className={'image-item thumbnail'}
                                            onClick={() =>
                                                image.storageType !== 'TEMP' &&
                                                this.props.controller.loadDashboard(image._id, null, rec)
                                            }
                                        />
                                        <span className='tag-list'>{imageTags}</span>
                                    </div>
                                );
                            })
                    );
                break;

            default:
                break;
        }

        this.setState({tabsContent});
    };

    onSubmit = async () => {
        this.setState({isLoading: true}, this.searchAndSetTags);
    };

    searchAndSetTags = async () => {
        try {
            const countResponse = await this.props.controller.getSearchByTagsCount(
                this.tags.value,
                this.state.startDate,
                this.state.endDate
            );

            await this.props.controller.executeSearchByTags(
                this.tags.value,
                this.state.startDate,
                this.state.endDate,
                1
            );

            this.setTabContent(tabsNames.All);
            this.setTabContent(tabsNames.Images);
            this.setSessionsResultsPaging(countResponse.matches);
            this.setState({isLoading: false, tagValue: this.tags.value, totalSessionsNumber: countResponse.matches});
        } catch (err: any) {
            this.setMessageAlert(err.data.message, 'red', 3000);
            this.setState({isLoading: false});
        }
    };

    setMessageAlert = (label: string, color: string, timeout: number) => {
        // @ts-ignore
        clearTimeout(this.state.alertMessageTimeout);
        this.setState({messageAlert: {label, color}});
        this.setState({
            alertMessageTimeout: setTimeout(() => {
                this.setState({messageAlert: null});
            }, timeout)
        });
    };

    renderInternal(): JSX.Element {
        const {controller} = this.props;
        const {messageAlert, sessionsPagingButtons, totalSessionsNumber} = this.state;
        // @ts-ignore
        const message = messageAlert ? messageAlert.label : '';

        return (
            <div className='search-by-tags-main'>
                {messageAlert && <MessageAlert color={'red'} label={message} className={'message-alert'} />}
                <div className='top-section'>
                    <PrimaryButton
                        type={ButtonTypeAttributes.Button}
                        className='exitBtn'
                        onClick={() => window.close()}>
                        {controller.translate('HISTORY.VIEW.EXIT')}
                    </PrimaryButton>

                    <h3 className={'search-by-tags-title'}>
                        {controller.translate('HISTORY.VIEW.SEARCH_BY_TAGS.TITLE')}
                    </h3>
                </div>

                <div className='search-by-tags-container'>
                    <div className='sidebar'>
                        <SimpleLabel>{controller.translate('HISTORY.VIEW.FILTER_OPTIONS')}</SimpleLabel>

                        <div className='date-range'>
                            <Datepicker
                                startDate={new Date()}
                                onDateChange={(date: Date) =>
                                    this.setState({startDate: this.getFormattedDate(date)})
                                }></Datepicker>

                            <i className='glyphicon glyphicon-calendar calendar' />
                        </div>

                        <div className='date-range'>
                            <Datepicker
                                startDate={new Date()}
                                onDateChange={(date: Date) =>
                                    this.setState({endDate: this.getFormattedDate(date)})
                                }></Datepicker>

                            <i className='glyphicon glyphicon-calendar calendar' />
                        </div>

                        <FormInput
                            uid={'search-by-tags-input'}
                            inputType={'text'}
                            model={this.tags}
                            className='tags-input'></FormInput>

                        <SecondaryButton className='btn btn-default' disabled={this.disable} onClick={this.onSubmit}>
                            {controller.translate('CHANGE_PASSWORD.VIEW.PASSWORD_SUBMIT_BUTTON')}
                        </SecondaryButton>
                        {totalSessionsNumber > 0 && (
                            <div key={'simpleLabelDiv'}>
                                <SimpleLabel key={'totalSessionsLabel'} className={'total-sessions-label'}>
                                    {controller.translate('HISTORY.VIEW.SEARCH_BY_TAGS.TOTAL_RESULTS_LABEL')}{' '}
                                    {totalSessionsNumber}
                                </SimpleLabel>
                            </div>
                        )}
                    </div>

                    {this.renderTabs()}
                </div>
                {totalSessionsNumber > 0 && (
                    <div className={'history-paging-buttons-container'}>{sessionsPagingButtons}</div>
                )}
            </div>
        );
    }

    dateIsValid(date: any): boolean {
        // eslint-disable-next-line max-len
        const dateRegex =
            /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;

        return dateRegex.test(date);
    }

    disableSubmit() {
        return (
            !(this.dateIsValid(this.state.startDate) && this.dateIsValid(this.state.endDate) && this.tags.value) ||
            this.state.isLoading
        );
    }

    onSessionPagingButtonClicked = async (pageNumber: number, isPrev = false, isNext = false) => {
        let newCurrentSessionsPage;

        if (isPrev) {
            newCurrentSessionsPage = this.state.currentSessionsPage - 1;
        } else if (isNext) {
            newCurrentSessionsPage = this.state.currentSessionsPage + 1;
        } else {
            newCurrentSessionsPage = pageNumber;
        }

        await this.props.controller.executeSearchByTags(
            this.tags.value,
            this.state.startDate,
            this.state.endDate,
            newCurrentSessionsPage
        );

        this.setState({currentSessionsPage: newCurrentSessionsPage});

        this.setTabContent(tabsNames.All);
        this.setTabContent(tabsNames.Images);
        this.setSessionsResultsPaging(this.state.totalSessionsCount, this.state.currentSessionsPage);
    };

    getNoResultsText() {
        return this.state.tagValue
            ? `${this.props.controller.translate('MAIN.VIEW.NO_SEARCH_RESULTS')} the tags '${this.state.tagValue}'`
            : '';
    }

    getFormattedDate(date?: Date, format?: string) {
        return (
            (date && moment(date).format(format || 'DD/MM/YYYY')) || moment(new Date()).format(format || 'DD/MM/YYYY')
        );
    }
}
