'use strict';

function linkFn(scope, element) {
    element.on('load', (event) => scope.callback({event: event}));
}

export function tsOnloadDirective() {
    return {
        restrict: 'A',
        scope: {
            callback: '&tsOnload'
        },
        link: linkFn
    };
}
