import {action} from 'mobx';
import {ITranslate} from '../../services/LocalizationService';
import moment from 'moment';
import {GenericFormController, IEnabledFields} from '../common-forms/controller';
import {BasicFormModel} from '../common-forms/form-model';

export class VJFormController extends GenericFormController {
    private readonly _dateFormat: string;

    constructor(
        translate: ITranslate,
        accSettings: any,
        formModel: BasicFormModel,
        onSubmitFunction: any,
        fieldsEnabled: IEnabledFields,
        dateFormat: string
    ) {
        super(translate, accSettings, formModel, onSubmitFunction, fieldsEnabled);

        this.onTodayClick = this.onTodayClick.bind(this);
        this.onYesterdayClick = this.onYesterdayClick.bind(this);
        this.onLast7DaysClick = this.onLast7DaysClick.bind(this);
        this.changeStartDate = this.changeStartDate.bind(this);
        this.changeEndDate = this.changeEndDate.bind(this);

        this._dateFormat = dateFormat.split(' ')[0];
    }

    get dateFormat(): string {
        return this._dateFormat;
    }

    get formStartDateValue(): Date | undefined {
        const {startDate} = this.formModel.fieldNames;

        return this.formModel.fields[startDate]?.value ? new Date(this.formModel.fields[startDate].value) : undefined;
    }

    get formEndDateValue(): Date | undefined {
        const {endDate} = this.formModel.fieldNames;

        return this.formModel.fields[endDate]?.value ? new Date(this.formModel.fields[endDate].value) : undefined;
    }

    get maxDate(): Date {
        return moment().endOf('day').toDate();
    }

    get minDate(): Date {
        return moment().subtract(VJ_INTERACTION_SUMMARY_MAX_YEAR_RANGE, 'year').startOf('day').toDate();
    }

    @action
    changeStartDate(newDate: Date) {
        const {startDate} = this.formModel.fieldNames;

        this.formModel.fields[startDate].setValue((newDate ?? '').toString());
    }

    @action
    changeEndDate(newDate: Date) {
        const {endDate} = this.formModel.fieldNames;

        this.formModel.fields[endDate].setValue((newDate ?? '').toString());
    }

    @action
    onTodayClick() {
        const {startDate, endDate} = this.formModel.fieldNames;

        const todatStart = moment().startOf('day');
        const todayEnd = moment().endOf('day');

        this.formModel.fields[startDate].setValue(todatStart.toString());

        this.formModel.fields[endDate].setValue(todayEnd.toString());
    }

    @action
    onYesterdayClick() {
        const {startDate, endDate} = this.formModel.fieldNames;

        const yesterdayStart = moment().subtract(1, 'days').startOf('day');
        const yesterdayEnd = moment().subtract(1, 'days').endOf('day');

        this.formModel.fields[startDate].setValue(yesterdayStart.toString());

        this.formModel.fields[endDate].setValue(yesterdayEnd.toString());
    }

    @action
    onLast7DaysClick() {
        const {startDate, endDate} = this.formModel.fieldNames;

        const lastWeekStart = moment().subtract(6, 'days').startOf('day');
        const todayEnd = moment().endOf('day');

        this.formModel.fields[startDate].setValue(lastWeekStart.toString());

        this.formModel.fields[endDate].setValue(todayEnd.toString());
    }
}
