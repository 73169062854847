/* eslint-disable max-classes-per-file,@typescript-eslint/no-use-before-define,no-use-before-define */
import React from 'react';
import {computed} from 'mobx';
import {observer} from 'mobx-react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {SimpleTabs, ITab} from '@techsee/techsee-ui-common/lib/tabs/simple';
import {GuidenceProgressItem, GuidanceProgress} from '../components/guidance-progress/GuidanceProgress';
import {EVENT_TYPES} from '../../../../../services/ts-sms/ts-sms.settings';
import {InviteStepsLayout, SpeakerWifiStepToolbar} from './_Shared';
import {InviteStepParams, InviteGenericStep, InviteStepProps} from './_InviteGenericStep';
import {IInviteFlowManager, InviteStepsEnum, InviteDeviceType} from '../../_contracts/InviteContracts';
import {RobotHelper} from '../components/robot-helper/index';
import {IMainLayoutController} from '../../../../layouts/main-layout/layout.controller';
import {IDomUtilsService} from '@techsee/techsee-client-infra/lib/services/DomUtilsService';

export interface SmsStatusesType {
    [status: string]: {
        label: string;
        icon: string;
        iconColor: string;
        pagePlacement: string;
    };
}

export const SMS_STATUSES: SmsStatusesType = {
    SENDING_WHATSAPP: {
        label: 'REACT.WHATSAPP.SENDING_WHATSAPP',
        icon: 'arrows-circle',
        iconColor: 'blue',
        pagePlacement: 'sms-step-sending-whatsapp'
    },
    SENDING_SMS: {
        label: 'REACT.SMS.SENDING',
        icon: 'arrows-circle',
        iconColor: 'blue',
        pagePlacement: 'sms-step-sending'
    },
    RESENDING_SMS: {
        label: 'REACT.SMS.RESENDING',
        icon: 'arrows-circle',
        iconColor: 'blue',
        pagePlacement: 'sms-step-resending'
    },
    SMS_DELIVERED: {
        label: 'REACT.SMS.DELIVERED',
        icon: 'envelop',
        iconColor: 'dark-gray',
        pagePlacement: 'sms-step-delivered'
    },
    WHATSAPP_DELIVERED: {
        label: 'REACT.WHATSAPP.WHATSAPP_DELIVERED',
        icon: 'envelop',
        iconColor: 'dark-gray',
        pagePlacement: 'sms-step-whatsapp-delivered'
    },
    CLIENT_RECEIVED: {
        label: 'REACT.SMS.CLIENT_RECEIVED',
        icon: 'check',
        iconColor: 'green',
        pagePlacement: 'sms-step-client-received'
    },
    WAITING_FOR_CLIENT: {
        label: 'REACT.SMS.WAITING_FOR_CLIENT',
        icon: 'clock',
        iconColor: 'green',
        pagePlacement: 'sms-step-client-waiting'
    },
    SWITCHING_TO_SMS: {
        label: 'REACT.WHATSAPP.SWITCHING_TO_SMS',
        icon: 'check',
        iconColor: 'green',
        pagePlacement: 'sms-step-switching-to-sms'
    },
    WHATSAPP_ERROR: {
        label: 'REACT.WHATSAPP.ERROR',
        icon: 'slim-x',
        iconColor: 'error',
        pagePlacement: 'sms-step-whatsapp-sending-failed'
    },

    ERROR: {label: 'REACT.SMS.ERROR', icon: 'slim-x', iconColor: 'error', pagePlacement: 'sms-step-sending-failed'},
    UNKNOWN: {label: '', icon: '', iconColor: '', pagePlacement: 'sms-step-sending-unknown'}
};

export class SmsStepController extends InviteGenericStep {
    constructor(
        flowManager: IInviteFlowManager,
        mainLayoutController: IMainLayoutController,
        domUtilsService: IDomUtilsService
    ) {
        const params: InviteStepParams = {
            stepType: InviteStepsEnum.SendSmsFlow,
            content: () => <StepSmsTabs ctrl={this} />,
            toolbar: SpeakerWifiStepToolbar
        };

        super(flowManager, mainLayoutController, domUtilsService, params);
    }

    @computed
    get isWaitingForSmsClick(): boolean {
        if (this.sendSmsProgress.length === 0) {
            return false;
        }

        return this.sendSmsProgress.filter((ss) => ss.name === EVENT_TYPES.WAITING_FOR_CLIENT).length > 0;
    }

    @computed
    get sendSmsProgress() {
        return this.flowManager.inviteState.smsFlowProgress;
    }

    @computed
    get latestStatus() {
        return this.sendSmsProgress[this.sendSmsProgress.length - 1]
            ? this.sendSmsProgress[this.sendSmsProgress.length - 1].name
            : '';
    }

    didMount() {
        super.didMount();

        if (!this.flowManager.isSmsFlowInProgress) {
            this.flowManager.sendInviteSms();
        }
    }
}

@observer
class StepSmsTabs extends TechseeBaseComponent<InviteStepProps & {ctrl: SmsStepController}> {
    componentDidMount() {
        this.props.ctrl.setPagePlacement('sms-sending-step');
    }

    componentWillUpdate() {
        const activeStatus = this.props.ctrl.sendSmsProgress.find((status) => status.isActive);

        if (activeStatus) {
            this.props.ctrl.setPagePlacement(SMS_STATUSES[activeStatus.name].pagePlacement);
        }
    }

    getGuidance(status: string): {
        guidanceType: string;
        guidanceText: string;
        makeSure: string;
        sendInvite: string;
        robotText: string;
    } {
        switch (status) {
            case EVENT_TYPES.SENDING_SMS:
            case EVENT_TYPES.RESENDING_SMS:
            case EVENT_TYPES.SMS_DELIVERED:
            case EVENT_TYPES.CLIENT_RECEIVED:
            case EVENT_TYPES.SWITCHING_TO_SMS:
            case EVENT_TYPES.WHATSAPP_ERROR:
            case EVENT_TYPES.ERROR:
                return {
                    guidanceType: 'sms',
                    guidanceText: 'REACT.SMS.NOTIFICATION_',
                    makeSure: 'REACT.SMS.MAKE_SURE_SMS',
                    sendInvite: 'REACT.SMS.SEND_INVITE_SMS',
                    robotText: 'REACT.ROBOT_HELPER.SMS'
                };
            case EVENT_TYPES.SENDING_WHATSAPP:
                return {
                    guidanceType: 'whatsapp',
                    guidanceText: 'REACT.WHATSAPP.NOTIFICATION_',
                    makeSure: 'REACT.WHATSAPP.MAKE_SURE_WHATSAPP',
                    sendInvite: 'REACT.WHATSAPP.SEND_INVITE_WHATSAPP',
                    robotText: 'REACT.ROBOT_HELPER.WHATSAPP'
                };
            case EVENT_TYPES.WAITING_FOR_CLIENT:
                return {
                    guidanceType: 'sms-waiting',
                    guidanceText: 'REACT.SMS.WAITING_FOR_CLIENT_',
                    makeSure: 'REACT.SMS.MAKE_SURE_SMS',
                    sendInvite: 'REACT.SMS.SEND_INVITE_SMS',
                    robotText: 'REACT.ROBOT_HELPER.SMS'
                };
            case EVENT_TYPES.WHATSAPP_DELIVERED:
                return {
                    guidanceType: 'whatsapp-waiting',
                    guidanceText: 'REACT.WHATSAPP.WAITING_FOR_CLIENT_',
                    makeSure: 'REACT.WHATSAPP.MAKE_SURE_WHATSAPP',
                    sendInvite: 'REACT.WHATSAPP.SEND_INVITE_WHATSAPP',
                    robotText: 'REACT.ROBOT_HELPER.WHATSAPP'
                };
            default:
                return {
                    guidanceType: 'sms',
                    guidanceText: 'REACT.SMS.NOTIFICATION_',
                    makeSure: 'REACT.SMS.MAKE_SURE_SMS',
                    sendInvite: 'REACT.SMS.SEND_INVITE_SMS',
                    robotText: 'REACT.ROBOT_HELPER.SMS'
                };
        }
    }

    renderInternal() {
        const {translate, sendSmsProgress, latestStatus} = this.props.ctrl;
        const {SENDING_SMS, RESENDING_SMS, SENDING_WHATSAPP} = EVENT_TYPES;

        const progressList = sendSmsProgress.map((status) => {
            const isAnimatedStatus =
                status.name === SENDING_SMS || status.name === RESENDING_SMS || status.name === SENDING_WHATSAPP;
            const item: GuidenceProgressItem = {
                isActive: status.isActive,
                isIconAnimated: status.isActive && isAnimatedStatus,
                iconColor: status.isActive ? SMS_STATUSES[status.name].iconColor : 'dark-gray',
                icon: SMS_STATUSES[status.name].icon,
                label: translate(SMS_STATUSES[status.name].label)
            };

            return item;
        });
        const {guidanceType, guidanceText, makeSure, sendInvite, robotText} = this.getGuidance(latestStatus);

        const Android = () => (
            <GuidanceProgress
                progressList={progressList}
                deviceType={InviteDeviceType.android}
                guidanceType={guidanceType}
                guidanceText={translate(guidanceText + 'ANDROID')}
            />
        );

        const Ios = () => (
            <GuidanceProgress
                progressList={progressList}
                deviceType={InviteDeviceType.ios}
                guidanceType={guidanceType}
                guidanceText={translate(guidanceText + 'IOS')}
            />
        );

        const tabsList: ITab[] = [];

        tabsList.push({title: 'Android', content: Android});
        tabsList.push({title: 'iOS', content: Ios});

        return (
            <React.Fragment>
                <RobotHelper>
                    <React.Fragment>
                        <div dangerouslySetInnerHTML={{__html: translate(robotText)}} />
                    </React.Fragment>
                </RobotHelper>
                <InviteStepsLayout className={'sms-step'} h1Text={translate(sendInvite)} h2Text={translate(makeSure)}>
                    <SimpleTabs tabsList={tabsList} uid={'sms-step-tabs'} />
                </InviteStepsLayout>
            </React.Fragment>
        );
    }
}
