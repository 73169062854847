import {action, computed, observable} from 'mobx';
import {ITranslate} from '../../../services/LocalizationService';
import {InviteMethodInfo, SessionInfo, SessionStateBase} from '../../../models/LiveSessionState';
import {onCopyClick} from '@techsee/techsee-common/lib/utils/window-utils';
import {DeepReadonly} from '@techsee/techsee-common/lib/core/data-structures';
// @ts-ignore
import {isModeSupportNativeVideoSharing} from '@techsee/techsee-common/lib/utils';
import {ConnectionStatesEnum, ConnectionTypesEnum} from '../../../services/Session/SessionContracts';
import {liveSdkAppIDs} from '@techsee/techsee-common/lib/constants/account.constants';

export interface UserConnectionInfoBox {
    icon: string;
    topLabel: string;
    topLine: string;
    bottomLine: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface IMainTopBarController<T extends SessionStateBase> {
    readonly translate: ITranslate;
    readonly logoUrl: string;
    readonly clientBrowser: string;
    readonly clientDevice: string;
    readonly clientOSName: string;
    readonly clientOSVersion: string;
    readonly applicationId: string;
    readonly usingApplication: boolean;
    readonly isDesktopSharing: boolean;
    readonly inviteMethodInfo: Readonly<InviteMethodInfo>;
    readonly multiLanguage: boolean;
    readonly userConnectionInfo: Readonly<UserConnectionInfoBox>;
    readonly sessionInfo: Readonly<SessionInfo>;
}

//TODO - Alex: Need to expose IAccountSettings outside of invite also, so it can be reused in dashboard also
export interface TempAccountSettings {
    generalSettings: {
        logoUrl: string;
        nativeAppId: string;
        localImageSaving: boolean;
        emailImageSharing: boolean;
        enableMobileGeolocation: boolean;
    };
    inviteMethodSettings: {
        multiLanguage: boolean;
    };
}

export abstract class MainTopBarControllerBase<T extends SessionStateBase> implements IMainTopBarController<T> {
    private readonly _stateObject: DeepReadonly<T>;

    private readonly _translate: ITranslate;

    private readonly _accSettings: TempAccountSettings;

    @observable private _inviteMethod: InviteMethodInfo;

    protected constructor(
        accountSettings: DeepReadonly<TempAccountSettings>,
        stateObject: DeepReadonly<T>,
        translate: ITranslate
    ) {
        this._accSettings = accountSettings;
        this._stateObject = stateObject;
        this._inviteMethod = stateObject.inviteMethodInfo;
        this._translate = translate;
    }

    get translate(): ITranslate {
        return this._translate;
    }

    protected get accountSettings(): DeepReadonly<TempAccountSettings> {
        return this._accSettings;
    }

    protected get stateObject(): DeepReadonly<T> {
        return this._stateObject;
    }

    @computed
    get inviteMethodInfo() {
        return this._inviteMethod;
    }

    @computed
    get multiLanguage() {
        return this._accSettings.inviteMethodSettings.multiLanguage;
    }

    @action
    setInviteMethodInfo(inviteMethodInfo: InviteMethodInfo) {
        this._inviteMethod = inviteMethodInfo;
    }

    get sessionInfo() {
        return this._stateObject.sessionInfo as Readonly<SessionInfo>;
    }

    @computed
    get userConnectionInfo(): UserConnectionInfoBox {
        const result: UserConnectionInfoBox = {icon: '', topLabel: '', topLine: '', bottomLine: ''};
        const {connectionState} = this.stateObject;

        if (!this.stateObject.sessionInfo.isSessionActive || !this.stateObject.sessionInfo.clientJoinedSession) {
            result.icon = 'network-offline';
            result.topLine = 'REACT.INVITE.VIEW.NETWORK_WAITING';
            result.bottomLine = 'REACT.INVITE.VIEW.NETWORK_CONNECTION';

            return result;
        }

        if (this.connectionType === ConnectionTypesEnum.NETWORK) {
            result.icon = connectionState === ConnectionStatesEnum.OFFLINE ? 'offline' : 'network-connected';
            result.topLabel = 'REACT.INVITE.VIEW.NETWORK_STATUS';
            result.bottomLine = 'REACT.INVITE.VIEW.NETWORK_' + connectionState;
        } else {
            const wifiOrMobile =
                this.connectionType !== ConnectionTypesEnum.WIFI
                    ? ConnectionTypesEnum.MOBILE_DATA
                    : ConnectionTypesEnum.WIFI;

            result.icon =
                connectionState === ConnectionStatesEnum.OFFLINE ? 'offline' : wifiOrMobile + '-' + connectionState;
            result.topLine = 'REACT.INVITE.VIEW.' + wifiOrMobile;
            result.bottomLine = 'REACT.INVITE.VIEW.' + wifiOrMobile + '_' + connectionState;
        }

        result.icon = result.icon.toLowerCase();

        return result;
    }

    @computed
    get logoUrl(): string {
        return this._accSettings.generalSettings.logoUrl || 'new-logo.png';
    }

    @computed
    get usingApplication() {
        const isSharingMode = isModeSupportNativeVideoSharing(this.sessionInfo.meetingMode);

        return !this.isDesktopSharing && (this.stateObject.usingApplication || isSharingMode);
    }

    @computed
    get clientBrowser() {
        const browser = this.stateObject.clientMobileBrowser;

        return browser ? browser.split('.')[0] : '';
    }

    @computed
    get clientDevice() {
        return this.stateObject.clientMobileType;
    }

    @computed
    get clientOSName() {
        return this.stateObject.clientMobileOsName;
    }

    @computed
    get clientOSVersion() {
        return this.stateObject.clientMobileOsVersion;
    }

    @computed
    get isDesktopSharing() {
        return this.stateObject.sessionInfo.isDesktopSharing;
    }

    protected copyMobileUrlToClipboard(): void {
        onCopyClick(this.stateObject.sessionInfo.sessionLink);
    }

    @computed
    private get connectionType(): ConnectionTypesEnum {
        switch (this.stateObject.clientNetworkInfo.connectionType.toUpperCase()) {
            case ConnectionTypesEnum.CELLULAR:
            case ConnectionTypesEnum.MOBILE_DATA:
                return ConnectionTypesEnum.MOBILE_DATA;
            case ConnectionTypesEnum.WIFI:
                return ConnectionTypesEnum.WIFI;
            default:
                return ConnectionTypesEnum.NETWORK;
        }
    }

    get applicationId() {
        return this.getAppId(liveSdkAppIDs, this._accSettings.generalSettings.nativeAppId);
    }

    getAppId(liveSdkAppIDs: any, nativeAppId: string): string {
        const appId = liveSdkAppIDs.filter((item: any) => item.value === nativeAppId);

        return appId.length ? appId[0].name : '';
    }
}
