'use strict';
import isEmpty from 'lodash/isEmpty';

import tsSolutionWidgetView from './ts-solution-widget.view.html';
import './ts-solution-widget.style.scss';
import {senderTypes} from '@techsee/techsee-common/lib/constants/resource.constants';
import {compareUnderThreshold} from '@techsee/techsee-common/lib/utils';

class TsSolutionController {
    constructor($scope, $window, db, tsChatApi, tsSharingService, tsDeviceClassificationService, tsUrlUtils) {
        'ngInject';

        $scope.$watch(() => this.service.selectedIssueId, this._handleSolutionIdChange.bind(this));
        this._db = db;
        this._$window = $window;
        this._chatApi = tsChatApi.service;

        this.$scope = $scope;
        this.currentSolution = null;
        this.currentSolutionStep = 0;
        this.tsSharingService = tsSharingService;
        this.service = tsDeviceClassificationService;
        this.urlUtils = tsUrlUtils;
    }

    solutionAsCurrent(index) {
        this.currentSolutionStep = index;
    }

    isStepActive(step) {
        return step.stepIndex === this.currentSolution.solutionSteps[this.currentSolutionStep].stepIndex;
    }

    previousSolution() {
        this.currentSolutionStep -= 1;
        if (this.currentSolutionStep < 0) {
            this.currentSolutionStep = 0;
        }
    }

    nextSolution() {
        this.currentSolutionStep += 1;
        if (this.currentSolutionStep >= this.currentSolution.solutionSteps.length) {
            this.currentSolutionStep = this.currentSolution.solutionSteps.length - 1;
        }
    }

    sendSolutionByStep(solutionStep, stepIndex, stepCount, setId) {
        const step = solutionStep || this.currentSolution.solutionSteps[this.currentSolutionStep];

        const meta = {
            tags: [],
            sharedBy: senderTypes.DASHBOARD,
            mediaFileName: this.urlUtils.extractFileName(step.image.url)
        };

        if (stepCount) {
            meta.setId = setId;
            meta.stepIndex = stepIndex;
            meta.stepCount = stepCount;
        }

        this._chatApi.sendImage(step.image.url, meta);
    }

    sendAllSolution() {
        const stepCount = this.currentSolution.solutionSteps.length;
        const setId = this.tsSharingService.uuidv4();

        this.currentSolution.solutionSteps.forEach((step, stepIndex) => {
            this.sendSolutionByStep(step, (step && step.stepIndex) || stepIndex, stepCount, setId);
        });
    }

    getContainerStyle() {
        const topBarHeight = 100; //top bar height + some spacing
        const bottomBarHeight = 60; //bottom bar height + some spacing
        const sectionTitle = 85; //title + description height + some spacing
        const sectionButtons = 60; //navigation section + some spacing
        const solutionActions = 47; //solution action button row + some spacing

        const reservedSpace = topBarHeight + bottomBarHeight + sectionTitle + sectionButtons + solutionActions;
        const maxHeight = this._$window.innerHeight - reservedSpace;

        if (this.previousMaxHeight && !compareUnderThreshold(this.previousMaxHeight, maxHeight)) {
            return this.previousMaxHeight;
        }

        this.previousMaxHeight = maxHeight;

        return {
            'max-height': maxHeight
        };
    }

    _handleSolutionIdChange() {
        if (this.service.selectedIssueId) {
            this._db.Smart.solutions(this.service.selectedIssueId)
                .then((solutions) => {
                    if (isEmpty(solutions.data)) {
                        this._cantLoadSolution(`No solution found for issue: ${this.service.selectedIssueId}`);
                    } else {
                        this._displaySolution(solutions.data[0]);
                    }
                })
                .catch((error) => {
                    this._cantLoadSolution(error);
                });
        }
    }

    _displaySolution(solution) {
        this.onDisplaySolution && this.onDisplaySolution({solution: solution});

        this.currentSolution = {
            issueName: solution.title,
            description: solution.description,
            solutionSteps: solution.solutionSteps
        };
        this.currentSolutionStep = 0;
    }

    _cantLoadSolution() {
        // TODO - Alex: handle error for loading solution.
    }
}

export function tsSolutionWidget() {
    return {
        template: tsSolutionWidgetView,
        replace: true,
        scope: {},
        controller: TsSolutionController,
        controllerAs: 'vm',
        bindToController: {
            onDisplaySolution: '&'
        }
    };
}
