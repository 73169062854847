import React from 'react';
import {ITranslate} from '../../../services/LocalizationService';

import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';

import './styles.scss';

export interface IBottomBar {
    translate: ITranslate;
}

export class BottomBar extends TechseeBaseComponent<IBottomBar> {
    renderInternal() {
        const {translate} = this.props;

        return (
            <div className='app-bottom-bar'>
                <div className='row'>
                    <div className='col-sm-4'></div>
                    <div className='col-sm-4 powered-by-block'>
                        <span className='powered-by-text'>{translate('REACT.BOTTOMBAR.POWEREDBY')}</span>
                        <i className='logo logo-footer'></i>
                    </div>
                    <div className='col-sm-4'>
                        <a
                            href='https://techsee.me/terms-and-conditions'
                            target='_blank'
                            rel='noopener noreferrer'
                            className='terms-link pull-right'>
                            {translate('REACT.BOTTOMBAR.TERMS_AND_CONDITIONS')}
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default BottomBar;
