'use strict';
import tsVideoToolsView from './ts-video-tools.view.html';
import './ts-video-tools.style.scss';

export function tsVideoTools() {
    return {
        template: tsVideoToolsView,
        scope: true
    };
}
