import tsAnalysisResultView from './ts-analysis-summary.view.html';
import './ts-analysis-summary.style.scss';

class TsAnalysisResultController {
    constructor(tsDeviceClassificationService, $rootScope) {
        'ngInject';

        this.requireImage = $rootScope.requireImage;
        this.service = tsDeviceClassificationService;
    }
}

export function tsAnalysisSummaryDirective() {
    return {
        template: tsAnalysisResultView,
        replace: true,
        scope: {},
        controller: TsAnalysisResultController,
        controllerAs: 'vm'
    };
}
