'use strict';
import tsLiveVideoView from './ts-live-video.view.html';
import tsLiveVideoStretchedView from './ts-live-video.stretched.view.html';
import './ts-live-video.style.scss';

export function tsLiveVideo() {
    return {
        template: (el, attrs) => (attrs.isStretched === 'true' ? tsLiveVideoStretchedView : tsLiveVideoView),
        scope: true,
        controller: function () {
            this.stopPropagation = (e) => {
                e.stopPropagation();
            };
        },
        controllerAs: 'tsLiveVideo'
    };
}
