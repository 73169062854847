'use strict';

export class TimeoutDialogController {
    constructor($modalInstance, $interval, tsChatApi, dir) {
        'ngInject';

        this.$modalInstance = $modalInstance;
        this.$interval = $interval;
        this.timer = 30;
        this.chatApi = tsChatApi.service;
        this.dir = dir;
        this.startCounting(this.chatApi.lastActivityTime());
    }

    /*
     * Count down timer. In every iteration, it checks if there was
     * any activity in the mean time.
     */
    startCounting(lastActivity) {
        this.timerId = this.$interval(() => {
            if (this.chatApi.lastActivityTime() > lastActivity) {
                this.$interval.cancel(this.timerId);
                this.keepAlive('yes');
            } else if (this.timer <= 0) {
                this.$interval.cancel(this.timerId);
                this.keepAlive('timeout');
            } else {
                this.timer--;
            }
        }, 1000);
    }

    keepAlive(keep) {
        this.$modalInstance.close(keep);
    }
}
