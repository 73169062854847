import {getRootStore} from '../../app.bootstrap';
import {SocketVersionConfig} from './SessionContracts';

export function onSocketVersionHandler(socketConfig: SocketVersionConfig) {
    const {browserUtilsService, globals} = getRootStore();

    if (socketConfig && !browserUtilsService.getFromSessionStorage('socketConfig')) {
        browserUtilsService.saveToSessionStorage('socketConfig', socketConfig);
        globals.window.location.reload();
    }
}
