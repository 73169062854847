import React from 'react';
import {observer} from 'mobx-react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {FormInput} from '@techsee/techsee-ui-common/lib/forms/input';
import {PrimaryButton} from '@techsee/techsee-ui-common/lib/forms/buttons/primary';
import {PhoneNumberField} from '@techsee/techsee-ui-common/lib/forms/phone-number-field';
import {ButtonTypeAttributes} from '@techsee/techsee-ui-common/lib/forms/buttons/generic';
import {Spinner} from '@techsee/techsee-ui-common/lib/spinner';
import {SimpleLabel} from '@techsee/techsee-ui-common/lib/forms/labels/simple';
import {ITranslate} from '../../services/LocalizationService';
import {IGenericFormController} from './controller';
import {Icon} from '@techsee/techsee-ui-common/lib/icons/simple-icon';
import reduce from 'lodash/reduce';

import './styles.scss';

export interface IGenericForm {
    ctrl: IGenericFormController;
    translate: ITranslate;
    buttonText: string;
    labelText: string;
    formErrorText: string;
    onSubmit?: () => void;
}

@observer
export class GenericForm extends TechseeBaseComponent<IGenericForm> {
    state = {numOfFieldEnabled: 0};

    componentDidMount() {
        this.setNumOfFieldEnabled();
    }

    setNumOfFieldEnabled = () => {
        const numOfFieldEnabled = reduce(
            this.props.ctrl.fieldsEnabled,
            (numOfFieldEnabled, isFieldEnabled) => {
                if (isFieldEnabled) {
                    // eslint-disable-next-line no-param-reassign
                    numOfFieldEnabled++;
                }

                return numOfFieldEnabled;
            },
            0
        );

        this.setState({numOfFieldEnabled});
    };

    renderInternal() {
        const {ctrl, translate, buttonText, labelText, formErrorText} = this.props;
        const {numOfFieldEnabled} = this.state;
        const {formModel, firstSubmit, formCustomerId, formPhoneNumberValue} = ctrl;

        const {formError, onTypeFieldChange} = ctrl;

        const customerRefField = (
            <FormInput
                uid={'customerRef'}
                className='input-field'
                model={formModel.fields[formModel.fieldNames.customerRef]}
            />
        );

        const emailField = <FormInput uid={'email'} model={formModel.fields[formModel.fieldNames.email]} />;

        const phoneNumberField = (
            <PhoneNumberField
                uid={'phoneNumber'}
                className='input-field'
                model={formModel.fields[formModel.fieldNames.phoneNumber]}
                phoneNumberModel={formModel.fields[formModel.fieldNames.phoneNumberModel]}
                countryCodeModel={formModel.fields[formModel.fieldNames.countryCode] || null}
                countryCodesList={ctrl.countriesInfo}
                selectedCountries={ctrl.phoneCountryCodes}
                defaultCountry={ctrl.defaultCountryCode}
                countryList={ctrl.countryList}
                loadMoreItemsText={translate('REACT.COMMON.COUNTRIES.LOAD_MORE_COUNTRY_CODES')}
                noResultsText={translate('REACT.COMMON.COUNTRIES.NO_RESULTS')}
            />
        );

        const radioField = (fieldName: string, name: string) => (
            <label className='radio-container' htmlFor={name}>
                <input
                    id={name}
                    name={name}
                    type='radio'
                    value={fieldName}
                    onChange={onTypeFieldChange}
                    checked={ctrl.selectedField === fieldName}
                />

                <span className='checkmark'></span>
            </label>
        );

        return (
            <div className={'generic-form-container'}>
                <SimpleLabel>{translate(labelText)}</SimpleLabel>
                <form className='use-common-styles generic-form' onSubmit={(event) => ctrl.onSubmit(event)}>
                    <div className='radio-group'>
                        {ctrl.isCustomerRefEnabled && ctrl.fieldsEnabled.enableCustomerRefField && customerRefField && (
                            <div className='radio-item'>
                                {numOfFieldEnabled > 1 && radioField(formModel.fieldNames.customerRef, 'customer-ref')}
                                {customerRefField}
                            </div>
                        )}
                        {ctrl.fieldsEnabled.enableEmailField && emailField && (
                            <div className='radio-item'>
                                {numOfFieldEnabled > 1 && radioField(formModel.fieldNames.email, 'email')}
                                {emailField}
                            </div>
                        )}

                        {ctrl.fieldsEnabled.enablePhoneNumberField && phoneNumberField && (
                            <div className='radio-item'>
                                {numOfFieldEnabled > 1 && radioField(formModel.fieldNames.phoneNumber, 'phone-number')}
                                {phoneNumberField}
                            </div>
                        )}
                    </div>

                    {formError && <div className='error'>{translate(formErrorText)}</div>}
                    {firstSubmit && !formError && !formCustomerId && !formPhoneNumberValue && (
                        <div className='error'>{translate('INVITE.VIEW.HISTORY_SEARCH.EMPTY_FORM')}</div>
                    )}

                    <PrimaryButton
                        className={ctrl.isLoading ? 'loading-button' : 'search-button'}
                        type={ButtonTypeAttributes.Submit}
                        disabled={ctrl.isLoading}>
                        {ctrl.isLoading ? (
                            <Spinner />
                        ) : (
                            <div>
                                {translate(buttonText)}
                                <Icon iconName={'arrow-next'} />
                            </div>
                        )}
                    </PrimaryButton>
                </form>
            </div>
        );
    }
}
