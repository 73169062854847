import {action, computed, observable} from 'mobx';
import {IBrowserUtilsService} from '@techsee/techsee-client-infra/lib/services/BrowserUtilsService';

import {LoginForm} from './model';
import {ITranslate} from '../../../../services/LocalizationService';
import {IApplicationService, ILoginError} from '../../../../services/ApplicationService';
import {IRedirectionService} from '../../../../services/RedirectionService';
import {IFieldFactory} from '../../../../app.contracts';
import {ILoginConfirmationController} from '../login-confirmation/controller';
import {IDbUser} from '../../../../services/AngularServices/AngularServices';

export interface IUserAccount {
    _id: string;
    companyName: string;
}

export interface ILoginControllerConfig {
    redirectToInvite?: boolean;
    showMissedAccountInfo?: boolean;
    submitButtonText?: string;
    submitCallback?: () => void;
    errorCallback?: (err: any) => void;
}

export interface ILoginController {
    readonly form: LoginForm;
    readonly errorMessage: string;
    readonly config: ILoginControllerConfig;
    readonly accounts: IUserAccount[];
    readonly hasMultipleAccounts: boolean;

    onSubmitLogin(confirmLogin?: boolean): PromiseLike<void>;
    setErrorMessage(msg: string): void;
    setConfig(config: ILoginControllerConfig): void;
}

export class LoginController implements ILoginController {
    readonly form: LoginForm;

    private readonly _translate: ITranslate;

    private readonly _appService: IApplicationService;

    private readonly _redirectionService: IRedirectionService;

    private readonly _loginConfirmationController: ILoginConfirmationController;

    private readonly _browserUtilsService: IBrowserUtilsService;

    @observable
    private _errorMessage: string = '';

    @observable
    private _config: ILoginControllerConfig;

    @observable
    private _accounts: IUserAccount[] = [];

    constructor(
        translate: ITranslate,
        appService: IApplicationService,
        redirectionService: IRedirectionService,
        createFieldModel: IFieldFactory,
        loginConfirmationController: ILoginConfirmationController,
        browserUtilsService: IBrowserUtilsService,
        private _dbUserService: IDbUser
    ) {
        this._translate = translate;
        this._appService = appService;
        this._redirectionService = redirectionService;
        this._loginConfirmationController = loginConfirmationController;
        this._browserUtilsService = browserUtilsService;

        this.form = new LoginForm(translate, createFieldModel);

        this.onSubmitLogin = this.onSubmitLogin.bind(this);

        this._loginConfirmationController.onConfirmLogin = () => this.onSubmitLogin(true);

        this._config = {
            redirectToInvite: true,
            showMissedAccountInfo: true,
            submitButtonText: this._translate('REACT.LOGIN.VIEW.START_BUTTON')
        };
    }

    @computed
    get errorMessage() {
        return this._errorMessage;
    }

    @computed
    get config() {
        return this._config;
    }

    @computed
    get accounts() {
        return this._accounts;
    }

    @computed
    get hasMultipleAccounts() {
        return Boolean(this._accounts.length);
    }

    @action
    private _checkMultiAccounts(): Promise<IUserAccount[]> {
        return this._dbUserService.find('current').then((user) => (user && user.accounts) || []);
    }

    @action
    onSubmitLogin(confirmLogin?: boolean): PromiseLike<void> {
        if (this.hasMultipleAccounts) {
            return this._loginSubUser();
        }

        return this._loginUser(confirmLogin);
    }

    @action
    private _loginUser(confirmLogin?: boolean): PromiseLike<void> {
        return new Promise((resolve) => {
            this.form.validateForm().then(() => {
                this.setErrorMessage();

                if (!this.form.isValid) {
                    this.form.displayFieldsErrors();
                    resolve();

                    return;
                }

                const username = this.form.fields.username.value as string;
                const password = this.form.fields.password.value as string;

                this._appService
                    .login(username, password, confirmLogin)
                    .then(() => this._checkMultiAccounts())
                    .then((accounts: IUserAccount[]) => {
                        this._loginConfirmationController.hide();
                        this._browserUtilsService.saveToLocalStorage('checkForcePasswordChange', true);
                        resolve();

                        if (accounts.length) {
                            this.setAccounts(accounts);

                            return;
                        }

                        this._onLoginSuccess();
                    })
                    .catch((err: ILoginError) => {
                        this._onLoginError(err);
                        resolve();
                    });
            });
        });
    }

    @action
    private _loginSubUser(): PromiseLike<void> {
        if (!this.hasMultipleAccounts) {
            return Promise.resolve();
        }

        const accountName = this.form.fields.account.value.toString();
        const account = this.accounts.find((account) => account.companyName === accountName);

        if (!account) {
            this.setErrorMessage(this._translate('REACT.LOGIN.VIEW.ACCOUNT_REQUIRED'));

            return Promise.resolve();
        }

        const accountId = account._id;

        return this._appService
            .loginSubUser(accountId)
            .then(() => {
                this._onLoginSuccess();
            })
            .catch((err: ILoginError) => {
                this._onLoginError(err);
            });
    }

    @action
    private _onLoginSuccess(): void {
        if (this._config.submitCallback) {
            this._config.submitCallback();
        }

        if (this._config.redirectToInvite) {
            this._redirectionService.goToInvite();
        }
    }

    @action
    private _onLoginError(err: ILoginError): void {
        const username = this.form.fields.username.value as string;

        if (this._config.errorCallback) {
            this._config.errorCallback(err.message);
        }

        if (err.message) {
            this.setErrorMessage(this._translate(err.message));
        }

        if (err.payload) {
            if (err.payload.confirmLogin) {
                this._loginConfirmationController.setUserName(username);
                this._loginConfirmationController.show();
            }
        }
    }

    @action
    setErrorMessage(msg: string = '') {
        this._errorMessage = msg;
    }

    @action
    setConfig(config: ILoginControllerConfig) {
        this._config = {
            ...this._config,
            ...config
        };
    }

    @action
    setAccounts(accounts: IUserAccount[]) {
        this._accounts = accounts;

        if (accounts.length) {
            this.form.fields.username.setDisabled(true);
            this.form.fields.password.setDisabled(true);
            this.form.fields.account.setValue(accounts[0].companyName);
        }
    }
}

export default LoginController;
