'use strict';
import get from 'lodash/get';
import forEach from 'lodash/forEach';
import isArray from 'lodash/isArray';
import {getAuthService} from '@techsee/techsee-client-infra/lib';
import EventEmitter from 'events';

export class AuthService extends EventEmitter {
    constructor($localStorage, db, tsUrlConfig) {
        'ngInject';

        super();

        this.$localStorage = $localStorage;
        this.db = db;

        const apiUrl = tsUrlConfig.get('API_URL');

        this._auth = getAuthService(apiUrl);

        this.loginWithOneTimeToken = this.loginWithOneTimeToken.bind(this);
        this.getAuthToken = this.getAuthToken.bind(this);
        this.setAuthToken = this.setAuthToken.bind(this);
        this.clearAuthToken = this.clearAuthToken.bind(this);
        this.logoutWithoutSSO = this.logoutWithoutSSO.bind(this);
    }

    /**
     * Make a login request
     *
     * @param  {string} userName   Username or email
     * @param  {string} password   User password
     * @return {Object}            The request Promise
     */
    login(userName, password, loginConfirmed = false) {
        const loginData = LoginEncoder.encode(userName, password);
        const params = {
            data: {
                loginData,
                loginConfirmed
            },
            timeout: 15000
        };

        return this.db.Token.auth(params).then((res) => {
            if (res.data.confirmLogin === true) {
                throw {confirmLogin: true};
            }

            // Login successful, save the Token
            this.setAuthToken(res.data);

            return res.data;
        });
    }

    loginSubUser(accountId) {
        return this.db.Auth.loginSubUser({
            data: {
                accountId
            }
        }).then((token) => {
            this.setAuthToken(token.data);
        });
    }

    getAuthToken() {
        return get(this.$localStorage, 'auth.token');
    }

    setAuthToken(token) {
        this.$localStorage.auth = token;

        this.emit('newToken', token);
    }

    clearAuthToken() {
        this.$localStorage.auth = null;
    }

    loginWithOneTimeToken(ott) {
        if (!ott || this.getAuthToken()) {
            return Promise.resolve({isRedeemed: false});
        }

        return this._auth.redeemOneTimeToken(ott).then((token) => {
            if (token) {
                // Login successful, save the Token
                this.setAuthToken({token});

                return {isRedeemed: true};
            }

            return {isRedeemed: false};
        });
    }

    /**
     * Sign the use out of the system and cleanup the saved Token
     *
     * @return {Object} The request Promise
     */
    logout(accountId, userId, returnURL) {
        return this.db.Auth.logout({
            data: {
                accountId: accountId,
                userId: userId,
                returnURL: returnURL,
                authId: this.$localStorage.auth && this.$localStorage.auth.authId
            }
        }).then((res) => {
            // Logout successful, clear the saved Token.  We also need
            // to $apply the chagnes in localStorage. This is neccessary
            // since the next step is to navigate away from the page.
            this.clearAuthToken();
            this.$localStorage.$apply();

            return res;
        });
    }

    logoutWithoutSSO(accountId, userId) {
        return this.db.Auth.logout({
            data: {
                accountId: accountId,
                userId: userId,
                skipSsoLogout: true
            }
        }).then((res) => {
            // Logout successful, clear the saved Token.  We also need
            // to $apply the chagnes in localStorage. This is neccessary
            // since the next step is to navigate away from the page.
            this.clearAuthToken();
            this.$localStorage.$apply();

            return res;
        });
    }

    /**
     * Get a ststae config object based on a given matching URL
     *
     * @param  {Array}  states All states objects
     * @param  {string} url    The URL to match with
     * @return {Object}        The found state config object
     */
    getStateByUrl(states, url) {
        let res = null;

        forEach(states, (state) => {
            // Make sure we have a reference to the extra state properties (set in app.module.js)
            if (!state.$$state) {
                return;
            }

            const stateUrl = state.$$state().url;
            // Test if the URL's match
            const match = stateUrl.exec(url);

            // If the URL's match, `match` will be an empty Object, otherwise `null`
            if (match) {
                res = state;

                // Stop the loop
                return;
            }
        });

        return res;
    }

    /**
     * Check if a given state is allowed access by a given role
     *
     * @param  {Object}  state Destination state config object
     * @param  {string}  role  Role to check access for
     * @return {Boolean}       Is that role allowed or not
     */
    isStateAllowed(state, role) {
        const stateRoles = state.data && state.data.scope;

        // If no restrictions are set, allow access
        if (!stateRoles || !isArray(stateRoles)) {
            return true;
        }

        if (stateRoles.indexOf(role) < 0) {
            return false;
        }

        return true;
    }
}
