import {computed, observable} from 'mobx';
import {onCopyClick} from '@techsee/techsee-common/lib/utils/window-utils';

export interface IRoomInfoController {
    onCopyClick(str: string): void;

    isDisplayLink: boolean;
}

export class RoomInfoController implements IRoomInfoController {
    @observable private _showLink: boolean;

    constructor() {
        this._showLink = false;

        this.onCopyClick = this.onCopyClick.bind(this);
    }

    onCopyClick(str: string): void {
        onCopyClick(str);
    }

    @computed
    get isDisplayLink(): boolean {
        return this._showLink;
    }
}
