import React from 'react';
import {observer} from 'mobx-react';
import {action, computed, observable} from 'mobx';
import {FormInput} from '@techsee/techsee-ui-common/lib/forms/input';
import {PrimaryButton} from '@techsee/techsee-ui-common/lib/forms/buttons/primary';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {IFilter, IObservableController, SearchByItem} from './controller';
import {Icon} from '@techsee/techsee-ui-common/lib/icons/simple-icon';
import {FormFancyDropDown} from '@techsee/techsee-ui-common/lib/forms/form-fancy-drop-down';
import {IObservableSessionsController} from '../observable-sessions/controller';
import isEmpty from 'lodash/isEmpty';

interface IObservableFormProps {
    ctrl: IObservableController;
    observableCtrl: IObservableSessionsController;
    onSearchObservableRooms: (filter?: IFilter) => Promise<any>;
}

@observer
export class ObservableForm extends TechseeBaseComponent<IObservableFormProps> {
    @observable private _disableSearchButton: boolean = false;

    @action
    setDisableSearchButton(value: boolean) {
        this._disableSearchButton = value;
    }

    @computed
    get disableSearchButton() {
        return this._disableSearchButton;
    }

    onSearch = () => {
        this.props.ctrl.setSearchByError(false);
        this.setDisableSearchButton(true);

        const filter = this.props.ctrl.getChosenFilter();

        this.props
            .onSearchObservableRooms(filter as IFilter)
            .then((data) => {
                if (isEmpty(data)) {
                    this.props.ctrl.setSearchByError(true, Object.keys(filter)[0]);
                }

                this.props.observableCtrl.setObservableUsers(data);
            })
            .catch(() => this.props.ctrl.setSearchByError(true, 'error'))
            .finally(() => this.setDisableSearchButton(false));
    };

    renderInternal() {
        const {ctrl} = this.props;
        const {form, searchByError, translate, searchByErrorMessage, isSearchByFieldEmpty} = ctrl;

        return (
            <div className='observable-info'>
                <div className={'observable-info__search'}>
                    <div className={'observable-info__title'}>
                        {translate('REACT.INVITE.VIEW.SESSION_OBSERVATION.SEARCH_BY')}
                    </div>
                    <div className='observable-info__field'>
                        <FormFancyDropDown
                            className='search-by-option'
                            uid='search-by-option'
                            options={ctrl.searchByOptions}
                            optionComponent={({data}: {data: SearchByItem}) => <div className='item'>{data.name}</div>}
                            model={form.fields[form.fieldNames.searchByOption]}
                            optionValueKey='name'
                        />
                        <FormInput
                            uid={'observable-id'}
                            className={'observable-info__input'}
                            model={form.fields[form.fieldNames.searchBy]}
                        />
                    </div>
                    {!!(searchByError && searchByErrorMessage) && (
                        <div className='observable-info__error'>{translate(searchByErrorMessage)}</div>
                    )}
                </div>

                <div className='observable-info__buttons'>
                    <button
                        onClick={() => ctrl.resetForm()}
                        className={`clear-button ${isSearchByFieldEmpty ? 'empty' : ''}`}>
                        <Icon iconName={'slim-x'} />
                        <span>{translate('REACT.INVITE.VIEW.SESSION_OBSERVATION.CLEAR')}</span>
                    </button>
                    <PrimaryButton disabled={this.disableSearchButton} onClick={this.onSearch} className={'submit'}>
                        {translate('REACT.INVITE.VIEW.SESSION_OBSERVATION.SEARCH')}
                        <Icon iconName='arrow-next' />
                    </PrimaryButton>
                </div>
            </div>
        );
    }
}

export default ObservableForm;
