'use strict';

import {getRootStore} from '../../_react_/app.bootstrap';

export class ChatApiNgService {
    private _service: any;

    constructor() {
        this._service = getRootStore().chatApi;
    }

    get service() {
        return this._service;
    }
}
