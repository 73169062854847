'use strict';

import get from 'lodash/get';
import './history.style.scss';

export class HistoryDialogController {
    constructor($modalInstance, customerHistory, db, $q, dir) {
        'ngInject';

        this.$modalInstance = $modalInstance;
        this.history = customerHistory;
        this.db = db;
        this.$q = $q;
        this.dir = dir;
    }

    close() {
        this.$modalInstance.dismiss();
    }

    _updateSecureResource(resource) {
        if (get(resource, 'security.enabled')) {
            if (!resource.isVideo) {
                return this.db.HistoryImage.resign(resource._id, {
                    bypassCache: true
                }).then((resp) => resp.data);
            }

            return this.db.HistoryVideo.resign(resource._id, {
                bypassCache: true
            }).then((resp) => resp.data);
        }

        return this.$q.when(resource);
    }

    annotateResource(resource, room) {
        this._updateSecureResource(resource).then((resource) => this.$modalInstance.close({resource, room}));
    }
}
