'use strict';
import tsDashboardLeftBarView from './ts-dashboard-left-bar.view.html';
import './ts-dashboard-left-bar.style.scss';

export function tsDashboardLeftBar() {
    return {
        template: tsDashboardLeftBarView,
        scope: true
    };
}
