'use strict';

import {getRootStore} from '../../../../_react_/app.bootstrap';
import {ServiceEvents} from '../../../../_react_/services/AccountSocket/AccountSocketEvents';
import {sendSentryLog} from '../../../../error-reporting';

export class SessionRequestsController {
    constructor(tsStateHelper, $scope, accountData, tsUrlUtils, $filter) {
        'ngInject';

        this.$scope = $scope;
        this.stateHelper = tsStateHelper;
        // this.dateFormat = accountData.protectedSettings.reportsDateFormat;
        this.dateFormat = 'dd/MM/yyyy HH:mm:ss';
        this.dateFilter = $filter('date');
        this.urlUtils = tsUrlUtils;
        this.accountSocketService = getRootStore().accountSocketService;

        this.invite = this.$scope.invite;
        this.loading = false;
        this.sessions = [];

        this.accountSocketService.on(ServiceEvents.FS_DATA, (data) => this.getSessionsData(data));
        this.accountSocketService.joinAccountChannel().catch((err) => sendSentryLog(err));
    }

    getSessionsData(roomCodes) {
        if (!roomCodes || !roomCodes.length) {
            return;
        }

        this.sessions = roomCodes.map((codeInfo) => {
            const user = codeInfo.usersData.find((user) => user._id.toString() === codeInfo.userId);
            const groups = user.groups.map(
                (groupId) => codeInfo.groupData.find((group) => group._id.toString() === groupId).name
            );

            return {
                ...codeInfo,
                createdAt: this._getDate(codeInfo.createdAt),
                groupNames: groups && groups.join(', ')
            };
        });
    }

    _getDate(dateString) {
        const date = moment(dateString);

        if (date.unix() === 0) {
            return '-';
        }

        return this.dateFilter(dateString, this.dateFormat);
    }

    joinRoom(index) {
        const roomCode = this.sessions[index].roomCode;

        this.loading = true;

        this.urlUtils.setParamValue('roomCode', roomCode);
        this.stateHelper.safeGo('join', {roomCode});
    }

    backToInvite() {
        this.stateHelper.safeGo('invite');
    }
}
