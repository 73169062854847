import React from 'react';
import {observer} from 'mobx-react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {ILoginModalController} from './controller';
import {GenericModal} from '@techsee/techsee-ui-common/lib/modals/generic';
import {LoginFormView} from '../login-form/view';
import {LoginConfirmation} from '../login-confirmation/component';

import './styles.scss';

export interface ILoginModalProps {
    controller: ILoginModalController;
}

@observer
export class LoginModal extends TechseeBaseComponent<ILoginModalProps> {
    renderContent() {
        return (
            <LoginFormView
                translate={this.props.controller.translate}
                ctrl={this.props.controller.loginFormController}
            />
        );
    }

    renderInternal() {
        const {controller} = this.props;

        return (
            <div>
                <LoginConfirmation
                    ctrl={this.props.controller.loginConfirmationController}
                    onConfirmLogin={this.props.controller.onConfirmedLogin}
                />
                <GenericModal
                    className={'login-modal'}
                    header={() => <React.Fragment />}
                    content={() => this.renderContent()}
                    toolbar={() => <React.Fragment />}
                    onHideHandler={controller.hide}
                    isCloseVisible={false}
                    closeOnEscape={false}
                    show={controller.isLoginModalVisible}></GenericModal>
            </div>
        );
    }
}

export default LoginModal;
