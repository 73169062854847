import {MeetingMode} from '@techsee/techsee-common/lib/constants/room.constants';

export const SMS_TIMEOUT = 15;
export const SMS_POLLING_INTERVAL = 3;
export const WHATSAPP_POLLING_INTERVAL = 3;
export const SMS_POLLING_DURING_PROMPT_ATTEMPTS = 5;
export const WHATSAPP_POLLING_DURING_PROMPT_ATTEMPTS = 5;
export const SMS_POLLING_DURING_PROMPT_INTERVAL = 10;

export enum EVENT_TYPES {
    SENDING_WHATSAPP = 'SENDING_WHATSAPP',
    WHATSAPP_ERROR = 'WHATSAPP_ERROR',
    WHATSAPP_DELIVERED = 'WHATSAPP_DELIVERED',
    SWITCHING_TO_SMS = 'SWITCHING_TO_SMS',
    SENDING_SMS = 'SENDING_SMS',
    SMS_DELIVERED = 'SMS_DELIVERED',
    CLIENT_RECEIVED = 'CLIENT_RECEIVED',
    RESENDING_SMS = 'RESENDING_SMS',
    WAITING_FOR_CLIENT = 'WAITING_FOR_CLIENT',
    ERROR = 'ERROR',
    UNKNOWN = 'UNKNOWN'
}

export interface ISmsData {
    phone: string;
    roomId: string;
    link: string | undefined;
    offline: boolean;
    chromeDetection: boolean;
    cameraModeOnly: boolean;
    showSmsPreview: boolean;
    audio?: boolean;
    mobileClientURL?: string;
    countryCode: string;
    startWithAgentType?: MeetingMode;
    smsUseNumberLookup: boolean;
    gateway?: string;
}

export interface IWhatsAppTemplateData {
    phone: string;
    countryCode: string;
    roomId: string;
    link: string | undefined;
}

export interface ICallback {
    (message: string): void;
}

export interface IPromptCallback {
    (): void;
}

export enum SmsErrorLevel {
    ERROR = 'ERROR',
    WARNING = 'WARNING',
    INFO = 'INFO'
}

export class SmsError {
    private _message: string;

    private _level: SmsErrorLevel;

    constructor(message: string, level: SmsErrorLevel) {
        this._message = message;
        this._level = level;
    }

    get Message() {
        return this._message;
    }

    set Message(message: string) {
        this._message = message;
    }

    get Level() {
        return this._level;
    }

    set Level(level: SmsErrorLevel) {
        this._level = level;
    }
}
