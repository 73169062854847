'use strict';

import tsShowPasswordView from './ts-show-password.view.html';

class TsShowPasswordController {
    get shown() {
        return this.tsShowPassword;
    }

    get hidden() {
        return !this.shown;
    }

    switch() {
        this.tsShowPassword = !this.tsShowPassword;
    }
}

export function tsShowPasswordDirective() {
    return {
        restrict: 'A',
        template: tsShowPasswordView,
        controller: TsShowPasswordController,
        controllerAs: 'vm',
        scope: {
            tsShowPassword: '='
        },
        bindToController: true
    };
}
