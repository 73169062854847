'use strict';
import tsShareMultipleResourcesView from './ts-share-multiple-resources.view.html';
import './ts-share-multiple-resources.style.scss';

export function tsShareMultipleResources() {
    return {
        restrict: 'E',
        replace: true,
        scope: true,
        controller: () => ({}),
        controllerAs: 'vm',
        bindToController: {
            resourceType: '='
        },
        template: tsShareMultipleResourcesView
    };
}
