import {inject, IReactComponent} from 'mobx-react';
import * as component from './component';

const mapStoresToProps = (stores: any) => ({
    controller: stores.agentPerformanceController
});

const AgentPerformance = inject(mapStoresToProps)(component.AgentPerformanceWidget) as IReactComponent;

export {AgentPerformance};
export default AgentPerformance;
