'use strict';

import historyView from './history.view.html';
import historySearchByTagView from './searchByTag.view.html';
import {HistoryController} from './history.controller.js';
import './history.style.scss';

function config($stateProvider) {
    $stateProvider.state('invite.old.history', {
        url: '^' + ROUTE_BASE_PATH + 'history?authId&customerId&customerNumber&filter&r&p&ott&isSearchByTags',
        template: historyView + historySearchByTagView,
        controller: 'HistoryController',
        controllerAs: 'history',
        resolve: {
            filter: (tsUrlUtils) => {
                const filter = tsUrlUtils.getParamValue('filter') || '';

                return filter.split(',').reduce((acc, val) => {
                    const [key, value] = val.split(':');

                    if (key && value) {
                        acc[key] = value;
                    }

                    return acc;
                }, {});
            },
            searchByTags: ($stateParams) => $stateParams.isSearchByTags
        }
    });
}

export default angular
    .module('states.invite.history', [])
    .config(config)
    .controller('HistoryController', HistoryController);
