import {IDbRooms} from '../../../../../services/AngularServices/AngularServices';
import {AppState} from '../../../../../app.state';
// @ts-ignore
import {observable, action, computed} from 'mobx';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import some from 'lodash/some';
import moment from 'moment';
import {ITranslate} from '../../../../../services/LocalizationService';
import {IInviteFlowManager} from '../../../_contracts/InviteContracts';
import {GeneralSettings, InviteMethodSettings} from '../../../../../models/AccountSettings';
import {IAccountSocketService} from '../../../../../services/AccountSocket/AccountSocketService';
import {ServiceEvents} from '../../../../../services/AccountSocket/AccountSocketEvents';
import {Nullable} from '@techsee/techsee-ui-common/lib/_shared/reusable-types';

// @ts-ignore
import {reportToMomentDateFormat} from '@techsee/techsee-common/lib/utils';

const INVITE_NOTIFICATION_MESSAGE_TIMEOUT = 10000;

export interface IPendingRoomSession {
    createdOn: string;
    _id: string;
    accountId: string;
    cid: string;
    agentLink: string;
    clientLink: string;
    sessionType: string;
    phoneNumber: string;
    sessionId: string;
    metaData: {[key: string]: string};
}

export interface IPendingRoomSessionsController {
    readonly pendingRoomSessions: IPendingRoomSession[];
    readonly selectedSession: IPendingRoomSession;
    readonly translate: ITranslate;
    readonly isJoinAvailable: boolean;
    readonly isSendSMSAvailable: boolean;
    readonly isSendSMSModalVisible: boolean;
    readonly notificationMessageText: Nullable<string>;
    readonly notificationMessageStatus: Nullable<NotificationStatus>;

    isSessionSelected(session: IPendingRoomSession): boolean;
    selectSession(session: IPendingRoomSession): void;
    onJoinbyLink(callback: (sessionId: string) => void): void;
    findLinkById(cId: string): string;
    openSendSMSModal(): void;
    closeModal(): void;
    sendSMS(): void;
}

export enum NotificationStatus {
    SUCCESS = 'success',
    ERROR = 'error'
}

export type NotificationStatusType = Nullable<NotificationStatus>;

export class PendingRoomSessionsController implements IPendingRoomSessionsController {
    private _selectedGroupsForPendingRoomAlerts: string[];

    private _notificationTimeout: any;

    @observable private _pendingRoomSessions: IPendingRoomSession[] = [];

    @observable private _selectedSession: IPendingRoomSession = {} as any as IPendingRoomSession;

    @observable private _isSendSMSModalVisible: boolean = false;

    @observable private _notificationMessage: {
        text: Nullable<string>;
        status: NotificationStatusType;
    };

    constructor(
        private _flowManager: IInviteFlowManager,
        public translate: ITranslate,
        private _dbRoomService: IDbRooms,
        private _appState: AppState,
        private _accSettings: GeneralSettings,
        private _inviteSettings: InviteMethodSettings,
        private _currentUser: any,
        private _accountSocketService: IAccountSocketService
    ) {
        this.isSessionSelected = this.isSessionSelected.bind(this);
        this.selectSession = this.selectSession.bind(this);
        this.onJoinbyLink = this.onJoinbyLink.bind(this);
        this.findLinkById = this.findLinkById.bind(this);
        this.sendSMS = this.sendSMS.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.openSendSMSModal = this.openSendSMSModal.bind(this);

        this._notificationMessage = {text: null, status: null};
        this._selectedGroupsForPendingRoomAlerts = this._inviteSettings
            ? this._inviteSettings.selectedGroupsForPendingRoomAlerts
            : [];
        const userGroups = this._currentUser.groups || [];

        const isUserPermittedToGetAlerts =
            this._selectedGroupsForPendingRoomAlerts.length &&
            this._inviteSettings.pendingRoomShowAlertsToSelectedGroups
                ? this._selectedGroupsForPendingRoomAlerts.some((group) => userGroups.includes(group))
                : true;

        if (isUserPermittedToGetAlerts) {
            this._accountSocketService.on(ServiceEvents.PENDING_ROOM_DATA, (data: any) => {
                this.updatePendingRoomSessions(data);
            });

            this.updatePendingRoomSessions(this._accountSocketService.wtPendingRooms);
        }
    }

    @computed
    get pendingRoomSessions() {
        let sessions = this._pendingRoomSessions.map((session) => ({
            ...session,
            createdOn: this.dateFormat(session.createdOn)
        }));

        if (this._inviteSettings.pendingRoomShowAlertsToSelectedGroups && this._selectedGroupsForPendingRoomAlerts) {
            if (
                !some(this._inviteSettings.groups, (group: any) =>
                    includes(this._selectedGroupsForPendingRoomAlerts, group._id)
                )
            ) {
                sessions = [];
            }
        }

        return sessions;
    }

    @computed
    get isSendSMSModalVisible() {
        return this._isSendSMSModalVisible;
    }

    @computed
    get isJoinAvailable() {
        return !isEmpty(this._selectedSession);
    }
    @computed
    get isSendSMSAvailable() {
        return !isEmpty(this._selectedSession);
    }

    @computed
    get selectedSession() {
        return this._selectedSession;
    }

    @computed
    get notificationMessageText(): Nullable<string> {
        return this._notificationMessage.text;
    }

    @computed
    get notificationMessageStatus(): NotificationStatusType {
        return this._notificationMessage.status;
    }

    isSessionSelected(session: IPendingRoomSession) {
        return this._selectedSession._id === session._id;
    }

    @action
    openSendSMSModal() {
        this._isSendSMSModalVisible = true;
    }

    @action
    closeModal() {
        this._isSendSMSModalVisible = false;
    }

    @action
    selectSession(session: IPendingRoomSession) {
        this._selectedSession = session;
    }

    @action
    setPendingRoomSessions(sessions: IPendingRoomSession[]) {
        this._pendingRoomSessions = sessions;
    }

    @action
    setNotificationMessage(message: Nullable<string>, status: NotificationStatusType) {
        this._notificationMessage.text = message;
        this._notificationMessage.status = status;

        this._notificationTimeout = setTimeout(() => {
            this._clearNotificationMessage();
        }, INVITE_NOTIFICATION_MESSAGE_TIMEOUT);
    }

    @action
    _clearNotificationMessage() {
        if (this._notificationTimeout) {
            clearTimeout(this._notificationTimeout);
            this._notificationTimeout = null;
        }

        this._notificationMessage.text = null;
        this._notificationMessage.status = null;
    }

    @action
    onJoinbyLink(callback: (sessionId: string) => void) {
        callback(this._selectedSession.agentLink);
    }

    @action
    findLinkById(cId: string) {
        const found = this._pendingRoomSessions.find((element) => element.cid === cId);

        return found?.agentLink || '';
    }

    @action
    sendSMS() {
        this._flowManager
            .sendWarmTransferClientLinkSMS(this._selectedSession.sessionId)
            .then(() => {
                this.setNotificationMessage(
                    this.translate('REACT.INVITE.VIEW.PENDING_ROOM_SMS_SUCCESS', {
                        mobileNumber: this._selectedSession.phoneNumber
                    }),
                    NotificationStatus.SUCCESS
                );
            })
            .catch((error) => {
                if (error && error.status && error.status > 400) {
                    this.setNotificationMessage(
                        this.translate('REACT.INVITE.VIEW.PENDING_ROOM_SMS_FAILED', {
                            mobileClient: this._selectedSession.phoneNumber
                        }),
                        NotificationStatus.ERROR
                    );
                }
            });
        this.closeModal();
    }

    @action
    private updatePendingRoomSessions(data: IPendingRoomSession[]) {
        this.setPendingRoomSessions(data);
        this.updatePendingRoomCount(data);
    }

    private dateFormat(date: string) {
        return moment(date).format(reportToMomentDateFormat(this._accSettings.reportsDateFormat));
    }

    @computed
    private get pendingRoomSessionsCount() {
        return this._appState.pendingRoomCount;
    }

    private updatePendingRoomCount(pendingRoomSessions: IPendingRoomSession[]) {
        let relevanWtRooms = pendingRoomSessions;

        if (this._inviteSettings.pendingRoomShowAlertsToSelectedGroups && this._selectedGroupsForPendingRoomAlerts) {
            if (
                !some(this._inviteSettings.groups, (group: any) =>
                    includes(this._selectedGroupsForPendingRoomAlerts, group._id)
                )
            ) {
                relevanWtRooms = [];
            }
        }

        this._appState.setPendingRoomCount(relevanWtRooms.length);
    }
}
