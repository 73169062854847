import React, {RefObject} from 'react';
import {observer} from 'mobx-react';
import {MagicMarkerComponent, Multiparty} from '@techsee/techsee-media-service-client';
import {IVideoController} from './VideoController';
export type VideoComponentProps = {
    controller: IVideoController;
};

export const VideoComponent: React.FC<VideoComponentProps> = observer(({controller}): React.ReactElement => {
    const {translate, multipartyController, magicMarkerController} = controller;
    const translations = {
        connectingCaption: translate('FACE_MEET.VIDEO.CONNECTING'),
        waitingForFirstPeerCaption: translate('FACE_MEET.VIDEO.WAITING_FOR_CLIENT'),
        mediaRejectedAccessDeniedTooltip: translate('FACE_MEET.VIDEO.TOOLTIP.PERMISSION_DENIED'),
        mediaRejectedUserDeniedPermission: translate('FACE_MEET.VIDEO.USER_DENIED_PERMISSION'),
        mediaRejectedPermissionDeniedTitle: translate('FACE_MEET.VIDEO.PERMISSION_DENIED_TITLE'),
        mediaRejectedPermissionDeniedText: translate('FACE_MEET.VIDEO.PERMISSION_DENIED_TEXT')
    };

    const remoteDrawings = magicMarkerController.remoteDrawings;
    const magicMarkerOptions = magicMarkerController.options;

    const magicMarker =
        magicMarkerController.shouldDisplayComponent &&
        controller.allowMagicMarker &&
        (({floatingElements, scale}: {floatingElements: RefObject<HTMLElement>[]; scale?: number}) => (
            <MagicMarkerComponent
                options={magicMarkerOptions}
                onDrawingsUpdate={magicMarkerController.sendDrawings}
                remoteDrawings={remoteDrawings}
                onRemoteDrawingsDisplayEnd={magicMarkerController.clearRemoteDrawings}
                floatingElements={floatingElements}
                scale={scale}
            />
        ));

    return multipartyController ? (
        <Multiparty
            controller={multipartyController}
            selectedLayoutType={controller.videoLayoutType}
            onPinnedVideoChanged={controller.onPinnedUserChangeRequested}
            onSnapshotRequested={controller.onSnapshotRequested}
            isPeerMediaPermissionRejected={controller.isPeerMediaPermissionRejected}
            translations={translations}
            videoOverlay={magicMarker || undefined}
            isMobile={controller.isMobile && controller.desktopSharingToMobile}
        />
    ) : (
        <React.Fragment />
    );
});
