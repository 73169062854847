'use strict';
import tsWidgetsView from './ts-widgets.view.html';
import './ts-widgets.style.scss';

export function tsWidgets() {
    return {
        template: tsWidgetsView,
        scope: true
    };
}
