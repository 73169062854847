import {ObservableForm} from './form-model';
import {IFieldFactory} from '../../../../../app.contracts';
import {IInviteFlowManager, IKeepCustomerDetailsConfig} from '../../../_contracts/InviteContracts';
import {action, computed, observable} from 'mobx';
import {ITranslate} from '../../../../../services/LocalizationService';
import isEmpty from 'lodash/isEmpty';

import './styles.scss';

export interface SearchByItem {
    name: string;
    value: string;
}

export const enum observableFieldsTranslate {
    userName = 'REACT.INVITE.VIEW.SESSION_OBSERVATION.USER_NAME',
    phone = 'REACT.INVITE.VIEW.SESSION_OBSERVATION.PHONE',
    customerId = 'REACT.INVITE.VIEW.SESSION_OBSERVATION.REFERENCE_ID',
    email = 'REACT.INVITE.VIEW.SESSION_OBSERVATION.EMAIL'
}

const enum observableFields {
    userName = 'userName',
    phone = 'customerNumber',
    customerId = 'customerId',
    email = 'customerEmail'
}

export interface IFilter {
    userName?: string;
    customerId?: string;
    customerNumber?: string;
    customerEmail?: string;
}

export interface IObservableController {
    readonly form: ObservableForm;
    readonly searchByError: boolean;
    readonly translate: ITranslate;
    searchByOptions: SearchByItem[];
    searchByErrorMessage: string;
    isSearchByFieldEmpty: boolean;

    setSearchByError(state: boolean, messageType?: observableFields | string): void;
    resetForm(): void;
    getChosenFilter(): IFilter;
}

const errorMapTranslate: {[key: string]: string} = {
    userName: 'REACT.INVITE.VIEW.SESSION_OBSERVATION.USER_NAME.NOT_EXIST',
    customerNumber: 'REACT.INVITE.VIEW.SESSION_OBSERVATION.PHONE.NOT_EXIST',
    customerEmail: 'REACT.INVITE.VIEW.SESSION_OBSERVATION.EMAIL.NOT_EXIST',
    customerId: 'REACT.INVITE.VIEW.SESSION_OBSERVATION.REFERENCE_ID.NOT_EXIST',
    error: 'REACT.INVITE.VIEW.SESSION_OBSERVATION.ERROR'
};

export class ObservableController implements IObservableController {
    @observable private _searchByError: boolean = false;

    @observable private _searchByErrorMessage: string = '';

    private _searchByOptions: SearchByItem[] = [];

    readonly form: ObservableForm;

    constructor(
        private _flowManager: IInviteFlowManager,
        private _fieldFactory: IFieldFactory,
        private _translate: ITranslate,
        private _config: IKeepCustomerDetailsConfig
    ) {
        this.form = new ObservableForm(this._flowManager.translate, _fieldFactory);
        this._searchByOptions = this.observableFieldsToSearch;

        this.createFormFields(this.form);

        this.getChosenFilter = this.getChosenFilter.bind(this);
        this.resetForm = this.resetForm.bind(this);
    }

    private get observableFieldsToSearch() {
        const fields = [
            {name: this._translate(observableFieldsTranslate.userName), value: observableFields.userName},
            {name: this._translate(observableFieldsTranslate.customerId), value: observableFields.customerId}
        ];

        if (this._config.keepCustomerMobile) {
            fields.push({name: this._translate(observableFieldsTranslate.phone), value: observableFields.phone});
        }

        if (this._config.keepCustomerEmailAddress) {
            fields.push({name: this._translate(observableFieldsTranslate.email), value: observableFields.email});
        }

        return fields;
    }

    get translate() {
        return this._translate;
    }

    get searchByOptions(): SearchByItem[] {
        return this._searchByOptions;
    }

    get isSearchByFieldEmpty() {
        return isEmpty(this.form.fields.searchBy.value.toString());
    }

    getChosenFilter(): IFilter {
        const searchByOption = this.form.fields.searchByOption.value;
        const searchBy = this.form.fields.searchBy.value.toString();
        const filter: IFilter = {};

        switch (searchByOption) {
            case this._translate(observableFieldsTranslate.userName):
                filter[observableFields.userName] = searchBy;
                break;
            case this._translate(observableFieldsTranslate.customerId):
                filter[observableFields.customerId] = searchBy;
                break;
            case this._translate(observableFieldsTranslate.phone):
                filter[observableFields.phone] = searchBy;
                break;
            case this._translate(observableFieldsTranslate.email):
                filter[observableFields.email] = searchBy;
                break;
            default:
                break;
        }

        return filter;
    }

    @computed
    get searchByError() {
        return this._searchByError;
    }

    @computed
    get searchByErrorMessage() {
        return this._searchByErrorMessage;
    }

    resetForm(): void {
        this.setSearchByError(false);
        this.form.onClear();
    }

    @action
    setSearchByError(state: boolean, messageType?: observableFields | string) {
        this._searchByError = state;
        this.setSearchByErrorMessage(messageType ? errorMapTranslate[messageType] : '');
    }

    @action
    private setSearchByErrorMessage(message: string) {
        this._searchByErrorMessage = message;
    }

    private createFormFields(form: ObservableForm): void {
        form.createSearchByField();
    }
}
