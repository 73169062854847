import React, {Component} from 'react';
import ChangeNumber from '../invite-wizard/components/change-number/index';
import LookupNumberModal from '../invite-wizard/components/lookup-number';
import {InviteWizard} from '../invite-wizard/';

import './style.scss';

export default class InviteMainContent extends Component {
    render() {
        return (
            <div className='invite-main-content'>
                <InviteWizard />
                <ChangeNumber />
                <LookupNumberModal />
            </div>
        );
    }
}
