'use strict';

import {getRootStore} from '../../_react_/app.bootstrap';
import {IMeasureService} from './ts-measure.service';

export class MeasureNgService {
    private _service: IMeasureService;

    constructor() {
        this._service = getRootStore().measureService;
    }

    get service() {
        return this._service;
    }
}
