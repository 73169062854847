import React from 'react';
import {observer} from 'mobx-react';

import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {IconColors} from '@techsee/techsee-ui-common/lib/icons/large-rounded-icon';

import {PermissionsStepTabs} from '../_Shared';
import {InviteStepProps} from '../_InviteGenericStep';
import {TermsStepController} from './controller';

import {GuidenceProgressItem} from '../../components/guidance-progress/GuidanceProgress';
import {ProgressList} from '../../components/progress-list/ProgressList';
import {AgentGuidance} from '../../components/agent-guidance/AgentGuidance';
import {InviteDeviceType} from '../../../_contracts/InviteContracts';

@observer
export class TermsStepContent extends TechseeBaseComponent<InviteStepProps<TermsStepController>> {
    componentDidMount() {
        if (this.props.ctrl.isDesktopSharing) {
            this.props.ctrl.setPagePlacement('invite-terms-desktop-sharing-step');
        } else {
            this.props.ctrl.setPagePlacement('invite-terms-step');
        }
    }

    renderRegularTerms() {
        const {clientDeviceType, translate, displayRepromptControls} = this.props.ctrl;
        const progressItems: GuidenceProgressItem[] = [
            {
                iconColor: IconColors.Blue,
                label: translate('REACT.INVITE.PERMISSIONS.TERMS.TITLE'),
                isActive: true,
                icon: 'tos',
                isIconAnimated: false
            },
            {
                iconColor: IconColors.DarkGray,
                label: translate('REACT.INVITE.PERMISSIONS.CAMERA.TITLE'),
                isActive: false,
                icon: 'camera',
                isIconAnimated: false
            }
        ];

        const tooltipText = displayRepromptControls ? '' : translate('REACT.INVITE.PERMISSIONS.TERMS.TAP_ALLOW');
        const guidanceType = displayRepromptControls ? 'terms-reject' : 'terms-allow';

        return (
            <PermissionsStepTabs
                guidanceType={guidanceType}
                progressList={progressItems}
                deviceType={clientDeviceType}
                stepClass={'terms-step'}
                title={translate('REACT.INVITE.PERMISSIONS.TERMS.TITLE')}
                subTitle={translate('REACT.INVITE.PERMISSIONS.TERMS.SUB_TITLE')}
                guidanceText={tooltipText}
            />
        );
    }

    renderDesktopSharingTerms() {
        const {translate} = this.props.ctrl;

        const progressItems: GuidenceProgressItem[] = [
            {
                iconColor: IconColors.Blue,
                label: translate('REACT.INVITE.GUIDANCE.ACCEPT_TOS'),
                isActive: true,
                icon: 'tos',
                isIconAnimated: false
            },
            {
                iconColor: IconColors.DarkGray,
                label: translate('REACT.INVITE.GUIDANCE.SHARING_TYPE_TITLE'),
                isActive: false,
                icon: 'desktop-sharing',
                isIconAnimated: false
            }
        ];

        return (
            <div className='desktop-sharing-guidance'>
                <h1 className='page-header'>{translate('REACT.INVITE.VIEW.START_DESKTOP_SHARING_TITLE')}</h1>
                <h2 className='sub-header'>{translate('REACT.INVITE.VIEW.START_DESKTOP_SHARING_SUB_TITLE')}</h2>

                <div className='desktop-sharing-guidance__content'>
                    <div className='desktop-sharing-guidance__progress'>
                        <ProgressList progressList={progressItems} />
                    </div>

                    <div className='desktop-sharing-guidance__agent'>
                        <AgentGuidance
                            stepType='desktop-sharing'
                            stepText={translate('REACT.INVITE.GUIDANCE.TAP_ALLOW')}
                            stepNum={2}
                            deviceType={InviteDeviceType.common}
                        />
                    </div>
                </div>
            </div>
        );
    }

    renderInternal() {
        if (this.props.ctrl.isDesktopSharing) {
            return this.renderDesktopSharingTerms();
        }

        return this.renderRegularTerms();
    }
}
