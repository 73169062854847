import {serializable} from 'serializr';

export interface ISmsStatusDetails {
    customError?: string;
    smsError?: string;
    statusMessage?: string;
}

export class SendSmsStatus {
    @serializable name: string;

    @serializable isActive: boolean;

    messageDetails: ISmsStatusDetails;

    constructor(
        name: string = '',
        isActive: boolean = false,
        messageDetails: {
            customError?: string;
            smsError?: string;
            statusMessage?: string;
        } = {}
    ) {
        this.name = name;
        this.isActive = isActive;
        this.messageDetails = messageDetails;
    }
}
