import {action, observable} from 'mobx';
import {ITranslate} from '../../../../services/LocalizationService';
import {ILoginConfirmationController} from '../login-confirmation/controller';
import {ILoginController} from '../login-form/controller';

export interface ILoginModalController {
    readonly isLoginModalVisible: boolean;
    readonly translate: ITranslate;
    readonly loginFormController: ILoginController;
    readonly loginConfirmationController: ILoginConfirmationController;

    onConfirmedLogin(): void;
    onSuccessLogin(callback: () => void): void;
    onErrorLogin(callback: (err?: any) => void): void;
    show(): void;
    hide(): void;
}

export class LoginModalController implements ILoginModalController {
    @observable isLoginModalVisible: boolean = false;

    private successLoginCallback: () => void = () => {};

    private errorLoginCallback: (err?: any) => void = () => {};

    constructor(
        public translate: ITranslate,
        public loginFormController: ILoginController,
        public loginConfirmationController: ILoginConfirmationController
    ) {
        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.onConfirmedLogin = this.onConfirmedLogin.bind(this);

        this.loginFormController.setConfig({
            errorCallback: (err) => {
                this.errorLoginCallback(err);
            },
            submitCallback: () => {
                this.hide();
                this.successLoginCallback();
            }
        });
    }

    @action
    onSuccessLogin(callback: () => void): void {
        this.successLoginCallback = callback;
    }

    @action
    onErrorLogin(callback: (err?: any) => void): void {
        this.errorLoginCallback = callback;
    }

    @action
    onConfirmedLogin() {
        this.loginFormController.onSubmitLogin(true);
    }

    @action
    show() {
        this.isLoginModalVisible = true;
    }

    @action
    hide() {
        this.isLoginModalVisible = false;
    }
}

export default LoginModalController;
