import {action, computed, observable, runInAction} from 'mobx';
import {IInviteFlowManager} from '../../_contracts/InviteContracts';

export interface ISessionAbortController {
    onAbortClicked(): void;

    abortSession(): void;

    continueSession(): void;

    readonly isSessionActive: boolean;

    readonly isDisplayConfirmationModal: boolean;
}

export class SessionAbortController implements ISessionAbortController {
    @observable private showConfirmationModal: boolean;

    private _flowManager: IInviteFlowManager;

    constructor(flowManager: IInviteFlowManager) {
        this._flowManager = flowManager;

        this.showConfirmationModal = false;

        this.onAbortClicked = this.onAbortClicked.bind(this);
        this.abortSession = this.abortSession.bind(this);
        this.continueSession = this.continueSession.bind(this);
    }

    @computed
    get isSessionActive() {
        return this._flowManager.inviteState.sessionInfo.isSessionActive;
    }

    @action
    onAbortClicked(): void {
        if (this.isSessionActive) {
            this.showConfirmationModal = true;
        }
    }

    abortSession(): void {
        this._flowManager.endSession().then(() => {
            runInAction(() => (this.showConfirmationModal = false));
        });
    }

    @action
    continueSession(): void {
        this.showConfirmationModal = false;
    }

    @computed
    get isDisplayConfirmationModal(): boolean {
        return this.showConfirmationModal;
    }
}
