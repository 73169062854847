import React from 'react';
import {observer} from 'mobx-react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {PrimaryButton} from '@techsee/techsee-ui-common/lib/forms/buttons/primary';
import {LabelType, NotificationLabel} from '@techsee/techsee-ui-common/lib/notification-label';
import FormInput from '@techsee/techsee-ui-common/lib/forms/input';
import FormFancyDropDown from '@techsee/techsee-ui-common/lib/forms/form-fancy-drop-down';
import {ButtonTypeAttributes} from '@techsee/techsee-ui-common/lib/forms/buttons/generic';
import {ILoginController} from './controller';
import {ITranslate} from '../../../../services/LocalizationService';
import {AccountOption} from './AccountOption';

import './styles.scss';

export interface ILoginFormProps {
    ctrl: ILoginController;
    translate: ITranslate;
}

@observer
export class LoginFormView extends TechseeBaseComponent<ILoginFormProps> {
    private submitHandler(e?: React.FormEvent) {
        e && e.preventDefault();

        if (e && e.target instanceof HTMLElement) {
            e.target.tabIndex = -1;
            e.target.focus();
        }

        return this.props.ctrl.onSubmitLogin();
    }

    renderInternal() {
        const {ctrl, translate} = this.props;
        const {form} = ctrl;

        return (
            <div className={'login-panel'}>
                <div className={'panel-heading'}>
                    <h3 className={'panel-title'}>{translate('REACT.LOGIN.VIEW.LOGIN_TITLE')}</h3>
                </div>

                {!!ctrl.errorMessage && (
                    <NotificationLabel type={LabelType.Error}>
                        <span dangerouslySetInnerHTML={{__html: ctrl.errorMessage}} />
                    </NotificationLabel>
                )}

                <div className={'panel-body'}>
                    <form className={'login-form'} onSubmit={this.submitHandler.bind(this)}>
                        <FormInput
                            uid={'login-username'}
                            className='login-username'
                            inputType={'text'}
                            model={form.fields.username}
                        />
                        <FormInput
                            uid={'login-password'}
                            className='login-password'
                            inputType={'password'}
                            model={form.fields.password}
                        />
                        {ctrl.hasMultipleAccounts && (
                            <FormFancyDropDown
                                className='login-account'
                                uid='login-account'
                                model={form.fields.account}
                                optionValueKey='companyName'
                                searchKeys={['companyName']}
                                options={ctrl.accounts}
                                optionComponent={AccountOption}
                            />
                        )}
                        <div className={'form-group started-button'}>
                            <PrimaryButton type={ButtonTypeAttributes.Submit} onClick={() => this.submitHandler()}>
                                {ctrl.config.submitButtonText}
                            </PrimaryButton>
                        </div>
                        {ctrl.config.showMissedAccountInfo && (
                            <p dangerouslySetInnerHTML={{__html: translate('REACT.LOGIN.VIEW.MISSED_ACCOUNT')}}></p>
                        )}
                    </form>
                </div>
            </div>
        );
    }
}

export default LoginFormView;
