import {observable, action, toJS, computed} from 'mobx';
import {ITranslate} from '../../../services/LocalizationService';

export interface IStep {
    name: string;
    image?: {
        url: string;
    };
}

export interface ISelfServiceController {
    translate: ITranslate;
    steps: IStep[];
    deviceType: string;
    transferInnitiator: string;
    portsMapping: any;
    ledsMapping: any;
    show: boolean;
    isStepsOpen: boolean;

    toggleSteps(): void;
    clickStepHandler(step: IStep): void;
}

export interface IPort {
    connected: boolean;
    translate?: string;
    message?: string;
}

export class SelfServiceController implements ISelfServiceController {
    @observable private _show: boolean = false;

    @observable private _isStepsOpen = false;

    @observable ledsMapping = [];

    @observable portsMapping = [];

    @observable steps: IStep[] = [];

    @observable deviceType = '';

    warmTransferStep: IStep;

    translate: ITranslate;

    transferInnitiator = 'CUSTOMER';

    private clickStepCallback: (step?: IStep) => void = () => {};

    constructor(
        translate: ITranslate,
        steps: IStep[],
        transferInnitiator: string | undefined,
        warmTransferStep: IStep
    ) {
        this.translate = translate;
        if (transferInnitiator) {
            this.transferInnitiator = transferInnitiator;
        }

        this.toggleSteps = this.toggleSteps.bind(this);
        this.steps = steps;
        this.warmTransferStep = warmTransferStep;
        if (warmTransferStep) {
            this.steps.push(warmTransferStep);
        }

        this.clickStepHandler = this.clickStepHandler.bind(this);
    }

    @action
    setDeviceType(deviceType: string) {
        this.deviceType = deviceType;
    }

    @action
    setLedsMapping(ledsMapping: any) {
        this.ledsMapping = ledsMapping;
    }

    @action
    setPortsMapping(portsMaping: any) {
        this.portsMapping = portsMaping;
    }

    @action
    setSteps(steps: IStep[]) {
        this.steps = steps;

        if (this.warmTransferStep) {
            this.steps.push(this.warmTransferStep);
        }
    }

    onClickStep(callback: (step: IStep | undefined) => void) {
        this.clickStepCallback = callback;
    }

    @computed
    get isStepsOpen() {
        return this._isStepsOpen;
    }

    @action
    toggleSteps() {
        this._isStepsOpen = !this._isStepsOpen;
    }

    @action
    clickSummary() {
        this._show = !this._show;
    }

    clickStepHandler(step: IStep) {
        this.clickStepCallback(toJS(step));
    }

    @computed
    get show() {
        return this._show;
    }
}
