'use strict';

import sessionRequestsView from './session-requests.view.html';
import {SessionRequestsController} from './session-requests.controller.js';
import './session-requests.style.scss';

function config($stateProvider) {
    $stateProvider.state('invite.old.sessionRequests', {
        url: '^' + ROUTE_BASE_PATH + 'session-requests',
        template: sessionRequestsView,
        controller: 'SessionRequestsController',
        controllerAs: 'sessionRequests'
    });
}

export default angular
    .module('states.invite.sessionRequests', [])
    .config(config)
    .controller('SessionRequestsController', SessionRequestsController);
