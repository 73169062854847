import React from 'react';
import {observer} from 'mobx-react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {GuidenceProgressItem} from '../components/guidance-progress/GuidanceProgress';
import {ProgressList} from '../components/progress-list/ProgressList';
import {AgentGuidance} from '../components/agent-guidance/AgentGuidance';
import {InviteStepsLayout} from './_Shared';
import {InviteStepProps} from './_InviteGenericStep';
import {SimpleTabs, ITab} from '@techsee/techsee-ui-common/lib/tabs/simple';
import {InviteDeviceType} from '../../_contracts/InviteContracts';

@observer
export class AppShareGuidance extends TechseeBaseComponent<InviteStepProps> {
    componentDidMount() {
        this.props.ctrl.setPagePlacement('invite-terms-app-mirroring');
    }

    get progressItems(): GuidenceProgressItem[] {
        return [];
    }

    getTab(device: InviteDeviceType) {
        const {progressItems} = this;
        const {translate} = this.props.ctrl;

        return () => (
            <div className={'guidance-progress row'}>
                <div className={'col-left col-sm-4'}>
                    <ProgressList progressList={progressItems} />
                </div>
                <div className={'col-left col-sm-4 mid-col'}>
                    <AgentGuidance
                        stepType={'app-share-user'}
                        stepNum={1}
                        deviceType={device}
                        stepText={translate('REACT.INVITE.PERMISSIONS.APP_SHARE.USER_PERMISSION')}
                    />
                </div>
                <div className={'col-left col-sm-4'}>&nbsp;</div>
            </div>
        );
    }

    renderInternal() {
        const {clientDeviceType, translate} = this.props.ctrl;
        const tabsList: ITab[] = [
            {
                title: clientDeviceType === InviteDeviceType.ios ? 'iOS' : 'Android',
                content: this.getTab(clientDeviceType)
            }
        ];

        return (
            <React.Fragment>
                <InviteStepsLayout
                    className={'app-share-user-step'}
                    h1Text={translate('REACT.INVITE.PERMISSIONS.APP_SHARE.TITLE')}
                    h2Text={translate('REACT.INVITE.PERMISSIONS.APP_SHARE.SUB_TITLE')}></InviteStepsLayout>
                <SimpleTabs tabsList={tabsList} uid={'app-share-user-tabs'} />
            </React.Fragment>
        );
    }
}
