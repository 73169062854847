'use strict';
import {IFloatingToolbarGroup} from '@techsee/techsee-ui-common/lib/floating-toolbar/contracts';
import get from 'lodash/get';
import {IToolbarOptionsController, ToolbarOptionsController} from './ts-toolbar-options.controller';
import './ts-floating-toolbar.style.scss';
import {Boundaries} from '@techsee/techsee-ui-common/lib/draggble-panel/contracts';
import {DeviceCategory} from '@techsee/techsee-common/lib/constants/utils.constant';
import {getRootStore} from '../../../_react_/app.bootstrap';

// eslint-disable-next-line init-declarations
declare const angular: any;

export interface ITsFloatingToolbarController {
    readonly boundaries: Boundaries;
    readonly offsetBoundaries: Boundaries;
    readonly initialized: boolean;
    readonly toolbarOptions: IFloatingToolbarGroup[];
}

export class TsFloatingToolbarController implements ITsFloatingToolbarController {
    // Angular dependencies
    private _scope: any;

    private _element: any;

    private _mainCtrl: any;

    private _dashboardCtrl: any;

    private _timeout: any;

    private _tsCanvasAnnotate: any;

    // Private props
    private _toolbarPos: {x: number; y: number} = {x: -999, y: -999};

    private _initialized: boolean = false;

    private _prevToolbarHeight: number = 0;

    private _toolbarOptionsController: IToolbarOptionsController;

    private _deviceCategory: DeviceCategory = DeviceCategory.desktop;

    static $inject = ['$scope', '$element', '$timeout', 'tsCanvasAnnotate'];

    constructor($scope: any, $element: any, $timeout: any, tsCanvasAnnotate: any) {
        this._scope = $scope;
        this._element = $element;
        this._mainCtrl = this._scope.main;
        this._dashboardCtrl = this._scope.dashboard;
        this._timeout = $timeout;
        this._tsCanvasAnnotate = tsCanvasAnnotate;

        const environmentDetect = getRootStore().environmentService;

        this._deviceCategory =
            environmentDetect.deviceCategory(getRootStore().displayTabletAsDesktop) || DeviceCategory.desktop;
        this._initialized = false;
        this._toolbarOptionsController = new ToolbarOptionsController(this._scope, this._tsCanvasAnnotate);
    }

    init() {
        if (!this._initialized) {
            const toolbarMargin = 30;
            const mainContent: any = angular.element('.dashboard-main-content-container');
            const toolbarEl = this._element.find('.floating-toolbar');
            const mainCoords = mainContent.get(0).getBoundingClientRect();
            const toolbarHeight = toolbarEl.get(0) && toolbarEl.get(0).offsetHeight;
            const toolbarWidth = toolbarEl.get(0) && toolbarEl.get(0).offsetWidth;

            if (mainCoords.right > 0 && mainCoords.bottom > 0 && toolbarWidth && toolbarHeight) {
                this._initialized = true;
            } else {
                return;
            }

            this._toolbarPos = {
                x: mainCoords.right - toolbarWidth - toolbarMargin,
                y: mainCoords.bottom - toolbarHeight - toolbarMargin
            };

            this._prevToolbarHeight = toolbarHeight;
        }
    }

    get initialized() {
        return this._initialized;
    }

    get toolbarPos() {
        return this._toolbarPos;
    }

    get deviceCategory() {
        return this._deviceCategory;
    }

    // Getting top, bottom, left and right edges to limit moving toolbar across the window
    get boundaries(): Boundaries {
        return {
            left: 0,
            top: 0,
            right: window.innerWidth,
            bottom: window.innerHeight
        };
    }

    get offsetBoundaries(): Boundaries {
        if (this._dashboardCtrl.isToggleEnabled_NewHeaderFooterLeftbar()) {
            const newLeftBar = (angular.element('.app-left-bar') as any).get(0);
            const newTopBar = (angular.element('.app-top-bar ') as any).get(0);

            return {
                left: get(newLeftBar, 'offsetWidth', 0),
                top: get(newTopBar, 'offsetHeight', 0),
                right: 0,
                bottom: 0
            };
        }

        const topBarHeightEl = (angular.element('.dashboard-top-bar') as any).get(0);
        const leftBarWidthEl = (angular.element('.main-left-side') as any).get(0);
        const bottomBarHeightEl = (angular.element('.dashboard-bottom-bar') as any).get(0);

        return {
            left: get(leftBarWidthEl, 'offsetWidth', 0),
            top: get(topBarHeightEl, 'offsetHeight', 0),
            right: 0,
            bottom: get(bottomBarHeightEl, 'offsetHeight', 0)
        };
    }

    get toolbarOptions() {
        return this._toolbarOptionsController.toolbarOptions;
    }
}
