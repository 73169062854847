import {action, autorun, computed, observable} from 'mobx';
import {IReactionDisposer} from 'mobx/lib/core/reaction';
import {deserialize, serialize} from 'serializr';
import {IBrowserUtilsService} from '@techsee/techsee-client-infra/lib/services/BrowserUtilsService';
import {Nullable} from '@techsee/techsee-ui-common/lib/_shared/reusable-types';
import {InviteState} from './_contracts/InviteState';
import merge from 'lodash/merge';

export interface IInviteStateManager {
    readonly inviteState: InviteState;

    readonly isAutoSaveEnabled: boolean;

    readonly isReady: boolean;

    readonly onReady: Promise<void>;

    enableAutoSave(): void;

    disableAutoSave(): void;

    resetState(): void;
}

export class InviteStateManager implements IInviteStateManager {
    private _browserUtilsService: IBrowserUtilsService;
    private _storageKey = 'current-session';
    private _autorunDisposer: Nullable<IReactionDisposer> = null;

    @observable
    private _inviteState: Nullable<InviteState> = null;
    private _readyPromise: any = {promise: null, resolver: null, rejector: null, isReady: false};

    constructor(browserUtilsService: IBrowserUtilsService) {
        this._browserUtilsService = browserUtilsService;

        this._readyPromise.promise = new Promise((resolve, reject) => {
            this._readyPromise.resolver = resolve;
            this._readyPromise.rejector = reject;
        });

        this.initInviteState();
    }

    @computed
    get inviteState(): InviteState {
        this.throwIfNotReady();

        return this._inviteState!;
    }

    get isAutoSaveEnabled(): boolean {
        return this._autorunDisposer !== null;
    }

    get isReady() {
        return this._readyPromise.isReady;
    }

    get onReady(): Promise<void> {
        return this._readyPromise.promise;
    }

    enableAutoSave() {
        if (this.isAutoSaveEnabled) {
            return;
        }

        this._autorunDisposer = autorun(() => {
            if (this._readyPromise.isReady) {
                if (this.inviteState.sessionInfo.isSessionActive) {
                    this.saveStateToSessionStorage();
                }
            }
        });
    }

    disableAutoSave() {
        this._autorunDisposer && this._autorunDisposer();
        this._autorunDisposer = null;
    }

    @action
    resetState() {
        this._browserUtilsService.removeFromSessionStorage(this._storageKey);
        merge(this._inviteState, new InviteState());
    }

    private initInviteState(): void {
        const storedSession = this.loadStateFromSessionStorage();

        if (storedSession !== null) {
            this._inviteState = storedSession;
        } else {
            this._inviteState = new InviteState();
        }

        this._readyPromise.isReady = true;
        this._readyPromise.resolver();
    }

    private saveStateToSessionStorage() {
        const dataToPersist = serialize(this.inviteState);

        this._browserUtilsService.saveToSessionStorage(this._storageKey, dataToPersist);
    }

    private loadStateFromSessionStorage(): Nullable<InviteState> {
        try {
            const storedData = this._browserUtilsService.getFromSessionStorage(this._storageKey);

            if (storedData) {
                const deserializedState = deserialize<InviteState>(InviteState, storedData);

                return deserializedState as InviteState;
            }

            return null;
        } catch (e) {
            return null;
        }
    }

    private throwIfNotReady() {
        if (!this._readyPromise.isReady) {
            const msg = 'InviteState not ready yet, insure you onReady resolved before using the class.';

            throw new Error(msg);
        }
    }
}
