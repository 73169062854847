import {getAppTracer} from '../../../../app.tracer';
import {IDbHistory} from '../../../services/AngularServices/AngularServices';
import {base64ToBlob} from './ImageUtils';
import {TriggeredDataCollectionSource} from '@techsee/techsee-common/lib/constants/account.constants';

const trace = getAppTracer('SnapshotService');

export class SnapshotService {
    constructor(
        private readonly $window: any,
        private readonly sharingService: any,
        private readonly dbHistoryService: IDbHistory,
        private readonly chatApi: any
    ) {
        this.snapshotToImg = this.snapshotToImg.bind(this);
    }

    public uploadSnapshot = async (base64img: string): Promise<boolean> => {
        trace.info('Snapshot ready', {base64img: base64img && base64img.length});

        const {URL} = this.$window;
        const data = base64img.split(',')[1];
        const objUrl = URL.createObjectURL(base64ToBlob(data));

        const result = new Promise((resolve: (value: boolean) => void) => {
            this.sharingService.uploadRoomImage(
                objUrl,
                {isTemp: false},
                (url: string) => {
                    this.chatApi.saveImage(url);
                    this.chatApi.imageSnapped();
                    resolve(true);
                },
                () => {
                    resolve(false);
                }
            );
        });

        URL.revokeObjectURL(objUrl);

        return result;
    };

    public uploadAutoCollectedImage = async (img: string): Promise<void> => {
        trace.info('uploadAutoCollectedImage about to start', {img: img});

        return this.sharingService
            .uploadAutoCollectedImage(img)
            .then(() => trace.info('Uploading of auto collected image ended successfully', {img: img}))
            .catch((err: any) =>
                trace.error('Uploading of auto collected image Failed!', {ErrorMessage: err && err.message})
            );
    };

    public uploadTriggeredCollectedImage = async (
        img: string,
        source: TriggeredDataCollectionSource
    ): Promise<void> => {
        trace.info('uploadTriggeredCollectedImage about to start', {img, source});

        return this.sharingService
            .uploadTriggeredCollectedImage(img, source)
            .then(() => trace.info('Uploading of triggered collected image ended successfully', {img, source}))
            .catch((err: any) =>
                trace.error('Uploading of triggered collected image Failed!', {ErrorMessage: err && err.message})
            );
    };

    public snapshotToImg(base64img: string) {
        const {URL} = this.$window;
        const dataUrl = base64img.split(',')[1];
        const blob = base64ToBlob(dataUrl);

        return {
            size: blob.size,
            img: URL.createObjectURL(blob)
        };
    }

    public fetchSnapshotUrlsFromHistory = async (roomId: string): Promise<string[]> => {
        const {data} = await this.dbHistoryService.room({params: {roomId}});

        return data ? data.images.map((snapshot) => snapshot.url) : [];
    };
}
