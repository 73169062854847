'use strict';

class TsDetachableWidgetController {
    constructor($element, $attrs) {
        'ngInject';

        this.$element = $element;
        this.$attrs = $attrs;
        this.position = $attrs.attachPosition === 'prepend' ? 'prepend' : 'append';
    }

    setDetached(val) {
        if (val === this.detached) {
            return;
        }

        this.$element.detach();
        this.detached = val;
        const target = val ? this.targetElement : this.originalContainer;

        target[this.position](this.$element);
    }

    init(selector) {
        this.targetElement = $(selector);

        if (this.targetElement.length > 0) {
            this.targetElement = this.targetElement.last();
        } else if (this.targetElement.lengt === 0) {
            this.targetElement = null;
        }

        this.originalContainer = this.$element.parent();
    }
}

function linkFn(scope, element, attrs, ctrl) {
    ctrl.init(attrs.detachTargetSelector);

    scope.$watch(
        () => scope.$eval(attrs.detachCondition),
        (newValue) => {
            ctrl.setDetached(newValue);
        }
    );
}

export function tsDetachableWidgetDirective() {
    return {
        restrict: 'A',
        scope: false,
        controller: TsDetachableWidgetController,
        controllerAs: 'vm',
        link: linkFn
    };
}
