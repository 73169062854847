'use strict';

import {getRootStore} from '../../_react_/app.bootstrap';
import {IUiFlavorService} from './ts-ui-flavor.service';

export class UiFlavorNgService {
    private _service: IUiFlavorService;

    constructor() {
        this._service = getRootStore().uiFlavorService;
    }

    get service() {
        return this._service;
    }
}
