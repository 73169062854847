'use strict';

/*
 * Helper service to load different view
 * Depending on an account setting
 */
import {getRootStore} from '../../_react_/app.bootstrap';

export class TsInterfaceHelperService {
    constructor($localStorage, mediaFrameUIParams, tsResponsiveUtils) {
        'ngInject';

        this.$localStorage = $localStorage;
        this.mediaFrameUIParams = mediaFrameUIParams;
        this.responsiveUtils = tsResponsiveUtils;
        this.environmentDetect = getRootStore().environmentService;
        this.isMobile = this.environmentDetect.isMobile(getRootStore().displayTabletAsDesktop);

        this.interfaceTypes = {
            old: 'old',
            new: 'new'
        };

        this.headerFooterLeftbarTypes = {
            old: 'old',
            new: 'new'
        };
    }

    get useNewInterface() {
        const interfaceType = this.$localStorage.interfaceType;

        return interfaceType ? interfaceType === this.interfaceTypes.new : false;
    }

    get newHeaderFooterLeftbar() {
        const headerFooterLeftbarType = this.$localStorage.headerFooterLeftbarType;

        return headerFooterLeftbarType ? headerFooterLeftbarType === this.headerFooterLeftbarTypes.new : false;
    }

    get mediaFrameHeightPadding() {
        const interfacePadding = this.useNewInterface
            ? this.mediaFrameUIParams.HEIGHT_PADDING_NEW
            : this.mediaFrameUIParams.HEIGHT_PADDING;
        const footerPadding = this.newHeaderFooterLeftbar
            ? this.mediaFrameUIParams.NEW_FOOTER_PADDING
            : this.mediaFrameUIParams.OLD_FOOTER_PADDING;

        return interfacePadding + footerPadding;
    }

    get mediaFrameWidthPadding() {
        return this.useNewInterface ? this.mediaFrameUIParams.WIDTH_PADDING_NEW : this.mediaFrameUIParams.WIDTH_PADDING;
    }

    get mediaFrameHeightPaddingMobile() {
        return this.responsiveUtils.isPortrait()
            ? this.mediaFrameUIParams.MOBILE_DASHBOARD_HEIGHT_PADDING_PORTRAIT
            : this.mediaFrameUIParams.MOBILE_DASHBOARD_HEIGHT_PADDING_LANDSCAPE;
    }

    get mediaFrameWidthPaddingMobile() {
        return this.responsiveUtils.isPortrait()
            ? this.mediaFrameUIParams.MOBILE_DASHBOARD_WIDTH_PADDING_PORTRAIT
            : this.mediaFrameUIParams.MOBILE_DASHBOARD_WIDTH_PADDING_LANDSCAPE;
    }

    get getPadding() {
        return this.isMobile
            ? {width: this.mediaFrameWidthPaddingMobile, height: this.mediaFrameHeightPaddingMobile}
            : {width: this.mediaFrameWidthPadding, height: this.mediaFrameHeightPadding};
    }

    setInterfaceType(useNew) {
        return (this.$localStorage.interfaceType = useNew ? this.interfaceTypes.new : this.interfaceTypes.old);
    }

    setHeaderFooterLeftbarType(isNew) {
        return (this.$localStorage.headerFooterLeftbarType = isNew
            ? this.headerFooterLeftbarTypes.new
            : this.headerFooterLeftbarTypes.old);
    }
}
