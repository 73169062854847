import React from 'react';
import classNames from 'classnames';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {PrimaryButton} from '@techsee/techsee-ui-common/lib/forms/buttons/primary';
import {SecondaryButton} from '@techsee/techsee-ui-common/lib/forms/buttons/secondary';
import {IGuestController} from '../controller';
import {FormInput} from '@techsee/techsee-ui-common/lib/forms/input';
import {observer} from 'mobx-react';

import '../styles.scss';

export interface IGuestView {
    controller: IGuestController;
}

@observer
export class GuestView extends TechseeBaseComponent<IGuestView> {
    renderInternal(): JSX.Element {
        const {controller} = this.props;

        const firstName = (
            <FormInput uid={'firstName'} className='input-first-name' inputType={'text'} model={controller.firstName} />
        );

        const lastName = (
            <FormInput uid={'lastName'} className='input-last-name' inputType={'text'} model={controller.lastName} />
        );

        const guestInitialView = (
            <>
                <div
                    className={classNames('invitation-message', {desktop: !controller.isPhone})}
                    dangerouslySetInnerHTML={{
                        __html: controller.translate('REACT.OBSERVER_STATE.INVITATION_MESSAGE')
                    }}></div>

                <div className='buttons-container'>
                    <PrimaryButton onClick={controller.goToLogin}>
                        {controller.translate('REACT.LOGIN.VIEW.LOGIN_BUTTON')}
                    </PrimaryButton>

                    <SecondaryButton onClick={controller.continueAsGuest}>
                        {controller.translate('REACT.OBSERVER_STATE.CONTINUE_AS_GUEST')}
                    </SecondaryButton>
                </div>
            </>
        );

        const continueAsGuest = (
            <>
                <div className={classNames('invitation-message', {desktop: !controller.isPhone})}>
                    {controller.translate('REACT.OBSERVER_STATE.FORM_MESSAGE')}
                </div>

                <div className='form-container'>
                    {firstName}
                    {lastName}

                    {controller.errorMessage && <div className='error-message'>{controller.errorMessage}</div>}

                    <PrimaryButton onClick={controller.joinSession} disabled={controller.joinInProgress}>
                        {controller.translate('REACT.OBSERVER_STATE.JOIN')}
                    </PrimaryButton>
                </div>
            </>
        );

        const guestView = (
            <div className='use-common-styles'>
                {!controller.isGuestLogin && guestInitialView}
                {controller.isGuestLogin && continueAsGuest}
            </div>
        );

        const sessionEnded = (
            <div
                className={classNames('session-expired', {desktop: !controller.isPhone})}
                dangerouslySetInnerHTML={{__html: controller.sessionEndedMessage}}></div>
        );

        return (
            <div className='guest-state'>
                <div className='logo'>
                    <img className='logo-img' src={controller.loadImage('logo-guest.png')} />
                </div>

                {controller.isSessionEnded && sessionEnded}
                {!controller.isSessionEnded && guestView}

                <div className='powered-by'>{controller.translate('DASHBOARD.VIEW.POWERED_BY_TECHSEE')}</div>
            </div>
        );
    }
}
