import React from 'react';
import {observer} from 'mobx-react';
import {ButtonsToolbar} from '@techsee/techsee-ui-common/lib/forms/buttons/toolbar';
import {PrimaryButton} from '@techsee/techsee-ui-common/lib/forms/buttons/primary';
import {GenericModal} from '@techsee/techsee-ui-common/lib/modals/generic';
import {SecondaryButton} from '@techsee/techsee-ui-common/lib/forms/buttons/secondary';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {BorderRoundedIcon, IconColors, IconSizes} from '@techsee/techsee-ui-common/lib/icons/large-rounded-icon';
import {IModeSwitchModalController} from './controller';

import './styles.scss';

export interface IModeSwitchModal {
    controller: IModeSwitchModalController;
}

@observer
export class ModeSwitchModal extends TechseeBaseComponent<IModeSwitchModal> {
    constructor(props: IModeSwitchModal) {
        super(props);

        this.renderDoubleArrow = this.renderDoubleArrow.bind(this);
        this.renderHeader = this.renderHeader.bind(this);
        this.renderContent = this.renderContent.bind(this);
        this.renderToolbar = this.renderToolbar.bind(this);
    }

    renderDoubleArrow() {
        return (
            <div className={'double-arrow-icon'}>
                <BorderRoundedIcon
                    className={'arrow-next-icon'}
                    iconName={'arrow-next'}
                    colorName={IconColors.Green}
                    sizeName={IconSizes.XLarge}
                    noBorder={true}></BorderRoundedIcon>
                <BorderRoundedIcon
                    className={'arrow-next-icon'}
                    iconName={'arrow-next'}
                    colorName={IconColors.Green}
                    sizeName={IconSizes.XLarge}
                    noBorder={true}></BorderRoundedIcon>
            </div>
        );
    }

    renderHeader() {
        const icons = this.props.controller.icons;

        return (
            <div className={'icon-container'}>
                <BorderRoundedIcon
                    className={'current-mode-icon'}
                    iconName={icons[0]}
                    colorName={IconColors.Blue}
                    sizeName={IconSizes.XLarge}
                    noBorder={true}></BorderRoundedIcon>
                {this.renderDoubleArrow()}
                <BorderRoundedIcon
                    className={'new-mode-icon'}
                    iconName={icons[1]}
                    colorName={IconColors.Blue}
                    sizeName={IconSizes.XLarge}
                    noBorder={true}></BorderRoundedIcon>
            </div>
        );
    }

    renderContent() {
        const {controller} = this.props;

        return (
            <div>
                <p className={'header'}>{controller.modeTitle}</p>
                <div className={'content'}>
                    {controller.translate('REACT.DASHBOARD.MODE_SWITCH_CONFIRMATION.CONTENT', {
                        mode: controller.modeTitle
                    })}
                </div>
            </div>
        );
    }

    renderToolbar() {
        const {controller} = this.props;

        return (
            <ButtonsToolbar>
                <SecondaryButton onClick={controller.modeSwitchRejected} className={'custom-css-class'}>
                    <strong>{controller.translate('REACT.COMMON.BUTTONS.CANCEL')}</strong>
                </SecondaryButton>
                <PrimaryButton onClick={controller.modeSwitchConfirmed} className={'custom-css-class'}>
                    <strong>{controller.translate('REACT.COMMON.BUTTONS.SWITCH')}</strong>
                </PrimaryButton>
            </ButtonsToolbar>
        );
    }

    renderInternal() {
        const {controller} = this.props;

        return (
            <GenericModal
                className={'mode-switch-confirmation-modal'}
                header={this.renderHeader}
                content={this.renderContent}
                toolbar={this.renderToolbar}
                onHideHandler={controller.hide}
                isCloseVisible={false}
                closeOnEscape={false}
                show={controller.isVisible}></GenericModal>
        );
    }
}
