'use strict';

/**
 * This directive handles proper highlighting of form elements using Bootstrap validation states
 *
 * Reference:
 * http://getbootstrap.com/css/#forms-control-validation
 */

class HighlightControlController {
    constructor($element, $attrs, $scope, $timeout) {
        'ngInject';

        this.$element = $element;
        this.$timeout = $timeout;

        // Load options:
        // `ignoreEmpty` - {boolean} Don't highlight when the control is empty
        //
        // e.g. `<input type="text" highlight-control="{ ignoreEmpty: true }"`
        this.options = $scope.$eval($attrs.highlightControl, $scope) || {};
    }

    /**
     * Determine if an input is in error state
     * - Invalid and has been changed
     * - Invalid and the form was submitted
     *
     * @param  {Object} form The form object reference
     * @param  {string} name The input `name` attribute value
     * @return {boolean}     To highlight or not
     */
    isError(form, name) {
        return form[name] && form[name].$invalid && (form[name].$dirty || form.$submitted);
    }

    /**
     * Determine if an input is in success state
     * - Valid and has been changed
     *
     * @param  {Object} form The form object reference
     * @param  {string} name The input `name` attribute value
     * @return {boolean}     To highlight or not
     */
    isSuccess(form, name) {
        return !form[name] || (!form[name].$invalid && form[name].$dirty);
    }

    /**
     * Toggle control highlight
     *
     * @param  {Object} formCtrl          The form object reference
     * @param  {string} targetControlName The input `name` attribute value
     */
    toggleHighlight(formCtrl, targetControlName) {
        const isError = this.isError(formCtrl, targetControlName);
        let isSuccess = this.isSuccess(formCtrl, targetControlName);

        // If `ignoreEmpty` set, don't highlight when the control is empty
        if (this.options.ignoreEmpty && !formCtrl[targetControlName].$viewValue) {
            isSuccess = false;
        }

        // Make sure the form has been updated properly
        this.$timeout(() => {
            // Don't highlight right after the form was saved
            if (formCtrl.$pristine) {
                isSuccess = false;
            }

            this.$element.toggleClass('has-error', !!isError);
            this.$element.toggleClass('has-success', !!isSuccess);
        }, 20);
    }
}

function linkFn(scope, element, attrs, ctrl) {
    const selfCtrl = ctrl[0];
    const formCtrl = ctrl[1];
    const formElm = element.parents('form[name="' + formCtrl.$name + '"]');

    // Find the target control that we should check
    const targetControl = element.find('.form-control[name]');
    const targetControlName = targetControl[0] && targetControl[0].name;

    if (!targetControl.length || !targetControlName) {
        throw "Can't find a child with `form-control` class and a `name` attribute";
    }

    // Watch target control view value and `$invalid` changes
    scope.$watchGroup([() => formCtrl[targetControlName].$viewValue, () => formCtrl[targetControlName].$invalid], () =>
        selfCtrl.toggleHighlight(formCtrl, targetControlName)
    );

    // On form submit, trigger highlight
    const submitHandler = () => selfCtrl.toggleHighlight(formCtrl, targetControlName);

    formElm.on('submit', submitHandler);

    scope.$on('$destroy', () => {
        formElm.off('submit', submitHandler);
    });
}

export function highlightControlDirective() {
    return {
        restrict: 'A',
        scope: {},
        controller: HighlightControlController,
        controllerAs: 'vm',
        require: ['highlightControl', '^form'],
        link: linkFn
    };
}
