'use strict';
import tsEndMeetingView from './ts-end-meeting.view.html';
import './ts-end-meeting.style.scss';

export function tsEndMeeting() {
    return {
        template: tsEndMeetingView,
        scope: true
    };
}
