'use strict';

import {DIALOG_TYPES} from './generic-dialog.settings';
import './generic-dialog.style.scss';

export class GenericDialogController {
    constructor($modalInstance, message, dir, dialogType) {
        'ngInject';

        this.$modalInstance = $modalInstance;
        this.message = message;
        this.dir = dir;
        this.dialogType = dialogType;
        this._setBtnLabels();
    }

    _isBandwithDialog() {
        return this.dialogType === DIALOG_TYPES.BANDWITH;
    }

    _isPhotostreamBlockedDialog() {
        return this.dialogType === DIALOG_TYPES.PHOTOSTREAM_BLOCKED;
    }

    _setBtnLabels() {
        if (this._isBandwithDialog()) {
            this.firstBtnLabel = 'BANDWIDTH_DIALOG.SWITCH_TO_IMAGE_UPLOAD';
            this.secondBtnLabel = 'BANDWIDTH_DIALOG.CONTINUE_WITH_LIVE_VIDEO';
        } else if (this._isPhotostreamBlockedDialog()) {
            this.firstBtnLabel = 'IMAGE_UPLOAD_BLOCKED_DIALOG.RESTART_SESSION';
            this.secondBtnLabel = 'IMAGE_UPLOAD_BLOCKED_DIALOG.END_SESSION';
        }
    }

    _switchToPhotoStream(isSwitched) {
        this.$modalInstance.close({switchToPhotoStream: isSwitched});
    }

    _restartSession(isRestart) {
        this.$modalInstance.close({restartSession: isRestart});
    }

    submitResult(result) {
        if (this._isBandwithDialog()) {
            this._switchToPhotoStream(result);
        } else if (this._isPhotostreamBlockedDialog()) {
            this._restartSession(result);
        }
    }
}
