import {inject, IReactComponent} from 'mobx-react';
import {VJHistoryFilter} from './VJHistoryFilter';

import './styles.scss';

const mapStoresToProps = (stores: any) => ({
    ctrl: stores.vjHistoryFilterController,
    translate: stores.localizationService.translate.bind(stores.localizationService)
});

const VJHistoryFilterComponent = inject(mapStoresToProps)(VJHistoryFilter) as IReactComponent;

export default VJHistoryFilterComponent;
