export interface IAgentPerformanceData {
    average: number;
    month: number;
    monthlyGoal: number;
    today: number;
    userCount: number;
    week: number;
    groupName: string;
    elementsToDisplay: string[];
    showCustomerSummary: boolean;
    showAgentSummary: boolean;
    userRating: number;
    groupRating: number;
    questions: {percent: number; question: string}[];
}

export const INTERVAL_LOAD_DATA = 600000;
