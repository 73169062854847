'use strict';

export function tsBaseName() {
    /**
     * [return description]
     * @param  {String} path        Path that we will split
     * @return {String}             The array in reverse
     */
    return (path) => {
        if (path) {
            return path.split('?')[0].substring(path.lastIndexOf('/') + 1);
        }

        return [];
    };
}
