import LoginView from './login-root.view.html';
import LoginRootController from './login-root.controller';
import LoginNewModule from '../../_react_/states/login-new/login.module';
import LoginOldModule from './login-old/login.module';

const loginRootView = {
    template: LoginView,
    controller: LoginRootController,
    controllerAs: 'loginRoot'
};

export default angular.module('states.login', [LoginNewModule.name, LoginOldModule.name]).config(config);

function config($stateProvider) {
    'ngInject';

    $stateProvider.state('login', {
        url:
            ROUTE_BASE_PATH +
            'login?authId&r&p&c&is&roomId&g&roomCode&nl&ott&audio&observe&emb&mini&tablet&sw&prg&startDate&endDate',
        params: {
            multiUser: null
        },
        views: {
            'desktop-view': loginRootView,
            'mobile-view': loginRootView
        }
    });
}
