import includes from 'lodash/includes';
import {AppState} from '../app.state';
import {IAuthService, IDbSystemInfo} from './AngularServices/AngularServices';

export interface ILoginError {
    message: string;
    payload?: any;
}

export interface IApplicationService {
    logout(): Promise<any>;

    login(username: string, password: string, confirmLogin?: boolean): Promise<void>;

    loginSubUser(accountId: string): Promise<void>;

    isAgentInRole(roles: string | string[]): boolean;
}

export class ApplicationService implements IApplicationService {
    private _appState: AppState;

    private _authService: IAuthService;

    private _systemInfoService: IDbSystemInfo;

    private _minLoadTime: number;

    private _loginCallback?: Function;

    private _logoutCallback?: Function;

    constructor(
        appState: AppState,
        authService: IAuthService,
        systemInfoService: IDbSystemInfo,
        loginCallback?: Function,
        logoutCallback?: Function
    ) {
        this._appState = appState;
        this._authService = authService;
        this._systemInfoService = systemInfoService;
        this._minLoadTime = 250;
        this._loginCallback = loginCallback;
        this._logoutCallback = logoutCallback;
    }

    logout(): Promise<any> {
        //TODO: Alex -> Update appState with logged out user

        if (this._logoutCallback) {
            this._logoutCallback(this._appState.accountId, this._appState.agentId);
        }

        return this._authService.logout(this._appState.accountId, this._appState.agentId);
    }

    isAgentInRole(roles: string | string[]) {
        if (!this._appState.agentRole) {
            return false;
        }

        if (roles && roles.length) {
            return includes(roles, this._appState.agentRole);
        }

        return roles === this._appState.agentRole;
    }

    login(username: string, password: string, confirmLogin: boolean): Promise<void> {
        const reqTime = Date.now();

        return this._authService
            .login(username, password, confirmLogin)
            .then(() => this._onLoginSuccess())
            .catch((err: any) => this._onLoginFail(err, reqTime));
    }

    loginSubUser(accountId: string): Promise<void> {
        const reqTime = Date.now();

        return this._authService
            .loginSubUser(accountId)
            .then(() => this._onLoginSuccess())
            .catch((err: any) => this._onLoginFail(err, reqTime));
    }

    _onLoginSuccess(): Promise<void> {
        return new Promise((resolve) => {
            //TODO: Alex -> Update appState with logged in user, account data and so on

            if (this._loginCallback) {
                this._loginCallback();
            }

            resolve();
        });
    }

    _onLoginFail(err: any, reqTime: number): Promise<void> {
        return new Promise((resolve, reject) => {
            const sleep = Math.max(0, this._minLoadTime - (Date.now() - reqTime));
            const errorObj: ILoginError = {
                message: '',
                payload: null
            };

            setTimeout(() => {
                if (err.status === 401) {
                    // Login failed, wrong userName or password<br>Please try again
                    errorObj.message = 'LOGIN.CONTROLLER.PLEASE_LOGIN';
                }

                if (err.status === 403 && err.data.confirmLogin) {
                    errorObj.message = '';
                    errorObj.payload = {confirmLogin: true};
                }

                if (err.status === 403 && !err.data.confirmLogin) {
                    errorObj.message = 'LOGIN.CONTROLLER.NOT_ALLOWED';
                }

                if (err.status === 429) {
                    errorObj.message = 'LOGIN.CONTROLLER.TOO_MANY_LOGINS';
                }

                if (err.status === 500 || err.status === 502 || err.status === -1) {
                    errorObj.message = 'LOGIN.CONTROLLER.BAD_GATEWAY';
                }

                reject(errorObj);
            }, sleep);
        });
    }

    // //Currently not in use, but this is an example on how to change language in app.
    // setCurrentLanguage(language: string): void {
    //     const cb = action(() => this._appState.dashboardLanguage = language);
    //     this._localizationService.changeLanguage(language).then(cb);
    // }
}
