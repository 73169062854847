import React from 'react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {MiniDashboardLayout} from './miniDashboardLayout/MiniDashboardLayout';
import {inject, IReactComponent, observer} from 'mobx-react';
import {MultipartyDashboardController} from '../../MultipartyDashboardController';
import {LiveDashboardLayout} from './liveDashboardLayout/LiveDashboardLayout';

type MultipartyDashboardLayoutProps = {
    controller: MultipartyDashboardController;
};

@observer
export class MultipartyDashboardLayout extends TechseeBaseComponent<MultipartyDashboardLayoutProps> {
    renderInternal(): JSX.Element {
        const {controller} = this.props;

        return controller.miniDashboard ? (
            <MiniDashboardLayout controller={controller} />
        ) : (
            <LiveDashboardLayout controller={controller} />
        );
    }
}

const mapStoresToProps = (stores: any) => ({
    controller: stores.dashboardController
});

export const DashboardContainer = inject(mapStoresToProps)(MultipartyDashboardLayout) as IReactComponent;
