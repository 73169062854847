import {IApplicationService} from '../../../services/ApplicationService';
import {IDbUser} from '../../../services/AngularServices/AngularServices';
import {computed, observable} from 'mobx';
import {IUserAccount} from '../../../states/login-new/components/login-form/controller';

import './styles.scss';

export interface IAccountSwitchController {
    onSwitchUserClick(accountId: string): void;

    accounts: IUserAccount[];
    companyName: string;
    shortCompanyName: string;
}

export class AccountSwitchController implements IAccountSwitchController {
    @observable private _accounts: IUserAccount[] = [];

    constructor(
        private _appService: IApplicationService,
        private _currentUser: any,
        private _companyName: string,
        private _dbUserService: IDbUser
    ) {
        this.onSwitchUserClick = this.onSwitchUserClick.bind(this);

        this._initSubUsers();
    }

    _initSubUsers() {
        if (!this._currentUser.parentSubUserId) {
            return;
        }

        this._dbUserService.find(this._currentUser.parentSubUserId).then((user) => {
            if (!user.accounts) {
                return;
            }

            this._accounts = user.accounts;
        });
    }

    onSwitchUserClick(accountId: string): void {
        this._appService.loginSubUser(accountId).then(() => {
            window.location.reload();
        });
    }

    @computed
    get accounts(): IUserAccount[] {
        return this._accounts;
    }

    get companyName(): string {
        return this._companyName;
    }

    get shortCompanyName(): string {
        const words = this.companyName.split(/[\s\-]+/g);

        let shortName = this.companyName.substring(0, 3);

        if (words.length === 2) {
            shortName = words[0].substring(0, 1) + words[1].substring(0, 2);
        }

        if (words.length > 2) {
            shortName = words[0].substring(0, 1) + words[1].substring(0, 1) + words[2].substring(0, 1);
        }

        return shortName.toUpperCase();
    }
}
