'use strict';
import moment from 'moment/min/moment-with-locales.min.js';

/**
 * [return description]
 * @param  {Date|String|Number} date        Date to format
 * @param  {String} format                  Moment formatting rules, e.g. 'DD-MM-YYYY'
 * @return {String}                         Formatted date
 */

export function localeDate(tsLocaleHelper) {
    return function (date = '', format = 'MMMM D, Y h:mm A') {
        const locale = tsLocaleHelper.getLocale();

        return moment(date).locale(locale).format(format);
    };
}
