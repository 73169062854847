import React from 'react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {DefaultStickyToggle} from '@techsee/techsee-ui-common/lib/sticky-slide-panel/default-toggle/default-toggles';
import StickySlidePanel from '@techsee/techsee-ui-common/lib/sticky-slide-panel';

import './styles.scss';

export interface IRobotHelper {
    children: React.ReactNode | string;
}

export default class RobotHelper extends TechseeBaseComponent<IRobotHelper> {
    renderInternal() {
        const {children} = this.props;

        return (
            <StickySlidePanel
                initialyOpened={true}
                autoClose={false}
                openComponent={DefaultStickyToggle}
                closeComponent={DefaultStickyToggle}>
                <div className='robot-helper-message'>{children}</div>
            </StickySlidePanel>
        );
    }
}
