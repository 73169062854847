'use strict';

import changePasswordView from './change-password.view.html';
import {ChangePasswordController} from './change-password.controller.js';
import './change-password.style.scss';

function config($stateProvider) {
    $stateProvider.state('changePassword', {
        url: '/changePassword',
        views: {
            'desktop-view': {
                template: changePasswordView,
                controller: 'ChangePasswordController',
                controllerAs: 'changePassword'
            },
            'mobile-view': {
                template: changePasswordView,
                controller: 'ChangePasswordController',
                controllerAs: 'changePassword'
            }
        },
        resolve: {
            currentUser: (db, tsStateHelper) =>
                db.User.find('current')
                    .then((user) => user)
                    .catch(() => {
                        tsStateHelper.safeGo('login');
                    }),
            accountData: (db, currentUser) => {
                if (!currentUser || !currentUser.accountId) {
                    return [];
                }

                return db.Account.find(currentUser.accountId, {bypassCache: true});
            }
        }
    });
}

export default angular
    .module('states.changePassword', [])
    .config(config)
    .controller('ChangePasswordController', ChangePasswordController);
