'use strict';

import smsStatusNewUiView from './sms-status-new-ui.view.html';
import {SmsStatusNewUiController} from './sms-status-new-ui.controller';
import {getRootStore} from '../../../../_react_/app.bootstrap';

import './sms-status-new-ui.style.scss';

export class SmsStatusNewUi {
    constructor($modal) {
        'ngInject';

        this.$modal = $modal;
        this.instance = null;
        this.environmentDetect = getRootStore().environmentService;
        this.isMobile = this.environmentDetect.isMobile(getRootStore().displayTabletAsDesktop);
    }

    show(title, message, dir) {
        this.instance = this.$modal.open({
            animation: true,
            template: smsStatusNewUiView,
            controller: SmsStatusNewUiController,
            controllerAs: 'vm',
            backdrop: 'static',
            backdropClass: 'backdrop-hidden',
            windowClass: 'on-new-ui-sms-status' + (this.isMobile ? ' mobile-modal' : ''),
            resolve: {
                title: () => title,
                message: () => message,
                dir: () => dir
            }
        });

        return this.instance;
    }

    hide() {
        this.instance.close();
    }
}
