/* eslint-disable init-declarations */
// @ts-ignore
import ConnectivityCheckView from './connectivity-check.view.html';
import './connectivity-check.style.scss';
import {getRootStore} from '../../app.bootstrap';

class ConnectivityController {
    private _accountId: string;

    private _stateHelper: any;

    private _auth: any;

    public isIE: boolean;

    private _username: string;

    static $inject = ['accountData', '$localStorage', 'tsStateHelper', 'currentUser', 'auth'];

    constructor(accountData: any, tsStateHelper: any, currentUser: any, auth: any) {
        this._accountId = accountData._id;
        this._stateHelper = tsStateHelper;
        this._username = currentUser.userName;
        this._auth = auth;
        this.isIE = getRootStore().environmentService.isIE11();
    }

    getIframeSrc() {
        const token = this._auth.getAuthToken();

        return `/connectivity.html#accountId=${this._accountId}&token=${token}&username=${this._username}`;
    }

    goToInvite() {
        this._stateHelper.safeGo('invite.old');
    }
}

declare const angular: any;

declare const ROUTE_BASE_PATH: string;

export default angular
    .module('states.connectivityCheck', [])
    .config(['$stateProvider', config])
    .controller('ConnectivityController', ConnectivityController);

function config($stateProvider: any) {
    $stateProvider.state('invite.old.connectivity', {
        url: '^' + ROUTE_BASE_PATH + 'connectivityCheck',
        template: ConnectivityCheckView,
        controller: 'ConnectivityController',
        controllerAs: 'connectivity'
    });
}
