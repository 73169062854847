'use strict';

import tsBadgedImageView from './ts-badged-image.view.html';
import './ts-badged-image.style.scss';

/*
 * Displays image with optional badge on it
 */
class TsBadgedContentController {
    isBadgeVisible() {
        return angular.isUndefined(this.displayBadge) || this.displayBadge;
    }

    imgWrapperStyle() {
        return {
            width: (this.width || 0) + 'px',
            height: (this.height || 0) + 'px'
        };
    }

    imgStyle() {
        const style = {};

        if (parseInt(this.height, 10) >= parseInt(this.width, 10)) {
            style.height = (this.height || 0) + 'px';
        } else {
            style.width = (this.width || 0) + 'px';
        }

        return style;
    }

    isBorderVisible() {
        return angular.isUndefined(this.displayBorder) || this.displayBorder;
    }

    imageLoaded(event) {
        if (this.onImageLoaded) {
            this.onImageLoaded({event: event});
        }
    }
}

export function tsBadgedImageDirective() {
    /*
        Scope params:
        isActive - Indicates "active" styling should be applied to image
        displayBadge - Indicates if badge should be displayed
        badgeValue - The value that will be displayed in badge
        width - the visible width of image (without 'px")
        height - the visible height of image (without 'px")
        url - the URL where to load image from.
    */
    return {
        restrict: 'EA',
        replace: true,
        scope: {},
        controller: TsBadgedContentController,
        controllerAs: 'vm',
        bindToController: {
            isActive: '=',
            displayBadge: '=',
            displayBorder: '=',
            badgeValue: '@',
            width: '@',
            height: '@',
            url: '@',
            onImageLoaded: '&'
        },
        template: tsBadgedImageView
    };
}
