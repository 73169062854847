'use strict';

import get from 'lodash/get';
import assign from 'lodash/assign';
import dashboardView from './dashboard.view.html';
import dashboardNewView from './dashboard-new.view.html';
import dashboardMobileView from './dashboard-mobile.view.html';
import dashboardMiniView from './dashboard-mini.view.html';
import dashboardMiniMobileView from './dashboard-mini-mobile.view.html';
import {DashboardController} from './dashboard.controller.js';
import {ErrorDialogModal} from './main/dialogs/error-dialog.service';
import {GenericDialogFactory} from '../../services/generic-dialog/generic-dialog.service';
import {AddressTypesEnum} from '@techsee/techsee-common/lib/constants/room.constants';
import {MEDIA_SERVICE} from './dashboard.settings';

import './dashboard.style.scss';
import './dashboard-mobile.style.scss';
import './dashboard-mini-mobile.style.scss';

// Sub states
import entryModule from './entry/entry.module.js';
import redirectToStoreModule from './redirectToStoreGuidance/redirectToStore.module';
import mainModule from './main/main.module.js';
import {getRootStore} from '../../_react_/app.bootstrap';
import {PlatformType} from '@techsee/techsee-common/lib/constants/utils.constant';
import {DashboardState} from '../../_react_/states/dashboard/_contracts/DashboardState';
import {runInAction} from 'mobx';
import {ConnectionTypesEnum} from '../../_react_/services/Session/SessionContracts';
import {DashboardAppMediaService} from '../../services/media-service-api/DashboardAppMediaService';
import {MessagesService} from '../../services/ts-messages-service/MessagesService.service';
import {
    DEFAULT_PEER_CONNECTIVITY_TIMEOUT_SECONDS,
    DEFAULT_VIDEO_WAIT_TIMEOUT_SECONDS,
    SessionClientRole
} from '@techsee/techsee-media-service/lib/MediaConstants';
import utils from '@techsee/techsee-common/lib/utils';

import {getAppTracer} from '../../app.tracer';
import {AudioService} from '../../services/ts-audio/AudioService';
import {getDashboardLanguage, setDashboardLanguage} from '../../_react_/states/helper/general.helper';
import {AutoDataCollectionServices} from '@techsee/techsee-client-services/lib/services/AIAutoCollectionServices';
const trace = getAppTracer('DashboardModule');

function config($stateProvider) {
    $stateProvider.state('dashboard', {
        url:
            ROUTE_BASE_PATH +
            'dashboard?room&roomCode&platform&audio&observe&r&p&c&cid&offline&loadImage&loadVideo&returnQuery&history&sf&sw&mini&emb',
        views: {
            'desktop-view': {
                template: dashboardView + dashboardNewView + dashboardMiniView,
                controller: 'DashboardController',
                controllerAs: 'dashboard'
            },
            'mobile-view': {
                template: dashboardMobileView + dashboardMiniMobileView,
                controller: 'DashboardController',
                controllerAs: 'dashboard'
            }
        },
        resolve: {
            embeddedInfo: (tsUrlUtils) => {
                const browserUtilsService = getRootStore().browserUtilsService;

                const isMiniDashboardSessionStorage = browserUtilsService.getFromSessionStorage('miniDashboard');
                const isEmbeddedDashboardSessionStorage =
                    browserUtilsService.getFromSessionStorage('embeddedDashboard');

                const isMiniDashboard = isMiniDashboardSessionStorage || tsUrlUtils.getParamValue('mini');
                const isEmbeddedDashboard = isEmbeddedDashboardSessionStorage || tsUrlUtils.getParamValue('mini');

                if (!isMiniDashboardSessionStorage && isMiniDashboard) {
                    browserUtilsService.saveToSessionStorage('miniDashboard', true);
                }

                if (!isEmbeddedDashboardSessionStorage && isEmbeddedDashboard) {
                    browserUtilsService.saveToSessionStorage('embeddedDashboard', true);
                }

                return {isMiniDashboard, isEmbeddedDashboard};
            },
            // Expose the current user data
            currentUser: (tsStateHelper, $q, db, eventLog, tsUrlUtils, embeddedInfo) => {
                const roomHint = tsUrlUtils.getParamValue('room');
                const {isMiniDashboard, isEmbeddedDashboard} = embeddedInfo;

                eventLog.dashboardModuleLoaded({roomHint});

                return (
                    db.User.find('current')
                        // If not logged-in, redirect to login
                        .catch((err) => {
                            trace.error(
                                'Dashboard.module failed - currentUser: ' +
                                    JSON.stringify({
                                        isMinidashboard: isMiniDashboard,
                                        isEmbeddedDashboard: isEmbeddedDashboard
                                    }),
                                err
                            );

                            tsStateHelper.safeGo(isEmbeddedDashboard ? 'embeddedDashboardGeneralError' : 'login');

                            // Reject to prevent this state from loading
                            return $q.reject(err);
                        })
                );
            },
            // Expose the associated user account data, if he is associated
            accountData: (db, currentUser) => {
                if (!currentUser.accountId) {
                    return [];
                }

                return db.Account.find(currentUser.accountId, {bypassCache: true});
            },
            verboseLogs: (accountData, tsExtraLoggingService, eventLog) => {
                tsExtraLoggingService.init(accountData, eventLog);
            },
            roomData: (
                tsStateHelper,
                $q,
                tsUrlUtils,
                db,
                currentUser,
                ROLES,
                accountData,
                tsSessionData,
                qos,
                eventLog,
                embeddedInfo
            ) => {
                let roomId = tsUrlUtils.getParamValue('room');

                const simpleAPIRoomId = tsUrlUtils.getParamValue('r'),
                    simpleAPIMode = simpleAPIRoomId || tsUrlUtils.getParamValue('p') || tsUrlUtils.getParamValue('cid'),
                    loadedFromHistory = tsUrlUtils.getParamValue('history'),
                    libraryTaggingMode =
                        currentUser.role === ROLES.KNOWLEDGE_EXPERT &&
                        !accountData.protectedSettings.knowledgeExpertRunSessions,
                    byShortId = !accountData.protectedSettings.showRoomIdInUrl && !loadedFromHistory;

                if (libraryTaggingMode) {
                    return {};
                }

                if (loadedFromHistory) {
                    return tsSessionData
                        .getValue('roomId')
                        .then((savedRoomId) => {
                            roomId = savedRoomId;

                            return db.Rooms.find(roomId || simpleAPIRoomId, {
                                params: {
                                    byShortId: byShortId,
                                    extendedAccess: true
                                }
                            }).then((room) => {
                                eventLog.room = get(room, '_id');

                                return room;
                            });
                        })
                        .catch((err) => {
                            if (simpleAPIMode) {
                                return $q.resolve(err);
                            }
                            const {isMiniDashboard, isEmbeddedDashboard} = embeddedInfo;

                            trace.error(
                                'Dashboard.module failed - history - roomData: ' +
                                    JSON.stringify({
                                        isMinidashboard: isMiniDashboard,
                                        isEmbeddedDashboard: isEmbeddedDashboard
                                    }),
                                err
                            );

                            tsStateHelper.safeGo(isEmbeddedDashboard ? 'embeddedDashboardGeneralError' : 'invite');

                            return $q.reject(err);
                        });
                }

                return db.Rooms.find(roomId || simpleAPIRoomId, {params: {byShortId: byShortId}})
                    .then((room) => {
                        eventLog.room = get(room, '_id');

                        if (ENABLE_QOS) {
                            qos.init(accountData, currentUser, room);
                        }

                        return room;
                    })
                    .catch((err) => {
                        if (simpleAPIMode) {
                            return $q.resolve(err);
                        }

                        const {isMiniDashboard, isEmbeddedDashboard} = embeddedInfo;

                        trace.error(
                            'Dashboard.module failed - roomData: ' +
                                JSON.stringify({
                                    isMinidashboard: isMiniDashboard,
                                    isEmbeddedDashboard: isEmbeddedDashboard
                                }),
                            err
                        );

                        tsStateHelper.safeGo(isEmbeddedDashboard ? 'embeddedDashboardGeneralError' : 'invite');

                        return $q.reject(err);
                    });
            },
            localLanguage: (accountData, tsLocaleHelper, tsTranslationHelper, roomData) => {
                const accountLanguage = getDashboardLanguage(accountData, roomData);

                setDashboardLanguage(accountLanguage);

                return tsTranslationHelper
                    .storeAccountCustomStrings(accountData._id)
                    .then(() => tsLocaleHelper.loadLocale(accountLanguage, accountData.companyName));
            },
            meetingHistory: () => [],
            wizardState: (accountData, currentUser, tsUrlUtils, tsWizardHelper, roomData) => {
                const roomId = tsUrlUtils.getParamValue('room'),
                    wizardState = tsWizardHelper.getState(roomId),
                    offlineMode = tsUrlUtils.getParamValue('offline'),
                    environmentDetect = getRootStore().environmentService,
                    displayTabletAsDesktop = getRootStore().displayTabletAsDesktop;

                wizardState.showWizard =
                    accountData.settings &&
                    accountData.settings.showWizard &&
                    !environmentDetect.isMobile(displayTabletAsDesktop) &&
                    currentUser.showWizard &&
                    !offlineMode &&
                    !roomData.offline;

                return wizardState;
            },
            preloadedImage: (db, tsUrlUtils, currentUser, ROLES) => {
                const resourceId = tsUrlUtils.getParamValue('loadImage'),
                    libraryTaggingMode = currentUser.role === ROLES.KNOWLEDGE_EXPERT;

                if (!resourceId) {
                    return {};
                }

                if (libraryTaggingMode) {
                    return db.Resource.resign(resourceId, {
                        bypassCache: true
                    })
                        .then((resp) => ({resource: resp.data}))
                        .catch((error) => ({error}));
                }

                return db.HistoryImage.resign(resourceId, {
                    bypassCache: true
                })
                    .then((resp) => ({resource: resp.data}))
                    .catch((error) => ({error}));
            },
            preloadedVideo: (db, tsUrlUtils, currentUser, ROLES) => {
                const resourceId = tsUrlUtils.getParamValue('loadVideo'),
                    libraryTaggingMode = currentUser.role === ROLES.KNOWLEDGE_EXPERT;

                if (!resourceId) {
                    return {};
                }

                if (libraryTaggingMode) {
                    return db.Resource.resign(resourceId, {
                        bypassCache: true
                    })
                        .then((resp) => ({resource: resp.data}))
                        .catch((error) => ({error}));
                }

                return db.HistoryVideo.resign(resourceId, {
                    bypassCache: true
                })
                    .then((resp) => {
                        if (resp && resp.data) {
                            resp.data.isVideo = true;
                            resp.data.video = resp.data.url;
                        }

                        return {resource: resp.data};
                    })
                    .catch((error) => ({error}));
            },
            allowScreenShare: (accountData, roomData) =>
                get(accountData, 'protectedSettings.liveSdk.enabled') &&
                utils.isModeSupportNativeVideoSharing(roomData.startWithAgentType),
            mediaServiceType: (accountData, roomData) =>
                get(roomData, 'mediaServiceType') || get(accountData, 'protectedSettings.mediaServiceType'),
            loadOpentok: ($rootScope, accountData, mediaServiceType) => {
                if (mediaServiceType === MEDIA_SERVICE.OPENTOK && !getRootStore().environmentService.isIE11()) {
                    const opentokVersion = get(accountData, 'protectedSettings.opentokVersion');

                    return $rootScope.includeJs(`/js/opentok.${opentokVersion}.js`);
                }

                return null;
            },
            localizationService: (accountData, roomData) => {
                'ngInject';

                const service = getRootStore().localizationService;
                const accountLanguage = getDashboardLanguage(accountData, roomData);

                service.setAccountData(accountData._id, accountLanguage);

                return service.init();
            },
            messagesService: (tsChatApi) => new MessagesService(tsChatApi),

            // Do not remove roomData, it's there to ensure resolve order
            webRTCSupport: (eventLog, tsChatApi) => {
                'ngInject';

                return getRootStore()
                    .webRtcDetector.getWebRtcInfo()
                    .then((webRTCSupportResult) => {
                        tsChatApi.service.setWebRTCSupport(webRTCSupportResult);
                        eventLog.webRTCSupported(assign(webRTCSupportResult, {side: PlatformType.dashboard}));

                        return webRTCSupportResult;
                    });
            },
            dashboardMediaService: (webRTCSupport, mediaServiceType, accountData, tsUrlUtils) => {
                trace.info('Init dashboardMediaService');
                const environmentDetect = getRootStore().environmentService;
                const service = new DashboardAppMediaService(environmentDetect, webRTCSupport);

                return service
                    .initMediaService({
                        clientRole: tsUrlUtils.getParamValue('observe')
                            ? SessionClientRole.OBSERVER
                            : SessionClientRole.AGENT,
                        mediaServiceType,
                        peerConnectivityTimeoutSeconds: DEFAULT_PEER_CONNECTIVITY_TIMEOUT_SECONDS,
                        videoNotReceivedTimeoutSeconds:
                            get(accountData, 'protectedSettings.videoWaitTimeoutSeconds') ||
                            DEFAULT_VIDEO_WAIT_TIMEOUT_SECONDS
                    })
                    .then(() => service);
            },
            dashboardState: (accountData, roomData, tsUrlUtils) => {
                const dashboardState = new DashboardState();

                runInAction(() => {
                    //TODO - Alex: Need to find how to set mode when entering dashboard module
                    //dashboardState.sessionInfo.meetingMode = roomData.mode

                    //TODO - Alex: Need to see how to set sessionId correctly here
                    //dashboardState.sessionInfo.sessionId = tsUrlUtils.getParamValue('room');

                    dashboardState.sessionInfo.sessionRoomId = roomData._id;
                    dashboardState.sessionInfo.sessionLink = roomData.sentURL;
                    dashboardState.sessionInfo.isSessionActive = true;
                    dashboardState.sessionInfo.isOffline = roomData.offline;
                    dashboardState.sessionInfo.clientJoinedSession = !!roomData.clientDeviceBrowser;

                    dashboardState.clientMobileType = roomData.clientDevice;
                    dashboardState.clientMobileBrowser = roomData.clientDeviceBrowser;
                    dashboardState.clientMobileOsName = get(roomData, 'clientOsObject.name');
                    dashboardState.clientMobileOsVersion = get(roomData, 'clientOsObject.version');
                    dashboardState.inviteMethodInfo.customerRef = roomData.customerId;
                    dashboardState.inviteMethodInfo.addressType =
                        roomData.customerEmail !== 'none' ? AddressTypesEnum.EMAIL : AddressTypesEnum.SMS;
                    dashboardState.inviteMethodInfo.address =
                        roomData.customerEmail !== 'none' ? roomData.customerEmail : roomData.customerNumber;
                    dashboardState.inviteMethodInfo.clientLanguage = roomData.clientLanguage;

                    dashboardState.clientNetworkInfo.connectionType = get(roomData, 'mobileNetworkInfo.connectionType')
                        ? get(roomData, 'mobileNetworkInfo.connectionType').toUpperCase()
                        : ConnectionTypesEnum.NETWORK;

                    dashboardState.clientNetworkInfo.downlinkMax = get(roomData, 'mobileNetworkInfo.downlinkMax') || 0;
                    dashboardState.isOfflineRoom = roomData.offline && tsUrlUtils.getParamValue('offline') !== 'true';
                    dashboardState.isOfflineMode = tsUrlUtils.getParamValue('offline') === 'true';
                    dashboardState.isObserve = tsUrlUtils.getParamValue('observe');
                });

                return dashboardState;
            },
            AudioService: (
                accountData,
                tsChatApi,
                eventLog,
                dashboardMediaService,
                tsUrlUtils,
                mediaServiceType,
                roomData,
                currentUser,
                db
            ) => {
                const environmentDetect = getRootStore().environmentService;
                const translate = getRootStore().localizationService.translate;
                const isObserveUser = tsUrlUtils.getParamValue('observe');
                let isAudioEnabledForCurrentRoom =
                    roomData.initiateWithAudio || tsUrlUtils.getParamValue('audio') === 'yes';
                const enableAudioForObserver =
                    mediaServiceType === MEDIA_SERVICE.MEDIASERVER || mediaServiceType === MEDIA_SERVICE.OPENTOK;

                if ((!enableAudioForObserver && isObserveUser) || environmentDetect.isIE11()) {
                    isAudioEnabledForCurrentRoom = false;
                }

                return new AudioService(
                    accountData,
                    tsChatApi,
                    eventLog,
                    translate,
                    dashboardMediaService,
                    isAudioEnabledForCurrentRoom,
                    isObserveUser ? currentUser._id : '',
                    db.Rooms.muteAudio,
                    enableAudioForObserver
                );
            },
            SharingService: (tsSharingService, roomData) => {
                tsSharingService.init(roomData._id);

                return tsSharingService;
            },
            AutoDataCollectionServices: () => new AutoDataCollectionServices()
        },
        onExit: (dashboardMediaService, tsChatApi, messagesService) => {
            'ngInject';

            const disposePromises = [
                tsChatApi.service.disconnect(),
                dashboardMediaService.dispose(),
                messagesService.dispose()
            ];

            Promise.all([disposePromises])
                .then(() => {
                    trace.info('dashboard.module.onExit success');
                })
                .catch((error) => {
                    trace.warn('Error in dashboard.module.onExit', error);
                });
        }
    });
}

export default angular
    .module('states.dashboard', [entryModule.name, redirectToStoreModule.name, mainModule.name])
    .config(config)
    .controller('DashboardController', DashboardController)
    .service('ErrorDialogModal', ErrorDialogModal)
    .service('GenericDialogFactory', GenericDialogFactory);
