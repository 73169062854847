import {calculateSizeWithRatio, SizeWithRatioParams} from './size-with-ratio';
import {VideoPositioningStrategy, VideoSizeResult} from './abstractions';

export class OldMinimizedVideoStrategy extends VideoPositioningStrategy {
    constructor(window, locationCalc) {
        super();

        this._locationCalc = locationCalc;
        this._window = window;
    }

    getVideoNewSize(videoMeta) {
        const {maxWidth, maxHeight} = this._getSizeLimits(videoMeta.isPortrait);
        const calcParams = new SizeWithRatioParams(maxWidth, maxHeight, videoMeta.isPortrait, videoMeta.aspectRatio);
        const {newWidth, newHeight} = calculateSizeWithRatio(calcParams);

        return new VideoSizeResult(newWidth, newHeight);
    }

    updateVideoLocation(videoSize, containerEl, videoWasManuallyMoved) {
        super.updateVideoLocation(videoSize, containerEl, videoWasManuallyMoved);

        if (!videoWasManuallyMoved) {
            const {top, left} = this._locationCalc(videoSize);

            containerEl.css({top, left, display: 'flex'});
        }
    }

    _getSizeLimits(isPortrait) {
        const isLargeScreen = this._window.matchMedia('(min-width: 1280px)').matches;

        if (isPortrait) {
            return {
                maxWidth: isLargeScreen ? 128 : 104,
                maxHeight: isLargeScreen ? 190 : 157
            };
        }

        return {
            maxWidth: isLargeScreen ? 190 : 157,
            maxHeight: isLargeScreen ? 128 : 104
        };
    }
}
