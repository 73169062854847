'use strict';

export const colors = [
    {
        name: 'black',
        hexCode: '#000000'
    },
    {
        name: 'gray-20',
        hexCode: '#333333'
    },
    {
        name: 'gray-40',
        hexCode: '#666666'
    },
    {
        name: 'gray-60',
        hexCode: '#999999'
    },
    {
        name: 'gray-80',
        hexCode: '#cccccc'
    },
    {
        name: 'white',
        hexCode: '#ffffff'
    },
    {
        name: 'red',
        hexCode: '#ff0000'
    },
    {
        name: 'green',
        hexCode: '#00ff00'
    },
    {
        name: 'blue',
        hexCode: '#0000ff'
    },
    {
        name: 'yellow',
        hexCode: '#ffff00'
    },
    {
        name: 'cyan',
        hexCode: '#00ffff'
    },
    {
        name: 'magenta',
        hexCode: '#ff00ff'
    }
];

export const tools = {
    HAND: 'hand',
    RECTANGLE: 'rectangle',
    CIRCLE: 'circle',
    ARROW: 'arrow',
    PEN: 'pen',
    TEXT: 'text',
    NUMTAG: 'numtag',
    TEXTTAG: 'texttag',
    ANALYZE: 'analyze'
};
