import {IBrowserUtilsService} from '@techsee/techsee-client-infra/lib/services/BrowserUtilsService';
import {IDbAccount} from '../../_react_/services/AngularServices/AngularServices';
import {ITsEnvironmentDetect} from '@techsee/techsee-common/lib/helpers/ts-environment-detect';

export enum Flavors {
    Default = 'default',
    NewInvite = 'newInvite'
}

export interface IUiFlavorService {
    setLastUsedAlias(alias: string): void;

    isNewFlavorAndSupported(): Promise<boolean>;

    setFlavor(flavor: Flavors): void;
}

export class UiFlavorService implements IUiFlavorService {
    private _dbAccount: IDbAccount;

    private _envService: any;

    private _browserUtils: IBrowserUtilsService;

    private _alias: string = '';

    private _flavorKey: string = 'uiFlavor';

    constructor(browserUtils: IBrowserUtilsService, dbAccount: IDbAccount, environment: ITsEnvironmentDetect) {
        this._browserUtils = browserUtils;
        this._dbAccount = dbAccount;
        this._envService = environment;
    }

    setLastUsedAlias(alias: string) {
        this._alias = alias;
    }

    private _getCurrentFlavor() {
        return new Promise((resolve) => {
            let flavor = this._browserUtils.getFromSessionStorage(this._flavorKey);

            if (flavor) {
                return resolve(flavor);
            }

            if (!this._alias || this._alias === 'login') {
                return resolve(Flavors.Default);
            }

            const checkEnabled = this._dbAccount
                .isNewInviteEnabled({params: {alias: this._alias}})
                .then((result: any) => result && result.data && result.data.enabled)
                .catch(() => resolve(Flavors.Default));

            checkEnabled.then((enabled: any) => {
                flavor = enabled ? Flavors.NewInvite : Flavors.Default;

                this._browserUtils.saveToSessionStorage(this._flavorKey, flavor);

                return resolve(flavor);
            });
        });
    }

    isNewFlavorAndSupported() {
        return this._getCurrentFlavor().then((flavor) => flavor === Flavors.NewInvite);
    }

    setFlavor(flavor: Flavors) {
        if (this._browserUtils.getFromSessionStorage(this._flavorKey) !== flavor) {
            this._browserUtils.saveToSessionStorage(this._flavorKey, flavor);
        }
    }
}
