/* eslint-disable max-classes-per-file */
import {ITranslate} from '../../../../../services/LocalizationService';
import {computed, observable} from 'mobx';
import {onCopyClick} from '@techsee/techsee-common/lib/utils/window-utils';

import {serializable} from 'serializr';
import {Nullable} from '@techsee/techsee-common';
import {DashboardState} from '../../../_contracts/DashboardState';

export class ClientLocationInfo {
    @serializable @observable lat: Nullable<number> = null;

    @serializable @observable lon: Nullable<number> = null;

    @serializable @observable altitude: Nullable<number> = null;

    @serializable @observable accuracy: Nullable<number> = null;

    @serializable @observable formattedAddress: string = '';

    @serializable @observable googleMapsLink: string = '';

    @serializable @observable isDenied: boolean = false;

    @serializable @observable isFailed: boolean = false;
}

export interface ILocationInfoController {
    readonly translate: ITranslate;
    readonly clientLocationInfo: ClientLocationInfo;
    readonly locationInfoTitle: string;
    readonly locationInfoData: string;
    readonly locationInfoCoordsShort: string;

    onCopyClick(str: string): void;
}

export class LocationInfoController implements ILocationInfoController {
    constructor(
        private dashboardState: DashboardState,
        private _translate: ITranslate
    ) {
        this.onCopyClick = this.onCopyClick.bind(this);
    }

    get translate(): ITranslate {
        return this._translate;
    }

    @computed
    get clientLocationInfo() {
        return this.dashboardState.clientLocationInfo;
    }

    @computed
    get locationInfoTitle() {
        if (this.clientLocationInfo.isDenied) {
            return this.translate('REACT.DASHBOARD.VIEW.CLIENT_LOCATION_DENIED');
        }

        if (this.clientLocationInfo.isFailed) {
            return this.translate('REACT.DASHBOARD.VIEW.CLIENT_LOCATION_ERROR');
        }

        if (!this.clientLocationInfo.formattedAddress || !this.clientLocationInfo.lat || !this.clientLocationInfo.lon) {
            return this.translate('REACT.DASHBOARD.VIEW.CLIENT_LOCATION_WAITING');
        }

        return this.translate('REACT.DASHBOARD.VIEW.CLIENT_LOCATION_TITLE');
    }

    @computed
    get locationInfoData() {
        if (this.clientLocationInfo.isDenied || this.clientLocationInfo.isFailed) {
            return '';
        }

        if (this.clientLocationInfo.formattedAddress) {
            return this.clientLocationInfo.formattedAddress;
        }

        if (this.clientLocationInfo.lat && this.clientLocationInfo.lon) {
            return `${this.clientLocationInfo.lat}, ${this.clientLocationInfo.lon}`;
        }

        return '';
    }

    @computed
    get locationInfoCoordsShort() {
        if (this.clientLocationInfo.lat && this.clientLocationInfo.lon) {
            return `${this.clientLocationInfo.lat.toFixed(2)}, ${this.clientLocationInfo.lon.toFixed(2)}`;
        }

        return '–';
    }

    onCopyClick(str: string): void {
        onCopyClick(str);
    }
}
