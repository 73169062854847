'use strict';

import get from 'lodash/get';

import tsLoaderView from './ts-loader.view.html';
import {MeetingMode} from '@techsee/techsee-common/lib/constants/room.constants';
import {getRootStore} from '../../_react_/app.bootstrap';

import './ts-loader.style.scss';

class TsLoaderController {
    /* @ngInject */
    constructor(tsChatApi, tsInterfaceHelper, tsLocaleHelper) {
        this.chatApi = tsChatApi.service;
        this.tsInterfaceHelper = tsInterfaceHelper;
        this.isIE11 = getRootStore().environmentService.isIE11();
        this.dir = tsLocaleHelper.isRTL() ? 'rtl' : 'ltr';
    }

    configureLoaderText() {
        if (!this.chatApi.areBothSidesConnected) {
            return 'MAIN.VIEW.RECOVERING_VIDEO';
        }

        if (get(this.chatApi, 'client.mode') === MeetingMode.screen && !this.tsInterfaceHelper.useNewInterface) {
            return 'MAIN.VIEW.SCREEN_SHARE_LOADER_TEXT';
        }

        return 'MAIN.VIEW.LOADER_TEXT';
    }
}

export function tsLoader() {
    return {
        template: tsLoaderView,
        scope: true,
        controller: TsLoaderController,
        bindToController: {},
        controllerAs: 'vm'
    };
}
