'use strict';

import keyBy from 'lodash/keyBy';
import filter from 'lodash/filter';
import {getRootStore} from '../../_react_/app.bootstrap';

// One day, in msec
const MAX_STATE_AGE = 86400000;

/*
 * Helper service for the wizard state.
 * Initializes, deletes, cleans up states
 */
export class TsWizardHelperService {
    constructor($localStorage) {
        'ngInject';

        this._reactBrowserUtils = getRootStore().browserUtilsService;
        this.$localStorage = $localStorage;

        if (!this.$localStorage.wizardStates) {
            this.$localStorage.wizardStates = {};
        }

        // on startup we clean up old states that might have not ended normally
        this._cleanUpOldStates();
    }

    getState(roomId) {
        return this.$localStorage.wizardStates[roomId] || this.newState(roomId);
    }

    newState(roomId) {
        this.$localStorage.wizardStates[roomId] = {
            roomId: roomId,
            timestamp: Date.now(),
            showWizard: true,
            step: 0,
            platform: null
        };

        return this.$localStorage.wizardStates[roomId];
    }

    removeState(state) {
        delete this.$localStorage.wizardStates[state.roomId];
        this._reactBrowserUtils.removeFromLocalStorage('current-session');
    }

    _cleanUpOldStates() {
        const now = Date.now();

        this.$localStorage.wizardStates = keyBy(
            filter(this.$localStorage.wizardStates, (state) => now <= state.timestamp + MAX_STATE_AGE),
            'roomId'
        );
    }
}
